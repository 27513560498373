
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";

const PackageTemplateForm = props => {
  const [formFields, setFormFields] = useState();

  useEffect(() => {
      setFormFields([
        { 
          id: "package_name", 
          label: "PACKAGE NAME *", 
          type: "text", 
        },
        {
          id: "description",
          label: "DESCRIPTION *",
          type: "text", 
        },
        {
          id: "monthly_price",
          label: "MONTHLY PRICE",
          type: "text", 
        },
        {
          id: "annual_price",
          label: "ANNUAL PRICE",
          type: "text", 
        },
        {
          id: "max_company",
          label: "MAX COMPANY",
          type: "text", 
        },
        {
          id: "max_company_site",
          label: "MAX SITES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_employee",
          label: "MAX EMPLOYEES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_device",
          label: "MAX SERVERS & DEVICES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_license",
          label: "MAX SOFTWARE LICENSES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_other_service",
          label: "MAX SERVICES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_domain",
          label: "MAX DOMAINS PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_gallery",
          label: "MAX GALLERY IMAGES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_document",
          label: "MAX DOCUMENTS PER COMPANY",
          type: "text",
        },
        {
          id: "max_resource",
          label: "MAX RESOURCE",
          type: "text",
        },
        {
          id: "max_vendor",
          label: "MAX VENDOR",
          type: "text",
        },
        {
          id: "max_service",
          label: "MAX SERVICE",
          type: "text",
        },
        {
          id: "max_custom_tab",
          label: "MAX CUSTOM TAB",
          type: "text",
        },
        {
          id: "max_custom_tab_entry",
          label: "MAX ENTRIES PER CUSTOM TAB",
          type: "text",
        },
        {
          id: "max_portal",
          label: "MAX PORTAL",
          type: "text", 
        },
        {
          id: "user_addon_price",
          label: "READONLY ADDON PRICE",
          type: "text", 
        },
        {
          id: "portal_addon_price",
          label: "PORTAL ADDON PRICE",
          type: "text", 
        },
        {
          id: "is_moneris_module_available",
          label: "IS MONERIS MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_connect_sync_available",
          label: "IS CONNECT SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_ninja_sync_available",
          label: "IS NINJA SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_syncro_sync_available",
          label: "IS SYNCRO SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_autotask_sync_available",
          label: "IS AUTOTASK SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_hr_module_available",
          label: "IS HR MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_meeting_module_available",
          label: "IS MEETING MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_hidden",
          label: "IS HIDDEN",
          type: "radio", 
        },
      ]);
  }, []);

  return(
    <>
    {formFields && 
    <ObjectForm 
      {...props} 
      url={`package_templates`} 
      formName={"Package Template"} 
      formFields={formFields} 
      object={{
        is_moneris_module_available:false,
        is_connect_sync_available:false,
        is_hr_module_available:false,
        is_meeting_module_available:false}}/>
      }
    </>
  );
};

export default PackageTemplateForm;
