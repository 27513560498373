
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";

const JsonDataForm = props => {
	const { id, jsonSchemaId } = props.match.params;
  const [jsonSchema, setJsonSchema] = useState();
  const [formFields, setFormFields] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    if(jsonSchemaId){
      http.Get(
        `json_schemas/${jsonSchemaId}?tenant=${props.companyTenant.id}`,
        (data) => {
          setJsonSchema(data);
        },
        () => {}
      );
    }
    else if(props.objectId){
      http.Get(
        `json_datas/${props.objectId}`,
        (jsonData) => {
          http.Get(
            `json_schemas/${jsonData.json_schema}?tenant=${props.companyTenant.id}`,
            (data) => {
              setJsonSchema(data);
            },
            () => {}
          );
        },
        () => {}
      );
    }

    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if(jsonSchema && categories){
      setFormFields([
        {
          id: "json_data",
          label: "JSON DATA",
          type: "json", 
          schema: jsonSchema.json_schema,
          categories: categories
        },
        {
          id: "dataMappings",
          label: "RELATED ENTRIES",
          type: "dataMapping",
          model: `${jsonSchema.name}_json_schema_${jsonSchema.id}`,
          objectId: props.objectId,
        },
      ]);      
    }
  }, [jsonSchema, categories]);

  return(
    <>
    {jsonSchema && formFields && (
    <ObjectForm 
      {...props} 
      url={`json_datas`} 
      formName={jsonSchema.name} 
      formFields={formFields} 
      object={{ company: id, json_schema:jsonSchemaId, json_data:"{}"}}/>
    )}
    </>
  );
};

export default JsonDataForm;
