import { SET_USER, CLEAR_USER } from "../actions/types";

const initialState = {
  user: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      if (action.payload.permissions) {
        let permissions = {};
        action.payload.permissions.forEach(element => {
          if (element) permissions[element] = true;
        });
        action.payload.permissions = permissions;
      }

      return {
        user: { ...state.user, ...action.payload }
      };
    case CLEAR_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default userReducer;
