import React, { useEffect, useState } from "react";
import PackageTemplateForm from "./PackageTemplateForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "package_name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "monthly_price",
    label: "MONTHLY PRICE",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "annual_price",
    label: "ANNUAL PRICE",
    minWidth: 150,
    sortable: true,
  },
];

const PackageTemplates = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `package_templates`;
  const actions = {
    delete: true,
    add: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Package Templates"}
      url={url}
      actions={actions}
      objectForm={PackageTemplateForm}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Package Templates"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Package Templates"}
      url={url}
      actions={actions}
      objectForm={PackageTemplateForm}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Package Templates"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default PackageTemplates;
