import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import http from "../../Common/RestAPIHandler";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Collapse from "@material-ui/core/Collapse";
import { formatPhoneNumber } from "../../Common/Utility";
import ScreenLoader from "../../Common/Loader";
import { Divider } from "@material-ui/core";
import useStyles from "../Style/style"
import {dashboard} from "../../Common/Languages";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const Offices = (props) => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [loadData, setLoadData] = useState(false);
  var rowCount = 0;
  useEffect(() => {
    setLoadData(true);
    http.Get(
      "tenant_address",
      (contacts) => {
        setContacts(contacts);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);


  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setOffices(collapse);
  };

  const lang = localStorage.getItem("languages");

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>{dashboard.OFFICES[lang]}</span>
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                fontSize="default"
                className={classes.collapseButton}
                onClick={handleArrowClick}
                />
                )) || (
                  <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                  />
                  )}
            </Grid>
          </Grid>
          <Divider classes={{root: classes.dividers}} component="hr" orientation="horizontal" />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <Collapse in={!collapse}>
            <Grid item xs={12} className={classes.contentGrid}>
              <Table
                stickyHeader
                aria-label="simple table"
                className={classes.table}
              >
                <TableBody>
                  {contacts.map((row) => {
                    rowCount = rowCount + 1;
                    return (
                      <TableRow
                        id="tableRow"
                        key={row.id}
                        className={classes.tableRow}
                        // style={{
                        //   height: "30px",
                        //   backgroundColor:
                        //     rowCount % 2 === 1 ? "#EEEEEE" : "white",
                        // }}
                      >
                        <TableCell
                          className={`${classes.ellipsis} ${classes.tableCell}`}
                          component="td"
                          scope="row"
                        >
                          {row.address}
                          <span className={classes.colorScheme}>{" "}|{" "}</span>
                          {row.city}
                          {", "}
                          {row.state} {row.postal} 
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${classes.ellipsis}`}
                          component="td"
                          align="right"
                          scope="row"
                        >
                          <a
                            className={`${classes.link} ${classes.ellipsis}`}
                            href={"tel:" + formatPhoneNumber(row.phone)}
                          >
                            {formatPhoneNumber(row.phone)}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </div>
  );
};
export default Offices;
