import React, { useState, useEffect } from "react";
import http from "../../Common/RestAPIHandler";
import {IconButton} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import useStyles from "../../Common/Style/FormStyle";
import ScreenLoader from "../../Common/Loader";

const FileDetails = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    const [file, setFile] = useState({});
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      http.Get(
        `company_files/${props.objectId}/`,
        (file) => {
            setFile(file);
            setLoading(false);
        },
        () => {}
      );
    }, []);

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <div style={{textAlign:"center", padding:20}}>            
            <div style={{float:"right"}}>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                        if(props.onClose){
                            props.onClose();
                        }
                        else{
                            props.history.push(
                                process.env.PUBLIC_URL + `/company/${id}/domains`
                        );
                        }
                    }}
                >
                <CancelIcon />
                </IconButton>
            </div>
            <img key={file.id}
                style={{margin:"auto", width:600, height:600, objectFit:"contain", animation:"fadeIn 1s"}} 
                src={file.file_src}
            />
        </div>
        }
        </>
    );
};

export default FileDetails;