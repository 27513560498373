import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Slider from '@material-ui/core/Slider';
import { Divider } from "@material-ui/core";
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(theme => ({
  root: {
    width: "450px",
    padding: "20px",
    overflow: "hidden",
  },
  centerElement: {
    textAlign: "center",
  },
  cursor: {
    cursor: "pointer",
  },
  floatRight: {
    float: "right",
    cursor: "pointer",
  }
}));


const KeyGen = props => {
  const classes = useStyles();
  const [length, setLength] = useState(16);
  const [password, setPassword] = useState("");
  const [simplePassword, setSimplePassword] = useState("");

  useEffect(() => {
    getSimplePassword();
    buildPassword();
  }, []);

  const getSimplePassword = () => {
    fetch('https://www.dinopass.com/password/strong')
      .then(res => res.text())
      .then(text => setSimplePassword(text));
  };

  useEffect(() => {
    buildPassword();
  }, [length]);

  const buildPassword = () => {
    let a = [],
        b = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        c = "abcdefghijklmnopqrstuvwxyz",
        d = "1234567890",
        e = "!@#$%^&*'+",
        b_length = Math.floor(length/4),
        c_length = Math.floor(length/4),
        d_length = Math.floor(length/4),
        e_length = length - b_length - c_length - d_length;
    for(let i = 0; i < b_length; i++) {
      a.push(b[Math.floor(Math.random() * b.length)]);
    }
    for(let i = 0; i < c_length; i++) {
      a.push(c[Math.floor(Math.random() * c.length)]);
    }
    for(let i = 0; i < d_length; i++) {
      a.push(d[Math.floor(Math.random() * d.length)]);
    }
    for(let i = 0; i < e_length; i++) {
      a.push(e[Math.floor(Math.random() * e.length)]);
    }
    a.sort(() => Math.random() - 0.5);
    setPassword(a.join(''));
  }

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12}>
        <h5>Strong Password</h5>
        <CachedIcon className={classes.floatRight} onClick={getSimplePassword}/>
        <h3 className={classes.cursor} onClick={()=>{props.setPassword(simplePassword);}}>{ simplePassword }</h3>
        <Divider/>
        <h5>Advanced Password</h5>
        <CachedIcon className={classes.floatRight} onClick={buildPassword}/>
        <h3 className={classes.cursor} onClick={()=>{props.setPassword(password);}}>{ password }</h3>
      </Grid>
      <Grid item xs={12} className={classes.centerElement}>        
        <Slider
            value={length}
            min={8}
            max={32}
            onChange={(event, newValue) => {setLength(newValue);}}
        />
        <h5>Length: { length } Characters</h5>
      </Grid>
    </Grid>
  );
};

export default KeyGen;
