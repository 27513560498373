import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Logo from "../../../Images/logo.png";
import useStyles from "./style";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import SnackbarComponent from "../../Common/Snackbar";
import countryCodes from "../../Common/CountryCodes";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";

const TwoFA = (props) => {
  const classes = useStyles();
  const [isOneTouchApproved, setIsOneTouchApproved] = useState(false);
  const [session, setSession] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isOneTouchVerified, setIsOneTouchVerified] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isAuthy, setIsAuthy] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [authyError, setAuthyError] = useState();
  const [countryCodesMap, setCountryCodesMap] = useState({});
  const [countryCode, setCountryCode] = useState();
  const [mobile, setMobile] = useState();

  const handleSms = () => {
    clearInterval(intervalId);
    http.Get(
      "token/sms",
      () => {setSnackBarMessage("SMS Token Sent");},
      (status, error) => {
        setAuthyError(error);
      }
    );
  };

  const handleEmail = () => {
    clearInterval(intervalId);
    http.Get(
      "token/email",
      () => {setSnackBarMessage("Email Token Sent");},
      () => {}
    );
  };

  const handleTokenSubmit = (token) => {
    clearInterval(intervalId);
    http.Post(
      "2fa",
      { token: token },
      (response) => {
        setTimeout(rediret, 1000);
      },
      () => {
        setIsInvalidToken(true);
      }
    );
  };

  useEffect(() => {
    console.log(props.user.tenant);
    setIsAuthy(props.user.tenant.is_authy);
    setIsSMS(props.user.tenant.is_sms);
    setIsEmail(props.user.tenant.is_email);
    if(props.user.tenant.is_authy)
    {
      http.Get(
        "token/onetouch",
        (session) => {
          setSession(session);
          setIsOneTouchApproved(true);
        },
        (status, error) => {
          setAuthyError(error);

          var countryCodeMap = {};
          
          Object.keys(countryCodes).forEach(function(c) {
            if (!countryCodeMap[countryCodes[c].code]) {
              countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
            } else {
              countryCodeMap[countryCodes[c].code] =
                countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
            }
          });
  
          Object.entries(countryCodeMap).map(([key, value]) => {
            countryCodeMap[key] = value + " (" + key + ")";
          });

          setCountryCodesMap(countryCodeMap);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isOneTouchApproved && session) {
      var id = setInterval(() => {
        http.Post(
          "onetouch-status",
          session,
          (data) => {
            if (data.approval_request.status === "approved") {
              clearInterval(id);
              setTimeout(rediret, 1000);
            } else if (data.approval_request.status === "expired") {
              clearInterval(id);
              setIsOneTouchVerified(true);
            } else if (data.approval_request.status === "denied") {
              clearInterval(id);
              setIsOneTouchVerified(true);
            }
          },
          () => {}
        );
      }, 2000);
      setIntervalId(id);
    }
  }, [isOneTouchApproved, session]);

  const rediret = () => {
    props.history.replace(process.env.PUBLIC_URL + "/portal/credentials");
    window.location = window.location + "#loading";
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={()=>{setSnackBarMessage(null);}}
        severity={"success"}
      ></SnackbarComponent>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.centerElement}>
                <Typography variant="h6" color="inherit" noWrap>
                  <img
                    src={Logo}
                    alt="Not Available"
                    className={classes.logo}
                  />
                </Typography>
              </Grid>
              {authyError &&
                <>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary">
                    Update mobile number to enable 2FA login:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    onChange={(e)=>{
                      setCountryCode(e.target.value);
                    }}
                  >
                    {Object.entries(countryCodesMap).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    label="Mobile"
                    type="text"
                    margin="dense"
                    variant="outlined"    
                    fullWidth                    
                    onChange={(e)=>{setMobile(e.target.value);}}
                  />
                </Grid>
                <Grid item xs={12} className={classes.rightElement}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    disabled={!countryCode || !mobile}
                    onClick={(e)=>{
                      http.Post(
                        "token/onetouch",
                        {"country_code": countryCode, "mobile": mobile},
                        () => {                          
                          setAuthyError(null);
                          http.Get(
                            "token/onetouch",
                            (session) => {
                              setSession(session);
                              setIsOneTouchApproved(true);
                            },
                            (status, error) => {
                              setAuthyError(error);
                            }
                          );
                        },
                        (status, error) => {
                          setAuthyError(error);
                        }
                      );
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                </>
              }
              {!authyError &&
              <>
              {isAuthy &&
              <Grid item xs={12}>
                <Typography variant="h6" color="textSecondary">
                  Waiting for one touch authentication...
                </Typography>
              </Grid>
              }
              {isAuthy && isOneTouchVerified && (
                <Grid item xs={12} className={classes.error}>
                  One Touch not verified or expired. Please login again or get
                  verified via sms.
                </Grid>
              )}
              {isInvalidToken && (
                <Grid item xs={12} className={classes.error}>
                  Invalid token
                </Grid>
              )}
              <Grid item xs={12}>  
                  <TextField
                    id="outlined-otp-input"
                    label="Enter verification code:"
                    type="text"
                    margin="dense"
                    variant="outlined"    
                    fullWidth                    
                    onChange={(e)=>{if(e.target.value && e.target.value.length===7)handleTokenSubmit(e.target.value);}}
                  />
              </Grid>
              {isSMS &&
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  color="textPrimary"
                  onClick={() => handleSms()}
                >
                  Sms token
                </Link>
              </Grid>
              }
              {isEmail &&
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  color="textPrimary"
                  onClick={() => handleEmail()}
                >
                  Email token
                </Link>
              </Grid>
              }
              </>
              }
              <Grid item xs={12} className={classes.centerElement}>
                <Link href="/portal/login">Return to login page.</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps)(TwoFA);
