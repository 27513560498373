import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";

const ResellerUserForm = props => {
  const [formFields, setFormFields] = useState();
  const [resellers, setResellers] = useState();

  useEffect(() => {
    http.Get(
      'resellers',
      (data) => {
        var resellerMap = {};
        data.map((reseller)=>{
          resellerMap[reseller.id] = reseller.name;
        });
        setResellers(resellerMap);
      },
      () => {
      }
    );
  }, []);

  var countryCodeMap = {};

  Object.keys(countryCodes).forEach(function(c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  Object.entries(countryCodeMap).map(([key, value]) => {
    countryCodeMap[key] = value + " (" + key + ")";
  });

  useEffect(() => {
    if (countryCodeMap && resellers) {
      setFormFields([
        {
          id: "reseller",
          label: "RESELLER",
          type: "select",
          valueMap: resellers
        },
        {
          id: "first_name",
          label: "FIRST NAME *",
          type: "text"
        },
        {
          id: "last_name",
          label: "LAST NAME *",
          type: "text"
        },
        {
          id: "email",
          label: "EMAIL *",
          type: "email"
        },
        {
          id: "phone",
          label: "PHONE",
          type: "text"
        },
        {
          id: "mobile",
          label: "MOBILE",
          type: "text"
        },
        {
          id: "country_code",
          label: "COUNTRY CODE",
          type: "select",
          valueMap: countryCodeMap
        },
        {
          id: "number_of_result_per_page",
          label: "NUMBER OF RESULT PER PAGE *",
          type: "select",
          valueMap: {25:25,50:50,100:100,200:200}
        },
        {
          id: "is_reseller_admin",
          label: "IS RESELLER ADMIN",
          type: "radio"
        },
        {
          id: "has_access",
          label: "HAS ACCESS",
          type: "radio"
        },
      ]);
    }
  }, [resellers]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`reseller_users`}
          formName={"Reseller User"}
          formFields={formFields}
          object={{ is_reseller_admin: false }}
        />
      )}
    </div>
  );
};

export default ResellerUserForm;
