import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ConvertDateToLocale } from "../../Common/Utility";
import http from "../../Common/RestAPIHandler";
import useStyles from "../../Common/Style/FormStyle";
import commonConstraints from "../../Common/CommonConstants";
import ClassicEditor from "@ckeditor-linkpoint/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";

const HRMeetingForm = (props) => {
  const classes = useStyles();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isInvalidDate, setisInvalidDate] = useState(false);
  const [versions, setVersions] = useState();
  const [ckeditor, setCkeditor] = useState();

  const [meeting, setMeeting] = useState({
    name: "",
    start_time: new Date(),
    end_time: new Date(),
    location: "",
    hr_employee: props.hr_employee,
  });
  const [meetingError, setMeetingError] = useState({});
  const { requiredError, blankError } = commonConstraints.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [content, setContent] = useState(props.template.data || "");
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `hr_employee_meetings/${props.objectId}`,
        (data) => {
          var content = data.content;
          if (!content) content = "";
          delete data.content;
          setMeeting(data);
          setStartTime(ConvertDateToLocale(data.start_time));
          setEndTime(ConvertDateToLocale(data.end_time));
          setContent(content);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setisInvalidDate(false);
    setMeeting({ ...meeting, [prop]: event.target.value });
    setMeetingError({ ...meetingError, [prop]: false });
    setIsSubmitted(false);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertTable",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        isExternal: {
          mode: "automatic",
          callback: (url) => url.startsWith("http://"),
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };

  const onEditorInit = (editor) => {
    setCkeditor(editor);
    editor.editing.view.change((writer) => {
      writer.setStyle(
        "height",
        "calc(100vh/4)",
        editor.editing.view.document.getRoot()
      );
    });
  };

  const onEditorChange = (event, editor) => {
    setContent(editor.getData());
  };

  const onMeetingSubmit = (e) => {
    e.preventDefault();

    setisInvalidDate(false);
    if (startTime >= endTime) {
      setisInvalidDate(true);
      return;
    }
    setisInvalidDate(false);

    var data = {
      ...meeting,
      start_time: startTime,
      end_time: endTime,
      content: content,
    };

    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `hr_employee_meetings/${props.objectId}`,
        data,
        (meeting) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setMeetingError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `hr_employee_meetings`,
        data,
        (meeting) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setMeetingError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={10}>
            <Typography variant="h6">
              {props.edit ? "Edit Meeting" : "Add New Meeting"}
            </Typography>
          </Grid>

          <Grid item xs={2} className={classes.cross}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ValidatorForm>
          <div className={classes.content}>
            <div style={{ display: versions ? "None" : "" }}>
              {loadData ? (
                <ScreenLoader />
              ) : (
                <div>
                  <p className={classes.label}>TITLE</p>
                  <Grid item xs={12}>
                    <TextValidator
                      id="name"
                      fullWidth
                      variant="outlined"
                      name="name"
                      value={meeting.name || ""}
                      onChange={handleChange("name")}
                      margin="dense"
                      validators={["required", "trim"]}
                      errorMessages={[requiredError, blankError]}
                      error={Boolean(meetingError.name)}
                      helperText={meetingError.name && meetingError.name[0]}
                      inputProps={{ maxLength: 25 }}
                    />
                  </Grid>

                  <p className={classes.label}>MEETING TIME</p>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                      Start
                    </Grid>
                    <Grid item xs={5}>
                      <DatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MMM dd, yyyy"
                        fullWidth
                        margin="dense"
                        value={startTime}
                        onChange={(value) => {
                          setStartTime(value);
                          setisInvalidDate(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TimePicker
                        autoOk
                        ampm
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm a"
                        fullWidth
                        margin="dense"
                        value={startTime}
                        onChange={(value) => {
                          setStartTime(value);
                          setisInvalidDate(false);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                      End
                    </Grid>
                    <Grid item xs={5}>
                      <DatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="MMM dd, yyyy"
                        fullWidth
                        margin="dense"
                        value={endTime}
                        onChange={(value) => {
                          setEndTime(value);
                          setisInvalidDate(false);
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TimePicker
                        autoOk
                        ampm
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm a"
                        fullWidth
                        margin="dense"
                        value={endTime}
                        onChange={(value) => {
                          setEndTime(value);
                          setisInvalidDate(false);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {isInvalidDate && (
                    <Grid item container spacing={1}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={9}>
                        <p style={{ color: "red" }}>Invalid Meeting time.</p>
                      </Grid>
                    </Grid>
                  )}

                  <p className={classes.label}>DESCRIPTION</p>
                  <Grid item xs={12} className={classes.editor}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      config={editorConfiguration}
                      onInit={onEditorInit}
                      onChange={onEditorChange}
                    />
                  </Grid>
                  <p className={classes.label}>LOCATION</p>
                  <Grid item xs={12}>
                    <TextValidator
                      id="location"
                      fullWidth
                      variant="outlined"
                      name="location"
                      margin="dense"
                      value={meeting.location || ""}
                      inputProps={{ maxLength: 25 }}
                      onChange={handleChange("location")}
                      error={Boolean(meetingError.location)}
                      helperText={
                        meetingError.location && meetingError.location[0]
                      }
                    />
                  </Grid>
                </div>
              )}
            </div>
          </div>

          <div className={classes.divider}>
            <Divider />
          </div>

          <div className={classes.footer}>
            <div className={classes.outlinedButtonContainer}>
              <Button
                variant="outlined"
                margin="dense"
                size="small"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => props.closeOpen(false)}
              >
                Cancel
              </Button>
              {!versions && (
                <Button
                  variant="outlined"
                  margin="dense"
                  size="small"
                  className={classes.filledButton}
                  disabled={isSubmitted}
                  onClick={onMeetingSubmit}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </ValidatorForm>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(HRMeetingForm);
