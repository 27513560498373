import React, { useState, useEffect, useRef } from "react";
import http from "../../Common/RestAPIHandler";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { onCopy } from "../../Common/TableUtility";
import CopyIconOut from "@material-ui/icons/FileCopyOutlined";
import CreateIcon from "@material-ui/icons/Create";
import CreateIconOut from "@material-ui/icons/CreateOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteIconOut from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";
import SaveIcon from "@material-ui/icons/Save";
import Popover from "@material-ui/core/Popover";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import KeyGen from "../../Common/KeyGen";
import ObjectVersions from "../../Common/ObjectVersions";
import {
	Grid,
	Divider,
	Dialog,
	Chip,
	CardContent,
	Card,
	Typography,
	Collapse,
	TextField,
	InputBase,
	IconButton,
	FormControl,
	Tooltip,
	FormHelperText,
	Menu,
	ClickAwayListener,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HistoryIcon from "@material-ui/icons/History";
import QrReader from "react-qr-reader";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import OTP from "../../Common/OTP";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { validPassword, isDarkEnabled } from "../../Common/Utility";
import { EmployeesLang } from "../../Common/Languages";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
	root: {
		width: "-webkit-fill-available",
		borderRadius: 8,
	},
	blankChip: {
		borderRadius: 8,
		width: "-webkit-fill-available",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	bkIcon: {
		background: "black",
		color: "white",
	},
	borderColor: {
		color: theme.borderColor
	},
	button: {
		borderRadius: 25,
		backgroundColor: theme.colorScheme,
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
			border: "1px solid white",
		},
	},
	cardContent: {
		display: "flex",
		height: 80,
		backgroundColor: "#fafafa",
	},
	cardCollapse: {
		backgroundColor: "#fafafa",
	},
	checkboxColor: {
		color: theme.colorScheme + "!important",
	},
	chip: {
		borderRadius: 8,
		color: "white",
		backgroundColor: theme.colorScheme,
		width: "-webkit-fill-available",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	chipOutlined: {
		width: "fit-content",
		borderRadius: 8,
		borderColor: theme.colorScheme,
		marginRight: "2px",
		color: theme.colorScheme,
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	copy: {
		cursor: "pointer",
		color: theme.colorScheme,
	},
	cursor: {
		cursor: "pointer",
		color: theme.colorScheme,
		margin: "10px",
	},
	dataMobile: {
		marginLeft: 5,
	},
	endIcons: {
		cursor: "pointer",
		color: theme.colorScheme,
		display: "flex",
		alignSelf: "center",
		justifyContent: "center",
	},
	error: {
		color: "red",
		fontSize: "x-small",
	},
	passwordHide: {
		cursor: "pointer",
		// color: theme.colorScheme,
		fontSize: "1.0rem",
		marginLeft: 5,
	},
	themeIcon: {
		color: theme.colorScheme,
	},
	FprintIconColorScheme: {
		cursor: "pointer",
		color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor,
		},
		float: "right",
	},
	iconColorScheme: {
		cursor: "pointer",
		// color: "#a4a4a4",
		color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor
		},
		width: 15,
		verticalAlign: "bottom",
	},
	errorIconColorScheme: {
		color: "#f44336",
		width: 15,
	},
	table: {
		backgroundColor: theme.notification
	},
	tableCell: {
		paddingTop: 12,
		minWidth: "195px !important",
		color: theme.subText,
	},
	tableRow: {
		backgroundColor: theme.notification,
		"&:hover": {
			backgroundColor: theme.tableRowHoverAlt
		}
	},
	themeColor: {
		color: theme.colorScheme,
	},
	underline: {
		color: theme.colorScheme,
		"& :after": {
			borderBottom: theme.colorScheme,
		},
	},
	wordWrap: {
		whiteSpace: "normal",
		wordWrap: "break-word",
		maxWidth: "200px",
	},
}));

// ----- Custom input used in DESKTOP view and EDIT of EmployeeCredentials ----- //
const InputCustom = withStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			// color: theme.colorScheme,
		}, 
		"& :after": {
			borderBottomColor: theme.colorScheme,
		},
	},
}))(TextField);

const EmployeeCredential = (props) => {
	const classes = useStyles();
	const categories = props.categories;
	const [account, setAccount] = useState(props.account);
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [errors, setErrors] = useState({});
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [collapse, setCollapse] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElMenu, setAnchorElMenu] = useState(null);
	const [anchorElEditMenu, setAnchorElEditMenu] = useState(null);
	const [turnonCamera, setTurnonCamera] = useState(false);
	const [showQrReader, setShowQrReader] = useState(false);
	const qrReader = useRef(null);

	const open = Boolean(anchorEl);
	const openMenu = Boolean(anchorElMenu);
	const openEditMenu = Boolean(anchorElEditMenu);

	const handleClickShowKeyGen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseShowKeyGen = () => {
		setAnchorEl(null);
	};

	const handleClickMenu = (event) => {
		setAnchorElMenu(event.currentTarget);
	};

	const handleClickEditMenu = (event) => {
		setAnchorElEditMenu(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorElMenu(null);
	};

	const handleCloseEditMenu = () => {
		setAnchorElEditMenu(null);
	};

	const onChange = (e) => {
		if ("checkbox" === e.target.type || "radio" === e.target.type) {
			setAccount({
				...account,
				[e.target.name]: !account[e.target.name],
			});
		} else
			setAccount({
				...account,
				[e.target.name]: e.target.value,
			});
	};

	const handlePut = (e) => {
		setEdit(false);
		setLoading(true);
		http.Put(
			`employee/credential/${account.id}/`,
			account,
			(data) => {
				data["versions"] = (
					<HistoryIcon
						className={classes.cursor}
						onClick={(e) => {
							props.setVersionsAnchorEl(e.currentTarget);
							props.setVersions(null);
							http.Get(
								`versions?object_id=${data.id}&model_name=credential`,
								(versions) => {
									props.setVersions(versions);
								},
								() => {
									props.setVersions(null);
								}
							);
						}}
					/>
				);
				setAccount(data);
				setErrors({});
				setLoading(false);
			},
			(status, error) => {
				setErrors(error);
				setEdit(true);
				setLoading(false);
			}
		);
	};

	const deleteAccount = (accountId) => {
		setLoading(true);
		http.Delete(
			`employee/credential/`,
			{ ids: [accountId] },
			() => {
				props.fetchData();
				setLoading(false);
			},
			(status, error) => {
				setErrors(error);
				setLoading(false);
			}
		);
	};

	// Option Icons for menu
	const options = [
		<Tooltip
			id="versions"
			arrow={true}
			title={EmployeesLang.History[lang]}
			placement="left-start"
			enterNextDelay={200}
		>
			{account.versions}
		</Tooltip>,
		<Tooltip
			id="edit"
			arrow={true}
			title={EmployeesLang.Edit[lang]}
			placement="left-start"
			enterNextDelay={200}
		>
			<CreateIcon
				onClick={(e) => {
					setEdit(true);
					handleCloseMenu();
				}}
				className={classes.cursor}
			/>
		</Tooltip>,
		<Tooltip
			id="delete"
			arrow={true}
			title={EmployeesLang.Delete[lang]}
			placement="left-start"
			enterNextDelay={200}
		>
			<DeleteIcon
				onClick={(e) => {
					deleteAccount(account.id);
				}}
				className={classes.cursor}
			/>
		</Tooltip>,
	];

	// Option Icons for menu
	const editOptions = [
		<Tooltip
			id="camera"
			arrow="true"
			title="Scan QR"
			placement="left-start"
			enterNextDelay={200}
			enterDelay={500}
		>
			<CameraAltIcon
				fontSize="small"
				className={classes.cursor}
				onClick={() => {
					setTurnonCamera((prev) => !prev);
				}}
			/>
		</Tooltip>,
		<Tooltip
			id="upload"
			arrow="true"
			title="Upload QR"
			placement="left-start"
			enterNextDelay={200}
			enterDelay={500}
		>
			<CloudUploadIcon
				fontSize="small"
				className={classes.cursor}
				onClick={() => {
					setShowQrReader((prev) => !prev);
					qrReader.current.openImageDialog();
				}}
			/>
		</Tooltip>,
		<Tooltip
			id="save"
			arrow="true"
			title="Save"
			placement="left-start"
			enterNextDelay={200}
			enterDelay={500}
		>
			<SaveIcon
				fontSize="small"
				onClick={handlePut}
				className={classes.cursor}
			/>
		</Tooltip>,
	];

	return (
		<React.Fragment>
			{loading && (
				<TableRow>
					<TableCell colSpan={9}>
						<ScreenLoader />
					</TableCell>
				</TableRow>
			)}
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={3000}
				onClose={() => {
					setSnackBarOpen(false);
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => {
						setSnackBarOpen(false);
					}}
					severity="success"
					elevation={6}
					variant="filled"
				>
					{message}
				</Alert>
			</Snackbar>

			{/* ----- DISPLAY individual employee credential DESKTOP ----- */}
			{!props.mobile && !loading && !edit && (
				<TableRow key={account.id} className={classes.tableRow}>
					<TableCell className={classes.tableCell}>{account.category_name}</TableCell>
					<TableCell className={classes.tableCell}>{account.description}</TableCell>
					<TableCell >
						<FilterNoneIcon
							fontSize="small"
							className={classes.iconColorScheme}
							onClick={() => {
								onCopy(props, account, "username");
								setMessage("Username Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<span>{account.username}</span>
					</TableCell>
					<TableCell>
						<FilterNoneIcon
							fontSize="small"
							className={classes.iconColorScheme}
							onClick={() => {
								onCopy(props, account, "password");
								setMessage("Password Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<span
							className={classes.passwordHide}
							onClick={(e) => {
								if (e.target.innerHTML === "············") {
									e.target.innerHTML = String(account["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
								} else {
									e.target.innerHTML = "············";
								}
							}}
						>
							············
						</span>
						&nbsp;
						{account["flagged_pass"] ? (
							<Tooltip
								interactive
								arrow={true}
								placement="top-start"
								title="Unsafe Password!"
							>
								<ErrorOutlineIcon className={classes.errorIconColorScheme} />
							</Tooltip>
						) : (
							<></>
						)}
					</TableCell>
					<TableCell>
						{account.website && (
							<span
								className={classes.copy}
								onClick={() => {
									let str = account.website && account.website.toString();
									if (
										str &&
										!(str.startsWith("https://") || str.startsWith("http://"))
									)
										str = "http://" + str;
									if (str) window.open(str);
								}}
							>
								{account.website}
							</span>
						)}
					</TableCell>
					<TableCell
						style={{
							width: 120,
							minWidth: 120,
						}}
					>
						{account.otp && <OTP {...props} otpKey={account.otp} />}
					</TableCell>
					<TableCell>
						{/* Menu handler for existing credentials */}
						<Tooltip
							arrow={true}
							title="Options"
							placement="top-start"
							enterDelay={200}
						>
							<ClickAwayListener onClickAway={handleCloseMenu}>
								<IconButton size="small" onClick={handleClickMenu}>
									<ListIcon className={classes.cursor} />
								</IconButton>
							</ClickAwayListener>
						</Tooltip>
						<Menu
							id="cred-desk"
							open={openMenu}
							anchorEl={anchorElMenu}
							onExit={handleCloseMenu}
						>
							{options.map((option) => {
								return (
									<MenuItem key={option.props["key"]} onClick={handleCloseMenu}>
										{option}
									</MenuItem>
								);
							})}
						</Menu>
					</TableCell>
				</TableRow>
			)}

			{/* ----- EDIT individual employee credential DESKTOP ----- */}
			{!props.mobile && !loading && edit && (
				<TableRow key={account.id} className={classes.tableRow}>
					<TableCell className={classes.tableCell}>
						<Select
							id={"category"}
							name={"category"}
							value={account.category || "placeholder"}
							onChange={onChange}
							classes={{ root: classes.borderColor }}
							style={{
								width: "195px",
								// border: "0px",
								// borderBottom: "1px solid ",
								outline: "none",
							}}
						>
							<MenuItem disabled value="placeholder">
								<span style={{ color: "#a2a2a2" }}>{EmployeesLang.ACCOUNTTYPE[lang]}</span>
							</MenuItem>
							{Object.entries(categories).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>
						<p style={{ color: "red" }}>{errors.category}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							placeholder={EmployeesLang.DESCRIPTION[lang]}
							variant="outlined"
							margin="dense"
							onChange={onChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="description"
							classes={{ root: classes.borderColor }}
							style={{
								border: "0px",
								// borderBottom: "1px solid ",
								outline: "none",
							}}
							value={account.description}
						/>
						<p style={{ color: "red" }}>{errors.description}</p>
					</TableCell>
					<TableCell>
						<></>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							placeholder={EmployeesLang.USERNAME[lang] + " *"}
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								setAccount({
									...account,
									["username"]: e.target.value,
								});
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="new-name"
							autoComplete="new-name"
							classes={{ root: classes.borderColor }}
							style={{
								border: "0px",
								// borderBottom: "1px solid ",
								outline: "none",
							}}
							value={account.username}
						/>
						<p style={{ color: "red" }}>{errors.username}</p>
					</TableCell>
					<TableCell>
						<Tooltip
							arrow={true}
							title="Generate Password"
							placement="bottom-start"
						>
							<FingerprintIcon
								className={classes.FprintIconColorScheme}
								onClick={handleClickShowKeyGen}
							/>
						</Tooltip>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleCloseShowKeyGen}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							<KeyGen
								setPassword={(p) => {
									setAccount({
										...account,
										["password"]: p,
									});
									handleCloseShowKeyGen();
								}}
							/>
						</Popover>{" "}
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							placeholder={EmployeesLang.PASSWORD[lang]+" *"}
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								setAccount({
									...account,
									["password"]: e.target.value,
								});
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="password"
							name="new-pass"
							autoComplete="new-pass"
							classes={{ root: classes.borderColor }}
							style={{
								border: "0px",
								// borderBottom: "1px solid ",
								outline: "none",
								fontFamily: "Raleway",
							}}
							value={account.password}
						/>
						<p style={{ color: "red" }}>{errors.password}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							placeholder={EmployeesLang.WEBSITE[lang]}
							variant="outlined"
							margin="dense"
							onChange={onChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="website"
							classes={{ root: classes.borderColor }}
							style={{
								border: "0px",
								// borderBottom: "1px solid ",
								outline: "none",
							}}
							value={account.website}
						/>
						<p style={{ color: "red" }}>{errors.website}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							placeholder={EmployeesLang.otp[lang]}
							variant="outlined"
							margin="dense"
							onChange={onChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="otp"
							style={{
								border: "0px",
								// borderBottom: "1px solid black",
								outline: "none",
							}}
							value={account.otp}
						/>

						{turnonCamera && (
							<QrReader
								delay={300}
								onError={(err) => {
									console.error(err);
								}}
								onScan={(data) => {
									if (data && data.includes("secret=")) {
										setTurnonCamera(false);
										setAccount({
											...account,
											["otp"]: data.split("secret=")[1].split("?")[0].split("&")[0],
										});
									}
								}}
								style={{ width: "100%" }}
							/>
						)}
						<div
							className={classes.cursor}
							onClick={() => {
								qrReader.current.openImageDialog();
							}}
						>
							<QrReader
								ref={qrReader}
								legacyMode={true}
								onError={(err) => {
									console.error(err);
								}}
								onScan={(data) => {
									if (data && data.includes("secret=")) {
										setAccount({
											...account,
											["otp"]: data.split("secret=")[1].split("?")[0].split("&")[0],
										});
									} else {
										setAccount({
											...account,
											["otp"]: "No OTP Key Found",
										});
									}
								}}
								style={{ display: "none" }}
							/>
						</div>
						<p style={{ color: "red" }}>{errors.otp}</p>
					</TableCell>
					<TableCell>
						{/* Menu handler for editing credentials */}
						<Tooltip
							arrow={true}
							title="Options"
							placement="top-start"
							enterDelay={200}
						>
							<ClickAwayListener onClickAway={handleCloseEditMenu}>
								<IconButton size="small" onClick={handleClickEditMenu}>
									<ListIcon className={classes.cursor} />
								</IconButton>
							</ClickAwayListener>
						</Tooltip>
						<Menu
							id="cred-edit-desk"
							open={openEditMenu}
							anchorEl={anchorElEditMenu}
							onClose={handleCloseEditMenu}
						>
							{editOptions.map((option) => {
								return (
									<MenuItem onClick={handleCloseEditMenu}>{option}</MenuItem>
								);
							})}
						</Menu>
					</TableCell>
				</TableRow>
			)}

			{/* ----- EDIT individual employee credential MOBILE ----- */}
			{props.mobile && !loading && edit && (
				<Dialog
					id={"hightop"}
					open={edit}
					onClose={() => setEdit(false)}
					disablePortal
					// style={{ width: 350, margin: 0 }}
				>
					{/* Need to redo the error handling <p> tags effecting styling */}
					<Card style={{ width: 300 }}>
						<CardContent>
							<Grid container spacing={0} direction="column">
								<Grid item xs={8}>
									<Select
										id={"category"}
										fullWidth
										name={"category"}
										value={account.category || ""}
										onChange={onChange}
										style={{
											border: "0px",
											borderBottom: "1px solid black",
											outline: "none",
										}}
										placeholder={EmployeesLang.ACCOUNTTYPE[lang]}
									>
										<MenuItem selected disabled>
											{EmployeesLang.SelectAccountType[lang]}
										</MenuItem>
										{Object.entries(categories).map(([key, value]) => (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										))}
									</Select>
									<p className={classes.error}>{errors.category}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="description"
										fullWidth
										variant="outlined"
										margin="dense"
										name="description"
										value={account.description || ""}
										onChange={onChange}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder={EmployeesLang.DESCRIPTION[lang]}
									/>
									<p className={classes.error}>{errors.description}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										variant="outlined"
										margin="dense"
										name="new-name"
										autoComplete="new-name"
										value={account.username || ""}
										onChange={(e) => {
											setAccount({
												...account,
												["username"]: e.target.value,
											});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder={EmployeesLang.USERNAME[lang] + " *"}
									/>
									<p className={classes.error}>{errors.username}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="password-w-icon"
										fullWidth
										variant="outlined"
										margin="dense"
										name="new-pass"
										autoComplete="new-pass"
										value={account.password || ""}
										onChange={(e) => {
											setAccount({
												...account,
												["password"]: e.target.value,
											});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder={EmployeesLang.PASSWORD[lang]+" *"}
										// style={{ fontFamily: "Raleway" }}
									/>
									<p className={classes.error}>{errors.password}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="website"
										fullWidth
										variant="outlined"
										margin="dense"
										name="website"
										value={account.website || ""}
										onChange={onChange}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder={EmployeesLang.WEBSITE[lang]}
									/>
									<p className={classes.error}>{errors.website}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="otp"
										fullWidth
										variant="outlined"
										margin="dense"
										name="otp"
										value={account.otp || ""}
										onChange={onChange}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder="OTP"
									/>
									{turnonCamera && (
										<QrReader
											delay={300}
											onError={(err) => {
												console.error(err);
											}}
											onScan={(data) => {
												if (data && data.includes("secret=")) {
													setTurnonCamera(false);
													setAccount({
														...account,
														["otp"]: data.split("secret=")[1].split("?")[0].split("&")[0],
													});
												}
											}}
											style={{ width: "100%" }}
										/>
									)}
									{!turnonCamera && (
										<CameraAltIcon
											className={classes.iconColorScheme}
											onClick={() => {
												setTurnonCamera(true);
											}}
										/>
									)}
									<p className={classes.error}>{errors.otp}</p>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<Chip
											variant="outlined"
											onClick={() => setEdit(false)}
											label="Cancel"
											className={classes.blankChip}
										/>
									</Grid>
									<Grid item xs={6}>
										<Chip
											label="Save"
											onClick={handlePut}
											className={classes.chip}
										/>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Dialog>
			)}

			{/* ----- Display individual employee credential component MOBILE -----  */}
			{props.mobile && !loading && !edit && (
				<Grid container spacing={1} className={classes.root}>
					<Grid item xs={12}>
						<Card style={{ borderRadius: 10 }}>
							<CardContent className={classes.cardContent}>
								<Grid container item xs={11} spacing={0}>
									<Grid item xs={12}>
										<Typography variant="overline">TYPE</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography
											variant="body2"
											classes={{
												body2: classes.themeColor,
											}}
										>
											{account.category_name ? account.category_name : " "}
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={1} spacing={0}>
									<Grid item xs={12} style={{ alignSelf: "center" }}>
										<IconButton size="small" className={classes.bkIcon}>
											{collapse ? (
												<KeyboardArrowUpIcon
													onClick={() => setCollapse((prev) => !prev)}
												/>
											) : (
												<KeyboardArrowDownIcon
													onClick={() => setCollapse((prev) => !prev)}
												/>
											)}
										</IconButton>
									</Grid>
								</Grid>
							</CardContent>
							<Collapse
								key={account.id}
								in={collapse}
								timeout="auto"
								className={classes.cardCollapse}
							>
								<Grid
									container
									spacing={1}
									direction="column"
									style={{ margin: 10 }}
								>
									<Grid item xs={12}>
										<Grid container spacing={0}>
											<Grid item xs={10}>
												<Typography variant="overline">description</Typography>
												<Typography
													variant="body2"
													color="textSecondary"
													className={classes.dataMobile}
												>
													{account.description ? account.description : " "}
												</Typography>
											</Grid>
											<Grid item xs={10}>
												<Typography variant="overline">username</Typography>
												<Typography
													variant="body2"
													color="textSecondary"
													className={classes.dataMobile}
												>
													{account.username ? account.username : " "}
												</Typography>
											</Grid>
											<Grid item xs={2} style={{ alignSelf: "flex-end" }}>
												<CopyIconOut
													className={classes.themeIcon}
													fontSize="small"
													onClick={() => {
														onCopy(props, account, "username");
														setMessage("Username Copied");
														setSnackBarOpen(true);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={0}>
											<Grid item xs={10}>
												<Typography variant="overline">password</Typography>
												<Typography variant="body2" color="textSecondary">
													<span
														className={classes.password}
														onClick={(e) => {
															if (e.target.innerHTML === "············")
																e.target.innerHTML = String(account["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
															else e.target.innerHTML = "············";
														}}
													>
														{" "}
														············
													</span>
												</Typography>
											</Grid>
											<Grid item xs={2} style={{ alignSelf: "flex-end" }}>
												<CopyIconOut
													className={classes.themeIcon}
													fontSize="small"
													onClick={() => {
														onCopy(props, account, "password");
														setMessage("Password Copied");
														setSnackBarOpen(true);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="overline">website</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											className={classes.dataMobile}
										>
											{account.website ? account.website : " "}
										</Typography>
									</Grid>
									{account.otp && (
										<Grid item xs={12}>
											<Typography variant="overline">otp</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												className={classes.dataMobile}
											>
												<OTP {...props} otpKey={account.otp}/>
											</Typography>
										</Grid>
									)}
								</Grid>
								<Divider variant="middle" />
								<Grid
									container
									spacing={1}
									direction="row-reverse"
									style={{ height: 50 }}
								>
									<Grid item xs className={classes.endIcons}>
										<DeleteIconOut
											fontSize="small"
											onClick={(e) => {
												deleteAccount(account.id);
											}}
											className={classes.cursor}
										/>
										<CreateIconOut
											fontSize="small"
											onClick={() => {
												setEdit(true);
											}}
											className={classes.cursor}
										/>
									</Grid>
								</Grid>
							</Collapse>
						</Card>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
};

const EmployeeCredentials = (props) => {
	const classes = useStyles();
	const categories = props.categories;
	const [errors, setErrors] = useState({});
	const [employeeAccounts, setEmployeeAccounts] = useState([]);
	const [account, setAccount] = useState({});
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [versionsAnchorEl, setVersionsAnchorEl] = useState(null);
	const [versions, setVersions] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [tempTooltip, setTempTooltip] = useState(
		errors.password ? true : false
	);

	const handleClickShowKeyGen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseShowKeyGen = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const fetchData = () => {
		setLoading("Loading");
		setEmployeeAccounts([]);
		http.Get(
			`employee/credential/?employee=${props.employeeId}`,
			(data) => {
				data.map((item) => {
					item["versions"] = (
						<HistoryIcon
							className={classes.cursor}
							onClick={(e) => {
								setVersionsAnchorEl(e.currentTarget);
								setVersions(null);
								http.Get(
									`versions?object_id=${item.id}&model_name=credential`,
									(versions) => {
										setVersions(versions);
									},
									() => {
										setVersions(null);
									}
								);
							}}
						/>
					);
				});
				setEmployeeAccounts(data);
				setLoading(null);
			},
			() => {
				setLoading(null);
			}
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onSubmit = (e) => {
		const send = () => {
			setLoading(true);
			http.Post(
				`employee/credential/`,
				account,
				(account) => {
					fetchData();
					setAccount({});
					setErrors({});
					setOpenModal(false);
					setLoading(false);
				},
				(status, error) => {
					setErrors(error);
					setLoading(false);
				}
			);
		};

		account.employee = props.employeeId;
		validPassword(account).then(({ res, error }) => {
			if (res === true) {
				console.log("res supposed to be true", res);
				send();
			} else if (error) {
				console.log("res supposed to be false", res);
				// Flag password as unsafe for UI feedback
				account["flagged_pass"] = true;
				send();
			}
		});
	};

	const flashTooltip = () => {
		console.log("in function");
		setTempTooltip(true);
		setTimeout(function () {
			setTempTooltip(false);
		}, 1500);
	};

	const onChange = (e) => {
		if ("checkbox" === e.target.type || "radio" === e.target.type) {
			setAccount({
				...account,
				[e.target.name]: !account[e.target.name],
			});
		} else
			setAccount({
				...account,
				[e.target.name]: e.target.value,
			});
		setErrors({});
	};

	return (
		<div>
			{loading && <ScreenLoader />}

			{versionsAnchorEl && (
				<ObjectVersions
					{...props}
					versions={versions}
					fetchData={fetchData}
					anchorEl={versionsAnchorEl}
					setAnchorEl={setVersionsAnchorEl}
				/>
			)}

			{/* ----- Display employee credentials on DESKTOP ---- */}
			{!props.mobile && !loading && (
				<Table
					aria-label="simple table"
					className={classes.table}
					style={{ minHeight: 75 }}
				>
					<TableBody>
						{employeeAccounts.map((account) => {
							return (
								<EmployeeCredential
									{...props}
									categories={categories}
									account={account}
									fetchData={fetchData}
									setVersionsAnchorEl={setVersionsAnchorEl}
									setVersions={setVersions}
								/>
							);
						})}
						{/* ----- For ADDING new employee credential on DESKTOP ---- */}
						<TableRow className={classes.tableRow}>
							<TableCell className={classes.tableCell}>
								<Select
									id={"category"}
									name={"category"}
									value={account.category || "placeholder"}
									error={errors && errors.category}
									onChange={(e) => onChange(e)}
									input={<InputBase />}
									style={{
										// border: "0px",
										width: "195px",
										// borderBottom: "1px solid black",
										// outline: "none",
									}}
								>
									<MenuItem disabled value="placeholder">
										<span style={{ color: "#a2a2a2" }}>{EmployeesLang.ACCOUNTTYPE[lang]}</span>
									</MenuItem>
									{Object.entries(categories).map(([key, value]) => (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									))}
								</Select>
								{errors.category ? (
									<FormHelperText className={classes.error}>
										{errors.category}
									</FormHelperText>
								) : (
									<></>
								)}
							</TableCell>
							<TableCell>
								<InputCustom
									id="description"
									label={EmployeesLang.DESCRIPTION[lang]}
									fullWidth
									margin="dense"
									name="description"
									shrink={account.description ? true : false}
									value={account.description || ""}
									error={errors && errors.description}
									className={classes.input}
									onChange={(e) => onChange(e)}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											onSubmit();
										}
									}}
								/>
								{errors.description ? (
									<FormHelperText className={classes.error}>
										{errors.description}
									</FormHelperText>
								) : (
									<>&nbsp;</>
								)}
							</TableCell>
							<TableCell>
								<div></div>
							</TableCell>
							<TableCell>
								<FormControl>
									<InputCustom
										id="username"
										name="username"
										label={EmployeesLang.USERNAME[lang] + " *"}
										fullWidth
										margin="dense"
										value={account.username || ""}
										error={errors.username}
										onChange={(e) => {
											setAccount({
												...account,
												["username"]: e.target.value,
											});
											setErrors({});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												onSubmit();
											}
										}}
									/>
									{errors.username ? (
										<FormHelperText className={classes.error}>
											{errors.username}
										</FormHelperText>
									) : (
										<>&nbsp;</>
									)}
								</FormControl>
							</TableCell>
							<TableCell>
								<Tooltip
									arrow={true}
									title="Generate Password"
									placement="bottom-start"
								>
									<FingerprintIcon
										className={classes.FprintIconColorScheme}
										onClick={handleClickShowKeyGen}
									/>
								</Tooltip>
								<Popover
									open={open}
									anchorEl={anchorEl}
									onClose={handleCloseShowKeyGen}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
								>
									<KeyGen
										setPassword={(p) => {
											setAccount({
												...account,
												["password"]: p,
											});
											handleCloseShowKeyGen();
										}}
									/>
								</Popover>
							</TableCell>
							<TableCell>
								<FormControl>
									<InputCustom
										id="password"
										label={EmployeesLang.PASSWORD[lang]+" *"}
										fullWidth
										margin="dense"
										name="new-pass"
										value={account.password ? account.password : ""}
										error={errors.password}
										onChange={(e) => {
											setAccount({
												...account,
												["password"]: e.target.value,
											});
											setErrors({});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												onSubmit();
											}
										}}
									/>
									{errors.password ? (
										<FormHelperText className={classes.error}>
											{errors.password}
										</FormHelperText>
									) : (
										<>&nbsp;</>
									)}
								</FormControl>
							</TableCell>
							<TableCell>
								<FormControl>
									<InputCustom
										id="website"
										label={EmployeesLang.WEBSITE[lang]}
										fullWidth
										margin="dense"
										name="website"
										value={account.website || ""}
										error={errors.website}
										onChange={(e) => onChange(e)}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												onSubmit();
											}
										}}
									/>
									{errors.website ? (
										<FormHelperText className={classes.error}>
											{errors.website}
										</FormHelperText>
									) : (
										<>&nbsp;</>
									)}
								</FormControl>
							</TableCell>
							<TableCell>
								<FormControl>
									<InputCustom
										id="otp"
										label={EmployeesLang.otp[lang]}
										fullWidth
										margin="dense"
										name="otp"
										value={account.otp}
										error={errors.otp}
										onChange={(e) => onChange(e)}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												onSubmit();
											}
										}}
									/>
									{errors.otp ? (
										<FormHelperText className={classes.error}>
											{errors.otp}
										</FormHelperText>
									) : (
										<>&nbsp;</>
									)}
								</FormControl>
							</TableCell>
							<TableCell>
								<Tooltip
									arrow="true"
									title={EmployeesLang.AddNew[lang]}
									placement="top-start"
									enterDelay={200}
								>
									<IconButton size="small">
										<AddIcon onClick={onSubmit} className={classes.cursor} />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}

			{/* ----- Credentials pop up for ADDING NEW employee credentials on mobile ------ */}
			{props.mobile && !loading && (
				<>
					<Grid
						container
						spacing={1}
						style={{ justifyContent: "center", paddingTop: 8 }}
					>
						<Grid item xs={12}>
							<Chip
								label="ADD NEW CREDENTIAL"
								variant="outlined"
								style={{
									width: "-webkit-fill-available",
									height: 45,
									fontSize: "initial",
								}}
								onClick={() => setOpenModal(true)}
								className={classes.chipOutlined}
							/>
						</Grid>
						<br />
						{employeeAccounts.map((account) => {
							if (account.employee === props.employeeId) {
								return (
									<Grid item xs={12}>
										<EmployeeCredential
											{...props}
											categories={categories}
											account={account}
											fetchData={fetchData}
										/>
									</Grid>
								);
							}
						})}
					</Grid>

					<Dialog
						id={"hightop"}
						open={openModal}
						onClose={() => {
							setOpenModal(false);
							setErrors({});
							setAccount({});
						}}
						disablePortal
					>
						<Card style={{ width: 300 }}>
							<CardContent>
								<Grid container spacing={1} direction="column">
									<Grid item xs={8}>
										<Select
											id={"category"}
											fullWidth
											name={"category"}
											value={account.category || ""}
											onChange={() => {
												setErrors({});
												onChange();
											}}
											style={{
												border: "0px",
												borderBottom: "1px solid black",
												outline: "none",
											}}
											label={errors.category ? errors.category : ""}
											error={errors.category}
											placeholder={EmployeesLang.ACCOUNTTYPE[lang]}
										>
											<MenuItem selected disabled>
												{EmployeesLang.SelectAccountType[lang]}
											</MenuItem>
											{Object.entries(categories).map(([key, value]) => (
												<MenuItem key={key} value={key}>
													{value}
												</MenuItem>
											))}
										</Select>
										<p className={classes.error}>{errors.category}</p>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="description"
											fullWidth
											variant="outlined"
											margin="dense"
											name="description"
											value={account.description || ""}
											onChange={() => {
												setErrors({});
												onChange();
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											label={errors.description ? errors.description : ""}
											error={errors.description}
											placeholder={EmployeesLang.DESCRIPTION[lang]}
										/>
										{/* <p className={classes.error}>{errors.description}</p> */}
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											variant="outlined"
											margin="dense"
											name="new-name"
											autoComplete="new-name"
											value={account.username || ""}
											onChange={(e) => {
												setAccount({
													...account,
													["username"]: e.target.value,
												});
												setErrors({});
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											label={errors.username ? errors.username : ""}
											error={errors.username}
											placeholder={EmployeesLang.USERNAME[lang] + " *"}
										/>
										{/* <p className={classes.error}>{errors.username}</p> */}
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											variant="outlined"
											margin="dense"
											name="new-pass"
											autoComplete="new-pass"
											value={account.password || ""}
											InputProps={{
												endAdornment: (
													<FingerprintIcon
														className={classes.iconColorScheme}
														onClick={handleClickShowKeyGen}
													/>
												),
											}}
											onChange={(e) => {
												setAccount({
													...account,
													["password"]: e.target.value,
												});
												setErrors({});
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											label={errors.password ? errors.password : ""}
											error={errors.password}
											placeholder={EmployeesLang.PASSWORD[lang]+" *"}
											// style={{ fontFamily: "Raleway" }}
										/>
										{/* <p className={classes.error}>{errors.password}</p> */}
									</Grid>
									{/* ----- PASSWORD GEN. POPOVER ----- */}
									<Popover
										open={open}
										anchorEl={anchorEl}
										onClose={handleCloseShowKeyGen}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
									>
										<KeyGen
											setPassword={(p) => {
												setAccount({
													...account,
													["password"]: p,
												});
												handleCloseShowKeyGen();
												setErrors({});
											}}
										/>
									</Popover>
									<Grid item xs={12}>
										<TextField
											id="website"
											fullWidth
											variant="outlined"
											margin="dense"
											name="website"
											value={account.website || ""}
											onChange={() => {
												setErrors({});
												onChange();
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											label={errors.website ? errors.website : ""}
											error={errors.website}
											placeholder={EmployeesLang.WEBSITE[lang]}
										/>
										{/* <p className={classes.error}>{errors.website}</p> */}
									</Grid>
									<Grid container spacing={1} style={{ marginTop: 0 }}>
										<Grid item xs={6}>
											<Chip
												variant="outlined"
												onClick={() => {
													setOpenModal(false);
													setErrors({});
													setAccount({});
												}}
												label="Cancel"
												className={classes.blankChip}
											/>
										</Grid>
										<Grid item xs={6}>
											<Chip
												label="Save"
												onClick={onSubmit}
												className={classes.chip}
											/>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Dialog>
				</>
			)}
		</div>
	);
};

export default EmployeeCredentials;
