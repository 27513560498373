import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import AccountingForm from "./AccountingForm";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import {AccountingLang} from "../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  loadingBlock: {
    backgroundColor: "#DDDDDD",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    top: "0px",
    left: "0px",
    opacity: ".8",
    filter: "alpha(opacity=80)",
  },
  absoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Accounting = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const url = `company_payment_infos`;
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState();

  const lang = localStorage.getItem("languages");
  
  const today = new Date();
  
  const redirect = (props, object, property) => {
    props.history.push(
      process.env.PUBLIC_URL + "accounting/" + object.company_id + "/details"
    );
  };

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    additionalActions: [
      {
        label: AccountingLang.Report[lang],
        onClick:(ids)=>{props.history.push("/accountingReport");},
      },
    ],
  };

  const tableColumns = [
    {
      id: "isExpired",
      label: "",
      minWidth: 10,
    },
    {
      id: "company_name",
      label: AccountingLang.CUSTOMERNAME[lang],
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: redirect,
    },
    {
      id: "card_number",
      label: AccountingLang.CARDONFILE[lang],
      minWidth: 80,
      sortable: true,
    },
    {
      id: "expiration",
      label: "EXP(YY/MM)",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "payment_type",
      label: AccountingLang.PAYMENTTYPE[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "payment_frequency_type",
      label: AccountingLang.REPEAT[lang],
      minWidth: 80,
      sortable: true,
    },
    {
      id: "payment_charge_day",
      label: AccountingLang.ChargeOn[lang],
      minWidth: 80,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (items) => {
        items.map((item) => {
          
          if(item.card_number)item.card_number = "****" + item.card_number.slice(-4);
          var expYear = Math.floor(item.expiration/100);
          var expMonth = item.expiration%100;
          if(expMonth>9){
            item.expiration = expYear + "/" + expMonth;
          }
          else{
            item.expiration = expYear + "/0" + expMonth;
          }
          if ((2000+expYear)<today.getFullYear() || 
            ((2000+expYear)==today.getFullYear() && (expMonth-1)<today.getMonth())) {
            item.isExpired = <ClearIcon />;
          } else if ((2000+expYear)===today.getFullYear() && (expMonth-1)===today.getMonth()) {
            item.isExpired = <WarningIcon />;
          }

          let ordinal_indicator = "th";
          if(item.payment_charge_day === 1)ordinal_indicator="st";
          if(item.payment_charge_day === 2)ordinal_indicator="nd";
          if(item.payment_charge_day === 3)ordinal_indicator="rd";
          item["mobile_view"] = item.payment_frequency_type + " - On " + item.payment_charge_day 
            + ordinal_indicator;
        });
        setData(items);
        
        setLoadData(false);
      },
      () => {
        
        setLoadData(false);
      }
    );
    
  };

  return (
    <>
    {loading && (
      <div className={classes.loadingBlock}>
        <div className={classes.absoluteCenter}>
          <h1>{loading}</h1>
        </div>
      </div>
    )}
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={AccountingLang.Accounting[lang]}
      url={url}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["Accounting"],
        edit: ["Accounting"],
        delete: ["Accounting"],
      }}
      fetchData={fetchData}
      objectForm={AccountingForm}
      orderBy={"company_name"}
      searchFields={["company_name","payment_type"]}
      pageHeader={(props.user.company_name || "") + " | Accounting"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Accounting"}
      url={url}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["Accounting"],
        edit: ["Accounting"],
        delete: ["Accounting"],
      }}
      fetchData={fetchData}
      objectForm={AccountingForm}
      orderBy={"company_name"}
      searchFields={["company_name","payment_type"]}
      pageHeader={(props.user.company_name || "") + " | Accounting"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Accounting;
