import React, { useState, useEffect } from "react";
import DocumentForm from "./DocumentForm";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../Common/Style/FormStyle";
import IconButton from "@material-ui/core/IconButton";
import { Divider, Radio, RadioGroup, Typography, Paper } from "@material-ui/core";
import FileViewer from "../../Common/FileViewer";
import { Dialog } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { formatDateTime } from "../../Common/Utility";
import DataMappings from "../DataMappings";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const DocumentDetails = (props) => {
  const classes = useStyles();
  var { documentId } = props.match.params;
  const [content, setContent] = useState();
  const [filePath, setFilePath] = useState();
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();
	const [dataMappings, setDataMappings] = useState([]);
  
  useEffect(() => {
    if(props.objectId){
      documentId = props.objectId;
    }
    http.Get(
      `company_documents/${documentId}`,
      (document) => {
        http.Get(
          `company_document_files/?company_document=${document.id}`,
          (data) => {
            document["files"] = data;    
            http.Get(
              "company_document_versions/?document_id=" + document.id,
              (data) => {
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  data[i].serialized_data = JSON.parse(data[i].serialized_data);
                  valueMap[data[i].serialized_data[0].fields.content] =
                    formatDateTime(data[i].serialized_data[0].fields.last_modified_time);
                }
                document["versions"] = valueMap;
                setContent(document);
                http.Get(
                  `data_mappings?from_id=${document.id}`,
                  (data) => {
                    setDataMappings(data);
                  },
                  () => {}
                );
              },
              () => {}
            );    
          },
          () => {
          }
        );
      },
      () => {}
    );
  }, []);

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <div>
      {filePath &&
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setFilePath(null);
          }}>
          <FileViewer
            fileType={filePath.fileType.toLowerCase()}
            filePath={filePath.filePath}
            onError={() => {
              setFilePath(null);
            }}
          />
        </Dialog>
      }
      {content && (
        <div>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            disableEnforceFocus
            classes={{
              paper: classes.drawerForm,
            }}
          >
            <div style={{ width: width }}>
              <table>
                <tbody>
                  <tr>
                    <td
                      draggable
                      style={{ cursor: "ew-resize" }}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      <DragIndicatorIcon />
                    </td>
                    <td width="100%">
                      <DocumentForm
                        {...props}
                        closeOpen={(open) => setOpen(open)}
                        objectId={content.id}
                        handleObjectSubmit={handleObjectSubmit}
                        template={{data:''}}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Drawer>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}} classes={{ h6: classes.title }}>
              {content.title}
            </Typography>
            <span className={classes.subText} >{formatDateTime(content.last_modified_time)} by {content.last_modified_by_name}</span>
          </Grid>
          <Grid item xs={2}>
            <div style={{paddingTop:10,float:"right"}}>
              {!props.objectId && props.user.role.docs_modify &&
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                      setOpen(true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              }
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                    var divToPDF = document.getElementById('divToPDF');
                    html2canvas(divToPDF)
                      .then((canvas) => {
                        var imgData = canvas.toDataURL('image/png');
                        var imgWidth = 210; 
                        var pageHeight = 292;
                        var imgHeight = canvas.height * imgWidth / canvas.width;
                        var heightLeft = imgHeight;
                        var doc = new jsPDF('p', 'mm');
                        var position = 0;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                        
                        while (heightLeft >= 0) {
                          position = heightLeft - imgHeight;
                          doc.addPage();
                          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                          heightLeft -= pageHeight;
                        }
                        doc.save(content.id+".pdf");
                      })
                    ;
                  }}
                >
                  <SaveAltIcon />
                </IconButton>
              
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    if(props.onClose){
                      props.onClose();
                    }
                    else{
                      props.history.push(
                        process.env.PUBLIC_URL + `/portal/documents`
                      );
                    }
                  }}
                >
                  <HighlightOffIcon className={classes.iconButtonColored} />
                </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>   
            <Divider className={classes.divider}/>
          </Grid>
          <Grid item xs={10}>
                <Paper>                  
                {content && !content.content && content.files && content.files.length === 1 &&
                  <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                    minHeight: "calc(100vh - 280px)",
                    overflowY: "scroll",
                    overflowX: "auto",  
                    marginLeft:10}}
                  >
                    {["pdf","doc","docx","xlsx","csv"].includes(content.files[0].name.split('.').pop().toLowerCase()) &&
                      <FileViewer
                        fileType={content.files[0].name.split('.').pop().toLowerCase()}
                        filePath={content.files[0].file_src}/>
                    }
                    {["png","jpg"].includes(content.files[0].name.split('.').pop().toLowerCase()) &&                      
                      <img src={content.files[0].file_src} style={{ maxWidth: "100%" }} />
                    }
                  </div>
                }                
                {!(content && !content.content && content.files && content.files.length === 1) &&
                  <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                    minHeight: "calc(100vh - 280px)",
                    overflowY: "scroll",
                    overflowX: "auto",  
                    marginLeft:10}}
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  />
                }
                </Paper>
                <div id="divToPDF"
                  style={{ position:"absolute",
                    left:"-8000px",
                    width:"780px",
                    padding:"30px"}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
                <Divider/>
          </Grid>
          <Grid item xs={2}>
                <Paper>
                <div
                  style={{ maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "scroll",
                  overflowX: "auto", 
                  marginLeft:10}}
                >

                <p style={{fontWeight:"bold"}}>Attachments:</p>
                  {content.files && content.files.map((file) => (
                    <p key={file.id}>
                      <span 
                        className={`${classes.link} ${classes.spacingLH}`} 
                        onClick={()=>{
                          if(file.file_src){
                            setFilePath({filePath:file.file_src,fileType:file.name.split('.').pop()});
                          }
                          else{
                            alert("File Not Found!");
                          }
                        }}>
                        {file.name}
                      </span>
                      <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                          if(file.file_src){
                            var a = window.document.createElement("a");
                            a.href = file.file_src;
                            a.download = file.name;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                          }
                          else{
                            alert("File Not Found!");
                          }
                        }}
                      >
                        <SaveAltIcon/>
                      </IconButton>
                    </p>
                  ))}
                <br/>
                <p style={{fontWeight:"bold"}}>Related Entries:</p>
                <DataMappings 
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
                <br/>
                <p style={{fontWeight:"bold"}}>Versions:</p>
                  <RadioGroup>
                    {content.versions && Object.entries(content.versions).map(([key, value]) => (
                      <span>
                        <Radio key={key} value={value + key} onClick={() => {setContent({...content,"content":key});}}/>{value}
                      </span>
                    ))}
                  </RadioGroup>
              </div>
              </Paper>
          </Grid>
        </Grid>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(DocumentDetails);
