import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import ScreenLoader from "../../Common/Loader";

const BillingForm = props => {
  const [formFields, setFormFields] = useState();
  const [resellers, setResellers] = useState();
  const [loadData, setLoadData] = useState(false);
  
  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `reseller_payment_infos/${props.objectId}`,
        (account) => {
          http.Get(
            `reseller_payment_infos`,
            (data) => {
              var paymentInfos = {};
              for (let i = 0; i < data.length; i++) {
                if(data[i].reseller !== account.reseller){
                  paymentInfos[data[i].reseller] = data[i].name;
                }          
              }
              http.Get(
                `resellers`,
                (data) => {
                  var valueMap = {};
                  for (let i = 0; i < data.length; i++) {
                    if (!paymentInfos[data[i].id]) {
                      valueMap[data[i].id] = data[i].name;
                    }
                  }
                  setResellers(valueMap);
                  setLoadData(false);
                },
                () => {
                  setLoadData(false);
                }
              );
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    }
    else{      
      setLoadData(false);
      http.Get(
        `reseller_payment_infos`,
        (data) => {
          var paymentInfos = {};
          for (let i = 0; i < data.length; i++) {
            paymentInfos[data[i].reseller] = data[i].reseller_name;
          }
          http.Get(
            `resellers`,
            (data) => {
              var valueMap = {};
              for (let i = 0; i < data.length; i++) {
                if (!paymentInfos[data[i].id]) {
                  valueMap[data[i].id] = data[i].name;
                }
              }
              setResellers(valueMap);
              setLoadData(false);
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if(resellers){
      setFormFields([
        {
          id: "reseller",
          label: "RESELLER *",
          type: "select",
          valueMap: resellers,
        },
        {
          id: "vault_id",
          label: "vault id",
          type: "text"
        },
        {
          id: "card_number",
          label: "card number",
          type: "text"
        },
        {
          id: "expiration",
          label: "expiration (YYMM)",
          type: "text"
        },
        {
          id: "unused_credit",
          label: "unused credit",
          type: "text"
        },
        {
          id: "payment_type",
          label: "payment type",
          type: "select",
          valueMap: {"Credit Card":"Credit Card","Cash":"Cash"},
        },
      ]);
    }
  }, [resellers]);

  return (
    <div>
      {loadData && (
        <ScreenLoader />
      )}
      {!loadData && formFields && (
        <ObjectForm
          {...props}
          url={`reseller_payment_infos`}
          formName={"Payment Info"}
          formFields={formFields}
          object={{payment_type:"Cash",expiration:9999}}
        />
      )}
    </div>
  );
};

export default BillingForm;
