import React, { useEffect, useState } from "react";
import SiteForm from "./SiteForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick } from "../../Common/TableUtility";
import { Dialog } from "@material-ui/core";
import SiteDetails from "./SiteDetails";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  }
}));

const Site = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [objectId, setObjectId] = useState();

  const url = `companies/${props.user.company_id}/sites`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
		var showId = new URLSearchParams(window.location.search).get("show_id");
		if(showId){
			setObjectId(showId);
			props.history.replace({ search: window.location.search.replace("&show_id="+showId,"")});
		}
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.head_office = item.is_head_office ? "HEAD OFFICE" : ""),
            (item.mobile_view = item.address),
            (item.mobile_view_button = item.primary_contact_number ? (
              <IconButton
                disableTouchRipple
                className={classes.button}
                edge="start"
                href={`tel://${item.primary_contact_number}`}
              >
                <PhoneIcon fontSize="small" />
              </IconButton>
            ) : "")
          )
        );
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  const tableColumns = [
    {
      id: "site_name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : (props, object, property)=>{
        setObjectId(object.id);
      },
    },
    {
      id: "primary_contact_number",
      label: "PRIMARY CONTACT NUMBER",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "primary_contact",
      label: "PRIMARY CONTACT",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "address",
      label: "ADDRESS",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "website",
      label: "WEBSITE",
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: onWebsiteClick,
    },
    {
      id: "head_office",
      label: "",
      minWidth: 150,
      style: commonConstants.smallFont,
      sortable: false,
    },
  ];

  return (
    <>
    {objectId &&
      <Dialog
        fullWidth
        maxWidth="lg"
        open={Boolean(objectId)}
        onClose={()=>{setObjectId(null);}}>
        <div style={{padding:10}}>
          <SiteDetails 
            {...props} 
            objectId={objectId} 
            handleClose={()=>{
              setObjectId(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}
            onClose={()=>{
              setObjectId(null);
            }}
          />
        </div>
      </Dialog>
    }
    {!props.mobile && 
      <ObjectList
        {...props}
        moduleName={"Sites List"}
        url={url}
        tableColumns={tableColumns}
        data={data}
        actions={actions}
        portalPermissions={{
          create: props.user.role.sites_create,
          edit: props.user.role.sites_modify,
          delete: props.user.role.sites_delete,
        }}
        fetchData={fetchData}
        objectForm={SiteForm}
        orderBy={"site_name"}
        searchFields={["site_name"]}
        pageHeader={(props.company.company_name || "") + " | Sites"}
        loadData={loadData}
      />
    }
    {props.mobile && 
      <ObjectListMobile
        {...props}
        moduleName={"Sites List"}
        url={url}
        tableColumns={tableColumns}
        data={data}
        actions={actions}
        portalPermissions={{
          create: props.user.role.sites_create,
          edit: props.user.role.sites_modify,
          delete: props.user.role.sites_delete,
        }}
        fetchData={fetchData}
        objectForm={SiteForm}
        orderBy={"site_name"}
        searchFields={["site_name"]}
        pageHeader={(props.company.company_name || "") + " | Sites"}
        loadData={loadData}
      />
    }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Site);
