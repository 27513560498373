import React, { useEffect, useState } from "react";
import PackageForm from "./PackageForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "reseller_name",
    label: "RESELLER",
    sortable: true,
  },
  {
    id: "package_name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "reseller_monthly_price",
    label: "MONTHLY COST",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "reseller_annual_price",
    label: "ANNUAL COST",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "reseller_portal_addon_price",
    label: "PORTAL ADDON COST",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "reseller_user_addon_price",
    label: "READONLY ADDON COST",
    minWidth: 150,
    sortable: true,
  },
];

const Packages = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `packages`;
  const actions = {
    delete: true,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Packages"}
      url={url}
      actions={actions}
      objectForm={PackageForm}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Packages"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Packages"}
      url={url}
      actions={actions}
      objectForm={PackageForm}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Packages"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Packages;
