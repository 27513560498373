import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {VendorsLang} from "../../Common/Languages";

const SupportForm = (props) => {
  const classes = useStyles();
  const [support, setSupport] = useState({ tenant: props.user.tenant });
  const [supportError, setSupportError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const { requiredError, blankError, websitePlaceholder } =
    commonConstants.error;

  const lang = localStorage.getItem("languages");

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `supports/${props.objectId}`,
        (support) => {
          setSupport(support);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  const onChange = (e) => {
    setSupport({
      ...support,
      [e.target.name]: e.target.value,
    });
    setSupportError({
      ...supportError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    setIsSubmitted(true);

    if (props.edit && props.objectId) {
      http.Put(
        `supports/${props.objectId}`,
        support,
        () => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setSupportError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `supports`,
        support,
        () => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setSupportError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
            >
              {props.edit ? VendorsLang.EditVendor[lang] : VendorsLang.AddNewVendor[lang]}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>{VendorsLang.COMPANYNAME[lang]} *</p>
              <TextValidator
                id="company_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="company_name"
                value={support.company_name || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(supportError.company_name)}
                helperText={
                  supportError.company_name && supportError.company_name[0]
                }
              />
              <p className={classes.label}>{VendorsLang.PHONE[lang]} *</p>
              <TextValidator
                id="contact_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="contact_number"
                value={support.contact_number || ""}
                onChange={onChange}
                inputProps={{ maxLength: 50 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(supportError.contact_number)}
                helperText={
                  supportError.contact_number && supportError.contact_number[0]
                }
              />
              <p className={classes.label}>{VendorsLang.HOURSOFOPERATION[lang]} *</p>
              <TextValidator
                id="hours_of_operation"
                fullWidth
                variant="outlined"
                margin="dense"
                name="hours_of_operation"
                value={support.hours_of_operation || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(supportError.hours_of_operation)}
                helperText={
                  supportError.hours_of_operation &&
                  supportError.hours_of_operation[0]
                }
              />
              <p className={classes.label}>{VendorsLang.WEBSITE[lang]} *</p>
              <TextValidator
                id="website"
                fullWidth
                variant="outlined"
                margin="dense"
                name="website"
                value={support.website || ""}
                placeholder={websitePlaceholder}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(supportError.website)}
                helperText={supportError.website && supportError.website[0]}
              />
              <p className={classes.label}>{VendorsLang.NOTES[lang]}</p>
              <TextValidator
                id="notes"
                fullWidth
                variant="outlined"
                margin="dense"
                name="notes"
                value={support.notes}
                onChange={onChange}
                error={Boolean(supportError.notes)}
                helperText={supportError.notes && supportError.notes[0]}
              />
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              {VendorsLang.Cancel[lang]}
            </Button>
            <Button
              className={classes.filledButton}
              variant="outlined"
              margin="dense"
              size="small"
              type="submit"
              disabled={isSubmitted}
            >
              {VendorsLang.Save[lang]}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default SupportForm;
