import React, { useEffect, useState } from "react";
import ObjectList from "../Common/ObjectList";
import ObjectListMobile from "../Common/ObjectListMobile";
import http from "../Common/RestAPIHandler";
import Button from "@material-ui/core/Button";
import SnackbarComponent from "../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import ObjectVersions from "../Common/ObjectVersions";
import { connect } from "react-redux";
import commonConstants from "../Common/CommonConstants";
import HistoryIcon from '@material-ui/icons/History';
import { ArchiveLang } from "../Common/Languages";
const lang = localStorage.getItem("languages");
const useStyles = makeStyles((theme) => ({
    button: {
      margin: 10,
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        border: "1px solid white",
      },
    },
    removeButton: {
      margin: 10,
      borderRadius: 25,
      backgroundColor: "red",
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
        border: "1px solid white",
      },
    },
  }));
  
  
const tableColumns = [
  {
    id: "model",
    label: ArchiveLang.MODEL[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "name",
    label: ArchiveLang.NAME[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "last_modified_by_name",
    label: ArchiveLang.LASTMODIFIEDBY[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "deleted_at",
    label: ArchiveLang.LASTMODIFIEDAT[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "versions",
    label: " ",
    minWidth: 10,
    disableTooltip: true,
    style: commonConstants.cellCursor,
    colored: true,
  },
  {
    id: "actions",
    label: " ",
    minWidth: 200,
    disableTooltip: true,
  },
];

const Archives = (props) => {
  const classes = useStyles();  
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `companies/${id}/archives`;
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [versions, setVersions] = useState();
    
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    let packageLimit = {
      companydocument: props.companyTenant.max_company_document,
      companyemployee: props.companyTenant.max_company_employee,
      companyservice: props.companyTenant.max_company_other_service,
      device: props.companyTenant.max_company_device,
      site: props.companyTenant.max_company_site,
      softwarelicense: props.companyTenant.max_company_license,
    };

    let unarchivedDataCount = {};
    http.Get(
      `company_documents?company=${id}`,
      (data) => {unarchivedDataCount['companydocument'] = data.length},
      () => {unarchivedDataCount['companydocument'] = 0}
    );
    http.Get(
      `company_employees?company_id=${id}`,
      (data) => {unarchivedDataCount['companyemployee'] = data.length},
      () => {unarchivedDataCount['companyemployee'] = 0}
    );
    http.Get(
      `companies/${id}/services`,
      (data) => {unarchivedDataCount['companyservice'] = data.length},
      () => {unarchivedDataCount['companyservice'] = 0}
    );
    http.Get(
      `companies/${id}/devices`,
      (data) => {unarchivedDataCount['device'] = data.length},
      () => {unarchivedDataCount['device'] = 0}
    );
    http.Get(
      `companies/${id}/sites`,
      (data) => {unarchivedDataCount['site'] = data.length},
      () => {unarchivedDataCount['site'] = 0}
    );
    http.Get(
      `software_licenses/?company=${id}`,
      (data) => {unarchivedDataCount['softwarelicense'] = data.length},
      () => {unarchivedDataCount['softwarelicense'] = 0}
    );

    http.Get(
      url,
      (data) => {
        data.map((archive)=>{
          try {
            archive["deleted_at"] = archive["last_modified_time"].split("T")[0];
          }
          catch(err) {
            archive["deleted_at"] = null;
          }
          if(props.companyPermission && props.companyPermission === "readAndWrite"){
            archive.actions = <div>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.removeButton} 
                  onClick={()=>{
                    if(window.confirm(ArchiveLang.helper[lang])){
                      http.Delete(
                        url,
                        archive,
                        (res) => {
                          setSnackBarMessage(res.message);
                          fetchData();
                        },
                        () => {}
                      );
                    }
                }}>{ArchiveLang.Remove[lang]}</Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.button} 
                  onClick={ () => {
                    if (archive.model === 'credential' ||
                        archive.model === 'devicecredential' ||
                        packageLimit[archive.model] < 0 || 
                        unarchivedDataCount[archive.model] < packageLimit[archive.model]) {
                      http.Put(
                          url,
                          archive,
                          (res) => {
                            setSnackBarMessage(res.message);
                            fetchData();
                          },
                          () => {}
                        );
                    }
                    else {
                      console.log(archive.model)
                      setSeverity("error");
                      setSnackBarMessage(commonConstants.error.packageLimitReached);
                    }
                  }}>{ArchiveLang.Restore[lang]}</Button></div>;

          }

          archive['versions'] = <HistoryIcon
              onClick={(e)=>{
                  setAnchorEl(e.currentTarget);
                  setVersions(null);
                  http.Get(
                    `versions?object_id=${archive.id}&model_name=${archive.model}`,
                    (versions) => {
                      setVersions(versions);
                    },
                    () => {
                      setVersions(null);
                    }
                  );
                  }}/>;
                  //console.log(archive);
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
    <SnackbarComponent
      message={snackBarMessage}
      open={Boolean(snackBarMessage)}
      handleSnackBarClose={()=>{
        setSnackBarMessage(null);
        setSeverity("success");
      }}
      severity={severity}
    ></SnackbarComponent>
    {anchorEl &&
    <ObjectVersions
      {...props}
      versions={versions}
      fetchData={fetchData}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    />}
    {!props.mobile &&
    <ObjectList
      {...props}
      moduleName={ArchiveLang.Archives[lang]}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"deleted_at"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Archives"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile &&
    <ObjectListMobile
      {...props}
      moduleName={"Archives"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"last_modified_time"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Archives"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Archives);
