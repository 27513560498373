import React, { useEffect, useState } from "react";
import DeviceForm from "./DeviceForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onCopy } from "../../Common/TableUtility";
import { format } from "date-fns-tz";
import { ConvertDateToLocale } from "../../Common/Utility";
import { Dialog, Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import SnackbarComponent from "../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { parse, unparse } from "../../Common/CSVImportExportUtility";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ObjectVersions from "../../Common/ObjectVersions";
import DeviceCredentials from "./DeviceCredentials";
import HistoryIcon from "@material-ui/icons/History";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DeviceDetails from "./DeviceDetails";
import { DeviceLang ,EmployeesLang} from "../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
    width: 15,
    verticalAlign: "bottom",
  },
  errorIconColorScheme: {
    color: "#f44336",
    width: 15,
  },
  floatRight: {
    float: "right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    // color: theme.colorScheme,
  },
}));

const Devices = (props) => {
  const selectedSite = localStorage.getItem("Site");
  const classes = useStyles();
  const { id } = props.match.params;
  const [activeIds, setActiveIds] = useState([]);
  const [sites, setSites] = useState();
  const [categories, setCategories] = useState();
  const [site, setSite] = useState(selectedSite);
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [unchangedData, setUnchangedData] = useState([]);
  const [device, setDevice] = useState();
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const url = `companies/${id}/devices`;
  const csvHeader = commonConstants.deviceHeader;
  const exportFormat = commonConstants.deviceExportFormat;
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [versions, setVersions] = useState();
  const lang = localStorage.getItem("languages");

  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const { csvEmptyError, csvFormatError, csvFormatMismatchError } =
  commonConstants.error;

  
  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
    importCSV:
      props.companyPermission && props.companyPermission === "readAndWrite",
    exportCSV: true,
    exportPDF: false,
    formatCredentials: {
      onClick: () => {
        unparse(commonConstants.deviceCredentialHeader, [], "Device Credentials");
      },
      label: EmployeesLang.DownloadCredentialsFormat[lang],
    },
    importCredentials: {
      onClick: () => {
        var input = document.createElement("input");
        input.type = "file";

        input.onchange = (e) => {
          var file = e.target.files[0];
          var fileName = file.name && file.name.split(".");
          if (fileName[fileName.length - 1].toLowerCase() !== "csv") {
            handleSnackBar(csvFormatError, "error");
            return;
          }
          parse(
            file,
            commonConstants.deviceCredentialHeader,
            null,
            `device_credentials/${id}/import_credentials`,
            "Credential",
            null,
            null,
            () => handleSnackBar(csvEmptyError, "error"),
            () => handleSnackBar(csvFormatMismatchError, "error"),
            (data) => {
              handleSnackBar(
                `Success: ${data.success}, Failed: ${data.failed}`,
                "success"
              );
              props.fetchData();
            },
            (data) => {
              handleSnackBar(
                `Success: ${data.success}, Failed: ${data.failed}`,
                "warning"
              );
              props.fetchData();
            },
            (error) => {
              handleSnackBar(
                `Success: ${error.success}, Failed: ${error.failed}`,
                "error"
              );
            },
            null
          );
        };
        input.click();
        setActiveIds([]);
      },
      label: EmployeesLang.ImportCredentials[lang],
    },
    exportAllCredentials: {
      onClick: () => {
        http.Get(
          `device/credential/?company_id=${id}`,
          (data) => {
            var exportData = data.map((item) => {
              return {
                device: item.device_name,
                category: item.category_name,
                username: item.username,
                password: item.password,
                description: item.description,
                website: item.website,
              };
            });
            unparse([], exportData, "Exported All Devices' Credentials");
          },
          () => {}
        );
      },
      label: EmployeesLang.ExportAllCredentials[lang],
    },
    exportSelectedCredentials: {
      onClick: () => {
        http.Get(
          `device/credential/?devices=${activeIds}`,
          (data) => {
            var exportData = data.map((item) => {
              return {
                device: item.device_name,
                category: item.category_name,
                username: item.username,
                password: item.password,
                description: item.description,
                website: item.website,
              };
            });
            unparse([], exportData, "Exported Showed Devices' Credentials");
          },
          () => {}
        );
      },
      label: EmployeesLang.ExportShowedCredentials[lang],
    },
  };
  const [objectId, setObjectId] = useState();

  const toggleActiveIds = (props, object, property) => {
    if (activeIds.includes(object.id)) {
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== object.id;
        })
      );
      object["indicator"] = <ExpandMoreIcon />;
    } else {
      var ids = [].concat(activeIds);
      ids.push(object.id);
      setActiveIds(ids);
      object["indicator"] = <ExpandLessIcon />;
    }
  };

  const tableColumns = [
    {
      id: "indicator",
      label: "",
      minWidth: 10,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
      onClick:
        props.companyPermission && props.companyPermission === "readAndWrite"
          ? toggleActiveIds
          : (props, object, property) => {
              setObjectId(object.id);
            },
    },
    {
      id: "device_name",
      label: DeviceLang.DEVICENAME[lang],
      minWidth: 130,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "infoCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "info",
      label: DeviceLang.INFO[lang],
      minWidth: 150,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "usernameCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "username",
      label: DeviceLang.USERNAME[lang],
      minWidth: 100,
      sortable: true,
    },
    {
      id: "passwordCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "staredPassword",
      label: DeviceLang.PASSWORD[lang],
      minWidth: 100,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "notes",
      label: DeviceLang.NOTES[lang],
      minWidth: 150,
      sortable: false,
    },
    {
      id: "versions",
      label: " ",
      minWidth: 10,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
  ];

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }

    http.Get(
      `companies/${id}/sites`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].site_name;
        }
        setSites(valueMap);
      },
      () => {}
    );

    http.Get(
      `categories?category_type=Credential Type`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (sites && categories) {
      fetchData();
    }
  }, [sites, categories]);

  useEffect(() => {
    var dataList = [];
    data.map((device) => {
      if (
        site === "All" ||
        device.sites.includes(site) ||
        device.location_id === site ||
        !device.location_id
      ) {
        dataList.push(device);
      }
    });
    setShowData(dataList);
  }, [data, site]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );

        data.map((item) => {
          if(item["url"]){ 
          item["infoCopy"] = (
            <FilterNoneIcon
              fontSize="small"
              className={classes.iconColorScheme}
              onClick={(props, row, columnId) => {
                onCopy(props, item, "url");
                setSnackBarMessage(DeviceLang.URLCopied[lang]);
              }}
            />
          );
            }
          item["info"] = (
            <React.Fragment>
              {item["category_name"]}
              <br />
              {item["description"]}
              <br />
              <span>
                {/* {item["url"] && (
									<> */}
                {/* <FilterNoneIcon
											fontSize="small"
											className={classes.iconColorScheme}
											onClick={(props, row, columnId) => {
												onCopy(props, item, "url");
												setSnackBarMessage("URL Copied");
											}}
										/> */}
                {item["url"]}
                {/* </>
								)} */}
              </span>
              <br />
              {item["site_name"]}
              <br />
              {item["tag_number"]}
              <br />
            </React.Fragment>
          );
          if(item["username"]){      
          item["usernameCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={`${classes.iconColorScheme}`}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "username");
                  setSnackBarMessage(DeviceLang.UsernameCopied[lang]);
                }}
              />
            </>
          );}

          
          if(item["password"]){
            item["passwordCopy"] = (
              <>
                <FilterNoneIcon
                  fontSize="small"
                  className={`${classes.iconColorScheme}`}
                  onClick={(props, row, columnId) => {
                    onCopy(props, item, "password");
                    setSnackBarMessage(DeviceLang.PasswordCopied[lang]);
                  }}
                />
              </>
            );

          item["staredPassword"] = (
            <>
              <span
                className={classes.passwordColorScheme}
                onClick={(e) => {
                  if (e.target.innerHTML === "············")
                    e.target.innerHTML = String(item["password"])
                      .replaceAll("<", "&lt;")
                      .replaceAll(">", "&gt;");
                  else e.target.innerHTML = "············";
                }}
              >
                ············
              </span>{" "}
              &nbsp;
              {item["flagged_pass"] ? (
                <Tooltip
                  interactive
                  arrow={true}
                  placement="top-start"
                  title="Unsafe Password!"
                >
                  <ErrorOutlineIcon className={classes.errorIconColorScheme} />
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );}
          else{
            item["staredPassword"] = ""
          }

          item["versions"] = (
            <HistoryIcon
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setVersions(null);
                http.Get(
                  `versions?object_id=${item.id}&model_name=device`,
                  (versions) => {
                    setVersions(versions);
                  },
                  () => {
                    setVersions(null);
                  }
                );
              }}
            />
          );

          item["subData"] = (
            <DeviceCredentials
              {...props}
              categories={categories}
              deviceId={item["id"]}
              fetchData={fetchData}
            />
          );

          item["indicator"] = <ExpandMoreIcon />;

          item["mobile_view"] = item["url"];
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <DeviceDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
            />
          </div>
        </Dialog>
      )}
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
      ></SnackbarComponent>
      {anchorEl && (
        <ObjectVersions
          {...props}
          versions={versions}
          fetchData={fetchData}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {device && (
        <Dialog
          open={true}
          onClose={() => {
            setDevice(null);
          }}
        >
          <div style={{ margin: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>DEVICE DETAILS</h3>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                DEVICE NAME
              </Grid>
              <Grid item xs={9}>
                {device.device_name}
              </Grid>
              <Grid item xs={3}>
                DEVICE TYPE
              </Grid>
              <Grid item xs={9}>
                {device.category_name}
              </Grid>
              <Grid item xs={3}>
                DESCRIPTION
              </Grid>
              <Grid item xs={9}>
                {device.description}
              </Grid>
              <Grid item xs={3}>
                IP ADDRESS/URL
              </Grid>
              <Grid item xs={9}>
                {device.url}
              </Grid>
              <Grid item xs={3}>
                USERNAME
              </Grid>
              <Grid item xs={9}>
                {device.username}
              </Grid>
              <Grid item xs={3}>
                PASSWORD
              </Grid>
              <Grid item xs={9}>
                {device.password}
              </Grid>
              <Grid item xs={3}>
                LOCATION
              </Grid>
              <Grid item xs={9}>
                {device.site_name}
              </Grid>
              <Grid item xs={3}>
                NOTES
              </Grid>
              <Grid item xs={9}>
                {device.notes}
              </Grid>
              <Grid item xs={3}>
                ASSET TAG NUMBER
              </Grid>
              <Grid item xs={9}>
                {device.tag_number}
              </Grid>
            </Grid>
          </div>
        </Dialog>
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={DeviceLang.ServersandDevices[lang]}
          url={url}
          importUrl={`companies/${id}/import_devices`}
          csvHeader={csvHeader}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_device > -1 &&
              unchangedData.length >= props.companyTenant.max_company_device)
              ? null
              : DeviceForm
          }
          orderBy={"device_name"}
          searchFields={["device_name", "url", "description"]}
          pageHeader={
            (props.company.company_name || "") + " | Servers and Devices"
          }
          loadData={loadData}
          packageLimit={props.companyTenant.max_company_device}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Servers and Devices"}
          url={url}
          importUrl={`companies/${id}/import_devices`}
          csvHeader={csvHeader}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_device > -1 &&
              unchangedData.length >= props.companyTenant.max_company_device)
              ? null
              : DeviceForm
          }
          orderBy={"device_name"}
          searchFields={["device_name", "url", "description"]}
          pageHeader={
            (props.company.company_name || "") + " | Servers and Devices"
          }
          loadData={loadData}
          packageLimit={props.companyTenant.max_company_device}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Devices);
