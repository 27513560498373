import React, { useEffect, useState } from "react";
import JsonDataForm from "./JsonDataForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import CopyIcon from "@material-ui/icons/FileCopy";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarComponent from "../../Common/Snackbar";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import commonConstants from "../../Common/CommonConstants";
import CheckIcon from "@material-ui/icons/Check";
import { Dialog } from "@material-ui/core";
import JsonDataDetails from "./JsonDataDetails";
import HistoryIcon from "@material-ui/icons/History";
import ObjectVersions from "../../Common/ObjectVersions";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    float: "right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    fontFamily: "Raleway",
  },
}));

const JsonDatas = (props) => {
  const classes = useStyles();
  const { id, jsonSchemaId } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `json_datas?company=${id}&json_schema=${jsonSchemaId}`;
  const [jsonSchema, setJsonSchema] = useState();
  const [tableColumns, setTableColumns] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [searchFields, setSearchFields] = useState();
  const [objectId, setObjectId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [versions, setVersions] = useState();

  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
  };

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }
  }, []);

  useEffect(() => {
    if (jsonSchemaId && props.companyTenant.id) {
      http.Get(
        `json_schemas/${jsonSchemaId}?tenant=${props.companyTenant.id}`,
        (data) => {
          var columns = [];
          var fields = [];
          var skipColumns = 0;
          var jsonSchema = JSON.parse(data.json_schema);
          if(jsonSchema.properties){
            Object.entries(jsonSchema.properties).map(([key, property]) => {
              if (property.listed) {
                if (property.copyable) {
                  columns.push({
                    id: "copy" + key,
                    label: "",
                    minWidth: 20,
                    sortable: false,
                    disableTooltip: true,
                  });
                  skipColumns = skipColumns + 1;
                }

                if (property.searchable) {
                  fields.push(key);
                }

                if (property.type === "password") {
                  columns.push({
                    id: "stared" + key,
                    label: key.toUpperCase(),
                    minWidth: 20,
                    sortable: false,
                    disableTooltip: true,
                  });
                  skipColumns = skipColumns + 1;
                } else if (property.type === "url") {
                  columns.push({
                    id: key,
                    label: key.toUpperCase(),
                    minWidth: 20,
                    sortable: false,
                    onClick: onWebsiteClick,
                    style: commonConstants.cellCursor,
                    colored: true,
                  });
                  skipColumns = skipColumns + 1;
                } else {
                  if (columns.length > skipColumns) {
                    columns.push({
                      id: key,
                      label: key.toUpperCase(),
                      minWidth: 200,
                      sortable: true,
                    });
                  } else {
                    columns.push({
                      id: key,
                      label: key.toUpperCase(),
                      minWidth: 200,
                      sortable: true,
                      colored: true,
                      style: commonConstants.cellCursor,
                      onClick: props.mobile
                        ? null
                        : (props, object, property) => {
                            setObjectId(object.id);
                          },
                    });
                  }
                }
              }
            });
          }
          columns.push(
            {
              id: "versions",
              label: " ",
              minWidth: 10,
              disableTooltip: true,
              style: commonConstants.cellCursor,
              colored: true,
            },
          );
          setTableColumns(columns);
          setSearchFields(fields);
          setJsonSchema(data);
        },
        () => {}
      );
    }
  }, [jsonSchemaId, props.companyTenant.id]);

  useEffect(() => {
    if (jsonSchema) {
      fetchData();
    }
  }, [jsonSchema]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var schema = JSON.parse(jsonSchema.json_schema);
        data.map((jsonData) => {
          jsonData["versions"] = (
            <HistoryIcon
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setVersions(null);
                http.Get(
                  `versions?object_id=${jsonData.id}&model_name=jsondata`,
                  (versions) => {
                    setVersions(versions);
                  },
                  () => {
                    setVersions(null);
                  }
                );
              }}
            />
          );

          var jsonDetail = JSON.parse(jsonData.json_data);
          schema.properties &&
            Object.entries(schema.properties).map(([key, property]) => {
              if (property.listed && jsonDetail) {
                jsonData[key] = jsonDetail[key];
                if (property.type === "password") {
                  jsonData["stared" + key] = !jsonData[key] ? (
                    ""
                  ) : (
                    <span
                      className={classes.passwordColorScheme}
                      onClick={(e) => {
                        if (e.target.innerHTML === "************")
                          e.target.innerHTML = String(jsonData[key])
                            .replaceAll("<", "&lt;")
                            .replaceAll(">", "&gt;");
                        else e.target.innerHTML = "************";
                      }}
                    >
                      ************
                    </span>
                  );
                }
                if (property.type === "boolean") {
                  jsonData[key] = jsonData[key] ? <CheckIcon /> : "";
                }
                if (property.copyable) {
                  jsonData["copy" + key] = !jsonData[key] ? (
                    ""
                  ) : (
                    <CopyIcon
                      fontSize="small"
                      className={classes.iconColorScheme}
                      onClick={(props, row, columnId) => {
                        onCopy(props, jsonData, key);
                        setSnackBarMessage(key + " Copied");
                      }}
                    />
                  );
                }
              }
            });
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <JsonDataDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
              }}
            />
          </div>
        </Dialog>
      )}
      {anchorEl && (
        <ObjectVersions
          {...props}
          versions={versions}
          fetchData={fetchData}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          jsonSchema={jsonSchema}
        />
      )}
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
      ></SnackbarComponent>
      {!props.mobile && jsonSchema && tableColumns && searchFields && (
        <ObjectList
          {...props}
          moduleName={jsonSchema.name}
          url={url}
          actions={actions}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_custom_tab_entry > -1 &&
              data.length >= props.companyTenant.max_custom_tab_entry)
              ? null
              : JsonDataForm
          }
          tableColumns={tableColumns}
          searchFields={searchFields}
          orderBy={"searchable"}
          data={data}
          fetchData={fetchData}
          F
          pageHeader={jsonSchema.name}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && jsonSchema && (
        <ObjectListMobile
          {...props}
          moduleName={jsonSchema.name}
          url={url}
          actions={actions}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_custom_tab_entry > -1 &&
              data.length >= props.companyTenant.max_custom_tab_entry)
              ? null
              : JsonDataForm
          }
          tableColumns={tableColumns}
          searchFields={["name"]}
          orderBy={"name"}
          data={data}
          fetchData={fetchData}
          pageHeader={jsonSchema.name}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </>
  );
};

export default JsonDatas;
