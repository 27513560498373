import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Input } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	editButton: {
		color: theme.colorScheme + "!important",
    cursor: "pointer",
    float: "right",
    margin: "10px"
	},
	popover: {
    width: 400,
    margin: 10
	},
  saveButton: {
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    marginLeft: 10
  },
  cancelButton: {
    float: "right",
    borderRadius: 25,
    textTransform: "capitalize",
    color: theme.colorScheme,
    borderColor: theme.colorScheme,
    marginRight: 10
  },
  error: {
    color: "red",
  },
}));

const tableColumns = [
  {
    id: "package_template_name",
    label: "TEMPLATE",
    sortable: true,
  },
  {
    id: "package_name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "reseller_monthly_price",
    label: "MONTHLY COST",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "reseller_annual_price",
    label: "ANNUAL COST",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "reseller_user_addon_price",
    label: "READONLY ADDON COST",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "reseller_portal_addon_price",
    label: "PORTAL ADDON COST",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "actions",
    label: "",
    sortable: false,
  },
];

const Packages = (props) => {
	const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `packages`;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((p)=>{
          p.actions = 
              <EditIcon className={classes.editButton} onClick={(e)=>{setSelectedPackage(p);setAnchorEl(e.currentTarget);}}/>
            ;
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {selectedPackage &&
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e)=>{setAnchorEl(null);}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container className={classes.popover} spacing={2}>
          <Grid item xs={12}>
            <h3>SELLING PRICE</h3><hr/>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                MONTHLY PRICE:
              </Grid>
              <Grid item xs={4}>
                <Input type="number" value={selectedPackage.monthly_price} onChange={(e)=>{
                  setSelectedPackage({
                    ...selectedPackage,
                    monthly_price: e.target.value
                  });
                }}></Input>
                <p className={classes.error}>{errors["monthly_price"]}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                ANNUAL PRICE:
              </Grid>
              <Grid item xs={4}>
                <Input type="number" value={selectedPackage.annual_price} onChange={(e)=>{
                  setSelectedPackage({
                    ...selectedPackage,
                    annual_price: e.target.value
                  });
                }}></Input>
                <p className={classes.error}>{errors["annual_price"]}</p>        
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                READONLY ADDON PRICE:
              </Grid>
              <Grid item xs={4}>
                <Input type="number" value={selectedPackage.user_addon_price} onChange={(e)=>{
                  setSelectedPackage({
                    ...selectedPackage,
                    user_addon_price: e.target.value
                  });
                }}></Input>
                <p className={classes.error}>{errors["user_addon_price"]}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                PORTAL ADDON PRICE:
              </Grid>
              <Grid item xs={4}>
                <Input type="number" value={selectedPackage.portal_addon_price} onChange={(e)=>{
                  setSelectedPackage({
                    ...selectedPackage,
                    portal_addon_price: e.target.value
                  });
                }}></Input>
                <p className={classes.error}>{errors["portal_addon_price"]}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  margin="dense"
                  size="small"
                  className={classes.cancelButton}
                  onClick={() => {
                    setAnchorEl(null);
                    setSelectedPackage(null);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  margin="dense"
                  size="small" 
                  className={classes.saveButton} 
                  onClick={(e)=>{
                    console.log(selectedPackage);
                    http.Put(
                      url + "/" + selectedPackage.id,
                      selectedPackage,
                      ()=>{
                        fetchData();
                        setAnchorEl(null);
                        setSelectedPackage(null);
                      },
                      (status, error)=>{
                        setErrors(error);
                      }
                    );
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    }
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Packages"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Packages"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Packages"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["package_name"]}
      orderBy={"package_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Packages"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </>
  );
};

export default Packages;
