import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";

const ResellerUserForm = props => {
  const [formFields, setFormFields] = useState();
  const [allRoles, setAllRoles] = useState();
  const [roleMappings, setRoleMappings] = useState();
  const [availableRoles, setAvailableRoles] = useState();

  var countryCodeMap = {};

  Object.keys(countryCodes).forEach(function(c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  Object.entries(countryCodeMap).map(([key, value]) => {
    countryCodeMap[key] = value + " (" + key + ")";
  });

  const getRoleMappings = () => {
    if(props.objectId){
      http.Get(
        `reseller_user_roles?user_id=${props.objectId}`,
      data => {
        var roleList = [];
        for(let i=0; i<data.length; i++){
          data[i]["value"] = data[i]["role_id"];
          data[i]["label"] = allRoles[data[i]["role_id"]];
          data[i]["onDelete"] = (e,object,setObject,setErrors) => {            
            http.Delete(
              "reseller_user_roles/" + data[i]["id"],
              {},
              data => {
                getRoleMappings();
              },
              (status, error) => {
                setErrors(error);
              }
            );

          };
          roleList.push(data[i]);
        }
        setRoleMappings(roleList);
        },
        () => {}
        );

    }
    else{
      var data = localStorage.getItem("rolesForResellerUser");
      if(data){
        var roles = data.split(",");
        var roleList = [];
        roles.map((role)=>{
            if(role){
                var data = {};
                data["value"] = role;
                data["label"] = allRoles[role];
                data["onDelete"] = (e,object,setObject,setErrors) => { 
                    var roles = localStorage.getItem("rolesForResellerUser");
                    if(roles){
                        localStorage.setItem("rolesForResellerUser",roles.replace(role + ",",""));
                        getRoleMappings();
                    }
                      var subDatas = object.subDatas;
                      if(subDatas){
                            var newSubDatas = subDatas.filter((subData)=>{
                                return subData.data.role_id!==role;
                                });
                          setObject({
                              ...object,
                              'subDatas': newSubDatas,
                            });
                      }
                        
                      };
                roleList.push(data);
            }
        });        
        setRoleMappings(roleList); 
      }
      else{
        localStorage.setItem("rolesForResellerUser","");
        setRoleMappings([]);
      }
    }
  };

  useEffect(() => {
    http.Get(
      `reseller_roles`,
      data => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].role_name;
        }
        setAllRoles(valueMap);
      },
      () => {}
    );
    localStorage.setItem("rolesForResellerUser","");
  }, []);

  useEffect(() => {
    if(allRoles && roleMappings) {
      var availableList = {...allRoles};
      for(let i=0;i<roleMappings.length;i++){
        if(availableList[roleMappings[i]['value']]){
          delete availableList[roleMappings[i]['value']];
        }
      }
      setAvailableRoles(availableList);
    }
  }, [allRoles,roleMappings]);

  useEffect(() => {
    if(allRoles) {
      getRoleMappings();
    }
  }, [allRoles]);

  useEffect(() => {
    if (availableRoles && allRoles && roleMappings) {
      setFormFields([
        {
          id: "first_name",
          label: "FIRST NAME *",
          type: "text"
        },
        {
          id: "last_name",
          label: "LAST NAME *",
          type: "text"
        },
        {
          id: "email",
          label: "EMAIL *",
          type: "email"
        },
        {
          id: "phone",
          label: "PHONE",
          type: "text"
        },
        {
          id: "mobile",
          label: "MOBILE",
          type: "text"
        },
        {
          id: "country_code",
          label: "COUNTRY CODE",
          type: "select",
          valueMap: countryCodeMap
        },
        {
          id: "number_of_result_per_page",
          label: "NUMBER OF RESULT PER PAGE *",
          type: "select",
          valueMap: {25:25,50:50,100:100,200:200}
        },
        {
          id: "is_reseller_admin",
          label: "IS RESELLER ADMIN",
          type: "radio"
        },
        {
          id: "has_access",
          label: "HAS ACCESS",
          type: "radio"
        },
        {
          id: "roles",
          label: "ROLES",
          type: "multiselect",
          valueMap: availableRoles,
          selectedValues: roleMappings,
          onChange: (e, object, setObject, setErrors) => {       
              if(object.id){
                http.Post(
                  "reseller_user_roles",
                  { user_id: object.id, role_id: e.target.value },
                  data => {
                    getRoleMappings();
                  },
                  (status, error) => {
                    setErrors(error);
                  }
                );
              }
              else{
                  var subDatas = object.subDatas;
                  if(!subDatas){
                      subDatas = [];
                  }
                  subDatas.push({url:"reseller_user_roles",data:{"role_id":e.target.value},id:'user_id'});
                  setObject({
                      ...object,
                      'subDatas': subDatas,
                    });
                    
                  var data = localStorage.getItem("rolesForResellerUser");     
                  if(!data){
                      data = "";
                  }
                  localStorage.setItem("rolesForResellerUser",data + e.target.value + ",");
                  getRoleMappings();
              }
            
          }
        }
      ]);
    }
  }, [availableRoles, allRoles, roleMappings]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`reseller_users`}
          formName={"Reseller User"}
          formFields={formFields}
          object={{ is_reseller_admin: false, reseller: props.user.reseller }}
        />
      )}
    </div>
  );
};

export default ResellerUserForm;
