import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Geocode from "react-geocode";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import SearchIcon from "@material-ui/icons/Search";
import http from "./RestAPIHandler";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CreateIcon from "@material-ui/icons/Create";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  stableSort,
  getSorting,
  formatDate,
  formatDateTime,
} from "./Utility";
import DeleteAlertDialog from "./AlertDialog";
import commonConstants from "./CommonConstants";
import SnackbarComponent from "../Common/Snackbar";
import { parse, unparse } from "./CSVImportExportUtility";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { hasPermission } from "./permission";
import { format } from "date-fns-tz";
import ScreenLoader from "../Common/Loader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ErrorBoundary from "./ErrorBoundary";
import useStyles from "./Style/CommonStyle";
import { form } from "./Languages";

const lang = localStorage.getItem("languages");

Geocode.setApiKey("AIzaSyD30xT-jnz-eajS6xaH61oS2SiDo1nHlRY");

export var delete_item = 0;
export function setDi(prop){
  delete_item = prop;
}

const ObjectList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [objectId, setObjectId] = useState(null);
  const [objects, setObjects] = useState([]);
  const [objectList, setObjectList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [search, setSearch] = useState("");
  const [check, setCheck] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.user.number_of_result_per_page || 25
  );
  const [order, setOrder] = useState(props.order || "asc");
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [templateAnchorEl, setTemplateAnchorEl] = React.useState(null);
  const templateMenuOpen = Boolean(templateAnchorEl);
  const [checkAll, setCheckAll] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [openOneTimeSecret, setOpenOneTimeSecret] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  const [formState, setFormState] = useState(false);
  const { csvEmptyError, csvFormatError, csvFormatMismatchError } =
    commonConstants.error;
  const [previousSearch, setPreviousSearch] = useState();

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  const handleChange = (e) => {
    let toggle = false;
    if (e.target.value.length > 0) {
      toggle = true;
    }
    setFormState(toggle);
  };

  useEffect(() => {
    if (startX && endX) {
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);

  useEffect(() => {
    if (props.templateUrl)
      http.Get(
        props.templateUrl,
        (data) => {
          var today = new Date();
          var date = formatDate(today.toISOString());
          var time = format(today, "hh:mm a");
          var datetime = formatDateTime(today.toISOString());

          data.forEach(
            (template) =>
              (template.data = template.data
                .replace(/%%date%%/gi, date)
                .replace(/%%time%%/gi, time)
                .replace(/%%datetime%%/gi, datetime))
          );
          setTemplates(data);
        },
        () => {}
      );
  }, []);

  useEffect(() => {
    setObjectList(props.data);
    var inputSearch = new URLSearchParams(window.location.search).get(
      "input_search"
    );
    if (inputSearch) {
      var input = document.getElementById("input_search");
      if (input) {
        setPreviousSearch(inputSearch);
        setCaretPosition(input, input.value.length);
      }
      setSearch(inputSearch);
      resetCheck();
      setPage(0);
    } else {
      if (props.url === "companies") {
        setPreviousSearch(localStorage.getItem("previous_search"));
        var input = document.getElementById("input_search");
        if (input) {
          setCaretPosition(input, input.value.length);
        }
        if (previousSearch) {
          setSearch(previousSearch);
        }
        resetCheck();
        setPage(0);
      } else {
        var input = document.getElementById("input_search");
        if (input) {
          setCaretPosition(input, input.value.length);
        }
      }
    }
  }, [props.data]);

  useEffect(() => {
    var tableData = [];
    if (!search) tableData = objectList;
    else{
      if (!props.url.includes("company_documents?company=")) {
      tableData = objectList.filter((object) =>
        props.searchFields.some(
          (key) =>
            object[key] &&
            object[key].toLowerCase().includes(search.toLowerCase())
        )
      )}
      else{
        tableData = props.allData.filter((object) =>
        props.searchFields.some(
          (key) =>
            object[key] &&
            object[key].toLowerCase().includes(search.toLowerCase())
        )
      )
      }}
    var sortedObjects = stableSort(tableData, getSorting(order, orderBy));
    if (props.fixedOrderBy) {
      sortedObjects = stableSort(
        sortedObjects,
        getSorting("asc", props.fixedOrderBy)
      );
    }
    // if(sortedObjects[0]){
    //   // let test = sortedObjects.copyPasssword
    //   sortedObjects[0].password + sortedObjects[0].copyPasssword;
    // }
    // console.log('sorted', sortedObjects);
    setObjects(sortedObjects);
  }, [objectList, search, order, orderBy]);

  //-------------------------------------------------------------------------ALI-------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (Object.keys(check).length > 0) {
      let flag = true;
      objects
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((element) => {
          if (check[element.id] !== true) {
            flag = false;
          }
        });

      if (objects && objects.length > 0 && flag) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
    }
  }, [check]);

  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const resetCheck = () => {
    setCheck({});
    setCheckAll(false);
    setAlertOpen(false);
    setAnchorEl(null);
  };

  const setCaretPosition = (ctrl, pos) => {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(0, pos);
    }
  };

  const handleSearch = (e) => {
    if (props.url === "companies") {
      localStorage.setItem("previous_search", e.target.value);
    }
    setPreviousSearch(e.target.value);
    setSearch(e.target.value);
    resetCheck();
    setPage(0);
  };

  const handleRequestSort = (property) => () => {
    const nextOrderBy = property;
    let nextOrder = "desc";
    if (orderBy === property && order === "desc") {
      nextOrder = "asc";
    }
    setOrder(nextOrder);
    setOrderBy(nextOrderBy);
    resetCheck();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    resetCheck();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    resetCheck();
  };

  const handleCheckChange = (id) => (event) => {
    setCheck({ ...check, [id]: event.target.checked });
  };

  const handleCheckAll = (e) => {
    let checks = {};
    if (e.target.checked) {
      objects
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach((element) => {
          checks[element.id] = e.target.checked;
        });
      setCheck(checks);
      setCheckAll(true);
    } else {
      setCheck(checks);
      setCheckAll(false);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTemplateMenu = (event) => {
    setTemplateAnchorEl(event.currentTarget);
  };

  const handleTemplateMenuClose = () => {
    setTemplateAnchorEl(null);
  };

  const selectedProfilesInUse = () => {
    const objectIdList = Object.keys(check).filter((key) => check[key] && key);
    for (const id of objectIdList) {
      if (props.vacationProfilesUsed[id]) return true;
    }
    return false;
  };

  const handleMultipleDelete = () => {
    if (props.vacationProfilesUsed && selectedProfilesInUse()) {
      handleSnackBar("Cannot delete. Profile(s) has assignees.", "error");
      return;
    }
    setAnchorEl(null);
    setAlertOpen(true);
  };
  
  const handleOneTimeSecret = () => {
    if (props.vacationProfilesUsed && selectedProfilesInUse()) {
      handleSnackBar("Cannot delete. Profile(s) has assignees.", "error");
      return;
    }
    setAnchorEl(null);
    setOpenOneTimeSecret(true);
  };

  const handleDelete = () => {
    setAlertOpen(true);
  };

  const handleDeleteAgree = () => {
    var objectIdList = Object.keys(check).filter((key) => check[key] && key);
    http.Delete(
      props.url,
      { ids: objectIdList },
      (status, data) => {
        if (status === 200 && data["database_error"]) {
          handleSnackBar(data["database_error"], "error");
        }
        props.fetchData();
        if (checkAll) {
          setPage(0);
        }

        setCheck({});
        setCheckAll(false);
        setAlertOpen(false);

        if (props.onDelete) {
          props.onDelete();
        }
      },
      () => {}
    );
  };

  const handleDeleteDisagree = () => {
    setAlertOpen(false);
    setCheck({});
    setCheckAll(false);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

	// This should be the function called at ALL TIMES FOR EDIT FROM THIS COMPONENT
	// OTHERWISE pass function as props from parent
  const handleEditClick = (e, id) => {
    if (e) {
      e.stopPropagation();
    }
    setEdit(true);
    setObjectId(id);
    setOpen(true);
    resetCheck();
  };

  const handleAddClick = (isUsingTemplate, e) => {
    if (!isUsingTemplate) setTemplate({});
    setEdit(false);
    setObjectId(null);
    setOpen(true);
    resetCheck();
  };

  const handleTemplateClick = (template) => {
    setTemplate(template);
    handleAddClick(true);
    handleTemplateMenuClose();
  };

  const handleObjectSubmit = () => {
    props.fetchData();
    setObjectId(null);
    setOpen(false);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const openFileExplorer = () => {
    var input = document.createElement("input");
    input.type = "file";

    input.onchange = (e) => {
      var file = e.target.files[0];
      var fileName = file.name && file.name.split(".");
      if (fileName[fileName.length - 1].toLowerCase() !== "csv") {
        handleSnackBar(csvFormatError, "error");
        return;
      }
      parse(
        file,
        props.csvHeader,
        null,
        props.importUrl,
        props.moduleName,
        props.packageLimit,
        props.unchangedData.length,
        () => handleSnackBar(csvEmptyError, "error"),
        () => handleSnackBar(csvFormatMismatchError, "error"),
        (data) => {

          handleSnackBar(
            
            `Success: ${data.success}, Failed: ${data.failed}`,
            "success"
          );
          setDi(1);
          props.fetchData();
        },
        (data) => {
          handleSnackBar(
            `Success: ${data.success}, Failed: ${data.failed}`,
            "warning"
          );
          props.fetchData();
        },
        (error) => {
          handleSnackBar(
            `Success: ${error.success}, Failed: ${error.failed}`,
            "error"
          );
        },
        () => handleSnackBar(commonConstants.error.packageLimitReached, "error")
      );
    };
    input.click();
    handleClose();
  };

  const exportToCSVAll = () => {
    var exportData = props.unchangedData.map((item) => {
      let obj = { ...item };
      delete obj.Id;
      return { ...obj };
    });
    unparse([], exportData, "Exported All " + props.moduleName);
    handleClose();
  };

  const exportToCSVSelected = () => {
    handleClose();
    
    var exportData = [];
    props.unchangedData.forEach((item) => {
      if (check[item.Id]) {
        let obj = { ...item };
        delete obj.Id;
        exportData.push(obj);
      }
    });
    unparse([], exportData, "Export Selected " + props.moduleName);
  };

  const exportToPDF = (data) => {
    handleSnackBar(
      "Preparing data to export. Download will begin automatically shortly.",
      "success"
    );
    http.ExportToPdf(
      `meetings/export_pdf`,
      data,
      (blob) => {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "Meeting Minutes Export.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      () => {
        handleSnackBar("Error occured in export to pdf.", "error");
      }
    );
  };

  const exportToPDFAll = () => {
    handleClose();
    const offset = new Date().getTimezoneOffset();
    exportToPDF({ offset: offset });
  };

  const exportToPDFSelected = () => {
    handleClose();
    const offset = new Date().getTimezoneOffset();
    var exportedIds = Object.keys(check).filter((key) => check[key] && key);
    exportToPDF({ offset: offset, ids: exportedIds });
  };

  const downloadImportFormat = () => {
    handleClose();
    unparse(props.csvHeader, [], props.moduleName);
  };

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <SnackbarComponent
          message={snackBarMessage}
          open={snackBarOpen}
          handleSnackBarClose={handleSnackBarClose}
          severity={severity}
        ></SnackbarComponent>
        <Dialog
          maxWidth="lg"
          open={openOneTimeSecret}
          onClose={() => {
            setOpenOneTimeSecret(false);
          }}>
            <div style={{padding: "20px",textAlign: "center"}}>
              <h1>Share a One-Time secret</h1>
                <input type="hidden" name="shrimp" value="ppdfbb9xxzjtl7ah9q2lx9zs6ly624xchr3n0iq4o5w6r8mp5s01qg6luhhgccynu36to4d4y3csaffrodt1wxc9mu5yngfj3k1" />
                  <div class="text-input" style={{marginBottom: "20px"}}>
                    <textarea rows="7" class="btn-block" name="secret" autocomplete="off" placeholder="Secret content goes here..."></textarea>
                  </div>
                  <div>
                    <div style={{marginBottom: "20px"}}>
                      <span style={{marginRight: "10px"}}>
                        Passphrase:
                      </span>
                      <span>
                        <input class="input-block-level" type="text" name="passphrase" id="passphraseField" value="" placeholder="A word or phrase that&#39;s difficult to guess" autocomplete="off" />
                      </span>
                    </div>
                    <div style={{marginBottom: "20px"}}>
                      <span style={{marginRight: "10px", marginBottom: "20px"}}>
                        Lifetime:
                      </span>
                      <span>
                        <select>
                          <option value="604800.0" selected>7 days</option>
                          <option value="259200.0" >3 days</option>
                          <option value="86400.0" >1 day</option>
                          <option value="43200.0" >12 hours</option>
                          <option value="14400.0" >4 hours</option>
                          <option value="3600.0" >1 hour</option>
                          <option value="1800.0" >30 minutes</option>
                          <option value="300.0" >5 minutes</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <div>
                    <button class="create btn btn-large btn-block btn-custom cufon" type="submit" name="kind" value="share" >Create a secret link*</button>
                  </div>
              <hr/>

            </div>
        </Dialog>



        <DeleteAlertDialog
          open={alertOpen}
          onAgree={()=>{handleDeleteAgree();
                        setDi(1)}}
          onDisagree={handleDeleteDisagree}
          primaryMessage={"Do you want to delete?"}
          secondaryMessage={"If you agree, data will be deleted permanently."}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className={classes.pageHeader}>{props.pageHeader}</span>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h4" classes={{ root: classes.moduleName }}>
                  {props.moduleName}
                </Typography>
              </Grid>
              <Grid item className={classes.containerItem}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item className={classes.containerItem}>
                    {!props.excludeAddButton ? (
                      <Chip
                        label={form.ADDNEW[lang]}
                        clickable
                        className={classes.chip}
                        color="primary"
                        onClick={
                          ((!(props.moduleName == "Tenant Users")&&(props.objectForm))||((props.moduleName == "Tenant Users")&&(props.noAdd != "allow")))
                            ? (e) => handleAddClick(false, e)
                            : () =>
                                handleSnackBar(
                                  commonConstants.error.packageLimitReached,
                                  "error"
                                )
                        }
                        disabled={
                          (props.permissions &&
                            !hasPermission(props, props.permissions.create)) ||
                          (props.portalPermissions &&
                            !props.portalPermissions.create)
                        }
                        onDelete={
                          props.templateUrl &&
                          templates.length > 0 &&
                          handleTemplateMenu
                        }
                        deleteIcon={
                          <ArrowDropDownCircleIcon
                            className={classes.arrowIcon}
                          />
                        }
                      />
                    ) : (
                      <> </>
                    )}
                    {props.templateUrl && (
                      <Menu
                        id="menu-template"
                        anchorEl={templateAnchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={templateMenuOpen}
                        onClose={handleTemplateMenuClose}
                        PaperProps={{
                          style: {
                            maxWidth: 250,
                            borderRadius: 15,
                            maxHeight: "calc(100vh/3)",
                          },
                        }}
                      >
                        {templates.map((template) => (
                          <MenuItem
                            onClick={() => handleTemplateClick(template)}
                            className={classes.templateMenuItem}
                          >
                            {template.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    {props.actions && (
                      <Chip
                        label={<MoreVertIcon />}
                        className={classes.chip}
                        // color="primary"
                        onClick={handleMenu}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {props.actions && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      minWidth: 160,
                      borderRadius: 15,
                    },
                  }}
                >
                  {props.actions["delete"] && (
                    <MenuItem
                      onClick={handleMultipleDelete}
                      className={classes.menuItem}
                      disabled={
                        Object.values(check).filter((item) => item).length ===
                          0 ||
                        (props.permissions &&
                          !hasPermission(props, props.permissions.delete)) ||
                        (props.portalPermissions &&
                          !props.portalPermissions.delete)
                      }
                    >
                      {form.DeleteSelectedRows[lang]}
                    </MenuItem>
                  )}

                  {props.actions["create"] && (
                    <MenuItem
                      onClick={handleOneTimeSecret}
                      className={classes.menuItem}
                    >
                      {form.CreateSecret[lang]}
                    </MenuItem>
                  )}

                  {props.actions["importCSV"] && (
                    <MenuItem
                      onClick={downloadImportFormat}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Import"])}
                    >
                      {form.DownloadImportFormat[lang]}
                    </MenuItem>
                  )}
                  {props.actions["importCSV"] && (
                    <MenuItem
                      onClick={openFileExplorer}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Import"])}
                    >
                      {form.ImportfromCSV[lang]}
                    </MenuItem>
                  )}
                  {props.actions["exportCSV"] && (
                    <MenuItem
                      onClick={exportToCSVAll}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Export"])}
                    >
                      {form.ExportAlltoCSV[lang]}
                    </MenuItem>
                  )}
                  {props.actions["exportCSV"] && (
                    <MenuItem
                      onClick={exportToCSVSelected}
                      className={classes.menuItem}
                      disabled={
                        Object.values(check).filter((item) => item).length ===
                          0 || !hasPermission(props, ["Export"])
                      }
                    >
                      {form.ExportSelectedtoCSV[lang]}
                    </MenuItem>
                  )}
                  {props.actions["exportPDF"] && (
                    <MenuItem
                      onClick={exportToPDFSelected}
                      className={classes.menuItem}
                      disabled={
                        Object.values(check).filter((item) => item).length === 0
                      }
                    >
                      {form.ExportSelectedtoPDF[lang]}
                    </MenuItem>
                  )}
                  {props.actions["exportPDF"] && (
                    <MenuItem
                      onClick={exportToPDFAll}
                      className={classes.menuItem}
                    >
                      {form.ExportAlltoPDF[lang]}
                    </MenuItem>
                  )}
                  {props.actions["formatCredentials"] && (
                    <MenuItem
                      onClick={() => {
                        props.actions["formatCredentials"].onClick();
                        setAnchorEl(null);
                      }}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Import"])}
                    >
                      {props.actions["formatCredentials"]["label"]}
                    </MenuItem>
                  )}
                  {props.actions["importCredentials"] && (
                    <MenuItem
                      onClick={() => {
                        props.actions["importCredentials"].onClick();
                        setAnchorEl(null);
                      }}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Import"])}
                    >
                      {props.actions["importCredentials"]["label"]}
                    </MenuItem>
                  )}
                  {props.actions["exportAllCredentials"] && (
                    <MenuItem
                      onClick={() => {
                        props.actions["exportAllCredentials"].onClick();
                        setAnchorEl(null);
                      }}
                      className={classes.menuItem}
                      disabled={!hasPermission(props, ["Export"])}
                    >
                      {props.actions["exportAllCredentials"]["label"]}
                    </MenuItem>
                  )}
                  {props.actions["exportSelectedCredentials"] && (
                    <MenuItem
                      onClick={() => {
                        props.actions["exportSelectedCredentials"].onClick();
                        setAnchorEl(null);
                      }}
                      className={classes.menuItem}
                      disabled={
                        props.activeIds.length === 0 ||
                        !hasPermission(props, ["Export"])
                      }
                    >
                      {props.actions["exportSelectedCredentials"]["label"]}
                    </MenuItem>
                  )}
                  {props.actions.additionalActions &&
                    props.actions.additionalActions.map((additionalAction) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            var objectIdList = Object.keys(check).filter(
                              (key) => check[key] && key
                            );
                            additionalAction.onClick(objectIdList);
                            setAnchorEl(null);
                          }}
                          className={classes.menuItem}
                          disabled={additionalAction.disabled}
                        >
                          {additionalAction.label}
                        </MenuItem>
                      );
                    })}
                </Menu>
              )}
            </Grid>
          </Grid>
          {/* MOVE THIS FIELD SO IT IS BESIDE MODULNAME HEADER? (COMPANIES, RESOURCES, VENDORS, ETC.) */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "space-between" }}
            >
              <Grid item>
                {!props.data.length == 0 && (
                  <div className={classes.search}>
                    <InputBase
                      id={"input_search"}
                      className={classes.input}
                      placeholder={form.Search[lang]}
                      inputProps={{ "aria-label": "search" }}
                      fullWidth
                      onChange={handleSearch}
                      value={previousSearch || ""}
                    />
                    <IconButton
                      disabled
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* ----------------------------------------------------------------------------------------------- */}
          <Grid item xs={12}>
            {/* <Divider classes={{root: classes.dividers}} /> */}
          </Grid>
          <Grid item xs={12} className={classes.tablePadding}>
            {props.loadData ? (
              <ScreenLoader />
            ) : (
              <ObjectTable
                {...props}
                data={objects}
                handleEditClick={handleEditClick}
                handleCheckChange={handleCheckChange}
                check={check}
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                tableColumns={props.tableColumns}
                permissions={props.permissions}
                portalPermissions={props.portalPermissions}
                checkAll={checkAll}
                handleCheckAll={handleCheckAll}
              />
            )}
          </Grid>
        </Grid>
        {props.objectForm && (
          <Drawer
            autoComplete="off"
            anchor="right"
            open={open}
            onChange={handleChange}
            onClose={(e) => {
              if (!formState) {
                setOpen(false);
              } else {
                handleSnackBar(
                  form.help1[lang],
                  form.help2[lang]
                );
              }
            }}
            disableEnforceFocus
            classes={{
              paper: classes.drawerForm,
            }}
          >
            <div style={{ width: width }}>
              {props.loadData ? (
                <ScreenLoader />
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td
                        draggable
                        style={{ cursor: "ew-resize" }}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                      >
                        <DragIndicatorIcon />
                      </td>
                      <td width="100%">
                        <props.objectForm
                          {...props}
													closeOpen={handleSetOpen}
													open={open}
                          setOpen={setOpen}
                          edit={edit}
													setEdit={setEdit}
                          objectId={objectId}
                          handleObjectSubmit={handleObjectSubmit}
                          template={template}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </Drawer>
        )}
      </ErrorBoundary>
    </div>
  );
};

const ObjectTable = (props) => {
  const classes = useStyles();
  const tableColumns = props.tableColumns;
  const [screenWidth, setScreenWidth] = useState(0);
  const hasEditPermission =
    (!props.permissions && !props.portalPermissions) ||
    (props.permissions && hasPermission(props, props.permissions.edit)) ||
    (props.portalPermissions && props.portalPermissions.edit);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
          height:
            "calc(100vh - " + (props.trialNotice ? "400px" : "365px") + ")",
        }}
      >
        <ErrorBoundary>
          <Table
            stickyHeader
            className={classes.tables}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {(props.data.length == 0 && (
                  <TableCell className={classes.emptyMessage}>
                    {form.Thislistisempty[lang]}
                  </TableCell>
                )) ||
                  tableColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="left"
                      style={{
                        minWidth: column.minWidth,
                      }}
                      sortDirection={
                        props.orderBy === column.id ? props.order : false
                      }
                      className={classes.padding}
                      classes={{ root: classes.tableCellHeader }}
                      // classes={{
                      // stickyHeader: classes.stickyheader,
                      // }}
                    >
                      {column.sortable ? (
                        <Tooltip
                          title={form.Sort[lang]}
                          placement="bottom-end"
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={props.orderBy === column.id}
                            direction={props.order}
                            onClick={props.handleRequestSort(column.id)}
                            children={column.label}
                            classes={{
                              root: classes.tableCellHeader,
                              active: classes.tableCellHeader,
                              icon: classes.sortIcon,
                            }}
                          >
                            {/* {column.label} */}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}

                {props.data.length > 0 && (
                  <React.Fragment>
                    {props.objectForm && (
                      <TableCell
                        align="left"
                        className={classes.padding}
                        classes={{ root: classes.tableCellHeader }}
                        // classes={{
                        // 	stickyHeader: classes.stickyheader,
                        // }}
                      ></TableCell>
                    )}
                    {props.actions && (
                      <TableCell
                        align="left"
                        className={classes.padding}
                        classes={{ root: classes.tableCellHeader }}
                        // classes={{
                        // stickyHeader: classes.stickyheader,
                        // }}
                      >
                        <Checkbox
                          className={classes.checkboxColor}
                          checked={props.checkAll ? true : false}
                          disableRipple
                          onChange={props.handleCheckAll}
                        />
                      </TableCell>
                    )}
                  </React.Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      className={`${row.rp_disabled && classes.disableRow} ${
                        classes.tableRow
                      }`}
                    >
                      {tableColumns.map((column) => (
                        <TableCell
                          key={row.id + column.id}
                          scope="row"
                          className={`${
                            row.rp_disabled && classes.disableCell
                          } ${classes.cellPadding} ${classes.cell} ${column.id == "category_name" && classes.truncate}`}
                          onClick={
                            column.onClick
                              ? () => {
                                  column.onClick(props, row, column.id);
                                }
                              : () => {}
                          }
                        >
                          {/* <Tooltip
														// title={row[column.id] || ""}
														title={column.label || ""}
														disableHoverListener={
															!row[column.id] || column.disableTooltip
														}
													> */}
                          <span
                            style={column.style}
                            className={`${
                              row.rp_disabled && classes.disableCell
                            } ${column.colored && classes.cellColor}`}
                          >
                            {row[column.id] &&
                            row[column.id].length > commonConstants.cellLength
                              ? row[column.id].substring(
                                  0,
                                  commonConstants.cellLength - 3
                                ) + "..."
                              : row[column.id]}
                          </span>
                          {/* </Tooltip> */}
                        </TableCell>
                      ))}
                      {props.objectForm && (
                        <TableCell
                          align="left"
                          className={`${
                            row.rp_disabled ? `${classes.disableCell}` : ""
                          } ${classes.cellPadding} ${classes.cellColor}`}
                        >
                          {!row.readonly && (
                            <Tooltip arrow={true} title={form.Edit[lang]}>
                              <CreateIcon
                                className={classes.cursor}
                                onClick={(e) =>
                                  hasEditPermission
                                    ? props.handleEditClick(e, row.id)
                                    : {}
                                }
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                      {props.actions && (
                        <TableCell
                          align="left"
                          className={`${
                            row.rp_disabled && classes.disableCell
                          } ${classes.cellPadding} ${classes.cell}`}
                        >
                          {(!row.readonly || props.moduleName == "API User Management")&& (
                            <Checkbox
                              className={classes.checkboxColor}
                              checked={props.check[row.id] ? true : false}
                              onClick={(e) => e.stopPropagation()}
                              onChange={props.handleCheckChange(row.id)}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                    {props.activeIds && props.activeIds.includes(row.id) && (
                      <TableRow>
                        <TableCell colSpan={props.tableColumns.length + 2}>
                          {row.subData}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </ErrorBoundary>
      </div>
      {!props.data.length == 0 && (
        <TablePagination
          classes={{
            caption: classes.subText,
            selectIcon: classes.paginationButton,
            menuItem: classes.subText,
          }}
          className={classes.pagination}
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={props.data.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          backIconButtonProps={{
            className: classes.paginationButton,
          }}
          nextIconButtonProps={{
            className: classes.paginationButton,
          }}
          labelRowsPerPage={
            (screenWidth > 500 && "Rows per page") ||
            (screenWidth <= 500 && screenWidth > 420 && "Rows") ||
            ""
          }
          SelectProps={{
            inputProps: {
              className: classes.subText,
            },
          }}
        />
      )}
    </div>
  );
};

export default ObjectList;
