import React, { useState, useEffect } from "react";
import DocumentForm from "./DocumentForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import commonConstants from "../../Common/CommonConstants";
import { formatDateTime, siteSelected } from "../../Common/Utility";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LockIcon from "@material-ui/icons/Lock";
import { Dialog, Button, Input } from "@material-ui/core";
import useStyles from "../../Common/Style/FormStyle";

const Documents = (props) => {
  //const selectedSite = localStorage.getItem("Site");
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("All");
  const [showData, setShowData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [categories, setCategories] = useState();
  //const [site, setSite] = useState(selectedSite);
  const [multipleUpload, setMultipleUpload] = useState();
  const [files, setFiles] = useState();
  const url = `company_documents?company=${props.user.company_id}`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "locked",
      label: "",
      minWidth: 20,
      sortable: false,
      colored: false,
    },
    {
      id: "title",
      label: "TITLE",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, document, column_id) => {
            props.history.push(
              process.env.PUBLIC_URL +
                `/portal/documents/${document.id}/details`
            );
          },
    },
    {
      id: "download",
      label: "",
      minWidth: 10,
      colored: true,
      style: commonConstants.cellCursor,
    },
    {
      id: "site_name",
      label: "SITE",
      minWidth: 175,
      sortable: true,
      onClick: (props, document, column_id) => {
        props.history.push(
          process.env.PUBLIC_URL +
            `/company/${props.user.company_id}/documents/${document.id}/details`
        );
      },
    },
    {
      id: "last_modified_time",
      label: "LAST MODIFIED",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "last_modified_by_name",
      label: "BY",
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    http.Get(
      `categories?category_type=Document Category`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (categories) {
      fetchData();
    }
  }, [categories]);

  useEffect(() => {
    setLoadData(true);
    let dataList = [];
    data.map((doc) => {
      if (category === "All" || doc.category === category) {
        dataList.push(doc);
      }
    });
    setShowData(dataList);
    setLoadData(false);
  }, [data, category]);

  const fetchData = () => {
    setLoadData(true);
    const documents = [];
    http.Get(
      url,
      (data) => {
        data.map((item) => {
          item["last_modified_time"] = formatDateTime(
            item["last_modified_time"]
          );
          item["locked"] = item["is_locked"] ? <LockIcon /> : "";
          item.download = (
            <SaveAltIcon
              onClick={() => {
                var divToPDF = window.document.getElementById(item.id);
                html2canvas(divToPDF).then((canvas) => {
                  var imgData = canvas.toDataURL("image/png");
                  var imgWidth = 210;
                  var pageHeight = 292;
                  var imgHeight = (canvas.height * imgWidth) / canvas.width;
                  var heightLeft = imgHeight;
                  var doc = new jsPDF("p", "mm");
                  var position = 0;
                  doc.addImage(
                    imgData,
                    "PNG",
                    0,
                    position,
                    imgWidth,
                    imgHeight
                  );
                  heightLeft -= pageHeight;

                  while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(
                      imgData,
                      "PNG",
                      0,
                      position,
                      imgWidth,
                      imgHeight
                    );
                    heightLeft -= pageHeight;
                  }
                  doc.save(item.id + ".pdf");
                });
              }}
            />
          );
          documents.push(item);
          /*
          if (item["site"] == site) {
          }
          */
        });
        setData(documents);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {data &&
        data.map((doc) => {
          return (
            <div
              id={doc.id}
              style={{
                position: "absolute",
                left: "-8000px",
                width: "780px",
                padding: "30px",
              }}
              dangerouslySetInnerHTML={{ __html: doc.content }}
            />
          );
        })}
      {multipleUpload && (
        <Dialog
          open={true}
          onClose={() => {
            setMultipleUpload(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <h3>Bulk Upload</h3>
            <Input
              inputProps={{
                multiple: true,
                accept: ".pdf, .doc, .docx, .xlsx, .csv",
              }}
              type="file"
              onChange={(e) => {
                setFiles(e.target.files);
              }}
            />
            <br />
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              onClick={() => {
                setLoadData(true);
                setMultipleUpload(false);
                if (files && files.length > 0) {
                  var formData = new FormData();
                  formData.append("company_id", props.user.company_id);
                  var i;
                  for (i = 0; i < files.length; i++) {
                    formData.append(files[i].name, files[i], files[i].name);
                  }
                  http.Post(
                    "company_documents_upload",
                    formData,
                    (data) => {
                      setMultipleUpload(false);
                      fetchData();
                      setLoadData(false);
                    },
                    (status, error) => {
                      setLoadData(false);
                    }
                  );
                } else {
                  setLoadData(false);
                  setMultipleUpload(false);
                }
              }}
            >
              Upload
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.outlinedButton}
              onClick={() => {
                setMultipleUpload(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
      )}
      {!props.mobile && data && (
        <ObjectList
          {...props}
          moduleName={
            <Grid container>
              <Grid item xs>
                Documents{" "}
              </Grid>
              <Grid item>&nbsp;&nbsp;&nbsp;&nbsp;</Grid>

              <Grid item style={{ fontSize: "12px", marginLeft: "20px" }}>
                <Select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <MenuItem value="All">All Categories</MenuItem>
                  {categories &&
                    Object.entries(categories).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>          
          }
          mobileMenu={
            <Grid item xs style={{ fontSize: "12px" }}>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <MenuItem value="All">All Categories</MenuItem>
                {categories &&
                  Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          }
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          portalPermissions={{
            create: props.user.role.docs_create,
            edit: props.user.role.docs_modify,
            delete: props.user.role.docs_delete,
          }}
          objectForm={DocumentForm}
          tableColumns={tableColumns}
          data={showData}
          fetchData={fetchData}
          categories={categories}
          orderBy={"title"}
          searchFields={["title", "last_modified_by_name", "site_name"]}
          pageHeader={(props.company.company_name || "") + " | Documents"}
          loadData={loadData}
        />
      )}
      {props.mobile && data && (
        <ObjectListMobile
          {...props}
          moduleName={"Documents"}
          mobileMenu={
            <Grid item xs style={{ fontSize: "12px" }}>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <MenuItem value="All">All Categories</MenuItem>
                {categories &&
                  Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          }
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          portalPermissions={{
            create: props.user.role.docs_create,
            edit: props.user.role.docs_modify,
            delete: props.user.role.docs_delete,
          }}
          objectForm={DocumentForm}
          tableColumns={tableColumns}
          data={showData}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title", "last_modified_by_name", "site_name"]}
          pageHeader={(props.company.company_name || "") + " | Documents"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Documents);
