import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  address: {
    paddingTop: "0 !important",
  },
  checkbox: {
    padding: "0px !important",
  },
  input: {
    backgroundColor: theme.inputFields,
    // borderColor: theme.borderColor,
    height: 30,
    padding: 0,
    margin: 0,
    fontSize: 14,
  },
  link: {
    color: theme.linkColor
  },
  saveButton: {
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  rightAlign: {
    textAlign: "right",
    fontSize: 14,
  },
  viewButtonLabel: {
    textTransform: "none",
  },
  inputButton: {
    display: "none",
  },
  uploadButton: {
    border: "1px dashed gray",
    height: 30,
    fontSize: 13,
  },
  logoCaption: {
    display: "flex",
    flexDirection: "column",
  },
  smallText: {
    fontSize: 10,
    padding: 5,
    paddingLeft: 0,
    color: theme.subText
  },
  subTitle: {
    color: theme.subText
  },
  title: {
    color: theme.text
  },
}));

export default useStyles;
