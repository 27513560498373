import React, { useState } from "react";
import useStyles from "../../../Common/Style/FormStyle";
import http from "../../../Common/RestAPIHandler";
import {
    Divider,
    Typography,
    Paper,
    Grid,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import ScreenLoader from "../../../Common/Loader";

const EmployeeSalaryHistory = (props) => {
    const classes = useStyles();
    const [loadData, setLoadData] = useState();

    return (
        <>
        {loadData ? <ScreenLoader /> : (
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Typography variant="h6" component="h6" style={{paddingTop:10, paddingBottom:10, paddingLeft:10}}>
                        Salary History
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{paddingTop:10,float:"right"}}>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => {
                                if(props.onClose){
                                    props.onClose();
                                }
                            }}
                        >
                        <CancelIcon />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>   
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <div
                            style={{ maxHeight: "calc(100vh - 280px)",
                                minHeight: "calc(100vh - 280px)",
                                overflowY: "auto",
                                overflowX: "auto",  
                                marginLeft:10,
                                padding:10
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Table className={{root: classes.table}}>
                                        <TableHead>
                                            <TableCell className={classes.tableCellHeader}>
                                            START DATE
                                            </TableCell>
                                            <TableCell className={classes.tableCellHeader}>
                                            AMOUNT
                                            </TableCell>
                                            <TableCell className={classes.tableCellHeader}>
                                            RATE TYPE
                                            </TableCell>
                                            <TableCell className={classes.tableCellHeader}>
                                            </TableCell>
                                        </TableHead>
                                        {props.salaryHistory && props.salaryHistory.map((salary) => {
                                            return (
                                                <TableRow className={classes.tableRow}>
                                                    <TableCell className={classes.tableCell}>{salary.start_date}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {"$" + (salary.amount.toFixed(2)).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {salary.is_hourly ? "HOURLY" : "ANNUAL"}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <DeleteIcon
                                                            className={classes.iconButtonColored}
                                                            onClick={() => {
                                                                setLoadData(true);
                                                                http.Delete(
                                                                    `hr_employee_salaries/${salary.id}`,
                                                                    null,
                                                                    (res) => {
                                                                        if (props.fetchSalaries) props.fetchSalaries();
                                                                        setLoadData(false);
                                                                    },
                                                                    () => {
                                                                        if (props.fetchSalaries) props.fetchSalaries();
                                                                        setLoadData(false);
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </TableCell>


                                                </TableRow>
                                            );
                                        })}



                                    </Table>
                                </Grid>
                            </Grid>                        
                        </div>
                    </Paper>
                    <Divider/>
                </Grid>
            </Grid>
        )}
        </>
    );
};

export default EmployeeSalaryHistory;