import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import ScreenLoader from "../../Common/Loader";

const BillingForm = props => {
  const [formFields, setFormFields] = useState();
  const [tenants, setTenants] = useState();
  const [loadData, setLoadData] = useState(false);
  
  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `tenant_payment_infos/${props.objectId}`,
        (account) => {
          http.Get(
            `tenant_payment_infos`,
            (data) => {
              var paymentInfos = {};
              for (let i = 0; i < data.length; i++) {
                if(data[i].tenant !== account.tenant){
                  paymentInfos[data[i].tenant] = data[i].company_name;
                }          
              }
              http.Get(
                `tenants`,
                (data) => {
                  var valueMap = {};
                  for (let i = 0; i < data.length; i++) {
                    if (!paymentInfos[data[i].id]) {
                      valueMap[data[i].id] = data[i].company_name;
                    }
                  }
                  setTenants(valueMap);
                  setLoadData(false);
                },
                () => {
                  setLoadData(false);
                }
              );
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    }
    else{      
      setLoadData(true);
      http.Get(
        `tenant_payment_infos`,
        (data) => {
          var paymentInfos = {};
          for (let i = 0; i < data.length; i++) {
            paymentInfos[data[i].tenant] = data[i].company_name;
          }
          http.Get(
            `tenants`,
            (data) => {
              var valueMap = {};
              for (let i = 0; i < data.length; i++) {
                if (!paymentInfos[data[i].id]) {
                  valueMap[data[i].id] = data[i].company_name;
                }
              }
              setTenants(valueMap);
              setLoadData(false);
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if(tenants){
      setFormFields([
        {
          id: "tenant",
          label: "TENANT *",
          type: "select",
          valueMap: tenants,
        },
        {
          id: "vault_id",
          label: "vault id",
          type: "text"
        },
        {
          id: "card_number",
          label: "card number",
          type: "text"
        },
        {
          id: "expiration",
          label: "expiration (YYMM) *",
          type: "text"
        },
        {
          id: "unused_credit",
          label: "unused credit",
          type: "text"
        },
      ]);
    }
  }, [tenants]);

  return (
    <div>
      {loadData && (
        <ScreenLoader />
      )}
      {!loadData && formFields && (
        <ObjectForm
          {...props}
          url={`tenant_payment_infos`}
          formName={"Payment Info"}
          formFields={formFields}
        />
      )}
    </div>
  );
};

export default BillingForm;
