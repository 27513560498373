import React, { useEffect, useState } from "react";
import { Dialog, Drawer } from "@material-ui/core";
import ObjectList from "../Common/ObjectList";
import ObjectListMobile from "../Common/ObjectListMobile";
import http from "../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "../Common/Utility";
import { connect } from "react-redux";
import commonConstants from "../Common/CommonConstants";
import CreateIcon from "@material-ui/icons/Create";
import DeviceForm from "./Device/DeviceForm";
import ServiceForm from "./Service/ServiceForm";
import JsonDataForm from "./JsonData/JsonDataForm";
import EmployeeCredentialForm from "./Employee/EmployeeCredentialForm";
import DeviceCredentialForm from "./Device/DeviceCredentialForm";
import DeviceDetails from "./Device/DeviceDetails";
import ServiceDetails from "./Service/ServiceDetails";
import JsonDataDetails from "./JsonData/JsonDataDetails";
import EmployeeCredentialDetails from "./Employee/EmployeeCredentialDetails";
import DeviceCredentialDetails from "./Device/DeviceCredentialDetails";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { onCopy } from "../Common/TableUtility";
import SnackbarComponent from "../Common/Snackbar";

const useStyles = makeStyles((theme) => ({
    button: {
      margin: 10,
      color: theme.colorScheme,
      cursor:"pointer",
    },
  }));
  


const Passwords = (props) => {
  const classes = useStyles();  
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `companies/${props.user.company_id}/passwords`;
  const [object, setObject] = useState();
	const [width, setWidth] = useState(400);
  const [objectShow, setObjectShow] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState();
  
  const tableColumns = [
    {
      id: "name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: (props, object, property)=>{
        setObjectShow(object);
      },
    },
    {
      id: "model",
      label: "MODEL",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "copyUsername",
      label: "",
      minWidth: 10,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
    {
      id: "username",
      label: "USERNAME",
      minWidth: 200,
      sortable: false,
    },
    {
      id: "copyPassword",
      label: "",
      minWidth: 10,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
    {
      id: "staredPassword",
      label: "PASSWORD",
      minWidth: 100,
      sortable: false,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
    {
      id: "last_modified_by_name",
      label: "LAST MODIFIED BY",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "last_modified_time",
      label: "LAST MODIFIED AT",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "actions",
      label: " ",
      minWidth: 200,
      disableTooltip: true,
    },
  ];
    
	useEffect(() => {
		const vw = Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		);
		setWidth(vw * 0.8);
	}, [window.innerWidth]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var filteredData = data.filter((item)=>((item.model==="device" && props.user.role.devices_read)
        || (item.model==="companyservice" && props.user.role.services_read)
        || (item.model==="credential" && props.user.role.employees_read)
        || (item.model==="devicecredential" && props.user.role.devices_read)
        || (item.model.includes("jsondata") && props.user.role.custom_read)))
        
        filteredData.map((item)=>{
          item["copyUsername"] = (
            !item["username"]?"":<CopyIcon className={classes.iconColorScheme} onClick={(props,row,columnId)=>{onCopy(props,item,"username");setSnackBarMessage("Username Copied");}}/>
          );         

          item["copyPassword"] = (
            !item["password"]?"":<CopyIcon className={classes.iconColorScheme} onClick={(props,row,columnId)=>{onCopy(props,item,"password");setSnackBarMessage("Password Copied");}}/>
          );  

          item["staredPassword"] = (<span className={classes.passwordColorScheme} onClick={(e)=>{
              if (e.target.innerHTML === "************") e.target.innerHTML = item["password"]?item["password"].replaceAll("<","&lt;").replaceAll(">","&gt;"):"";
              else e.target.innerHTML = "************";}}>************</span>);
          
          item["last_modified_time"] = formatDate(item["last_modified_time"]);
          if((item.model==="device" && props.user.role.devices_modify)
            || (item.model==="companyservice" && props.user.role.services_modify)
            || (item.model==="credential" && props.user.role.employees_modify)
            || (item.model==="devicecredential" && props.user.role.devices_modify)
            || (item.model.includes("jsondata") && props.user.role.custom_modify)
          ){
            item.actions = 
                <CreateIcon
                  className={classes.button} 
                  onClick={()=>{
                    setObject(item);
                    setObjectShow(null);
                }}/>;
          }
        });
        setData(filteredData);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={()=>{setSnackBarMessage(null);}}
        severity={"success"}
      ></SnackbarComponent>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={Boolean(objectShow)}
        onClose={()=>{setObjectShow(null);}}>
        <div style={{padding:10}}>
          {objectShow && objectShow.model==="device" &&
            <DeviceDetails 
              {...props} 
              objectId={objectShow.id} 
              handleClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
              onClose={()=>{
                setObjectShow(null);
              }}
            />
          }
          {objectShow && objectShow.model==="companyservice" &&
            <ServiceDetails 
              {...props} 
              objectId={objectShow.id} 
              handleClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
              onClose={()=>{
                setObjectShow(null);
              }}
            />
          }
          {objectShow && objectShow.model==="credential" &&
            <EmployeeCredentialDetails 
              {...props} 
              objectId={objectShow.id} 
              handleClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
            />
          }
          {objectShow && objectShow.model==="devicecredential" &&
            <DeviceCredentialDetails 
              {...props} 
              objectId={objectShow.id} 
              handleClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
            />
          }
          {objectShow && objectShow.model.includes("jsondata") &&
            <JsonDataDetails 
              {...props} 
              objectId={objectShow.id} 
              handleClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
              onClose={()=>{
                setObjectShow(null);
                if(props.handleClose){
                  props.handleClose();
                }
              }}
            />
          }
          <div style={{float:"right"}}>{props.mobile && objectShow && objectShow.actions}</div>
        </div>
      </Dialog>
      <Drawer
        autoComplete="off"
        anchor="right"
        open={Boolean(object)}
        onClose={(e) => {
          setObject(null);
        }}
        disableEnforceFocus
      >
        <div style={{ width: width }}>
          {object && object.model==="device" &&
            <DeviceForm 
              {...props} 
              edit={true} 
              objectId={object.id} 
              closeOpen={()=>{setObject(null);}}
              handleObjectSubmit={()=>{
                fetchData();
                setObject(null);
              }}/>
          }
          {object && object.model==="companyservice" &&
            <ServiceForm 
              {...props} 
              edit={true} 
              objectId={object.id} 
              closeOpen={()=>{setObject(null);}}
              handleObjectSubmit={()=>{
                fetchData();
                setObject(null);
              }}/>
          }
          {object && object.model==="credential" &&
            <EmployeeCredentialForm 
              {...props} 
              edit={true} 
              objectId={object.id} 
              closeOpen={()=>{setObject(null);}}
              handleObjectSubmit={()=>{
                fetchData();
                setObject(null);
              }}/>
          }
          {object && object.model==="devicecredential" &&
            <DeviceCredentialForm 
              {...props} 
              edit={true} 
              objectId={object.id} 
              closeOpen={()=>{setObject(null);}}
              handleObjectSubmit={()=>{
                fetchData();
                setObject(null);
              }}/>
          }
          {object && object.model.includes("jsondata") &&
            <JsonDataForm 
              {...props} 
              edit={true} 
              objectId={object.id} 
              closeOpen={()=>{setObject(null);}}
              handleObjectSubmit={()=>{
                fetchData();
                setObject(null);
              }}/>
          }
        </div>
      </Drawer>
    {!props.mobile &&
    <ObjectList
      {...props}
      moduleName={"Passwords"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"last_modified_time"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Passwords"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile &&
    <ObjectListMobile
      {...props}
      moduleName={"Passwords"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"last_modified_time"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Passwords"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Passwords);
