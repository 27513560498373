import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import http from "../Common/RestAPIHandler";
import useStyles from "./styles";
import Logo from "../../Images/logo.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CommonConstants from "../Common/CommonConstants";

const ForgetPassword = props => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const errorText = CommonConstants.error;
  const urlParams = new URLSearchParams(window.location.search);
  var loginUrl = "/login";
  if(urlParams.get("userType")==="Company Employee"){
    loginUrl = "/portal/login";
  }
  if(urlParams.get("userType")==="Super User"){
    loginUrl = "/admin/login";
  }
  if(urlParams.get("userType")==="Reseller User"){
    loginUrl = "/reseller/login";
  }

  const onInputChange = e => {
    setEmail(e.target.value.trim());
    setHasError(false);
    setIsSubmit(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmit(true);
    setIsSuccess(false);
    setHasError(false);
    http.Post(
      "password_reset/?user_type=" + urlParams.get("userType"),
      { email: email },
      () => {
        setIsSuccess(true);
        setSuccessMessage(
          "If an account matching this address exists, an email will be sent to you shortly with instructions on how to reset your password. If you do not receive an email, please make sure you've entered the address you registered with, and check your spam folder."
        );
      },
      (status, error) => {
        setIsSubmit(true);
        setErrorMessage(
          "If an account matching this address exists, an email will be sent to you shortly with instructions on how to reset your password. If you do not receive an email, please make sure you've entered the address you registered with, and check your spam folder."
        );
        /*
        if (status === 404) {
          setErrorMessage(errorText.invalidEmailError);
        } else if (status === 403) {
          setErrorMessage("You are not eligible to reset password.");
        } else if (status === 400) {
          setErrorMessage(errorText.invalidEmailError);
        } else {
          setErrorMessage(
            "If an account matching this address exists, an email will be sent to you shortly with instructions on how to reset your password. If you do not receive an email, please make sure you've entered the address you registered with, and check your spam folder."
          );
          setIsSubmit(true);
        }*/
      }
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <ValidatorForm className={classes.form} onSubmit={onSubmit}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h4" component="h5" color="textSecondary">
                    Confirm Email Address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {(!isSubmit && (
                    <TextValidator
                      key="outlined-email-input"
                      label="E-mail"
                      type="text"
                      name="email"
                      variant="outlined"
                      autoFocus
                      fullWidth
                      value={email || ""}
                      onChange={onInputChange}
                      validators={["required", "trim"]}
                      errorMessages={[
                        errorText.requiredError,
                        errorText.blankError
                      ]}
                      error={hasError}
                      helperText={hasError && errorMessage}
                    />
                  )) || <p>{errorMessage}</p>}
                </Grid>

                {isSuccess && (
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <p style={{ color: "green" }}>{successMessage}</p>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={1} justify="center">
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={8}
                      align="center"
                      className={classes.centerElement}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.button}
                        disabled={isSubmit}
                      >
                        Send Password Reset Email
                      </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.centerElement}>
                      <Link
                        component={RouterLink}
                        to={loginUrl}
                        color="textPrimary"
                      >
                        Back to Login
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default ForgetPassword;
