import React, { useEffect, useState } from "react";
import http from "./RestAPIHandler";
import Popover from '@material-ui/core/Popover';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "./Utility";
import ScreenLoader from "./Loader";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
    button: {
      margin: 10,
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        border: "1px solid white",
      },
    },
    popover:{
      padding: "20px",
      maxWidth:"800px",
    },
    passwordColorScheme: {
      marginLeft: 10,
      cursor: "pointer",
      color: theme.colorScheme,
      fontFamily: "Raleway",
    },
  }));

const ObjectVersions = (props) => {
    const classes = useStyles();
    return (
        <Popover
            open={Boolean(props.anchorEl)}
            anchorEl={props.anchorEl}
            onClose={()=>{props.setAnchorEl(null);}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.popover}>
                {props.versions && props.versions.length === 0 &&
                  "No Previous Versions"
                }
                {props.versions && props.versions.map((version)=>{
                    var serialized_data = JSON.parse(version.serialized_data);
                    var values = [];
                    if(serialized_data[0].model === "api.credential"){
                        values.push(<div>Username: {String(serialized_data[0].fields["username"])}</div>);
                        values.push(<div>Password: 
                            <span
                              className={classes.passwordColorScheme}
                              onClick={(e) => {
                                if (e.target.innerHTML === "************")
                                  e.target.innerHTML = String(serialized_data[0].fields["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                else e.target.innerHTML = "************";
                              }}
                            >
                              ************
                            </span></div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.device"){
                        values.push(<div>Username: {String(serialized_data[0].fields["username"])}</div>);
                        values.push(<div>Password: 
                            <span
                              className={classes.passwordColorScheme}
                              onClick={(e) => {
                                if (e.target.innerHTML === "************")
                                  e.target.innerHTML = String(serialized_data[0].fields["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                else e.target.innerHTML = "************";
                              }}
                            >
                              ************
                            </span></div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.companyservice"){
                        values.push(<div>Username: {String(serialized_data[0].fields["username"])}</div>);
                        values.push(<div>Password: 
                            <span
                              className={classes.passwordColorScheme}
                              onClick={(e) => {
                                if (e.target.innerHTML === "************")
                                  e.target.innerHTML = String(serialized_data[0].fields["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                else e.target.innerHTML = "************";
                              }}
                            >
                              ************
                            </span></div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.softwarelicense"){
                        values.push(<div>Serial Number: {String(serialized_data[0].fields["serial_number"])}</div>);
                        values.push(<div>Install Date: {String(serialized_data[0].fields["install_date"])}</div>);
                        values.push(<div>Expiration Date: {String(serialized_data[0].fields["expiration_date"])}</div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.companyemployee"){
                        values.push(<div>Email: {String(serialized_data[0].fields["email"])}</div>);
                        values.push(<div>Mobile: {String(serialized_data[0].fields["mobile"])}</div>);
                        values.push(<div>Phone: {String(serialized_data[0].fields["phone_number"])}</div>);
                        values.push(<div>Workstation: {String(serialized_data[0].fields["work_station"])}</div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.site"){
                        values.push(<div>Address: {String(serialized_data[0].fields["address"])}</div>);
                        values.push(<div>City: {String(serialized_data[0].fields["city"])}</div>);
                        values.push(<div>State: {String(serialized_data[0].fields["state"])}</div>);
                        values.push(<div>Country: {String(serialized_data[0].fields["country"])}</div>);
                        values.push(<div>Website: {String(serialized_data[0].fields["website"])}</div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.companydocument"){  
                        values.push(<div>Title: {String(serialized_data[0].fields["title"])}</div>); 
                        values.push(<div>Content: </div>); 
                        values.push(<div
                            style={{ maxHeight: "calc(100vh - 260px)",
                              minHeight: "30px",
                              overflowY: "scroll",
                              overflowX: "auto", 
                              borderStyle: "solid",
                              borderColor: "#aaaaaa"
                            }}
                            dangerouslySetInnerHTML={{ __html: String(serialized_data[0].fields["content"]) }}
                          />); 
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.devicecredential"){
                        values.push(<div>Username: {String(serialized_data[0].fields["username"])}</div>);
                        values.push(<div>Password: 
                            <span
                              className={classes.passwordColorScheme}
                              onClick={(e) => {
                                if (e.target.innerHTML === "************")
                                  e.target.innerHTML = String(serialized_data[0].fields["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                else e.target.innerHTML = "************";
                              }}
                            >
                              ************
                            </span></div>);
                        values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                        values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else if(serialized_data[0].model === "api.jsondata" && props.jsonSchema){
                      var jsonSchema = JSON.parse(props.jsonSchema.json_schema);
                      let jsonData = JSON.parse(serialized_data[0].fields["json_data"]);
                      if(jsonSchema.properties){
                        Object.entries(jsonSchema.properties).map(([key, property]) => {
                          values.push(
                            <div>{key}:{" "}
                              {property.type === "boolean" && (
                                <>
                                  {jsonData[key] && <CheckIcon />}
                                </>
                              )}
                              {property.type === "password" && (
                                <>
                                <span
                                  className={classes.passwordColorScheme}
                                  onClick={(e) => {
                                    if (e.target.innerHTML === "············") {
                                      e.target.innerHTML = String(
                                        jsonData[key]
                                      )
                                        .replaceAll("<", "&lt;")
                                        .replaceAll(">", "&gt;");
                                    } else {
                                      e.target.innerHTML = "············";
                                    }
                                  }}
                                >
                                  ············
                                </span>
                                </>
                              )}
                              {!["boolean", "password"].includes(
                                property.type
                              ) && (
                                <>
                                {jsonData[key]}
                                </>
                              )}
                            </div>
                          );
                        });
                      }
                      values.push(<div>Last Modified By: {String(serialized_data[0].fields["last_modified_by_name"])}</div>);
                      values.push(<div>Last Modified At: {formatDate(serialized_data[0].fields["last_modified_time"])}</div>);
                    }
                    else{
                        for (var key in serialized_data[0].fields) {
                            if (serialized_data[0].fields.hasOwnProperty(key)) {
                                values.push(<div>{key}: {String(serialized_data[0].fields[key])}</div>);
                            }
                        }
                    }
                    values.push(
                    <div><Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        className={classes.outlinedButton}
                        onClick={()=>{
                        http.Get(
                            `version_revert?version_id=${version.id}`,
                            (data) => {
                                props.setAnchorEl(null);
                                props.fetchData();
                            },
                            () => {
                                props.setAnchorEl(null);
                                props.fetchData();
                            }
                        );
                        }}>Revert</Button>
                    </div>
                    );
                    return (<div>{values}</div>);
                })}
                {!props.versions &&
                    <ScreenLoader/>
                }
            </div>
        </Popover>
    );
};


export default ObjectVersions;