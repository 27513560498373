import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import ScreenLoader from "../../Common/Loader";
import { hasPermission } from "../../Common/permission";
import useStyles from "../../Common/Style/FormStyle";
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SnackbarComponent from "../../Common/Snackbar";
import { formatDateTime } from "../../Common/Utility";

const DocumentForm = (props) => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState();
  const [categories, setCategories] = useState();
  const [sites, setSites] = useState();
  const [files, setFiles] = useState();
  const [versions, setVersions] = useState({});
  const [objectId, setObjectId] = useState(props.objectId);
  const [loadData, setLoadData] = useState(false);
  const [tenantUsers, setTenantUsers] = useState();
  const [document, setDocument] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState();

  const getFiles = () => {
    setLoadData(true);
    if (objectId) {
      http.Get(
        `company_document_files/?company_document=${objectId}`,
        (data) => {
          var fileList = [];
          for (let i = 0; i < data.length; i++) {
            data[i]["onDelete"] = (fileId) => {
              http.Delete(
                `company_document_files/${fileId}/`,
                {},
                (data) => {
                  getFiles();
                },
                () => {}
              );
            };
            fileList.push(data[i]);
          }
          setFiles(fileList);
          setLoadData(false);
        },
        () => {}
      );
    } else {
      setFiles([]);
      setLoadData(false);
    }
  };

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `categories?category_type=Document Category`,
      (data) => {
        var valueMap = { "": "None" };
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );

    http.Get(
      `companies/${props.user.company_id}/sites`,
      (data) => {
        var valueMap = { "": "None" };
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].site_name;
        }
        setSites(valueMap);
      },
      () => {}
    );

    http.Get(
      `company_documents?company=${props.user.company_id}`,
      (data) => {
        var valueMap = {};
        var contentMap = {};
        for (let i = 0; i < data.length; i++) {
          if (data[i].id !== objectId) {
            valueMap[data[i].id] = data[i].title;
            contentMap[data[i].id] = data[i].content;
          } else {
            setDocument(data[i]);
          }
        }
      },
      () => {}
    );

    if (objectId) {
      http.Get(
        "company_document_versions/?document_id=" + objectId,
        (data) => {
          var valueMap = {};
          for (let i = 0; i < data.length; i++) {
            data[i].serialized_data = JSON.parse(data[i].serialized_data);
            valueMap[data[i].serialized_data[0].fields.content] =
              formatDateTime(
                data[i].serialized_data[0].fields.last_modified_time
              );
          }
          setVersions(valueMap);
        },
        () => {}
      );
    }

    getFiles();

    setLoadData(false);
  }, [objectId]);

  useEffect(() => {
    setLoadData(true);
    if (
      document &&
      document.is_locked &&
      !hasPermission(props, ["Lock Documents"])
    ) {
      http.Get(
        `company_document_tenant_user_mappings/?company_document=${objectId}&tenant_user=${props.user.tenant.id}`,
        (data) => {
          setTenantUsers(data);
          data.map((tenantUser) => {
            if (tenantUser.is_locked) {
              setTenantUsers([tenantUser]);
            } else {
              setTenantUsers(null);
            }
          });
          setLoadData(false);
        },
        () => {}
      );
    }
  }, [document]);

  useEffect(() => {
    if (
      sites &&
      categories &&
      files &&
      versions
    ) {
      setLoadData(true);
      if (hasPermission(props, ["Lock Documents"])) {
        setFormFields([
          {
            id: "is_locked",
            label: "Locked",
            type: "radio",
          },
          {
            id: "title",
            label: "TITLE *",
            type: "text",
          },
          {
            id: "site",
            label: "SITE",
            type: "select",
            valueMap: sites,
          },
          {
            id: "category",
            label: "CATEGORY",
            type: "select",
            valueMap: categories,
          },
          {
            id: "confidential_level",
            label: "SHARED",
            type: "select",
            valueMap: {
              "Tenant Only": "NO",
              "Shared To All": "YES",
            },
          },
          {
            id: "content",
            label: "CONTENT",
            type: "ckeditor",
          },
          {
            id: "ckeditorVersions",
            label: "VERSION",
            type: "select",
            valueMap: versions,
            onChange: (e, object, setObject, setErrors) => {
              setObject({
                ...object,
                content: e.target.value,
              });
            },
          },
          {
            id: "files",
            label: "FILES",
            type: "files",
            files: files,
            onChange: (e, object, setObject, setErrors) => {
              if (e.target.files && e.target.files.length === 1) {
                var formData = new FormData();
                formData.append("name", e.target.files[0].name);
                formData.append(
                  "file",
                  e.target.files[0],
                  e.target.files[0].name
                );
                if (object.id) {
                  formData.append("company_document", object.id);
                  http.Post(
                    "company_document_files/",
                    formData,
                    (data) => {
                      getFiles();
                    },
                    (status, error) => {
                      setErrors(error);
                    }
                  );
                } else {
                  formData.append("company_document", "");
                  var subDatas = object.subDatas;
                  if (!subDatas) {
                    subDatas = [];
                  }
                  files.push({
                    name: e.target.files[0].name,
                    onDelete: (fileName) => {
                      var newFiles = files.filter((file) => {
                        return file.name !== fileName;
                      });
                      setFiles(newFiles);
                      var newSubDatas = subDatas.filter((subData) => {
                        return subData.data.get("name") !== fileName;
                      });
                      setObject({
                        ...object,
                        subDatas: newSubDatas,
                      });
                    },
                  });
                  setFiles(files);
                  subDatas.push({
                    url: "company_document_files/",
                    data: formData,
                    id: "company_document",
                  });
                  setObject({
                    ...object,
                    subDatas: subDatas,
                  });
                }
              }
            },
          },
          {
            id: "dataMappings",
            label: "RELATED ENTRIES",
            type: "dataMapping",
            model: "Document",
            objectId: objectId,
          },
        ]);
      } else {
        setFormFields([
          {
            id: "title",
            label: "TITLE *",
            type: "text",
          },
          {
            id: "site",
            label: "SITE",
            type: "select",
            valueMap: sites,
          },
          {
            id: "category",
            label: "CATEGORY",
            type: "select",
            valueMap: categories,
          },
          {
            id: "confidential_level",
            label: "SHARED",
            type: "select",
            valueMap: {
              "Tenant Only": "NO",
              "Shared To All": "YES",
            },
          },
          {
            id: "content",
            label: "CONTENT",
            type: "ckeditor",
          },
          {
            id: "ckeditorVersions",
            label: "VERSION",
            type: "select",
            valueMap: versions,
            onChange: (e, object, setObject, setErrors) => {
              setObject({
                ...object,
                content: e.target.value,
              });
            },
          },
          {
            id: "files",
            label: "FILES",
            type: "files",
            files: files,
            onChange: (e, object, setObject, setErrors) => {
              if (e.target.files && e.target.files.length === 1) {
                var formData = new FormData();
                formData.append("name", e.target.files[0].name);
                formData.append(
                  "file",
                  e.target.files[0],
                  e.target.files[0].name
                );
                if (object.id) {
                  formData.append("company_document", object.id);
                  http.Post(
                    "company_document_files/",
                    formData,
                    (data) => {
                      getFiles();
                    },
                    (status, error) => {
                      setErrors(error);
                    }
                  );
                } else {
                  formData.append("company_document", "");
                  var subDatas = object.subDatas;
                  if (!subDatas) {
                    subDatas = [];
                  }
                  files.push({
                    name: e.target.files[0].name,
                    onDelete: (fileName) => {
                      var newFiles = files.filter((file) => {
                        return file.name !== fileName;
                      });
                      setFiles(newFiles);
                      var newSubDatas = subDatas.filter((subData) => {
                        return subData.data.get("name") !== fileName;
                      });
                      setObject({
                        ...object,
                        subDatas: newSubDatas,
                      });
                    },
                  });
                  setFiles(files);
                  subDatas.push({
                    url: "company_document_files/",
                    data: formData,
                    id: "company_document",
                  });
                  setObject({
                    ...object,
                    subDatas: subDatas,
                  });
                }
              }
            },
          },
          {
            id: "dataMappings",
            label: "RELATED ENTRIES",
            type: "dataMapping",
            model: "Document",
            objectId: objectId,
          },
        ]);
      }
      setLoadData(false);
    }
  }, [
    sites,
    categories,
    files,
    versions,
  ]);

  return (
    <div>
      {loadData && <ScreenLoader />}
      {!tenantUsers && formFields && (
        <ObjectForm
          {...props}
          objectId={objectId}
          url={`company_documents`}
          formName={"Document"}
          formFields={formFields}
          object={{ company: props.user.company_id, content: props.template.data || "" }}
        />
      )}
      {tenantUsers && (
        <div>
          <SnackbarComponent
            message={snackBarMessage}
            open={snackBarMessage}
            handleSnackBarClose={() => {
              setSnackBarMessage(null);
            }}
            severity={"success"}
          ></SnackbarComponent>
          <div className={classes.header}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h6" component="h6">
                  Document is Locked
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <IconButton
                  className={classes.iconButtonColored}
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => props.closeOpen(false)}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={classes.content}>
            {tenantUsers.length > 0 &&
              tenantUsers.map((tenantUser) => {
                return (
                  <p>
                    {tenantUser.is_locked
                      ? "You have sent an unlock request, please wait!"
                      : ""}
                  </p>
                );
              })}
          </div>
          <Divider />
          <div className={classes.footer}>
            <div className={classes.outlinedButtonContainer}>
              <Button
                variant="outlined"
                margin="dense"
                size="small"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => props.closeOpen(false)}
              >
                Cancel
              </Button>
              {tenantUsers.length === 0 && (
                <Button
                  variant="outlined"
                  margin="dense"
                  size="small"
                  className={classes.filledButton}
                  type="submit"
                  onClick={(e) => {
                    http.Post(
                      `company_document_tenant_user_mappings/`,
                      {
                        company_document: document.id,
                        tenant_user: props.user.id,
                      },
                      (data) => {
                        setSnackBarMessage("One time unlock request is sent");
                        setTimeout(() => {
                          props.closeOpen(false);
                        }, 5000);
                      },
                      () => {
                        setSnackBarMessage("One time unlock request is sent");
                        setTimeout(() => {
                          props.closeOpen(false);
                        }, 5000);
                      }
                    );
                  }}
                >
                  Unlock Request
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentForm;
