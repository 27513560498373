import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {Divider, Typography, Paper, Grid, IconButton,} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from '@material-ui/icons/Check';
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";

const SiteDetails = (props) => {
    const classes = useStyles();
    var { siteId } = props.match.params;  
    const [site, setSite] = useState({});
	const [dataMappings, setDataMappings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if(props.objectId){
        siteId = props.objectId;
      }
      http.Get(
        `companies/${props.user.company_id}/sites/${siteId}`,
        (site) => {
            setSite(site);

            http.Get(
              `data_mappings?from_id=${site.id}`,
              (data) => {
                setDataMappings(data);
                setLoading(false);
              },
              () => {
              }
            );
        },
        () => {}
      );
    }, []);

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <Grid container spacing={1}>
            <Grid item xs={9}>
                <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
                    {site.site_name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{paddingTop:10,float:"right"}}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => {
                            if(props.onClose){
                                props.onClose();
                            }
                            else{
                                props.history.push(
                                    process.env.PUBLIC_URL + `/company/${props.user.company_id}/sites`
                            );
                            }
                        }}
                    >
                    <CancelIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={9}>
                <Paper>
                    <div
                        style={{ maxHeight: "calc(100vh - 280px)",
                            minHeight: "calc(100vh - 280px)",
                            overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SITE NAME</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.site_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>ADDRESS</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.address}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>CITY</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.city}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>PROVINCE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.state}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>COUNTRY</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.country}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>PRIMARY CONTACT</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.primary_contact}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>PRIMARY CONTACT NUMBER</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.primary_contact_number}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>HEAD OFFICE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {site.is_head_office &&
                                    <CheckIcon/>
                                }
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
            <Grid item xs={3}>
                <Paper>
                <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                        minHeight: "calc(100vh - 280px)",
                        overflowY: "auto",
                        overflowX: "auto", 
                        marginLeft:10,
                        padding:10,
                    }}
                >
                <p style={{fontWeight:"bold"}}>Related Entries:</p>
                <DataMappings 
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
              </Paper>
          </Grid>
        </Grid>
        }
        </>
    );
};

export default SiteDetails;