import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import companyLogo from "../../Images/SyncMonkey_Registration_Logo.png";
import backsplash from "../../Images/SyncMonkey_Registration_BG.png";
import {
  Card,
  CssBaseline,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fefefefe",
    width: "auto",
    margin: "auto",
  },
  formContainer: {
    alignSelf: "center",
    padding: 50
  },
  fontColor: {
    color: "#f4f4f4",
  },
  leftPanel: {
    display: "flex",
    backgroundColor: "#2a2d38",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backsplash})`,
    backgroundPosition: "bottom",
  },
  loginButton: {
    borderRadius: 5,
    color: "#d9d941",
    textTransform: "capitalize",
    borderColor: "#d9d941",
    "&:hover": {
      color: "#e4e643",
      borderColor: "#e4e643",
      backgroundColor: "#9c9ba0",
    },
    width: 300
  },
  logo: {
    maxWidth: "100%",
  },
  pageContainer: {
    minHeight: "100vh",
    width: "auto",
    overflow: "hidden"
  },
  rightPanel: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
		backgroundColor: "#f7f9fc",
    alignItems: "center",
  },
  spacer: {
    height: 200,
    [theme.breakpoints.down("md")]: {
      height: 10,
    },
  },
}));

const Registered = (props) => {
  const classes = useStyles();
  React.useEffect(() => {
    const script1 = document.createElement('script');  
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-FJW8VPDDCX";
    script1.async = true;  
    document.body.appendChild(script1);

    const script2 = document.createElement('script');  
    script2.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-FJW8VPDDCX');";
    script2.async = false;  
    document.body.appendChild(script2);
  
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={0} className={classes.pageContainer}>
        <Grid item md={12} lg={5} className={classes.leftPanel}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.fontColor}>
                  WELCOME TO{" "}
                  <span style={{ color: "#d9d941" }}>SYNCMONKEY</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.fontColor}>
                  Empower your MSP and manage your client's documents and
                  accesses in one place. Register now to start your 15 day
                  trial.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.fontColor}>
                  Already have an account?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.loginButton}
                  size="large"
                  onClick={() => {
                    window.location.replace(process.env.PUBLIC_URL + "/login");
                  }}
                >
                  LOG IN
                </Button>
                <div className={classes.spacer}>&nbsp;</div>
              </Grid>
            </Grid>
        </Grid>
        <Grid item md={12} lg={7} className={classes.rightPanel}>
          <Grid container spacing={3} className={classes.formContainer}>
            <Grid item xs={12}>
              <img src={companyLogo} alt="SyncMonkey" className={classes.logo}></img>
            </Grid>
            <Grid item xs={12}>
              <br/>
              <br/>
              <Typography variant="h4" color="inherit">
                Thanks for registering!
              </Typography>
              <br/>
              <br/>
              <Typography variant="subtitle1">
                We still have a few more steps to go just to confirm your email address and complete your account setup.
                Please check your inbox and click the link emailed to you to continue.
              </Typography>
              <br/>
              <div className={classes.spacer}>&nbsp;</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Registered;
