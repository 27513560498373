import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import BillingForm from "./BillingForm";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import commonConstants from "../../Common/CommonConstants";

const redirect = (props, object, property) => {
  props.history.push(
    "./billing/" + object.tenant + "/details"
  );
};

const useStyles = makeStyles((theme) => ({
  cardImage: {
    width: "100%",
    maxHeight: 80,
  },
  chip: {
    borderRadius: 8,
    backgroundColor: theme.colorScheme,
    color: "white",
    marginRight: "2px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
}));

const Billing = (props) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);

  const today = new Date();

  const tableColumns = [
    {
      id: "company_name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: redirect,
    },
		{
			id: "tenant",
			label: "ID",
			minWidth: 300,
			sortable: false,
		},
    {
      id: "card_number",
      label: "CARD ON FILE",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "expiration",
      label: "EXP(YY/MM)",
      minWidth: 200,
      sortable: true,
    },
  ];

  const url = `tenant_payment_infos`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item) => {
          {
            item &&
              item.card_number &&
              item.card_number.startsWith("1") &&
              (item.icon = (
                <img
                  className={classes.cardImage}
                  src="http://www.credit-card-logos.com/images/american_express_credit-card-logos/american_express_logo_7.gif"
                />
              ));
          }
          {
            item &&
              item.card_number &&
              item.card_number.startsWith("4") &&
              (item.icon = (
                <img
                  className={classes.cardImage}
                  src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_logo_6.gif"
                />
              ));
          }
          {
            item &&
              item.card_number &&
              item.card_number.startsWith("5") &&
              (item.icon = (
                <img
                  className={classes.cardImage}
                  src="http://www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_5.gif"
                />
              ));
          }
          if (item.card_number)
            item.card_number = "****" + item.card_number.slice(-4);
          var expYear = Math.floor(item.expiration / 100);
          var expMonth = item.expiration % 100;
          if (expMonth > 9) {
            item.expiration = expYear + "/" + expMonth;
          } else {
            item.expiration = expYear + "/0" + expMonth;
          }
          if (
            2000 + expYear < today.getFullYear() ||
            (2000 + expYear === today.getFullYear() &&
              expMonth - 1 < today.getMonth())
          ) {
            item.isExpired = <ClearIcon />;
          } else if (
            2000 + expYear === today.getFullYear() &&
            expMonth - 1 === today.getMonth()
          ) {
            item.isExpired = <WarningIcon />;
          }
        },
        setData(data)
        );

        setLoadData(false);
      },
      () => {}
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Billing"}
      url={url}
      actions={actions}
      permissions={{
        create: ['Create/Modify Tenant Billing Info'],
        edit: ['Create/Modify Tenant Billing Info'],
        delete: ['Delete Tenant Billing Info'],
      }}
      objectForm={BillingForm}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Billing"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Billing"}
      url={url}
      actions={actions}
      permissions={{
        create: ['Create/Modify Tenant Billing Info'],
        edit: ['Create/Modify Tenant Billing Info'],
        delete: ['Delete Tenant Billing Info'],
      }}
      objectForm={BillingForm}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Billing"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Billing;
