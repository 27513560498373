import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import ScreenLoader from "../../Common/Loader";
import PlacesAutocomplete from 'react-places-autocomplete';

const AddressForm = (props) => {
  const classes = useStyles();
  const [address, setAddress] = useState({ tenant: props.user.tenant });
  const [addressError, setAddressError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const {
    requiredError,
    blankError,
    urlError,
    websitePlaceholder,
  } = commonConstants.error;

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `tenant_address/${props.objectId}`,
        (address) => {
          setAddress(address);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  const onChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
    setAddressError({
      ...addressError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `tenant_address/${props.objectId}`,
        address,
        () => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setAddressError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `tenant_address`,
        address,
        () => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setAddressError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.edit ? "Edit Address" : "Add New Address"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>ADDRESS *</p>
              <TextValidator
                id="address"
                fullWidth
                variant="outlined"
                margin="dense"
                name="address"
                value={address.address || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(addressError.address)}
                helperText={addressError.address && addressError.address[0]}
              />

              <p className={classes.label}>COUNTRY *</p>
              <TextValidator
                id="country"
                fullWidth
                variant="outlined"
                margin="dense"
                name="country"
                value={address.country || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(addressError.country)}
                helperText={addressError.country && addressError.country[0]}
              />

              <p className={classes.label}>PROVINCE *</p>
              <TextValidator
                id="state"
                fullWidth
                variant="outlined"
                margin="dense"
                name="state"
                value={address.state || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(addressError.state)}
                helperText={addressError.state && addressError.state[0]}
              />

              <p className={classes.label}>CITY *</p>
              <TextValidator
                id="city"
                fullWidth
                variant="outlined"
                margin="dense"
                name="city"
                value={address.city || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(addressError.city)}
                helperText={addressError.city && addressError.city[0]}
              />

              <p className={classes.label}>POSTAL *</p>
              <TextValidator
                id="postal"
                fullWidth
                variant="outlined"
                margin="dense"
                name="postal"
                value={address.postal || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(addressError.postal)}
                helperText={addressError.postal && addressError.postal[0]}
              />

              <p className={classes.label}>PHONE</p>
              <TextValidator
                id="phone"
                fullWidth
                variant="outlined"
                margin="dense"
                name="phone"
                value={address.phone || ""}
                onChange={onChange}
                inputProps={{ maxLength: 50 }}
                error={Boolean(addressError.phone)}
                helperText={addressError.phone && addressError.phone[0]}
              />

              <p className={classes.label}>WEBSITE</p>
              <TextValidator
                id="website"
                fullWidth
                variant="outlined"
                margin="dense"
                name="website"
                value={address.website || ""}
                placeholder={websitePlaceholder}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.website)}
                helperText={addressError.website && addressError.website[0]}
              />
              <p className={classes.label}>FAX</p>
              <TextValidator
                id="fax"
                fullWidth
                variant="outlined"
                margin="dense"
                name="fax"
                value={address.fax}
                onChange={onChange}
                error={Boolean(addressError.fax)}
                helperText={addressError.fax && addressError.fax[0]}
              />
            </div>
          )}
        </div>

        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AddressForm;
