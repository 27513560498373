import React, { useEffect, useState } from "react";
import ObjectList from "../../../Common/ObjectList";
import ObjectListMobile from "../../../Common/ObjectListMobile";
import commonConstants from "../../../Common/CommonConstants";
import http from "../../../Common/RestAPIHandler";
import { onWebsiteClick, onCopy } from "../../../Common/TableUtility";
import SnackbarComponent from "../../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CopyIcon from "@material-ui/icons/FileCopy";
import Popover from '@material-ui/core/Popover';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  stableSort,
  getSorting,
} from "../../../Common/Utility";
import ConfigurationForm from "./ConfigurationForm";
import {myVault} from "../../../Common/Languages";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    float:"right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    fontFamily: "Raleway",
  },
  button: {
    margin: 10,
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  buttonCancel: {
    margin: 10,
    borderRadius: 25,
  },
  popover:{
    padding: "20px",
    width:"400px",
    maxWidth:"600px"
  },
  popoverMobile:{
    padding: "20px",
    width:"300px",
    maxWidth:"350px"
  }
}));

const tableColumns = [
  {
    id: "name",
    label: myVault.NAME[lang],
    minWidth: 150,
    sortable: true,
  },
  {
      id: "usage",
      label: myVault.USAGE[lang],
      minWidth: 100,
      sortable: false,
  },
  // {
  //   id: "copyUsername",
  //   label: "",
  //   minWidth: 10,
  //   sortable: false,
  //   disableTooltip: true,
  // },
  // {
  //   id: "username",
  //   label: "USERNAME",
  //   minWidth: 100,
  //   sortable: true,
  // },
  // {
  //   id: "copyPassword",
  //   label: "",
  //   minWidth: 10,
  //   sortable: false,
  //   disableTooltip: true,
  // },
  // {
  //   id: "staredPassword",
  //   label: "PASSWORD",
  //   minWidth: 100,
  //   sortable: false,
  //   disableTooltip: true,
  // },
  // {
  //   id: "url",
  //   label: "URL",
  //   minWidth: 150,
  //   sortable: true,
  //   onClick: onWebsiteClick,
  //   style: commonConstants.cellCursor,
  //   colored: true,
  // },
  {
    id: "expire_at",
    label: myVault.EXPIREAT[lang],
    minWidth: 150,
    sortable: true,
  },
  {
    id: "copyLink",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "link",
    label: myVault.Link[lang],
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "move",
    label: " ",
    minWidth: 10,
    disableTooltip: true,
  },
  {
    id: "lock",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  }
];

const Configurations = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [object, setObject] = useState();
  const [companies, setCompanies] = useState();
  const [company, setCompany] = useState();
  const [employees, setEmployees] = useState();
  const [employee, setEmployee] = useState();
  const [newEmployee, setNewEmployee] = useState();
  const [moveTo, setMoveTo] = useState();
  const [devices, setDevices] = useState();
  const [device, setDevice] = useState();
  const [services, setServices] = useState();
  const [service, setService] = useState();
  const [categories, setCategories] = useState();  
  const [category, setCategory] = useState();
  const [severity, setSeverity] = useState("success");
  const url = `tenant_user_configurations`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    create: true
  };

  useEffect(() => {
    fetchData();
    http.Get(
      `categories`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );

    http.Get(
      `companies`,
      (data) => {
        setCompanies(data);
      },
      () => {}
    );

  }, []);

  useEffect(() => {
    if(company){
      http.Get(
        `company_employees?company_id=${company}`,
        (data) => {
          var sortedObjects = stableSort(data, getSorting("asc", "employee_name"));
          setEmployees(sortedObjects);
        },
        () => {
        }
      );   
      http.Get(
        `companies/${company}/devices`,
        (data) => {
          var sortedObjects = stableSort(data, getSorting("asc", "device_name"));
          setDevices(sortedObjects);
        },
        () => {
        }
      );  
      http.Get(
        `companies/${company}/services`,
        (data) => {
          var sortedObjects = stableSort(data, getSorting("asc", "service_name"));
          setServices(sortedObjects);
        },
        () => {
        }
      );
    }
  }, [company]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item)=>{
          
          item["copyUsername"] = !item["username"]?"":<CopyIcon
          fontSize="small"
          className={classes.iconColorScheme}
          onClick={(props, row, columnId) => {
            onCopy(props, item, "username");
            setSeverity("success");
            setSnackBarMessage("Username Copied");
          }}
          />;
          
        item["copyPassword"] = !item["password"]?"":<CopyIcon
          fontSize="small"
          className={classes.iconColorScheme}
          onClick={(props, row, columnId) => {
            onCopy(props, item, "password");
            setSeverity("success");
            setSnackBarMessage("Password Copied");
          }}
          />;

        item["lock"] = !item["passphrase"]?<LockOpenIcon/>:<LockIcon/>;
          

        item["staredPassword"] = (
          <span
            className={classes.passwordColorScheme}
            onClick={(e) => {
              if (e.target.innerHTML === "************")
                e.target.innerHTML = String(item["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
              else e.target.innerHTML = "************";
            }}
          >
            ************
          </span>
        );

        item['move'] = <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={(e)=>{
                setObject(item);
                setMoveTo(null);
                setCompany(null);
                setDevice(null);
                setService(null);
                setEmployee(null);
                setAnchorEl(e.currentTarget)
                }}>{myVault.Move[lang]}</Button>;
        
                item["link"] = window.location.origin + process.env.PUBLIC_URL + "/configuration/" + item["series"]
                item["copyLink"] = !item["link"]?"":<CopyIcon
                fontSize="small" 
                className={classes.iconColorScheme}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "link");
                  setSeverity("success");
                  setSnackBarMessage("Link Copied");
                }}
                />;
                
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <div>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={severity}
      ></SnackbarComponent>
      {anchorEl &&        
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={()=>{setAnchorEl(null);}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
          <div className={props.mobile ? classes.popoverMobile : classes.popover}>
            <h3>Name</h3>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              value={object["name"]}/>
            <h3>Move To</h3>
            <h3>Company</h3>            
            <Select
              fullWidth
              variant="outlined"
              margin="dense"
              onChange={(e) => {
                setDevice(null);
                setService(null);
                setEmployee(null);
                setMoveTo(null);
                setCompany(e.target.value);
              }}
            >
              <MenuItem value={""}>
                {""}
              </MenuItem>
              {companies && companies.map((company)=>{
                return <MenuItem key={company.id} value={company.id}>{company.company_name}</MenuItem>;
              })}
            </Select>
            {company && 
              <>
              <br/><h3>Data Type</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e) => {
                  setDevice(null);
                  setService(null);
                  setEmployee(null);
                  setMoveTo(e.target.value);
                }}
              >
                <MenuItem value={""}>
                  {""}
                </MenuItem>
                <MenuItem value={"Servers & Devices"}>
                  {"Servers & Devices"}
                </MenuItem>
                <MenuItem value={"Other Services"}>
                  {"Other Service"}
                </MenuItem>
                <MenuItem value={"Employee Credentials"}>
                  {"Employee Credentials"}
                </MenuItem>
              </Select>
              </>
            }
            {devices && moveTo && moveTo === "Servers & Devices" &&
              <>
              <br/><h3>Device</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setDevice(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {"Create New"}
                </MenuItem>
                {devices.map((device)=>{
                  return <MenuItem key={device.id} value={device.id}>{device.device_name}</MenuItem>;
                })}
              </Select>
              {device && device !== "Create New" &&
                <>
                <br/><h3>Credential Type</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Credential Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{                    
                      var data ={
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "device":device,
                        "category":category,
                      };
                      http.Post(
                        `device/credential/`,
                        data,
                        (newDevice) => {            
                          http.Delete(
                            "tenant_user_configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                        }
                      );
                    }}>Move</Button>
                  }
                  </>
                }
              {device && device === "Create New" &&
                <>
                <br/><h3>Device Type</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Device Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var data ={
                        "device_name":object["name"],
                        "url":object["url"],
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "company_id":company,
                        "category":category,
                      };
                      http.Post(
                        `companies/${company}/devices`,
                        data,
                        (newDevice) => {            
                          http.Delete(
                            "tenant_user_configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                        }
                      );
                    }}>Move</Button>
                  }
                  </>
                }
                </>
            }
            {services && moveTo && moveTo === "Other Services" &&
              <>
              <br/><h3>Service</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setService(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {"Create New"}
                </MenuItem>
                {services.map((service)=>{
                  return <MenuItem key={service.id} value={service.id}>{service.service_name}</MenuItem>;
                })}
              </Select>              
              {service && service !== "Create New" &&
                <>
                <br/>Current credential will be overwritten.<br/>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.outlinedButton}
                  onClick={()=>{
                    var data = services.filter((s)=>{return s.id===service;})[0];
                    data["username"] = object["username"];
                    data["password"] = object["password"];
                    http.Put(
                      `companies/${company}/services/${service}`,
                      data,
                      (newService) => {            
                        http.Delete(
                          "tenant_user_configurations/" + object["id"],
                          {},
                          () => {
                            setAnchorEl(null);
                            fetchData();
                          },
                          (status, error) => {
                            setSeverity("error");
                            setSnackBarMessage(JSON.stringify(error));
                          }
                        );
                      },
                      (status, error) => {
                        setSeverity("error");
                        setSnackBarMessage(JSON.stringify(error));
                      }
                    );
                  }}>Move</Button>
                  </>
                }
              {service && service === "Create New" &&
                <>
                <br/><h3>Service Type</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Service Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var url = object["url"];
                      if(url && !url.startsWith("http")){
                        url = "http://" + url;
                      }
                      var data ={
                        "service_name":object["name"],
                        "website":url,
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "company_id":company,
                        "category": category,
                      };
                      http.Post(
                        `companies/${company}/services`,
                        data,
                        (newService) => {            
                          http.Delete(
                            "tenant_user_configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                        }
                      );
                    }}>Move</Button>
                  }
                </>
                }
              </>
            }
            {employees && moveTo && moveTo === "Employee Credentials" &&
              <>
              <br/><h3>Employee</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setEmployee(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {"Create New"}
                </MenuItem>
                {employees.map((employee)=>{
                  return <MenuItem key={employee.id} value={employee.id}>{employee.employee_name}</MenuItem>;
                })}
              </Select>
              {employee && employee === "Create New" && categories &&
                <>
                <br/><h3>Employee Name</h3>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={newEmployee}
                  onChange={(e)=>{
                    setNewEmployee(e.target.value);
                  }}></TextField>
                <br/><h3>Account Type</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Account Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && newEmployee &&
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{                      
                      http.Post(
                        `company_employees`,
                        {employee_name:newEmployee,company_id:company,status:true},
                        (e) => {
                          var url = object["url"];
                          if(url && !url.startsWith("http")){
                            url = "http://" + url;
                          }
                          var credential ={
                            "description":object["name"],
                            "website":url,
                            "username":object["username"],
                            "password":object["password"],
                            "notes":object["notes"],
                            "employee":e.id,
                            "category":category,
                          };
                          http.Post(
                            `employee/credential/`,
                            credential,
                            (newCredential) => {
                              http.Delete(
                                "tenant_user_configurations/" + object["id"],
                                {},
                                data => {
                                  setAnchorEl(null);
                                  fetchData();
                                },
                                (status, error) => {
                                  setSeverity("error");
                                  setSnackBarMessage(JSON.stringify(error));
                                }
                              );
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                        }
                      );
                    }}>Move</Button>
                }
                </>
              }
              {employee && employee !== "Create New" && categories &&
                <>
                <br/><h3>Account Type</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Account Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var url = object["url"];
                      if(url && !url.startsWith("http")){
                        url = "http://" + url;
                      }
                      var credential ={
                        "description":object["name"],
                        "website":url,
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "employee":employee,
                        "category":category,
                    };
                    http.Post(
                      `employee/credential/`,
                      credential,
                      (newCredential) => {
                        http.Delete(
                          "tenant_user_configurations/" + object["id"],
                          {},
                          data => {
                            setAnchorEl(null);
                            fetchData();
                          },
                          (status, error) => {
                            setSeverity("error");
                            setSnackBarMessage(JSON.stringify(error));
                          }
                        );
                      },
                      (status, error) => {
                        setSeverity("error");
                        setSnackBarMessage(JSON.stringify(error));
                      }
                    );
                  }}>Move</Button>
                }
                </>
              }
              </>
            }
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButtonCancel}
              onClick={()=>{
                setAnchorEl(null);
              }}>
                Cancel
            </Button>
          </div>
        </Popover>
      }
      {!props.mobile &&
      <ObjectList
        {...props}
        moduleName={myVault.Vault[lang]}
        url={url}
        actions={actions}
        permissions={{
          create: ["Create/Update Data"],
          edit: ["Create/Update Data"],
          delete: ["Remove Data"],
        }}
        objectForm={ConfigurationForm}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        orderBy={"name"}
        searchFields={["name", "notes"]}
        pageHeader={(props.user.company_name || "") + " | Vault"}
        loadData={loadData}
      />
      }
      {props.mobile &&
      <ObjectListMobile
        {...props}
        moduleName={"Vault"}
        url={url}
        actions={actions}
        permissions={{
          create: ["Create/Update Data"],
          edit: ["Create/Update Data"],
          delete: ["Remove Data"],
        }}
        objectForm={ConfigurationForm}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        orderBy={"name"}
        searchFields={["name", "notes"]}
        pageHeader={(props.user.company_name || "") + " | Vault"}
        loadData={loadData}
      />
      }
    </div>
  );
};


export default Configurations;
