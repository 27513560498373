import ContactPhoneIcon from "@material-ui/icons/ContactPhoneOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import StorageIcon from "@material-ui/icons/StorageOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembershipOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import HomeIcon from '@material-ui/icons/Home';
import ArchiveIcon from '@material-ui/icons/Archive';
import PaymentIcon from '@material-ui/icons/Payment';
import SyncIcon from '@material-ui/icons/Sync';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PhotoIcon from '@material-ui/icons/Photo';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from "@material-ui/icons/Storefront";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShareIcon from '@material-ui/icons/Share';
import {index,leftBar,companySetting,topNav} from "../Languages";

const lang = localStorage.getItem("languages");

export const menus = {
  companies: [
    {
      id: 1,
      name: "Company Details",
      link: process.env.PUBLIC_URL + "/company/id",
      icon: HomeIcon,
      permissions: ["View Data"],
    },
    {
      id: 2,
      name: leftBar.Sites[lang],
      link: process.env.PUBLIC_URL + "/company/id/sites",
      icon: BusinessIcon,
      permissions: ["View Data"],
    },
    {
      id: 3,
      name: leftBar.Documents[lang],
      link: process.env.PUBLIC_URL + "/company/id/documents",
      icon: DescriptionIcon,
      permissions: ["View Data"],
    },
    {
      id: 4,
      name: leftBar.Employees[lang],
      link: process.env.PUBLIC_URL + "/company/id/employees",
      icon: PeopleAltIcon,
      permissions: ["View Data"],
    },
    {
      id: 5,
      name: leftBar.ServersDevices[lang],
      link: process.env.PUBLIC_URL + "/company/id/servers_devices",
      icon: StorageIcon,
      permissions: ["View Data"],
    },
    {
      id: 6,
      name: leftBar.SoftwareLicenses[lang],
      link: process.env.PUBLIC_URL + "/company/id/software_licenses",
      icon: CardMembershipIcon,
      permissions: ["View Data"],
    },
    {
      id: 7,
      name: leftBar.OtherServices[lang],
      link: process.env.PUBLIC_URL + "/company/id/other_services",
      icon: ContactPhoneIcon,
      permissions: ["View Data"],
    },
    {
      id: 8,
      name: leftBar.Domains[lang],
      link: process.env.PUBLIC_URL + "/company/id/domains",
      icon: LanguageIcon,
      permissions: ["View Data"],
    },
    {
      id: 9,
      name: leftBar.Archives[lang],
      link: process.env.PUBLIC_URL + "/company/id/archives",
      icon: ArchiveIcon,
      permissions: ["Company Archive Access"],
    },
    {
      id: 10,
      name: leftBar.ImportVault[lang],
      link: process.env.PUBLIC_URL + "/company/id/configurations",
      icon: ImportExportIcon,
      permissions: ["View Data"],
    },
    {
      id: 11,
      name: leftBar.Gallery[lang],
      link: process.env.PUBLIC_URL + "/company/id/files",
      icon: PhotoIcon,
      permissions: ["View Data"],
    },
    {
      id: 12,
      name: leftBar.PasswordVault[lang],
      link: process.env.PUBLIC_URL + "/company/id/passwords",
      icon: VpnKeyIcon,
      permissions: ["View Data"],
    },
  ],
  settings: [
    {
      id: 1,
      name: companySetting.CompanyDetails[lang],
      icon: BusinessIcon,
      items: [
        {
          id: 1,
          name: companySetting.General[lang],
          link: process.env.PUBLIC_URL + "/settings/profile",
        },
        {
          id: 2,
          name: companySetting.Addresses[lang],
          link: process.env.PUBLIC_URL + "/settings/address",
        },
      ],
      permissions: [],
    },
    {
      id: 2,
      name: companySetting.Billing[lang],
      icon: PaymentIcon,
      items: [
        {
          id: 1,
          name: companySetting.Invoices[lang],
          link: process.env.PUBLIC_URL + "/settings/invoices",
        },
        {
          id: 2,
          name: companySetting.Subscription[lang],
          link: process.env.PUBLIC_URL + "/settings/subscription",
        },
      ],
      permissions: [],
    },
    {
      id: 3,
      name: companySetting.UsersControl[lang],
      icon: PeopleAltIcon,
      items: [
        {
          id: 1,
          name: companySetting.Users[lang],
          link: process.env.PUBLIC_URL + "/settings/users",
        },
        {
          id: 2,
          name: companySetting.SecurityControl[lang],
          link: process.env.PUBLIC_URL + "/settings/user/roles",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 4,
      name: companySetting.Sharing[lang],
      icon: ShareIcon,
      items: [
        {
          id: 1,
          name: companySetting.ToTenants[lang],
          link: process.env.PUBLIC_URL + "/settings/to_tenants",
        },
        {
          id: 2,
          name: companySetting.FromTenants[lang],
          link: process.env.PUBLIC_URL + "/settings/from_tenants",
        },
        {
          id: 3,
          name: companySetting.SharedCompanies[lang],
          link: process.env.PUBLIC_URL + "/settings/sharings",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 5,
      name: companySetting.Customization[lang],
      icon: DescriptionIcon,
      items: [
        {
          id: 1,
          name: companySetting.Categories[lang],
          link: process.env.PUBLIC_URL + "/settings/categories",
        },
        {
          id: 2,
          name: companySetting.Templates[lang],
          link: process.env.PUBLIC_URL + "/settings/templates",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 6,
      name: companySetting.DeveloperSpace[lang],
      icon: StorageIcon,
      items: [
        {
          id: 1,
          name: companySetting.Extensions[lang],
          link: process.env.PUBLIC_URL + "/settings/extensions",
        },
        {
          id: 2,
          name: companySetting.CustomizedData[lang],
          link: process.env.PUBLIC_URL + "/settings/json_schemas",
        },
        {
          id: 2,
          name: "API User Management",
          link: process.env.PUBLIC_URL + "/settings/api_user_management",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 7,
      name: companySetting.DataAdministration[lang],
      icon: SyncIcon,
      items: [
        {
          id: 1,
          name: "AutoTask Sync",
          link: process.env.PUBLIC_URL + "/settings/syncAutoTask",
          integration: "autotask"
        },
        {
          id: 2,
          name: "ConnectWise Sync",
          link: process.env.PUBLIC_URL + "/settings/sync",
          integration: "connect_wise"
        },
        {
          id: 3,
          name: "Ninja Sync",
          link: process.env.PUBLIC_URL + "/settings/syncNinja",
          integration: "ninja"
        },
        {
          id: 4,
          name: "Syncro Sync",
          link: process.env.PUBLIC_URL + "/settings/syncSyncro",
          integration: "syncro"
        },
        {
          id: 5,
          name: "Merge Company",
          link: process.env.PUBLIC_URL + "/settings/merges",
          integration: "has_integration"
        }
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 8,
      name: companySetting.PortalManagement[lang],
      icon: PeopleAltIcon,
      items: [
        {
          id: 1,
          name: companySetting.Users[lang],
          link: process.env.PUBLIC_URL + "/settings/portal-users",
        },
        {
          id: 2,
          name: companySetting.SecurityControl[lang],
          link: process.env.PUBLIC_URL + "/settings/portal-user/roles",
        },
      ],
      permissions: ["Manage Portal Users"],
    },
    {
      id: 9,
      name: companySetting.Archives[lang],
      link: process.env.PUBLIC_URL + "/settings/archives",
      icon: ArchiveIcon,
      permissions: ["Manage Users"],
    },
  ],
  tenants: [
    {
      id: 1,
      name: topNav.Companies[lang],
      link: process.env.PUBLIC_URL + "/company",
      icon: BusinessIcon,
      permissions: [],
    },
    {
      id: 2,
      name: topNav.Resources[lang],
      link: process.env.PUBLIC_URL + "/documents",
      icon: DescriptionIcon,
      permissions: [],
    },
    {
      id: 3,
      name: topNav.Services[lang],
      link: process.env.PUBLIC_URL + "/services",
      icon: ContactPhoneIcon,
      permissions: [],
    },
    {
      id: 4,
      name: topNav.Vendors[lang],
      link: process.env.PUBLIC_URL + "/support",
      icon: StorefrontIcon,
      permissions: [],
    },
    {
      id: 5,
      name: topNav.Meetings[lang],
      link: process.env.PUBLIC_URL + "/meeting",
      icon: MeetingRoomIcon,
      permissions: [],
    },
    {
      id: 6,
      name: topNav.Accounting[lang],
      link: process.env.PUBLIC_URL + "/accounting",
      icon: ReceiptIcon,
      permissions: [],
    },
  ],
  hr: [
    {
      id: 1,
      name: topNav.Dashboard[lang],
      link: process.env.PUBLIC_URL + "/hr",
      icon: HomeIcon,
      permissions: ["HR"],
    },
    {
      id: 2,
      name: topNav.Employees[lang],
      link: process.env.PUBLIC_URL + "/hr/employees",
      icon: PeopleAltIcon,
      permissions: ["View Employee Profile"],
    },
    {
      id: 3,
      name: topNav.JobPostings[lang],
      link: process.env.PUBLIC_URL + "/hr/jobPostings",
      icon: WorkIcon,
      permissions: ["View Job Postings"],
    },
    {
      id: 4,
      name: topNav.Reports[lang],
      link: process.env.PUBLIC_URL + "/hr/reports",
      icon: DescriptionIcon,
      permissions: ["Report Access"],
    },
    {
      id: 5,
      name: topNav.Settings[lang],
      icon: SettingsIcon,
      items: [
        {
          id: 1,
          name: topNav.General[lang],
          link: process.env.PUBLIC_URL + "/hr/settings/general",
        },
        {
          id: 2,
          name: topNav.VacationProfiles[lang],
          link: process.env.PUBLIC_URL + "/hr/settings/vacation-profiles",
        },
        {
          id: 3,
          name: topNav.JobTemplates[lang],
          link: process.env.PUBLIC_URL + "/hr/settings/jobPostingTemplates",
          permissions: ["View Job Postings"],
        },
      ],
      permissions: ["Settings Access"],
    },
  ],
  blank: [],
};

