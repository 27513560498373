import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import http from "../../Common/RestAPIHandler";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../Common/Style/FormStyle";
import CommonConstants from "../../Common/CommonConstants";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ScreenLoader from "../../Common/Loader";

const CredentialForm = (props) => {
  const classes = useStyles();
  const [credentialError, setCredentialError] = useState({});
  const [credential, setCredential] = useState({});
  const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [categories, setCategories] = useState([]);
  const errorText = CommonConstants.error;
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `employee/credential/${props.objectId}/`,
        (data) => {
          setCredential(data);
          setLoadData(false);
        },
        (status, error) => {
          setLoadData(false);
        },
        true
      );
    }

    http.Get(
      "categories?category_type=Account Type",
      (data) => {
        setCategories(data);
      },
      (status, error) => {
      },
      true
    );
  }, []);

  const onChange = (e) => {
    setCredential({
      ...credential,
      [e.target.name]: e.target.value,
    });
    setCredentialError({
      ...credentialError,
      [e.target.name]: false,
    });
    setIsWebsiteInvalid(false);
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setCredential({
      ...credential,
      employee: props.user.id,
    });

    var data = {
      ...credential,
      employee: props.user.id,
    };

    if (props.edit && props.objectId) {
      http.Put(
        `employee/credential/${props.objectId}/`,
        data,
        (credential) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setCredentialError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `employee/credential/`,
        data,
        (credential) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setCredentialError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  const handleClickShowPassword = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item xs={10}>
            <Typography variant="h6">
              {props.edit ? "Edit Credential" : "Add New Credential"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.cross}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <div>
                <p className={classes.label}>ACCOUNT TYPE</p>
                <TextValidator
                  id="category"
                  fullWidth
                  select
                  variant="outlined"
                  margin="dense"
                  name="category"
                  value={credential.category || ""}
                  onChange={onChange}
                  error={Boolean(credentialError.category)}
                  helperText={
                    credentialError.category && credentialError.category[0]
                  }
                >
                  {categories && categories.length > 0 && (
                    <MenuItem value={""}>None</MenuItem>
                  )}
                  {categories && categories.length === 0 ? (
                    <MenuItem value={""} selected disabled>
                      No account types found
                    </MenuItem>
                  ) : (
                    categories.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.category_name}
                      </MenuItem>
                    ))
                  )}
                </TextValidator>
              </div>
              <div>
                <p className={classes.label}>USERNAME *</p>
                <TextValidator
                  id="username"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="username"
                  autoComplete="new-username"
                  value={credential.username || ""}
                  onChange={onChange}
                  validators={["required", "trim"]}
                  inputProps={{ maxLength: 100 }}
                  errorMessages={[
                    errorText.requiredError,
                    errorText.blankError,
                  ]}
                  error={Boolean(credentialError.username)}
                  helperText={
                    credentialError.username && credentialError.username[0]
                  }
                />
              </div>

              <div>
                <p className={classes.label}>PASSWORD *</p>
                <TextValidator
                  id="password"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  type={isPasswordShow ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  value={credential.password || ""}
                  onChange={onChange}
                  inputProps={{ maxLength: 100 }}
                  validators={["required"]}
                  errorMessages={[errorText.requiredError]}
                  error={Boolean(credentialError.password)}
                  helperText={
                    credentialError.password && credentialError.password[0]
                  }
                  InputProps={{
                    style: {fontFamily: 'Raleway'},
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {isPasswordShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div>
                <p className={classes.label}>WEBSITE</p>
                <TextValidator
                  id="website"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="website"
                  value={credential.website || ""}
                  placeholder={errorText.websitePlaceholder}
                  onChange={onChange}
                  error={Boolean(credentialError.website)}
                  helperText={
                    credentialError.website && credentialError.website[0]
                  }
                />
              </div>
              <div>
                <p className={classes.label}>DESCRIPTION</p>
                <TextValidator
                  id="description"
                  type="textarea"
                  multiline
                  rows={3}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="description"
                  value={credential.description || ""}
                  onChange={onChange}
                  error={Boolean(credentialError.description)}
                  helperText={
                    credentialError.description &&
                    credentialError.description[0]
                  }
                />
              </div>

              <div>
                <p className={classes.label}>NOTES</p>
                <TextValidator
                  id="notes"
                  multiline
                  rows={3}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="notes"
                  value={credential.notes || ""}
                  onChange={onChange}
                  error={Boolean(credentialError.notes)}
                  helperText={credentialError.notes && credentialError.notes[0]}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.divider}>
          <Divider />
        </div>
        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(CredentialForm);
