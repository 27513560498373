import React, { useEffect, useState } from "react";
import ApiForm from "./ApiForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "userFirstName",
    label: "FIRST NAME",
    minWidth: 200,
    sortable: true,
    readonly: true,
  },
  {
    id: "userLastName",
    label: "LAST NAME",
    minWidth: 200,
    sortable: true,
    readonly: true,
  },
  {
    id: "apiKey",
    label: "Key",
    minWidth: 200,
    sortable: true,
    readonly: true,
  },
];

const ApiSchemas = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `api_key`;
  const actions = {
    delete: true,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"API User Management"}
      url={url}
      actions={actions}
      objectForm={props.user.max_custom_tab > -1 && data.length >= props.user.max_custom_tab ? null : ApiForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"order"}
      data={data}
      fetchData={fetchData}
      pageHeader={"API User Management"}
      loadData={loadData}
      onDelete={()=>{window.location.reload();}}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"API User Management"}
      url={url}
      actions={actions}
      objectForm={props.user.max_custom_tab > -1 && data.length >= props.user.max_custom_tab ? null : ApiForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"order"}
      data={data}
      fetchData={fetchData}
      pageHeader={"API User Management"}
      loadData={loadData}
      onDelete={()=>{window.location.reload();}}
    />
    }
    </>
  );
};

export default ApiSchemas;
