import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import HRForm from "./HRForm";
import HRFormMobile from "./HRFormMobile";
import {
  formatDate,
  daysUntilThreeMonths,
  daysUntilAnniversary,
} from "../../Common/Utility";
import CakeIcon from "@material-ui/icons/Cake";
import EventIcon from "@material-ui/icons/Event";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { hasPermission } from "../../Common/permission";
import CreateIcon from "@material-ui/icons/Create";
import commonConstants from "../../Common/CommonConstants";
import { Dialog } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 8,
    backgroundColor: theme.colorScheme,
    color: "white",
    marginRight: "2px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  chipAddNew: {
    float: "right",
    backgroundColor: theme.colorScheme,
    marginTop: "5px",
    marginRight: "-14px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
}));


const HR = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [objectId, setObjectId] = useState(null);
  const [calendar, setCalendar] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [loadData, setLoadData] = useState(false);
  const url = `hr_employees/`;

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    additionalActions: [
      {
        label:"Calender",
        onClick:(ids)=>{setShowCalendar(true);},
      },
    ],
  };

  const tableColumns = [
    {
      id: "first_name",
      label: "FIRST NAME",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "last_name",
      label: "LAST NAME",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "designation",
      label: "TITLE",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "joining_date",
      label: "START DATE",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "joining_date_icon",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "birth_date",
      label: "BIRTHDAY",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "birth_date_icon",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "status",
      label: "STATUS",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "edit",
      label: "",
      minWidth: 100,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
    },
  ];

  const isShowIcon = (item, column_id) => {
    var daysCount = -1;
    if (column_id === "birth_date" && item[column_id]) {
      daysCount = daysUntilAnniversary(item[column_id]);
    } else if (column_id === "joining_date" && item[column_id]) {
      daysCount = daysUntilThreeMonths(item[column_id]);
      if (!(daysCount >= 0 && daysCount <= 7)) {
        daysCount = daysUntilAnniversary(item[column_id]);
      }
    }

    if (daysCount >= 0 && daysCount <= 7) {
      return true;
    } else {
      return false;
    }
  };

  const getIconText = (item, column_id) => {
    var iconText = "";
    var daysCount = -1;

    if (column_id === "birth_date" && item[column_id]) {
      daysCount = daysUntilAnniversary(item[column_id]);
      if (daysCount >= 0 && daysCount <= 7) {
        iconText = "Birthday";
      }
    } else if (column_id === "joining_date" && item[column_id]) {
      daysCount = daysUntilThreeMonths(item[column_id]);
      if (daysCount >= 0 && daysCount <= 7) {
        iconText = "3 months completion";
      } else {
        daysCount = daysUntilAnniversary(item[column_id]);
        if (daysCount >= 0 && daysCount <= 7) {
          iconText = "Joining anniversary";
        }
      }
    }

    if (daysCount >= 0 && daysCount <= 7) {
      if (daysCount === 0) {
        iconText += " is today!";
      } else if (daysCount === 1) {
        iconText += " is tomorrow";
      } else {
        iconText += ` is in ${daysCount} days`;
      }
    }

    return iconText;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    http.Get(
      `hr_calendar?year=${year}&month=${month}`,
      (data) => {
        setCalendar(data.calendar);
      },
      () => {}
    );
  }, [year, month]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.birth_date = formatDate(item.birth_date)),
            (item.joining_date = formatDate(item.joining_date)),
            (item.status = item.status ? "Active" : "Disabled"),
            (item["birth_date_icon"] = isShowIcon(item, "birth_date")?(<Tooltip title={getIconText(item, "birth_date")}><CakeIcon onClick={() => {setShowCalendar(true);}} className={classes.iconColorScheme}/></Tooltip>):("")),
            (item["joining_date_icon"] = isShowIcon(item, "joining_date")?(<Tooltip title={getIconText(item, "joining_date")}><EventIcon onClick={() => {setShowCalendar(true);}} className={classes.iconColorScheme}/></Tooltip>):("")),
            (item.rp_disabled = !item.status),
            (item.edit = (
              <CreateIcon
                onClick={() => {
                  setEdit(true);
                  setObjectId(item.id);
                  setShowForm(true);
                }}
              />
            ))
          )
        );

        setData(data);
        setLoadData(false);

        http.Get(
          `hr_calendar?year=${year}&month=${month}`,
          (data) => {
            setCalendar(data.calendar);
          },
          () => {}
        );
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      <Dialog
        open={showCalendar}
        onClose={() => {
          setShowCalendar(false);
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
          }}
        >
          <a
            href="#"
            onClick={() => {
              if (month > 1) {
                setMonth(month - 1);
              } else {
                setMonth(12);
                setYear(year - 1);
              }
            }}
          >
            {"<<<<"}
          </a>
          <a
            href="#"
            style={{ float: "right" }}
            onClick={() => {
              if (month < 12) {
                setMonth(month + 1);
              } else {
                setMonth(1);
                setYear(year + 1);
              }
            }}
          >
            {">>>>"}
          </a>
        </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
          dangerouslySetInnerHTML={{ __html: calendar }}
        />
      </Dialog>

      {showForm && (
        <HRForm
          {...props}
          closeOpen={() => {
            setShowForm(!showForm);
          }}
          edit={edit}
          objectId={objectId}
          pageHeader={(props.user.company_name || "") + " | HR | "}
          handleObjectSubmit={() => {
            fetchData();
            setObjectId(null);
            setShowForm(!showForm);
          }}
        />
      )}
      {!props.mobile && !showForm && (
        <ObjectList
          {...props}
          moduleName={
            <div>
              HR
              <Chip
                label="ADD NEW"
                clickable
                className={classes.chipAddNew}
                color="primary"
                onClick={() => {
                  setEdit(false);
                  setObjectId(null);
                  setShowForm(true);
                }}
                disabled={
                  props.permissions &&
                  !hasPermission(props, props.permissions.create)
                }
              />
            </div>
          }
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"first_name"}
          searchFields={["first_name"]}
          pageHeader={(props.user.company_name || "") + " | HR"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {props.mobile && !showForm && (
        <ObjectListMobile
          {...props}
          moduleName={"HR"}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"first_name"}
          searchFields={["first_name"]}
          pageHeader={(props.user.company_name || "") + " | HR"}
          loadData={loadData}
          objectForm={HRFormMobile}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

export default HR;
