import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";

const TenantTransferForm = props => {
  const [formFields, setFormFields] = useState();
	const [packages, setPackages] = useState();

  useEffect(() => {
		http.Get(
			`packages`,
			(data) => {
				var valueMap = {};
				for (let i = 0; i < data.length; i++) {
					valueMap[data[i].id] = data[i].package_name;
				}
				setPackages(valueMap);
			},
			() => {}
		);
  }, []);


  useEffect(() => {
    if(packages){
      setFormFields([
        {
          id: "tenant",
          label: "Tenant ID *",
          type: "text"
        },
        {
          id: "email",
          label: "Admin Email *",
          type: "email"
        },
        {
          id: "package",
          label: "Package *",
          type: "select",
          valueMap: packages
        },
        {
          id: "vault_id",
          label: "vault id",
          type: "text"
        },
        {
          id: "card_number",
          label: "card number",
          type: "text"
        },
        {
          id: "expiration",
          label: "expiration (YYMM)",
          type: "text"
        },
      ]);
    }
  }, [packages]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`tenant_transfers`}
          formName={"Tenant Transfer"}
          formFields={formFields}
          object={{ ...props.object, reseller: props.user.reseller, status: "Pending" }}
        />
      )}
    </div>
  );
};

export default TenantTransferForm;
