import React, { useEffect, useState } from "react";
import SupportForm from "./SupportForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import SnackbarComponent from "../../Common/Snackbar";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { makeStyles } from "@material-ui/core/styles";
import {VendorsLang} from "../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
	backgroundColor: "transparent !important",
    color: theme.linkColorFaded,
    "&:hover": {
		color: theme.linkColor
	},
    width: 15,
    verticalAlign: "bottom",
  },
}));

const lang = localStorage.getItem("languages");

const tableColumns = [
  {
    id: "company_name",
    label: VendorsLang.COMPANY[lang],
    minWidth: 150,
    sortable: true,
  },
  {
    id: "copyWebsite",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "website",
    label: VendorsLang.WEBSITE[lang],
    minWidth: 150,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: onWebsiteClick,
  },
  {
    id: "contact_number",
    label: VendorsLang.PHONE[lang],
    minWidth: 120,
    sortable: true,
  },
  {
    id: "hours_of_operation",
    label: VendorsLang.HOURSOFOPERATION[lang],
    minWidth: 220,
    sortable: false,
  },
  {
    id: "notes",
    label: VendorsLang.NOTES[lang],
    minWidth: 120,
    sortable: true,
  },
];

const Support = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const url = `supports`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item) => {

          item["copyWebsite"] = (
            <FilterNoneIcon
              fontSize="small"
              className={classes.iconColorScheme}
              onClick={(props, row, columnId) => {
                onCopy(props, item, "website");
                setSnackBarMessage(VendorsLang.WebsiteCopied[lang]);
              }}
            />
          );

          item["mobile_view"] = "Hours: " + item["hours_of_operation"]
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
    ></SnackbarComponent>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={VendorsLang.Vendors[lang]}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Update Data"],
        edit: ["Create/Update Data"],
        delete: ["Remove Data"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={props.user.max_vendor > -1 && data.length >= props.user.max_vendor ? null : SupportForm}
      orderBy={"company_name"}
      searchFields={["company_name"]}
      pageHeader={(props.user.company_name || "") + " | Support"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Vendors"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Update Data"],
        edit: ["Create/Update Data"],
        delete: ["Remove Data"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={props.user.max_vendor > -1 && data.length >= props.user.max_vendor ? null : SupportForm}
      orderBy={"company_name"}
      searchFields={["company_name"]}
      pageHeader={(props.user.company_name || "") + " | Support"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Support;
