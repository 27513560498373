import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CompanyGoogleMap from "../Company/CompanyDetails/GoogleMap";
import Geocode from "react-geocode";
import RouterIcon from "@material-ui/icons/Router";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import GavelIcon from "@material-ui/icons/Gavel";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import ApartmentIcon from "@material-ui/icons/Apartment";
import StoreIcon from "@material-ui/icons/Store";
import BusinessIcon from "@material-ui/icons/Business";
import StorefrontIcon from "@material-ui/icons/Storefront";
import DescriptionIcon from "@material-ui/icons/Description";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import SecurityIcon from "@material-ui/icons/Security";
import CategoryIcon from "@material-ui/icons/Category";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ArchiveIcon from '@material-ui/icons/Archive';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import http from "./RestAPIHandler";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AppBar from "@material-ui/core/AppBar";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import WorkIcon from '@material-ui/icons/Work';
import {
  stableSort,
  getSorting,
  formatDate,
  formatDateTime,
  formatPhoneNumber,
  alphaArray,
} from "./Utility";
import DeleteAlertDialog from "./AlertDialog";
import SnackbarComponent from "../Common/Snackbar";
import { hasPermission } from "./permission";
import { format } from "date-fns-tz";
import ScreenLoader from "../Common/Loader";
import {
  Card,
  CardContent,
  Dialog,
  List,
  ListItem,
  Collapse,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import commonConstants from "./CommonConstants";
import { menus } from "../Common/Layout/MenuList";

Geocode.setApiKey("AIzaSyD30xT-jnz-eajS6xaH61oS2SiDo1nHlRY");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 0,
  },
  appbar: {
    backgroundColor: theme.colorScheme,
    paddingBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  arrowIcon: {
    height: 35,
  },
  containerItem: {
    textAlign: "start",
    width: "max-content",
  },
  "@media (min-width: 426px)": {
    containerItem: {
      textAlign: "end !important",
    },
  },

  chip: {
    backgroundColor: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },

  chipMobile: {
    borderRadius: 8,
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },

  chipBlack: {
    borderRadius: 8,
    backgroundColor: "#313131",
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  disableCell: {
    color: "#888888 !important",
  },
  drawerForm: {
		// Change Drawer Paper element to slide fullscreen on mobile
		width: "75%",
		"@media (max-width: 430px)": {
      width: "-webkit-fill-available",
    },
  },
  iconButton: {
    padding: 5,
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  invoiceTextL: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  invoiceTextR: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  listRoot: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: "#424242",
  },
  notesCollapse: {
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: 1.57,
  },
  objectDataDisplay: {
    marginLeft: 5,
  },
  search: {
    display: "flex",
    width: 200,
    height: 32,
    borderRadius: 16,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  "@media (max-width: 430px)": {
    search: {
      width: "-webkit-fill-available",
    },
  },
  divider: {
    padding: 0,
  },
  emptyMessage: {
    placeContent: "center",
    fontSize: "120%",
    color: "gray",
  },
  templateMenuItem: {
    padding: 13,
    whiteSpace: "normal",
  },
  typoAccountingH: {
    fontSize: "small",
  },
  pageHeader: {
    display: "none",
  },
  "@media (max-width: 960px)": {
    pageHeader: {
      display: "block",
    },
  },
  card: {
    minWidth: 300,
    maxHeight: 65,
    position: "relative",
    borderRadius: 8,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    maxHeight: 65,
  },
  cardDisabled: {
    backgroundColor: "#f4f4f4",
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    maxHeight: 65,
  },
  credentialCard: {
    borderRadius: 10,
    minWidth: 350,
    // minHeight: 350,
    marginTop: 10,
    marginBottom: 10,
  },
  ojIcon: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  },
  ojIconDisabled: {
    height: 30,
    width: 30,
    background: "#b3b3b3",
    color: "white",
    margin: 1,
  },
  bkIcon: {
    height: 30,
    width: 30,
    background: "black",
    color: "white",
    margin: 1,
  },
  bkIconDisabled: {
    height: 30,
    width: 30,
    background: "#b3b3b3",
    color: "white",
    margin: 1,
  },
  lineheight: {
    lineHeight: "normal",
  },
}));

const ObjectListMobile = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [objectId, setObjectId] = useState(null);
  const [objects, setObjects] = useState([]);
  const [objectList, setObjectList] = useState([]);
  const [search, setSearch] = useState("");
  const [check, setCheck] = useState({});
  const [order, setOrder] = useState(props.order || "asc");
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [templateAnchorEl, setTemplateAnchorEl] = React.useState(null);
  const templateMenuOpen = Boolean(templateAnchorEl);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  const [formState, setFormState] = useState(false);

  let abc = alphaArray();
  const jumpToAnchor = (anchors, target) => {
    let loc = anchors.filter((el) => el.company_name[0] == target);
    if (loc.length > 0) {
      window.location.href = "#" + loc[0].company_name[0];
    }
  };

  const [previousSearch, setPreviousSearch] = useState();

  const handleChange = (e) => {
    let toggle = false;
    if (e.target.value.length > 0) {
      toggle = true;
    }
    setFormState(toggle);
  };

  useEffect(() => {
    if (props.templateUrl)
      http.Get(
        props.templateUrl,
        (data) => {
          var today = new Date();
          var date = formatDate(today.toISOString());
          var time = format(today, "hh:mm a");
          var datetime = formatDateTime(today.toISOString());

          data.forEach(
            (template) =>
              (template.data = template.data
                .replace(/%%date%%/gi, date)
                .replace(/%%time%%/gi, time)
                .replace(/%%datetime%%/gi, datetime))
          );
          setTemplates(data);
        },
        () => {}
      );
  }, []);

  useEffect(() => {
    setObjectList(props.data);
    if (props.url === "companies") {
      setPreviousSearch(localStorage.getItem("previous_search"));
      var input = document.getElementById("input_search");
      if (input) {
        setCaretPosition(input, input.value.length);
      }
      if (previousSearch) {
        setSearch(previousSearch);
      }
    }
  }, [props.data]);

  useEffect(() => {
    var tableData = [];
    if (!search) tableData = objectList;
    else
      tableData = objectList.filter((object) =>
        props.searchFields.some(
          (key) =>
            object[key] &&
            object[key].toLowerCase().includes(search.toLowerCase())
        )
      );
    var sortedObjects = stableSort(tableData, getSorting(order, orderBy));
    if (props.fixedOrderBy) {
      sortedObjects = stableSort(
        sortedObjects,
        getSorting("asc", props.fixedOrderBy)
      );
    }
    setObjects(sortedObjects);
  }, [objectList, search, order, orderBy]);

  const setCaretPosition = (ctrl, pos) => {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(0, pos);
    }
  };

  const handleSearch = (e) => {
    if (props.url === "companies") {
      localStorage.setItem("previous_search", e.target.value);
    }
    setPreviousSearch(e.target.value);
    setSearch(e.target.value);
  };

  const handleTemplateMenu = (event) => {
    setTemplateAnchorEl(event.currentTarget);
  };

  const handleTemplateMenuClose = () => {
    setTemplateAnchorEl(null);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation();
    setEdit(true);
    setObjectId(id);
    setOpen(true);
  };

  const handleAddClick = (isUsingTemplate, e) => {
    if (!isUsingTemplate) setTemplate({});
    setEdit(false);
    setObjectId(null);
    setOpen(true);
  };

  const handleTemplateClick = (template) => {
    setTemplate(template);
    handleAddClick(true);
    handleTemplateMenuClose();
  };

  const handleObjectSubmit = () => {
    props.fetchData();
    setObjectId(null);
    setOpen(false);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  return (
    <div className={classes.root}>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarOpen}
        handleSnackBarClose={handleSnackBarClose}
        severity={severity}
      ></SnackbarComponent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span className={classes.pageHeader}>{props.pageHeader}</span>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6} lg={6}>
              {props.loadData ? (
                <ScreenLoader />
              ) : (
                <Typography
                  variant="h4"
                  color="initial"
                  className={classes.moduleHeader}
                  children={props.moduleName}
                  align={"center"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.containerItem}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={11} md={11}>
                  {!props.loadData && !props.excludeAddButton ? (
                    <Chip
                      label="ADD NEW"
                      clickable
                      className={classes.chipMobile}
                      color="primary"
                      onClick={props.objectForm
                        ? (e) => handleAddClick(false, e) 
                        : () => handleSnackBar(commonConstants.error.packageLimitReached,
                                    "error")
                      }
                      disabled={
                        (props.permissions &&
												!hasPermission(props, props.permissions.create)) ||
												(props.portalPermissions && 
												!props.portalPermissions.create)
                      }
                      onDelete={
                        props.templateUrl &&
                        templates.length > 0 &&
                        handleTemplateMenu
                      }
                      deleteIcon={
                        <ArrowDropDownIcon className={classes.arrowIcon} />
                      }
                    />
                  ) : (
                    <> </>
                  )}
                </Grid>
                <Grid item xs={6} sm={1} md={1} style={{ textAlign: "end" }}>
                  {!props.loadData && props.moduleButton && props.moduleButton}
                  {!props.loadData && props.mobileMenu && props.mobileMenu}
                  {props.templateUrl && (
                    <Menu
                      id="menu-template"
                      anchorEl={templateAnchorEl}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={templateMenuOpen}
                      onClose={handleTemplateMenuClose}
                      PaperProps={{
                        style: {
                          maxWidth: 250,
                          borderRadius: 15,
                          maxHeight: "calc(100vh/3)",
                        },
                      }}
                    >
                      {templates.map((template) => (
                        <MenuItem
                          onClick={() => handleTemplateClick(template)}
                          className={classes.templateMenuItem}
                        >
                          {template.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {!props.data.length == 0 && (
                <div className={classes.search}>
                  <InputBase
                    id={"input_search"}
                    className={classes.input}
                    placeholder="Search "
                    inputProps={{ "aria-label": "search" }}
                    fullWidth
                    onChange={handleSearch}
                    value={previousSearch || ""}
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    disabled
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          {props.loadData ? (
            <ScreenLoader />
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ObjectTable
                  {...props}
                  data={objects}
                  handleEditClick={handleEditClick}
                  check={check}
                  order={order}
                  orderBy={orderBy}
                  tableColumns={props.tableColumns}
                  permissions={props.permissions}
                  portalPermissions={props.portalPermissions}
                />
              </Grid>
              {props.url === "companies" && (
                <Grid
                  container
                  spacing={1}
                  item
                  xs={1}
                  style={{
                    position: "fixed",
                    right: 0,
                    top: 130,
                  }}
                >
                  <ul
                    style={{
                      width: "fit-content",
                      float: "right",
                      textAlign: "center",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {abc.map((letter) => (
                      <p
                        style={{ fontSize: "small", margin: 5 }}
                        onClick={() => jumpToAnchor(props.data, letter)}
                      >
                        {letter}
                      </p>
                    ))}
                  </ul>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Drawer
        autoComplete="off"
        anchor="right"
        open={open}
        onChange={handleChange}
        onClose={(e) => {
          if (!formState) {
            setOpen(false);
          } else {
            handleSnackBar(
              "Please finish inputting, or clear the form!!",
              "warning"
            );
          }
        }}
        PaperProps={{ root: { width: "-webkit-fill-available" } }}
        disableEnforceFocus
        classes={{
          paper: classes.drawerForm,
        }}
      >
        <table style={{ width: "-webkit-fill-available" }}>
          <tbody>
            <tr>
              <td width="100%">
                <props.objectForm
                  {...props}
                  closeOpen={handleSetOpen}
                  edit={edit}
                  objectId={objectId}
                  handleObjectSubmit={handleObjectSubmit}
                  template={template}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Drawer>
    </div>
  );
};

const ObjectTable = (props) => {
  const classes = useStyles();
  const [tableColumns, setTableColumns] = useState(props.tableColumns);
  let documentCat = props.categories;
  const hasEditPermission =
    (!props.permissions && !props.portalPermissions) ||
    (props.permissions && hasPermission(props, props.permissions.edit)) ||
    (props.portalPermissions && props.portalPermissions.edit);
  const [icon, setIcon] = useState();
  const [position, setPosition] = useState();
  const [site, setSite] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [id, setId] = useState();
  const [expanded, setExpanded] = useState(false);
  const [activeId, setActiveId] = useState();
  const [tableColumn, setTableColumn] = useState();

  useEffect(() => {
    if (props.tableColumns) {
      props.tableColumns.some((tableColumn)=>{
        setTableColumn(tableColumn);
        return tableColumn.label!=="";
      });
    }
  }, [props.tableColumns]);

  const handleDelete = (id) => {
    if (props.vacationProfilesUsed && props.vacationProfilesUsed[id]) {
      handleSnackBar("Cannot delete. Profile(s) has assignees.", "error");
			return;
    }
    setAlertOpen(true);
  };

  const handleDeleteAgree = (rowID) => {
    http.Delete(
      props.url,
      { ids: rowID },
      (status, data) => {
        if (status === 200 && data["database_error"]) {
          handleSnackBar(data["database_error"], "error");
        }
        props.fetchData();
        setAlertOpen(false);

        if(props.onDelete){
          props.onDelete();
        }

      },
      () => {}
    );
  };

  const handleDeleteDisagree = () => {
    setAlertOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  useEffect(() => {
    if (activeId) {
      let location = props.data.filter((site) => site.id === activeId);
      // Pulling the value out of the array, array is not needed, probably better way to do this, did this way for speed
      setSite(location[0]);
    }
  }, [activeId]);

  useEffect(() => {
    var selectedMenus = [];
    if (window.location.pathname.includes("/company/")) 
    {
      selectedMenus = menus.companies;
    }
    else if (window.location.pathname.includes("/hr/")) 
    {
      selectedMenus = menus.hr;
    }
    else if (window.location.pathname.includes("/settings/")) 
    {
      selectedMenus = menus.settings;
    }
    else
    {
      selectedMenus = menus.tenants;
    }
    const { id } = props.match.params;
    selectedMenus.map((menu)=>{
      if(menu.link){
        let url = menu.link.replace("/id", `/${id}`);
        if(window.location.pathname === url){
          setIcon(<menu.icon/>);
        }
      }
      else if(menu.items){
        menu.items.map((item)=>{
          if(item.link){
            let url = item.link.replace("/id", `/${id}`);
            if(window.location.pathname === url){
              setIcon(<menu.icon/>);
            }
          }

        });
      }
    });
  }, []);

  useEffect(() => {
    site &&
      Geocode.fromAddress(
        site.address &&
          site.address +
            ", " +
            site.city +
            ", " +
            site.state +
            ", " +
            site.postal +
            ", " +
            site.country
      ).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setPosition({ lat: lat, lng: lng });
        },
        (error) => {
          console.error(error);
        }
      );
  }, [site]);

  return (
    <>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarOpen}
        handleSnackBarClose={handleSnackBarClose}
        severity={severity}
      ></SnackbarComponent>
      <DeleteAlertDialog
        open={alertOpen}
        onAgree={() => handleDeleteAgree(id)}
        onDisagree={handleDeleteDisagree}
        primaryMessage={"Do you want to delete?"}
        secondaryMessage={"If you agree, data will be deleted permanently."}
      />

      <Grid container spacing={1} item xs={12}>
        {props.subFolders && props.subFolders.map((row) => (
          <Grid item xs={12}>
            <Card
              name="mainCard"
              className={classes.card}
              variant="outlined"
            >
              <CardContent
                className={classes.cardContent}
              >
                <Grid container spacing={1} style={{ alignSelf: "center" }}>
                  <Grid
                    item
                    xs={1}
                    style={{ 
                      padding: 0, 
                      alignSelf: "center",
                    }}
                    direction="column"
                  >
                    <a onClick={row.onClick}>{row.icon}</a>
                  </Grid>
                  <Grid item xs={6} style={{ alignSelf: "center" }}>
                    <a onClick={row.onClick}>{row.title}</a>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {props.data.map((row) => (
          <>
            <Grid item xs={12}>
              {props.loadData ? (
                <ScreenLoader />
              ) : (
                <Card
                  id={row.company_name ? row.company_name[0].toUpperCase() : ""}
                  name="mainCard"
                  key={row.id}
                  className={classes.card}
                  variant="outlined"
                >
                  <CardContent
                    className={
                      row["status"] === "Inactive"
                        ? classes.cardDisabled
                        : classes.cardContent
                    }
                  >
                    <Grid container spacing={1} style={{ alignSelf: "center" }}>
                      <Grid
                        item
                        xs={1}
                        style={{ 
                          padding: 0, 
                          alignSelf: "center",
                        }}
                        direction="column"
                      >
                        {icon}
                      </Grid>
                      <Grid item xs={6} style={{ alignSelf: "center" }}>
                        <Grid container item xs spacing={1} direction="column">
                          <Typography
                            variant="subtitle"
                            style={{
                              maxWidth: 140,
                              maxHeight: 18,
                              overflow: "hidden",
                            }}
                          >
                            <span>
                              {tableColumn && row[tableColumn.id]}
                            </span>
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                          >
                            <span style={{ whiteSpace: "nowrap"}}>
                              {row["mobile_view"]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {row.mobile_view_button}

                        <IconButton
                          disableTouchRipple
                          className={
                            row["status"] === "Inactive"
                              ? classes.bkIconDisabled
                              : classes.bkIcon
                          }
                          onClick={
                            row["status"] === "Inactive"
                              ? () => {
                                  console.log("clicked");
                                }
                              : () => {
                                  tableColumn.onClick
                                    ? tableColumn.onClick(props, row)
                                    : setActiveId(row.id);
                                }
                          }
                        >
                          <KeyboardArrowRight fontSize="small" />
                        </IconButton>
                      </Grid>
                      {activeId && activeId === row.id &&
                      
                          // Data display for the fullscreen Dialog popup when clicking on arrow-right-icon
                          <Dialog open={true} fullScreen>
                            <Grid
                              container
                              spacing={1}
                              style={{
                                width: "-webkit-fill-available",
                                margin: 0,
                              }}
                            >
                              <Grid item xs={12}>
                                <AppBar
                                  position="static"
                                  className={classes.appbar}
                                >
                                  <Typography
                                    style={{
                                      width: "max-content",
                                      height: "max-content",
                                      padding: "10px 0px 5px 10px",
                                    }}
                                  >
                                    {tableColumn && row[tableColumn.id]}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    style={{
                                      width: "max-content",
                                      height: "max-content",
                                      alignSelf: "center",
                                      padding: "10px 10px 5px 0px",
                                    }}
                                    onClick={() => {
                                      setActiveId(null);
                                    }}
                                  >
                                    <CancelIcon className={classes.chipMobile}/>
                                  </IconButton>
                                </AppBar>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              style={{
                                maxWidth: "100vw",
                                alignSelf: "center",
                              }}
                            >
                              <Grid item xs={12}>
                                {props.loadData ? (
                                  <ScreenLoader />
                                ) : (
                                  position && (
                                    <CompanyGoogleMap
                                      {...props}
                                      position={position}
                                    />
                                  )
                                )}
                                <Card
                                  className={classes.credentialCard}
                                  variant="outlined"
                                >
                                  <CardContent style={{ display: "flex" }}>
                                    <List>
                                      <Grid container spacing={0}>
                                        {tableColumns &&
                                          tableColumns.map((column) => {
                                            return (
                                              <Grid
                                                container
                                                spacing={0}
                                                item
                                                xs={12}
                                              >
                                                {column.label ? (
                                                  <ListItem
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <Grid
                                                      container
                                                      spacing={0}
                                                      item
                                                      xs={12}
                                                    >
                                                      <Typography
                                                        variant="overline"
                                                        className={
                                                          classes.lineheight
                                                        }
                                                      >
                                                        {column.label != "" &&
                                                          column.label}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      container
                                                      spacing={0}
                                                      item
                                                      xs={12}
                                                    >
                                                      <Typography
                                                        id="web"
                                                        variant="subtitle2"
                                                        color="textSecondary"
                                                        className={
                                                          classes.objectDataDisplay
                                                        }
                                                        style={{
                                                          width:
                                                            "-webkit-fill-available",
                                                        }}
                                                      >
                                                        {row[column.id] &&
                                                        column.onClick ? (
                                                          <a
                                                            href="#"
                                                            onClick={() => {
                                                              column.onClick(
                                                                props,
                                                                row,
                                                                column.id
                                                              );
                                                            }}
                                                          >
                                                            {row[column.id]}
                                                          </a>
                                                        ) : column.id ===
                                                            "phone" ||
                                                          column.id ===
                                                            "primary_contact_number" ? (
                                                          formatPhoneNumber(
                                                            row[column.id]
                                                          )
                                                        ) : // column.label &&
                                                        row[column.id] ? (
                                                          row[column.id]
                                                        ) : (
                                                          "N/A"
                                                        )}
                                                      </Typography>
                                                    </Grid>
                                                  </ListItem>
                                                ) : (
                                                  <></>
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        {row["guests"] && (
                                          <ListItem>
                                            <Grid
                                              container
                                              spacing={0}
                                              item
                                              xs={12}
                                            >
                                              <Grid item xs={12}>
                                                <Typography variant="overline">
                                                  GUESTS
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                container
                                                spacing={0}
                                                item
                                                xs={12}
                                                justify="space-between"
                                              >
                                                {row["guests"] &&
                                                  row["guests"].map((guest) => {
                                                    return (
                                                      <Chip
                                                        label={`${guest.first_name} ${guest.last_name}`}
                                                        size="small"
                                                        style={{
                                                          marginTop: 5,
                                                        }}
                                                      />
                                                    );
                                                  })}
                                              </Grid>
                                            </Grid>
                                          </ListItem>
                                        )}
                                      </Grid>
                                    </List>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {row["content"] && (
                                <Grid item xs={12}>
                                  <Card
                                    variant="outlined"
                                    className={classes.credentialCard}
                                  >
                                    <CardContent>
                                      <Typography variant="overline">
                                        NOTES <br />
                                      </Typography>
                                      <Collapse
                                        id="testing"
                                        in={expanded}
                                        className={classes.notesCollapse}
                                        children={
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: row["content"],
                                            }}
                                          />
                                        }
                                        collapsedHeight="50px"
                                        timeout="auto"
                                      />
                                      {row["content"] &&
                                        row["content"].length > 92 && (
                                          <IconButton
                                            style={{ float: "right" }}
                                            size="small"
                                            onClick={() => {
                                              setExpanded((prev) => !prev);
                                            }}
                                          >
                                            {!expanded ? (
                                              <KeyboardArrowDownIcon size="small" />
                                            ) : (
                                              <KeyboardArrowUpIcon size="small" />
                                            )}
                                          </IconButton>
                                        )}
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                              {row["files"] && (
                                <Grid item xs={12}>
                                  <Card
                                    variant="outlined"
                                    className={classes.credentialCard}
                                  >
                                    <CardContent>
                                      <Typography variant="overline">
                                        ATTACHMENTS
                                      </Typography>
                                      <Typography variant="subtitle2">
                                        {row["files"].length > 0
                                          ? row["files"].map((file) => {
                                              return file.name;
                                            })
                                          : "None"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                              <Grid
                                container
                                spacing={1}
                                justify="center"
                                style={{ margin: 0 }}
                              >
                                {props.objectForm && (
                                  <>
                                    <Grid item xs={12}>
                                      <Chip
                                        label="EDIT"
                                        variant="outlined"
                                        style={{
                                          height: 45,
                                          fontSize: "initial",
                                          margin: 0,
                                          width: "-webkit-fill-available",
                                        }}
                                        className={`${
                                          row.rp_disabled
                                            ? `${classes.disableCell} ${classes.chipMobile}`
                                            : classes.chipMobile
                                        }`}
                                        onClick={(e) =>
                                          hasEditPermission
                                            ? props.handleEditClick(e, row.id)
                                            : {}
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Chip
                                        label="DELETE"
                                        variant="outlined"
                                        style={{
                                          height: 45,
                                          fontSize: "initial",
                                          margin: 0,
                                          width: "-webkit-fill-available",
                                        }}
                                        onClick={() => {
                                          setId([row.id]);
                                          handleDelete(row.id);
                                        }}
                                        className={`${
                                          row.rp_disabled
                                            ? `${classes.disableCell} ${classes.chipBlack}`
                                            : classes.chipBlack
                                        }`}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              style={{
                                maxWidth: "100vw",
                                alignSelf: "center",
                              }}
                            >
                              <Grid item xs={12}>
                                {row.subData}
                              </Grid>
                            </Grid>
                          </Dialog>                        
                      }
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};

export default ObjectListMobile;
