import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactFileViewer from "react-file-viewer";

// const FileViewer = (props) => { 
//     return (        
//         <div style={{ padding: "20px" }}>
//         {["pdf","doc","docx","xlsx","csv"].includes(props.fileType.toLowerCase()) && (
//           <ReactFileViewer
//             fileType={props.fileType.toLowerCase()}
//             filePath={props.filePath}
//             onError={() => {
//                 if(props.onError){
//                     props.onError();
//                 }
//             }}
//           />
//         )}
//         {["png","jpg"].includes(props.fileType.toLowerCase()) && (
//           <img src={props.filePath} style={{ maxWidth: "100%" }} />
//         )}
//         {!["pdf","doc","docx","xlsx","csv","png","jpg"].includes(props.fileType.toLowerCase()) &&
//           <span>Unsupported file type on preview</span>
//         }
//       </div>
//     );

// };

const FileViewer = (props) => {
  return ( 
    <div style={{ padding: "20px" }}>
    {["docx","xlsx","csv"].includes(props.fileType.toLowerCase()) && (
              <ReactFileViewer
                fileType={props.fileType.toLowerCase()}
                filePath={props.filePath}
                onError={() => {
                    if(props.onError){
                        props.onError();
                    }
                }}
              />
            )
    }
    {!["docx","xlsx","csv"].includes(props.fileType.toLowerCase()) && (
              <DocViewer documents={[{ uri: props.filePath, fileName: props.fileName}]} pluginRenderers={DocViewerRenderers} config={{ header: { disableHeader: true } }}/>
            )
    }
  </div>
  )
};

export default FileViewer;