import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import http from "../Common/RestAPIHandler";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Logo from "../../Images/logo.png";
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CommonConstants from "../Common/CommonConstants";

const ResetPassword = props => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const error = CommonConstants.error;
  const urlParams = new URLSearchParams(window.location.search);
  var loginUrl = "/login";
  if(urlParams.get("userType")==="Company Employee"){
    loginUrl = "/portal/login";
  }
  if(urlParams.get("userType")==="Super User"){
    loginUrl = "/admin/login";
  }
  if(urlParams.get("userType")==="Reseller User"){
    loginUrl = "/reseller/login";
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    var tokenQuery = urlParams.get("token");
    http.Post(
      "password_reset/validate_token/",
      { token: tokenQuery },
      () => {},
      () => {
        props.history.replace(loginUrl);
      }
    );
  }, []);

  const onInputChange = e => {
    if (e.target.name === "password") {
      setPassword(e.target.value.trim());
    } else {
      setConfirmPassword(e.target.value.trim());
    }
    setHasError(false);
    setIsSubmit(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmit(true);

    if (password !== confirmPassword) {
      return;
    }

    setIsPasswordMatch(true);
    setIsSuccess(false);
    setSuccessMessage("");
    setHasError(false);
    setErrorMessage("");
    setShowPassword(false);
    setShowConfirmPassword(false);

    var tokenQuery = urlParams.get("token");

    http.Post(
      "password_reset/confirm/",
      { password: password, token: tokenQuery },
      () => {
        setHasError(false);
        setIsSubmit(false);
        setIsPasswordMatch(false);
        setIsSuccess(true);
        setSuccessMessage(
          error.setPasswordSuccess
        );
        const redirect = () => {
          setIsSuccess(false);
          props.history.replace(loginUrl);
        };
        setTimeout(redirect, 3000);
      },
      (status, error) => {
        setHasError(true);
        setIsSubmit(false);
        setIsPasswordMatch(false);
        if (status === 400) {
          if (error.password) {
            setErrorMessage(error.password);
          } else {
            if (error.status) {
              setErrorMessage(error.status);
            } else {
              setErrorMessage("Please try again.");
            }
          }
        } else if (status === 403) {
          setErrorMessage("You are not eligible to reset password.");
        } else {
          setErrorMessage(error.miscallenousError);
        }
      }
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <ValidatorForm className={classes.form} onSubmit={onSubmit}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h4" component="h5" color="textSecondary">
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    key="outlined-password-input"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      labelWidth: 77,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    value={password || ""}
                    onChange={onInputChange}
                    validators={[
                      "required",
                      "minStringLength:8",
                      "matchRegexp:(?=.*[A-Z])",
                      "matchRegexp:(?=.*[0-9])",
                      "matchRegexp:(?=.*[!@#$%^&*'+])"
                    ]}
                    errorMessages={[
                      error.requiredError,
                      error.eightChar,
                      error.uppercase,
                      error.numeric,
                      error.specialChar
                    ]}
                    error={hasError || (isSubmit && !isPasswordMatch)}
                    helperText={hasError && errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    key="outlined-confirm-password-input"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      labelWidth: 148,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    value={confirmPassword || ""}
                    onChange={onInputChange}
                    validators={["required"]}
                    errorMessages={[error.requiredError]}
                    error={isSubmit && !isPasswordMatch}
                    helperText={
                      isSubmit && !isPasswordMatch && error.passwordMismatch
                    }
                  />
                </Grid>

                {isSuccess && (
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <p style={{ color: "green" }}>{successMessage}</p>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={1} justify="center">
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={8}
                      align="center"
                      className={classes.centerElement}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.outlinedButton}
                        disabled={isSubmit}
                      >
                        Reset Password
                      </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.centerElement}>
                      <Link
                        component={RouterLink}
                        to={loginUrl}
                        color="textPrimary"
                      >
                        Back to Login
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default ResetPassword;
