import React, { useEffect, useState } from "react";
import CompanyForm from "./CompanyForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick } from "../../Common/TableUtility";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteAlertDialog from "../../Common/AlertDialog";
import { formatPhoneNumber, ConvertDateToLocale } from "../../Common/Utility";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import { Checkbox, Grid, Input, MenuItem, Select } from "@material-ui/core";
import { format } from "date-fns-tz";
import Popover from "@material-ui/core/Popover";
import SyncIcon from "@material-ui/icons/Sync";
import ShareIcon from '@material-ui/icons/Share';
import Pwned from "../../../Images/pwned.png";
import { hasPermission } from "../../Common/permission";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import { companyList , CompanyLang} from "../../Common/Languages";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
  chipSelect: {
    marginLeft: "10px",
    backgroundColor: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  loadingBlock: {
    backgroundColor: "#DDDDDD",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    top: "0px",
    left: "0px",
    opacity: ".8",
    filter: "alpha(opacity=80)",
  },
  absoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "70%",
    maxHeight: "70%",
    overflowY: "auto",
    overflowX: "auto",
    fontWeight: "bold",
  },
  error: {
    color: "red",
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  popover: {
    padding: "10px",
    width: "500px",
  },
}));

const tableColumns = [
  {
    id: "company_name",
    label: companyList.COMPANYNAME[lang],
    minWidth: 180,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: (props, object, property) => {
      window.location = process.env.PUBLIC_URL + `/company/${object.id}`;
    },
  },
  {
    id: "main_phone_number",
    label: companyList.PHONE[lang],
    minWidth: 100,
    sortable: true,
  },
  {
    id: "industry_type",
    label: companyList.INDUSTRYTYPE[lang],
    minWidth: 150,
    sortable: true,
  },
  {
    id: "website",
    label: companyList.WEBSITE[lang],
    minWidth: 150,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: onWebsiteClick,
  },
  {
    id: "status",
    label: companyList.STATUS[lang],
    minWidth: 80,
    sortable: true,
  },
  {
    id: "sync_cell",
    label: companyList.SYNC[lang],
    minWidth: 40,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "share_cell",
    label: companyList.SHARE[lang],
    minWidth: 40,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "pwned",
    label: " ",
    minWidth: 10,
    sortable: true,
    colored: false,
    disableTooltip: true,
  },
];

const CompanyList = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [unchangedData, setUnchangedData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [cwSync, setCwSync] = useState();
  const [syncing, setSyncing] = useState();
  const [error, setError] = useState();
  const [cwCompanies, setCwCompanies] = useState();
  const [search, setSearch] = useState(null);
  const [ninjaSync, setNinjaSync] = useState();
  const [syncroSync, setSyncroSync] = useState();
  const [syncroCustomers, setSyncroCustomers] = useState();
  const [autotaskSync, setAutotaskSync] = useState();
  const [autotaskCompanies, setAutotaskCompanies] = useState();
  const [tenant, setTenant] = useState();
  const [tenants, setTenants] = useState();
  const [sharings, setSharings] = useState();
  const [sharing, setSharing] = useState({});
  const [selectedCompany, setSelectedCompany] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [showDetails, setShowDetails] = useState();

  const url = `companies`;
  const csvHeader = commonConstants.companyHeader;
  const exportFormat = commonConstants.companyExportFormat;
  const actions = {
    delete: true,
    importCSV: true,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `tenants/${props.user.tenant}`,
      (tenant) => {
        setTenant(tenant);
        setLoadData(false);
      },
      (status, error) => {
        setLoadData(false);
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    );
    
    http.Get(
      `tenant_tenant_mappings?from_tenant=${props.user.tenant}`,
      (data) => {
        setTenants(data);
      },
      (status, error) => {
      }
    );
  }, []);

  useEffect(() => {
    if (tenant) {
      fetchData();
    }
  }, [tenant]);

  useEffect(() => {
    if(selectedCompany && showDetails==="share"){
      fetchSharings();
    }
  }, [selectedCompany, showDetails]);

  const fetchSharings = () => {    
    http.Get(
      `sharings?company=${selectedCompany.id}`,
      (sharings) => {
        setSharings(sharings);
      },
      (status, error) => {
      }
    );
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );

        data.forEach(
          (item) => (
            (item.rp_disabled = !item.status),
            (item.status = item.status ? "Active" : "Inactive"),
            (item.company_name =
              item.company_name === "Syncro Customer"
                ? item.first_name + " " + item.last_name
                : item.company_name),
            (item.main_phone_number = formatPhoneNumber(
              item.main_phone_number
            )),
            (item.sync_cell = (item.tenant_id!==props.user.tenant? "":(
              <SyncIcon
                className={classes.cursor}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setShowDetails("sync");
                  setSelectedCompany(item);
                }}
              />
            ))),
            (item.share_cell = (item.tenant_id!==props.user.tenant? 
              <div style={{margin:10}}>{`Shared By ${item.tenant_name}`}</div>
              :
              (
              <ShareIcon
                className={classes.cursor}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setShowDetails("share");
                  setSelectedCompany(item);
                }}
              />
            ))),
            (item.readonly = (item.tenant_id!==props.user.tenant)),
            (item.pwned = item.pwned ? (
              <img style={{ maxWidth: 30, maxHeight: 30 }} src={Pwned} />
            ) : (
              ""
            ))
          )
        );
        setData(data);
        setLoadData(false);
      },
      (status, error) => {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setLoadData(false);
      }
    );
  };

  const toggleSync = () => {
    setLoadData(true);
    if (!cwSync.sync && cwSync.cw_company_id) {
      http.Get(
        `cw_companies/?cw_company_id=${cwSync.cw_company_id}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setCwSync(null);
            setLoadData(false);
            return;
          }
          if (data && data.name === cwSync.company_name) {
            http.Post(
              `sync/`,
              {
                company_id: cwSync.id,
                sync: !cwSync.sync,
                cw_company_id: data.id,
                company_name: cwSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setCwSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setCwSync(null);
                setCwCompanies(null);
              }
            );
          } else if (data && data.name) {
            setCwCompanies([data]);
            setLoadData(false);
          } else {
            http.Get(
              `cw_companies/`,
              (data) => {
                setCwCompanies(data);
                setLoadData(false);
              },
              (status, error) => {
                console.error(error);
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setCwSync(null);
                setCwCompanies(null);
              }
            );
          }
        },
        (status, error) => {
          console.error(error);
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setCwSync(null);
        }
      );
    } else if (!cwSync.sync && !cwSync.cw_company_id) {
      http.Get(
        `cw_companies/?cw_company_name=${cwSync.company_name}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setCwSync(null);
            setLoadData(false);
            return;
          }
          if (data.length === 1) {
            http.Post(
              `sync/`,
              {
                company_id: cwSync.id,
                sync: !cwSync.sync,
                cw_company_id: data[0].id,
                company_name: cwSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setCwSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setCwSync(null);
                setCwCompanies(null);
              }
            );
          } else if (data.length === 0) {
            http.Get(
              `cw_companies/`,
              (data) => {
                setCwCompanies(data);
                setLoadData(false);
              },
              (status, error) => {
                console.error(error);
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setCwSync(null);
                setCwCompanies(null);
              }
            );
          } else {
            setLoadData(false);
            setCwCompanies(data);
          }
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setCwSync(null);
        }
      );
    } else if (cwSync.sync) {
      http.Post(
        `sync/`,
        {
          company_id: cwSync.id,
          sync: !cwSync.sync,
          cw_company_id: cwSync.cw_company_id,
          company_name: cwSync.company_name,
        },
        (data) => {
          setSyncing(data.messages);
          setTimeout(() => {
            setSyncing(null);
          }, 5000);
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          fetchData();
          setLoadData(false);
          setCwSync(null);
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setCwSync(null);
        }
      );
    }
  };

  const handleNinjaSync = () => {
    setLoadData(true);
    http.Get(
      "ninja_sync/?ninja_organization_id=" + ninjaSync.ninja_organization_id,
      (data) => {
        if (data.error) {
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setNinjaSync(null);
          setLoadData(false);
          return;
        }
        setSyncing(data.messages);
        setTimeout(() => {
          setSyncing(null);
        }, 5000);
        setError(data.error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        fetchData();
        setLoadData(false);
        setNinjaSync(null);
      },
      (status, error) => {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        fetchData();
        setLoadData(false);
        setNinjaSync(null);
      }
    );
  };

  const toggleSyncroSync = () => {
    setLoadData(true);
    if (!syncroSync.syncro_sync && syncroSync.syncro_customer_id) {
      http.Get(
        `syncro_companies/?syncro_customer_id=${syncroSync.syncro_customer_id}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setSyncroSync(null);
            setLoadData(false);
            return;
          }
          if (data && data.business_name === syncroSync.company_name) {
            http.Post(
              `syncro_sync/`,
              {
                company_id: syncroSync.id,
                syncro_sync: !syncroSync.syncro_sync,
                syncro_customer_id: data.id,
                company_name: syncroSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setSyncroSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setSyncroSync(null);
                setSyncroCustomers(null);
              }
            );
          } else if (data && data.business_name) {
            setSyncroCustomers([data]);
            setLoadData(false);
          } else {
            http.Get(
              `syncro_companies/`,
              (data) => {
                setSyncroCustomers(data);
                setLoadData(false);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setSyncroSync(null);
                setSyncroCustomers(null);
              }
            );
          }
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setSyncroSync(null);
        }
      );
    } else if (!syncroSync.syncro_sync && !syncroSync.syncro_customer_id) {
      http.Get(
        `syncro_companies/?syncro_customer_name=${syncroSync.company_name}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setSyncroSync(null);
            setLoadData(false);
            return;
          }
          if (data.length === 1) {
            http.Post(
              `syncro_sync/`,
              {
                company_id: syncroSync.id,
                sync: !syncroSync.syncro_sync,
                syncro_customer_id: data[0].id,
                company_name: syncroSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setSyncroSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setSyncroSync(null);
                setSyncroCustomers(null);
              }
            );
          } else if (data.length === 0) {
            http.Get(
              `syncro_companies/`,
              (data) => {
                setSyncroCustomers(data);
                setLoadData(false);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setSyncroSync(null);
                setSyncroCustomers(null);
              }
            );
          } else {
            setLoadData(false);
            setSyncroCustomers(data);
          }
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setSyncroSync(null);
        }
      );
    } else if (syncroSync.syncro_sync) {
      http.Post(
        `syncro_sync/`,
        {
          company_id: syncroSync.id,
          syncro_sync: !syncroSync.syncro_sync,
          syncro_customer_id: syncroSync.syncro_customer_id,
          company_name: syncroSync.company_name,
        },
        (data) => {
          setSyncing(data.messages);
          setTimeout(() => {
            setSyncing(null);
          }, 5000);
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          fetchData();
          setLoadData(false);
          setSyncroSync(null);
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setSyncroSync(null);
        }
      );
    }
  };

  const toggleAutotaskSync = () => {
    setLoadData(true);
    if (!autotaskSync.autotask_sync && autotaskSync.autotask_company_id) {
      http.Get(
        `autotask_companies/?autotask_company_id=${autotaskSync.autotask_company_id}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setAutotaskSync(null);
            setLoadData(false);
            return;
          }
          if (data && data.companyName === autotaskSync.company_name) {
            http.Post(
              `autotask_sync/`,
              {
                company_id: autotaskSync.id,
                autotask_sync: !autotaskSync.autotask_sync,
                autotask_company_id: data.id,
                company_name: autotaskSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setAutotaskSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setAutotaskSync(null);
                setAutotaskCompanies(null);
              }
            );
          } else if (data && data.companyName) {
            setAutotaskCompanies([data]);
            setLoadData(false);
          } else {
            http.Get(
              `autotask_companies/`,
              (data) => {
                setAutotaskCompanies(data);
                setLoadData(false);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setAutotaskSync(null);
                setAutotaskCompanies(null);
              }
            );
          }
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setAutotaskSync(null);
        }
      );
    } else if (!autotaskSync.autotask_sync && !autotaskSync.autotask_company_id) {
      http.Get(
        `autotask_companies/?autotask_company_name=${autotaskSync.company_name}`,
        (data) => {
          if (data.error) {
            setError(data.error);
            setTimeout(() => {
              setError(null);
            }, 5000);
            setAutotaskSync(null);
            setLoadData(false);
            return;
          }
          if (data.length === 1) {
            http.Post(
              `autotask_sync/`,
              {
                company_id: autotaskSync.id,
                sync: !autotaskSync.autotask_sync,
                autotask_company_id: data[0].id,
                company_name: autotaskSync.company_name,
              },
              (data) => {
                setSyncing(data.messages);
                setTimeout(() => {
                  setSyncing(null);
                }, 5000);
                setError(data.error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                fetchData();
                setLoadData(false);
                setAutotaskSync(null);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setAutotaskSync(null);
                setAutotaskCompanies(null);
              }
            );
          } else if (data.length === 0) {
            http.Get(
              `autotask_companies/`,
              (data) => {
                setAutotaskCompanies(data);
                setLoadData(false);
              },
              (status, error) => {
                setError(error);
                setTimeout(() => {
                  setError(null);
                }, 5000);
                setLoadData(false);
                setAutotaskSync(null);
                setAutotaskCompanies(null);
              }
            );
          } else {
            setLoadData(false);
            setAutotaskCompanies(data);
          }
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setAutotaskSync(null);
        }
      );
    } else if (autotaskSync.autotask_sync) {
      http.Post(
        `autotask_sync/`,
        {
          company_id: autotaskSync.id,
          autotask_sync: !autotaskSync.autotask_sync,
          autotask_company_id: autotaskSync.autotask_company_id,
          company_name: autotaskSync.company_name,
        },
        (data) => {
          setSyncing(data.messages);
          setTimeout(() => {
            setSyncing(null);
          }, 5000);
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          fetchData();
          setLoadData(false);
          setAutotaskSync(null);
        },
        (status, error) => {
          setError(error);
          setTimeout(() => {
            setError(null);
          }, 5000);
          setLoadData(false);
          setAutotaskSync(null);
        }
      );
    }
  };

  return (
    <div>
      {selectedCompany && showDetails==="sync" && (
        <Popover
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popover}>
            <Grid container>
              {tenant.is_connect_sync_available && (
                <>
                  <Grid item xs={9}>
                    CW:
                  </Grid>
                  {hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.sync ? (
                        <Tooltip title={CompanyLang.off[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setCwSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.No[lang]}
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title={CompanyLang.on[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setCwSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.Yes[lang]}
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  }
                  {!hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.sync ? companyList.Yes[lang] : companyList.No[lang]}
                    </Grid>
                  }
                </>
              )}
              {tenant.is_ninja_sync_available &&
                selectedCompany.ninja_organization_id && (
                  <>
                    <Grid item xs={9}>
                      NINJA:
                    </Grid>
                    {hasPermission(props, ["Sync"]) &&
                      <Grid item xs={3}>
                        <Tooltip title="Click to sync from Ninja">
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setNinjaSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.Yes[lang]}
                          </span>
                        </Tooltip>
                      </Grid>
                    }
                    {!hasPermission(props, ["Sync"]) &&
                      <Grid item xs={3}>
                        {companyList.Yes[lang]}
                      </Grid>
                    }
                  </>
                )}
              {tenant.is_syncro_sync_available && (
                <>
                  <Grid item xs={9}>
                    SYNCRO:
                  </Grid>
                  {hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.syncro_sync ? (
                        <Tooltip title={CompanyLang.off[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setSyncroSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.No[lang]}
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title={CompanyLang.on[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setSyncroSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.Yes[lang]}
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  }
                  {!hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.syncro_sync ? companyList.Yes[lang] : companyList.No[lang]}
                    </Grid>
                  }
                </>
              )}
              {tenant.is_autotask_sync_available && (
                <>
                  <Grid item xs={9}>
                    AUTOTASK:
                  </Grid>
                  {hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.autotask_sync ? (
                        <Tooltip title={CompanyLang.off[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setAutotaskSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.No[lang]}
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title={CompanyLang.on[lang]}>
                          <span
                            className={classes.cursor}
                            onClick={() => {
                              setAutotaskSync(selectedCompany);
                              setAnchorEl(null);
                            }}
                          >
                            {companyList.Yes[lang]}
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  }
                  {!hasPermission(props, ["Sync"]) &&
                    <Grid item xs={3}>
                      {selectedCompany.autotask_sync ? companyList.Yes[lang] : companyList.No[lang]}
                    </Grid>
                  }
                </>
              )}
            </Grid>
          </div>
        </Popover>
      )}
      {selectedCompany && showDetails==="share" && sharings && (
        <Popover
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={() => {
            setSelectedCompany(null);
            setAnchorEl(null);
            setSharing({});
            setSharings(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popover}>
            <Grid container>
              {sharings && sharings.map((s)=>{
                return(
                  <>
                  <Grid item xs={5}>
                    {s.to_tenant_name}
                  </Grid>
                  <Grid item xs={5} style={{display: "flex",}}>
                    <Checkbox 
                      checked={s.readonly} 
                      style={{marginTop: -10,}}
                      onClick={(e)=>{
                        http.Put(
                          `sharings/${s.id}`,
                          {...s,"readonly":!s.readonly},
                          (data) => {
                            fetchSharings();
                          },
                          (status, error) => {
                          }
                        );

                      }}/> {companyList.Readonly[lang]}
                  </Grid>
                  <Grid item xs={2}>
                    <CancelIcon 
                      className={classes.cursor}
                      onClick={(e)=>{
                        http.Delete(
                          `sharings/${s.id}`,
                          {},
                          (data) => {
                            fetchSharings();
                          },
                          (status, error) => {
                          }
                        );

                      }}/>
                  </Grid>
                  </>
                );
              })}
              <Grid item xs={10}>
                <Select fullWidth value={sharing.tenant} onChange={(e)=>{setSharing({...sharing,"tenant":e.target.value});}}>
                  <MenuItem disabled>{" "}
                  </MenuItem>
                  {sharings && tenants && tenants.map((t)=>{
                    if(!sharings.some((s)=>(s.tenant === t.to_tenant))){
                      return(
                        <MenuItem value={t.to_tenant}>{t.to_tenant_name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <AddIcon className={classes.cursor} onClick={(e)=>{
                  http.Post(
                    `sharings`,
                    {
                      "company": selectedCompany.id,
                      "tenant": sharing.tenant,
                    },
                    (data) => {
                      fetchSharings();
                    },
                    (status, error) => {
                    }
                  );
                }}/>
              </Grid>
            </Grid>
          </div>
        </Popover>
      )}
      {cwSync && !loadData && !cwCompanies && (
        <DeleteAlertDialog
          open={true}
          onAgree={toggleSync}
          onDisagree={() => {
            setCwSync(null);
          }}
          primaryMessage={
            (cwSync.sync ? "Disconnect " : "Connect ") +
            cwSync.company_name +
            " with ConnectWise?"
          }
          secondaryMessage={""}
        />
      )}
      {syncroSync && !loadData && !syncroCustomers && (
        <DeleteAlertDialog
          open={true}
          onAgree={toggleSyncroSync}
          onDisagree={() => {
            setSyncroSync(null);
          }}
          primaryMessage={
            (syncroSync.syncro_sync ? "Disconnect " : "Connect ") +
            syncroSync.company_name +
            " with Syncro?"
          }
          secondaryMessage={""}
        />
      )}
      {autotaskSync && !loadData && !autotaskCompanies && (
        <DeleteAlertDialog
          open={true}
          onAgree={toggleAutotaskSync}
          onDisagree={() => {
            setAutotaskSync(null);
          }}
          primaryMessage={
            (autotaskSync.autotask_sync ? "Disconnect " : "Connect ") +
            autotaskSync.company_name +
            " with AutoTask?"
          }
          secondaryMessage={""}
        />
      )}
      {syncing && (
        <div className={classes.loadingBlock}>
          <div className={classes.absoluteCenter}>
            {syncing.map((message) => {
              return <div>{message}</div>;
            })}
          </div>
        </div>
      )}
      {error && (
        <div className={classes.loadingBlock}>
          <div className={classes.absoluteCenter}>
            <h1 className={classes.error}>{error}</h1>
          </div>
        </div>
      )}
      {ninjaSync && !loadData && (
        <DeleteAlertDialog
          open={true}
          onAgree={handleNinjaSync}
          onDisagree={() => {
            setNinjaSync(null);
          }}
          primaryMessage={"Do you want to continue?"}
          secondaryMessage={"If you agree, local data will be overwritten."}
        />
      )}
      {cwSync && cwCompanies && !loadData && (
        <Dialog
          open={true}
          onClose={() => {
            setCwCompanies(null);
            setLoadData(false);
            setCwSync(null);
            setSearch(null);
          }}
        >
          <div
            style={{
              padding: "20px",
              minWidth: "600px",
              minHeight: "400px",
            }}
          >
            <h3>Company Mapping</h3>
            <h4>SyncMonkey: </h4>
            {cwSync.company_name} ({cwSync.identifier})
            <br /> <br />
            <h4>
              ConnectWise: &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                placeholder="Search"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.target.value) {
                    setSearch(e.target.value);
                    e.preventDefault();
                  }
                }}
              ></Input>
            </h4>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {cwCompanies &&
                  cwCompanies.map((cwCompany) => {
                    if (
                      !search ||
                      cwCompany.name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      cwCompany.identifier
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return (
                        <Grid container spacing={1} key={cwCompany.id}>
                          <Grid item xs={6}>
                            {cwCompany.name} ({cwCompany.identifier})
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use CW Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `sync/`,
                                  {
                                    company_id: cwSync.id,
                                    sync: !cwSync.sync,
                                    cw_company_id: cwCompany.id,
                                    company_name: cwCompany.name,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setCwSync(null);
                                    setCwCompanies(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setCwSync(null);
                                    setCwCompanies(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use SM Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `sync/`,
                                  {
                                    company_id: cwSync.id,
                                    sync: !cwSync.sync,
                                    cw_company_id: cwCompany.id,
                                    company_name: cwSync.company_name,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setCwSync(null);
                                    setCwCompanies(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setCwSync(null);
                                    setCwCompanies(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <br />
            <br />
            <Chip
              label="Or Create A New Company"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                http.Post(
                  `sync/`,
                  {
                    company_id: cwSync.id,
                    sync: !cwSync.sync,
                    company_name: cwSync.company_name,
                  },
                  (data) => {
                    setSyncing(data.messages);
                    setTimeout(() => {
                      setSyncing(null);
                    }, 5000);
                    setError(data.error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setCwSync(null);
                    setCwCompanies(null);
                    fetchData();
                  },
                  (status, error) => {
                    setError(error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setCwSync(null);
                    setCwCompanies(null);
                  }
                );
              }}
            />
            <Chip
              label="Show All Companies on ConnectWise"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                setSearch(null);
                http.Get(
                  `cw_companies/`,
                  (data) => {
                    setCwCompanies(data);
                    setLoadData(false);
                  },
                  () => {
                    console.error(error);
                    setLoadData(false);
                    setCwSync(null);
                    setCwCompanies(null);
                  }
                );
              }}
            />
          </div>
        </Dialog>
      )}
      {syncroSync && syncroCustomers && !loadData && (
        <Dialog
          open={true}
          onClose={() => {
            setSyncroCustomers(null);
            setLoadData(false);
            setSyncroSync(null);
            setSearch(null);
          }}
        >
          <div
            style={{
              padding: "20px",
              minWidth: "600px",
              minHeight: "400px",
            }}
          >
            <h3>Company Mapping</h3>
            <h4>SyncMonkey: </h4>
            {syncroSync.company_name}
            <br /> <br />
            <h4>
              Syncro: &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                placeholder="Search"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.target.value) {
                    setSearch(e.target.value);
                    e.preventDefault();
                  }
                }}
              ></Input>
            </h4>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {syncroCustomers &&
                  syncroCustomers.map((syncroCustomer) => {
                    if (
                      !search ||
                      syncroCustomer.business_and_full_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return (
                        <Grid container spacing={1} key={syncroCustomer.id}>
                          <Grid item xs={6}>
                            {syncroCustomer.business_and_full_name}
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use Syncro Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `syncro_sync/`,
                                  {
                                    company_id: syncroSync.id,
                                    syncro_sync: !syncroSync.syncro_sync,
                                    syncro_customer_id: syncroCustomer.id,
                                    company_name: syncroCustomer.business_name,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setSyncroSync(null);
                                    setSyncroCustomers(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setSyncroSync(null);
                                    setSyncroCustomers(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use SM Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `syncro_sync/`,
                                  {
                                    company_id: syncroSync.id,
                                    syncro_sync: !syncroSync.syncro_sync,
                                    syncro_customer_id: syncroCustomer.id,
                                    company_name: syncroSync.company_name,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setSyncroSync(null);
                                    setSyncroCustomers(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setSyncroSync(null);
                                    setSyncroCustomers(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <br />
            <br />
            <Chip
              label="Or Create A New Company"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                http.Post(
                  `syncro_sync/`,
                  {
                    company_id: syncroSync.id,
                    syncro_sync: !syncroSync.syncro_sync,
                    company_name: syncroSync.company_name,
                  },
                  (data) => {
                    setSyncing(data.messages);
                    setTimeout(() => {
                      setSyncing(null);
                    }, 5000);
                    setError(data.error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setSyncroSync(null);
                    setSyncroCustomers(null);
                    fetchData();
                  },
                  (status, error) => {
                    setError(error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setSyncroSync(null);
                    setSyncroCustomers(null);
                  }
                );
              }}
            />
            <Chip
              label="Show All Companies on Syncro"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                setSearch(null);
                http.Get(
                  `syncro_companies/`,
                  (data) => {
                    setSyncroCustomers(data);
                    setLoadData(false);
                  },
                  () => {
                    setLoadData(false);
                    setSyncroSync(null);
                    setSyncroCustomers(null);
                  }
                );
              }}
            />
          </div>
        </Dialog>
      )}
      {autotaskSync && autotaskCompanies && !loadData && (
        <Dialog
          open={true}
          onClose={() => {
            setAutotaskCompanies(null);
            setLoadData(false);
            setAutotaskSync(null);
            setSearch(null);
          }}
        >
          <div
            style={{
              padding: "20px",
              minWidth: "600px",
              minHeight: "400px",
            }}
          >
            <h3>Company Mapping</h3>
            <h4>SyncMonkey: </h4>
            {autotaskSync.company_name}
            <br /> <br />
            <h4>
              AutoTask: &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                placeholder="Search"
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.target.value) {
                    setSearch(e.target.value);
                    e.preventDefault();
                  }
                }}
              ></Input>
            </h4>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {autotaskCompanies &&
                  autotaskCompanies.map((autotaskCompany) => {
                    if (
                      !search ||
                      autotaskCompany.companyName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return (
                        <Grid container spacing={1} key={autotaskCompany.id}>
                          <Grid item xs={6}>
                            {autotaskCompany.companyName}
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use AutoTask Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `autotask_sync/`,
                                  {
                                    company_id: autotaskSync.id,
                                    autotask_sync: !autotaskSync.autotask_sync,
                                    autotask_company_id: autotaskCompany.id,
                                    company_name: autotaskCompany.companyName,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setAutotaskSync(null);
                                    setAutotaskCompanies(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setAutotaskSync(null);
                                    setAutotaskCompanies(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Chip
                              label="Use SM Name"
                              clickable
                              className={classes.chipSelect}
                              color="primary"
                              onClick={() => {
                                setLoadData(true);
                                http.Post(
                                  `autotask_sync/`,
                                  {
                                    company_id: autotaskSync.id,
                                    autotask_sync: !autotaskSync.autotask_sync,
                                    autotask_company_id: autotaskCompany.id,
                                    company_name: autotaskSync.company_name,
                                  },
                                  (data) => {
                                    setSyncing(data.messages);
                                    setTimeout(() => {
                                      setSyncing(null);
                                    }, 5000);
                                    setError(data.error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    fetchData();
                                    setLoadData(false);
                                    setAutotaskSync(null);
                                    setAutotaskCompanies(null);
                                  },
                                  (status, error) => {
                                    setError(error);
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setLoadData(false);
                                    setAutotaskSync(null);
                                    setAutotaskCompanies(null);
                                  }
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <br />
            <br />
            <Chip
              label="Or Create A New Company"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                http.Post(
                  `autotask_sync/`,
                  {
                    company_id: autotaskSync.id,
                    autotask_sync: !autotaskSync.autotask_sync,
                    company_name: autotaskSync.company_name,
                  },
                  (data) => {
                    setSyncing(data.messages);
                    setTimeout(() => {
                      setSyncing(null);
                    }, 5000);
                    setError(data.error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setAutotaskSync(null);
                    setAutotaskCompanies(null);
                    fetchData();
                  },
                  (status, error) => {
                    setError(error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                    setAutotaskSync(null);
                    setAutotaskCompanies(null);
                  }
                );
              }}
            />
            <Chip
              label="Show All Companies on AutoTask"
              clickable
              className={classes.chipSelect}
              color="primary"
              onClick={() => {
                setLoadData(true);
                setSearch(null);
                http.Get(
                  `autotask_companies/`,
                  (data) => {
                    setAutotaskCompanies(data);
                    setLoadData(false);
                  },
                  () => {
                    setLoadData(false);
                    setAutotaskSync(null);
                    setAutotaskCompanies(null);
                  }
                );
              }}
            />
          </div>
        </Dialog>
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={companyList.Companies[lang]}
          url={url}
          importUrl={`companies/import`}
          csvHeader={csvHeader}
          tableColumns={tableColumns}
          unchangedData={unchangedData}
          data={data}
          actions={actions}
          permissions={{
            create: ["Create Company"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          fetchData={fetchData}
          objectForm={
            props.user.max_company > -1 && data.length >= props.user.max_company
              ? null
              : CompanyForm
          }
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={(props.user.company_name || "") + " | Companies"}
          loadData={loadData}
          packageLimit={props.user.max_company}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Companies"}
          url={url}
          importUrl={`companies/import`}
          csvHeader={csvHeader}
          tableColumns={tableColumns}
          unchangedData={unchangedData}
          data={data}
          actions={actions}
          permissions={{
            create: ["Create Company"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          fetchData={fetchData}
          objectForm={
            props.user.max_company > -1 && data.length >= props.user.max_company
              ? null
              : CompanyForm
          }
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={(props.user.company_name || "") + " | Companies"}
          loadData={loadData}
          packageLimit={props.user.max_company}
        />
      )}
    </div>
  );
};

export default CompanyList;
