import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import HRMeetingForm from "./HRMeetingForm";
import { formatDateTime } from "../../Common/Utility";

const HRMeetings = (props) => {
  const [data, setData] = useState([]);
  const url = `hr_employee_meetings?hr_employee=${props.hr_employee}`;

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "name",
      label: "NAME",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "start_time",
      label: "START",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "end_time",
      label: "END",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "location",
      label: "LOCATION",
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    http.Get(
      url,
      (data) => {
        data.map((meeting) => {
          meeting.start_time = formatDateTime(meeting.start_time);
          meeting.end_time = formatDateTime(meeting.end_time);
        });
        setData(data);
      },
      () => {}
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={" "}
      url={url}
      templateUrl={"public_templates?template_type_name=HR"}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["HR"],
        edit: ["HR"],
        delete: ["HR"],
      }}
      fetchData={fetchData}
      objectForm={HRMeetingForm}
      orderBy={"name"}
      searchFields={["name"]}
      pageHeader={""}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={" "}
      url={url}
      templateUrl={"public_templates?template_type_name=HR"}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["HR"],
        edit: ["HR"],
        delete: ["HR"],
      }}
      fetchData={fetchData}
      objectForm={HRMeetingForm}
      orderBy={"name"}
      searchFields={["name"]}
      pageHeader={""}
    />
    }
    </>
  );
};

export default HRMeetings;
