import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {Divider, Typography, Paper, Grid, IconButton, Snackbar,} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import ScreenLoader from "../../Common/Loader";
import OTP from "../../Common/OTP";

const EmployeeCredentialDetails = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    var { employeeCredentialId } = props.match.params;  
    const [employeeCredential, setEmployeeCredential] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if(props.objectId){
        employeeCredentialId = props.objectId;
      }
      http.Get(
        `employee_credentials/${employeeCredentialId}`,
        (employeeCredential) => {
            setEmployeeCredential(employeeCredential);
            setLoading(false);
        },
        () => {}
      );
    }, []);

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <Grid container spacing={1}>
            <Grid item xs={9}>
                <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
                    {employeeCredential.employee_name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{paddingTop:10,float:"right"}}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => {
                            if(props.handleClose){
                                props.handleClose();
                            }
                            else{
                                props.history.push(
                                    process.env.PUBLIC_URL + `/company/${id}/employees`
                            );
                            }
                        }}
                    >
                    <CancelIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <div
                        style={{ maxHeight: "calc(100vh - 280px)",
                            minHeight: "calc(100vh - 280px)",
                            overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Snackbar
                            open={Boolean(message)}
                            autoHideDuration={3000}
                            onClose={() => {
                                setMessage(null);
                            }}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <Alert
                                onClose={() => {
                                    setMessage(null);
                                }}
                                severity="success"
                                elevation={6}
                                variant="filled"
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>ACCOUNT TYPE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {employeeCredential.category_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>DESCRIPTION</span>
                            </Grid>
                            <Grid item xs={8}>
                                {employeeCredential.description}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>WEBSITE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {employeeCredential.website}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>USERNAME</span>
                            </Grid>
                            <Grid item xs={8}>
                                {employeeCredential.username}&nbsp;&nbsp;
                                <FilterNoneIcon
                                    fontSize="small"
                                    style={{
                                        cursor: "pointer",
                                        color: "#a4a4a4",
                                        width: 15,
                                        verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                        onCopy(props, employeeCredential, "username");
                                        setMessage("Username Copied");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>PASSWORD</span>
                            </Grid>
                            <Grid item xs={8}>
                                <span
                                    className={classes.cursor}
                                    onClick={(e) => {
                                        if (e.target.innerHTML === "············") {
                                            e.target.innerHTML = String(employeeCredential.password).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                        } else {
                                            e.target.innerHTML = "············";
                                        }
                                    }}
                                >
                                    ············
                                </span>&nbsp;&nbsp;
                                <FilterNoneIcon
                                    fontSize="small"
                                    style={{
                                        cursor: "pointer",
                                        color: "#a4a4a4",
                                        width: 15,
                                        verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                        onCopy(props, employeeCredential, "password");
                                        setMessage("Password Copied");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>OTP</span>
                            </Grid>
                            <Grid item xs={8}>
                                <OTP {...props} otpKey={employeeCredential.otp} />
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
        </Grid>
        }
        </>
    );
};

export default EmployeeCredentialDetails;