import React, { useState, useEffect } from 'react';
import http from "../../Common/RestAPIHandler";
import Chip from "@material-ui/core/Chip";
import Input from '@material-ui/core/Input';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin:"10px",
    backgroundColor: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
}));

const AccountingReport = (props) => {
  const classes = useStyles();
	const [state, setState] = useState({
		'payments':[],
		'amountTotal':0,
	});

	const handleSearch = (e) => {		
		var startDate = document.getElementById("startDate").value;
		var endDate = document.getElementById("endDate").value;
		if(startDate && endDate){      
      http.Get(
        'company_payments?start_date=' + startDate + '&end_date=' + endDate,
        (data) => {
          var payments = [];
          var amountTotal = 0;
          data.map(payment =>{
            payment["invoice_number"] = payment.response_content.split("<ReceiptId>")[1].split("</ReceiptId>")[0].split("(")[0];
            payment["amount"] = parseFloat(payment.response_content.split("<TransAmount>")[1].split("</TransAmount>")[0]);
            payment["payment_time"] = payment.payment_time.split("T")[0];
            payments.push(payment);
            amountTotal += payment["amount"];
          })
          setState({payments:payments,amountTotal:amountTotal});
        },
        () => {
        }
      );
		}
	};

	return (
		<div>
			<span style={{margin:"10px"}}> From: <Input type="date" name="startDate" id="startDate"/></span>
			<span style={{margin:"10px"}}> To: <Input type="date" name="endDate" id="endDate"/></span>
      <Chip
          label={"Search"}
          clickable
          color="primary"
          className={classes.chip}
          onClick={handleSearch}
        />
      <br/>
      <br/>
			<Table>
				<TableHead>
          <TableRow>
            <TableCell align="left">Payment ID</TableCell>
            <TableCell align="left">Invoice Number</TableCell>
            <TableCell align="left">Card Number</TableCell>
            <TableCell align="left">Tranction Time</TableCell>
            <TableCell align="left">Amount</TableCell>
          </TableRow>
        </TableHead>
				<TableBody>
          {state.payments.map(payment => {
            return (
              <TableRow key={payment.id}>
                <TableCell>{payment.payment_id}</TableCell>
                <TableCell>{payment.invoice_number}</TableCell>
                <TableCell>{payment.payment_card_number}</TableCell>
                <TableCell>{payment.payment_time}</TableCell>
                <TableCell>{payment.amount}</TableCell>
              </TableRow>
            );
          })}
			  </TableBody>
			<TableFooter>
			<tr>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td>Total: {Math.round(state.amountTotal)}</td>
				<td></td>
			</tr>
			</TableFooter>
			</Table>
		</div>
	);
	
};

export default AccountingReport;