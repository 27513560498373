import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../Common/Style/FormStyle";
import ScreenLoader from "../../Common/Loader";
import Dialog from "@material-ui/core/Dialog";
import FileViewer from "../../Common/FileViewer";

const HRFileForm = (props) => {
  const classes = useStyles();
  const [account, setAccount] = useState({
    hr_employee: props.hr_employee,
  });
  const [accountError, setAccountError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [filePath, setFilePath] = useState();

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `hr_employee_files/${props.objectId}`,
        (account) => {
          setAccount(account);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  const onFileChange = (e) => {
    if ("file" === e.target.type) {
      setAccount({
        ...account,
        [e.target.name]: e.target.files[0],
        name: e.target.files[0].name,
      });
    } else {
      setAccount({
        ...account,
        [e.target.name]: e.target.value,
      });
    }
    setAccountError({
      ...accountError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onFileSubmit = (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("name", account.name);
    formData.append("file", account.file, account.file.name);
    formData.append("hr_employee", account.hr_employee);

    setIsSubmitted(true);

    if (props.edit && props.objectId) {
      http.Put(
        `hr_employee_files/${props.objectId}`,
        formData,
        () => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setAccountError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `hr_employee_files`,
        formData,
        (data) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setAccountError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
    {filePath &&
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={() => {
          setFilePath(null);
        }}>
        <FileViewer
          fileType={filePath.fileType.toLowerCase()}
          filePath={filePath.filePath}
          onError={() => {
            setFilePath(null);
          }}
        />
      </Dialog>
    }
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.edit ? "Edit File" : "Add File"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>NAME</p>
              <TextValidator
                id="name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="name"
                value={account.name || ""}
                onChange={onFileChange}
                error={Boolean(accountError.name)}
                helperText={accountError.name && accountError.name[0]}
              />
              <p className={classes.label}>File</p>
              {account.file && ( 
                <a className={classes.link} href="#" onClick={()=>{
                  if(account.file_src){
                    setFilePath({filePath:account.file_src,fileType:account.file.split('?')[0].split('.').pop()});
                  }
                  else{
                    alert("File Not Found!");
                  }
                  }}>
                  {account.name}
                </a>
              )}
              <TextValidator
                id="file"
                fullWidth
                variant="outlined"
                margin="dense"
                name="file"
                type="file"
                onChange={onFileChange}
                error={Boolean(accountError.file)}
                helperText={accountError.file && accountError.file[0]}
              />
            </div>
          )}
        </div>

        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              disabled={isSubmitted}
              onClick={onFileSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default HRFileForm;
