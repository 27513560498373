import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Divider } from "@material-ui/core";
import http from "../../Common/RestAPIHandler";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "right",
		color: theme.palette.text.secondary,
	},
	container: {
		backgroundColor: "white",
	},
	containerItem: {
		backgroundColor: "#fafafa",
	},
}));

const Dashboard = (props) => {
	const classes = useStyles();
	const [tenants, setTenants] = React.useState();
	const [tenantPayments, setTenantPayments] = React.useState();
	const [tenantPaymentInfos, setTenantPaymentInfos] = React.useState();
	const [recentSingUps, setRecentSingUps] = React.useState();
	const [dailySingUps, setDailySingUps] = React.useState();

	if (window.location.hash) {
		window.location.href = window.location.href.split("#")[0];
	}

	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
		const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
		
		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};
	
	React.useEffect(() => {		
		http.Get(
			"tenants",
			(data) => {
				var today = new Date();
				var signUps = [];
				for(let i = 0; i <= 30; i++)
				{
					var date = new Date();
					date.setDate(today.getDate() - 30 + i);
					signUps.push(
						{
							name: date.toLocaleDateString(),
							amount: 0,
						}
					);
				}
				var activeCount = 0;
				var trialCount = 0;
				var lockedCount = 0;
				data.map((tenant)=>{
					tenant["created_time"] = new Date(tenant["created_time"]).toLocaleDateString();
					var createdDate = new Date(tenant["created_time"]).toLocaleDateString();
					signUps.map((signUp)=>{
						if(createdDate === signUp.name){
							signUp.amount = signUp.amount + 1;
						}
					});

					if(tenant["status"]==="Active"){
						activeCount = activeCount + 1;
					}
					else if(tenant["status"]==="Lockout"){
						lockedCount = lockedCount + 1;
					}
					else{
						trialCount = trialCount + 1;
					}
				});
				setRecentSingUps(data.slice(0,14));
				setDailySingUps(signUps);
				setTenants([
					{ name: 'Active', value: activeCount, color: '#0088FE' },
					{ name: 'Trial', value: trialCount, color: '#00C49F' },
					{ name: 'Locked', value: lockedCount, color: '#FFBB28' },]);
			},
			() => {
			}
		  );

		http.Get(
			"tenant_payments",
			(data) => {				
				var today = new Date();
				var year = today.getFullYear();
				var month = today.getMonth();
				var payments = [];
				for(let i = month; i <= 12; i++)
				{
					payments.push(
						{
							name: (year-1) + "-" + (i>9?i:("0"+i)),
							income: 0,
						}
					);
				}
				for(let i =1; i <= month; i++)
				{
					payments.push(
						{
							name: year + "-" + (i>9?i:("0"+i)),
							income: 0,
						}
					);

				}
				data.map((tenant_payment)=>{
					payments.map((payment)=>{
						if(tenant_payment["time"].startsWith(payment.name)){
							payment.income = payment.income + parseFloat(tenant_payment["amount"]);
						}
					});
				});
				setTenantPayments(payments);
			},
			() => {
			}
		);

		http.Get(
			"tenant_payment_infos",
			(data) => {
				data.sort((a,b)=>{
					return b.total - a.total;
				});
				if(data.lenght > 10){
					data.slice(0, 10);
				}
				setTenantPaymentInfos(data);
			},
			() => {
			}
			);
	}, []);

	return (
		<div className={classes.root}>
			<Grid container spacing={6}>
				{/* Left */}
				<Grid item xs={12} sm={12} md={12} lg={6}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Grid container spacing={3} className={classes.container}>
								<Grid item xs={12}>	
									<h3>Trial Conversion Percentage:</h3>
									<Divider/>
									{tenants && tenants.map((tenant, index) => (
										<>										
										{tenant.name}: <span style={{backgroundColor:tenant.color}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</>
									))}
									{tenants &&
										<PieChart width={500} height={500}>
											<Pie
												data={tenants}
												cx="50%"
												cy="50%"
												labelLine={false}
												label={renderCustomizedLabel}
												outerRadius={200}
												fill="#8884d8"
												dataKey="value"
											>
												{tenants.map((tenant, index) => (
													<Cell key={`cell-${index}`} fill={tenant.color} />
												))}
											</Pie>
										</PieChart>
									}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={3} className={classes.container}>
								<Grid item xs={12}>	
									<h3>Sign Up History:</h3>
									<Divider/>
									{dailySingUps &&				
										<BarChart
											width={600}
											height={300}
											data={dailySingUps}
											margin={{
												top: 30,
												right: 20,
												left: 20,
												bottom: 5,
											}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="amount" name="SignUps" fill="#8884d8" />
										</BarChart>
									
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{/* right */}
				<Grid item xs={12} sm={12} md={12} lg={6}>
					<Grid container direction="column" spacing={6}>
						<Grid item xs={12}>
							<Grid container spacing={3} className={classes.container}>
								<Grid item xs={12}>
									<h3>Account Income:</h3>
									<Divider/>
									{tenantPayments &&					
										<BarChart
											width={600}
											height={300}
											data={tenantPayments}
											margin={{
												top: 30,
												right: 20,
												left: 20,
												bottom: 5,
											}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="income" name="Income" fill="#8884d8" />
										</BarChart>
									}	
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={3} className={classes.container}>
								<Grid item xs={12}>									
								<h3>Top 10 Tenant Value:</h3>
									<Divider/>
									{tenantPaymentInfos &&													
										<BarChart
											width={600}
											height={300}
											data={tenantPaymentInfos}
											margin={{
												top: 30,
												right: 20,
												left: 20,
												bottom: 5,
											}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="company_name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar dataKey="total" name="Value" fill="#FFD700" />
										</BarChart>
									}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={3} className={classes.container}>
								<Grid item xs={12}>									
								<h3>Recent Sign Up:</h3>
									<Divider/>
									<Grid container>
										<Grid item xs={3} style={{ fontWeight: "bold" }}>Date</Grid>
										<Grid item xs={3} style={{ fontWeight: "bold" }}>Company Name</Grid>
										<Grid item xs={3} style={{ fontWeight: "bold" }}>Package</Grid>
										<Grid item xs={3} style={{ fontWeight: "bold" }}>Reseller</Grid>
										{recentSingUps && recentSingUps.map((recentSingUp)=>{
											return(
											<>
												<Grid item xs={3}>{recentSingUp.created_time}</Grid>
												<Grid item xs={3}>{recentSingUp.company_name}</Grid>
												<Grid item xs={3}>{recentSingUp.package_name}</Grid>
												<Grid item xs={3}>{recentSingUp.reseller_name}</Grid>
											</>
											)
										})}
									</Grid>
								</Grid>
							</Grid>
						</Grid>					
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};


export default Dashboard;
