import http from "./RestAPIHandler";
import commonConstants from "./CommonConstants";
const Papa = commonConstants.Papa;

const importObjectList = (
  resultData,
  url,
  moduleName,
  successCallback,
  warningCallback,
  failedCallback
) => {
  http.Post(
    url,
    resultData,
    data => {
      if (data.failed === 0) successCallback(data);
      else {
        unparse([], data.failed_data, "Failed " + moduleName);
        warningCallback(data);
      }
    },
    (status, error) => {
      if (status === 400) {
        unparse([], error.failed_data, "Failed " + moduleName);
        failedCallback(error);
      }
    }
  );
};

export const parse = (
  file,
  header,
  headerMap,
  url,
  moduleName,
  packageLimit,
  currentTotData,
  emptyCallback,
  formatMismatchCallback,
  successCallback,
  warningCallback,
  failedCallback,
  importLimitCallback,
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: result => {
      if (result.data.length === 0) {
        emptyCallback();
        return;
      }
      if(headerMap){
        result.data.map((row)=>{
          Object.keys(headerMap).map((key)=>{
            row[key] = row[headerMap[key]];
          });
        });
      }
      var isValidFormat = header.every(field =>
        Object.keys(result.data[0]).includes(field)
      );
      if (!isValidFormat) {
        formatMismatchCallback(Object.keys(result.data[0]));
        return;
      }
      if (packageLimit && packageLimit>-1 && currentTotData && importLimitCallback && result.data.length + currentTotData > packageLimit) {
        importLimitCallback();
        return;
      }
      importObjectList(
        result.data,
        url,
        moduleName,
        successCallback,
        warningCallback,
        failedCallback
      );
    }
  });
};

export const unparse = (header, data, fileName) => {
  var csv = Papa.unparse({
    fields: header.length > 0 && header,
    data: data
  });
  var blob = new Blob([csv]);
  var a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
