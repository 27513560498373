import React, { useEffect, useState } from "react";
import http from "../../Common/RestAPIHandler";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  Chip,
  Collapse,
  Divider,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from "@material-ui/core";
import ScreenLoader from "../../Common/Loader";
import useStyles from "../Style/style";

const PendingTimeOffRequests = (props) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      `hr_employee_vacations/pending_requests/${props.user.id}`,
      (data) => {
        data = data['data'];
        data.map((request) => {
          if (request.vacation_type === 'Other') request.vacation_type += ` (${request.reason})`;
        })
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setPending(collapse);
  };

  const handleRequestClick = (id, accepted) => {
    http.Post(
      `hr_employee_vacations/pending_requests/${props.user.id}`,
      {
        id: id,
        accepted: accepted,
      },
      (res) => {
        const msg = accepted ? 'Request accepted.' : 'Request rejected.';
        props.setSnackBarMessage(msg);
        props.setSnackBarOpen(true);
        props.setSeverity("success");
        fetchData();
      },
      (status, error) => {
        if (status == 400) props.setSnackBarMessage('Request already accepted/rejected.');
        else props.setSnackBarMessage(`Error status: ${status}`);
        props.setSnackBarOpen(true);
        props.setSeverity("error");
        fetchData();
      }
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>PENDING TIME OFF REQUESTS</span>
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <Collapse in={!collapse}>
            <Grid item xs={12} className={classes.tableGrid}>
              <TableContainer
                component={Paper}
                classes={{ root: classes.tableContainer }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        EMPLOYEE
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        START DATE
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        END DATE
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        TYPE
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        IS PAID
                      </TableCell>
                      <TableCell colSpan={2} className={classes.tableHeader} />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.length === 0 && (
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={6} align="center">This table is empty.</TableCell>
                      </TableRow>
                    ) || (
                      <>
                        {data.map((request) =>
                          <TableRow key={request.id}>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                            >
                              {request.employee_name}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                            >
                              {request.start_date}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                            >
                              {request.end_date ? request.end_date : 'N/A (Partial Day)'}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                            >
                              {request.vacation_type}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row"
                            >
                              {request.available_hours_used ? 'YES' : 'NO'}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                              colSpan={2}
                            >
                              <Chip
                                label="REJECT"
                                clickable
                                className={classes.chip}
                                color="primary"
                                onClick={() => handleRequestClick(request.id, false)}
                              />
                              <Chip
                                label="ACCEPT"
                                clickable
                                className={classes.chip}
                                color="primary"
                                onClick={() => handleRequestClick(request.id, true)}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </div>
  );
};

export default PendingTimeOffRequests;
