import React, { useEffect, useState } from "react";
import TenantUserForm from "./TenantUserForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import SnackbarComponent from "../../Common/Snackbar";
import { format } from "date-fns-tz";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import PageviewIcon from '@material-ui/icons/Pageview';
import BlockIcon from '@material-ui/icons/Block';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  }
}));

const tableColumns = [
  {
    id: "profile_image_src",
    label: "",
    minWidth: 50,
    sortable: false,
  },
  {
    id: "name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "email",
    label: "EMAIL",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "tenant_name",
    label: "COMPANY NAME",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "passwordCopy",
    label: "",
    minWidth: 10,
    sortable: false,
    colored: true,
    disableTooltip: true,
  },
  {
    id: "staredPassword",
    label: "INITIAL PASSWORD",
    minWidth: 175,
    sortable: false,
    colored: true,
    disableTooltip: true,
  },
  {
    id: "last_login",
    label: "LAST LOGIN",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "status",
    label: "",
    minWidth: 20,
    sortable: true,
    colored: true,
  },
];

const TenantUsers = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState();
  const url = `tenant_users`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var tenantUserList = [];
        data.map((tenantUser) => {
          tenantUser["status"] = 
          <>
            {tenantUser["is_tenant_admin"] ? (
              <Tooltip title='Tenant Admin'><SupervisorAccountIcon /></Tooltip>
            ) : (
              ""
            )}
            {tenantUser["is_readonly"] = tenantUser["is_readonly"] ? (
              <Tooltip title='Readonly'><PageviewIcon/></Tooltip>
            ) : (
              ""
            )}
            {tenantUser["has_access"] = tenantUser["has_access"] ? (
              ""
            ) : (
              <Tooltip title='No Access'><BlockIcon/></Tooltip>
            )}
          </>;
          tenantUser["name"] =
            tenantUser["first_name"] + " " + tenantUser["last_name"];
          tenantUser["profile_image_src"] = tenantUser["profile_image_src"] ? (
            <img
              width="50px"
              alt={tenantUser.id}
              src={tenantUser["profile_image_src"]}
            />
          ) : (
            ""
          );

          tenantUser["passwordCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                style={{cursor:"pointer"}}
                onClick={(props, row, columnId) => {
                  onCopy(props, tenantUser, "initial_password");
                  setSnackBarMessage("Password Copied");
                }}
              />
            </>
          );

          tenantUser["staredPassword"] = (
            <>
              <span
                style={{cursor:"pointer"}}
                onClick={(e) => {
                  if (e.target.innerHTML === "············")
                    e.target.innerHTML = String(tenantUser["initial_password"])
                      .replaceAll("<", "&lt;")
                      .replaceAll(">", "&gt;")
                      .replaceAll("null", "");
                  else e.target.innerHTML = "············";
                }}
              >
                ············
              </span>
            </>
          );
          tenantUser["last_login"] = tenantUser["last_login"] &&
            format(
              new Date(tenantUser["last_login"]),
              "yyyy-MM-dd HH:mm"
            );
          tenantUser["mobile_view"] = tenantUser["roles"];
          tenantUser["mobile_view_button"] = (
            <>
            {tenantUser.mobile &&
              <IconButton
                disableTouchRipple
                className={classes.button}
                edge="start"
                href={`tel://${tenantUser.mobile}`}
              >
                <PhoneIcon fontSize="small" />
              </IconButton>
            }
            {tenantUser.email &&            
              <IconButton
                className={classes.button}
                href={`mailto:${tenantUser.email}`}
              >
                <MailOutlineIcon fontSize="small" />
              </IconButton>
            }
            </>
          );
          tenantUserList.push(tenantUser);
        });
        setData(tenantUserList);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <SnackbarComponent
      message={snackBarMessage}
      open={snackBarMessage}
      handleSnackBarClose={() => {
        setSnackBarMessage(null);
      }}
      severity={"success"}
    ></SnackbarComponent>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Tenant Users"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Tenant Users"],
        edit: ["Create/Modify Tenant Users"],
        delete: ["Delete Tenant Users"],
      }}
      objectForm={TenantUserForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Registered Users"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Tenant Users"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Tenant Users"],
        edit: ["Create/Modify Tenant Users"],
        delete: ["Delete Tenant Users"],
      }}
      objectForm={TenantUserForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Registered Users"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default TenantUsers;
