import React, { useEffect, useState } from "react";
import JsonDataForm from "./JsonDataForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import CopyIcon from "@material-ui/icons/FileCopy";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarComponent from "../../Common/Snackbar";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import commonConstants from "../../Common/CommonConstants";
import CheckIcon from '@material-ui/icons/Check';
import { Dialog } from "@material-ui/core";
import JsonDataDetails from "./JsonDataDetails";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    float:"right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    fontFamily: "Raleway",
  },
}));

const JsonDatas = (props) => {
  const classes = useStyles();
	const { jsonSchemaId } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `json_datas?company=${props.user.company_id}&json_schema=${jsonSchemaId}`;
  const [jsonSchema, setJsonSchema] = useState();
  const [tableColumns, setTableColumns] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [searchFields, setSearchFields] = useState();
	const [objectId, setObjectId] = useState();

  const actions = {
    delete: true,
  };

  useEffect(() => {
		var showId = new URLSearchParams(window.location.search).get("show_id");
		if(showId){
			setObjectId(showId);
			props.history.replace({ search: window.location.search.replace("&show_id="+showId,"")});
		}
  }, []);

  useEffect(() => {
    http.Get(
      `json_schemas/${jsonSchemaId}`,
      (data) => {
        var columns = [];
        var fields = [];
        var jsonSchema = JSON.parse(data.json_schema);
        jsonSchema.properties && Object.entries(jsonSchema.properties).map(([key, property])=>{
          if(property.listed){
            if(property.copyable){
              columns.push(
                {
                  id: "copy" + key,
                  label: "",
                  minWidth: 20,
                  sortable: false,
                  disableTooltip: true,
                });
            }

            if(property.searchable){
              fields.push(key);
            }
  
            if(property.type==="password"){
              columns.push(
                {
                  id: "stared" + key,
                  label: key.toUpperCase(),
                  minWidth: 20,
                  sortable: false,
                  disableTooltip: true,
                });
            }
            else if(property.type==="url"){
              columns.push(
                {
                  id: key,
                  label: key.toUpperCase(),
                  minWidth: 20,
                  sortable: false,
                  onClick: onWebsiteClick,
                  style: commonConstants.cellCursor,
                  colored: true,
                });
            }
            else{
              if(columns.length>0){
                columns.push(
                  {
                    id: key,
                    label: key.toUpperCase(),
                    minWidth: 200,
                    sortable: true,
                  });
              }
              else{
                columns.push(
                  {
                    id: key,
                    label: key.toUpperCase(),
                    minWidth: 200,
                    sortable: true,
                    colored: true,
                    style: commonConstants.cellCursor,
                    onClick: props.mobile ? null : (props, object, property)=>{
                      setObjectId(object.id);
                    },
                  });
              }

              }
          }
        });
        setTableColumns(columns);        
        setSearchFields(fields);        
        setJsonSchema(data);
      },
      () => {}
    );    
  }, [jsonSchemaId]);

  useEffect(() => {
    if(jsonSchema){
      fetchData();
    }
  }, [jsonSchema]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var schema = JSON.parse(jsonSchema.json_schema);
        data.map((jsonData)=>{
          var jsonDetail = JSON.parse(jsonData.json_data);
          schema.properties && Object.entries(schema.properties).map(([key, property])=>{
            if(property.listed && jsonDetail){
              jsonData[key] = jsonDetail[key];
              if(property.type==="password"){
                jsonData["stared" + key] = !jsonData[key]?"":(
                  <span
                    className={classes.passwordColorScheme}
                    onClick={(e) => {
                      if (e.target.innerHTML === "************")
                        e.target.innerHTML = String(jsonData[key]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                      else e.target.innerHTML = "************";
                    }}
                  >
                    ************
                  </span>
                );
              }
              if(property.type==="boolean"){
                jsonData[key] = jsonData[key]?<CheckIcon/>:"";
              }
              if(property.copyable){
                jsonData["copy" + key] = !jsonData[key]?"":<CopyIcon
                    fontSize="small"
                    className={classes.iconColorScheme}
                    onClick={(props, row, columnId) => {
                      onCopy(props, jsonData, key);
                      setSnackBarMessage(key + " Copied");
                    }}
                  />;
              }
            }
          });
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {objectId &&
    <Dialog
      fullWidth
      maxWidth="lg"
      open={Boolean(objectId)}
      onClose={()=>{setObjectId(null);}}>
      <div style={{padding:10}}>
      <JsonDataDetails 
        {...props} 
        objectId={objectId} 
        handleClose={()=>{
          setObjectId(null);
          if(props.handleClose){
            props.handleClose();
          }
        }}
        onClose={()=>{
          setObjectId(null);
        }}
      />
      </div>
    </Dialog>
    }
    <SnackbarComponent
      message={snackBarMessage}
      open={snackBarMessage}
      handleSnackBarClose={() => {
        setSnackBarMessage(null);
      }}
      severity={"success"}
    ></SnackbarComponent>
    {!props.mobile && jsonSchema && tableColumns && searchFields &&
    <ObjectList
      {...props}
      moduleName={jsonSchema.name}
      url={url}
      actions={actions}
      objectForm={JsonDataForm}
      tableColumns={tableColumns}
      searchFields={searchFields}
      orderBy={"searchable"}
      data={data}
      fetchData={fetchData}
      pageHeader={jsonSchema.name}
      loadData={loadData}
      portalPermissions={{
        create: props.user.role.custom_create,
        edit: props.user.role.custom_modify,
        delete: props.user.role.custom_delete,
      }}
    />
    }
    {props.mobile && jsonSchema &&
    <ObjectListMobile
      {...props}
      moduleName={jsonSchema.name}
      url={url}
      actions={actions}
      objectForm={JsonDataForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={jsonSchema.name}
      loadData={loadData}
      portalPermissions={{
        create: props.user.role.custom_create,
        edit: props.user.role.custom_modify,
        delete: props.user.role.custom_delete,
      }}
    />
    }
    </>
  );
};

export default JsonDatas;
