import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "../../../../Images/tenant_default_logo.png";
import MobileLogo from "../../../../Images/tenant_default_mobile_logo.png";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Chip from "@material-ui/core/Chip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { clearUser } from "../../../../redux-tools/actions/userActions";
import auth from "../../../../services/authService";
import http from "../../../Common/RestAPIHandler";
import { format } from "date-fns-tz";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import { Tooltip } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import NotificationsIcon from '@material-ui/icons/Notifications';
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const AdminPortalLayout = (props) => {
  const classes = useStyles();
  const [dateTime, setDateTime] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [delay, setDelay] = useState(0);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    http.Post(
      "admin/logout/",
      null,
      () => {
        auth.deleteToken();
        props.clearUser();
        props.history.replace(process.env.PUBLIC_URL + "/admin/login");
      },
      () => {}
    );
  };


  useEffect(() => {
    const interval = setTimeout(() => {
      var today = new Date();
      var dateFormat = format(today, "hh:mm|a|eeee|MMM dd, yyyy").split("|");
      setDateTime({
        time: dateFormat[0],
        ampm: dateFormat[1],
        dayName: dateFormat[2],
        date: dateFormat[3],
      });
      setDelay(62 - today.getSeconds());
    }, delay * 1000);
    return () => clearTimeout(interval);
  }, [dateTime]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.appdiv}>
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbarOne}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="row"
                justify="flex-start"
              >
                <Grid item>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={`${classes.full_image}`}
                    />
                    <img
                      src={MobileLogo}
                      alt="Not Available"
                      className={`${classes.mobile_image}`}
                    /> 
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                direction="row"
                justify="flex-end"
              >
                <Grid item className={classes.headerRightGrid}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item className={classes.TimeHide}>
                      <div className={classes.time}>
                        {dateTime.time}
                        <sup className={classes.superscript}>
                          {dateTime.ampm}
                        </sup>
                      </div>
                    </Grid>
                    <Grid item className={classes.DateHide}>
                      <div className={classes.date}>
                        {dateTime.dayName}
                        <br></br> {dateTime.date}
                      </div>
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleMenu}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              alt="No Logo"
                              src={""}
                            />
                          }
                          label={"Hi System Admin"
                          }
                          clickable
                          onDelete={handleMenu}
                          deleteIcon={
                            <ArrowDropDownIcon
                              color="inherit"
                              className={classes.arrowIcon}
                            />
                          }
                          className={classes.hideChip}
                        />
                        <Avatar
                          className={classes.showProfileImage}
                          alt="No Logo"
                          src={""}
                        />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: 160,
                            borderRadius: 15,
                          },
                        }}
                      >
                        <MenuItem
                          onClick={handleLogOut}
                          className={classes.menuItem}
                        >
                          Log Out
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.navdiv}>
              <div className={classes.navbar}>
                <NavLink
                  className={classes.navLink + " " + ("/admin/dashboard" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/dashboard"}
                >
                  <Tooltip title="Dashboard"><DashboardIcon/></Tooltip>
                  <span className={classes.navLinkText}>Dashboard
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>      
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/resellers" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/resellers"}
                >
                  <Tooltip title="Resellers"><BusinessIcon/></Tooltip>
                  <span className={classes.navLinkText}>Resellers
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>      
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/billing" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/billing"}
                >
                  <Tooltip title="Billing"><CreditCardIcon/></Tooltip>
                  <span className={classes.navLinkText}>Billing
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>      
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/reseller_users" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/reseller_users"}
                >
                  <Tooltip title="Reseller Users"><PeopleAltIcon/></Tooltip>
                  <span className={classes.navLinkText}>Reseller Users
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>      
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/package_templates" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/package_templates"}
                >
                  <Tooltip title="Package Templates"><DescriptionIcon/></Tooltip>
                  <span className={classes.navLinkText}>Templates
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/packages" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/packages"}
                >
                  <Tooltip title="Packages"><DescriptionIcon/></Tooltip>
                  <span className={classes.navLinkText}>Packages
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/notifications" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/notifications"}
                >
                  <Tooltip title="Notifications"><NotificationsIcon/></Tooltip>
                  <span className={classes.navLinkText}>Notifications
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/permission_categories" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/permission_categories"}
                >
                  <Tooltip title="Permission Categories"><LockOpenIcon/></Tooltip>
                  <span className={classes.navLinkText}>Categories
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/permissions" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/permissions"}
                >
                  <Tooltip title="Permissions"><LockOpenIcon/></Tooltip>
                  <span className={classes.navLinkText}>Permissions
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/reseller_permission_categories" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/reseller_permission_categories"}
                >
                  <Tooltip title="Reseller Permission Categories"><LockOpenIcon/></Tooltip>
                  <span className={classes.navLinkText}>Res. Categories
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/reseller_permissions" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/reseller_permissions"}
                >
                  <Tooltip title="Reseller Permissions"><LockOpenIcon/></Tooltip>
                  <span className={classes.navLinkText}>Res. Permissions
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                <NavLink
                  className={classes.navLink + " " + ("/admin/tenants" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/admin/tenants"}
                >
                  <Tooltip title="Tenants"><BusinessIcon/></Tooltip>
                  <span className={classes.navLinkText}>Tenants
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <footer className={classes.footerdiv}>
                  POWERED BY<br/> SYNCMONKEY
                </footer>
              </div>
            </Grid>
            <Grid item className={classes.maindiv}>
              <main>{props.children}</main>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

AdminPortalLayout.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  clearUser,
})(AdminPortalLayout);
