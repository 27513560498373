import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactPhoneIcon from "@material-ui/icons/ContactPhoneOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import StorageIcon from "@material-ui/icons/StorageOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembershipOutlined";
import { useDebounce } from "use-debounce";
import http from "../RestAPIHandler";
import SwipeableViews from "react-swipeable-views";
import ShowDetails from "./ShowDetails";
import ShowSearches from "./ShowSearches";
import { Button } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import PhotoIcon from '@material-ui/icons/Photo';
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    background: theme.dialogs
  },
  searchBoxContent: {
    display: "flex",
    flexDirection: "row",
    margin: "unset",
  },
  sectionDesktop: {
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  sectionMobile: {
    display: "none",
    [theme.breakpoints.down(800)]: {
      display: "block",
    },
  },
  tabContainer: {
    padding: 5,
    height: "100%",
  },
}));

const SearchBox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [text, setText] = useState("");
  const [debouncedText] = useDebounce(text, 500);
  const [data, setData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [previousHoverIndex, setPreviousHoverIndex] = useState(-1);
  const [showDetails, setShowDetails] = useState(false);
  const [item, setItem] = useState({});
  const [loadData, setLoadData] = useState(false);

  var icons = {
    Company: ApartmentOutlinedIcon,
    Site: BusinessIcon,
    Document: DescriptionIcon,
    "Server and Device": StorageIcon,
    Service: ContactPhoneIcon,
    "Software License": CardMembershipIcon,
    Employee: PeopleAltIcon,
    "Customized Data": ListIcon,
    "File": PhotoIcon,
    "Domain": LanguageIcon
  };

  const setEventData = (key, e) => {
    if (data && selectedIndex >= 0) {
      if (key === "up" && hoverIndex > 0) {
        setHoverIndex(hoverIndex - 1);
      } else if (key === "down" && hoverIndex < data.length - 1) {
        setHoverIndex(hoverIndex + 1);
      } else if (key === "enter") {
        const currentIndex =
          previousHoverIndex === -1 ? hoverIndex : previousHoverIndex;
        setItemClick(data[currentIndex]);
        setSelectedIndex(currentIndex);
        setHoverIndex(currentIndex);
        setPreviousHoverIndex(-1);
      }
    }
  };

  const setItemClick = (item) => {
    setShowDetails(true);
    setItem(item);
    setIndex(1);
  };

  const unsetItemClick = () => {
    setShowDetails(false);
    setItem({});
  };

  const handleListItemClick = (item, index) => {
    setItemClick(item);
    setSelectedIndex(index);
    setPreviousHoverIndex(hoverIndex);
    setHoverIndex(index);
  };

  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const onInputChange = (e) => {
    setText(e.target.value);
    setShowDetails(false);
    setItem({});
    setIndex(0);
  };

  useEffect(() => {
    if (debouncedText) {
      var data = { search: debouncedText, company: props.company };
      setLoadData(true);
      http.Post(
        `search/`,
        data,
        (res) => {
          res.forEach((item) => (item.icon = icons[item.model]));
          setData(res);
          setLoadData(false);
        },
        () => {
          setData([]);
          setLoadData(false);
        }
      );
    } else {
      setData([]);
    }
    unsetItemClick();
    setSelectedIndex(-1);
    setHoverIndex(-1);
    setPreviousHoverIndex(-1);
  }, [debouncedText]);

  const mobileSearch = () => {
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={index}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={"Search"} />
            <Tab label={"Details"} />
            <Button onClick={props.onClose}>x</Button>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={index}
          onChangeIndex={handleChangeIndex}
        >
          <Grid className={classes.tabContainer}>
            <ShowSearches
              component_id="mobile-search"
              data={data}
              text={text}
              selectedIndex={selectedIndex}
              hoverIndex={hoverIndex}
              handleListItemClick={handleListItemClick}
              setEventData={setEventData}
              setItemClick={setItemClick}
              onInputChange={onInputChange}
              unsetItemClick={unsetItemClick}
              loadData={loadData}
              company={props.company}
            />
          </Grid>
          <Grid className={classes.tabContainer}>
            {showDetails ? <ShowDetails data={item} /> : <React.Fragment />}
          </Grid>
        </SwipeableViews>
      </React.Fragment>
    );
  };

  const desktopSearch = () => {
    return (
      <React.Fragment>
        <Grid container spacing={1} className={classes.searchBoxContent}>
          <Grid item xs={6}>
            <ShowSearches
              component_id="desktop-search"
              data={data}
              text={text}
              selectedIndex={selectedIndex}
              hoverIndex={hoverIndex}
              handleListItemClick={handleListItemClick}
              setEventData={setEventData}
              setItemClick={setItemClick}
              onInputChange={onInputChange}
              unsetItemClick={unsetItemClick}
              loadData={loadData}
              company={props.company}
            />
          </Grid>
          <Grid item xs={6}>
            {showDetails ? <ShowDetails data={item} /> : <React.Fragment />}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.sectionDesktop}>{desktopSearch()}</div>
      <div className={classes.sectionMobile}>{mobileSearch()}</div>
    </div>
  );
};

export default SearchBox;
