import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import CommonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import { isUrl } from "../../Common/Validation";

const ResourceForm = (props) => {
  const classes = useStyles();
  const [resource, setResource] = useState({ tenant: props.user.tenant });
  const [resourceError, setResourceError] = useState({});
  const {
    requiredError,
    blankError,
    urlError,
    websitePlaceholder,
  } = CommonConstants.error;
  const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (props.isEdit && props.resourceId) {
      http.Get(
        `resources/${props.resourceId}/`,
        (resource) => setResource(resource),
        () => {}
      );
    }
  }, []);

  const onChange = (e) => {
    setResource({
      ...resource,
      [e.target.name]: e.target.value,
    });
    setResourceError({
      ...resourceError,
      [e.target.name]: false,
    });
    setIsWebsiteInvalid(false);
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!isUrl(resource.link)) {
      setIsWebsiteInvalid(true);
      setIsSubmitted(false);
      return;
    }

    if (props.isEdit && props.resourceId) {
      http.Put(
        `resources/${props.resourceId}/`,
        resource,
        (resource) => {
          props.handleResourceSubmit(resource, props.isEdit);
        },
        (status, error) => {
          if (status === 400) setResourceError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `resources/`,
        resource,
        (resource) => {
          props.handleResourceSubmit(resource, props.isEdit);
        },
        (status, error) => {
          if (status === 400) setResourceError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" className={classes.light}>
              {props.isEdit ? "Edit Resource" : "Add New Resource"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.onClose(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          <p className={`${classes.label} ${classes.semiBold}`}>TITLE *</p>
          <TextValidator
            id="title"
            fullWidth
            margin="dense"
            variant="outlined"
            name="title"
            value={resource.title || ""}
            onChange={onChange}
            inputProps={{ maxLength: 100 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={Boolean(resourceError.title)}
            helperText={resourceError.title && resourceError.title[0]}
          />
          <p className={`${classes.label} ${classes.semiBold}`}>LINK</p>
          <TextValidator
            id="link"
            fullWidth
            margin="dense"
            variant="outlined"
            name="link"
            value={resource.link || "https://"}
            onChange={onChange}
            placeholder={websitePlaceholder}
            inputProps={{ maxLength: 255 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={
              Boolean(resourceError.link) || (isWebsiteInvalid && resource.link)
            }
            helperText={
              (resourceError.link && resourceError.link[0]) ||
              (resource.link && isWebsiteInvalid && urlError)
            }
          />
        </div>
        <div className={classes.divider}>
          <Divider />
        </div>
        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.onClose(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(ResourceForm);
