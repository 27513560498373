
import React from "react";
import ObjectForm from "../../Common/ObjectForm";
import commonConstants from "../../Common/CommonConstants";
import {customizedJson} from "../../Common/Languages";

const lang = localStorage.getItem("languages");

const formFields = [
  { 
    id: "name", 
    label: customizedJson.MODELNAME1[lang], 
    type: "text", 
  },
  {
    id: "order",
    label: customizedJson.ORDER[lang],
    type: "text", 
  },
  {
    id: "json_schema",
    label: customizedJson.JSONSCHEMA[lang],
    type: "textarea", 
  },
  {
    id: "icon",
    label: customizedJson.ICON[lang],
    type: "icon",
    onChange: (e, object, setObject, setErrors) => {
      if (e.target.files && e.target.files.length === 1) {
        if (!e.target.files[0].name.match(/\.(png|PNG|jpeg|JPEG|jpg|JPG)$/)) {
          setErrors({"icon": [commonConstants.error.imageFormatError],});
          return false;
        }
        if (e.target.files[0].size > parseInt(1 * 1024 * 1024, 10)) {
          setErrors({"icon": [commonConstants.error.imageSizeError],});
          return false;
        }
        setErrors({"icon": null});
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
          setObject({
            ...object,
            "icon":reader.result
          });
        };
      }
    },
  },
  {
    id: "json_schema",
    label: customizedJson.JSONSCHEMA[lang],
    type: "jsonSchema", 
  },
];

const json_schema = {
  "type" : "object",
  "properties" : {
    "Name" : {"type":"text","required":true,"listed":true,"searchable":true,"copyable":false,},
 }};

const JsonSchemaForm = props => {
  return(
    <ObjectForm 
      {...props} 
      url={`json_schemas`} 
      formName={customizedJson.CustomizedDataModels[lang]} 
      formFields={formFields}
      onSubmit={()=>{window.location.reload();}}
      object={{
        tenant:props.user.tenant, json_schema: JSON.stringify(json_schema,null," ")}}/>
  );
};

export default JsonSchemaForm;
