import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker
} from "react-google-maps";

const CompanyGoogleMap = (props) => {

  const Map = () => {
    return (
      <GoogleMap defaultZoom={16} defaultCenter={props.position}>
        <Marker
          position={props.position}
        />
      </GoogleMap>
    );
  };

  var MapWrapped = withScriptjs(withGoogleMap(Map));
  const API_KEY = "AIzaSyCt5raqgoef0oKG1zVVoRTXLkHLi2GVVJ0";

  return (
    <div style={{ minWidth: 100, height: 300 }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "100%", width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};
export default CompanyGoogleMap;
