import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Dialog } from "@material-ui/core";
import { formatDate } from "../../Common/Utility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ScreenLoader from "../../Common/Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import CcMaster from "../../../Images/cc_master.png";
import CcVisa from "../../../Images/cc_visa.png";
import CcAmex from "../../../Images/cc_amex.png";
import CcUnknown from "../../../Images/cc_unknown.png";
import { hasPermission } from "../../Common/permission";

const useStyles = makeStyles((theme) => ({
	cursor: {
		cursor: "pointer",
		color: theme.colorScheme,
		float:"right",
	},
	paper: {
		padding: 15,
		height: 120,
		minWidth: 50,
	},
	loadingBlock: {
		backgroundColor: "#DDDDDD",
		position: "fixed",
		width: "100%",
		height: "100%",
		zIndex: 1000,
		top: "0px",
		left: "0px",
		opacity: ".8",
		filter: "alpha(opacity=80)",
	},
	absoluteCenter: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	cardImage: {
		width: "100%",
		maxWidth: 130,
		maxHeight: 80,
	},
	mobilePaper: {
		[theme.breakpoints.down(445)]: {
			height: "max-content",
		},
	},
	subscription: {
		overflowX: "auto",
		overflowY: "auto",
		height: "80px",
	},
}));

const BillingDetails = (props) => {
	const classes = useStyles();
	const { id } = props.match.params;
	const [data, setData] = useState();
	const [account, setAccount] = useState();
	const [balance, setBalance] = useState(0);
	const url = `tenant_invoices?tenant=${id}`;
	const [loading, setLoading] = useState();
	const [content, setContent] = useState();
	const [loadData, setLoadData] = useState(false);
	const [packages, setPackages] = useState();

	const tableColumns = [
		{
			id: "id",
			label: "INVOICE ID",
			minWidth: 100,
			colored: true,
			style: commonConstants.cellCursor,
			onClick: (props, object, property)=>{
				const offset = new Date().getTimezoneOffset();			
				http.ExportToPdf(
					`tenant_invoice/${object.id}/pdf`,
					{ offset: offset },
					(blob) => {
						if(blob.size>0){
							var a = window.document.createElement("a");
							a.href = window.URL.createObjectURL(blob);
							a.download = object.company_name + ".pdf";
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}
					},
					() => {
					}
				);
			},
		},
		{
			id: "items",
			label: "ITEMS",
			minWidth: 100,
			sortable: true,
		},
		{
			id: "sub_total",
			label: "SUB TOTAL",
			minWidth: 100,
			sortable: true,
			style: { float: "right" },
		},
		{
			id: "discount",
			label: "DISCOUNT(%)",
			minWidth: 100,
			sortable: true,
			style: { float: "right" },
		},
		{
			id: "total",
			label: "TOTAL",
			minWidth: 100,
			sortable: true,
			style: { float: "right" },
		},
		{
		  id: "period",
		  label: "PERIOD",
		  minWidth: 100,
		  sortable: true,
		},
		{
			id: "due_date",
			label: "DUE DATE",
			minWidth: 80,
			sortable: true,
		},
		{
			id: "status",
			label: "STATUS",
			minWidth: 80,
			sortable: true,
			colored: true,
		},
		{
			id: "payments",
			label: "",
			minWidth: 80,
			colored: true,
			style: commonConstants.cellCursor,
		},
		{
			id: "pay",
			label: "",
			minWidth: 80,
			colored: true,
			style: commonConstants.cellCursor,
			disableTooltip: true,
		},
	];

	useEffect(() => {
		if (data && packages) {
			http.Get(
				`tenant_payment_infos?tenant=${id}`,
				(infos) => {
					if (infos && infos.length === 1) {
						var info = infos[0];
						http.Get(
							`tenant_versions?tenant_id=${id}`,
							(versions) => {
								setAccount(() => {
									return (
										<div>
											{info.company_name}
											<br />
											<Grid
												container
												spacing={3}
												style={{ fontSize: 12 }}
												alignItems="center"
											>
												<Grid item xs={12} sm={12} md={4}>
													<Paper className={classes.paper}>
														<Grid container spacing={1}>
															<Grid item xs={12}>
																CURRENT BALANCE
															</Grid>
															<Grid item xs={12}>
																<Typography variant="h3">
																	{" "}
																	$ {balance}
																</Typography>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
												<Grid item xs={12} sm={12} md={4}>
													<Paper
														className={`${classes.paper} ${classes.mobilePaper}`}
													>
														<Grid container spacing={1}>
															<Grid item xs={12}>
																CARD INFO
															</Grid>
															<Grid item xs={12}>
																<Grid container spacing={3}>
																	<Grid item xs={6}>
																		{info &&
																			info.card_number &&
																			info.card_number.startsWith("3") && (
																				<img
																					className={classes.cardImage}
																					src={CcAmex}
																				/>
																			)}
																		{info &&
																			info.card_number &&
																			info.card_number.startsWith("4") && (
																				<img
																					className={classes.cardImage}
																					src={CcVisa}
																				/>
																			)}
																		{info &&
																			info.card_number &&
																			info.card_number.startsWith("5") && (
																				<img
																					className={classes.cardImage}
																					src={CcMaster}
																				/>
																			)}
																		{info &&
																			info.card_number &&
																			!info.card_number.startsWith("5") &&
																			!info.card_number.startsWith("4") &&
																			!info.card_number.startsWith("3") && (
																				<img
																					className={classes.cardImage}
																					src={CcUnknown}
																				/>
																			)}
																	</Grid>
																	<Grid item xs={6}>
																		{info.card_number &&
																			"***" +
																				info.card_number.substring(
																					info.card_number.length - 4
																				)}
																		<br />
																		<br />
																		Exp: &nbsp;
																		{Math.floor(info.expiration / 100)}/
																		{info.expiration % 100}
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
												<Grid item xs={12} sm={12} md={4}>
													<Paper
														className={`${classes.paper} ${classes.mobilePaper}`}
													>
														<Grid container spacing={1}>
															<Grid item xs={12}>
																SUBSCRIPTION
															</Grid>
															<Grid item xs={12}>
																<Grid
																	container
																	spacing={1}
																	className={classes.subscription}
																>
																	{versions &&
																		versions.map((version) => {
																			var serialized_data = JSON.parse(version.serialized_data);
																			return (
																			  <Grid item xs={12}>
																				{packages[serialized_data[0].fields.package]} &nbsp;&nbsp;&nbsp;&nbsp;
																				+ {serialized_data[0].fields.user_addons || 0} users&nbsp;&nbsp;&nbsp;&nbsp;
																				+ {serialized_data[0].fields.readonly_addons || 0} readonly&nbsp;&nbsp;&nbsp;&nbsp;
																				+ {serialized_data[0].fields.portal_addons || 0} portals&nbsp;&nbsp;&nbsp;&nbsp;
																				<br/>{serialized_data[0].fields.is_monthly ? "Monthly" : "Annual"} @ {formatDate(version.date_created)}																				
																				{/*<DeleteIcon className={classes.cursor}
																					onClick={() => {
																						http.Delete(
																							`tenant_versions/${version.id}?tenant_id=${id}`,
																							(res) => {
																								fetchData();
																							},
																							(status, error) => {
																								fetchData();
																							}
																						);
																					}}
																				/>*/}
																				</Grid>
																			);
																		})}
																</Grid>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
											</Grid>
										</div>
									);
								});
							},
							() => {}
						);
					}
				},
				() => {}
			);
		}
	}, [data, packages]);

	useEffect(() => {
		fetchData();
		http.Get(
			`packages`,
			(data) => {
				var valueMap = {};
				for (let i = 0; i < data.length; i++) {
					valueMap[data[i].id] = data[i].package_name;
				}
				setPackages(valueMap);
			},
			() => {}
		);
	}, []);

	const fetchData = () => {
		setLoadData(true);
		http.Get(
			url,
			(data) => {
				var itemBalance = 0;
				data.forEach((item) => {
					item.due_date = formatDate(item.due_date);
					item.balance = item.total - item.payments;
					if(item.day){
					  item.period = item.year + '-' + (item.month>9?(item.month):('0' + item.month)) + '-' + item.day + ' to ' + item.due_date;
					}
					else{
					  item.period = item.year + '-' + (item.month>9?(item.month):('0' + item.month))
					}
					item.items = (
						<div>
							{item.items.map((i) => {
								return <div>{i.name}: ${i.price} * {i.quantity}</div>;
							})}
						</div>
					);
					if (item.balance === item.total && item.total > 0) {
						item.pay = (
							<div style={{ margin: "10px" }}>
								<CreditCardIcon
									onClick={() => {
										if (!hasPermission(props, ['Process Tenant Invoice Payment'])) return;
										setLoading("Processing......");
										http.Get(
											`process_tenant_invoice?invoice_id=${item.id}`,
											(res) => {
												fetchData();
												setLoading(res.message);
												setTimeout(() => {
													setLoading(null);
												}, 5000);
											},
											(status, error) => {
												setLoading(status + ": " + error.error);
												setTimeout(() => {
													setLoading(null);
												}, 5000);
											}
										);
									}}
								/>
								<span style={{ margin: "10px" }}> </span>
								<DeleteIcon
									onClick={() => {
										if (!hasPermission(props, ['Delete Tenant Invoice'])) return;
										http.Delete(
											`tenant_invoices/${item.id}`,
											(res) => {
												fetchData();
											},
											(status, error) => {
												fetchData();
											}
										);
									}}
								/>
							</div>
						);
					} else {
						item.pay = 
							<div style={{ margin: "10px" }}>
								<DeleteIcon
									onClick={() => {
										http.Delete(
											`tenant_invoices/${item.id}`,
											(res) => {
												fetchData();
											},
											(status, error) => {
												fetchData();
											}
										);
									}}
								/>
							</div>;
					}

					if (item.payments > 0) {
						item.payments = (
							<div style={{ margin: "10px" }}>
								<VisibilityIcon
									onClick={() => {
										const offset = new Date().getTimezoneOffset();			
										http.ExportToPdf(
											`tenant_invoice/${item.id}/receipt`,
											{ offset: offset },
											() => {
											},
											() => {
											}
										);
										http.Get(
											`tenant_payments?tenant_invoice=${item.id}`,
											(payments) => {
												setContent(
												  <div>
												    {payments.map((payment) => {
														if(payment.response_content.startsWith("https://")){
															window.open(payment.response_content);															
															setTimeout(() => {
																setContent(null);
															}, 1000);
															return <a href={payment.response_content} target="_blank">Receipt</a>;
														}
														else{
															return payment.response_content;
														}
												    })}
												  </div>
												);
											},
											() => {}
										);
									}}
								/>
							</div>
						);
					} else {
						item.payments = <div style={{ margin: "10px" }}>&nbsp;</div>;
					}
					itemBalance = itemBalance + item.balance;
					item.sub_total = Number.parseFloat(item.sub_total).toFixed(2);
					item.total = Number.parseFloat(item.total).toFixed(2);
					item.balance = Number.parseFloat(item.balance).toFixed(2);
				});

				setBalance(Number.parseFloat(itemBalance).toFixed(2));
				setData(data);
				setLoadData(false);
			},
			() => {
				setLoadData(false);
			}
		);
	};

	return (
		<div>
			{loading && (
				<div className={classes.loadingBlock}>
					<div className={classes.absoluteCenter}>
						<h1>{loading}</h1>
					</div>
				</div>
			)}

			{content && (
				<Dialog
					open={true}
					onClose={() => {
						setContent(null);
					}}
				>
					<div
						style={{ padding: "20px", minWidth: "400px", minHeight: "300px" }}
					>
						{content}
					</div>
				</Dialog>
			)}

			{loadData && <ScreenLoader />}

			{!props.mobile && account && (
				<ObjectList
					{...props}
					moduleName={account}
					url={url}
					tableColumns={tableColumns}
					data={data}
					fetchData={fetchData}
					orderBy={"due_date"}
					searchFields={["id", "due_date"]}
					pageHeader={""}
					loadData={loadData}
					excludeAddButton={true}
				/>
			)}

			{props.mobile && account && (
				<ObjectListMobile
					{...props}
					moduleName={account}
					url={url}
					tableColumns={tableColumns}
					data={data}
					fetchData={fetchData}
					orderBy={"due_date"}
					searchFields={["id", "due_date"]}
					pageHeader={""}
					loadData={loadData}
					excludeAddButton={true}
				/>
			)}
		</div>
	);
};

export default BillingDetails;
