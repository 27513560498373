
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import {categories} from "../../Common/Languages";

const CategoryForm = props => {
  const [formFields, setFormFields] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [cwConfigurationTypes, setCwConfigurationTypes] = useState();
  const [showCwConfigurationTypes, setShowCwConfigurationTypes] = useState(false);
  const [cwConfigurationTypeQuestions, setCwConfigurationTypeQuestions] = useState();
  const [showCwConfigurationTypeQuestions, setShowCwConfigurationTypeQuestions] = useState(false);
  const [showConfigurationItemTypes, setShowConfigurationItemTypes] = useState(false);
  const [configurationItemTypes, setConfigurationItemTypes] = useState();
  const [categoryType, setCategoryType] = useState();

  const lang = localStorage.getItem("languages");
  
  useEffect(() => {
    if(props.objectId){
      http.Get(
        'categories/'+props.objectId,
        (category)=>{
          setCategoryType(category.category_type);
          if(category.cw_configuration_type_id){    
            http.Get(
              'cw_configuration_type_questions/?cw_configuration_type_id='+category.cw_configuration_type_id,
              (data)=>{
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  valueMap[data[i].id] = data[i].question;
                }
                setCwConfigurationTypeQuestions(valueMap);
              },
              ()=>{}
            );
          }
        },
        ()=>{}
      );
    }
    else if(props.categoryType){
      setCategoryType(props.categoryType);
    }

    http.Get(
      'cw_configuration_types/',
      (data)=>{
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].name;
        }
        setCwConfigurationTypes(valueMap);
      },
      ()=>{}
    );

    http.Get(
      'autotask_configuration_item_types/',
      (data)=>{
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].name;
        }
        setConfigurationItemTypes(valueMap);
      },
      ()=>{}
    );

  },[]);

  useEffect(() => {
    setShowOrder(false);
    setShowCwConfigurationTypes(false);
    setShowCwConfigurationTypeQuestions(false);
    setShowConfigurationItemTypes(false);
    if(categoryType === "Employee Type")
    {
      setShowOrder(true);
    }
    else if(categoryType === "Device Type" || categoryType === "Service Type")
    {
      setShowCwConfigurationTypes(true);
      setShowCwConfigurationTypeQuestions(true);
      setShowConfigurationItemTypes(true);
    }
    else if(categoryType === "License Type")
    {
      setShowCwConfigurationTypes(true);
      setShowCwConfigurationTypeQuestions(false);
      setShowConfigurationItemTypes(true);
    }

  },[categoryType]);

  useEffect(() => {
    var fields = [
      { 
        id: "category_name", 
        label: categories.CATEGORYNAME1[lang], 
        type: "text", 
      },
      {
        id: "category_type",
        label: categories.CATEGORYTYPE1[lang],
        type: "select",
        onChange: (e,object,setObject,setErrors) => {
          setObject({
            ...object,
            [e.target.name]: e.target.value,
          });
          setCategoryType(e.target.value);
        },
        valueMap:{
          "Account Type":categories.AccountType[lang],
          "Credential Type":categories.CredentialType[lang],
          "Device Type":categories.DeviceType[lang],
          "Document Category":categories.DocumentCategory[lang],
          "Employee Type":categories.EmployeeType[lang],
          "Industry Type":categories.IndustryType[lang],
          "License Type":categories.LicenseType[lang],
          "Service Type":categories.ServiceType[lang],
          "Tag Type":categories.TagType[lang],
        },
      },
    ];

    if(showOrder){
      fields.push(
        { 
          id: "order", 
          label: categories.ORDER[lang], 
          type: "text", 
        }
      );
      fields.push(
        { 
          id: "is_default", 
          label: categories.ISDEFAULT[lang], 
          type: "radio", 
        }
      );
    }

    if(showCwConfigurationTypes && cwConfigurationTypes){
      fields.push(
        { 
          id: "cw_configuration_type_id", 
          label: categories.CWCONFIGURATIONTYPE[lang], 
          type: "select", 
          onChange: (e,object,setObject,setErrors) => {
            setObject({
              ...object,
              [e.target.name]: e.target.value,
            }); 
            http.Get(
              'cw_configuration_type_questions/?cw_configuration_type_id='+e.target.value,
              (data)=>{
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  console.log(data[i]);
                  valueMap[data[i].id] = data[i].question;
                }
                setCwConfigurationTypeQuestions(valueMap);
              },
              ()=>{}
            );
          },
          valueMap:cwConfigurationTypes,
        }
      );
      if(showCwConfigurationTypeQuestions && cwConfigurationTypeQuestions){
        fields.push(
          { 
            id: "cw_username_question_id", 
            label: categories.CWUSERNAME[lang], 
            type: "select", 
            valueMap:cwConfigurationTypeQuestions,
          }
        );
  
        fields.push(
          { 
            id: "cw_password_question_id", 
            label: categories.CWPASSWORD[lang], 
            type: "select", 
            valueMap:cwConfigurationTypeQuestions,
          }
        );

      }
    }

    if(showConfigurationItemTypes && configurationItemTypes){
      fields.push(
        { 
          id: "autotask_configuration_item_type_id", 
          label: categories.AUTOTASKCONFIGURATION[lang], 
          type: "select", 
          onChange: (e,object,setObject,setErrors) => {
            setObject({
              ...object,
              [e.target.name]: e.target.value,
            });
          },
          valueMap:configurationItemTypes,
        }
      );
    }

    setFormFields(fields);

  }, [showOrder, cwConfigurationTypes, showCwConfigurationTypes, cwConfigurationTypeQuestions, showConfigurationItemTypes, configurationItemTypes, showCwConfigurationTypeQuestions, cwConfigurationTypeQuestions]);

  return(
    <div>
    {formFields && <ObjectForm {...props} url={`categories`} formName={categories.Category[lang]} formFields={formFields} object={{tenant:props.user.tenant,category_type:props.categoryType,is_default:false}}/>}
    </div>
  );
};

export default CategoryForm;
