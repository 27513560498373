import { makeStyles } from "@material-ui/core/styles";
import { isDarkEnabled } from "../../Common/Utility";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: isDarkEnabled() ? "#333644" : ""
    overflow: "hidden"
  },
  alert: {
    color: theme.textAlert,
  },
  button: {
    color: theme.colorScheme,
    cursor: "pointer",
    height: 20,
    width: 20,
  },
  buttons: {
    color: theme.colorScheme,
    cursor: "pointer",
  },
  checkboxColor: {
    color: theme.colorScheme + "!important",
    paddingRight: 0,
    backgroundColor: "transparent",
  },
  "@media (max-width: 430px)": {
    checkboxColor: {
      paddingRight: 0,
    },
  },
  chip: {
    backgroundColor: theme.colorScheme,
    float: "right",
    margin: "2px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  collapse: {
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
    borderRadius: isDarkEnabled() ? 0 : 5,
  },
  collapsed: {
    backgroundColor: "white",
    borderRadius: 5,
  },
  collapseButton: {
    color: theme.colorScheme,
    cursor: "pointer",
  },
  colorScheme: {
    color: theme.colorScheme,
  },
  
  grid4Container:{
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  grid3Container:{
    height: "auto"
  },

  dashboardContainer: {
    // maxHeight: "auto",
    // height:'40vh',
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  ////////////////4-4//////////////////
  dashboard41Container: {
    height:"55vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard42Container: {
    height:"30vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard43Container: {
    height:"23vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard44Container: {
    height:"18vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  ///////////////////////////////////

  ////////////////3-3//////////////////
  dashboard31Container: {
    height:"62vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard32Container: {
    height:"34vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard33Container: {
    height:"24.5vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  ///////////////////////////////////

  ////////////////2-2//////////////////
  dashboard21Container: {
    height:"110vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  dashboard22Container: {
    height:"66vh",
    overflow: "auto",
    backgroundColor: isDarkEnabled() ? "#333644" : "white",
  },
  ///////////////////////////////////

  dividers: {
    backgroundColor: theme.dividers,
    opacitiy: 0.25,
  },
  containerItem: {
    textAlign: "right",
  },
  contentGrid: {
    height:"100%",
    // maxHeight: "70vh",
    overflow: "auto",
  },
  dashboardDiv: {
    padding: "0px !important",
    marginBottom: 2,
    overflowY: "hidden",
  },
  drawerForm: {
    overflowY: "hidden",
  },
  eagleEye: {
    fontSize: "12px",
    textAlign: "center",
  },
  eagleEyeClose: {
    top: -40,
    right: -40,
    position: "relative",
    float: "right",
    color: theme.buttonClickableBorder,
    cursor: "pointer",
  },
  eagleEyeDialog: {
    overflow: "visible",
    backgroundColor: theme.dialogs,
  },
  eagleEyeInfo: {
    float: "right",
    color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor
		}
  },
  eagleEyeNotification: {
    backgroundColor: theme.notification,
    padding: theme.spacing(2),
    textAlign: "left",
  },
  editIcon: {
    marginRight: 10,
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "auto",
    wordBreak: "break-all",
    [theme.breakpoints.down(480)]: {
      width: "75px",
      padding: 5,
    },
  },
  ellipsis2: {
    cursor: "pointer",
    whiteSpace: "normal",
    wordBreak: "break-all",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  // ellipsis: {
  // 	cursor: "pointer",
  // 	whiteSpace: "normal",
  // 	wordBreak: "break-all",
  // 	"-webkit-box-orient": "vertical",
  // 	"-webkit-line-clamp": 1,
  // 	overflow: "hidden",
  // 	textOverflow: "ellipsis",
  // 	width: "100%",
  // },
  // ellipsis: {
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap",
  //   width: "auto",
  //   wordBreak: "break-all",
  //   [theme.breakpoints.down(480)]: {
  //     width: "75px",
  //     padding: 5,
  //   },
  // },
  ext: {
    width: 65,
    [theme.breakpoints.down(480)]: {
      width: 50,
    },
  },
  expired: {
    color: "red",
  },
  footnote: {
    float: "right",
    color: theme.colorScheme,
  },
  gridItem: {
    // backgroundColor: "#fafafa",
  },
  icons: {
    textAlign: "right",
  },
  eagleEyeList: {
    // backgroundColor: "#EFEFEF",
    // overflow: "visible",
    paddingTop: 0,
  },
  listItem: {
    color: "grey",
  },
  link: {
    textDecoration: "none",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
  },
  notificationIcons: {
    color: theme.icons,
  },
  other: {
    color: theme.other,
  },
  paper: {
    padding: 12,
    backgroundColor: theme.notification,
  },
  paperEven: {
    padding: theme.spacing(2),
    textAlign: "left",
    backgroundColor: "#FDF8E4",
    marginRight: "-24px",
  },
  paperOdd: {
    padding: theme.spacing(2),
    textAlign: "left",
    backgroundColor: "#ECDCDF",
  },
  settingItem: {
    textAlign: "right",
  },
  stickyHeader: {
    backgroundColor: "white",
  },
  subText: {
    paddingLeft: theme.spacing(2),
    color: theme.navBarLink,
  },
  success: {
    color: theme.success,
  },
  table: {
    tableLayout: "fixed",
    overflow: "auto",
    // backgroundColor: theme.palette
  },
  tableCell: {
    backgroundColor: "transparent",
    borderBottom: "none",
    color: theme.subText,
  },
  tableContainer: {
    width: "98%",
  },
  tableHeader: {
    backgroundColor: theme.tableHeaderBG,
    color: theme.text,
  },
  tableGrid: {
    // backgroundColor: "#ffffff",
    overflow: "auto",
    textAlign: "-webkit-center",
  },
  tableRow: {
    backgroundColor: isDarkEnabled() ? theme.notification : "",
  },
  themedButton: {
    cursor: "pointer",
    color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor
		}
  },
  title: {
    color: theme.text,
  },
	popupDialog: {
		background: theme.dialogs,
	},
  warning: {
    color: theme.warning,
  },
}));

export default useStyles;
