import React, { useEffect, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import LocationData from "./LocationData/LocationData";
import AvgSalaryDepartment from "./EmployeeData/AvgSalaryDepartment"
import TotalEmployeesOnVacation, { EmployeeVacationModal } from "./EmployeeData/TotalEmployeesOnVacation"
import { hasPermission } from "../../../Common/permission";
import useStyles from "./Style/style";
import http from "../../../Common/RestAPIHandler";
import EmployeeList from "./EmployeeData/EmployeeList";

const Dashboard = (props) => {
 	const classes = useStyles();
	const [hrEmployees, setHrEmployees] = useState();
	const [salaryData, setSalaryData] = useState();
	const [vacationCount, setVacationCount] = useState(null);
	const [showEmployees, setShowEmployees] = useState(false);

	if (window.location.hash) {
		window.location.href = window.location.href.split("#")[0];
	}

	useEffect(() => {
		http.Get(
			`hr_employees/`,
			(employees) => {
				let vacationCount = 0;
				// Salary data is found in employees data, so make array of salary data objects and then pass it down as prop.
				// Vacation data is also found in employees data, so as we process salary data, we'll also tabulate the number
				// of employees on vacation. We'll pass the value into the TotalEmployeesOnVacationComponent.

				// Make dict with department name as key and dict of number as value
				// Each dict contains number of employees for department and sum of salaries for employees in department.
				let salaryDict = {};
				for (const e of employees) {
					if (e.department) {
						if (e.department in salaryDict) {
							const entry = salaryDict[e.department];
							salaryDict = {...salaryDict, [e.department]: {employees: entry.employees + 1, totalSalary: entry.totalSalary + e.curr_salary}}
						}
						else {
							const newEntry = {employees: 1, totalSalary: e.curr_salary}
							salaryDict = {...salaryDict, [e.department]: newEntry}
						}
					}
					else {
						if ('Unassigned' in salaryDict) {
							salaryDict['Unassigned'].employees++;
							salaryDict['Unassigned'].totalSalary += e.curr_salary;
						}
						else {
							const newEntry = {employees: 1, totalSalary: e.curr_salary};
							salaryDict = {...salaryDict, 'Unassigned': newEntry};
						}
					}
					if (e.curr_vacation) vacationCount++;
				}
				// Create array of objects based on salary dict.
				// Each entry in an object will correspond to table column.
				const currSalaryData = [];
				for (const [department, data] of Object.entries(salaryDict)) {
					currSalaryData.push({department: department, avg: (data.totalSalary / data.employees).toFixed(2)});
				}
				setSalaryData(currSalaryData);
				setVacationCount(vacationCount);
				setHrEmployees(employees);
			},
			() => {}
		);
	}, []);

	return (
		<div className={classes.root}>
			{showEmployees && (
				<Dialog
					fullWidth
					maxWidth="lg"
					open={Boolean(showEmployees)}
					onClose={()=>{setShowEmployees(null);}}>
						<div className={classes.popupDialog} style={{padding:10}}>
							<EmployeeVacationModal
								{...props} 
								hrEmployees={hrEmployees}
								handleClose={()=>{
									setShowEmployees(null);
									if(props.handleClose){
										props.handleClose();
									}
								}}
								onClose={()=>{
									setShowEmployees(null);
								}}
							/>
						</div>
				</Dialog>
			)}
			<div style={{height:20,minHeight:20,maxHeight:20}}></div>
			<Grid container spacing={6}>
				<Grid item xs={12} sm={12} md={12} lg={9}>
					<Grid container spacing={6}>
						{hasPermission(props, ["HR"]) &&
							<Grid item xs={12}>
								<Grid container spacing={3} className={classes.dashboardContainer}>
									<Grid item xs={12}>
										<AvgSalaryDepartment 
											props={props}
											salaryData={salaryData}
										/>
									</Grid>
								</Grid>
							</Grid>
						}
						{hasPermission(props, ["HR"]) && 
							<Grid item xs={12}>
								<Grid container spacing={3} className={classes.dashboardContainer}>
									<Grid item xs={12}>
										<LocationData props={props} />
									</Grid>
								</Grid>
							</Grid>
						}
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={3}>
					<Grid container spacing={6}>
						{hasPermission(props, ["HR"]) &&
							<Grid item xs={12}>
								<Grid container spacing={3} className={classes.dashboardContainer}>
									<Grid item xs={12}>
										<EmployeeList/>
										{/* <TotalEmployeesOnVacation 
											props={props}
											vacationCount={vacationCount}
											hrEmployees={hrEmployees}
											setShowEmployees={setShowEmployees}
										/> */}
									</Grid>
								</Grid>
							</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

// </Grid>
// <Grid item xs={12} sm={12} md={12} lg={6}>

export default Dashboard;
