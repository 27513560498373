import { makeStyles } from "@material-ui/core";
import { isDarkEnabled } from "../Utility";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 0,
  },
  adornedStart: {
    marginRight: "0px !important",
  },
  arrowIcon: {
    height: 35,
  },
  buttonOutlined: {
    borderColor: theme.buttonClickableBorderAlt,
    "&:hover": {
      borderColor: theme.buttonClickableBorder,
    },
    color: theme.buttonClickableText,
  },
  cpb: {
    // Circulare progressbar Style object
    pathColor: "rgb(110, 116, 145)",
    backgroundColor: theme.dialogs,
  },
  cell: {
    color: theme.subText,
    borderColor: theme.subText,
    borderBottom: "None",
  },
  cellColor: {
    color: theme.colorScheme,
    borderBottom: "None",
  },
  cellPadding: {
    paddingTop: 2,
    paddingBottom: 2,
    "word-break": "break-all",
    maxHeight: 21,
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
  },
  chip: {
    backgroundColor: theme.colorScheme,
    marginRight: "2px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  checkboxColor: {
    color: theme.colorScheme + "!important",
  },
  containerItem: {
    textAlign: "right",
    width: "max-content",
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  dividers: {
    backgroundColor: theme.dividers,
    opacity: 0.25,
  },
  disableCell: {
    color: "#888888 !important",
  },
  disableRow: {
    
    backgroundColor: isDarkEnabled() ? "#333644 !important" : "#f6f6f6 !important",
  },
  drawerForm: {
    overflowY: "hidden",
  },
  emptyMessage: {
    textAlign: "center",
    fontSize: "120%",
    color: "gray",
  },
  iconButton: {
    padding: 5,
    color: theme.subText,
    "&:hover": {
      color: theme.subText
    }
  },
  iconBody: {
    cursor: "pointer",
    color: "#909497",
  },
  iconToggle: {
    color: "#909497",
    margin: 5,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: "center",
  },
  iconHead: {
    minWidth: 50,
  },
  iconColorScheme: {
    color: theme.colorScheme,
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  menuItem: {
    padding: 13,
  },
  moduleName: {
    color: theme.text,
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 12,
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
  },
  pagination: {
    overflowX: "hidden",
  },
  paginationButton: {
    color: theme.paginationButton,
    "&:disabled": {
      color: theme.subText,
    },
  },
  pageHeader: {
    display: "none",
  },
  "@media (max-width: 960px)": {
    pageHeader: {
      display: "block",
    },
  },
  paperIcon: {
    fontSize: "small",
    color: theme.colorScheme,
    cursor: "pointer",
  },
  password: {
    cursor: "pointer",
    fontFamily: "Raleway",
  },
  passwordIcon: {
    padding: "5px !important",
  },
  passwordField: {
    padding: "5px !important",
    fontFamily: "Raleway",
  },
  placeHolder: {
    color: isDarkEnabled() ? "white" : "",
  },
  resources: {
    padding: 15,
    height: 115,
    minWidth: 50,
    backgroundColor: `${theme.dialogs} !important`,
  },
  rowHover: {
    backgroundColor: "#333644",
  },
  search: {
    display: "flex",
    width: 200,
    height: 32,
    borderRadius: 16,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderColor: theme.borderColor,
    "&:hover": {
      borderColor: theme.borderColorAlt,
    },
  },
  show: {
    [theme.breakpoints.up(480)]: {
      display: "none",
    },
  },
  sortIcon: {
    color: `${theme.icons} !important`,
  },
  stickyheader: {
    backgroundColor: "#fafafa",
  },
  subText: {
    color: theme.subText,
  },
  subTextWHover: {
	cursor: "pointer",
    color: theme.subText,
    "&:hover": {
      color: theme.text,
    },
  },
  tableCellHeader: {
    // backgroundColor: theme.RowHover,
    color: `${theme.tableHeaderTitle} !important`,
    // borderTop: `1px solid ${theme.colorScheme}`,
    // borderColor: theme.colorScheme
  },
  tables: {
    minWidth: 300,
    backgroundColor: theme.palette,
  },
  tablePadding: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.tableRowHover,
    },
  },
  templateMenuItem: {
    padding: 13,
    whiteSpace: "normal",
  },
  text: {
    color: theme.text,
  },
  visibilityIcon: {
    width: ".90rem",
    height: ".90rem",
    marginRight: 10,
  },
  truncate:{
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
}));

export default useStyles;
