import React, { useEffect, useState } from "react";
import AddressForm from "./AddressForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  }
}));

const Address = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_address`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };
  
  const tableColumns = [
    {
      id: "address",
      label: "ADDRESS",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "city",
      label: "CITY",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "state",
      label: "PROVINCE",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "postal",
      label: "POSTAL",
      minWidth: 50,
      sortable: true,
    },
    {
      id: "country",
      label: "COUNTRY",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "phone",
      label: "PHONE",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "website",
      label: "WEBSITE",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "fax",
      label: "FAX",
      minWidth: 100,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item)=>{
          item["mobile_view"] = item["city"];
          item["mobile_view_button"] = item["phone"] ? (
            <IconButton
              disableTouchRipple
              className={classes.button}
              edge="start"
              href={`tel://${item.primary_contact_number}`}
            >
              <PhoneIcon fontSize="small" />
            </IconButton>
          ):"";
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Addresses"}
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={AddressForm}
      orderBy={"address"}
      searchFields={["address"]}
      pageHeader={(props.user.company_name || "") + " | Addresses"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Addresses"}
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={AddressForm}
      orderBy={"address"}
      searchFields={["address"]}
      pageHeader={(props.user.company_name || "") + " | Addresses"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Address;
