import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import SharingForm from "./SharingForm";
import CheckIcon from '@material-ui/icons/Check';

const tableColumns = [
  {
    id: "company_name",
    label: "COMPANY NAME",
    minWidth: 180,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: (props, object, property) => {
      window.location = process.env.PUBLIC_URL + `/company/${object.company}`;
    },
  },
  {
    id: "to_tenant_name",
    label: "TO TENANT",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "is_readonly",
    label: "READONLY",
    minWidth: 100,
    sortable: true,
  },
];

const ToTenantDetails = (props) => {
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [error, setError] = useState();

  const url = `sharings?tenant=${id}&from_tenant=${props.user.tenant}`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.is_readonly = item.readonly? <CheckIcon/> : ""),
            (item.readonly = false)
          )
        );
        setData(data);
        setLoadData(false);
      },
      (status, error) => {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Companies To Other Tenants"}
          url={"sharings"}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          objectForm={SharingForm}
          fetchData={fetchData}
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={"Companies"}
          loadData={loadData}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Companies To Other Tenants"}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          objectForm={SharingForm}
          fetchData={fetchData}
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={"Companies"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

export default ToTenantDetails;
