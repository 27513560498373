import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import commonConstants from "../../Common/CommonConstants";
import MergeCompany from "./MergeCompany";

const Merge = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [error, setError] = useState();
  const { id } = props.match.params;
	const [activeIds, setActiveIds] = useState([]);

  const url = `merge_companies?merge=${id}`;

  const toggleActiveIds = (props, object, property) => {
    if (activeIds.includes(object.id)) {
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== object.id;
        })
      );
      object["indicator"] = <ExpandMoreIcon style={{margin:10}} />;
    } else {
      var ids = [].concat(activeIds);
      ids.push(object.id);
      setActiveIds(ids);
      object["indicator"] = <ExpandLessIcon style={{margin:10}} />;
    }
  };

  const tableColumns = [
    {
      id: "indicator",
      label: "",
      minWidth: 10,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : toggleActiveIds,
    },
    {
      id: "name",
      label: "COMPANY NAME",
      minWidth: 180,
      sortable: true,
    },
    {
      id: "conflicts",
      label: "CONFLICTS",
      minWidth: 80,
      sortable: true,
    },
    {
      id: "fixed_conflicts",
      label: "FIXED CONFLICTS",
      minWidth: 80,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((m)=>{
          m["subData"]=
            <MergeCompany
              {...props}
              fetchData={fetchData}
              id={m["id"]}
            />;
          m["indicator"] = <ExpandMoreIcon style={{margin:10}}/>;
        });
        setData(data);
        setLoadData(false);
      },
      (status, error) => {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Merge Details"}
          url={"merge_companies"}
          tableColumns={tableColumns}
          data={data}
          activeIds={activeIds}
          fetchData={fetchData}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={"Merge Details"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Merge Details"}
          url={"merge_companies"}
          tableColumns={tableColumns}
          data={data}
          activeIds={activeIds}
          fetchData={fetchData}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={"Merge Details"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

export default Merge;
