import React, { useState,useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../Common/Style/FormStyle";
import commonConstants from "../../Common/CommonConstants";
import { parse } from "../../Common/CSVImportExportUtility";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import MenuItem from "@material-ui/core/MenuItem";
import http from "../../Common/RestAPIHandler";
import auth from "../../../services/authService";
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ImportForm = (props) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [object, setObject] = useState({});
  const { requiredError } = commonConstants.error;
  const { id } = props.match.params;
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [isInvalidCSV, setIsInvalidCSV] = useState(false);
  const [isCsvEmpty, setIsCsvEmpty] = useState(false);
  const [isFormatMismatch, setIsFormatMismatch] = useState(false);
  const Papa = commonConstants.Papa;
  const JSZip = require("jszip");
  const [fileHeader, setFileHeader] = useState();
  const [headerMap, setHeaderMap] = useState({});
  const [currTotData, setCurrTotData] = useState(null);
  const [ifIt, setIfIt] = useState(false);
  const [ifSync, setIfSync] = useState(false);
  const formData = new FormData()
  

  // For universal import
  const [ uploadZip, setUploadZip] = useState(false);
  const [ file, setFile ] = useState("");
  const [progress, setProgress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [msg1, setMsg1] = useState(null);
  const [value, setValue] = React.useState(0);
  const httpHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Authorization": `Bearer ${auth.getToken()}`
  };
  

  const {
    csvEmptyError,
    csvFormatError,
    csvFormatMismatchError,
  } = commonConstants.error;

  const moduleMap = [
    {
      id: 1,
      module: "Servers and Devices",
      header: commonConstants.deviceHeader,
      url: `companies/${id}/import_devices`,
      fetchUrl: `companies/${id}/devices`,
      packageLimit: props.companyTenant.max_company_device,
    },
    {
      id: 2,
      module: "Employees",
      header: commonConstants.employeeHeader,
      url: `company_employees/${id}/import_employees`,
      fetchUrl: `company_employees?company_id=${id}`,
      packageLimit: props.companyTenant.max_company_employee,
    },
    {
      id: 3,
      module: "Software Licenses",
      header: commonConstants.licenseHeader,
      url: `companies/${id}/import_licenses`,
      fetchUrl: `software_licenses/?company=${id}`,
      packageLimit: props.companyTenant.max_company_license,
    },
    {
      id: 4,
      module: "Other Services",
      header: commonConstants.serviceHeader,
      url: `companies/${id}/import_services`,
      fetchUrl: `companies/${id}/services`,
      packageLimit: props.companyTenant.max_company_other_service,
    },
    {
      id: 5,
      module: "Credentials",
      header: commonConstants.credentialHeader,
      url: `company_employees/${id}/import_credentials`,
      fetchUrl: null,
      packageLimit: null,
    },
    {
      id: 6,
      module: "Domains",
      header: commonConstants.domainHeader,
      url: `companies/${id}/import_domains`,
      fetchUrl: `company_domains?company=${id}`,
      packageLimit: props.companyTenant.max_company_domain,
    },
  ];


  const onChangeZip = () => {
    if (!file) {
      setMsg("No file selected");
    }else{
      formData.append("company", id);
      formData.append("zipFile", file);
      
      setMsg("Your IT Glue file is now uploading. You can close this tab and will be notified in the Notifications area once the import is complete.")
      setProgress(prevState => {
        return {...prevState, started:true}
      })
      axios.post(process.env.PUBLIC_URL +'/api/api/companies/universal', formData,{
        onUploadProgress: (progressEvent) => {setProgress(prevState => {
          return { ...prevState, pc: progressEvent.progress*100}
        })},
        headers: httpHeaders
      })
      .then(res => {
        if (res.data["message"]){
          setMsg("Update Successfully");
          setMsg1(res.data["message"]);
        }else{
          setMsg("Update Successfully");
        }
      })
      .catch(err => {
        setMsg1(err.data["message"]);
        setMsg("Upload failed");
      });
    }
    

    
  }

  const handleClose = () => {
    setMsg1(null)
    props.closeOpen(false);
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const onChange = (e) => {
    
    setObject({
      ...object,
      [e.target.name]:
        e.target.name === "file" ? e.target.files[0] : e.target.value,
    });
    setIsSubmitted(false);
    setHeaderMap({});
    if (e.target.name === "file") {
      setIsInvalidCSV(false);
      setIsInvalidFile(false);
      setIsCsvEmpty(false);
      setIsFormatMismatch(false);
    }
    else {
      const mappedObject = moduleMap.filter(
        (item) => item.module === e.target.value
      )[0];
      if (mappedObject.fetchUrl) {
        fetchData(mappedObject.fetchUrl);
      }
      else {
        setCurrTotData(null);
      }
    }
  };

  const fetchData = (url) => {
    http.Get(
      url,
      (data) => {
        setCurrTotData(data.length);
      },
      () => {}
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!object.file) {
      setIsInvalidFile(true);
      return;
    }
    var fileName = object.file.name && object.file.name.split(".");
    if ((fileName[fileName.length - 1].toLowerCase() !== "csv")&&(fileName[fileName.length - 1].toLowerCase() !== "zip")) {
      setIsInvalidCSV(true);
      return;
    }
    
    if (fileName[fileName.length - 1].toLowerCase() !== "zip"){
      
    }

    const mappedObject = moduleMap.filter(
      (item) => item.module === object.module
    )[0];

    parse(
      object.file,
      mappedObject.header,
      headerMap,
      mappedObject.url,
      mappedObject.module,
      mappedObject.packageLimit,
      currTotData,
      () => setIsCsvEmpty(true),
      (mismatchHeader) => {
          setFileHeader(mismatchHeader);
          moduleMap.filter((item) => item.module === object.module)[0].header.map((header)=>{
              headerMap[header] = header;
          });
          setHeaderMap(headerMap);
          setIsFormatMismatch(true);
       },
      (data) => {
        props.handleSnackBar(
          `Success: ${data.success}, Failed: ${data.failed}`,
          "success"
        );
      },
      (data) => {
        props.handleSnackBar(
          `Success: ${data.success}, Failed: ${data.failed}`,
          "warning"
        );
      },
      (error) => {
        props.handleSnackBar(
          `Success: ${error.success}, Failed: ${error.failed}`,
          "error"
        );
      },
      () => props.handleSnackBar(commonConstants.error.packageLimitReached, "error")
    );
  };

  const handleDownloadClick = () => {
    var zip = new JSZip();
    moduleMap.map((item) => {
      var csv = Papa.unparse({
        fields: item.header,
        data: [],
      });
      zip.file(item.module + ".csv", csv);
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "sample files.zip");
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  return (
    <div className={classes.list} style={{width:"350px"}}>
      <Dialog
        open={msg1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{"Feedback for the upload:"}</DialogTitle>
        <div style={{padding:"25px"}}>
          {msg1}
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Got it</Button>
        </DialogActions>
      </Dialog>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" className={classes.light}>
              Import
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="CSV File" {...a11yProps(1)} />
          <Tab label="Zip File" {...a11yProps(0)} />
          
        </Tabs>
        <div className={classes.content}>
            
          <div
            hidden={value !== 0}>
          <div>
            <p>FROM FILE</p>
            <p>You can import through csv file.</p>
            <p onClick={handleDownloadClick} className={classes.download}>
              <GetAppIcon fontSize="small" />{" "}
              <span>Download sample files.</span>
            </p>
          </div>
          <p className={classes.label}>SELECT MODULE</p>
          <TextValidator
            id="module"
            fullWidth
            margin="dense"
            select
            variant="outlined"
            name="module"
            value={object.module}
            onChange={onChange}
            validators={["required"]}
            errorMessages={[requiredError]}
          >
            {moduleMap && moduleMap.length === 0 ? (
              <MenuItem value={""} selected disabled>
                No modules found
              </MenuItem>
            ) : (
              moduleMap.map((item) => (
                <MenuItem key={item.id} value={item.module}>
                  {item.module}
                </MenuItem>
              ))
            )}
          </TextValidator>
          
          <p className={classes.label}>UPLOAD THE FILE</p>
          <TextValidator
            id="file"
            fullWidth
            type="file"
            margin="dense"
            name="file"
            onChange={onChange}
          />
          {isInvalidFile && <p className={classes.error}>{requiredError}</p>}
          {isInvalidCSV && <p className={classes.error}>{csvFormatError}</p>}
          {isCsvEmpty && <p className={classes.error}>{csvEmptyError}</p>}
          {isFormatMismatch && (              
            <div style={{width:"350px"}}>
                <p className={classes.error}>{csvFormatMismatchError}</p>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.outlinedButton}
                  onClick={() => {
                      if(object.module === "Servers and Devices")
                      {
                        setHeaderMap(commonConstants.deviceImportFormat);
                      }
                      if(object.module === "Employees")
                      {
                        setHeaderMap(commonConstants.employeeImportFormat);
                      }
                      if(object.module === "Software Licenses")
                      {
                        setHeaderMap(commonConstants.licenseImportFormat);
                      }
                      if(object.module === "Other Services")
                      {
                        setHeaderMap(commonConstants.serviceImportFormat);
                      }
                      if(object.module === "Credentials")
                      {
                        setHeaderMap(commonConstants.credentialImportFormat);
                      }
                      if(object.module === "Domains")
                      {
                        setHeaderMap(commonConstants.domainImportFormat);
                      }
                      setIfSync(true);
                      setIfIt(false);
                      setIsSubmitted(false);
                  }}
                >
                  SyncMonkey Format
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.outlinedButton}
                  onClick={() => {
                      setIfIt(true)
                      setIfSync(false)
                      if(object.module === "Servers and Devices")
                      {
                        setHeaderMap(commonConstants.deviceImportFormatItGlue);
                      }
                      if(object.module === "Employees")
                      {
                        setHeaderMap(commonConstants.employeeImportFormatItGlue);
                      }
                      if(object.module === "Software Licenses")
                      {
                        setHeaderMap(commonConstants.licenseImportFormatItGlue);
                      }
                      if(object.module === "Other Services")
                      {
                        setHeaderMap(commonConstants.serviceImportFormatItGlue);
                      }
                      if(object.module === "Credentials")
                      {
                        setHeaderMap(commonConstants.credentialImportFormatItGlue);
                      }
                      if(object.module === "Domains")
                      {
                        setHeaderMap(commonConstants.domainImportFormatItGlue);
                      }
                      setIsSubmitted(false);
                  }}
                >
                  IT Glue Format
                </Button>
                <div>
                  {ifIt &&<p>IT Glue Format is selected</p>}
                  {ifSync &&<p>Reformated data by SyncMonkey Format</p>}
                </div>
                {moduleMap.filter((item) => item.module === object.module)[0].header.map((header)=>{
                    return (
                        <Grid container alignItems="center">
                            <Grid xs={5} item><span style={{fontWeight:"bold"}}>{header}</span></Grid>
                            <Grid xs={5} item>
                              <TextValidator
                                id={header}
                                margin="dense"
                                fullWidth
                                select
                                variant="outlined"
                                name={header}
                                value={headerMap[header] || ""}
                                onChange={(e)=>{
                                    setHeaderMap({...headerMap,[e.target.name]:e.target.value});
                                    setIsSubmitted(false);
                                }}
                                validators={["required"]}
                                errorMessages={[requiredError]}
                              >
                                {fileHeader &&
                                  fileHeader.map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))
                                }
                                <MenuItem key={""} value={" "}>
                                  {"Unselected"}
                                </MenuItem>
                              </TextValidator>
                            </Grid>
                            <Grid xs={2} item>
                                &nbsp;
                            </Grid>
                        </Grid>);
                })}
            </div>
          )}
        

        <div className={classes.divider}>
          <Divider />
        </div>
        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
        </div>
        <div 
              style={{padding:22}}
              role="tabpanel"
              hidden={value !== 1}
              >
              <p>Import a complete IT Glue export</p>
              <br/>
              <div style={{textAlign:"right"}}>
              <TextValidator
                id="file"
                fullWidth
                type="file"
                margin="dense"
                name="file"
                onChange={(e) => {setFile(e.target.files[0])}}
              />
              <div className={classes.divider}>
          <Divider />
        </div>
        {!msg && <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.filledButton}
              onClick={onChangeZip}
            >
              Save
            </Button>
            </div>
            </div>}
              {/* <Button onClick={onChangeZip}>Upload</Button> */}
              { progress.started && <progress max='100' value = {progress.pc}></progress>}
              { msg && <div>{msg}</div>}
              </div>
            </div>
        </div>

      </ValidatorForm>
    </div>
  );
  
};

export default ImportForm;
