import React from "react";
import ObjectForm from "../../../Common/ObjectForm";
import {jobLang} from "../../../Common/Languages";

const JobPostingForm = (props) => {
  const lang = localStorage.getItem("languages");

  const formFields = [
    {
      id: "title",
      label: jobLang.TITLE[lang],
      type: "text",
    },
    {
      id: "content",
      label: jobLang.CONTENT[lang],
      type: "ckeditor",
    },
  ];

  return (
    <ObjectForm
      {...props}
      objectId={props.objectId}
      url={`hr_job_postings`}
      formName={jobLang.JobPosting[lang]}
      formFields={formFields}
      object={{ tenant:props.user.tenant,content: props.template.data || "" }}
    />
  );
};

export default JobPostingForm;
