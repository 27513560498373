import React from "react";
import ClientPortalLayout from "../Common/Layout/Layout";
import { connect } from "react-redux";
import auth from "../../../services/authService";
import { Redirect } from "react-router-dom";

const ClientPortalPage = props => {
  const PageComponent = props.component;

  if (auth.isAuthorized(props)) {
    if (props.user && props.user.user_type === "Company Employee") {   
			localStorage.removeItem("tenantTrial");
      return (
        <ClientPortalLayout {...props}>
          <PageComponent {...props} />
        </ClientPortalLayout>
      );
    } else {
      return <Redirect to={process.env.PUBLIC_URL + "/404"} />;
    }
  }
  return <Redirect to="/portal/login" />;
};

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(ClientPortalPage);
