export const onWebsiteClick = (props, object, property) => {
  let str = object[property] && object[property].toString();
  if (str && !(str.startsWith("https://") || str.startsWith("http://")))
    str = "http://" + str;
  if(str) window.open(str);
};

export const onCopy = (props, object, property) => {
  const text = property ? object[property] : object;

  if (!navigator.clipboard) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("ie: Copying text command was " + msg);
    } catch (err) {
      console.error("ie: Unable to copy", err);
    }

    document.body.removeChild(textArea);
    return;
  }
  navigator.clipboard.writeText(text);
};
