import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Logo from "../../../../src/Images/logo_app.png";
import MobileLogo from "../../../../src/Images/mobile_logo.png";
import SearchIcon from "@material-ui/icons/Search";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useStyles from "./Style/style";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { clearUser, setUser } from "../../../redux-tools/actions/userActions";
import auth from "../../../services/authService";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import http from "../RestAPIHandler";
import MoreIcon from "@material-ui/icons/MoreVert";
import { menus } from "./MenuList";
import Collapse from "@material-ui/core/Collapse";
import { format } from "date-fns-tz";
import Dialog from "@material-ui/core/Dialog";
import SearchBox from "../GlobalSearch/SearchBox";
import { Tooltip } from "@material-ui/core";
import { hasPermission } from "../permission";
import UserNotification from "../../Dashboard/Notification/UserNotification";
import ListIcon from "@material-ui/icons/List";

//importing states from each pages to achieve realtime update
import { site_status,setSS } from "../../Company/Sites/SiteForm"; 
import { delete_item,setDi } from "../ObjectList";
import { temp_status,setTS } from "../ObjectForm";
import { device_status,setDS } from "../../Company/Device/DeviceForm";
import { license_status,setLS } from "../../Company/SoftwareLicense/SoftwareLicenseForm";
import { service_status,setS} from "../../Company/Service/ServiceForm";
import { photo_status,setPS} from "../../Company/File/Files";
import { bulk_status, setBS } from "../../Company/Document/Documents";
import {topNav1,ShortCut,footer,userMenu,Month,Week,topNavHelpTool,LayoutLang} from "../Languages";

const lang = localStorage.getItem("languages");

const Layout = (props) => {
  const { container } = props;
	const PageComponent = props.component;
  const classes = useStyles();
  const [drawerWidth, setDrawerWidth] = useState(260);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [companySearchOpen, setCompanySearchOpen] = React.useState(false);
  const [dateTime, setDateTime] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorHistory, setAnchorHistory] = useState(null);
  const historyIcon = React.useRef();
  const open = Boolean(anchorEl);
  const historyOpen = Boolean(anchorHistory);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [selectedMenu, setSelectedmenu] = useState();
  const [openCollapse, setOpenCollapse] = useState({});
  const { id } = props.match.params;
  const [company, setCompany] = useState({});
  const [companyTenant, setCompanyTenant] = useState({});
  const [companyPermission, setCompanyPermission] = useState();
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [historyItems, setHistoryItems] = useState([]);
  const settingsUrl = props.user.is_tenant_admin
    ? "/settings/profile"
    : "/settings/users";
  const [mainLogo, setMainLogo] = useState();
  const [mobileLogo, setMobileLogo] = useState();
  const [tenantIsActive, setTenantIsActive] = useState(true);
  const [numberOfNewNotifications, setNumberOfNewNotifications] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [showSideBar, setShowSideBar] = useState(
    window.innerWidth > 960 &&
      (window.location.pathname.includes("company/") ||
        window.location.pathname.includes("settings") ||
        window.location.pathname.includes("/hr"))
  );
  const [trialNotice, setTrialNotice] = useState(false);
  const [jsonSchemas, setJsonSchemas] = useState();
  const [tenant, setTenant] = useState();
  const [integrations, setIntegrations] = useState();
  const keysPressed = {};
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [appVersion, setAppVersion] = useState();

//////////////////new////////////////////////////
  const [site, setSite] = useState(0);
  const [updateSite,setUpdateSite] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [device,setDevice] = useState(0);
  const [employee,setEmployee] = useState(0);
  const [liscense,setLiscense] = useState(0);
  const [service,setService] = useState(0);
  const [domain,setDomain] = useState(0);
  const [archive,setArchive] = useState(0);
  const [vault,setVault] = useState(0);
  const [gallery,setGallery] = useState(0);
  const [password,setPassword] = useState(0);
  const [count,setCount] = useState();
////////////////////////////////////////////////

  useEffect(() => {
    const handleResize = () => {
      setShowSideBar(
        window.innerWidth > 960 &&
          (window.location.pathname.includes("company/") ||
            window.location.pathname.includes("settings") ||
            window.location.pathname.includes("/hr"))
      );
    };
    window.addEventListener("resize", handleResize);

    window.addEventListener("blur",onBlur,false);
    function onBlur(e){ 
      keysPressed['Control'] = false;
      keysPressed['Alt'] = false;
    }
    
    window.addEventListener('keydown', (e)=>{
      keysPressed[e.key] = true;
      if (keysPressed['Control'] && keysPressed['Alt']) {
        setShowShortcuts(true);
        setSearchOpen(false);
        if(e.key === 'Enter'){
          setSearchOpen(true);
        }
        else if(e.key === ' '){
          fetchCompanyHistory(e);
        }
        else if(e.key === 'c' || e.key === 'C' ){
          props.history.push(process.env.PUBLIC_URL + `/company`);
        }
        else if(e.key === 'r' || e.key === 'R' ){
          props.history.push(process.env.PUBLIC_URL + `/documents`);
        }
        else if(e.key === 's' || e.key === 'S' ){
          props.history.push(process.env.PUBLIC_URL + `/services`);
        }
        else if(e.key === 'v' || e.key === 'V' ){
          props.history.push(process.env.PUBLIC_URL + `/support`);
        }
        else if(e.key === 'm' || e.key === 'M' ){
          props.history.push(process.env.PUBLIC_URL + `/meeting`);
        }
        else if(e.key === 'a' || e.key === 'A' ){
          props.history.push(process.env.PUBLIC_URL + `/accounting`);
        }
        else if(e.key === 'h' || e.key === 'H' ){
          props.history.push(process.env.PUBLIC_URL + `/hr`);
        }
        else if(id){
          if(e.key === '1' || e.key === '!'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}`);
          }
          else if(e.key === '2' || e.key === '@'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/sites`);
          }
          else if(e.key === '3' || e.key === '#'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/documents`);
          }
          else if(e.key === '4' || e.key === '$'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/employees`);
          }
          else if(e.key === '5' || e.key === '%'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/servers_devices`);
          }
          else if(e.key === '6' || e.key === '^'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/software_licenses`);
          }
          else if(e.key === '7' || e.key === '&'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/other_services`);
          }
          else if(e.key === '8' || e.key === '*'){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/domains`);
          }
          else if(e.key === '9' || e.key === '('){
            props.history.push(process.env.PUBLIC_URL + `/company/${id}/archives`);
          }
        }
      } 
    });

    window.addEventListener('keyup', (event) => {
      setShowShortcuts(false);
      delete keysPressed[event.key];
    });

    setLoadData(true);
    http.Get(
      `tenants/${props.user.tenant}`,
      (tenant) => {
        setTenant(tenant);
        setMainLogo(tenant.logo);
        setMobileLogo(tenant.mobile_logo);
        if (tenant.status === "Active") {
          setTenantIsActive(true);
          setTrialNotice(false);
        } else if (tenant.status === "Lockout") {
          setTenantIsActive(false);
        } else {
          setTenantIsActive(true);
          setTrialNotice(tenant.status);
        }
        setLoadData(false);
      },
      (status, error) => {
        setLoadData(false);
      }
    );

    http.Get(
      `tenant_address`,
      (tenant_address) => {
        if(tenant_address.length<1){
          alert("Please update company address!");
          props.history.replace(process.env.PUBLIC_URL + "/settings/address");
        }
      },
      (status, error) => {
      }
    );

    http.Get(
      "integration/",
      (data) => {
        if(data["autotask"] || data["connect_wise"] || data["ninja"] || data["syncro"]){
          data["has_integration"]=true;
        }
        setIntegrations(data);
      },
      () => {
        setIntegrations({});
      }
    );

    http.Get(
      "new_notifications/count/",
      (data) => {
        setNumberOfNewNotifications(data);
      },
      () => {}
    );

    var today = new Date();
    var dateFormat = format(today, "hh:mm|a|eeee|MMM dd, yyyy").split("|");

    setDateTime({
      time: dateFormat[0],
      ampm: dateFormat[1],
      dayName: Week(dateFormat[2],lang),
      date: Month(dateFormat[3],lang),
    });

    http.Get(
      "app_version",
      (data) => {
        setAppVersion(data.version);
      },
      () => {}
    );

  }, []);

  useEffect(()=>{
    // window.addEventListener('click', function(){
    //   setTimeout(function(){
      http.Get(
        `companies/${id}/sites`,
        (data) => {
          setSite(Object.keys(data).length);
        },
      );
      
      http.Get(
        `company_documents?company=${id}`,
        (data) => {
          setTotalDocs(Object.keys(data).length);
        }
      );
  
      http.Get(
        `company_employees?company_id=${id}`,
        (data) => {
          setEmployee(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/devices`,
        (data) => {
          setDevice(Object.keys(data).length);
        },
      );
  
      http.Get(
        `software_licenses/?company=${id}`,
        (data) => {
          setLiscense(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/services`,
        (data) => {
          setService(Object.keys(data).length);
        },
      );
  
      http.Get(
        `company_domains?company=${id}`,
        (data) => {
          setDomain(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/archives`,
        (data) => {
          setArchive(Object.keys(data).length);
        },
      );
  
      http.Get(
        `configurations?company=${id}`,
        (data) => {
          setVault(Object.keys(data).length);
        },
      );
      
      
      http.Get(
        `company_files/?company=${id}`,
        (data) => {
          setGallery(Object.keys(data).length);
        },
      );
      
      http.Get(
        `companies/${id}/passwords`,
        (data) => {
          setPassword(Object.keys(data).length);
        },
      //);},100)
        
      //})
  //}
  )},[]);
  useEffect(() =>{
        if (site!=99){
    setCount({2:site,
      3:totalDocs,
      4:employee,
      5:device,
      6:liscense,
      7:service,
      8:domain,
      9:archive,
      10:vault,
      11:gallery,
      12:password})}

  },[site,totalDocs,employee,device,liscense,service,domain,archive,vault,gallery,password])

  // useEffect(()=>{
  //   if(site_status){
  //     http.Get(
  //       `companies/${id}/sites`,
  //       (data) => {
  //         setSite(Object.keys(data).length);
  //       },
  //     );
  //     // http.Get(
  //     //   `companies/${id}/${site_status}`,
  //     //   (data) => {
  //     //     setSite(Object.keys(data).length);
  //     //   },
  //     // );
  //     setCount({2:site,
  //       3:totalDocs,
  //       4:employee,
  //       5:device,
  //       6:liscense,
  //       7:service,
  //       8:domain,
  //       9:archive,
  //       10:vault,
  //       11:gallery,
  //       12:password})
  //     setSS(0)
  //   }
    
  // },[site_status])

  useEffect(() => {
    setShowSideBar(
      window.innerWidth > 960 &&
        (window.location.pathname.includes("company/") ||
          window.location.pathname.includes("settings") ||
          window.location.pathname.includes("/hr"))
    );
    http.Get(
      `tenant_user_profile/`,
      (user) => {
        props.setUser(user);
      },
      () => {}
    );

    if (window.location.pathname.includes("company/"))
      setSelectedmenu("companies");
    else if (window.location.pathname.includes("settings") &&
      !window.location.pathname.includes("/hr"))
      setSelectedmenu("settings");
    else if (window.location.pathname.includes("/hr"))
      setSelectedmenu("hr");
    else setSelectedmenu("blank");

    if (
      !tenantIsActive &&
      !window.location.pathname.includes("settings/invoices") &&
      !window.location.pathname.includes("settings/subscription")
    ) {
      alert("Account disabled! Please check the billing settings!");
      props.history.replace(process.env.PUBLIC_URL + "/settings/lockout");
    }
    if (
      props.user.password_need_change &&
      !window.location.pathname.includes("/myprofile")
    ) {
      alert("Please change your password!");
      props.history.replace(process.env.PUBLIC_URL + "/myprofile");
    }

    if(window.location.pathname.includes("settings/")){
      document.title = `SyncMonkey - ` + LayoutLang.Settings[lang];
    }
    else if(window.location.pathname.includes("configurations")){
      document.title = `SyncMonkey - ` + LayoutLang.Vault[lang];
    }
    else if(window.location.pathname.includes("myprofile")){
      document.title = `SyncMonkey - ` + LayoutLang.Profile[lang];
    }
    else if(window.location.pathname.includes("company")){
      document.title = `SyncMonkey - ` + LayoutLang.Companies[lang];
    }
    else if(window.location.pathname.includes("documents")){
      document.title = `SyncMonkey - ` + LayoutLang.Resources[lang];
    }
    else if(window.location.pathname.includes("dashboard")){
      document.title = `SyncMonkey - ` + LayoutLang.Welcome[lang];
    }
    else if(window.location.pathname.includes("accounting")){
      document.title = `SyncMonkey - ` + LayoutLang.Accounting[lang];
    }
    else if(window.location.pathname.includes("hr")){
      document.title = `SyncMonkey - ` + LayoutLang.HR[lang];
    }
    else if(window.location.pathname.includes("meeting")){
      document.title = `SyncMonkey - ` + LayoutLang.Meetings[lang];
    }
    else if(window.location.pathname.includes("support")){
      document.title = `SyncMonkey - ` + LayoutLang.Vendors[lang];
    }
    else if(window.location.pathname.includes("services")){
      document.title = `SyncMonkey - ` + LayoutLang.Services[lang];
    }

  }, [window.location.pathname]);

  useEffect(() => {
    if (id && window.location.pathname.includes("company/")) {
      setCompany({});
      http.Get(
        `companies/${id}`,
        (company) => {
          setCompany(company);
          // For setting site when switching companies
          window.localStorage.setItem("Site", "All");
          document.title = `SyncMonkey - ${company.company_name}`;
        },
        () => {
          props.history.push("/404");
        }
      );
    }
  }, [id]);

  useEffect(() => {
    if (company.tenant_id) {      
      http.Get(
        `tenants/${company.tenant_id}`,
        (companyTenant) => {
          setCompanyTenant(companyTenant);
        },
        () => {
        }
      );

      http.Get(
        `json_schemas?tenant=${company.tenant_id}`,
        (data) => {
          setJsonSchemas(data);
        },
        () => {}
      );
    }
  }, [company]);

  useEffect(() => {
    if (company.tenant_id) {      
      http.Get(
        `tenants/${company.tenant_id}`,
        (companyTenant) => {
          setCompanyTenant(companyTenant);
        },
        () => {
        }
      );

      http.Get(
        `json_schemas?tenant=${company.tenant_id}`,
        (data) => {
          setJsonSchemas(data);
        },
        () => {}
      );
    }
  }, [company]);
  
  useEffect(() => {
    if (companyTenant.id && tenant.id) {
      if(companyTenant.id !== tenant.id){
        http.Get(
          `sharings?company=${company.id}&tenant=${tenant.id}`,
          (sharings) => {
            if(sharings.length>0)
            {
              setCompanyPermission(sharings[0].readonly?"readonly":"readAndWrite");
            }
          },
          () => {
          }
        );
      }
      else{
        setCompanyPermission("readAndWrite");
      }
    }
  }, [companyTenant, tenant]);

  useEffect(() => {
    setHistoryLoaded(false);
    if (historyOpen)
      http.Get(
        "companies/last_viewed?number=5",
        (data) => {
          setHistoryItems(data);
          setHistoryLoaded(true);
        },
        () => {
          console.log("Error: failed to fetch history");
        }
      );
  }, [anchorHistory]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchCompanyHistory = (event) => {
    setAnchorHistory(historyIcon.current);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (path) => {
    props.history.push(path);
    handleMobileMenuClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorHistory(null);
  };

  const fetchCompanyHistoryItemClick = (path) => {
    window.location = process.env.PUBLIC_URL + "/company/" + path;
    handleClose();
  };

  const handleMyProfile = () => {
    props.history.push(process.env.PUBLIC_URL + "/myprofile");
    setAnchorEl(null);
  };

  const handleMyVault = () => {
    props.history.push(process.env.PUBLIC_URL + "/configurations");
    setAnchorEl(null);
  };

  const handleCompanySettings = () => {
    props.history.push(process.env.PUBLIC_URL + settingsUrl);
    setAnchorEl(null);
  };

  const handleLogOut = () => {
		localStorage.clear();			
    http.Post(
      "logout",
      null,
      () => {
        auth.deleteToken();
        props.clearUser();
        props.history.replace("/login");
      },
      () => {}
    );
  };
  
    const handleOpenCollapse = (name) => {
      setOpenCollapse({ [name]: !openCollapse[name] });
    };
  
    const handleLogoClick = () => {
      props.history.push("/dashboard");
    };

  useEffect(() => {
    const intervalTime = setTimeout(() => {
      http.Get(
        "new_notifications/count/",
        (data) => {
          setNumberOfNewNotifications(data);
        },
        () => {}
      );

      var today = new Date();
      var dateFormat = format(today, "hh:mm|a|eeee|MMM dd, yyyy").split("|");
      setDateTime({
        time: dateFormat[0],
        ampm: dateFormat[1],
        dayName: Week(dateFormat[2],lang),
        date: Month(dateFormat[3],lang),
      });
      // Call MSAL here...
    }, 60 * 1000);
    return () => clearTimeout(intervalTime);
  }, [dateTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      http.Get(
        "app_version",
        (data) => {
          if(appVersion && data && appVersion !== data.version){
            console.log("Server Updated!");
            window.location.reload(true);
          }
          else{
            setAppVersion(data.version);
          }
        },
        () => {}
      );
      
    }, 6 * 1000);
    return () => clearInterval(intervalId);
  }, [appVersion]);

//Detect what has been changed and 
  useEffect(() => {
    const intervalId = setInterval(() => {
    if(site_status){
      http.Get(
        `companies/${id}/sites`,
        (data) => {
          setSite(Object.keys(data).length);
        },
      );
      setSS(0)
    }
      
    }, 1000);
    return () => clearInterval(intervalId);
  }, [site_status]);

  useEffect(() => {
    const intervalId = setInterval(() => {
    if(delete_item||temp_status||device_status||license_status||service_status||photo_status||bulk_status){
      http.Get(
        `companies/${id}/sites`,
        (data) => {
          setSite(Object.keys(data).length);
        },
      );
      
      http.Get(
        `company_documents?company=${id}`,
        (data) => {
          setTotalDocs(Object.keys(data).length);
        }
      );
  
      http.Get(
        `company_employees?company_id=${id}`,
        (data) => {
          setEmployee(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/devices`,
        (data) => {
          setDevice(Object.keys(data).length);
        },
      );
  
      http.Get(
        `software_licenses/?company=${id}`,
        (data) => {
          setLiscense(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/services`,
        (data) => {
          setService(Object.keys(data).length);
        },
      );
  
      http.Get(
        `company_domains?company=${id}`,
        (data) => {
          setDomain(Object.keys(data).length);
        },
      );
  
      http.Get(
        `companies/${id}/archives`,
        (data) => {
          setArchive(Object.keys(data).length);
        },
      );
  
      http.Get(
        `configurations?company=${id}`,
        (data) => {
          setVault(Object.keys(data).length);
        },
      );
      
      
      http.Get(
        `company_files/?company=${id}`,
        (data) => {
          setGallery(Object.keys(data).length);
        },
      );
      
      http.Get(
        `companies/${id}/passwords`,
        (data) => {
          setPassword(Object.keys(data).length);
        },

  )
      setDi(0)
      setTS(0)
      setDS(0)
      setLS(0)
      setS(0)
      setPS(0)
      setBS(0)
    }
      
    }, 1000);
    return () => clearInterval(intervalId);
  }, [delete_item,temp_status]);

  

  const getHistory = historyItems.map((item) => {
    return (
      <div>
        <Tooltip title={item.company_name}>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              fetchCompanyHistoryItemClick(item.id);
            }}
          >
            {item.company_name}
          </MenuItem>
        </Tooltip>
        <Divider variant="middle" className={classes.divider} />
      </div>
    );
  });

  const drawer = (
    <div className={classes.mainDrawer}>
      <div className={classes.toolbarOne}></div>
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.toolbarTwo}>
        <Typography
          variant="h6"
          noWrap
          className={`${classes.companyName} ${classes.drawerCompanyName}`}
        >
          {props.user.company_name}
        </Typography>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.drawerContent}>
        <div>
          {trialNotice ? (
            <>
              <div style={{ height:35, minHeight: 35, maxHeight:35 }} />
            </>
          ) : (
            <></>
          )}



          {integrations && menus[selectedMenu] && selectedMenu != "companies" &&
            menus[selectedMenu].map((menu) => {
              return menu.items ? (
                menu.permissions &&
                !hasPermission(props, menu.permissions) ? null : (
                  <div key={menu.name}>
                    <ListItem
                      button
                      key={menu.name}
                      onClick={() => handleOpenCollapse(menu.name)}
                      classes={{
                        root: classes.sideMenu,
                        gutters: classes.listItem,
                      }}
                      className={
                        menu.items.some(
                          (item) =>
                            item.link === window.location.pathname ||
                            window.location.pathname.includes(item.link)
                        )
                          ? classes.active
                          : classes.link
                      }
                    >
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <Tooltip title={menu.name}>
                          <menu.icon />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={menu.name}
                        classes={{ primary: classes.listItemText }}
                      />
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <KeyboardArrowRightIcon
                          className={classes.iconColorTheme}
                        />
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      key={menu.id + menu.name}
                      in={openCollapse[menu.name]}
                      timeout="auto"
                    >
                      <List disablePadding>
                        {menu.items.map((item) => {
                          if((!item.permissions || hasPermission(props, item.permissions)) && (!item.integration))
                            return (
                              <ListItem
                                button
                                key={item.name + menu.name}
                                component={NavLink}
                                to={item.link.replace("/id", `/${id}`)}
                                activeClassName={classes.active}
                                className={classes.link}
                                classes={{ gutters: classes.listItem }}
                              >
                                <div className={classes.listItemIcon}></div>
                                <ListItemText
                                  primary={item.name}
                                  classes={{ primary: classes.listItemText }}
                                />
                              </ListItem>
                            );
                          if(integrations && item.integration && Object.keys(integrations).includes(item.integration))
                            return (
                              <ListItem
                                button
                                key={item.name + menu.name}
                                component={NavLink}
                                to={item.link.replace("/id", `/${id}`)}
                                activeClassName={classes.active}
                                className={classes.link}
                                classes={{ gutters: classes.listItem }}
                              >
                                <div className={classes.listItemIcon}></div>
                                <ListItemText
                                  primary={item.name}
                                  classes={{ primary: classes.listItemText }}
                                />
                              </ListItem>
                            );
                          })}
                        <Divider variant="middle" className={classes.divider} />
                      </List>
                    </Collapse>
                    <Divider variant="middle" className={classes.divider} />
                  </div>
                )
              ) : menu.permissions &&
                !hasPermission(props, menu.permissions) ? null : (
                <div key={menu.id}>
                  <List disablePadding>
                    <ListItem
                      button
                      component={NavLink}
                      to={menu.link.replace("/id", `/${id}`)}
                      activeClassName={classes.active}
                      className={classes.link}
                      classes={{ gutters: classes.listItem }}
                    >
                      {menu.icon && (
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <Tooltip title={menu.name}>
                            <menu.icon />
                          </Tooltip>
                        </ListItemIcon>
                      )}
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary={
                          selectedMenu === "companies" && menu.id === 1
                            ? company.company_name 
                            : menu.name
                        }
                      />
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <KeyboardArrowRightIcon
                          className={classes.iconColorTheme}
                        />
                      </ListItemIcon>
                    </ListItem>
                  </List>
                  <Divider />
                </div>
              );
            })}
          

          {integrations && menus[selectedMenu] && selectedMenu === "companies" &&
            menus[selectedMenu].map((menu) => {
              return menu.items ? (
                menu.permissions &&
                !hasPermission(props, menu.permissions) ? null : (
                  <div key={menu.name}>
                    <ListItem
                      button
                      key={menu.name}
                      onClick={() => handleOpenCollapse(menu.name)}
                      classes={{
                        root: classes.sideMenu,
                        gutters: classes.listItem,
                      }}
                      className={
                        menu.items.some(
                          (item) =>
                            item.link === window.location.pathname ||
                            window.location.pathname.includes(item.link)
                        )
                          ? classes.active
                          : classes.link
                      }
                    >
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <Tooltip title={menu.name}>
                          <menu.icon />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={menu.name}
                        classes={{ primary: classes.listItemText }}
                      />
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <KeyboardArrowRightIcon
                          className={classes.iconColorTheme}
                        />
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      key={menu.id + menu.name}
                      in={openCollapse[menu.name]}
                      timeout="auto"
                    >
                      <List disablePadding>
                        {menu.items.map((item) => {
                          if((!item.permissions || hasPermission(props, item.permissions)) && (!item.integration))
                            return (
                              <ListItem
                                button
                                key={item.name + menu.name}
                                component={NavLink}
                                to={item.link.replace("/id", `/${id}`)}
                                activeClassName={classes.active}
                                className={classes.link}
                                classes={{ gutters: classes.listItem }}
                              >
                                <div className={classes.listItemIcon}></div>
                                <ListItemText
                                  primary={item.name}
                                  classes={{ primary: classes.listItemText }}
                                />
                              </ListItem>
                            );
                          if(integrations && item.integration && Object.keys(integrations).includes(item.integration))
                            return (
                              <ListItem
                                button
                                key={item.name + menu.name}
                                component={NavLink}
                                to={item.link.replace("/id", `/${id}`)}
                                activeClassName={classes.active}
                                className={classes.link}
                                classes={{ gutters: classes.listItem }}
                              >
                                <div className={classes.listItemIcon}></div>
                                <ListItemText
                                  primary={item.name}
                                  classes={{ primary: classes.listItemText }}
                                />
                              </ListItem>
                            );
                          })}
                        <Divider variant="middle" className={classes.divider} />
                      </List>
                    </Collapse>
                    <Divider variant="middle" className={classes.divider} />
                  </div>
                )
              ) : menu.permissions &&
                !hasPermission(props, menu.permissions) ? null : (
                <div key={menu.id}>
                  <List disablePadding>
                    <ListItem
                      button
                      component={NavLink}
                      to={menu.link.replace("/id", `/${id}`)}
                      activeClassName={classes.active}
                      className={classes.link}
                      classes={{ gutters: classes.listItem }}
                    >
                      {menu.icon && (
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <Tooltip title={menu.name}>
                            <menu.icon />
                          </Tooltip>
                        </ListItemIcon>
                      )}
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary={
                          menu.id === 1
                            ? company.company_name 
                            : menu.name
                        }
                      />
                      {menu.id !== 1 && <div style={{borderRadius: "50%",
                                    width: "28px",
                                    height: "28px",
                                    padding: "4px",

                                    background: "grey",
                                    border: "0px",
                                    color: "white",
                                    textAlign: "center",

                                    font: "22px",}}>{count[menu.id]}</div>}
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <KeyboardArrowRightIcon
                          className={classes.iconColorTheme}
                        />
                      </ListItemIcon>
                    </ListItem>
                  </List>
                  <Divider />
                </div>
              );
            })}



          {selectedMenu === "companies" &&
            jsonSchemas &&
            jsonSchemas.map((jsonSchema) => {
              return (
                <>
                  <ListItem
                    button
                    component={NavLink}
                    to={
                      process.env.PUBLIC_URL +
                      "/company/" +
                      id +
                      "/json_datas/" +
                      jsonSchema.id +
                      "/"
                    }
                    activeClassName={classes.active}
                    className={classes.link}
                    classes={{ gutters: classes.listItem }}
                  >
                    <div className={classes.listItemIcon}>
                      {jsonSchema["icon"] ? (
                        <img
                          style={{ maxWidth: "20px", maxHeigth: "20px" }}
                          alt={jsonSchema.name}
                          src={jsonSchema["icon"]}
                        />
                      ) : (
                        <ListIcon />
                      )}
                    </div>
                    <ListItemText
                      primary={jsonSchema.name}
                      classes={{ primary: classes.listItemText }}
                    />
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <KeyboardArrowRightIcon
                        className={classes.iconColorTheme}
                      />
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id="mobile-menu"
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        style: {
          width: 160,
          borderRadius: 15,
        },
      }}
    >
      {hasPermission(props, ["View Data", "Create Company"]) && (
        <MenuItem
          className={classes.menuItem}
          onClick={() =>
            handleMenuItemClick(process.env.PUBLIC_URL + "/company")
          }
        >
          {topNav1.COMPANIES[lang]}
        </MenuItem>
      )}
      {hasPermission(props, ["View Data"]) && (
        <div>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() =>
              handleMenuItemClick(process.env.PUBLIC_URL + "/documents")
            }
          >
            RESOURCES
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() =>
              handleMenuItemClick(process.env.PUBLIC_URL + "/services")
            }
          >
            SERVICES
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() =>
              handleMenuItemClick(process.env.PUBLIC_URL + "/support")
            }
          >
            VENDORS
          </MenuItem>
          {tenant && tenant.is_meeting_module_available && (
            <>
              <Divider />
              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleMenuItemClick(process.env.PUBLIC_URL + "/meeting")
                }
              >
                MEETINGS
              </MenuItem>
            </>
          )}
        </div>
      )}
      {tenant &&
        tenant.is_moneris_module_available &&
        hasPermission(props, ["Accounting"]) && (
          <div>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                handleMenuItemClick(process.env.PUBLIC_URL + "/accounting")
              }
            >
              ACCOUNTING
            </MenuItem>
          </div>
        )}
      {tenant && tenant.is_hr_module_available && hasPermission(props, ["HR"]) && (
        <div>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick(process.env.PUBLIC_URL + "/hr")}
          >
            HR
          </MenuItem>
        </div>
      )}
    </Menu>
  );

  // Parent Component that renders Layout.js is Page.js
  return (
    <div className={classes.root}>
      <CssBaseline />
      {showShortcuts && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={showShortcuts}
          onClose={() => {
            setShowShortcuts(false);
          }}
        >
          <div style={{ padding: 10 }}>
            <h4>{ShortCut.title[lang]}</h4>
            <Grid container spacing={1}>
              <Grid item xs={2}>Enter</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.GlobalSearch[lang]}</Grid>

              <Grid item xs={2}>Space</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.RecentlyViewedCompanies[lang]}</Grid>

              <Grid item xs={2}>c/C</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.Companies[lang]}</Grid>

              <Grid item xs={2}>r/R</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.TenantResources[lang]}</Grid>

              <Grid item xs={2}>s/S</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.TenantServices[lang]}</Grid>

              <Grid item xs={2}>v/V</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.TenantVendors[lang]}</Grid>

              <Grid item xs={2}>m/M</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.TenantMeetings[lang]}</Grid>

              <Grid item xs={2}>a/A</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.TenantAccounting[lang]}</Grid>

              <Grid item xs={2}>h/H</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.RecentlyViewedCompanies[lang]}</Grid>
              {id && <>
              <Grid item xs={2}>1</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyDetails[lang]}</Grid>
              
              <Grid item xs={2}>2</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanySites[lang]}</Grid>

              <Grid item xs={2}>3</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyDocuments[lang]}</Grid>

              <Grid item xs={2}>4</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyEmployees[lang]}</Grid>

              <Grid item xs={2}>5</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyServersDevices[lang]}</Grid>

              <Grid item xs={2}>6</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanySoftwareLicenses[lang]}</Grid>

              <Grid item xs={2}>7</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyOtherServices[lang]}</Grid>

              <Grid item xs={2}>8</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyDomains[lang]}</Grid>

              <Grid item xs={2}>9</Grid>
              <Grid item xs={2}>{"-->"}</Grid>
              <Grid item xs={8}>{ShortCut.CompanyArchives[lang]}</Grid>
              </>}
            </Grid>
          </div>
        </Dialog>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.appdiv}>
          <AppBar className={classes.appBar}>
            {trialNotice != "" ? (
              <>
                <Toolbar variant="dense" className={classes.toolbarTrial}>
                  <span className={classes.trialNotice}>{trialNotice}</span>
                </Toolbar>
              </>
            ) : (
              <></>
            )}
            <Toolbar className={classes.toolbarOne}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {!loadData && (
                    <Typography variant="h6" color="inherit" noWrap>
                      <img
                        src={mainLogo ? mainLogo : Logo}
                        alt={LayoutLang.NotAvailable[lang]}
                        className={`${classes.full_image}`}
                        onClick={handleLogoClick}
                      />
                      <img
                        src={mobileLogo ? mobileLogo : MobileLogo}
                        alt={LayoutLang.NotAvailable[lang]}
                        className={`${classes.mobile_image}`}
                        onClick={handleLogoClick}
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs>
                  {/* Div for spacing */}
                </Grid>
                <Grid item className={classes.headerRightGrid}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item className={classes.mobileHide}>
                      <div className={classes.time}>
                        {dateTime.time}
                        <sup className={classes.superscript}>
                          {dateTime.ampm}
                        </sup>
                      </div>
                    </Grid>
                    <Grid item className={classes.mobileHide}>
                      <div className={classes.date}>
                        {dateTime.dayName}
                        <br></br> {dateTime.date}
                      </div>
                    </Grid>
                    <Grid item>
                      <Tooltip title={topNavHelpTool.Search[lang]}>
                        <IconButton
                          className={classes.searchIconButton}
                          aria-label="search"
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>

                      <Dialog
                        open={searchOpen}
                        onClose={() => setSearchOpen(false)}
                        fullWidth
                        maxWidth="lg"
                      >
                        <SearchBox onClose={() => setSearchOpen(false)} />
                      </Dialog>
                    </Grid>
                    <Grid item>
                      <Tooltip title={topNavHelpTool.Recently[lang]}>
                        <IconButton
                          ref={historyIcon}
                          className={classes.iconButton}
                          onClick={fetchCompanyHistory}
                        >
                          <ScheduleIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={topNavHelpTool.ShowNotifications[lang]}>
                        <IconButton
                          aria-label="show new notifications"
                          className={classes.iconButton}
                          onClick={() => {
                            setNumberOfNewNotifications(0);
                            setShowNotifications(true);
                          }}
                        >
                          <Badge
                            badgeContent={numberOfNewNotifications}
                            color="secondary"
                            classes={{
                              badge: classes.notificationBadge,
                            }}
                          >
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>
                      </Tooltip>

                      <Dialog
                        open={showNotifications}
                        onClose={() => {
                          setShowNotifications(false);
                        }}
                      >
                        <UserNotification
                          onClose={() => setShowNotifications(false)}
                        />
                      </Dialog>
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleMenu}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              alt="No Logo"
                              src={props.user.profile_image_src}
                            />
                          }
                          label={
                            userMenu.Hi[lang] +
                            props.user.first_name +
                            " " +
                            props.user.last_name
                          }
                          clickable
                          onDelete={handleMenu}
                          deleteIcon={
                            <ArrowDropDownIcon
                              color="inherit"
                              className={classes.arrowIcon}
                            />
                          }
                          className={classes.hide}
                        />
                        <Avatar
                          className={classes.show}
                          alt="No Logo"
                          src={props.user.profile_image_src}
                        />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: 200,
                            borderRadius: 15,
                          },
                        }}
                      >
                        <MenuItem
                          onClick={handleMyProfile}
                          className={classes.menuItem}
                        >
                          {userMenu.MyProfile[lang]}
                        </MenuItem>
                        <Divider variant="middle" className={classes.divider} />
                        <MenuItem
                          onClick={handleMyVault}
                          className={classes.menuItem}
                        >
                          {userMenu.MyVault[lang]}
                        </MenuItem>
                        <Divider variant="middle" className={classes.divider} />
                        <MenuItem
                          onClick={() => {
                            window.open("http://kb.syncmonkey.com/");
                            handleClose();
                          }}
                          className={classes.menuItem}
                        >
                          {userMenu.KnowledgeBase[lang]}
                        </MenuItem>
                        {props.user.is_tenant_admin && (
                          <React.Fragment>
                            <Divider
                              variant="middle"
                              className={classes.divider}
                            />
                            <MenuItem
                              onClick={handleCompanySettings}
                              className={classes.menuItem}
                            >
                              {userMenu.CompanySettings[lang]}
                            </MenuItem>
                          </React.Fragment>
                        )}
                        <Divider variant="middle" className={classes.divider} />
                        <MenuItem
                          onClick={handleLogOut}
                          className={classes.menuItem}
                        >
                          {userMenu.LogOut[lang]}
                        </MenuItem>
                      </Menu>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorHistory}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={historyOpen}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: 300,
                            borderRadius: 10,
                            marginRight: 5,
                          },
                        }}
                      >
                        {(historyLoaded &&
                          historyItems.length > 0 &&
                          getHistory) || (
                          <MenuItem className={classes.menuItem}>
                            {LayoutLang.Nothingtoshow[lang]}
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
            <Toolbar className={classes.toolbarTwo}>
              {(window.location.pathname.includes("company/") ||
                window.location.pathname.includes("settings") ||
                window.location.pathname.includes("/hr")) && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                  style={{ display: showSideBar ? "none" : "block" }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h6"
                noWrap
                className={`${classes.companyName}`}
              >
                <span style={{ cursor: "pointer" }} onClick={handleLogoClick}>
                  {props.user.company_name}
                </span>
              </Typography>
              <Typography className={classes.sectionDesktop}>
                {hasPermission(props, ["View Data", "Create Company"]) && (
                  <NavLink
                    activeClassName={classes.active}
                    className={classes.navBarLink}
                    to={process.env.PUBLIC_URL + "/company"}
                  >
                    {topNav1.COMPANIES[lang]}
                  </NavLink>
                )}
                {hasPermission(props, ["View Data"]) && (
                  <React.Fragment>
                    <span className={classes.navDivider}>|</span>
                    <NavLink
                      activeClassName={classes.active}
                      className={classes.navBarLink}
                      to={process.env.PUBLIC_URL + "/documents"}
                    >
                      {topNav1.RESOURCES[lang]}
                    </NavLink>
                  </React.Fragment>
                )}
                {hasPermission(props, ["View Data"]) && (
                  <React.Fragment>
                    <span className={classes.navDivider}>|</span>
                    <NavLink
                      activeClassName={classes.active}
                      className={classes.navBarLink}
                      to={process.env.PUBLIC_URL + "/services"}
                    >
                      {topNav1.SERVICES[lang]}
                    </NavLink>
                  </React.Fragment>
                )}
                {hasPermission(props, ["View Data"]) && (
                  <React.Fragment>
                    <span className={classes.navDivider}>|</span>
                    <NavLink
                      activeClassName={classes.active}
                      className={classes.navBarLink}
                      to={process.env.PUBLIC_URL + "/support"}
                    >
                     {topNav1.VENDORS[lang]}
                    </NavLink>
                  </React.Fragment>
                )}
                {tenant &&
                  tenant.is_meeting_module_available &&
                  hasPermission(props, ["View Data"]) && (
                    <React.Fragment>
                      <span className={classes.navDivider}>|</span>
                      <NavLink
                        activeClassName={classes.active}
                        className={classes.navBarLink}
                        to={process.env.PUBLIC_URL + "/meeting"}
                      >
                        {topNav1.MEETINGS[lang]}
                      </NavLink>
                    </React.Fragment>
                  )}
                {tenant &&
                  tenant.is_moneris_module_available &&
                  hasPermission(props, ["Accounting"]) && (
                    <React.Fragment>
                      <span className={classes.navDivider}>|</span>
                      <NavLink
                        className={
                          window.location.pathname.includes("accounting")
                            ? `${classes.active} ${classes.navBarLink}`
                            : classes.navBarLink
                        }
                        to={process.env.PUBLIC_URL + "/accounting"}
                      >
                        {topNav1.ACCOUNTING[lang]}
                      </NavLink>
                    </React.Fragment>
                  )}
                {tenant &&
                  tenant.is_hr_module_available &&
                  hasPermission(props, ["HR"]) && (
                    <React.Fragment>
                      <span className={classes.navDivider}>|</span>
                      <NavLink
                        activeClassName={classes.active}
                        className={classes.navBarLink}
                        to={process.env.PUBLIC_URL + "/hr"}
                      >
                        {topNav1.HR[lang]}
                      </NavLink>
                    </React.Fragment>
                  )}
              </Typography>
              <div className={classes.grow} />
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls="mobile-menu"
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  className={classes.iconColorTheme}
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
        </Grid>
        <Grid
          item
          className={classes.navdiv}
          style={{ display: showSideBar ? "block" : "none" }}
        >
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Grid>
        <Grid
          item
          className={classes.maindiv}
          style={{
            width: showSideBar ? "calc(100% - " + drawerWidth + "px)" : "100%",
          }}
        >          
          {trialNotice ? (
            <>
              <div style={{ height:35, minHeight: 35, maxHeight:35 }} />
            </>
          ) : (
            <></>
          )}
          <main className={classes.content}>
            {(!props.mobile) && window.location.pathname.includes("/company/") && <div style={{height:20,minHeight:20,maxHeight:20}}></div>}
            {window.location.pathname.includes("/company/") &&
              <>
              <SearchIcon
                className={classes.iconFloatRight}
                style={{top: trialNotice?180:140}}
                onClick={() => {
                  setCompanySearchOpen(true);
                }}
              />
              <Dialog
                open={companySearchOpen}
                onClose={() => setCompanySearchOpen(false)}
                fullWidth
                maxWidth="lg"
              >
                <SearchBox onClose={() => setCompanySearchOpen(false)} company={window.location.pathname.split("/company/")[1].split("/")[0]}/>
              </Dialog>
              <PageComponent {...props} trialNotice={trialNotice} company={company} companyTenant={companyTenant} tenant={tenant} companyPermission={companyPermission}/>              
              </>
            }
            {!window.location.pathname.includes("/company/") &&
              <PageComponent {...props} trialNotice={trialNotice} tenant={tenant}/>
            }
          </main>
          <footer
          className={classes.footerdiv}
          style={{ left: showSideBar ? (drawerWidth+10) : 10 }}>{footer.powered[lang]}</footer>
        </Grid>
      </Grid>
    </div>
  );
};

Layout.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  clearUser,
  setUser,
})(Layout);
