import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatDate } from "../../Common/Utility";
import { connect } from "react-redux";
import {
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {recentActivityLang} from "../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    height: "80vh"
  },
  "@media (max-width: 430px)": {
    root: {
      // backgroundColor: "#e3e3e3",
      fontSize: 15,
    },
  },
  title: {
    color: theme.text,
  },
  tableHeader: {
    backgroundColor: `${theme.palette} !important`,
    color: theme.text,
    borderColor: theme.subText
  },
  tableCellThemed: {
    color: theme.colorScheme,
    borderBottom: "None"
  },
  tableCell: {
    color: theme.subText,
    borderBottom: "None",
    borderColor: theme.dividers
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.sidebar
    }
  },
  searchInput: {
    backgroundColor: theme.sidebar,
  },
  colorScheme: {
    color: theme.colorScheme,
  },
}));

const CompanyActivities = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");

  const lang = localStorage.getItem("languages");
  
  return (
    <div className={classes.root}>      
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableHeader}}>              
              <Typography component="h3" className={classes.title}>
                {recentActivityLang.RECENTACTIVITY[lang]}
              </Typography>
            </TableCell>
            {props.user.is_tenant_admin && (
              <TableCell align="left" classes={{ root: classes.tableHeader}}></TableCell>
            )}
            <TableCell align="right" classes={{ root: classes.tableHeader}}>              
              <TextField 
                className={classes.searchInput} 
                onChange={(e)=>{setSearch(e.target.value);}}
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      className={classes.colorScheme}
                    />
                  ),
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.tableHeader}}>{recentActivityLang.UPDATE[lang]}</TableCell>
            {props.user.is_tenant_admin && (
              <TableCell align="left" classes={{ root: classes.tableHeader}}>{recentActivityLang.BY[lang]}</TableCell>
            )}
            <TableCell align="left" classes={{ root: classes.tableHeader}}>{recentActivityLang.DATE[lang]}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.filter((row)=>(row.update.includes(search))).map((row) => (
            <TableRow key={row.id} classes={{ root: classes.tableRow }}>
              <TableCell component="th" scope="row" classes={{ root: classes.tableCell }}>
                {row.update}
              </TableCell>
            {props.user.is_tenant_admin && (
              <React.Fragment>
              <TableCell classes={{ root: classes.tableCellThemed }}>{row.modified_by}</TableCell>
              </React.Fragment>
            )}
              <TableCell classes={{ root: classes.tableCell }}>{formatDate(row.date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(CompanyActivities);
