import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import HRFileForm from "./HRFileForm";
import commonConstants from "../../Common/CommonConstants";
import { formatDateTime } from "../../Common/Utility";
import Dialog from "@material-ui/core/Dialog";
import FileViewer from "../../Common/FileViewer";
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const HRFiles = (props) => {
  const [data, setData] = useState([]);
  const url = `hr_employee_files?hr_employee=${props.hr_employee}`;
  const [filePath, setFilePath] = useState();

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "name",
      label: "NAME",
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : (props, item, column_id)=>{
        setFilePath({filePath:item.file_src,fileType:item.file.split('?')[0].split('.').pop()});
      }
    },
    {
      id: "download",
      label: "",
      minWidth: 10,
      colored: true,
      style: commonConstants.cellCursor,
    },
    {
      id: "created_time",
      label: "CREATED AT",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "created_by_name",
      label: "BY",
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    http.Get(
      url,
      (data) => {
        data.map((file) => {
          file.created_time = formatDateTime(file.created_time);
          file.download = props.mobile ? 
            <span
              onClick={() => {
                setFilePath({filePath:file.file_src,fileType:file.file.split('?')[0].split('.').pop()});
            }}>{file.name}</span> 
            : 
            <SaveAltIcon
              onClick={() => {
                if(file.file_src){
                  var a = window.document.createElement("a");
                  a.href = file.file_src;
                  a.download = file.name;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }
                else{
                  alert("File Not Found!");
                }
              }}
            />;
        });
        setData(data);
      },
      () => {}
    );
  };

  return (
    <>
    {filePath &&
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={() => {
          setFilePath(null);
        }}>
        <FileViewer
          fileType={filePath.fileType.toLowerCase()}
          filePath={filePath.filePath}
          onError={() => {
            setFilePath(null);
          }}
        />
      </Dialog>
    }
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={" "}
      url={url}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["HR"],
        edit: ["HR"],
        delete: ["HR"],
      }}
      fetchData={fetchData}
      objectForm={HRFileForm}
      orderBy={"name"}
      searchFields={["name"]}
      pageHeader={""}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={" "}
      url={url}
      tableColumns={tableColumns}
      data={data}
      actions={actions}
      permissions={{
        create: ["HR"],
        edit: ["HR"],
        delete: ["HR"],
      }}
      fetchData={fetchData}
      objectForm={HRFileForm}
      orderBy={"name"}
      searchFields={["name"]}
      pageHeader={""}
    />
    }
    </>
  );
};

export default HRFiles;
