import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { Button, Popover, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { hasPermission } from "../../Common/permission";
const useStyles = makeStyles((theme) => ({
    button: {
      float: "right",
      margin: 10,
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        border: "1px solid white",
      },
    },
  }));

const OutgoingTenants = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [tenantTransfer, setTenantTransfer] = useState();
  const [tenantInvoices, setTenantInvoices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_transfers`;

  const tableColumns = [
    {
      id: "current_reseller_name",
      label: "FROM RESELLER",
      sortable: true,
    },
    {
      id: "reseller_name",
      label: "TO RESELLER",
      sortable: true,
    },
    {
      id: "tenant_name",
      label: "TENANT NAME",
      sortable: true,
    },
    {
      id: "tenant",
      label: "TENANT ID",
      minWidth: 300,
      sortable: false,
    },
    {
      id: "email",
      label: "EMAIL",
      sortable: true,
    },
    {
      id: "status",
      label: "STATUS",
      sortable: true,
    },
    {
      id: "action",
      label: "",
      minWidth: 200,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var transfers = [];
        data.map((transfer)=>{
          if(transfer.current_reseller_id === props.user.reseller && transfer.status !== "Transfered"){
            if(transfer.status === "Pending"){
              transfer["action"] = (
                <Button
                  className={classes.button}
                  disabled={!hasPermission(props, ['Accept Outgoing Transfer'])}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e)=>{
                    setAnchorEl(e.currentTarget);
                    setTenantTransfer(transfer);
                  }}
                >
                  Approve
                </Button>
              );
            }
            transfers.push(transfer);
          }
        });
        setData(transfers);
        setLoadData(false);
      },
      () => {}
    );
  };

  useEffect(() => {
    if(tenantTransfer){
      http.Get(
        `tenant_invoices?tenant=${tenantTransfer.tenant}`,
        (invoices) => {
          setTenantInvoices(invoices);
        },
        () => {
        }
      );
    }
  }, [tenantTransfer]);

  return (
    <>
    {tenantTransfer &&      
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={()=>{setAnchorEl(null);setTenantTransfer(null);}}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
      >
        <div style={{padding:10,width:500}}>
          <h4>Unpaid Invoices:</h4>
          <Grid container>
          {tenantInvoices && tenantInvoices.map((tenantInvoice)=>{
            if((tenantInvoice.status === "Open" || tenantInvoice.status === "Overdue") && tenantInvoice.total > 0){
              return (
                <>
                  <Grid item xs={6}>{tenantInvoice.period}</Grid>
                  <Grid item xs={3}>{tenantInvoice.total}</Grid>
                  <Grid item xs={3}>{tenantInvoice.status}</Grid>
                </>
              );
            }
          })}
          </Grid>
          <h4>Enter Tenant ID To Confirm Transfer:</h4>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            onChange={(e)=>{
              setTenantTransfer({
                ...tenantTransfer,
                confirm_tenant: e.target.value,
                status: "Approved"
              })
            }}
            placeholder="Confirm Tenant ID"
          />
          <div>(You will lose unpaid invoices!)</div>
          <Button
            className={classes.button}
            variant="outlined"
            size="small"
            color="primary"
            onClick={(e)=>{
              http.Put(
                `tenant_transfers/${tenantTransfer.id}`,
                tenantTransfer,
                () => {
                  fetchData();
                  setAnchorEl(null);
                  setTenantTransfer(null);
                },
                (status, error) => {
                  console.log(error);
                  setAnchorEl(null);
                  setTenantTransfer(null);
                }
              );
            }}
            disabled={tenantTransfer.tenant !== tenantTransfer.confirm_tenant}
          >
            Confirm
          </Button>
        </div>
      </Popover>
    }
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Outgoing Tenants"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Outgoing Tenants"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </>
  );
};

export default OutgoingTenants;
