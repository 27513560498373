import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Dialog } from "@material-ui/core";
import { formatDate } from "../../Common/Utility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ScreenLoader from "../../Common/Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import CcMaster from "../../../Images/cc_master.png";
import CcVisa from "../../../Images/cc_visa.png";
import CcAmex from "../../../Images/cc_amex.png";
import CcUnknown from "../../../Images/cc_unknown.png";

const useStyles = makeStyles((theme) => ({
	cursor: {
		cursor: "pointer",
		color: theme.colorScheme,
		float:"right",
	},
	paper: {
		padding: 15,
		height: 120,
		minWidth: 50,
	},
	loadingBlock: {
		backgroundColor: "#DDDDDD",
		position: "fixed",
		width: "100%",
		height: "100%",
		zIndex: 1000,
		top: "0px",
		left: "0px",
		opacity: ".8",
		filter: "alpha(opacity=80)",
	},
	absoluteCenter: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	cardImage: {
		width: "100%",
		maxWidth: 130,
		maxHeight: 80,
	},
	mobilePaper: {
		[theme.breakpoints.down(445)]: {
			height: "max-content",
		},
	},
	subscription: {
		overflowX: "auto",
		overflowY: "auto",
		height: "80px",
	},
}));

const BillingDetails = (props) => {
	const classes = useStyles();
	const { id } = props.match.params;
	const [data, setData] = useState();
	const [account, setAccount] = useState();
	const [balance, setBalance] = useState(0);
	const url = `reseller_invoices?reseller=${id}`;
	const [loading, setLoading] = useState();
	const [content, setContent] = useState();
	const [loadData, setLoadData] = useState(false);

	const tableColumns = [
		{
			id: "billing_cycle",
			label: "BILLING CYCLE",
			minWidth: 100,
			sortable: true,
			colored: true,
			style: commonConstants.cellCursor,
			onClick: (props, object, property)=>{
				const offset = new Date().getTimezoneOffset();					
				http.ExportToPdf(
					`reseller/${id}/invoices/pdf`,
					{ offset: offset, year:object.year, month:object.month },
					(blob) => {
						if(blob.size>0){
							var a = window.document.createElement("a");
							a.href = window.URL.createObjectURL(blob);
							a.download = object.reseller_name + object.billing_cycle + ".pdf";
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}
					},
					() => {
					}
				);
			},
		},
		{
			id: "tenant_name",
			label: "TENANT",
			minWidth: 100,
			sortable: true,
			colored: true,
			style: commonConstants.cellCursor,
			onClick: (props, object, property)=>{
				const offset = new Date().getTimezoneOffset();			
				http.ExportToPdf(
					`reseller_invoice/${object.id}/pdf`,
					{ offset: offset },
					(blob) => {
						if(blob.size>0){
							var a = window.document.createElement("a");
							a.href = window.URL.createObjectURL(blob);
							a.download = object.tenant_name + object.billing_cycle + ".pdf";
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}
					},
					() => {
					}
				);
			},
		},
		{
			id: "total",
			label: "TOTAL",
			minWidth: 100,
			sortable: true,
			style: { float: "right" },
		},
		{
		  id: "period",
		  label: "PERIOD",
		  minWidth: 100,
		  sortable: true,
		},
		{
			id: "due_date",
			label: "DUE DATE",
			minWidth: 80,
			sortable: true,
		},
		{
			id: "status",
			label: "STATUS",
			minWidth: 80,
			sortable: true,
			colored: true,
		},
		{
			id: "pay",
			label: "",
			minWidth: 150,
			colored: true,
			style: commonConstants.cellCursor,
			disableTooltip: true,
		},
	];

	useEffect(() => {
		if (data) {
			http.Get(
				`reseller_payment_infos?reseller=${id}`,
				(infos) => {
					if (infos && infos.length === 1) {
						var info = infos[0];
						setAccount(() => {
							return (
								<div>
									{info.reseller_name}
									<br />
									<Grid
										container
										spacing={3}
										style={{ fontSize: 12 }}
										alignItems="center"
									>
										<Grid item xs={12} sm={12} md={6}>
											<Paper className={classes.paper}>
												<Grid container spacing={1}>
													<Grid item xs={12}>
														CURRENT BALANCE
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h3">
															{" "}
															$ {balance}
														</Typography>
													</Grid>
												</Grid>
											</Paper>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Paper
												className={`${classes.paper} ${classes.mobilePaper}`}
											>
												<Grid container spacing={1}>
													<Grid item xs={12}>
														CARD INFO
													</Grid>
													<Grid item xs={12}>
														<Grid container spacing={3}>
															<Grid item xs={6}>
																{info &&
																	info.card_number &&
																	info.card_number.startsWith("3") && (
																		<img
																			className={classes.cardImage}
																			src={CcAmex}
																		/>
																	)}
																{info &&
																	info.card_number &&
																	info.card_number.startsWith("4") && (
																		<img
																			className={classes.cardImage}
																			src={CcVisa}
																		/>
																	)}
																{info &&
																	info.card_number &&
																	info.card_number.startsWith("5") && (
																		<img
																			className={classes.cardImage}
																			src={CcMaster}
																		/>
																	)}
																{info &&
																	info.card_number &&
																	!info.card_number.startsWith("5") &&
																	!info.card_number.startsWith("4") &&
																	!info.card_number.startsWith("3") && (
																		<img
																			className={classes.cardImage}
																			src={CcUnknown}
																		/>
																	)}
															</Grid>
															<Grid item xs={6}>
																{info.card_number &&
																	"***" +
																		info.card_number.substring(
																			info.card_number.length - 4
																		)}
																<br />
																<br />
																Exp: &nbsp;
																{Math.floor(info.expiration / 100)}/
																{info.expiration % 100}
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Paper>
										</Grid>
									</Grid>
								</div>
							);
						});
					}
				},
				() => {}
			);
		}
	}, [data]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = () => {
		setLoadData(true);
		http.Get(
			url,
			(data) => {
				var itemBalance = 0;
				data.forEach((item) => {
					item.billing_cycle = item.year + (item.month>9?("-" + item.month):("-0" + item.month));
					item.due_date = formatDate(item.due_date);
					item.balance = parseFloat(item.total) - item.payments;
					item.items = (
						<div>
							{item.items.map((i) => {
								return <div>{i.name}: ${i.price} * {i.quantity}</div>;
							})}
						</div>
					);
					item.pay = (
						<div style={{ margin: "10px" }}>
							{item.payments === 0 && parseFloat(item.total) > 0 &&
								<CreditCardIcon
									onClick={() => {
										setLoading("Processing......");
										http.Get(
											`process_reseller_invoice?invoice_id=${item.id}`,
											(res) => {
												fetchData();
												setLoading(res.message);
												setTimeout(() => {
													setLoading(null);
												}, 5000);
											},
											(status, error) => {
												setLoading(status + ": " + error.error);
												setTimeout(() => {
													setLoading(null);
												}, 5000);
											}
										);
									}}
								/>
							}
							{item.payments > 0 &&
								<VisibilityIcon
									onClick={() => {
										http.Get(
											`reseller_payments?reseller_invoice=${item.id}`,
											(payments) => {
												setContent(
												<div>
													{payments.map((payment) => {
														if(payment.response_content.startsWith("https://")){
															window.open(payment.response_content);															
															setTimeout(() => {
																setContent(null);
															}, 1000);
															return <a href={payment.response_content} target="_blank">Receipt</a>;
														}
														else{
															return payment.response_content;
														}
													})}
												</div>
												);
											},
											() => {}
										);
									}}
								/>
							}
							<span style={{ margin: "10px" }}> </span>
							<DeleteIcon
								onClick={() => {
									http.Delete(
										`reseller_invoices/${item.id}`,
										(res) => {
											fetchData();
										},
										(status, error) => {
											fetchData();
										}
									);
								}}
							/>
						</div>
					);

					itemBalance = itemBalance + item.balance;
					item.sub_total = Number.parseFloat(item.sub_total).toFixed(2);
					item.total = Number.parseFloat(item.total).toFixed(2);
					item.balance = Number.parseFloat(item.balance).toFixed(2);
				});

				setBalance(Number.parseFloat(itemBalance).toFixed(2));
				setData(data);
				setLoadData(false);
			},
			() => {
				setLoadData(false);
			}
		);
	};

	return (
		<div>
			{loading && (
				<div className={classes.loadingBlock}>
					<div className={classes.absoluteCenter}>
						<h1>{loading}</h1>
					</div>
				</div>
			)}

			{content && (
				<Dialog
					open={true}
					onClose={() => {
						setContent(null);
					}}
				>
					<div
						style={{ padding: "20px", minWidth: "400px", minHeight: "300px" }}
					>
						{content}
					</div>
				</Dialog>
			)}

			{loadData && <ScreenLoader />}

			{account && (
				<ObjectList
					{...props}
					moduleName={account}
					url={url}
					tableColumns={tableColumns}
					data={data}
					fetchData={fetchData}
					orderBy={"billing_cycle"}
					searchFields={["tenant_name", "due_date", "billing_cycle"]}
					pageHeader={
						<span>
							{(props.user.company_name || "") + " | "}{" "}
							<span
								onClick={() => {
									props.history.push(
										process.env.PUBLIC_URL + "/settings/billing"
									);
								}}
							>
								Billing
							</span>
						</span>
					}
					loadData={loadData}
					excludeAddButton={true}
				/>
			)}
		</div>
	);
};

export default BillingDetails;
