import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import http from "../../Common/RestAPIHandler";
import { formatDateTime } from "../../Common/Utility";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    minWidth: 300,
  },
  notificationBox: {
    display: "flex",
    flexDirection: "row",
    margin: "unset",
  },
  header: {
    padding: theme.spacing(1),
    backgroundColor: "#fafafa",
    overflow: "hidden",
  },
  cross: {
    textAlign: "right",
  },
  contentGrid: {
    overflow: "auto",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    maxHeight: "350px",
    minHeight: "200px",
  },
  noContentGrid: {
    overflow: "auto",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    maxHeight: "350px",
    minHeight: "200px",
    minWidth: "300px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
  },
  bold: {
    fontWeight: 500,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    textAlign: "right",
    color: theme.palette.grey[500],
  },
  noNotification: {
    textAlign: "center",
  },
  deleteButton: {
    float: "right",
    color: theme.palette.grey[500],
    cursor: "pointer",
  },
}));

const UserNotification = (props) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    http.Get(
      "notifications/",
      (data) => {
        setData(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      var items = data.map((item) => {
        item["modified_at"] = formatDateTime(item.last_modified_time);
        return item;
      });
      if (items) {
        setNotifications(items);
      }
    }
  }, [data]);

  const showNotification = (notification) => {
    if (notification["model"] === "Meeting") {
      return (
        <div>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="initial">
              <span className={classes.bold}>
                {notification.last_modified_by_name}
              </span>
              <span>{" mentioned you in "}</span>
              <span className={classes.bold}>{notification.name}</span>
              <span>{" meeting."}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {notification.modified_at}
            </Typography>
          </Grid>
        </div>
      );
    } else if (notification["model"] === "TenantDocument") {
      return (
        <div>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="initial">
              <span className={classes.bold}>
                {notification.last_modified_by_name}
              </span>
              <span>{" mentioned you in "}</span>
              <span className={classes.bold}>{notification.title}</span>
              <span>{" document."}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              {notification.modified_at}
            </Typography>
          </Grid>
        </div>
      );
    }else if (notification["model"] === "TenantUserNotification") {
      return (
        <div>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="initial">
              <span className={classes.bold}>
                <p
                  dangerouslySetInnerHTML={{ __html: notification.content }}
                  />

                {/* {notification.content} */}
                {notification.file &&
                  <IconButton
                    className={classes.iconButtonColored}
                    onClick={() => {
                      var a = window.document.createElement("a");
                      a.href = notification.file;
                      a.download = notification.content;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}
                  >
                    <SaveAltIcon/>
                  </IconButton>
                }
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DeleteIcon
              className={classes.deleteButton}
              onClick={(e)=>{
                http.Delete(
                  "tenant_user_notifications/" + notification.id,
                  {},
                  () => {
                    http.Get(
                      "notifications/",
                      (data) => {
                        setData(data);
                      },
                      () => {}
                    );
                  },
                  () => {}
                );
              }}/>            
            <Typography variant="subtitle2" color="textSecondary">
              {notification.modified_at}
            </Typography>
          </Grid>
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.notificationBox}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.header}>
            <Grid item xs={11}>
              <Typography variant="subtitle1" className={classes.bold}>
                NOTIFICATIONS
              </Typography>
            </Grid>
            <Grid item xs={1} className={classes.cross}>
              {props.onClose ? (
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={
              notifications.length > 0
                ? classes.contentGrid
                : classes.noContentGrid
            }
          >
            <Grid container spacing={1}>
              {(notifications.length > 0 &&
                notifications.map((notification) => {
                  return (
                    <Grid item xs={12} key={notification.id}>
                      <Paper className={classes.paper}>
                        <Grid item xs={12} alignItems="center">
                          {showNotification(notification)}
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })) || (
                <Grid item xs={12} className={classes.noNotification}>
                  You have no notification
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default UserNotification;
