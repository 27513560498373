import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {
  Divider,
  Typography,
  Paper,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Snackbar,
} from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SyncIcon from "@material-ui/icons/Sync";
import Tooltip from "@material-ui/core/Tooltip";

import DeviceForm from "./DeviceForm";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CreateIcon from "@material-ui/icons/Create";
import { DeviceLang } from "../../Common/Languages";

const DeviceDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  var { deviceId } = props.match.params;
  const [device, setDevice] = useState({});
  const [dataMappings, setDataMappings] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const [ipAddress, setIpAddress] = useState();
  const [refreshingIP, setRefreshingIP] = useState(false);

  const lang = localStorage.getItem("languages");

  useEffect(() => {
    if (props.objectId) {
      deviceId = props.objectId;
    }
    http.Get(
      `companies/${id}/devices/${deviceId}`,
      (device) => {
        setDevice(device);
        setIpAddress(device.current_ip);
        http.Get(
          `data_mappings?from_id=${device.id}`,
          (data) => {
            setDataMappings(data);
            http.Get(
              `device/credential/?device=${device.id}`,
              (credentials) => {
                setCredentials(credentials);
                setLoading(false);
              },
              () => {}
            );
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  const refreshIp = () => {
    setRefreshingIP(true);
    if (device.ninja_device_id) {
      http.Get(
        `ninja_sync/ip_address/${id}/${device.ninja_device_id}`,
        (data) => {
          setIpAddress(data["latest_ip_address"]);
          setRefreshingIP(false);
          setMessage(data["message"]);
        },
        () => {
          setRefreshingIP(false);
          setSeverity("error");
          setMessage("Unable to refresh IP.");
        }
      );
    } else if (device.cw_configuration_id) {
      http.Get(
        `cw/ip_address/${id}/${device.cw_configuration_id}`,
        (data) => {
          setIpAddress(data["latest_ip_address"]);
          setRefreshingIP(false);
          setMessage(data["message"]);
        },
        () => {
          setRefreshingIP(false);
          setSeverity("error");
          setMessage("Unable to refresh IP.");
        }
      );
    } else {
      http.Get(
        `syncro_sync/ip_address/${id}/${device.syncro_asset_id}`,
        (data) => {
          setIpAddress(data["latest_ip_address"]);
          setRefreshingIP(false);
          setMessage(data["message"]);
        },
        () => {
          setRefreshingIP(false);
          setSeverity("error");
          setMessage("Unable to refresh IP.");
        }
      );
    }
  };

  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && (
        <Grid container spacing={1} className={classes.paper}>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {device.device_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButtonColored}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  } else {
                    props.history.push(
                      process.env.PUBLIC_URL + `/company/${id}/servers_devices`
                    );
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={props.mobile ? 12 : 10}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.DEVICENAME[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.device_name}                    
                    
                    <IconButton
                      className={classes.iconButtonColored}
                      style={{float:"right"}}
                      onClick={() => {
                          setOpen(true);
                      }}
                    >
                      <CreateIcon />
                    </IconButton>
                    <Drawer
                      anchor="right"
                      open={open}
                      onClose={() => setOpen(false)}
                      disableEnforceFocus
                      classes={{
                        paper: classes.drawerForm,
                      }}
                    >
                      <div style={{ width: width }}>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                draggable
                                style={{ cursor: "ew-resize" }}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                              >
                                <DragIndicatorIcon />
                              </td>
                              <td width="100%">
                                <DeviceForm
                                  {...props}
                                  closeOpen={(open) => setOpen(open)}
                                  objectId={props.objectId}
                                  handleObjectSubmit={handleObjectSubmit}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Drawer>

                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.DEVICETYPE[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.category_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.DESCRIPTION[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.description}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.URL[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "url");
                        setMessage(DeviceLang.UrlCopied[lang]);
                      }}
                    />
                    &nbsp;&nbsp;{device.url}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.USERNAME[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "username");
                        setMessage(DeviceLang.UsernameCopied[lang]);
                      }}
                    />
                    &nbsp;&nbsp;{device.username}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.PASSWORD[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "password");
                        setMessage(DeviceLang.PasswordCopied[lang]);
                      }}
                    />
                    &nbsp;&nbsp;
                    <span
                      className={classes.cursor}
                      onClick={(e) => {
                        if (e.target.innerHTML === "············") {
                          e.target.innerHTML = String(device.password)
                            .replaceAll("<", "&lt;")
                            .replaceAll(">", "&gt;");
                        } else {
                          e.target.innerHTML = "············";
                        }
                      }}
                    >
                      ············
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.TAGNUMBER[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.tag_number}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.OPERATINGSYSTEM[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.os}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.HARDWAREMAKE[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_make}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.HARDWAREMODEL[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_model}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>
                    {DeviceLang.HARDWARESERIALNUMBER[lang]}
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_serial_number}
                  </Grid>
                  {(device.ninja_device_id ||
                    device.syncro_asset_id ||
                    device.cw_configuration_id) && (
                    <>
                      <Grid item xs={4}>
                        <span style={{ fontWeight: "bold" }}>{DeviceLang.CURRENTIP[lang]}</span>
                      </Grid>
                      <Grid item xs={8}>
                        <Tooltip title="Refresh IP">
                          <SyncIcon
                            className={classes.iconButtonColored}
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              width: 15,
                              verticalAlign: "bottom",
                            }}
                            onClick={() => refreshIp()}
                          />
                        </Tooltip>
                        &nbsp;&nbsp;
                        {!refreshingIP ? ipAddress : <ScreenLoader />}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.LASTLOGINNAME[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.last_login_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.NOTES[lang]}</span>
                  </Grid>
                  <Grid item xs={8} style={{whiteSpace:"pre-wrap"}}>
                    {device.notes}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>{DeviceLang.SITE[lang]}</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.site_name}
                  </Grid>
                  {!props.mobile && (
                    <>
                      <Grid item xs={12}>
                        <span style={{ fontWeight: "bold" }}>{DeviceLang.CREDENTIALS[lang]}</span>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Snackbar
                          open={Boolean(message)}
                          autoHideDuration={3000}
                          onClose={() => {
                            setMessage(null);
                            setSeverity("success");
                          }}
                          severity={severity}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Alert
                            onClose={() => {
                              setMessage(null);
                              setSeverity("success");
                            }}
                            severity={severity}
                            elevation={6}
                            variant="filled"
                          >
                            {message}
                          </Alert>
                        </Snackbar>
                        <Table classes={{ root: classes.tables }}>
                          <TableHead>
                            <TableCell className={classes.tableCellHeader}>
                            {DeviceLang.CATEGORY[lang]}
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                            {DeviceLang.DESCRIPTION[lang]}
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                            {DeviceLang.USERNAME[lang]}
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                            {DeviceLang.PASSWORD[lang]}
                            </TableCell>
                          </TableHead>
                          {credentials.map((credential) => {
                            return (
                              <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                  {credential.category_name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {credential.description}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <FilterNoneIcon
                                    className={classes.iconButtonColored}
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                      width: 15,
                                      verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                      onCopy(props, credential, "username");
                                      setMessage(DeviceLang.UsernameCopied[lang]);
                                    }}
                                  />
                                  &nbsp;&nbsp;{credential.username}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <FilterNoneIcon
                                    className={classes.iconButtonColored}
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                      width: 15,
                                      verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                      onCopy(props, credential, "password");
                                      setMessage(DeviceLang.PasswordCopied[lang]);
                                    }}
                                  />
                                  &nbsp;&nbsp;
                                  <span
                                    className={classes.cursor}
                                    onClick={(e) => {
                                      if (
                                        e.target.innerHTML === "············"
                                      ) {
                                        e.target.innerHTML = String(
                                          credential.password
                                        )
                                          .replaceAll("<", "&lt;")
                                          .replaceAll(">", "&gt;");
                                      } else {
                                        e.target.innerHTML = "············";
                                      }
                                    }}
                                  >
                                    ············
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
          {!props.mobile && (
            <Grid item xs={2}>
              <Paper>
                <div
                  style={{
                    maxHeight: "calc(100vh - 280px)",
                    minHeight: "calc(100vh - 280px)",
                    overflowY: "auto",
                    overflowX: "auto",
                    marginLeft: 10,
                    padding: 10,
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>{DeviceLang.RelatedEntries[lang]}:</p>
                  <DataMappings
                    {...props}
                    dataMappings={dataMappings}
                    setDataMappings={null}
                  />
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default DeviceDetails;
