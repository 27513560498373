import React, { useEffect } from "react";
import http from "../Common/RestAPIHandler";
import auth from "../../services/authService";
import { setUser } from "../../redux-tools/actions/userActions";
import { connect } from "react-redux";

import {PublicClientApplication} from "@azure/msal-browser";
import crypto from "crypto";
import Logo from "../../Images/logo.png";
import useStyles from "./style";
import { Grid, Typography, Link, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import commonConstants from "../Common/CommonConstants";

const AzureSSO = (props) => {
  const classes = useStyles();
  const [user, setUser] = React.useState();
  const [errors, setErrors] = React.useState({});
  const errorText = commonConstants.error;
  const azure_tenant_id = localStorage.getItem("azure_tenant_id");
  const azure_client_id = localStorage.getItem("azure_client_id");
  const azure_client_secret = localStorage.getItem("azure_client_secret");

  // Tool for code Verifier challenge (PKCE)
  const base64URLEncode = (str) => {
    return str
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  };

  const verifier = base64URLEncode(crypto.randomBytes(32));

  // Azure config
  const msalConfig = {
    auth: {
      clientId: azure_client_id, //process.env.AZURE_CLIENT_ID,
      // clientSecret: "dfe71bbd-5645-43b3-b9a6-2f3279bb7dbe", //process.env.AZURE_CLIENT_SECRET,
      authority:
        `https://login.microsoftonline.com/${azure_tenant_id}/`,
    },
    cache: {
      cacheLocation: "sessionStorage",
      secureCookies: true, // Ensure cookies can only be sent over HTTPS
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  // PUBLIC CLIENT
  useEffect(() => {
    msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      console.log(tokenResponse);
      if (tokenResponse) {
        http.Get(
          `azuresso?SSO_unique_id=${tokenResponse.uniqueId}`,
          (response) => {
            auth.saveToken(response.access_token);
            if (response.theme == "Light") {
              localStorage.setItem("isDarkEnabled", false);
            } else {
              localStorage.setItem("isDarkEnabled", true);
            }
            localStorage.setItem(
              "colorScheme",
              JSON.stringify(response.colour_scheme || "Orange")
            );
            localStorage.setItem("date_format", response.date_format);
            localStorage.setItem("date_time_format", response.date_time_format);
            localStorage.setItem("notification_days", response.notification_days);
            if(response.authy_verified){
              props.setUser(response);
              props.history.replace(process.env.PUBLIC_URL + "/dashboard");
            }
            else{
              props.setUser(response);
              props.history.replace(process.env.PUBLIC_URL + "/twoFA");
            }
          },
          () => {
            setUser({"email":tokenResponse.account.username,"SSO_unique_id":tokenResponse.uniqueId,"meta_data":tokenResponse.account});
          }
        );
      } else {
        msalInstance
        .loginRedirect({
          extraQueryParameters: {
            scopes: ["user.read"],
            codeChallenge: verifier,
            codeChallengeMethod: "S256",
          },
          clientSecret: azure_client_secret,
          redirectUri:
            (window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? `:${window.location.port}/azuresso` : "/azuresso")),
        })
        .catch((error) => {
          props.history.replace(process.env.PUBLIC_URL + "/passwordlogin");
        });
      }
    })
    .catch((error) => {
      props.history.replace(process.env.PUBLIC_URL + "/passwordlogin");
    });
  }, []);


  return (
    <div className={classes.main}>
    {user &&
     <div className={classes.root}>   
      <ValidatorForm onSubmit={()=>{
        http.Post(
          "azuresso",
          user,
          (response) => {
            auth.saveToken(response.access_token);
            if (response.theme == "Light") {
              localStorage.setItem("isDarkEnabled", false);
            } else {
              localStorage.setItem("isDarkEnabled", true);
            }
            localStorage.setItem(
              "colorScheme",
              JSON.stringify(response.colour_scheme || "Orange")
            );
            localStorage.setItem("date_format", response.date_format);
            localStorage.setItem("date_time_format", response.date_time_format);
            localStorage.setItem("notification_days", response.notification_days);
            if (response.authy_verified) {
              props.setUser(response);
              props.history.replace(process.env.PUBLIC_URL + "/dashboard");
            } else {
              props.setUser(response);
              props.history.replace(process.env.PUBLIC_URL + "/twoFA");
            }

          },
          (status, error)=>{
            setErrors(error);
            if (error.invalid_email_password) {
              http.Post(
                "user_session/lock/?user_type=Tenant User",
                user,
                () => {},
                () => {}
              );
            }
          }
        );
      }}>    
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className={classes.container}
        >
          
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.centerElement}>
                <Typography variant="h6" color="inherit" noWrap>
                  <img
                    src={Logo}
                    alt="Not Available"
                    className={classes.logo}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="h5"
                  color="textSecondary"
                  classes={{ h4: classes.title }}
                >                  
                  No SSO setup for this user!<br/>
                  Please login first:<br/>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  id="outlined-email-input"
                  label="E-mail"
                  type="text"
                  name="email"
                  variant="outlined"
                  fullWidth
                  value={user.email || ""}
                  onChange={(e)=>{setUser({...user,"email":e.target.value});}}
                  validators={["required", "trim", "isEmail"]}
                  errorMessages={[
                    errorText.requiredError,
                    errorText.blankError,
                    errorText.invalidEmailError,
                  ]}
                />
              </Grid>                   
              <Grid item xs={12}>
                <TextValidator
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  name="password"
                  variant="outlined"
                  className={classes.passwordField}
                  fullWidth
                  inputProps={{ style: { fontWeight: "bold" } }}
                  value={user.password || ""}
                  onChange={(e)=>{setUser({...user,"password":e.target.value});}}
                  validators={["required"]}
                  errorMessages={[errorText.requiredError]}
                />
              </Grid>

              {errors && errors.invalid_email_password && (
                <Grid item xs={12}>
                  <Alert severity="error" variant="filled">
                    {errors.invalid_email_password}
                  </Alert>
                </Grid>
              )}

              {errors && errors.lockout && (
                <Grid item xs={12}>
                  <Alert severity="error" variant="filled">
                    {errors.lockout}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Link
                      component={RouterLink}
                      to={process.env.PUBLIC_URL + "/forgetPassword?userType=Tenant User"}
                      color="textPrimary"
                      className={classes.link}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item xs={6} className={classes.rightElement}>
                    <Button
                      type="submit"
                      className={classes.button}
                      variant="outlined"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
     </div>
    }
    </div>
  );
};
export default connect(null, { setUser })(AzureSSO);
