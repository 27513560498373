import React, { useEffect, useState } from "react";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ScreenLoader from "../../Common/Loader";
import { connect } from "react-redux";
import Logo from "../../../Images/logo_app.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Slider from '@material-ui/core/Slider';
import SnackbarComponent from "../../Common/Snackbar";
import Input from '@material-ui/core/Input';
import CcMaster from "../../../Images/cc_master.png";
import CcVisa from "../../../Images/cc_visa.png";
import CcAmex from "../../../Images/cc_amex.png";
import CcUnknown from "../../../Images/cc_unknown.png";
import { FormControlLabel } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import { hasPermission } from "../../Common/permission";

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        display: "flex",
        overflow: "hidden",
        width:"100%",
        margin: "auto"
    },
    gridTop: {
        height: "100px",
    },
    gridLeft: {
        height: "calc(100vh - 200px)",
        backgroundColor: "#DDDDDD",
        color: "#222222",
        overflow: "auto",
        [theme.breakpoints.down(445)]: {
            height: "100%",
        },
    },
    gridRight: {
        height: "calc(100vh - 200px)",
        backgroundColor: "#222222",
        color: "#DDDDDD",
    },
    gridBottom: {
        height: "100px",
        fontWeight: 500,
    },
    selectPackage: {
        marginLeft: "10px",
        borderRadius: "25px",
    },
    button: {
      margin: 10,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        border: "1px solid white",
      },
    },
    floatRight: {
        float: "right",
    },
    input: {
      border: "1px solid #ccc",
      padding: "5px 12px",
      cursor: "pointer",
      borderRadius: "5px",
      color: "#DDDDDD",
    },
    cardImage: {
      maxWidth: 90,
      maxHeight: 60,
    },
    error: {
      color: "red",
    },
    message: {
      color: theme.colorScheme,
      float: "right",
    },
    billingDiv: {
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down(445)]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    paymentDiv: {
        paddingTop: 100,
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down(445)]: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
}));

const Subscription = (props) => {
  const id = props.id;
  const classes = useStyles();
  const [loadData, setLoadData] = useState(false);
  const [paymentInfoOnFile, setPaymentInfoOnFile] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [packages, setPackages] = useState();
  const [tenant, setTenant] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [errors, setErrors] = useState({});
  const yearMonth = (new Date().getFullYear()-2000) * 100 + new Date().getMonth();
  const monthNames = ["February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "January", ];
  
  useEffect(() => {
    setLoadData(true);
    http.Get(
        `packages`,
        (data) => {
            setPackages(data);
        },
        () => {
        }
    );
    http.Get(
        `tenant_payment_infos?tenant=${id}`,
        (data) => {
            if(data.length==1){
                setPaymentInfo(data[0]);
                setPaymentInfoOnFile(data[0]);
            }
            else{
                setPaymentInfo({card_number:"",expiration:"",tenant:id});
            }
        },
        () => {
        }
    );
    http.Get(
        `tenants/${id}`,
        (data) => {
            setTenant(data);
        },
        () => {
        }
    );
  }, []);

  useEffect(() => {
    if(tenant && paymentInfo && packages){
        packages.map((p)=>{
            if(p.id === tenant.package){
                p.is_hidden=false;
                setSelectedPackage(packages.filter((p)=>{return p.id === tenant.package})[0]);
            }
        });
        setLoadData(false);
    }
  }, [tenant,paymentInfo,packages]);

  return (
    <Grid container spacing={3} className={classes.gridRoot}>
        {window.location.pathname.includes("settings/lockout") &&
            <Grid item xs={12} className={classes.gridTop}>
                <img
                    src={Logo}
                    alt="Not Available"
                />
            </Grid>
        }
        {loadData &&
            <ScreenLoader/>
        }
        <SnackbarComponent
          message={snackBarMessage}
          open={snackBarMessage}
          handleSnackBarClose={() => {
            setSnackBarMessage(null);
            setSeverity("success");
          }}
          severity={severity}
        ></SnackbarComponent>
        {!loadData && tenant && paymentInfo && packages && 
            <>
                <Grid item md={12} lg={8} className={classes.gridLeft}>
                    <div className={classes.billingDiv}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => props.setTenant(null)}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <h2>{tenant.company_name}</h2>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xl={4}>
                                        PLAN
                                        <Select
                                            className={classes.selectPackage}
                                            variant="outlined"
                                            margin="dense"
                                            name={"package"}
                                            value={tenant["package"] || ""}
                                            onChange={(e) => {                                            
                                                setSelectedPackage(packages.filter((p)=>{return p.id === e.target.value})[0]);
                                                setTenant({...tenant,package:e.target.value});
                                            }}
                                        >
                                            {packages.map(
                                                (p) => {
                                                    if(!p.is_hidden) return (
                                                    <MenuItem key={p.id} value={p.id}>
                                                        {p.package_name}
                                                    </MenuItem>
                                                );}
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item lg={12} xl={5}>
                                        Subscription &nbsp;
                                        <FormControlLabel
                                            control={                                                                                    
                                                <Radio
                                                    checked={tenant.is_monthly}
                                                    onClick={(e) => {
                                                        setTenant({...tenant, is_monthly:true});
                                                    }}
                                                    color="primary"
                                                    disabled={(new Date(tenant.renewal_date).getTime() - new Date().getTime()) / (24*60*60*1000) > 14}
                                                />
                                            }
                                            label="Monthly"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={  
                                                <Radio
                                                    checked={!tenant.is_monthly}
                                                    onClick={(e) => {
                                                        setTenant({...tenant, is_monthly:false});
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Annual"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item lg={12} xl={3}>                                        
                                        {selectedPackage && 
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.button}
                                            disabled={!hasPermission(props, ['Create/Modify Tenant Billing Info'])}
                                            onClick={(e)=>{
                                                http.Put(
                                                    `tenants/${id}`,
                                                    tenant,
                                                    (data) => {
                                                        setSnackBarMessage("Plan updated");
                                                        setTenant(data);
                                                        http.Get(
                                                            `create_tenant_invoice?tenant_id=${id}`,
                                                            (data) => {
                                                                if(data['invoice_ids'] && paymentInfoOnFile){
                                                                    data['invoice_ids'].map((invoice_id)=>{
                                                                        http.Get(
                                                                          `process_tenant_invoice?invoice_id=${invoice_id}`,
                                                                          (res) => {
                                                                            setSnackBarMessage("Invoice process message: " + res.message);
                                                                          },
                                                                          (status, error) => {
                                                                            setSeverity("error");
                                                                            setSnackBarMessage("Invoice process error: " + error.error);
                                                                          }
                                                                        );
                                                                    });
                                                                }
                                                            },
                                                            (status, error) => {
                                                                setSeverity("error");
                                                                setSnackBarMessage(JSON.stringify(error));
                                                            }
                                                        );
                                                    },
                                                    (status, error) => {
                                                        setSeverity("error");                                                        
                                                        setSnackBarMessage(JSON.stringify(error));
                                                    }
                                                );
                                            }}>
                                                UPDATE PLAN
                                        </Button>
                                        }
                                    </Grid>
                                </Grid>                             
                            </Grid>
                            {selectedPackage && 
                            <>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        Users
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Slider
                                            value={tenant['user_addons']}
                                            min={1}
                                            max={128}
                                            onChange={(event, newValue) => {
                                                setTenant({
                                                ...tenant,
                                                ['user_addons']: newValue
                                                });}}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {tenant['user_addons']}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        Readonly
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Slider
                                            value={tenant['readonly_addons']}
                                            min={0}
                                            max={128}
                                            onChange={(event, newValue) => {
                                                setTenant({
                                                ...tenant,
                                                ['readonly_addons']: newValue
                                                });}}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {tenant['readonly_addons']}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        Add Portals
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Slider
                                            value={tenant['portal_addons']}
                                            min={0}
                                            max={128}
                                            onChange={(event, newValue) => {
                                                setTenant({
                                                ...tenant,
                                                ['portal_addons']: newValue
                                                });}}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {tenant['portal_addons']}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        Discount
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Slider
                                            value={tenant['discount']}
                                            min={0}
                                            max={100}
                                            onChange={(event, newValue) => {
                                                setTenant({
                                                ...tenant,
                                                ['discount']: newValue
                                                });}}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {tenant['discount']}%
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        SUBSCRIPTION DETAILS
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span className={classes.message}>Renewal on {tenant.renewal_date}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!tenant.is_monthly && (new Date(tenant.renewal_date).getTime() - new Date().getTime()) / (24*60*60*1000) > 14 &&
                                <Grid item xs={12}>
                                    <span className={classes.error}>Change subscription to monthly will be available 2 weeks before renewal date.</span>
                                </Grid>
                            }
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <h3>ITEM</h3>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h3 className={classes.floatRight}>NO. OF UNITS</h3>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h3 className={classes.floatRight}>UNIT PRICE</h3>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h3 className={classes.floatRight}>TOTAL / {tenant.is_monthly?"MONTH":"YEAR"}</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        {selectedPackage.package_name} - {selectedPackage.description}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>1</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- Companies
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.max_company>-1?selectedPackage.max_company:"Unlimited"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>Included In Package</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {selectedPackage.max_portal !== 0 &&
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- Portals
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.max_portal>-1?selectedPackage.max_portal:"Unlimited"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>Included In Package</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            }
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- ConnectWise Sync
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.is_connect_sync_available?"Opted":"Not Opted"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- Ninja Sync
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.is_ninja_sync_available?"Opted":"Not Opted"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- HR Module
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.is_hr_module_available?"Opted":"Not Opted"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;--- Moneris Module
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{selectedPackage.is_moneris_module_available?"Opted":"Not Opted"}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>$0.00</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        Users
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{tenant['user_addons']}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${(tenant.is_monthly?selectedPackage.monthly_price:selectedPackage.annual_price)}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${Number.parseFloat(tenant['user_addons'] * (tenant.is_monthly?selectedPackage.monthly_price:selectedPackage.annual_price)).toFixed(2)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        Readonly
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{tenant['readonly_addons']}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${(selectedPackage.user_addon_price * (tenant.is_monthly?1:12)).toFixed(2)}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${Number.parseFloat(tenant['readonly_addons'] * selectedPackage.user_addon_price * (tenant.is_monthly?1:12)).toFixed(2)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        Add Portals
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{tenant['portal_addons']}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${(selectedPackage.portal_addon_price * (tenant.is_monthly?1:12)).toFixed(2)}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${Number.parseFloat(tenant['portal_addons'] * selectedPackage.portal_addon_price * (tenant.is_monthly?1:12)).toFixed(2)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>NET TOTAL</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${Number.parseFloat(tenant['user_addons'] * (tenant.is_monthly?selectedPackage.monthly_price:selectedPackage.annual_price)
                                            + tenant['portal_addons'] * selectedPackage.portal_addon_price * (tenant.is_monthly?1:12)).toFixed(2)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>DISCOUNT</span> 
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>{tenant.discount}%</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                            
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>TOTAL</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={classes.floatRight}>${Number.parseFloat((tenant['user_addons'] * (tenant.is_monthly?selectedPackage.monthly_price:selectedPackage.annual_price) 
                                            + tenant['portal_addons'] * selectedPackage.portal_addon_price * (tenant.is_monthly?1:12)) * (100.0-tenant.discount)/100.0).toFixed(2)}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                            }
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={12} lg={4} className={classes.gridRight}>
                    <div className={classes.paymentDiv}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                PAYMENT METHOD
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    fullWidth
                                    variant="outlined"
                                    className={classes.input}
                                    disableUnderline={true}
                                    placeholder={"Card Number"}
                                    value={paymentInfo.card_number}
                                    onChange={(e)=>{
                                        setPaymentInfo(
                                            {...paymentInfo,
                                                card_number:e.target.value
                                            }
                                        );
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "white"
                                        }
                                    }}
                                />
                                <p className={classes.error}>{errors["card_number"]}</p>
                            </Grid>
                            <Grid item xs={12}>
                                EXP (YYMM)
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    variant="outlined"
                                    className={classes.input}
                                    disableUnderline={true}
                                    placeholder={"YYMM"}
                                    value={paymentInfo.expiration}
                                    onChange={(e)=>{
                                        setPaymentInfo(
                                            {...paymentInfo,
                                                expiration:e.target.value
                                            }
                                        );
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "white"
                                        }
                                    }}
                                />
                                <p className={classes.error}>{errors["expiration"]}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.button}
                                    disabled={!hasPermission(props, ['Create/Modify Tenant Billing Info'])}
                                    onClick={(e)=>{
                                        if(paymentInfo && paymentInfo.id){
                                            http.Put(
                                                `tenant_payment_infos/${paymentInfo.id}`,
                                                paymentInfo,
                                                (data) => {
                                                    setSnackBarMessage("Card updated");
                                                    setPaymentInfo(data);
                                                    setPaymentInfoOnFile(data);
                                                    setErrors({});                                                    
                                                    http.Get(
                                                        `process_all_tenant_invoices?tenant_id=${id}`,
                                                        (data) => {
                                                            setSnackBarMessage(data['message']);
                                                        },
                                                        (status, error) => {
                                                            setSeverity("error");
                                                            setSnackBarMessage(JSON.stringify(error));
                                                        }
                                                    );
                                                },
                                                (status, error) => {
                                                    setErrors(error);
                                                }
                                            );

                                        }
                                        else{
                                            http.Post(
                                                `tenant_payment_infos`,
                                                paymentInfo,
                                                (data) => {
                                                    setSnackBarMessage("Card updated");
                                                    setPaymentInfo(data);
                                                    setPaymentInfoOnFile(data);
                                                    setErrors({});                                                    
                                                    http.Get(
                                                        `process_all_tenant_invoices?tenant_id=${id}`,
                                                        (data) => {
                                                            setSnackBarMessage(data['message']);
                                                        },
                                                        (status, error) => {
                                                            setSeverity("error");
                                                            setSnackBarMessage(JSON.stringify(error));
                                                        }
                                                    );
                                                },
                                                (status, error) => {   
                                                    setErrors(error);
                                                }
                                            );
                                        }
                                    }}>
                                        UPDATE CARD
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        {paymentInfoOnFile &&
                                            paymentInfoOnFile.card_number &&
                                            paymentInfoOnFile.card_number.startsWith("3") && (
                                                <img
                                                    className={classes.cardImage}
                                                    src={CcAmex}
                                                />
                                            )}
                                        {paymentInfoOnFile &&
                                            paymentInfoOnFile.card_number &&
                                            paymentInfoOnFile.card_number.startsWith("4") && (
                                                <img
                                                    className={classes.cardImage}
                                                    src={CcVisa}
                                                />
                                            )}
                                        {paymentInfoOnFile &&
                                            paymentInfoOnFile.card_number &&
                                            paymentInfoOnFile.card_number.startsWith("5") && (
                                                <img
                                                    className={classes.cardImage}
                                                    src={CcMaster}
                                                />
                                            )}
                                        {paymentInfo &&
                                            paymentInfo.card_number &&
                                            !paymentInfo.card_number.startsWith("5") && 
                                            !paymentInfo.card_number.startsWith("4") && 
                                            !paymentInfo.card_number.startsWith("3") && (
                                            <img
                                                className={classes.cardImage}
                                                src={CcUnknown}
                                            />
                                            )}
                                    </Grid>
                                    <Grid item xs={7}>
                                        {paymentInfoOnFile &&
                                            paymentInfoOnFile.card_number && (
                                                <span>{paymentInfoOnFile.card_number}</span>
                                            )}<br/>
                                        {paymentInfoOnFile &&
                                            paymentInfoOnFile.expiration && (
                                            <span style={{color:(yearMonth<paymentInfoOnFile.expiration?"green":"red")}}>Exp(YYMM): {paymentInfoOnFile.expiration}</span>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </div>
                </Grid>
            </>
        }
        {!loadData && window.location.pathname.includes("settings/lockout") &&
            <Grid item xs={12} className={classes.gridBottom}>
                POWERED BY SYNCMONKEY
            </Grid>
        }
    </Grid>
  );
};

const mapStateToProps = state => {
    return {
      user: state.user.user
    };
  };
  
export default connect(mapStateToProps, null)(Subscription);
