import React, { useEffect, useState } from "react";
import NotificationForm from "./NotificationForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const tableColumns = [
  {
    id: "tenant_user_name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "tenant_user_company_name",
    label: "COMPANY NAME",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "content",
    label: "CONTENT",
    minWidth: 175,
    sortable: true,
  },
];

const Notifications = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_user_notifications`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((d) => {
            d["tenant_user_name"] =
              d["tenant_user_first_name"] + " " + d["tenant_user_last_name"];
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Notifications"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={NotificationForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Notifications"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Notifications"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={NotificationForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Notifications"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Notifications;
