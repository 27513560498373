import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SoftwareLicenseForm from "./SoftwareLicenseForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import { format } from "date-fns-tz";
import { ConvertDateToLocale } from "../../Common/Utility";
import http from "../../Common/RestAPIHandler";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import { formatDate } from "../../Common/Utility";
import Tooltip from "@material-ui/core/Tooltip";
import { Dialog } from "@material-ui/core";
import SoftwareLicenseDetails from "./SoftwareLicenseDetails";

const SoftwareLicenses = (props) => {
  //const selectedSite = localStorage.getItem("Site");
  //const [site, setSite] = useState(selectedSite);
  const [data, setData] = useState([]);
  const [unchangedData, setUnchangedData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `software_licenses/?company=${props.user.company_id}`;
  const csvHeader = commonConstants.licenseHeader;
  const exportFormat = commonConstants.licenseExportFormat;
  const today = new Date();
  const expirationDate = new Date(
    today.getTime() + localStorage.getItem("notification_days") * 86400000
  );
	const [objectId, setObjectId] = useState();

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "isExpired",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "name",
      label: "SOFTWARE NAME",
      minWidth: 200,
      sortable: true,
			colored: true,
			style: commonConstants.cellCursor,
			onClick: props.mobile ? null : (props, object, property)=>{
			  setObjectId(object.id);
			},
    },
    {
      id: "serial_number",
      label: "SERIAL NUMBER",
      minWidth: 80,
      sortable: true,
    },
    {
      id: "expiration_date",
      label: "EXPIRATION DATE",
      minWidth: 120,
      sortable: true,
    },
    { id: "site_name", label: "LOCATION", minWidth: 150, sortable: true },
    {
      id: "software_asset_tag",
      label: "SOFTWARE ASSET TAG",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "workstation_asset_tag",
      label: "WORKSTATION ASSET TAG",
      minWidth: 100,
      sortable: true,
    },
  ];

  useEffect(() => {
		var showId = new URLSearchParams(window.location.search).get("show_id");
		if(showId){
			setObjectId(showId);
			props.history.replace({ search: window.location.search.replace("&show_id="+showId,"")});
		}

    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        const documents = [];

        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );

        data.forEach((item) => {
          console.log('item', item)

          if (
            item.expiration_date &&
            ConvertDateToLocale(item.expiration_date) < today
          ) {
            item.isExpired = (
              <Tooltip title={"Expired"}>
                <ClearIcon fontSize="inherit"/>
              </Tooltip>
            );
          } else if (
            item.expiration_date &&
            ConvertDateToLocale(item.expiration_date) < expirationDate
          ) {
            let diffTime = Math.abs(new Date(item.expiration_date) - today);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            let day = "";
            if (diffDays > 1) {
              day = " days ";
            } else day = " day ";

            item.isExpired = (
              <Tooltip title={"Expiring in " + diffDays + day}>
                <WarningIcon fontSize="inherit"/>
              </Tooltip>
            );
          }
          item.expiration_date = formatDate(item.expiration_date);
          documents.push(item);
          //return item.site['id'] == site ? documents.push(item) : "";
        });
        setData(documents);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {objectId &&
    <Dialog
      fullWidth
      maxWidth="lg"
      open={Boolean(objectId)}
      onClose={()=>{setObjectId(null);}}>
      <div style={{padding:10}}>
      <SoftwareLicenseDetails 
        {...props} 
        objectId={objectId} 
        handleClose={()=>{
          setObjectId(null);
          if(props.handleClose){
            props.handleClose();
          }
        }}
        onClose={()=>{
          setObjectId(null);
          if(props.handleClose){
            props.handleClose();
          }
        }}
      />
      </div>
    </Dialog>
    }
    {!props.mobile && 
      <ObjectList
        {...props}
        moduleName={"Software Licenses"}
        url={url}
        importUrl={`companies/${props.user.company_id}/import_licenses`}
        csvHeader={csvHeader}
        actions={actions}
        portalPermissions={{
          create: props.user.role.licenses_create,
          edit: props.user.role.licenses_modify,
          delete: props.user.role.licenses_delete,
        }}
        unchangedData={unchangedData}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        objectForm={SoftwareLicenseForm}
        order={"asc"}
        orderBy={"name"}
        searchFields={[
          "name",
          "serial_number",
          "software_asset_tag",
          "workstation_asset_tag",
        ]}
        pageHeader={(props.company.company_name || "") + " | Software Licenses"}
        loadData={loadData}
      />
    }
    {props.mobile && 
      <ObjectListMobile
        {...props}
        moduleName={"Software Licenses"}
        url={url}
        importUrl={`companies/${props.user.company_id}/import_licenses`}
        csvHeader={csvHeader}
        actions={actions}
        portalPermissions={{
          create: props.user.role.licenses_create,
          edit: props.user.role.licenses_modify,
          delete: props.user.role.licenses_delete,
        }}
        unchangedData={unchangedData}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        objectForm={SoftwareLicenseForm}
        order={"asc"}
        orderBy={"name"}
        searchFields={[
          "name",
          "serial_number",
          "software_asset_tag",
          "workstation_asset_tag",
        ]}
        pageHeader={(props.company.company_name || "") + " | Software Licenses"}
        loadData={loadData}
      />
    }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(SoftwareLicenses);
