import React from "react";
import { Grid, Dialog, Select, MenuItem, Tooltip, } from "@material-ui/core";
import DocumentDetails from "./Document/DocumentDetails";
import SiteDetails from "./Sites/SiteDetails";
import EmployeeDetails from "./Employee/EmployeeDetails";
import DeviceDetails from "./Device/DeviceDetails";
import SoftwareLicenseDetails from "./SoftwareLicense/SoftwareLicenseDetails";
import ServiceDetails from "./Service/ServiceDetails";
import JsonDataDetails from "./JsonData/JsonDataDetails";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import http from "../Common/RestAPIHandler";
import { menus } from "../Common/Layout/MenuList";
import useStyles from "../Common/Style/FormStyle";


const DataMappings = (props) => {
    const classes = useStyles();
    const [dataMapping, setDataMapping] = React.useState();
    const [models, setModels] = React.useState();
    const [selectedModel, setSelectedModel] = React.useState();
    const [modelDatas, setModelDatas] = React.useState();
    const [modelIcons, setModelIcons] = React.useState();

    React.useEffect(() => {
      http.Get(
        `json_schemas?tenant=${props.user.tenant.id}`,
        (data) => {
          var valueMap = {
            "Site":"Site",
            "Document":"Document",
            "Employee":"Employee",
            "Device":"Device",
            "License":"License",
            "Service":"Service",
          };
          var iconMap = {};
          for (let i = 0; i < data.length; i++) {
            valueMap[data[i].name + "_json_schema_" + data[i].id] = data[i].name;
            iconMap[data[i].name + "_json_schema_" + data[i].id] = data[i].icon;
          }
          setModels(valueMap);
          setModelIcons(iconMap);
        },
        () => {}
      );

    }, []);

    React.useEffect(() => {
      setModelDatas(null);
      if(selectedModel === "Site"){
        http.Get(
          `companies/${props.user.company_id}/sites`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].site_name;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel === "Document"){
        http.Get(
          `company_documents?company=${props.user.company_id}`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].title;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel === "Employee"){
        http.Get(
          `company_employees?company_id=${props.user.company_id}`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].employee_name;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel === "Device"){
        http.Get(
          `companies/${props.user.company_id}/devices`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].device_name;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel === "License"){
        http.Get(
          `software_licenses/?company=${props.user.company_id}`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].name;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel === "Service"){
        http.Get(
          `companies/${props.user.company_id}/services`,
          (data) => {
            var valueMap = {};
            for (let i = 0; i < data.length; i++) {
              valueMap[data[i].id] = data[i].service_name;
            }
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else if(selectedModel && selectedModel.includes("_json_schema_")){
        http.Get(
          `json_datas?company=${props.user.company_id}&json_schema=${selectedModel.split("_json_schema_")[1]}`,
          (data) => {
            var valueMap = {};
            data.map((json_data)=>{
              valueMap[json_data.id] = json_data.name;
            });
            setModelDatas(valueMap);
          },
          () => {}
        );
      }
      else{
        setModelDatas({});
      }
    }, [selectedModel]);
  
    return (
        <>
        {props.setDataMappings &&
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Select
              fullWidth
              variant="outlined"
              margin="dense"
              value={selectedModel}
              onChange={(e) => {
                setSelectedModel(e.target.value);
              }}
            >
              {models && Object.entries(models).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={8}>
          <Select
            fullWidth
            variant="outlined"
            margin="dense"
          >
            {!modelDatas &&
              <MenuItem
                disabled>
                Loading...
              </MenuItem>
            }
            {modelDatas && Object.entries(modelDatas).map(([key, value]) => {
              if(key!==props.fromId && props.dataMappings && !props.dataMappings.some((dataMapping)=>{return dataMapping.to_id===key;})){
                return (
                  <MenuItem key={key} value={key}
                    onClick={(e) => {
                      var newDataMappings = props.dataMappings.filter((newDataMapping)=>{
                        return (newDataMapping.to_name && newDataMapping.to_name!=="");
                      });
                      newDataMappings.push({company:props.user.company_id,from_id:props.fromId,from_model:props.fromModel,to_id:key,to_model:selectedModel,to_name:value});
                      props.setDataMappings(newDataMappings);
                    }}>
                    {value}
                  </MenuItem>);
              }
            })}
          </Select>
        </Grid>
        </Grid>
        }
        {props.dataMappings.sort((a,b)=>(a.to_name.toLowerCase() > b.to_name.toLowerCase()) ? 1 : ((a.to_name.toLowerCase() < b.to_name.toLowerCase() ? -1 : 0))).map((dataMapping)=>{
          if(dataMapping.to_name && dataMapping.to_name!==""){
            return (
              <Grid container spacing={1}>
                <Grid item xs={11}>                  
                  {!dataMapping.to_model.includes("_json_schema_") && menus.companies.map((companyMenu)=>{
                    if(companyMenu.name.includes(dataMapping.to_model)){
                      return (<Tooltip title={dataMapping.to_model}><companyMenu.icon style={{verticalAlign: "text-bottom"}}/></Tooltip>);
                    }
                  })}
                  {dataMapping.to_model.includes("_json_schema_") && modelIcons && modelIcons[dataMapping.to_model] &&
                    <Tooltip title={dataMapping.to_model.split("_json_schema_")[0]}>
                    <img
                      style={{maxWidth:"20px",maxHeigth:"20px",verticalAlign: "text-bottom"}}
                      alt={dataMapping.to_model}
                      src={modelIcons[dataMapping.to_model]}
                    />
                    </Tooltip>
                  }
                  <span
                    className={`${classes.link} ${classes.spacingLH}`}
                    onClick={(event) =>{
                      setDataMapping(dataMapping);
                    }}>{dataMapping.to_name}</span>
                </Grid>
                <Grid item xs={1} className={classes.endIcons}>
                  {props.setDataMappings && 
                    <HighlightOffIcon
                      className={classes.iconButtonColored}
                      style={{cursor:"pointer"}}
                      onClick={(e) => {
                        var newDataMappings = props.dataMappings.filter((newDataMapping)=>{
                          return (newDataMapping.to_name && newDataMapping.to_name!=="" && newDataMapping.to_id!==dataMapping.to_id);
                        });
                        props.setDataMappings(newDataMappings);
                      }}/>
                  }
                </Grid>
              </Grid>);
          }
        })}
        {dataMapping && dataMapping.to_model === "Document" &&
          <Dialog
            fullWidth
            maxWidth="xl"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <DocumentDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model === "Site" &&
          <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <SiteDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model === "Employee" &&
          <Dialog
            fullWidth
            maxWidth="xl"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <EmployeeDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model === "Device" &&
          <Dialog
            fullWidth
            maxWidth="xl"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <DeviceDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model === "License" &&
          <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <SoftwareLicenseDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model === "Service" &&
          <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <ServiceDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        {dataMapping && dataMapping.to_model.includes("_json_schema_") &&
          <Dialog
            fullWidth
            maxWidth="lg"
            open={Boolean(dataMapping)}
            onClose={()=>{
              setDataMapping(null);
              if(props.handleClose){
                props.handleClose();
              }
            }}>
            <div style={{padding:10}}>
              <JsonDataDetails 
                {...props} 
                objectId={dataMapping.to_id} 
                handleClose={()=>{
                  setDataMapping(null);
                  if(props.handleClose){
                    props.handleClose();
                  }
                }}
                onClose={()=>{
                  setDataMapping(null);
                }}
              />
            </div>
          </Dialog>
        }
        </>
    );
};

export default DataMappings;