import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import DateFnsUtils from "@date-io/date-fns";
import AlertDialog from "../../Common/AlertDialog";
import ProfileLogo from "../../../Images/profile_logo.png";
import HRMeetings from "./HRMeetings";
import HRFiles from "./HRFiles";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Link from "@material-ui/core/Link";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ScreenLoader from "../../Common/Loader";
import { Paper } from "@material-ui/core";
import SnackbarComponent from "../../Common/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import HistoryIcon from "@material-ui/icons/History";
import { Dialog } from "@material-ui/core";
import HRSalaryHistory from "./HRSalaryHistory"

const HRForm = (props) => {
  const classes = useStyles();
  const [mapUser, setMapUser] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [birthday, setBirthday] = useState(new Date(1970, 0, 1));
  const [active, setActive] = useState(true);
  const today = new Date();

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [hr, setHr] = useState({
    name: "",
    email: "",
    designation: "",
    status: true,
    tenant: props.user.tenant,
    joining_date: formatDate(today),
    resignation_date: formatDate(today),
    birth_date: formatDate(today),
	reports_to: null,
	department: null,
  });
  const [hrError, setHrError] = useState({});
  const { requiredError, blankError } = commonConstants.error;

  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [invalidBirthDate, setInvalidBirthDate] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isTenantUser, setIsTenantUser] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [loadData, setLoadData] = useState(false);
  const [showSin, setShowSin] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [severity, setSeverity] = useState("success");
  const [contacts, setContacts] = useState([]);

  const [tenantLocations, setTenantLocations] = useState([]);
  const [reportToOptions, setReportToOptions] = useState([]);
  const [reportsToObj, setReportsToObj] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentObj, setDepartmentObj] = useState(null);

  const [currSalaryStartDate, setCurrSalaryStartDate] = useState(null);
  const [isInvalidSalaryDate, setIsInvalidSalaryDate] = useState(false);
  const [isExistingSalaryDate, setIsExistingSalaryDate] = useState(false);
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [salary, setSalary] = useState({
	  amount: "",
	  is_hourly: false,
	  start_date: new Date(),
	  hr_employee: null,
	  id: null,
  });
  const [salaryError, setSalaryError] = useState({});
  const [showSalaryHistory, setShowSalaryHistory] = useState(null);

  // Called when HRForm component is first loaded.
  useEffect(() => {
	setLoadData(true);
    if (props.edit && props.objectId) {
      http.Get(
        `hr_employees/${props.objectId}/`,
        (hr) => {
          setHr(hr);
          setStartDate(
            new Date(
              new Date(hr.joining_date).getTime() +
                new Date(hr.joining_date).getTimezoneOffset() * 60 * 1000
            )
          );
		  if(!hr.status){
			setEndDate(
			  new Date(
				new Date(hr.resignation_date).getTime() +
				  new Date(hr.resignation_date).getTimezoneOffset() * 60 * 1000
			  )
			);
		  }

          setBirthday(
            new Date(
              new Date(hr.birth_date).getTime() +
                new Date(hr.birth_date).getTimezoneOffset() * 60 * 1000
            )
          );
          setActive(hr.status);

          if (hr.tenant_user) {
            http.Get(
              `tenant_users/${hr.tenant_user}`,
              (tu) => {
                setProfileImage(tu.profile_image_src);
              },
              () => {}
            );
          }

          http.Get(
            `hr_employee_contacts?hr_employee=${props.objectId}`,
            (data) => {
              setContacts(data);
            },
            () => {}
          );

		  // Get salary with most recent start date.
		  http.Get(
			  `hr_employee_salaries?hr_employee=${props.objectId}`,
			  (salaries) => {
				  // Check if any salaries retrieved.
				  // Salaries are in descending order by start_date.
				  // If there are salaries, get data from first one on list.
				  if (salaries.length > 0) {
					  // Formatted date of salary. Format should match dates from KeyboardDatePicker component.
					  const date = new Date(
						  new Date(salaries[0].start_date).getTime() +
						  new Date(salaries[0].start_date).getTimezoneOffset() * 60 * 1000
						  );
					  setSalary({
							amount: salaries[0].amount,
							is_hourly: salaries[0].is_hourly,
							id: salaries[0].id,
							start_date: date,
							hr_employee: salaries[0].hr_employee
						});
					  // Save salary's start date in case user changes it.
					  setCurrSalaryStartDate(date);
					  // Save other salary records to view in HRSalaryHistory component
					  setSalaryHistory(salaries.length > 1 ? salaries.slice(1) : null);
			      }
			  },
			  () => {}
		  );
        },
        () => {
          setLoadData(false);
        }
      );
    }

	// Get all locations under user's tenant for dropdown.
	// For each location, create label in format [ADDRESS, CITY, PROVINCE/STATE POSTAL_CODE]
	http.Get(
		`tenant_address`,
		(data) => {
			data.map((location) => location.label = [location.address, location.city, location.state + ' ' + location.postal].join(', '));
			setTenantLocations(data);
		},
		() => {
			setLoadData(false);
		}
	);
	// Get all tenant users for Report To dropdown. Can be changed later to retrieve options from `hr_employees/` endpoint.
	http.Get(
		`tenant_users`,
		(data) => {
			// Process data by 1) remove employee being edited as option 2) Create objects, containing id and label keys, to be accepted as AutoComplete options.
			const users = data.filter(user => user.id !== props.user.id).map((user) => {return {id: user.id, label: user.first_name + ' ' + user.last_name};});
			// Sort options based on label.
			users.sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0)));
			// Set Report To Options.
			setReportToOptions(users);
		},
		() => {
			setLoadData(false);
		}
	);
	http.Get(
		`hr_employees/`,
		(data) => {
			// Create array of options for Department dropdown. Steps:
			// 1) Filter for non-null department values
			// 2) Extract each department value
			// 3) Make array contain unique values only.
			const departments = [...new Set([...data].filter((user) => user.department).map((user) => user.department))];
			// Sort options based on department name.
			departments.sort((a,b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : ((a.toLowerCase() < b.toLowerCase() ? -1 : 0)));
			// Create objects of each name. AutoComplete options only accept a list of objects.
			const departmentObjects = departments.map((name) => {return {value: name}});
			// Set Department options.
			setDepartmentOptions(departmentObjects);
		},
		() => {
			setLoadData(false);
		}
	);
	setLoadData(false);
  }, []);

  // After Report To options created, prefill Report To AutoComplete field if employee has a Reports To foreign key
  useEffect(() => {
	  if (props.edit && hr.reports_to) {
		  // Retrieve actual object from options list, not make new object.
		  const user = reportToOptions.find((user) => user.id === hr.reports_to)
		  setReportsToObj(user);
	  }
  }, [reportToOptions]);

  // After Department Options created, prefill Department AutoComplete field
  useEffect(() => {
	if (props.edit && hr.department) {
		// Retrieve actual object from options list, not make new object.
		const department = departmentOptions.find((dep) => dep.value === hr.department)
		setDepartmentObj(department);
	}
  }, [departmentOptions]);

  // When an option is selected from Reports To AutoComplete, set reports_to value in hr state object 
  useEffect(() => {
	  setHr({...hr, reports_to: reportsToObj ? reportsToObj.id : null});
  }, [reportsToObj]);

  // If an option is selected from Reports To AutoComplete, set department value in hr state object 
  useEffect(() => {
	setHr({...hr, department: departmentObj ? departmentObj.value : null});
  }, [departmentObj]);

  // After deleting a salary in HRSalaryHistory component, refresh salary history.
  const fetchSalaries = () => {
	http.Get(
		`hr_employee_salaries?hr_employee=${props.objectId}`,
		(salaries) => {
			setSalaryHistory(salaries.length > 1 ? salaries.slice(1) : null);
		},
		() => {}
	);
  };

  // Helps set hr and hrError values when values for input fields change.
  const onChange = (e) => {
    setHr({
      ...hr,
      [e.target.name]: e.target.value,
    });
    setHrError({
      ...hrError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
    setIsInvalidDate(false);
  };

  // Creates POST request to save a new HREmployee.
  const post = (data) => {
    http.Post(
      `hr_employees/`,
      data,
      (savedHr) => {
        contacts.map((contact) => {
          http.Post(
            `hr_employee_contacts`,
            {
              name: contact.name,
              relation: contact.relation,
              phone: contact.phone,
              hr_employee: savedHr.id,
            },
            (result) => {},
            (status, error) => {}
          );
        });
        if (hr.contact_name || hr.contact_relation || hr.contact_phone) {
          http.Post(
            `hr_employee_contacts`,
            {
              name: hr.contact_name,
              relation: hr.contact_relation,
              phone: hr.contact_phone,
              hr_employee: savedHr.id,
            },
            (result) => {},
            (status, error) => {}
          );
        }
		// Send POST request to create new salary record if values for both amount and start date exist.
		// Otherwise submit action ends and form closes.
		if (salary.amount && salary.start_date) postSalary(savedHr.id);
        else props.handleObjectSubmit();
      },
      (status, error) => {
        if (status === 400) setHrError(error);
        setIsSubmitted(false);
      }
    );
  };

  // Handles submit action.
  const onSubmit = (e) => {
	// Checks dates before submission begins.
    if (birthday >= today) {
      setInvalidBirthDate(true);
      return;
    }

    setIsInvalidDate(false);
    if (birthday >= startDate) {
      setIsInvalidDate(true);
      return;
    }

    setIsInvalidDate(false);
    if (!active && startDate >= endDate) {
      setIsInvalidDate(true);
      return;
    }

	// If validation fails for salary start date, error messages should pop up and submission cancels.
	setIsInvalidDate(false);
	if (salary.start_date) {
		// Checks if salary's start date is on or after employee's joining date.
		// Or if former employee, checks if date is before or on resignation_date.
		if (salary.start_date < startDate || (!active && salary.start_date > endDate)) {
			setIsInvalidSalaryDate(true);
			return;
		}
		// Checks if salary record for entered start date is unique (must be unique).
		if (salaryHistory && salaryHistory.some((sal) => sal.start_date === formatDate(salary.start_date))) {
			setIsExistingSalaryDate(true);
			return;
		}
	}

    var input = {
      ...hr,
      joining_date: formatDate(startDate),
      resignation_date: formatDate(endDate),
      birth_date: formatDate(birthday),
    };
    const emailField = {
      email: hr.email,
    };

	// Actual submission process begins.
    e.preventDefault();
    setIsSubmitted(true);
    if (props.edit && props.objectId) {
	  // Update existing employee.
      http.Put(
        `hr_employees/${props.objectId}/`,
        input,
        (savedHr) => {
          if (hr.contact_name || hr.contact_relation || hr.contact_phone) {
            http.Post(
              `hr_employee_contacts`,
              {
                name: hr.contact_name,
                relation: hr.contact_relation,
                phone: hr.contact_phone,
                hr_employee: savedHr.id,
              },
              (result) => {},
              (status, error) => {}
            );
          }
		  // Checks if salary amount and start date exist on form.
		  // Otherwise submit action ends and form closes.
		  if (salary.amount && salary.start_date) {
			  // If form's start date the same as before, simply update existing salary record.
			  // Otherwise create a new salary record based on new start date entered. 
			  if (salary.id && salary.start_date === currSalaryStartDate) putSalary(salary.id);
			  else postSalary(hr.id);
		  }
		  else props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setHrError(error);
          setIsSubmitted(false);
        }
      );
    } else {
	  // Create new employee with email.
      http.Post(
        `tenant_user_by_email/`,
        emailField,
        (result) => {
          setMapUser(result.id);
          setIsTenantUser(true);
		  if (salary.amount && salary.start_date) postSalary(result.id);
        },
        (status, error) => {
          post(input);
        }
      );
    }
  };

  // Create new salary record for employee with id 'hr_employee_id'.
  const postSalary = (hr_employee_id) => {
	  http.Post(
		  `hr_employee_salaries`,
		  {
			  amount: salary.amount,
			  is_hourly: salary.is_hourly,
			  start_date: formatDate(salary.start_date), // Format date in request body for db.
			  hr_employee: hr_employee_id
		  },
		  (result) => {
			// End submit action and close form.
			props.handleObjectSubmit();
		  },
		  (status, error) => {
			// Set error messages returned if status code is 400.
			if (status === 400) setSalaryError(error);
			setIsSubmitted(false);
		  }
	  );
  };

  // Update existing salary record.
  const putSalary = (salary_id) => {
	  http.Put(
		  `hr_employee_salaries/${salary_id}`,
		  {
			...salary,
			start_date: formatDate(salary.start_date) // Format date in request body for db.
		  },
		  (result) => {
			// End submit action and close form.
			props.handleObjectSubmit();
		  },
		  (status, error) => {
			// Set error messages returned if status code is 400.
			if (status === 400) setSalaryError(error);
			setIsSubmitted(false);
		  }
	  )
  }

  const handleMapAgree = () => {
    var input = {
      ...hr,
      joining_date: formatDate(startDate),
      resignation_date: formatDate(endDate),
      birth_date: formatDate(birthday),
      tenant_user: mapUser,
    };
    post(input);
    setIsTenantUser(false);
  };

  const handleMapDisagree = () => {
    var input = {
      ...hr,
      joining_date: formatDate(startDate),
      resignation_date: formatDate(endDate),
      birth_date: formatDate(birthday),
    };
    post(input);
    setIsTenantUser(false);
  };

  return (
    <div style={{ width: "calc(100vw - 140px)" }}>
      <a id="top"></a>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={severity}
      ></SnackbarComponent>
      <Paper
        elevation={3}
        style={{
          position: "fixed",
          top: props.trialNotice ? "185px" : "130px",
          right: "0px",
          fontSize: "80%",
          padding: "10px",
        }}
      >
        <Link href="#top" className={classes.link}>
          TOP
        </Link>
        <br />
        <br />
        <Link href="#contacts" className={classes.link}>
          CONTACTS
        </Link>
        <br />
        <br />
        <Link href="#meetings" className={classes.link}>
          MEETINGS
        </Link>
        <br />
        <br />
        <Link href="#attachments" className={classes.link}>
          ATTACHMENTS
        </Link>
        <br />
        <br />
        <Link
          href="#top"
          className={classes.link}
          onClick={() => {
            setSnackBarMessage(
              "Preparing data to export. Download will begin automatically shortly."
            );
            setSeverity("success");
            http.ExportToPdf(
              `hr_employee/export_pdf`,
              { offset: 0, hr_id: props.objectId },
              (blob) => {
                //window.open(window.URL.createObjectURL(blob));
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = "hr.pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              },
              () => {
                setSnackBarMessage("Error occured in export to pdf.");
                setSeverity("error");
              }
            );
          }}
        >
          PRINT
        </Link>
        <br />
        <br />
      </Paper>
      {isTenantUser && (
        <AlertDialog
          open={isTenantUser}
          primaryMessage="Would you like to map this HR Employee to existing Tenant User?"
          secondaryMessage={
            "Agreeing will map this HR Employee to Tenant User " + hr.email
          }
          onAgree={handleMapAgree}
          onDisagree={handleMapDisagree}
        />
      )}
	  {showSalaryHistory && (
		  <Dialog
		  fullWidth
		  maxWidth="lg"
		  open={Boolean(showSalaryHistory)}
		  onClose={()=>{setShowSalaryHistory(null);}}>
			  <div style={{padding:10}}>
				<HRSalaryHistory 
					{...props} 
					objectId={0}
					salaryHistory={salaryHistory}
					fetchSalaries={fetchSalaries}
					handleClose={()=>{
						setShowSalaryHistory(null);
						if(props.handleClose){
							props.handleClose();
						}
					}}
					onClose={()=>{
						setShowSalaryHistory(null);
					}}
				/>
			  </div>
		  </Dialog>
	  )}
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9}>
            <span className={classes.title}>
              {props.pageHeader}
              {props.edit ? hr.first_name + " " + hr.last_name : "New"}
            </span>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      {loadData ? (
        <ScreenLoader />
      ) : (
        <div>
          <div>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={5} sm={4} md={3} lg={2}>
                {profileImage && (
                  <img width="100px" alt="profileImage" src={profileImage} />
                )}
                {!profileImage && (
                  <img width="100px" alt="default" src={ProfileLogo} />
                )}
              </Grid>
              <Grid item xs={7} sm={8} md={9} lg={10}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <div style={{ fontSize: "210%" }}>
                      <span className={classes.title}>
                        {hr.first_name} {hr.last_name}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <span className={classes.subText}>
                      Age:{" "}
                      {today.getFullYear() -
                        new Date(birthday).getFullYear()}
                      &nbsp;&nbsp;&nbsp;&nbsp; Title:{" "}{hr.designation}
                      &nbsp;&nbsp;&nbsp;&nbsp; Employment Duration:{" "}
                      {startDate &&
                        endDate &&
                        Math.floor(
                          (new Date(endDate).getFullYear() * 12 +
                            new Date(endDate).getMonth() -
                            new Date(startDate).getFullYear() * 12 -
                            new Date(startDate).getMonth()) /
                            12
                        ) +
                          "yrs " +
                          ((new Date(endDate).getFullYear() * 12 +
                            new Date(endDate).getMonth() -
                            new Date(startDate).getFullYear() * 12 -
                            new Date(startDate).getMonth()) %
                            12) +
                          "mos "}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <br />

          <div style={{ fontWeight: 420 }}>
            <span className={classes.title}>EMPLOYEE INFOMATION</span>
          </div>
          <Divider />
          <br />

					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ValidatorForm onSubmit={onSubmit}>
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>NAME</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="first_name"
												fullWidth
												placeholder="First Name *"
												variant="outlined"
												margin="dense"
												name="first_name"
												value={hr.first_name || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												validators={["required", "trim"]}
												errorMessages={[requiredError, blankError]}
												error={Boolean(hrError.first_name)}
												helperText={hrError.first_name && hrError.first_name[0]}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="middle_name"
												fullWidth
												placeholder="Middle Name"
												variant="outlined"
												margin="dense"
												name="middle_name"
												value={hr.middle_name || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												error={Boolean(hrError.middle_name)}
												helperText={
													hrError.middle_name && hrError.middle_name[0]
												}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="last_name"
												fullWidth
												placeholder="Last Name *"
												variant="outlined"
												margin="dense"
												name="last_name"
												value={hr.last_name || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												validators={["required", "trim"]}
												errorMessages={[requiredError, blankError]}
												error={Boolean(hrError.last_name)}
												helperText={hrError.last_name && hrError.last_name[0]}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>ADDRESS</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="address_street"
												fullWidth
												placeholder="Street *"
												variant="outlined"
												margin="dense"
												name="address_street"
												value={hr.address_street || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												validators={["required", "trim"]}
												errorMessages={[requiredError, blankError]}
												error={Boolean(hrError.address_street)}
												helperText={
													hrError.address_street && hrError.address_street[0]
												}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={6} className={classes.gridItem}>
													<TextValidator
														id="address_city"
														fullWidth
														placeholder="City *"
														variant="outlined"
														margin="dense"
														name="address_city"
														value={hr.address_city || ""}
														onChange={onChange}
														inputProps={{ maxLength: 255 }}
														validators={["required", "trim"]}
														errorMessages={[requiredError, blankError]}
														error={Boolean(hrError.address_city)}
														helperText={
															hrError.address_city && hrError.address_city[0]
														}
													/>
												</Grid>
												<Grid item xs={6} className={classes.gridItem}>
													<TextValidator
														id="address_state"
														fullWidth
														placeholder="Province *"
														variant="outlined"
														margin="dense"
														name="address_state"
														value={hr.address_state || ""}
														onChange={onChange}
														inputProps={{ maxLength: 255 }}
														validators={["required", "trim"]}
														errorMessages={[requiredError, blankError]}
														error={Boolean(hrError.address_state)}
														helperText={
															hrError.address_state && hrError.address_state[0]
														}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<Grid container spacing={1} alignItems="center">
												<Grid item xs={6} className={classes.gridItem}>
													<TextValidator
														id="address_postal"
														fullWidth
														placeholder="Postal *"
														variant="outlined"
														margin="dense"
														name="address_postal"
														value={hr.address_postal || ""}
														onChange={onChange}
														inputProps={{ maxLength: 255 }}
														validators={["required", "trim"]}
														errorMessages={[requiredError, blankError]}
														error={Boolean(hrError.address_postal)}
														helperText={
															hrError.address_postal &&
															hrError.address_postal[0]
														}
													/>
												</Grid>
												<Grid item xs={6} className={classes.gridItem}>
													<TextValidator
														id="address_country"
														fullWidth
														placeholder="Country *"
														variant="outlined"
														margin="dense"
														name="address_country"
														value={hr.address_country || ""}
														onChange={onChange}
														inputProps={{ maxLength: 255 }}
														validators={["required", "trim"]}
														errorMessages={[requiredError, blankError]}
														error={Boolean(hrError.address_country)}
														helperText={
															hrError.address_country &&
															hrError.address_country[0]
														}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>PHONE</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="mobile"
												fullWidth
												placeholder="Phone Number *"
												variant="outlined"
												margin="dense"
												name="mobile"
												value={hr.mobile || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												validators={["required", "trim"]}
												errorMessages={[requiredError, blankError]}
												error={Boolean(hrError.mobile)}
												helperText={hrError.mobile && hrError.mobile[0]}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="phone"
												fullWidth
												placeholder="Alt. Phone Number"
												variant="outlined"
												margin="dense"
												name="phone"
												value={hr.phone || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												error={Boolean(hrError.phone)}
												helperText={hrError.phone && hrError.phone[0]}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>EMAIL</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="email"
												fullWidth
												placeholder="Email *"
												variant="outlined"
												margin="dense"
												name="email"
												value={hr.email || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												error={Boolean(hrError.email)}
												helperText={hrError.email && hrError.email[0]}
												validators={["required", "trim"]}
												errorMessages={[requiredError, blankError]}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>BIRTHDAY</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<KeyboardDatePicker
												fullWidth
												disableToolbar
												autoOk
												variant="inline"
												inputVariant="outlined"
												format="dd/MM/yyyy"
												placeholder="dd/mm/yyyy"
												margin="dense"
												id="birth_date"
												name="birth_date"
												value={birthday}
												onChange={(value) => {
													setBirthday(value);
													setIsInvalidDate(false);
													setInvalidBirthDate(false);
												}}
												maxDate={today}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
												error={
													Boolean(hrError.birth_date) ||
													isInvalidDate ||
													invalidBirthDate
												}
												helperText={
													(hrError.birth_date && hrError.birth_date[0]) ||
													(invalidBirthDate && "Invalid birth date.") ||
													(isInvalidDate &&
														"Start date cannot be before birthday.")
												}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>SIN</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												type={showSin ? "text" : "password"}
												id="sin"
												fullWidth
												placeholder="SIN"
												variant="outlined"
												margin="dense"
												name="sin"
												value={hr.sin || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												error={Boolean(hrError.sin)}
												helperText={hrError.sin && hrError.sin[0]}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle sin visibility"
																onClick={() => {
																	setShowSin(!showSin);
																}}
															>
																{showSin ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>TITLE</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="designation"
												fullWidth
												placeholder="Title *"
												variant="outlined"
												margin="dense"
												name="designation"
												value={hr.designation || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
												error={Boolean(hrError.designation)}
												helperText={
													hrError.designation && hrError.designation[0]
												}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>DEPARTMENT</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<Autocomplete
												id="department"
												name="department"
												freeSolo
												filterSelectedOptions
												classes={{
													option: classes.option,
													popper: classes.popper,
												}}
												options={departmentOptions}
												value={departmentObj}
												onChange={(e, value) => {
													setDepartmentObj(value);
													setIsSubmitted(false);
													setIsInvalidDate(false);
												}}
												onInputChange={(e, value) => {
													const option = departmentOptions.find((entry) => entry.value === value.trim());
													if (option) return setDepartmentObj(option);
													else setHr({...hr, department: value ? value.trim() : null});
													setIsSubmitted(false);
													setIsInvalidDate(false);
												}}
												getOptionLabel={(option) => option.value}
												renderOption={(option) => <span>{option.value}</span>}
												renderInput={(params) => (
													<TextField
													{...params}
													variant="outlined"
													fullWidth
													margin="dense"
													placeholder="Department"
													/>
												)}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>LOCATION</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="location"
												fullWidth
												select
												variant="outlined"
												margin="dense"
												name="location"
												value={hr.location || ''}
												onChange={onChange}
											>
												{tenantLocations && tenantLocations.length === 0 ? (
													<MenuItem key={0} value={""}>
														No locations found.
													</MenuItem>
												) : (
													tenantLocations.map((location) => (
														<MenuItem key={location.id} value={location.id}>
															{location.label}
														</MenuItem>
													))
												)}
											</TextValidator>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
										<Grid item xs={4} className={classes.gridItem}></Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>REPORTS TO</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
								<Grid container spacing={1} alignItems="center">
									<Grid item xs={4} className={classes.gridItem}>
										<Autocomplete
											id="reports_to"
											name="reports_to"
											filterSelectedOptions
											classes={{
												option: classes.option,
												popper: classes.popper,
											}}
											options={reportToOptions}
											value={reportsToObj}
											onChange={(e, value) => {
												setReportsToObj(value);
												setIsSubmitted(false);
												setIsInvalidDate(false);
											}}
											getOptionLabel={(option) => option.label}
											renderOption={(option) => <span>{option.label}</span>}
											renderInput={(params) => (
												<TextField
												{...params}
												variant="outlined"
												fullWidth
												margin="dense"
												placeholder="Reports to"
												/>
											)}
										/>
									</Grid>
									<Grid item xs={4} className={classes.gridItem}></Grid>
									<Grid item xs={4} className={classes.gridItem}></Grid>
								</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>SALARY</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<CurrencyTextField
												id="amount"
												name="amount"
												placeholder="Amount"
												value={salary.amount || ""}
												currencySymbol="$"
												minimumValue="0"
												outputFormat="string"
												variant="outlined"
												fullWidth
												margin="dense"
												onChange={(e, value) => {
													setSalary({...salary, amount: value});
													setIsSubmitted(false);
													setIsInvalidSalaryDate(false);
												}}
											
											/>
										</Grid>
										<Grid item xs={2} align="left">
											<FormControlLabel
												control={
												<Radio
													name="is_hourly"
													checked={salary.is_hourly}
													onClick={() => {
														setSalary({...salary, is_hourly: !salary.is_hourly});
														setIsSubmitted(false);
													}}
													color="primary"
												/>
												}
												label="IS HOURLY"
												classes={{ label: classes.headOffice }}
												labelPlacement="end"
											/>
										</Grid>
										<Grid item xs={2} align="right">
											<div style={{ fontWeight: 520 }}>
												<span className={classes.title}>SALARY START DATE</span>
											</div>
										</Grid>
										<Grid item xs={3} className={classes.gridItem}>
											<KeyboardDatePicker
												fullWidth
												disableToolbar
												autoOk
												variant="inline"
												inputVariant="outlined"
												format="dd/MM/yyyy"
												placeholder="dd/mm/yyyy"
												margin="dense"
												id="start_date"
												name="start_date"
												value={salary.start_date}
												onChange={(value) => {
													setSalary({...salary, start_date: value});
													setSalaryError({...salaryError, start_date: false});
													setIsInvalidSalaryDate(false);
													setIsExistingSalaryDate(false);
												}}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
												error={Boolean(salaryError.start_date) ||
															   isInvalidSalaryDate ||
															   isExistingSalaryDate}
												helperText={
													(salaryError.start_date && salaryError.start_date[0]) ||
													(isInvalidSalaryDate && "Invalid salary date.") ||
													(isExistingSalaryDate && "Salary record for this date already exists.")
												}
											/>
										</Grid>
										<Grid item xs={1} className={classes.gridItem}>
											<HistoryIcon
												onClick={(e) => setShowSalaryHistory(true)}
												className={classes.iconButtonColored}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>START / END DATE</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<KeyboardDatePicker
												fullWidth
												autoOk
												disableToolbar
												variant="inline"
												inputVariant="outlined"
												format="dd/MM/yyyy"
												margin="dense"
												name="joining_date"
												value={startDate}
												onChange={(value) => {
													setStartDate(value);
													setIsInvalidDate(false);
													setIsInvalidSalaryDate(false);
													setIsExistingSalaryDate(false);
												}}
												maxDate={today}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
												style={{
													textAlign: "center",
												}}
												error={Boolean(hrError.joining_date) || isInvalidDate}
												helperText={
													(hrError.joining_date && hrError.joining_date[0]) ||
													(isInvalidDate &&
														"Start date cannot be before birthday.")
												}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											{!active && (
												<KeyboardDatePicker
													fullWidth
													autoOk
													disableToolbar
													variant="inline"
													inputVariant="outlined"
													format="dd/MM/yyyy"
													margin="dense"
													name="resignation_date"
													value={endDate}
													onChange={(value) => {
														setEndDate(value);
														setIsInvalidDate(false);
														setIsInvalidSalaryDate(false);
														setIsExistingSalaryDate(false);
													}}
													maxDate={today}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													style={{
														textAlign: "center",
													}}
													error={
														Boolean(hrError.resignation_date) || isInvalidDate
													}
													helperText={
														(hrError.resignation_date &&
															hrError.resignation_date[0]) ||
														(isInvalidDate &&
															"End date cannot be before birthday.")
													}
												/>
											)}
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												id="status"
												fullWidth
												select
												variant="outlined"
												margin="dense"
												name="status"
												value={hr.status}
												onChange={(e) => {
													onChange(e);
													setActive(e.target.value);
												}}
											>
												<MenuItem key={1} value={true} selected>
													Active
												</MenuItem>
												<MenuItem key={2} value={false}>
													Disabled
												</MenuItem>
											</TextValidator>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<a id="contacts"></a>
							<br />
							<div style={{ fontWeight: 420 }}>
								<span className={classes.title}>EMERGENCY CONTACTS</span>
							</div>
							<Divider />
							<br />
							{contacts &&
								contacts.map((contact) => {
									return (
										<Grid container spacing={3} alignItems="center">
											<Grid item xs={2} align="right">
												<div style={{ fontWeight: 520 }}>
													<span className={classes.title}>CONTACT</span>
												</div>
											</Grid>
											<Grid item xs={10} className={classes.gridItem}>
												<Grid container spacing={1} alignItems="center">
													<Grid item xs={4} className={classes.gridItem}>
														<TextValidator
															fullWidth
															placeholder="Name"
															variant="outlined"
															margin="dense"
															name="name"
															value={contact.name || ""}
															onChange={(e) => {
																if (props.objectId) {
																	contact.name = e.target.value;
																	http.Put(
																		`hr_employee_contacts/${contact.id}`,
																		contact,
																		(result) => {
																			http.Get(
																				`hr_employee_contacts?hr_employee=${props.objectId}`,
																				(data) => {
																					setContacts(data);
																				},
																				() => {}
																			);
																		},
																		(status, error) => {}
																	);
																} else {
																	var newContacts = [].concat(contacts);
																	newContacts.map((c) => {
																		if (c.id === contact.id) {
																			c.name = e.target.value;
																		}
																	});
																	setContacts(newContacts);
																}
															}}
															inputProps={{ maxLength: 255 }}
														/>
													</Grid>
													<Grid item xs={4} className={classes.gridItem}>
														<TextValidator
															fullWidth
															placeholder="Relation"
															variant="outlined"
															margin="dense"
															name="relation"
															value={contact.relation || ""}
															onChange={(e) => {
																if (props.objectId) {
																	contact.relation = e.target.value;
																	http.Put(
																		`hr_employee_contacts/${contact.id}`,
																		contact,
																		(result) => {
																			http.Get(
																				`hr_employee_contacts?hr_employee=${props.objectId}`,
																				(data) => {
																					setContacts(data);
																				},
																				() => {}
																			);
																		},
																		(status, error) => {}
																	);
																} else {
																	var newContacts = [].concat(contacts);
																	newContacts.map((c) => {
																		if (c.id === contact.id) {
																			c.relation = e.target.value;
																		}
																	});
																	setContacts(newContacts);
																}
															}}
															inputProps={{ maxLength: 255 }}
														/>
													</Grid>
													<Grid item xs={3} className={classes.gridItem}>
														<TextValidator
															fullWidth
															placeholder="Contact Number"
															variant="outlined"
															margin="dense"
															name="phone"
															value={contact.phone || ""}
															onChange={(e) => {
																if (props.objectId) {
																	contact.phone = e.target.value;
																	http.Put(
																		`hr_employee_contacts/${contact.id}`,
																		contact,
																		(result) => {
																			http.Get(
																				`hr_employee_contacts?hr_employee=${props.objectId}`,
																				(data) => {
																					setContacts(data);
																				},
																				() => {}
																			);
																		},
																		(status, error) => {}
																	);
																} else {
																	var newContacts = [].concat(contacts);
																	newContacts.map((c) => {
																		if (c.id === contact.id) {
																			c.phone = e.target.value;
																		}
																	});
																	setContacts(newContacts);
																}
															}}
															inputProps={{ maxLength: 255 }}
														/>
													</Grid>
													<Grid item xs={1} className={classes.gridItem}>
														<Button
															variant="outlined"
															margin="dense"
															size="small"
															// className={classes.addButton}
															onClick={() => {
																if (props.objectId) {
																	http.Delete(
																		`hr_employee_contacts`,
																		{ ids: [contact.id] },
																		(result) => {
																			http.Get(
																				`hr_employee_contacts?hr_employee=${props.objectId}`,
																				(data) => {
																					setContacts(data);
																				},
																				() => {}
																			);
																		},
																		(status, error) => {}
																	);
																} else {
																	setContacts(
																		contacts.filter((c) => c.id !== contact.id)
																	);
																}
															}}
														>
															Delete
														</Button>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									);
								})}
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}></div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<Grid container spacing={1} alignItems="center">
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												fullWidth
												placeholder="Name"
												variant="outlined"
												margin="dense"
												name="contact_name"
												value={hr.contact_name || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
											/>
										</Grid>
										<Grid item xs={4} className={classes.gridItem}>
											<TextValidator
												fullWidth
												placeholder="Relation"
												variant="outlined"
												margin="dense"
												name="contact_relation"
												value={hr.contact_relation || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
											/>
										</Grid>
										<Grid item xs={3} className={classes.gridItem}>
											<TextValidator
												fullWidth
												placeholder="Contact Number"
												variant="outlined"
												margin="dense"
												name="contact_phone"
												value={hr.contact_phone || ""}
												onChange={onChange}
												inputProps={{ maxLength: 255 }}
											/>
										</Grid>
										<Grid item xs={1} className={classes.gridItem}>
											<Button
												variant="outlined"
												margin="dense"
												size="small"
												// className={classes.addButton}
												onClick={() => {
													if (props.objectId) {
														http.Post(
															`hr_employee_contacts`,
															{
																name: hr.contact_name,
																relation: hr.contact_relation,
																phone: hr.contact_phone,
																hr_employee: props.objectId,
															},
															(result) => {
																http.Get(
																	`hr_employee_contacts?hr_employee=${props.objectId}`,
																	(data) => {
																		setHr({
																			...hr,
																			contact_name: "",
																			contact_relation: "",
																			contact_phone: "",
																		});
																		setContacts(data);
																	},
																	() => {}
																);
															},
															(status, error) => {}
														);
													} else {
														contacts.push({
															name: hr.contact_name,
															relation: hr.contact_relation,
															phone: hr.contact_phone,
															id: Math.random(),
														});
														setContacts(contacts);
														setHr({
															...hr,
															contact_name: "",
															contact_relation: "",
															contact_phone: "",
														});
													}
												}}
											>
												Add
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<br />
							<div style={{ fontWeight: 420 }}>
								<span className={classes.title}>
									ARE THERE ANY HEALTH ISSUES YOUR EMPLOYER SHOULD BE AWARE OF?
								</span>
							</div>
							<Divider />
							<br />
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={2} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>HEALTH ISSUES</span>
									</div>
								</Grid>
								<Grid item xs={10} className={classes.gridItem}>
									<TextValidator
										id="health_issues"
										fullWidth
										multiline
										rows={4}
										placeholder="Health Issues"
										variant="outlined"
										margin="dense"
										name="health_issues"
										value={hr.health_issues || ""}
										onChange={onChange}
										inputProps={{ maxLength: 255 }}
										error={Boolean(hrError.health_issues)}
										helperText={
											hrError.health_issues && hrError.health_issues[0]
										}
									/>
								</Grid>
							</Grid>
							{props.objectId && (
								<div>
									<a id="meetings"></a>
									<br />
									<div className={classes.title}>MEETINGS</div>
									<Divider />
									<br />
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={12}>
											<HRMeetings
												user={props.user}
												hr_employee={props.objectId}
											/>
										</Grid>
									</Grid>
									<a id="attachments"></a>
									<br />
									<div style={{ fontSize: "150%" }}>ATTACHMENTS</div>
									<Divider />
									<br />
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={12}>
											<HRFiles user={props.user} hr_employee={props.objectId} />
										</Grid>
									</Grid>
								</div>
							)}
							<div>
								<div style={{ float: "right" }}>
									<Button
										variant="outlined"
										margin="dense"
										size="small"
										color="primary"
										className={classes.outlinedButton}
										onClick={() => props.closeOpen(false)}
									>
										Cancel
									</Button>
									<Button
										variant="outlined"
										margin="dense"
										size="small"
										className={classes.filledButton}
										type="submit"
										disabled={isSubmitted}
									>
										Save
									</Button>
								</div>
							</div>
						</ValidatorForm>
					</MuiPickersUtilsProvider>
				</div>
			)}
		</div>
	);
};

export default HRForm;
