
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import {DeviceLang} from "../../Common/Languages";

const lang = localStorage.getItem("languages");

const DeviceCredentialForm = props => {
  const [formFields, setFormFields] = useState();
	const [categories, setCategories] = useState([]);  

	useEffect(() => {
		http.Get(
			`categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Credential Type`,
			(data) => {
				var valueMap = {};
				for (let i = 0; i < data.length; i++) {
					valueMap[data[i].id] = data[i].category_name;
				}
				setCategories(valueMap);
			},
			() => {}
		);
  }, []);

  useEffect(() => {
    if(categories){
      setFormFields([
        { 
          id: "category", 
          label: DeviceLang.CREDENTIALTYPE[lang], 
          type: "select",
          valueMap: categories
        },
        { 
          id: "description", 
          label: DeviceLang.DESCRIPTION[lang], 
          type: "text", 
        },
        { 
          id: "username", 
          label: DeviceLang.USERNAME[lang]+" *", 
          type: "text", 
        },
        { 
          id: "password", 
          label: DeviceLang.PASSWORD[lang]+" *", 
          type: "password", 
        },
      ]);

    }

  }, [categories]);


  return(
    <div>{formFields && <ObjectForm {...props} url={`device_credentials`} formName={DeviceLang.DeviceCredential[lang]} formFields={formFields}/>}</div>
  );
};

export default DeviceCredentialForm;
