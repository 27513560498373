import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackbarComponent = (props) => {
  const horizontal = "center";
  const vertical = "top";
  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={5000}
        onClose={props.handleSnackBarClose}
        anchorOrigin={{ horizontal, vertical }}
        key={`${horizontal},${vertical}`}
      >
        <Alert onClose={props.handleSnackBarClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
