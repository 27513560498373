import React, { useState, useEffect } from "react";
import ScreenLoader from "../../Common/Loader";
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button } from "@material-ui/core";
import http from "../../Common/RestAPIHandler";
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import useStyles from "../../Common/Style/FormStyle";

const MergeCompany = (props) => {
    const classes = useStyles();
	const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [company, setCompany] = useState();

    const cellStyle = {border: "1px solid rgba(0,0,0,0.2)", width:"33%"};

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        http.Get(
            `merge_companies/${props.id}`,
            (company) => {
                company.data_mapping=JSON.parse(company.data_mapping);
                setCompany(company);
                http.Get(
                    `merge_datas?merge_company=${props.id}`,
                    (data) => {
                        data.map((m)=>{
                            m.data_mapping=JSON.parse(m.data_mapping);
                        });
                        setData(data);
                        setLoading(false);
                    },
                    (status, error) => {
                        setLoading(false);
                    }
                );
            },
            (status, error) => {
                setLoading(false);
            }
        );
    };
  
    useEffect(() => {
    }, [data]);

    return (
        <Table style={{ backgroundColor: "white" }}>
            {loading && (
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <ScreenLoader />
                        </TableCell>
                    </TableRow>
                </TableBody>
            )}
            {!loading && (
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {company &&
                                <>
                                <div style={{ backgroundColor: "#DDDDDD", padding:10 }}>Company: {company.name} (Conflicts: {company.conflicts} / Fixed: {company.fixed_conflicts})</div>
                                <Table style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={cellStyle}>
                                                
                                            </TableCell>
                                            <TableCell>
                                                {company.from_name}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {company.to_name}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(company.data_mapping).map(([key, property])=>{
                                            return(
                                                <TableRow>
                                                    <TableCell style={cellStyle}>
                                                        {key} 
                                                        {property.conflict && <WarningIcon style={{float:"right"}}/>}
                                                    </TableCell>
                                                    <TableCell style={cellStyle}>
                                                        {property.from_id} 
                                                        {!property.conflict && property.selected === "from_id" && <CheckIcon style={{float:"right"}}/>}
                                                        {property.conflict && <Checkbox checked={property.selected === "from_id"} style={{float:"right"}} onClick={(e)=>{
                                                            company.data_mapping[key].selected = "from_id";
                                                            setCompany({...company});
                                                        }}/>}
                                                    </TableCell>
                                                    <TableCell style={cellStyle}>
                                                        {property.to_id} 
                                                        {!property.conflict && property.selected === "to_id" && <CheckIcon style={{float:"right"}}/>}
                                                        {property.conflict && <Checkbox checked={property.selected === "to_id"} style={{float:"right"}} onClick={(e)=>{
                                                            company.data_mapping[key].selected = "to_id";
                                                            setCompany({...company});
                                                        }}/>}
                                                    </TableCell>
                                                </TableRow>

                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <br/>
                                </>                            
                            }
                            {data.map((m)=>{
                                return(
                                    <>
                                    <div style={{ backgroundColor: "#DDDDDD", padding:10 }}>{m.model}: {m.name} (Conflicts: {m.conflicts} / Fixed: {m.fixed_conflicts})</div>
                                    <Table style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={cellStyle}>
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    {m.from_name}
                                                </TableCell>
                                                <TableCell style={cellStyle}>
                                                    {m.to_name}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(m.data_mapping).map(([key, property])=>{
                                                return(
                                                    <TableRow>
                                                        <TableCell style={cellStyle}>
                                                            {key} 
                                                            {property.conflict && <WarningIcon style={{float:"right"}}/>}
                                                        </TableCell>
                                                        <TableCell style={cellStyle}>
                                                            {property.from_id} 
                                                            {!property.conflict && property.selected === "from_id" && <CheckIcon style={{float:"right"}}/>}
                                                            {property.conflict && <Checkbox checked={property.selected === "from_id"} style={{float:"right"}} onClick={(e)=>{
                                                                m.data_mapping[key].selected = "from_id";
                                                                setData([...data]);
                                                            }}/>}
                                                        </TableCell>
                                                        <TableCell style={cellStyle}>
                                                            {property.to_id} 
                                                            {!property.conflict && property.selected === "to_id" && <CheckIcon style={{float:"right"}}/>}
                                                            {property.conflict && <Checkbox checked={property.selected === "to_id"} style={{float:"right"}} onClick={(e)=>{
                                                                m.data_mapping[key].selected = "to_id";
                                                                setData([...data]);
                                                            }}/>}
                                                        </TableCell>
                                                    </TableRow>

                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    </>
                                );                        
                            })}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Button
                                variant="outlined"
                                margin="dense"
                                size="small"
                                color="secondary"
                                className={classes.filledButton}
                                style={{float:"right"}}
                                onClick={() => {
                                    setLoading(true);
                                    var promises = data.map((m)=>{  
                                        m.data_mapping = JSON.stringify(m.data_mapping);
                                        return http.Put(
                                            `merge_datas/${m.id}`,
                                            m,
                                            (data) => {
                                            },
                                            (status, error) => {
                                                console.log(error);
                                            }
                                        );
                                    });
                                    Promise.all(promises).then((results) => {
                                        company.data_mapping = JSON.stringify(company.data_mapping);
                                        http.Put(
                                            `merge_companies/${company.id}`,
                                            company,
                                            (cs) => {
                                                fetchData();
                                                props.fetchData();
                                            },
                                            (status, error) => {
                                                console.log(error);
                                                setLoading(false);
                                            }
                                        );
                                    });
                                }}
                            >
                                Save
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )}
        </Table>
    );
};

export default MergeCompany;