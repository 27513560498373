import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    width: "95%",
    margin: "auto",
    marginTop: "10%",
    textAlign: "center"
  },
  color: {
    color: theme.colorScheme
  },
  title: {
    fontSize: 100
  },
  caption: {
    fontSize: 20,
    paddingBottom: 50
  },
  link: {
    paddingTop: 50
  }
}));

const Page404 = props => {
  const path = props.location.pathname;
  const [homepage, setHomepage] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (props.user) {
      if (props.user.user_type === "Tenant User") {
        setHomepage("/dashboard");
      } else if (props.user.user_type === "Company Employee" && props.user.role?.status) {
        setHomepage("/portal/credentials");
      }else if (props.user.user_type === "Reseller User") {
        setHomepage("/reseller/dashboard");
      }else if (props.user.user_type === "Super User") {
        setHomepage("/admin/dashboard");
      }
      else{
        if (props.user.user_type === "Company Employee") setHomepage("/portal/login")
        else setHomepage("/login");
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container className={classes.body}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            className={`${classes.color} ${classes.title}`}
          >
            404
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5">OOPS! NOTHING WAS FOUND</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.caption}>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.caption}>
            {homepage && (
              <Link
                className={`${classes.color} ${classes.link}`}
                href={homepage}
              >
                Return to homepage
              </Link>
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(Page404);
