import React, { useState, useEffect } from 'react'
import { Collapse, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import QueueIcon from '@material-ui/icons/Queue';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import http from '../../../../Common/RestAPIHandler';
import useStyles from '../Style/style';
import ScreenLoader from '../../../../Common/Loader';
import {HRlang} from "../../../../Common/Languages";

export default function EmployeeList(props) {
	const classes = useStyles();
	const [hrProfiles, setHrProfiles] = useState([]);
	const [employees, setEmployees] = useState([])
	const [collapse, setCollapse] = useState(false);
	const [loadData, setLoadData] = useState(false);
	const url = `hr_employees/`;

	const lang = localStorage.getItem("languages");

	useEffect(() => {
		fetchData();
	}, []);

	const handleArrowClick = () => {
		setCollapse(!collapse);
	};

	const fetchData = () => {
		http.Get(
			url,
			(data) => {
				console.log(data);
				setHrProfiles(data);
			}, () => {}
		)
		
	}

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12} className={classes.gridItem}>
					<Grid
						container
						spacing={1}
						alignItems="center"
						className={classes.collapse}
					>
						<Grid item xs={11}>
							<span className={classes.title}>
								{HRlang.EMPLOYEES[lang]}
							</span>
						</Grid>
						<Grid item xs={1} className={classes.icons}>
							{(collapse && (
								<ArrowDropDownIcon
									fontSize="default"
									className={classes.collapseButton}
									onClick={handleArrowClick}
								/>
							)) || (
									<ArrowDropUpIcon
										fontSize="default"
										className={classes.collapseButton}
										onClick={handleArrowClick}
									/>
								)}
						</Grid>
					</Grid>
					<Divider />
				</Grid>

				{loadData ? (
					<ScreenLoader />
				) : (
					<Grid item xs={12} className={classes.gridItem}>
						<Collapse in={!collapse}>
							<List component="nav">
								{employees && employees.map((employee) => {
									return (
										<ListItem button>
											<ListItemAvatar children={<AccountBoxIcon className={classes.iconColorSchemeLight} />} />
											<ListItemText primary={employee.full_name} primaryTypographyProps={{variant: 'subtitle'}} className={classes.subText} />
										</ListItem>
									)
								})}
								&nbsp;
								<Divider />
								<ListItem button style={{ textAlign: 'center' }}>
									<QueueIcon className={classes.iconColorScheme} fontSize='small' />
									<ListItemText primary={HRlang.CreateNewHRProfile[lang]} className={classes.subText} />
								</ListItem>
							</List>
						</Collapse>
					</Grid>
				)}
			</Grid>
		</>
	)
}
