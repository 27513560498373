import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import commonConstants from "../../Common/CommonConstants";
import CheckIcon from '@material-ui/icons/Check';

const Tenants = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [activeIds, setActiveIds] = useState([]);
  const url = `tenants`;
  const actions = {
    delete: false,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "company_name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "created_time",
      label: "CREATED TIME",
      minWidth: 300,
      sortable: true,
    },
    {
      id: "package_name",
      label: "PACKAGE",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "reseller_name",
      label: "RESELLER",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "is_active",
      label: "ACTIVE",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "status",
      label: "STATUS",
      minWidth: 150,
      sortable: true,
			colored: true,
			style: commonConstants.cellCursor,
      onClick: (props, object, property) => {
        if (activeIds.includes(object.id)) {
          setActiveIds(
            activeIds.filter((activeId) => {
              return activeId !== object.id;
            })
          );
        } else {
          var ids = [].concat(activeIds);
          ids.push(object.id);
          setActiveIds(ids);
        }
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((tenant) => {
          tenant["is_active"] = tenant.is_active ? <CheckIcon/> : "";
          tenant["created_time"] = new Date(tenant["created_time"]).toLocaleDateString();
          tenant["subData"] = (
            <span>
              <span style={{fontWeight:"bold"}}>Signed Up</span>: {tenant.created_time && tenant.created_time.split("T")[0]} | <span style={{fontWeight:"bold"}}>Trial End</span>: {tenant.trial_end_date} | <span style={{fontWeight:"bold"}}>Status</span>: {tenant.status} | <span style={{fontWeight:"bold"}}>Last Payment</span>: {tenant.last_payment && tenant.last_payment.split("T")[0]} | <span style={{fontWeight:"bold"}}>Max Company</span>: {tenant.max_company} ({tenant.company_count} used) | <span style={{fontWeight:"bold"}}>User Count</span>: {tenant.user_addons} ({tenant.user_count} used) | <span style={{fontWeight:"bold"}}>Readonly Count</span>: {tenant.readonly_addons} ({tenant.readonly_count} used) | <span style={{fontWeight:"bold"}}>Portal Addons</span>: {tenant.portal_addons} ({tenant.portal_count} used)
            </span>
          );
          tenant["mobile_view"] = tenant["package_name"];
        });
        setData(data);
        setLoadData(false);
      },
      () => {}
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      activeIds={activeIds}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </>
  );
};

export default Tenants;
