import React, { useState, useEffect } from "react";
import commonConstants from "../../../Common/CommonConstants";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../../Images/logo.png";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: commonConstants.background
  },
  root: {
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%"
    }
  },
  container: {
    minHeight: "100vh"
  },
  centerElement: {
    textAlign: "center"
  },
  rightElement: {
    textAlign: "right"
  },
  logo: {
    width: "50%",
    height: "auto"
  },
  button: {
    borderRadius: 30,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#e37309",
    "&:hover": {
      backgroundColor: "#e37309"
    },
    color: "white"
  },
  passwordField: {
    fontWeight: "bold"
  }
}));

const Configuration = props => {
  const classes = useStyles();
  const { series } = props.match.params;
  const { id } = props.match.params;
  const { token } = props.match.params;
  const [check,setCheck] = useState();
  const [passphrase,setPassphrase] = useState();
  const [passwordValid,setPasswordValid] = useState();
  const [valid,setValid] = useState(true);
  const [configuration, setConfiguration] = useState({});
  const [loading, setLoading] = useState("Loading...");
  const [title, setTitle] = useState("");
  const errorText = commonConstants.error;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    fetch(`${commonConstants.serverName}tenant_user_configuration/pre/${series}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        res.json().then(data => {
          if(data.message === "True"){
            setCheck("pass");
            setLoading(null);
          }else{
            setCheck(null);
            setLoading(null);
            setPasswordValid(true);
          }
        })
        // setCheck(res.data);
        
      } else {
        setLoading("Link expired!");
        setTitle("Sorry, this link has expired and is no longer available. Please request a new link from the sender.");
      }
    })
  }, []);

  useEffect(() => {
    if (!check && passwordValid){
      fetch(`${commonConstants.serverName}tenant_user_configuration/${series}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .then((res) => {
        return res.text().then((text) => {
          let data = text.length ? JSON.parse(text) : {};
          return { status: res.status, data: data };
        });
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setConfiguration(res.data);
          setLoading(null);
        } else {
          setLoading("Link expired!");
          setTitle("Sorry, this link has expired and is no longer available. Please request a new link from the sender.");
        }
      })
    }
    
  }, [check,passwordValid]);

  const onChange = (e) => {
    setConfiguration({
      ...configuration,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePhrase = (e) => {
    setPassphrase(e.target.value);
  };

  const onSubmit = (e) => {
    if (check){
    e.preventDefault();
    fetch(`${commonConstants.serverName}tenant_user_configuration/valid/${series}`, {
      method: "POST",
      body: JSON.stringify(passphrase),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
          res.json().then(data => {
            if(data.message === "True"){
              setPasswordValid("pass");
              setLoading(null);
              setCheck(null)
            }else{
              setPasswordValid(null);
              setLoading(null);
              setValid(false);
            }
          })
      } else {
        setLoading("Form Submit Failed! Please Try Later!");
      }
    })
  }else{
    e.preventDefault();
    fetch(`${commonConstants.serverName}tenant_user_configuration/${series}`, {
      method: "PATCH",
      body: JSON.stringify(configuration),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
    .then((res) => {
      return res.text().then((text) => {
        let data = text.length ? JSON.parse(text) : {};
        return { status: res.status, data: data };
      });
    })
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setConfiguration(res.data);
        setLoading("Form Submitted!");
      } else {
        setLoading("Form Submit Failed! Please Try Later!");
      }
    })
  }
  
  };
  
  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <ValidatorForm onSubmit={onSubmit}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Typography>
                </Grid>
                {loading &&
                  <Grid item xs={12} className={classes.centerElement}>
                    <Typography
                      variant="h4"
                      component="h5"
                      color="textSecondary"
                    >
                      
                      <div>{loading}</div>
                      <div>{title}</div>
                    </Typography>
                  </Grid>                  
                }
                {!loading && check && !passwordValid &&
                <>
                {valid && <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h5"
                    color="textSecondary"
                  >
                    This message requires a passphrase:
                  </Typography>
                </Grid>}
                {!valid && <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h5"
                    color="textSecondary"
                  >
                    Invalid passphrase, please enter again
                  </Typography>
                </Grid>}
                <Grid item xs={12}>
                  <TextValidator
                    label="PassPhrase"
                    type="text"
                    name="name"
                    placeholder="Enter the passphrase here"
                    variant="outlined"
                    fullWidth
                    onChange={onChangePhrase}
                    // validators={["required"]}
                    // errorMessages={[errorText.requiredError]}
                  />
                </Grid>
                <Grid item xs={6} className={classes.rightElement}>
                  <Button
                    type="submit"
                    className={classes.outlinedButton}
                    variant="outlined"
                  >
                    Submit
                  </Button>
                </Grid>
                </>}
                {!loading && !check && passwordValid &&
                <>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h5"
                    color="textSecondary"
                  >
                    Credentials Secured Submit Form
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="h6"
                    color="textSecondary"
                  >
                  Credentials will be encrypted and sent to <span className={classes.passwordField}>{configuration.tenant_user_name}</span>.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Name *"
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={configuration.name || ""}
                    onChange={onChange}
                    validators={["required"]}
                    errorMessages={[errorText.requiredError]}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="URL"
                    type="text"
                    name="url"
                    variant="outlined"
                    fullWidth
                    value={configuration.url || ""}
                    onChange={onChange}
                    errorMessages={[errorText.requiredError]}
                    readonly={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Username"
                    type="text"
                    name="username"
                    variant="outlined"
                    fullWidth
                    value={configuration.username || ""}
                    onChange={onChange}
                    errorMessages={[errorText.requiredError]}
                  />
                </Grid>
                <Grid item xs={12} >
                <TextValidator
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      labelWidth: 77,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    value={configuration.password || ""}
                    onChange={onChange}
                    errorMessages={[errorText.requiredError]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Message"
                    type="text"
                    name="content"
                    variant="outlined"
                    multiline
                    fullWidth
                    value={configuration.content || ""}
                    onChange={onChange}
                    errorMessages={[errorText.requiredError]}
                  />
                </Grid>
                <Grid item xs={12} ></Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      This form will expire on {configuration.expire_at}.
                    </Grid>
                    <Grid item xs={6} className={classes.rightElement}>
                      <Button
                        type="submit"
                        className={classes.outlinedButton}
                        variant="outlined"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                </>
                }
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default Configuration;
