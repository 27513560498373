import React, { useEffect, useState } from "react";
import ObjectList from "../Common/ObjectList";
import ObjectListMobile from "../Common/ObjectListMobile";
import http from "../Common/RestAPIHandler";
import Button from "@material-ui/core/Button";
import SnackbarComponent from "../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import ObjectVersions from "../Common/ObjectVersions";
import { formatDate } from "../Common/Utility";
import { connect } from "react-redux";
import commonConstants from "../Common/CommonConstants";
import HistoryIcon from '@material-ui/icons/History'; 
import {tenantArchive} from "../Common/Languages";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
    button: {
      margin: 10,
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        border: "1px solid white",
      },
    },
    removeButton: {
      margin: 10,
      borderRadius: 25,
      backgroundColor: "red",
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
        border: "1px solid white",
      },
    },
  }));
  
  
const tableColumns = [
  {
    id: "model",
    label: tenantArchive.MODEL[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "name",
    label: tenantArchive.NAME[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "last_modified_by_name",
    label: tenantArchive.LASTMODIFIEDBY[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "deleted_at",
    label: tenantArchive.LASTMODIFIEDAT[lang],
    minWidth: 200,
    sortable: true,
  },
  {
    id: "versions",
    label: " ",
    minWidth: 10,
    disableTooltip: true,
    style: commonConstants.cellCursor,
    colored: true,
  },
  {
    id: "actions",
    label: " ",
    minWidth: 200,
    disableTooltip: true,
  },
];

const Archives = (props) => {
  const classes = useStyles();  
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_archives`;
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [versions, setVersions] = useState();
    
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((archive)=>{
          archive["deleted_at"] = archive["last_modified_time"].split("T")[0];
          archive.actions = <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.removeButton} 
                onClick={()=>{
                  if(window.confirm(tenantArchive.Deletepermanently[lang])){
                    http.Delete(
                      url,
                      archive,
                      (res) => {
                        setSnackBarMessage(res.message);
                        fetchData();
                      },
                      () => {}
                    );
                  }
              }}>{tenantArchive.Remove[lang]}</Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.button} 
                onClick={()=>{
                  http.Put(
                      url,
                      archive,
                      (res) => {
                        setSnackBarMessage(res.message);
                        fetchData();
                      },
                      () => {}
                  );
              }}>{tenantArchive.Restore[lang]}</Button></div>;

          archive['versions'] = <HistoryIcon
              onClick={(e)=>{
                  setAnchorEl(e.currentTarget);
                  setVersions(null);
                  http.Get(
                    `versions?object_id=${archive.id}&model_name=${archive.model}`,
                    (versions) => {
                      setVersions(versions);
                    },
                    () => {
                      setVersions(null);
                    }
                  );
                  }}/>;

        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
    <SnackbarComponent
      message={snackBarMessage}
      open={Boolean(snackBarMessage)}
      handleSnackBarClose={()=>{setSnackBarMessage(null);}}
      severity={"success"}
    ></SnackbarComponent>
    {anchorEl &&
    <ObjectVersions
      {...props}
      versions={versions}
      fetchData={fetchData}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    />}
    {!props.mobile &&
    <ObjectList
      {...props}
      moduleName={tenantArchive.Archives[lang]}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"deleted_at"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Archives"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile &&
    <ObjectListMobile
      {...props}
      moduleName={"Archives"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["model","name","last_modified_by_name"]}
      order={"desc"}
      orderBy={"last_modified_time"}
      data={data}
      fetchData={fetchData}
      pageHeader={(props.company.company_name || "") + " | Archives"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Archives);
