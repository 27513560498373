import React, { useEffect, useState } from "react";
import ResellerUserForm from "./ResellerUserForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { format } from "date-fns-tz";

const tableColumns = [
  {
    id: "first_name",
    label: "FIRST NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "last_name",
    label: "LAST NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "roles",
    label: "ROLES",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "email",
    label: "EMAIL",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "last_login",
    label: "LAST LOGIN",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "reseller_name",
    label: "RESELLER",
    minWidth: 175,
    sortable: true,
  },
];

const ResellerUsers = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `reseller_users`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((resellerUser) => {
          resellerUser["last_login"] = resellerUser["last_login"] &&
            format(
              new Date(resellerUser["last_login"]),
              "yyyy-MM-dd HH:mm"
            );
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Reseller Users"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Reseller Users"],
        edit: ["Create/Modify Reseller Users"],
        delete: ["Delete Reseller Users"],
      }}
      objectForm={ResellerUserForm}
      tableColumns={tableColumns}
      searchFields={["first_name","last_name","email", "roles"]}
      orderBy={"first_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Reseller Users"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Reseller Users"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Reseller Users"],
        edit: ["Create/Modify Reseller Users"],
        delete: ["Delete Reseller Users"],
      }}
      objectForm={ResellerUserForm}
      tableColumns={tableColumns}
      searchFields={["first_name","last_name","email", "roles"]}
      orderBy={"first_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Reseller Users"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default ResellerUsers;
