import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {Divider, Typography, Paper, Grid, IconButton,} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { formatDate } from "../../Common/Utility";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";

const SoftwareLicenseDetails = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    var { softwareLicenseId } = props.match.params;  
    const [softwareLicense, setSoftwareLicense] = useState({});
	const [dataMappings, setDataMappings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if(props.objectId){
        softwareLicenseId = props.objectId;
      }
      http.Get(
        `software_licenses/${softwareLicenseId}/`,
        (softwareLicense) => {
            setSoftwareLicense(softwareLicense);

            http.Get(
              `data_mappings?from_id=${softwareLicense.id}`,
              (data) => {
                setDataMappings(data);
                setLoading(false);
              },
              () => {
              }
            );
        },
        () => {}
      );
    }, []);

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={10}>
                <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
                    {softwareLicense.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <div style={{paddingTop:10,float:"right"}}>
                    <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                            if(props.onClose){
                                props.onClose();
                            }
                            else{
                                props.history.push(
                                    process.env.PUBLIC_URL + `/company/${id}/software_licenses`
                            );
                            }
                        }}
                    >
                    <HighlightOffIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={10}>
                <Paper>
                    <div
                        style={{ maxHeight: "calc(100vh - 280px)",
                            minHeight: "calc(100vh - 280px)",
                            overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>NAME</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>LICENSE TYPE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.category_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SERIAL NUMBER</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.serial_number}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>INSTALL DATE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(softwareLicense.install_date)}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>EXPIRATION DATE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(softwareLicense.expiration_date)}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>LOCATION</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.site_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SOFTWARE ASSET TAG</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.software_asset_tag}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>WORKSTATION ASSET TAG</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.workstation_asset_tag}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>NOTES</span>
                            </Grid>
                            <Grid item xs={8} style={{whiteSpace:"pre-wrap"}}>
                                {softwareLicense.notes}
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
            <Grid item xs={2}>
                <Paper>
                <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                        minHeight: "calc(100vh - 280px)",
                        overflowY: "auto",
                        overflowX: "auto", 
                        marginLeft:10,
                        padding:10,
                    }}
                >
                <p style={{fontWeight:"bold"}}>Related Entries:</p>
                <DataMappings 
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
              </Paper>
          </Grid>
        </Grid>
        }
        </>
    );
};

export default SoftwareLicenseDetails;