import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "../../../../Images/tenant_default_logo.png";
import MobileLogo from "../../../../Images/tenant_default_mobile_logo.png";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Chip from "@material-ui/core/Chip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { clearUser } from "../../../../redux-tools/actions/userActions";
import auth from "../../../../services/authService";
import http from "../../../Common/RestAPIHandler";
import { format } from "date-fns-tz";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Tooltip } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import StorageIcon from "@material-ui/icons/StorageOutlined";
import ContactPhoneIcon from "@material-ui/icons/ContactPhoneOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembershipOutlined";
import ListIcon from "@material-ui/icons/List";

const ClientPortalLayout = (props) => {
  const classes = useStyles();
  const [dateTime, setDateTime] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [delay, setDelay] = useState(0);
  const [mainLogo, setMainLogo] = useState();
  const [mobileLogo, setMobileLogo] = useState();
  const [jsonSchemas, setJsonSchemas] = useState([]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    http.Post(
      "employee/logout/",
      null,
      () => {
        auth.deleteToken();
        props.clearUser();
        props.history.replace(process.env.PUBLIC_URL + "/portal/login");
      },
      () => {}
    );
  };

  useEffect(() => {
    setMainLogo(props.user.tenant.logo);
    setMobileLogo(props.user.tenant.mobile_logo);
    http.Get(
      `json_schemas?tenant=${props.user.tenant.id}`,
      (data) => {
        setJsonSchemas(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      var today = new Date();
      var dateFormat = format(today, "hh:mm|a|eeee|MMM dd, yyyy").split("|");
      setDateTime({
        time: dateFormat[0],
        ampm: dateFormat[1],
        dayName: dateFormat[2],
        date: dateFormat[3],
      });
      setDelay(62 - today.getSeconds());
    }, delay * 1000);
    return () => clearTimeout(interval);
  }, [dateTime]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.appdiv}>
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbarOne}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="row"
                justify="flex-start"
              >
                <Grid item>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={mainLogo ? mainLogo : Logo}
                      alt="Not Available"
                      className={classes.full_image}
                    />
                    <img
                      src={mobileLogo ? mobileLogo : MobileLogo}
                      alt="Not Available"
                      className={classes.mobile_image}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                direction="row"
                justify="flex-end"
              >
                <Grid item className={classes.headerRightGrid}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item className={classes.TimeHide}>
                      <div className={classes.time}>
                        {dateTime.time}
                        <sup className={classes.superscript}>
                          {dateTime.ampm}
                        </sup>
                      </div>
                    </Grid>
                    <Grid item className={classes.DateHide}>
                      <div className={classes.date}>
                        {dateTime.dayName}
                        <br></br> {dateTime.date}
                      </div>
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleMenu}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              alt="No Logo"
                              src={""}
                            />
                          }
                          label={"Hi " + props.user.employee_name}
                          clickable
                          onDelete={handleMenu}
                          deleteIcon={
                            <ArrowDropDownIcon
                              color="inherit"
                              className={classes.arrowIcon}
                            />
                          }
                          className={classes.hideChip}
                        />
                        <Avatar
                          className={classes.showProfileImage}
                          alt="No Logo"
                          src={""}
                        />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: 160,
                            borderRadius: 15,
                          },
                        }}
                      >
                      <MenuItem
                        onClick={(e) => {
                          props.history.replace(process.env.PUBLIC_URL + "/portal/changePassword");
                          setAnchorEl(null);
                        }}
                        className={classes.menuItem}
                      >
                        Change Password
                      </MenuItem>
                      <Divider />
                        <MenuItem
                          onClick={handleLogOut}
                          className={classes.menuItem}
                        >
                          Log Out
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.navdiv}>
              <div className={classes.navbar}>
                <NavLink
                  className={classes.navLink + " " + ("/portal/credentials" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/portal/credentials"}
                >
                  <Tooltip title="My Credentials"><VpnKeyIcon/></Tooltip>
                  <span className={classes.navLinkText}>My Credentials
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <br/>
                <Divider />
                <br/>
                {props.user.role.sites_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/sites" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/sites"}
                    >
                      <Tooltip title="Sites"><BusinessIcon/></Tooltip>
                      <span className={classes.navLinkText}>Sites
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.docs_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/documents" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/documents"}
                    >
                      <Tooltip title="Documents"><DescriptionIcon/></Tooltip>
                      <span className={classes.navLinkText}>Documents
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.employees_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/employees" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/employees"}
                    >
                      <Tooltip title="Employees"><PeopleAltIcon/></Tooltip>
                      <span className={classes.navLinkText}>Employees
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.devices_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/servers_devices" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/servers_devices"}
                    >
                      <Tooltip title="Servers & Devices"><StorageIcon/></Tooltip>
                      <span className={classes.navLinkText}>Servers & Devices
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.licenses_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/software_licenses" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/software_licenses"}
                    >
                      <Tooltip title="Software Licenses"><CardMembershipIcon/></Tooltip>
                      <span className={classes.navLinkText}>Software Licenses
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.services_read && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/other_services" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/other_services"}
                    >
                      <Tooltip title="Other Services"><ContactPhoneIcon/></Tooltip>
                      <span className={classes.navLinkText}>Other Services
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                {props.user.role.custom_read && jsonSchemas.length > 0 &&
                  jsonSchemas.map((jsonSchema) => {
                    return (
                      <React.Fragment>
                        <NavLink
                          className={classes.navLink + " " + ("/portal/json_datas/" + jsonSchema.id + "/" === window.location.pathname?classes.active:"")}
                          to={process.env.PUBLIC_URL + "/portal/json_datas/" + jsonSchema.id + "/"}
                        >
                          <Tooltip title={jsonSchema.name}>
                            {jsonSchema['icon'] ? (
                              <img
                                style={{ maxWidth: "20px", maxHeigth: "20px" }}
                                alt={jsonSchema.name}
                                src={jsonSchema["icon"]}
                              />
                              ) : <ListIcon />
                            }
                          </Tooltip>
                          <span className={classes.navLinkText}>{jsonSchema.name}
                          <KeyboardArrowRightIcon
                            style={{float:"right"}}
                          /></span>
                        </NavLink>
                        <br/>
                        <Divider />
                        <br/>
                      </React.Fragment>
                    );
                  })
                }
                {props.user.is_billing_contact && (
                  <React.Fragment>
                    <NavLink
                      className={classes.navLink + " " + ("/portal/billing" === window.location.pathname?classes.active:"")}
                      to={process.env.PUBLIC_URL + "/portal/billing"}
                    >
                      <Tooltip title="Billing"><CreditCardIcon/></Tooltip>
                      <span className={classes.navLinkText}>Billing
                      <KeyboardArrowRightIcon
                        style={{float:"right"}}
                      /></span>
                    </NavLink>
                    <br/>
                    <Divider />
                    <br/>
                  </React.Fragment>
                )}
                <NavLink
                  className={classes.navLink + " " + ("/portal/passwords" === window.location.pathname?classes.active:"")}
                  to={process.env.PUBLIC_URL + "/portal/passwords"}
                >
                  <Tooltip title="Password Vault"><CreditCardIcon/></Tooltip>
                  <span className={classes.navLinkText}>Password Vault
                  <KeyboardArrowRightIcon
                    style={{float:"right"}}
                  /></span>
                </NavLink>
                <footer className={classes.footerdiv}>
                  POWERED BY<br/> SYNCMONKEY
                </footer>
              </div>
            </Grid>
            <Grid item className={classes.maindiv}>
              <main className={classes.content}>{props.children}</main>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ClientPortalLayout.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  clearUser,
})(ClientPortalLayout);
