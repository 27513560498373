import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import { clearUser, setUser } from "../../../redux-tools/actions/userActions";
import ScreenLoader from "../../Common/Loader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.text
  },
  input: {
    backgroundColor: "white",
    height: 30,
    padding: 0,
    margin: 0,
    fontSize: 14,
  },
  saveButton: {
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  rightAlign: {
    textAlign: "right",
    fontSize: 14,
  },
  viewButtonLabel: {
    textTransform: "none",
  },
  inputButton: {
    display: "none",
    textAlign: "center",
  },
  uploadButton: {
    border: "1px dashed gray",
    height: 30,
    fontSize: 13,
  },
  hidden: {
    display: "none !important",
  },
}));


const ResellerSettings = (props) => {
  const classes = useStyles();
  const [reseller, setReseller] = useState({});
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `reseller_third_party_resources`,
      (data) => {
        setReseller(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const onSubmit = () => {
    setLoadData(true);
    http.Put(
      `reseller_third_party_resources`,
      reseller,
      (data) => {
        console.log(data);
        setLoadData(false);
      },
      (status, error) => {
        console.log(error);
        setLoadData(false);
      }
    );
  };

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span>{props.user.reseller_name} | Reseller Settings</span>
          </Grid>
          <Grid item xs={12}>
            {loadData ? (
              <ScreenLoader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" noWrap>
                    AZURE SSO SETTINGS
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>TENANT ID</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,azure_tenant_id:e.target.value});}}
                          name="azure_tenant_id"
                          value={reseller.azure_tenant_id || ""}
                          InputProps={{ className: classes.input }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>CLIENT ID</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,azure_client_id:e.target.value});}}
                          name="azure_client_id"
                          value={reseller.azure_client_id || ""}
                          InputProps={{ className: classes.input }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>CLIENT SECRET</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,azure_client_secret:e.target.value});}}
                          name="azure_client_secret"
                          value={reseller.azure_client_secret || ""}
                          InputProps={{ className: classes.input }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>STATUS</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          select
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,azure_status:e.target.value});}}
                          name="azure_status"
                          value={reseller.azure_status || ""}
                          InputProps={{ className: classes.input }}
                        >
                          <MenuItem value={"Active"}>
                            Active
                          </MenuItem>
                          <MenuItem value={"Disabled"}>
                            Disabled
                          </MenuItem>
                        </TextValidator>
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" noWrap>
                    ONELOGIN SSO SETTINGS
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>CLIENT ID</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,onelogin_client_id:e.target.value});}}
                          name="onelogin_client_id"
                          value={reseller.onelogin_client_id || ""}
                          InputProps={{ className: classes.input }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>URL</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,onelogin_url:e.target.value});}}
                          name="onelogin_url"
                          value={reseller.onelogin_url || ""}
                          InputProps={{ className: classes.input }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2} className={classes.rightAlign}>
                        <span>STATUS</span>
                      </Grid>
                      <Grid item xs={8} sm={7} md={6} lg={4}>
                        <TextValidator
                          select
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={(e)=>{setReseller({...reseller,onelogin_status:e.target.value});}}
                          name="onelogin_status"
                          value={reseller.onelogin_status || ""}
                          InputProps={{ className: classes.input }}
                        >
                          <MenuItem value={"Active"}>
                            Active
                          </MenuItem>
                          <MenuItem value={"Disabled"}>
                            Disabled
                          </MenuItem>
                        </TextValidator>
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                      <Grid item xs={4} sm={2} lg={1}>
                        <Button
                          className={classes.filledButton}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { clearUser, setUser })(ResellerSettings);
