import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CompanyGoogleMap from "./GoogleMap";
import Divider from "@material-ui/core/Divider";
import http from "../../Common/RestAPIHandler";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Tooltip, TextField, Dialog, Button } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import Drawer from "@material-ui/core/Drawer";
import ImportForm from "./ImportForm";
import SnackbarComponent from "../../Common/Snackbar";
import { hasPermission } from "../../Common/permission";
import Typography from "@material-ui/core/Typography";
import Geocode from "react-geocode";
import ScreenLoader from "../../Common/Loader";
import CreateIcon from "@material-ui/icons/Create";
import CompanyForm from "../CompanyList/CompanyForm";
import { formatPhoneNumber } from "../../Common/Utility";
import ErrorIcon from "@material-ui/icons/Error";
import UpdateIcon from "@material-ui/icons/Update";
import Select from "@material-ui/core/Select";
import { formatDate } from "../../Common/Utility";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CompanyActivities from "./CompanyActivities";
import {index,companyDetail} from "../../Common/Languages";

Geocode.setApiKey("AIzaSyD30xT-jnz-eajS6xaH61oS2SiDo1nHlRY");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  arrowIcon: {
    color: theme.buttonClickableBorder,
    "&:hover": {
      color: theme.buttonClickableBorder,
    },
  },
  bold: {
    display: "inline",
    fontWeight: 520,
  },
  chipLabel: {
    color: theme.subText,
  },
  colorScheme: {
    color: theme.colorScheme,
  },
  company: {
    fontSize: "210%",
  },
  companyGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  cursor: {
    cursor: "pointer",
  },
  divider: {
    backgroundColor: theme.colorScheme,
    height: 1,
    // opacity: 0.25,
  },
  drawer: {
    width: "75%",
    "@media (max-width: 430px)": {
      width: "-webkit-fill-available",
    },
  },
  dropDown: {
    width: 250,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  "@media (max-width: 1439px)": {
    dropDown: {
      width: "-webkit-fill-available",
    },
  },
  hasLogo: {
    flexDirection: "row",
  },
  link: {
    textDecoration: "None",
    color: theme.linkColor,
    "&:hover": {
      color: theme.linkColorHover,
    },
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
    textAlign: "center",
  },
  noLogo: {
    flexDirection: "column",
  },
  logoImage: {
    padding: "0px",
    margin: "0px",
    maxWidth: "-webkit-fill-available",
    maxHeight: "300px",
  },
  iconButton: {
    backgroundColor: "transparent",
    margin: "0px",
    padding: "0px",
  },
  outlined: {
    border: "1px solid " + theme.colorScheme,
  },
  menuItem: {
    marginLeft: 5,
    marginRight: 5,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
  },
  menuSelected: {
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 10,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  wrap: {
    wordBreak: "break-all",
  },
  siteList: {
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
    borderRadius: 15,
    height: "max-content",
    width: 250,
  },
  importExport: {
    // fontWeight: 700,
    cursor: "pointer",
    color: theme.colorScheme,
  },
  activityTitle: {
    marginRight: 32,
  },
  notes: {
    display: "flex",
    width: "100%",
    maxHeight: "60px",
    // width: "250px",
    overflowY: "scroll",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  tag_container: {
    display: "flex",
    flexDirection: "row",
  },
  chip: {
    padding: 5,
    margin: 5,
    color: theme.subText,
    borderColor: theme.colorScheme,
  },
  dangerChip: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 15,
    paddingBottom: 15,
    margin: 5,
    color: "black",
    backgroundColor: "#fde5e7",
  },
  dangerIcon: {
    color: "#f25767",

    cursor: "pointer",
  },
  // notesUpdateButton: {
  //   zIndex: 1,
  // },
  iconColorScheme: {
    cursor: "pointer",
    color: theme.linkColorHover,
    "&:hover": {
      color: theme.linkColor,
    },
    marginLeft: 20,
  },
  iconFloatRight: {
    cursor: "pointer",
    color: theme.colorScheme,
    float: "right",
    marginLeft: 20,
  },
  "@media (max-width:960px)": {
    notesUpdateButton: {
      display: "none",
    },
  },
  box1: {
    flexDirection: "row",
  },
  "@media (max-width: 430px)": {
    box1: {
      flexDirection: "column",
    },
    addressBox: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  pageHeader: {
    color: theme.subText,
  },
  pageTitle: {
    color: theme.text,
  },
  hasLogo: {
    flexDirection: "row",
  },
  noLogo: {
    flexDirection: "column",
  },
  logoImage: {
    padding: "0px",
    margin: "0px",
    maxWidth: "-webkit-fill-available",
    maxHeight: "300px",
  },
  company: {
    fontSize: "210%",
    margin: "10px",
    paddingRight: "80px",
  },
  iconButton: {
    backgroundColor: "transparent",
    margin: "0px",
    padding: "0px",
  },
  outlined: {
    border: "1px solid " + theme.colorScheme,
  },
  dropDown: {
    width: 250,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  "@media (max-width: 1439px)": {
    dropDown: {
      width: "-webkit-fill-available",
    },
  },
  companyGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  menuItem: {
    marginLeft: 5,
    marginRight: 5,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
  },
  menuSelected: {
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 10,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  wrap: {
    wordBreak: "break-all",
  },
  bold: {
    display: "inline",
    fontWeight: 520,
  },
  siteList: {
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
    borderRadius: 15,
    height: "max-content",
    width: 250,
  },
  subTitle: {
    paddingLeft: theme.spacing(1),
    color: theme.subText,
  },
  title: {
    color: theme.text,
  },
  cursor: {
    cursor: "pointer",
  },
  activityTitle: {
    marginRight: 32,
  },
  tag_container: {
    display: "flex",
    flexDirection: "row",
  },
  infoI: {
    fontSize: "small",
    color: "black",
  },
  papery: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.notification,
  },
  notesUpdateButton: {
    zIndex: 1,
  },
  tableHeader: {
    backgroundColor: `${theme.palette} !important`,
    color: theme.text,
    borderColor: theme.subText
  },
  tableCellThemed: {
    color: theme.colorScheme,
    borderBottom: "None"
  },
  tableCell: {
    color: theme.subText,
    borderBottom: "None",
    borderColor: theme.dividers
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.tableRowHover
    }
  },
  "@media (max-width:960px)": {
    notesUpdateButton: {
      display: "none",
    },
  },
  box1: {
    flexDirection: "row",
  },
  "@media (max-width: 430px)": {
    box1: {
      flexDirection: "column",
    },
    addressBox: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    activity: {
      position: "absolute",
    },
  },
}));

const CompanyDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [activities, setActivities] = useState([]);
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({});
  const [selectedSite, setSelectedSite] = useState("All");
  const [selectedSiteList, setSelectedSiteList] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [position, setPosition] = useState();
  const [loadData, setLoadData] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [placeID, setPlaceID] = useState("");
  const [company, setCompany] = useState("");
  const [companyNotes, setCompanyNotes] = useState();
  const [companyTags, setCompanyTags] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [viewAll, setViewAll] = useState(false);

  const lang = localStorage.getItem("languages");

  const url = "companies";

  const handleCollapse = () => {
    var t = document.getElementById("notes");
    if (document.getElementById("notes").style.maxHeight == "60px") {
      t.style.maxHeight = "none";
      setCollapsed(true);
    } else if (document.getElementById("notes").style.maxHeight != "60px") {
      t.style.maxHeight = "60px";
      setCollapsed(false);
    }
    // setCollapsed((prev) => !prev);
  };


  const handleObjectSubmit = () => {
    setShowForm(false);
    fetchData();
  };

  const handleChange = (e) => {
    setCompanyNotes(e.target.value);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setCompanyNotes(e.target.value);
    http.Put(
      `${url}/${id}/notes`,
      { notes: companyNotes },
      (res) => {
        handleSnackBar("Notes Updated!", "success");
      },
      (status, error) => {
        handleSnackBar("Notes Update Failed!", "error");
      }
    );
  };

  const deleteTag = (tag) => {
    // setLoadData(true);
    var data = {
      tags: [],
    };
    companyTags.split(",").map((ea) => {
      return ea != tag ? data["tags"].push(ea) : null;
    });

    data["tags"] = data["tags"].toString();

    http.Put(
      `${url}/${id}/tags`,
      data,
      (res) => {
        setCompanyTags(res);
        handleSnackBar("Tag Deleted!", "success");
        // setLoadData(false)
      },
      (status, error) => {
        handleSnackBar("Tag Delete Failed!", "error");
      }
    );
  };

  const fetchData = async () => {
    setLoadData(true);
    http.Get(
      `${url}/${id}`,
      (res) => {
        setCompanyNotes(res.notes);
        setCompanyTags(res.tags);
        setCompany(res);
        http.Get(
          `companies/${id}/sites`,
          (data) => {
            setSites(data);
            if (data.length === 0) {
              setSite({ site_name: "Site list is empty." });
            }
            else {
              const head_office = data.filter((site) => site.is_head_office);
              setSite(head_office[0] ? head_office[0] : data[0]);
            }
            setSelectedSiteList(() => {
              // Create key value pairs from site objects, combine into one
              var valueMap = {};
              for (let i = 0; i < data.length; i++) {
                valueMap[data[i].id] = data[i].site_name;
              }
              return valueMap
            });

            http.Get(
              `companies/recent_activities/?company=${id}`,
              (data) => {
                setActivities(data);
                setLoadData(false);
              },
              () => {
                setLoadData(false);
              }
            );
          },
          () => {
            setLoadData(false);
          }
        );
      },
      (status, error) => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
		fetchData();
  }, []);

  useEffect(() => {
    Geocode.fromAddress(
      site.address &&
        site.address +
          ", " +
          site.city +
          ", " +
          site.state +
          ", " +
          site.postal +
          ", " +
          site.country
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setPlaceID(response.results[0].place_id);
        setPosition({ lat: lat, lng: lng });
      },
      (error) => {}
    );
  }, [site]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (id) => {
    setLoadData(true);
    var newSite = sites.filter((item) => item.id === id)[0];
    setSite(newSite); // Set site in local state

    // const { company } = props;
    // company["site_id"] = newSite["id"]; // Update site_id in company object before sending to database
    // Set the id in local storage when inside CompanyDetails page overriding one set higher up in component tree
    // localStorage.setItem("Site", newSite["id"]);

    // http.Put(
    //   `${url}/${company.id}`,
    //   company,
    //   (company) => {
    //     handleObjectSubmit();
    //     setLoadData(false);
    //   },
    //   (status, error) => {
    //     setLoadData(false);
    //   }
    // );
    setLoadData(false);
    handleClose();
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleSnackBar = (message, severity) => {
    setOpen(false);
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const exportToPdf = () => {
    const offset = new Date().getTimezoneOffset();
    handleSnackBar(
      "Preparing data to export. Download will begin automatically shortly.",
      "success"
    );
    http.ExportToPdf(
      `companies/${id}/export_pdf`,
      { offset: offset },
      (blob) => {
        if (blob.size > 0) {
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = company.company_name + ".pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          handleSnackBar(
            "Error occured in export to pdf. We will send you notification if the PDF is ready!",
            "error"
          );
        }
      },
      () => {
        handleSnackBar(
          "Error occured in export to pdf. We will send you notification if the PDF is ready!",
          "error"
        );
      }
    );
  };

  return (
    <div className={classes.root}>
      <SnackbarComponent
        autoHideDuration={2500}
        message={snackBarMessage}
        open={snackBarOpen}
        handleSnackBarClose={handleSnackBarClose}
        severity={severity}
      ></SnackbarComponent>
      {loadData && <ScreenLoader />}
      {!loadData && (
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <span className={classes.pageHeader}> {companyDetail.Home[lang]}</span>
            <span className={classes.colorScheme}> | </span>
            <span className={classes.pageHeader}>
              {company.company_name}
            </span>
          </Grid>
          <Grid container item xs={2} lg justify="flex-end">
            <Select
              value={selectedSite}
              onChange={(e) => {
                setSelectedSite(e.target.value);
                localStorage.setItem("Site", e.target.value);
              }}
            >
              <MenuItem value="All">{companyDetail.AllSites[lang]}</MenuItem>
              {selectedSiteList &&
                Object.entries(selectedSiteList).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
            </Select>&nbsp;&nbsp;&nbsp;&nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={1}>
              {company.logo_src && (
                <Grid item xs={12} sm={6}>
                  <Tooltip
                    title={company.company_name}
                    placement="bottom"
                  >
                    <img
                      src={company.logo_src}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={company.logo_src ? 6 : 12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {!company.logo_src && (
                      <>
                        <h1 className={classes.pageTitle}>
                          {company.company_name} 
                          {props.companyPermission && props.companyPermission === "readAndWrite" &&
                          <CreateIcon
                            className={classes.iconColorScheme}
                            onClick={() => {
                              setShowForm(true);
                            }}
                          />}
                        </h1>
                      </>
                    )}
                    <Chip
                      label={
                        site.is_head_office
                          ? site.site_name + " Head Office"
                          : site.site_name
                      }
                      style={{
                        fontSize: "14px",
                      }}
                      clickable
                      onClick={handleMenu}
                      onDelete={handleMenu}
                      variant="outlined"
                      fullwidth
                      classes={{
                        deleteIcon: classes.arrowIcon,
                        outlined: classes.outlined,
                        label: classes.chipLabel,
                      }}
                      className={classes.dropDown}
                      deleteIcon={<ArrowDropDownIcon />}
                    />
                    {company.logo_src ? (
                      <CreateIcon
                        className={classes.iconColorScheme}
                        onClick={() => {
                          setShowForm(true);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                    <div>
                      {/* <List>
												<ListItem>
													<ListItemText
														className={classes.title}
														primary={"Address:"}
														secondary={
															site.address && (
																<div className={classes.subTitle}>
																	{position ? (
																		<a
																			className={classes.link}
																			target="_blank"
																			rel="noopener noreferrer"
																			href={`https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}&query_place_id=${placeID}`}
																		>
																			{site.address} <br />
																		</a>
																	) : (
																		<>
																			{site.address + ", " + site.city} <br />
																		</>
																	)}
																	{site.city ? site.city + ", " : ""}
																	{site.state ? site.state : ""}
																	{site.postal ? ", " + site.postal : ""} <br />
																	{site.country ? site.country : ""}
																</div>
															)
														}
														secondaryTypographyProps={{
															className: classes.subTitle,
														}}
													/>
												</ListItem>
											</List> */}
                      <div className={classes.title}>{companyDetail.Address[lang]}:</div>{" "}
                      {site.address && (
                        <div className={classes.subTitle}>
                          {position ? (
                            <a
                              className={classes.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}&query_place_id=${placeID}`}
                            >
                              {site.address} <br />
                            </a>
                          ) : (
                            <>
                              {site.address + ", " + site.city} <br />
                            </>
                          )}
                          {site.city ? site.city + ", " : ""}
                          {site.state ? site.state : ""}
                          {site.postal ? ", " + site.postal : ""} <br />
                          {site.country ? site.country : ""}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                    <div>
                      {/* <List>
												<ListItem>
													<ListItemText
														className={classes.title}
														primary={"Address:"}
														secondary={
															site.address && (
																<div className={classes.subTitle}>
																	{position ? (
																		<a
																			className={classes.link}
																			target="_blank"
																			rel="noopener noreferrer"
																			href={`https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}&query_place_id=${placeID}`}
																		>
																			{site.address} <br />
																		</a>
																	) : (
																		<>
																			{site.address + ", " + site.city} <br />
																		</>
																	)}
																	{site.city ? site.city + ", " : ""}
																	{site.state ? site.state : ""}
																	{site.postal ? ", " + site.postal : ""} <br />
																	{site.country ? site.country : ""}
																</div>
															)
														}
														secondaryTypographyProps={{
															className: classes.subTitle,
														}}
													/>
												</ListItem>
											</List> */}
                      <div className={classes.title}>{companyDetail.HoursOfOperation[lang]}:</div>{" "}
                        <div className={classes.subTitle}>
                          {site.hours_of_operation ? site.hours_of_operation: "N/A"}
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                    <div className={classes.title}>{companyDetail.Website[lang]}: </div>{" "}
                    <span className={classes.subTitle}>
                      {company.website ? (
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          className={classes.link}
                          onClick={() => {
                            let str =
                              company.website && company.website.toString();
                            if (
                              str &&
                              !(
                                str.startsWith("https://") ||
                                str.startsWith("http://")
                              )
                            )
                              str = "http://" + str;
                            if (str) window.open(str);
                          }}
                        >
                          {company.website}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                    <div className={classes.title}>{companyDetail.Contact[lang]}:</div>{" "}
                    <span className={classes.subTitle}>
                      {site.primary_contact ? site.primary_contact : "N/A"}
                    </span>
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                    <div className={classes.title}>{companyDetail.Phone[lang]}:</div>{" "}
                    <span className={classes.subTitle}>
                      {site.primary_contact_number
                        ? formatPhoneNumber(site.primary_contact_number)
                        : company.main_phone_number
                        ? formatPhoneNumber(company.main_phone_number)
                        : "N/A"}
                    </span>
                  </Grid>
                  <Grid item xs={12} className={classes.wrap}>
                   {hasPermission(props, ["Import"])&&<span
                      className={(classes.cursor, classes.importExport)}
                      onClick={() =>
                        hasPermission(props, ["Import"])
                          ? handleSetOpen(true)
                          : {}
                      }
                    >
                      {companyDetail.Import[lang]}
                    </span>}
                    <span className={classes.subTitle}>{"| "}</span>
                    {hasPermission(props, ["Export"])&&<span
                      className={(classes.cursor, classes.importExport)}
                      onClick={() =>
                        hasPermission(props, ["Export"]) ? exportToPdf() : {}
                      }
                    >
                      {companyDetail.Export[lang]}
                    </span>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {position && <CompanyGoogleMap {...props} position={position} />}
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12}>
              <span className={classes.title}>{companyDetail.Notes[lang]}: </span>
                {(companyNotes && collapsed) ? (
                  <Tooltip title="Less" placement="left">
                    <ExpandLessRoundedIcon
                      className={classes.arrowIcon}
                      fontSize="small"
                      cursor="pointer"
                      style={{ float: "right" }}
                      onClick={handleCollapse}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="More" placement="left">
                    <ExpandMoreRoundedIcon
                      className={classes.arrowIcon}
                      fontSize="small"
                      cursor="pointer"
                      style={{ float: "right" }}
                      onClick={handleCollapse}
                    />
                  </Tooltip>
                )}
                <Grid
                  item
                  xs={12}
                >
                <TextField
                  id="notes"
                  value={companyNotes}
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                </Grid>
                {/* <TextField
                  fullwidth
                  variant="filled"
                  max_length={500}
                  rows={2}
                  rowsMax={12}
                  // style={{
                  //   width: "-webkit-fill-available",
                  //   borderRadius: "0px",
                  // }}
                  value={companyNotes}
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <span className={classes.title}>{companyDetail.Tags[lang]}: </span>
                {/* {companyTags && (
                  <Tooltip title="More" placement="left">
                    <ExpandMoreRoundedIcon
                      className={classes.arrowIcon}
                      fontSize="small"
                      cursor="pointer"
                      style={{ float: "right" }}
                    />
                  </Tooltip>
                )} */}
              </Grid>
              <Grid
                id="tags"
                item
                xs={12}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "hidden",
                }}
              >
                {companyTags &&
                  companyTags.split(",").map((tag) => {
                    if (tag) {
                      return (
                        <Chip
                          label={
                            <span>
                            {tag}{" "}
                          </span>
                          }
                          size="small"
                          variant="outlined"
                          className={
                            tag === "Breach found!"
                              ? classes.dangerChip
                              : classes.chip
                          }
                        />
                      );
                    }
                  })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Dialog
              open={viewAll}
              onClose={() => setViewAll(false)}
              fullWidth
              maxWidth="lg"
            >
              <CompanyActivities data={activities} />
            </Dialog>
            <Button style={{float:"right"}} className={classes.iconColorScheme} onClick={()=>{setViewAll(true);}}>{companyDetail.ViewAll[lang]}</Button>
            <Typography component="h3" className={classes.title}>
              {companyDetail.RECENTACTIVITY[lang]}
            </Typography>
            <br/>
            <Divider
              classes={{ root: classes.divider }}
              component="hr"
              orientation="horizontal"
            />            
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeader}}>{companyDetail.UPDATE[lang]}</TableCell>
                  {props.user.is_tenant_admin && (
                    <TableCell align="left" classes={{ root: classes.tableHeader}}>{companyDetail.BY[lang]}</TableCell>
                  )}
                  <TableCell align="left" classes={{ root: classes.tableHeader}}>{companyDetail.DATE[lang]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.slice(0, 5).map((row) => (
                  <TableRow key={row.id} classes={{ root: classes.tableRow }}>
                    <TableCell component="th" scope="row" classes={{ root: classes.tableCell }}>
                      {row.update}
                    </TableCell>
                    {props.user.is_tenant_admin && (
                      <TableCell classes={{ root: classes.tableCellThemed }}>{row.modified_by}</TableCell>
                    )}
                    <TableCell classes={{ root: classes.tableCell }}>{formatDate(row.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
        classes={{
          paper: classes.siteList,
        }}
      >
        {(sites.length > 0 &&
          sites.map((siteItem) => {
            return (
              <Tooltip title={siteItem.site_name}>
                <MenuItem
                  onClick={() => handleMenuClick(siteItem.id)}
                  key={siteItem.id}
                  className={
                    siteItem.id === site.id
                      ? classes.menuSelected
                      : classes.menuItem
                  }
                  style={{ fontSize: "14px" }}
                >
                  {siteItem.site_name}
                </MenuItem>
              </Tooltip>
            );
          })) || (
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            Site list is empty
          </MenuItem>
        )}
      </Menu>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
        style={{ width: 300 }}
      >
        <ImportForm
          {...props}
          closeOpen={handleSetOpen}
          handleSnackBar={handleSnackBar}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showForm}
        onClose={() => setShowForm(false)}
        disableEnforceFocus
        classes={{ paper: classes.drawer }}
      >
        <CompanyForm
          {...props}
          closeOpen={() => setShowForm(false)}
          edit={true}
          objectId={company.id}
          handleObjectSubmit={handleObjectSubmit}
        />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(CompanyDetails);
