import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import http from "../../Common/RestAPIHandler";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import OfficeContactForm from "./OfficeContactForm";
import {dashboard} from "../../Common/Languages";
import {
  Drawer,
  Divider,
  TableSortLabel,
  TableContainer,
  TableCell,
  Paper,
} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import { formatPhoneNumber } from "../../Common/Utility";
import ScreenLoader from "../../Common/Loader";
import useStyles from "../Style/style";

const OfficeContact = (props) => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([], [contacts]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [showHiddenContacts, setShowHiddenContacts] = useState(false);
  var rowCount = 0;

  const lang = localStorage.getItem("languages");

  useEffect(() => {
    setLoadData(true);
    http.Get(
      "offices",
      (data) => {
        let c = data;
        c.sort((a, b) => {
          const c1 = a.name.toLowerCase().split(" ");
          const c2 = b.name.toLowerCase().split(" ");
          let comparison = 0;

          if (c1[0] < c2[0]) {
            comparison = -1;
          } else if (c2[0] > c1[0]) {
            comparison = 1;
          }
          return comparison;
        });
        setContacts(c);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setContact(collapse);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleAddClick = () => {
    setEdit(false);
    setOpen(true);
  };

  const handleSubmit = (item) => {
    setContacts([...contacts, item]);
    setEdit(false);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>{dashboard.OFFICECONTACTS[lang]}</span>
              {/* IconButton for show/hide office contacts should be Admin ONLY~ */}
              {/* <IconButton
                size="small"
                onClick={() => setShowHiddenContacts((prev) => !prev)}
              >
                {showHiddenContacts ? (
                  <Tooltip arrow={true} title="Hide" placement="right-end">
                    <VisibilityIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  <Tooltip arrow={true} title="Show" placement="right-end">
                    <VisibilityOffIcon fontSize="small" />
                  </Tooltip>
                )}
              </IconButton> */}
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
              {1 === 2 && (
                <SettingsIcon
                  fontSize="small"
                  className={classes.outlinedButtons}
                  onClick={handleAddClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <Collapse in={!collapse}>
            <Grid item xs={12} className={classes.contentGrid}>
              <TableContainer
                component={Paper}
                classes={{ root: classes.tableContainer }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        <TableSortLabel>{dashboard.NAME[lang]}</TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.ext} ${classes.tableHeader}`}
                      >
                        {dashboard.EXT[lang]}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        {dashboard.EMAIL[lang]}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        {dashboard.MOBILE[lang]}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <Grid container item lg={12}>
										<Divider
											classes={{ root: classes.dividers }}
											component="hr"
											orientation="horizontal"
										/>
									</Grid> */}

                  <TableBody>
                    <>
                      {contacts.map((row) => {
                        rowCount = rowCount + 1;
                        const c = (
                          <TableRow key={row.email}>
                            <TableCell
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                              component="th"
                              scope="row"
                              sortDirection="asc"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={`${classes.tableCell} ${classes.ellipsis} ${classes.ext}`}
                            >
                              {row.ext}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={`${classes.tableCell} ${classes.ellipsis}`}
                            >
                              {row.email}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={`${classes.tableCell} ${classes.ellipsis} ${classes.mobile}`}
                              x-ms-format-detection="none"
                            >
                              <a
                                id={row.id}
                                className={`${classes.link} ${classes.ellipsis}`}
                                href={"tel:" + formatPhoneNumber(row.mobile)}
                              >
                                {row.is_mobile_hidden
                                  ? ""
                                  : formatPhoneNumber(row.mobile)}
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                        if (row.is_hidden) {
                          return showHiddenContacts ? c : <> </>;
                        } else {
                          return c;
                        }
                      })}
                    </>
                  </TableBody>
                  <TableFooter></TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        )}
      </Grid>

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <OfficeContactForm
          closeOpen={handleSetOpen}
          handleContactSubmit={handleSubmit}
        />
      </Drawer>
    </div>
  );
};
export default OfficeContact;
