import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Dialog, IconButton } from "@material-ui/core";
import { formatDate } from "../../Common/Utility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ScreenLoader from "../../Common/Loader";
import CcMaster from "../../../Images/cc_master.png";
import CcVisa from "../../../Images/cc_visa.png";
import CcAmex from "../../../Images/cc_amex.png";
import CcUnknown from "../../../Images/cc_unknown.png";
import ObjectListMobile from "../../Common/ObjectListMobile";
import CreateIcon from "@material-ui/icons/Create";
import AccountingForm from "./AccountingForm";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  cell: {
    color: theme.colorScheme,
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 12,
  },
  icon: {
    color: theme.colorScheme,
  },
  paper: {
    padding: 15,
    height: 120,
    minWidth: 50,
  },
  paperIcon: {
    fontSize: "small",
    color: theme.colorScheme,
    cursor: "pointer",
  },
  loadingBlock: {
    backgroundColor: "#DDDDDD",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    top: "0px",
    left: "0px",
    opacity: ".8",
    filter: "alpha(opacity=80)",
  },
  absoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cardImage: {
    width: "100%",
    maxWidth: 130,
    maxHeight: 80,
  },
  mobilePaper: {
    [theme.breakpoints.down(445)]: {
      height: "max-content",
    },
  },
  drawer: {
    width: "75%",
    "@media (max-width: 430px)": {
      width: "-webkit-fill-available",
    },
  },
}));

const AccountingDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState(false);
  const [account, setAccount] = useState();
  const [balance, setBalance] = useState(0);
  const url = `invoices?company_id=${id}`;
  const [loading, setLoading] = useState();
  const [payments, setPayments] = useState(false);
  const [content, setContent] = useState();
  const [invoiceDetail, setInvoiceDetail] = useState();
  const [loadData, setLoadData] = useState(false);
  const [paymentInfoId, setPaymentInfoId] = useState();

  const handleObjectSubmit = () => {
    setPaymentInfoId(null);
    fetchData();
  };

  const tableColumns = [
    {
      id: "invoiceNumber",
      label: "INVOICE NUMBER",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "status",
      label: "STATUS",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "total",
      label: "TOTAL",
      minWidth: 100,
      sortable: true,
      // style: { float: "right" },
    },
    {
      id: "balance",
      label: "BALANCE",
      minWidth: 100,
      sortable: true,
      // style: { float: "right" },
    },
    {
      id: "dueDate",
      label: "DUE DATE",
      minWidth: 80,
      sortable: true,
    },
    {
      id: "type",
      label: "TYPE",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "email",
      label: "EMAIL",
      minWidth: 1,
    },
    {
      id: "payment",
      label: "",
      minWidth: 80,
      colored: true,
      style: commonConstants.cellCursor,
    },
    {
      id: "pay",
      label: "",
      minWidth: 80,
      colored: true,
      style: commonConstants.cellCursor,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (Array.isArray(data) && payments && balance) {
      data.forEach((invoice) => {
        if (payments[invoice.id]) {
          invoice.payment = (
            <div style={{ margin: "10px" }}>
              <VisibilityIcon
                onClick={() => {
                  setContent(
                    <Grid container spacing={1}>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        ReceiptId:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<ReceiptId>")[1]
                            .split("</ReceiptId>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        ReferenceNum:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<ReferenceNum>")[1]
                            .split("</ReferenceNum>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        ResponseCode:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<ResponseCode>")[1]
                            .split("</ResponseCode>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        TransDate:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<TransDate>")[1]
                            .split("</TransDate>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        TransTime:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<TransTime>")[1]
                            .split("</TransTime>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        Message:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<Message>")[1]
                            .split("</Message>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        TransAmount:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<TransAmount>")[1]
                            .split("</TransAmount>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        CardType:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<CardType>")[1]
                            .split("</CardType>")[0]
                        }
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: "bold" }}>
                        TransID:
                      </Grid>
                      <Grid item xs={8}>
                        {
                          payments[invoice.id]

                            .split("<TransID>")[1]
                            .split("</TransID>")[0]
                        }
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </div>
          );
        }
      });
      http.Get(
        `company_payment_infos?company_id=${id}`,
        (infos) => {
          var info = infos[0];
          setAccount(() => {
            return (
              <div>
                {info.company_name}
                <CreateIcon
                  className={classes.cursor}
                  style={{ float: "right" }}
                  onClick={() => {
                    setPaymentInfoId(info.id);
                  }}
                />
                <br />
                <Grid
                  container
                  spacing={3}
                  style={{ fontSize: 12 }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          CURRENT BALANCE
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h3"> $ {balance}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Paper
                      className={`${classes.paper} ${classes.mobilePaper}`}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              CARD INFO
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  {info &&
                                    info.card_number &&
                                    info.card_number.startsWith("3") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcAmex}
                                      />
                                    )}
                                  {info &&
                                    info.card_number &&
                                    info.card_number.startsWith("4") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcVisa}
                                      />
                                    )}
                                  {info &&
                                    info.card_number &&
                                    info.card_number.startsWith("5") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcMaster}
                                      />
                                    )}
                                  {info &&
                                    info.card_number &&
                                    !info.card_number.startsWith("5") &&
                                    !info.card_number.startsWith("4") &&
                                    !info.card_number.startsWith("3") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcUnknown}
                                      />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                  {info.card_number &&
                                    "***" +
                                      info.card_number.substring(
                                        info.card_number.length - 4
                                      )}
                                  <br />
                                  <br />
                                  Exp: &nbsp;
                                  {Math.floor(info.expiration / 100)}/
                                  {info.expiration % 100}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              PAYMENT INFO
                            </Grid>
                            <Grid item xs={12}>
                              Payment Type: &nbsp;
                              {info.payment_type}
                              <br />
                              <br />
                              Recurrence: &nbsp;
                              {info.payment_frequency_type} - Every{" "}
                              {info.payment_charge_day === 1 &&
                                info.payment_charge_day + "st"}
                              {info.payment_charge_day === 2 &&
                                info.payment_charge_day + "nd"}
                              {info.payment_charge_day === 3 &&
                                info.payment_charge_day + "rd"}
                              {info.payment_charge_day > 3 &&
                                info.payment_charge_day + "th"}
                              <br />
                              <br />
                              Payment Gateway: &nbsp;
                              {info.payment_gateway_type}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            );
          });
        },
        () => {}
      );
    }
  }, [data, payments, balance]);

  const handleStatus = (paidDate, isVoided) => {
    if (paidDate == null && !isVoided) {
      return "OPEN";
		} else if (paidDate){
			return "PAID"
    } else {
      return "ERROR";
    }
  };

  const fetchData = () => {
    let { keys } = Object;
    let { stringify } = JSON;
    //let interfaceAT = stringify(["items", "pageDetails"]);
    let interfaceAT = stringify(["items"]);

    // ? Historical payments made ??
    http.Get(
      `company_payments?company=${id}`,
      (res) => {
        var valueMap = {};
        res.map((payment) => {
          valueMap[payment.invoice_id] = payment.response_content;
        });
        setPayments(valueMap);
      },
      () => {}
    );

    setLoadData(true);

    http.Get(
      url,
      (data) => {
        //console.log(data);
        if(data.error){setLoading(data.error)}
        var itemBalance = 0;
        if (stringify(keys(data)) == interfaceAT) {
          // * Autotask
          let { items } = data;

          items.forEach((invoice) => {
            invoice.dueDate = formatDate(invoice.dueDate) || "N/A";
            invoice.status = handleStatus(invoice.paidDate, invoice.isVoided);
            invoice.pay = (
              <div style={{ margin: "10px" }}>
                <IconButton
                  disabled={invoice.invoiceTotal <= 0||invoice.status != "OPEN"}
                  //disabled={invoice.invoiceTotal <= 0}
                  onClick={() => {
                    setLoading("Processing......");
										delete invoice.pay;
										delete invoice.payment;
										delete invoice.status;
                    http.Post(
                      `invoices`,
                      invoice,
                      (res) => {
												console.log(res)
                        setLoading(res.message);
												fetchData();
                        setTimeout(() => {
                          setLoading(null);
                        }, 5000);
                      },
                      (status, error) => {
                        setLoading(status + ": " + error);
                        setTimeout(() => {
                          setLoading(null);
                        }, 50000);
                      }
                    );
                  }}
                >
                  <CreditCardIcon />
                </IconButton>
              </div>
            );
            if (!invoice.paidDate){
              itemBalance = itemBalance + invoice.invoiceTotal;
            }
            //itemBalance = itemBalance + invoice.invoiceTotal;
            invoice.total = `$${Number.parseFloat(invoice.invoiceTotal).toFixed(
              2
            )}`;
            invoice.balance = `$${Number.parseFloat(
              invoice.invoiceTotal
            ).toFixed(2)}`;
            invoice.type = invoice.type || "N/A";
            invoice.email = invoice.email || "N/A";
          });

          setData(items);
        } else {
          // * Connectwise
          data.forEach((item) => {
            item.dueDate = formatDate(item.dueDate);
            item.status = item.status.name;
            if (item.balance > 0) {
              item.pay = (
                <div style={{ margin: "10px" }}>
                  <CreditCardIcon
                    onClick={() => {
                      setLoading("Processing......");

                      http.Post(
                        `invoices`,
                        item,
                        (res) => {
                          fetchData();
                          setLoading(res.message);
                          setTimeout(() => {
                            setLoading(null);
                          }, 5000);
                        },
                        (status, error) => {
                          setLoading(status + ": " + error);

                          setTimeout(() => {
                            setLoading(null);
                          }, 5000);
                        }
                      );
                    }}
                  />
                </div>
              );
            } else {
              item.pay = <div style={{ margin: "10px" }}>&nbsp;</div>;
            }
            
            itemBalance = itemBalance + item.balance;
            item.total = Number.parseFloat(item.total).toFixed(2);
            item.balance = Number.parseFloat(item.balance).toFixed(2);
            setData(data);
          });
        }

        //console.log("itemBalance: ", itemBalance);
        setBalance(Number.parseFloat(itemBalance).toFixed(2));
        setLoadData(false);
      },
      () => {
        
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {loading && (
        <div className={classes.loadingBlock}>
          <div className={classes.absoluteCenter}>
            <h1>{loading}</h1>
          </div>
        </div>
      )}

      <Drawer
        anchor="right"
        open={Boolean(paymentInfoId)}
        onClose={() => setPaymentInfoId(null)}
        disableEnforceFocus
        classes={{ paper: classes.drawer }}
      >
        <AccountingForm
          {...props}
          closeOpen={() => setPaymentInfoId(null)}
          edit={true}
          objectId={paymentInfoId}
          handleObjectSubmit={handleObjectSubmit}
        />
      </Drawer>

      {content && (
        <Dialog
          open={true}
          onClose={() => {
            setContent(null);
          }}
        >
          <div
            style={{ padding: "20px", minWidth: "400px", minHeight: "300px" }}
          >
            {content}
          </div>
        </Dialog>
      )}

      {loadData && <ScreenLoader />}

      {!props.mobile && account && (
        <ObjectList
          {...props}
          moduleName={account}
          url={url}
          tableColumns={tableColumns}
          data={data}
          permissions={{
            create: ["Accounting"],
            edit: ["Accounting"],
            delete: ["Accounting"],
          }}
          fetchData={fetchData}
          orderBy={"dueDate"}
          order={"desc"}
          searchFields={["invoiceNumber"]}
          pageHeader={(props.user.company_name || "") + " | Accounting"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {props.mobile && account && (
        <ObjectListMobile
          {...props}
          moduleName={account}
          url={url}
          tableColumns={tableColumns}
          data={data}
          permissions={{
            create: ["Accounting"],

            edit: ["Accounting"],

            delete: ["Accounting"],
          }}
          fetchData={fetchData}
          orderBy={"dueDate"}
          order={"desc"}
          searchFields={["invoiceNumber"]}
          pageHeader={(props.user.company_name || "") + " | Accounting"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

export default AccountingDetails;
