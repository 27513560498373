import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {
  Divider,
  Typography,
  Paper,
  Grid,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";

import JsonDataForm from "./JsonDataForm";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CreateIcon from "@material-ui/icons/Create";

const JsonDataDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  var { jsonDataId } = props.match.params;
  const [jsonData, setJsonData] = useState({});
  const [jsonSchema, setJsonSchema] = useState({});
  const [dataMappings, setDataMappings] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.objectId) {
      jsonDataId = props.objectId;
    }
    http.Get(
      `json_datas/${jsonDataId}`,
      (jsonData) => {
        jsonData.json_data = JSON.parse(jsonData.json_data);
        setJsonData(jsonData);

        http.Get(
          `data_mappings?from_id=${jsonData.id}`,
          (data) => {
            setDataMappings(data);

            http.Get(
              `json_schemas/${jsonData.json_schema}?tenant=${props.companyTenant.id}`,
              (schema) => {
                setJsonSchema(JSON.parse(schema.json_schema));
                setLoading(false);
              },
              () => {}
            );
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && (
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography
              variant="h6"
              component="h6"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {jsonData.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  } else {
                    props.history.push(
                      process.env.PUBLIC_URL +
                        `/company/${id}/json_datas/${jsonData.json_schema}`
                    );
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={props.mobile ? 12 : 9}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <IconButton
                    className={classes.iconButtonColored}
                    style={{float:"right",marginBottom:-50}}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <CreateIcon/>
                </IconButton>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                    disableEnforceFocus
                    classes={{
                        paper: classes.drawerForm,
                    }}
                >
                    <div style={{ width: width }}>
                        <table>
                        <tbody>
                            <tr>
                            <td
                                draggable
                                style={{ cursor: "ew-resize" }}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                            >
                                <DragIndicatorIcon />
                            </td>
                            <td width="100%">
                                <JsonDataForm
                                    {...props}
                                    closeOpen={(open) => setOpen(open)}
                                    objectId={props.objectId}
                                    handleObjectSubmit={handleObjectSubmit}
                                />
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </Drawer>
                <Snackbar
                  open={Boolean(message)}
                  autoHideDuration={3000}
                  onClose={() => {
                    setMessage(null);
                  }}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={() => {
                      setMessage(null);
                    }}
                    severity="success"
                    elevation={6}
                    variant="filled"
                  >
                    {message}
                  </Alert>
                </Snackbar>
                <Grid container spacing={2}>
                  {jsonSchema.properties &&
                    Object.entries(jsonSchema.properties).map(
                      ([key, property]) => {
                        return (
                          <>
                            <Grid item xs={4}>
                              <span style={{ fontWeight: "bold" }}>{key}</span>
                            </Grid>
                            {property.type === "boolean" && (
                              <Grid item xs={8}>
                                {jsonData.json_data[key] && <CheckIcon />}
                              </Grid>
                            )}
                            {property.type === "password" && (
                              <Grid item xs={8}>
                                <FilterNoneIcon
                                  fontSize="small"
                                  className={classes.iconButtonColored}
                                  style={{
                                    cursor: "pointer",
                                    width: 15,
                                    verticalAlign: "bottom",
                                  }}
                                  onClick={() => {
                                    onCopy(props, jsonData.json_data, key);
                                    setMessage(key + "Copied");
                                  }}
                                />
                                &nbsp;&nbsp;
                                <span
                                  className={classes.cursor}
                                  onClick={(e) => {
                                    if (e.target.innerHTML === "············") {
                                      e.target.innerHTML = String(
                                        jsonData.json_data[key]
                                      )
                                        .replaceAll("<", "&lt;")
                                        .replaceAll(">", "&gt;");
                                    } else {
                                      e.target.innerHTML = "············";
                                    }
                                  }}
                                >
                                  ············
                                </span>
                              </Grid>
                            )}
                            {!["boolean", "password"].includes(
                              property.type
                            ) && (
                              <Grid item xs={8}>
                                {property.copyable && (
                                  <FilterNoneIcon
                                    fontSize="small"
                                    className={classes.iconButtonColored}
                                    style={{
                                      cursor: "pointer",
                                      width: 15,
                                      verticalAlign: "bottom",
                                
                                    }}
                                    onClick={() => {
                                      onCopy(props, jsonData.json_data, key);
                                      setMessage(key + "Copied");
                                    }}
                                  />
                                )}
                                <span
                                  style={{ whiteSpace: 'pre-wrap',verticalAlign: 'bottom',}} 
                                  >
                                    {jsonData.json_data[key]}
                                </span>
                              </Grid>
                            )}
                          </>
                        );
                      }
                    )}
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
          {!props.mobile && (
            <Grid item xs={3}>
              <Paper>
                <div
                  style={{
                    maxHeight: "calc(100vh - 280px)",
                    minHeight: "calc(100vh - 280px)",
                    overflowY: "auto",
                    overflowX: "auto",
                    marginLeft: 10,
                    padding: 10,
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Related Entries:</p>
                  <DataMappings
                    {...props}
                    dataMappings={dataMappings}
                    setDataMappings={null}
                  />
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default JsonDataDetails;
