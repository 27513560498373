import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Dialog } from "@material-ui/core";
import { formatDate } from "../../Common/Utility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ScreenLoader from "../../Common/Loader";
import CreateIcon from "@material-ui/icons/Create";
import CcMaster from "../../../Images/cc_master.png";
import CcVisa from "../../../Images/cc_visa.png";
import CcAmex from "../../../Images/cc_amex.png";
import CcUnknown from "../../../Images/cc_unknown.png";
import ObjectListMobile from "../../Common/ObjectListMobile";
import {invoices} from "../../Common/Languages";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  cell: {
    color: theme.colorScheme,
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
    float: 'right'
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 12,
  },
  icon: {
    color: theme.colorScheme,
  },
  paper: {
    padding: 15,
    height: 130,
    minWidth: 50,
  },
  paperIcon: {
    fontSize: "small",
    color: theme.colorScheme,
    cursor: "pointer",
  },
  loadingBlock: {
    backgroundColor: "#DDDDDD",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    top: "0px",
    left: "0px",
    opacity: ".8",
    filter: "alpha(opacity=80)",
  },
  absoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cardImage: {
    width: "100%",
    maxWidth: 130,
    maxHeight: 80,
  },
  mobilePaper: {
    [theme.breakpoints.down(445)]: {
      height: "max-content",
    },
  },
  subscription: {
    overflowX: "auto",
    overflowY: "auto",
    height: "85px",
  },
  drawerForm: {
    overflowY: "hidden",
    width: "800px"
  },
  subTitle: {
    color: theme.subText
  },
}));

const Invoices = (props) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [account, setAccount] = useState();
  const [balance, setBalance] = useState(0);
  const url = `tenant_invoices?tenant=${props.user.tenant}`;
  const [paymentInfo, setPaymentInfo] = useState();
  const [loading, setLoading] = useState();
  const [content, setContent] = useState();
  const [loadData, setLoadData] = useState(false);
  const [packages, setPackages] = useState();
  const [tenant, setTenant] = useState();
  var date;

  const tableColumns = [
    {
      id: "id",
      label: invoices.INVOICEID[lang],
      minWidth: 100,
      sortable: true,
    },
    {
      id: "items",
      label: invoices.ITEMS[lang],
      minWidth: 100,
      sortable: true,
    },
    {
      id: "sub_total",
      label: invoices.SUBTOTAL[lang],
      minWidth: 100,
      sortable: true,
      style: { float: "right" },
    },
    {
      id: "discount",
      label: invoices.DISCOUNT[lang],
      minWidth: 100,
      sortable: true,
      style: { float: "right" },
    },
    {
      id: "total",
      label: invoices.TOTAL[lang],
      minWidth: 100,
      sortable: true,
      style: { float: "right" },
    },
    {
      id: "period",
      label: invoices.PERIOD[lang],
      minWidth: 100,
      sortable: true,
    },
    {
      id: "payments",
      label: "",
      minWidth: 80,
      colored: true,
      style: commonConstants.cellCursor,
    },
    {
      id: "pay",
      label: "",
      minWidth: 80,
      colored: true,
      style: commonConstants.cellCursor,
    },
  ];

  useEffect(() => {
    if (data && packages && paymentInfo && tenant) {
      setLoadData(true);
      http.Get(
        `tenant_versions?tenant_id=${paymentInfo.tenant}`,
        (versions) => {
          setAccount(() => {
            setLoadData(false);
            return (
              <div>
                {paymentInfo.company_name}
                <br />
                <Grid
                  container
                  spacing={3}
                  style={{ fontSize: 12 }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                        {invoices.CURRENTBALANCE[lang]}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h3"> $ {balance}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Paper
                      className={`${classes.paper} ${classes.mobilePaper}`}
                    >
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              {invoices.CARDINFO[lang]}                              
                              <CreateIcon className={classes.cursor} onClick={()=>{
                                props.history.push(process.env.PUBLIC_URL + "/settings/subscription");
                              }}/>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>
                                  {paymentInfo &&
                                    paymentInfo.card_number &&
                                    paymentInfo.card_number.startsWith("3") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcAmex}
                                      />
                                    )}
                                  {paymentInfo &&
                                    paymentInfo.card_number &&
                                    paymentInfo.card_number.startsWith("4") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcVisa}
                                      />
                                    )}
                                  {paymentInfo &&
                                    paymentInfo.card_number &&
                                    paymentInfo.card_number.startsWith("5") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcMaster}
                                      />
                                    )}
                                  {paymentInfo &&
                                    paymentInfo.card_number &&
                                    !paymentInfo.card_number.startsWith("5") && 
                                    !paymentInfo.card_number.startsWith("4") && 
                                    !paymentInfo.card_number.startsWith("3") && (
                                      <img
                                        className={classes.cardImage}
                                        src={CcUnknown}
                                      />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                  {paymentInfo.card_number &&
                                    "***" +
                                    paymentInfo.card_number.substring(
                                      paymentInfo.card_number.length - 4
                                      )}
                                  <br />
                                  <br />
                                  {invoices.Exp[lang]}&nbsp;
                                  {Math.floor(paymentInfo.expiration / 100)}/
                                  {paymentInfo.expiration % 100}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                    </Paper>
                  </Grid><Grid item xs={12} sm={12} md={4}>
                    <Paper
                      className={`${classes.paper} ${classes.mobilePaper}`}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {invoices.SUBSCRIPTION[lang]}
                          <CreateIcon className={classes.cursor} onClick={()=>{
                            props.history.push(process.env.PUBLIC_URL + "/settings/subscription");
                          }}/>
                        </Grid>
                        <Grid item xs={12}>
                        <Grid container spacing={1} className={classes.subscription}>
                          {versions &&
                            versions.map((version)=>{
                              var serialized_data = JSON.parse(version.serialized_data);
                              return (
                                <Grid item xs={12}>
                                  {packages[serialized_data[0].fields.package]} &nbsp;&nbsp;&nbsp;&nbsp;
                                  + {serialized_data[0].fields.user_addons || 0} {invoices.users[lang]}&nbsp;&nbsp;&nbsp;&nbsp;
                                  + {serialized_data[0].fields.readonly_addons || 0} {invoices.readonly[lang]}&nbsp;&nbsp;&nbsp;&nbsp;
                                  + {serialized_data[0].fields.portal_addons || 0} {invoices.portals[lang]}&nbsp;&nbsp;&nbsp;&nbsp;
                                  <br/>{serialized_data[0].fields.is_monthly ? "Monthly" : "Annual"} @ {formatDate(version.date_created)}
                                  </Grid>
                              );
                            })
                          }
                        </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            );
          });
        },
        () => {
          setLoadData(false);
        }
      ); 
    }
  }, [data, packages, paymentInfo, tenant]);

  useEffect(() => {
    http.Get(
      `packages`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].package_name;
        }
        setPackages(valueMap);
      },
      () => {
      }
    );

    http.Get(
      `tenants/${props.user.tenant}`,
      (data) => {
        setTenant(data);
      },
      () => {
      }
    );

    http.Get(
      `tenant_payment_infos?tenant=${props.user.tenant}`,
      (data) => {
        if(data.length==1){
          setPaymentInfo(data[0]);
          setLoadData(false);
        }
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);


  useEffect(() => {
    if(url){
      fetchData();
    }
  }, [url]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var itemBalance = 0;
        data.forEach((item) => {
          item.due_date = formatDate(item.due_date);
          if(item.day){
            item.period = item.year + '-' + (item.month>9?(item.month):('0' + item.month)) + '-' + item.day + ' to ' + item.due_date;
          }
          else{
            item.period = item.year + '-' + (item.month>9?(item.month):('0' + item.month))
          }
          item.balance = item.total - item.payments;
					item.items = (
						<div>
							{item.items.map((i) => {
								return <div>{i.name}: ${i.price} * {i.quantity}</div>;
							})}
						</div>
					);
          if (item.balance === item.total && item.total > 0) {
            item.pay = (              
              <div style={{ margin: "10px" }}>
                <CreditCardIcon
                  onClick={() => {
                    setLoading("Processing......");
                    http.Get(
                      `process_tenant_invoice?invoice_id=${item.id}`,
                      (res) => {
                        fetchData();
                        setLoading(res.message);
                        setTimeout(() => {
                          setLoading(null);
                        }, 5000);
                      },
                      (status, error) => {
                        setLoading(status + ": " + error.error);
                        setTimeout(() => {
                          setLoading(null);
                        }, 5000);
                      }
                    );
                  }}
                />
              </div>
            );
          }
          else{
            item.pay = (              
              <div style={{ margin: "10px" }}>&nbsp;</div>
            );
          }

          if(item.payments>0){
            item.payments = (              
              <div style={{ margin: "10px" }}>
                <VisibilityIcon
                  onClick={() => {
                    http.Get(`tenant_payments?tenant_invoice=${item.id}`,
                      (payments)=>{
                        setContent(
                          <div>
                            {payments.map((payment)=>{
                              if(payment.response_content.startsWith("https://")){
                                window.open(payment.response_content);															
                                setTimeout(() => {
                                  setContent(null);
                                }, 1000);
                                return <a href={payment.response_content} target="_blank">Receipt</a>;
                              }
                              else{
                                return payment.response_content;
                              }
                            })}
                          </div>
                        );
                      },
                      ()=>{
                      }
                    );
                  }}
                />
              </div>
            );
          }
          else{
            item.payments = (              
              <div style={{ margin: "10px" }}>&nbsp;</div>
            );
          }
          itemBalance = itemBalance + item.balance;
          item.sub_total = Number.parseFloat(item.sub_total).toFixed(2);
          item.total = Number.parseFloat(item.total).toFixed(2);
          item.balance = Number.parseFloat(item.balance).toFixed(2);
        });
        setBalance(Number.parseFloat(itemBalance).toFixed(2));
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {loading && (
        <div className={classes.loadingBlock}>
          <div className={classes.absoluteCenter}>
            <h1>{loading}</h1>
          </div>
        </div>
      )}

      {content && (
        <Dialog
          open={true}
          onClose={() => {
            setContent(null);
          }}
        >
          <div
            style={{ padding: "20px", minWidth: "400px", minHeight: "300px" }}
          >
            {content}
          </div>
        </Dialog>
      )}

      {loadData &&
        <ScreenLoader/>
      }

      {!props.mobile && account && (
        <ObjectList
          {...props}
          moduleName={account}
          url={url}
          tableColumns={tableColumns}
          data={paymentInfo.invoices?data:[]}
          fetchData={fetchData}
          orderBy={"due_date"}
          searchFields={["id","due_date"]}
          pageHeader={(props.user.company_name || "") + " | Invoices"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}

      {props.mobile && account && (
        <ObjectListMobile
          {...props}
          moduleName={account}
          url={url}
          tableColumns={tableColumns}
          data={paymentInfo.invoices?data:[]}
          fetchData={fetchData}
          orderBy={"period"}
          searchFields={["id","period"]}
          pageHeader={(props.user.company_name || "") + " | Invoices"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}

      {!account && !loadData && tenant && tenant.tenant_billing_enabled && (
        <div style={{width:'260px'}}>
          <br/>
          <span className={classes.subTitle}>Please setup your payment info</span> 
          <CreateIcon className={classes.cursor} onClick={()=>{
            props.history.push(process.env.PUBLIC_URL + "/settings/subscription");
          }}/>
        </div>
      )}

      {!account && !loadData && tenant && !tenant.tenant_billing_enabled && (
        <div style={{width:'300px'}}>
          <br/>
          <span className={classes.subTitle}>Please contact your reseller for invoices!</span> 
        </div>
      )}

    </div>
  );
};

export default Invoices;
