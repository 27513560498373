import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-app-polyfill/ie9";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { store, persistor } from "./redux-tools/configureStore";
import App from "./App";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import {
  getColorScheme,
  getColorSchemeLight,
  isDarkEnabled,
  isInit,
} from "./Components/Common/Utility";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
require("dotenv").config();
// import commonConstraints from "./Components/Common/CommonConstants";

// const background = commonConstraints.background;

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  palette: {
    background: {
      default: isDarkEnabled() ? "#2a2d38" : "#fafafa",
    },
  },
  borderColor: isDarkEnabled() ? "#9c9ba0" : "black",
  borderColorAlt: isDarkEnabled() ? "#a2aac3" : "black",
  buttonClickableBorder: getColorScheme(),
  buttonClickableBorderAlt: getColorSchemeLight(),
  buttonClickableText: isDarkEnabled() ? "#9c9ba0" : "inherit",
  buttonDisabled: isDarkEnabled() ? "#5d5e6a" : "black",
  ckEditorBackground: isDarkEnabled() ? "#383c4b" : "#fafafa",
  ckEditorButtonHover: isDarkEnabled() ? "#333644" : "inherit",
  colorScheme: getColorScheme(),
  colorSchemeLight: getColorSchemeLight(),
  companyName: getColorScheme(),
  dialogs: isDarkEnabled() ? "#333644" : "#EFEFEF",
  dialogsAlt: isDarkEnabled() ? "#6e7491" : "white",
  dividers: isDarkEnabled() ? "#a2aac3" : "white",
  dividersLight: isDarkEnabled() ? "#646369" : "#e6e6e6",
  icons: isDarkEnabled() ? "#c8cddc" : "inherit",
  inputFields: isDarkEnabled() ? "#383c4b" : "white",
  linkColor: getColorScheme(),
  linkColorFaded: getColorSchemeLight(),
  linkColorHover: getColorSchemeLight(),
  logoBackground: isDarkEnabled() ? "#f4f4f4" : "#fff",
  rightBackground: isDarkEnabled() ? "#f4f4f4" : "#f6f6f6",
  menuButton: isDarkEnabled() ? "white" : "grey",
  navBarOne: isDarkEnabled() ? "#2a2d38" : "", // Top navbar
  navBarTwo: isDarkEnabled() ? "#333644" : "#f6f6f6", // Second from top navbar (has links)
  navBarLink: isDarkEnabled() ? "#9c9ba0" : "#4b4a4f",
  notification: isDarkEnabled() ? "#383c4b" : "", // #ECDCDF
  paginationButton: isDarkEnabled() ? getColorScheme() : "inherit",
  sidebar: isDarkEnabled() ? "#35353c" : "#fff",
  tableHeaderBG: isDarkEnabled() ? "#2a2d38" : "",
  tableHeaderTitle: isDarkEnabled() ? "#9c9ba0" : "",
  tableRowHover: isDarkEnabled() ? "#383c4b" : "#e6e6e6",
  tableRowHoverAlt: isDarkEnabled() ? "#333644" : "#EEEEEE",
  primary: {
    "& :hover": {
      visibility: "hidden",
    },
  },
  subText: isDarkEnabled() ? "#9c9ba0" : "grey",
  tiText: "#585555",
  text: isDarkEnabled() ? "#c8cddc" : "black",
  textAlert: "#c43030",
  textOther: "#3f70b7",
  textSuccess: "#0acf97",
  textWarning: "#f7bc16",
  textThemed: getColorScheme(),

  // ----------- Theme overrides for Material-UI components ----------- //
  overrides: {
    MuiButton: {
      outlined: {
        color: isDarkEnabled() ? "#9c9ba0" : "grey",
        borderColor: getColorSchemeLight(),
        "&:hover": {
          backgroundColor: getColorScheme() + " !important",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.$Mui-error": {
          borderColor: "blue",
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: isDarkEnabled() ? "#a2aac3" : "#c0c0c0",
      },
    },
    MuiIconButton: {
      root: {
        color: getColorSchemeLight(),
        "&:hover": {
          color: getColorScheme() + " !important",
        },
        // "&$disabled": isDarkEnabled() ? "#5d5e6a" : "black",
      },
    },
    MuiInputBase: {
      root: {
        // borderBottom: `1px solid ${isDarkEnabled() ? "#9c9ba0" : "grey"}`,\
        borderColor: isDarkEnabled() ? "#a2aac3 !important" : "",
        // "&:hover": {
        //   borderColor: isDarkEnabled() ? getColorScheme() + " !important" : "",
        "&:hover": {
          borderColor: isInit()
            ? "#e4e643" + " !important"
            : isDarkEnabled()
            ? getColorScheme()
            : "",
        },
        "&$focused": {
          "& > *": {
            borderColor: isInit()
              ? "#e4e643" + " !important"
              : getColorScheme() + " !important",
          },
        },
        "&:after": {
          borderColor: isInit()
            ? "#e4e643" + " !important"
            : getColorScheme() + " !important",
        },
        "&.$Mui-disabled": {
          border: "none",
          "&:hover": {
            borderColor: "none !important",
          },
        },
      },
      input: {
        color: isDarkEnabled() ? "#9c9ba0" : "black",
      },
    },
    MuiInput: {
      root: {
        borderBottomColor: isDarkEnabled() ? "#9c9ba0" : "black",
      },
      underline: {
        "&:before": {
          borderBottom: "None !important",
        },
        "&:hover:before": {
          borderBottom: "None !important",
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        "& $.MuiIconButton": {
          root: {
            color: "red",
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: isInit()
            ? "grey" + " !important"
            : getColorScheme() + " !important",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: isDarkEnabled() ? "#9c9ba0" : "grey",
      },
    },
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          // Error color on helper text of input components
          color: isDarkEnabled() ? "#c8cddc" : "#F44336",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderColor: isDarkEnabled() ? getColorSchemeLight() : "#c0c0c0",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkEnabled() ? getColorScheme() : "#999999",
          },
        },
        "&.Mui-error": {
          // Error border around input components on error
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isDarkEnabled() ? "red" : "#F44356",
          },
        },
      },
      notchedOutline: {
        borderColor: isDarkEnabled() ? "#a2aac3" : "",
      },
    },
    MuiListItem: {
      divider: {
        backgroundColor: "#c8cddc",
      },
    },
    MuiPaper: {
      root: {
        color: isDarkEnabled() ? "#9c9ba0" : "inherit",
        backgroundColor: isDarkEnabled() ? "#383c4b" : "#f4f4f4",
      },
    },
    MuiPickersDay: {
      day: {
        color: getColorScheme(),
      },
      daySelected: {
        backgroundColor: getColorScheme(),
      },
      current: {
        color: getColorScheme(),
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {},
      dayLabel: {
        color: isDarkEnabled() ? "#9c9ba0" : "grey",
      },
      iconButton: {
        backgroundColor: "None !important",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: getColorScheme(),
      },
    },
    MuiPickersClockPointer: {
      noPoint: {
        backgroundColor: getColorScheme(),
      },
      pointer: {
        backgroundColor: getColorScheme(),
      },
      thumb: {
        border: "14px solid" + getColorScheme(),
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: getColorScheme(),
      },
    },
    MuiRadio: {
      root: {
        color: getColorScheme(),
        colorPrimary: getColorScheme(),
        "&$checked": {
          color: getColorScheme(),
          colorPrimary: getColorScheme(),
          colorSecondary: getColorScheme(),
        },
        colorSecondary: getColorScheme(),
        "&$checked": {
          color: getColorScheme(),
          colorPrimary: getColorScheme(),
          colorSecondary: getColorScheme(),
        },
      },
      colorPrimary: {
        "&$checked": {
          color: getColorScheme(),
          colorPrimary: getColorScheme(),
          colorSecondary: getColorScheme(),
        },
      },
      colorSecondary: {
        "&$checked": {
          color: getColorScheme(),
          colorPrimary: getColorScheme(),
          colorSecondary: getColorScheme(),
        },
      },
    },
    MuiSelect: {
      icon: {
        color: getColorScheme(),
      },
    },
    MuiSlider: {
      root: {
        color: getColorScheme(),
        colorPrimary: getColorScheme(),
      },
      colorPrimary: {
        color: getColorScheme(),
      },
    },
    MuiStepIcon: {
      active: {
        color: getColorScheme() + " !important",
      },
      completed: {
        color: getColorScheme() + " !important",
      },
    },
    MuiTableCell: {
      root: {
        border: "None",
        borderBottom: "None",
      },
      head: {
        borderBottom: "1px solid rgb(162, 170, 195)",
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App theme={theme} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
