
import { Select, MenuItem, Grid, Typography, Divider, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
const invoicing = {
  'CW':"CONNECTWISE",
  'AT':"AUTOTASK",
  'Nin':"NINJA",
  'N/A':"NONE"
}
const useStyles = makeStyles((theme) => ({
  header: {
    height: 80,
    width: "100%",
    margin: "auto",
    paddingLeft: "10%",
    paddingRight: "10%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  footer: {
    height: 80,
    bottom: 0,
    width: "100%",
    margin: "auto",
    paddingLeft: "10%",
    paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    height: "calc(100vh - 170px)",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    margin: "auto",
    paddingRight: "10%",
    paddingLeft: "10%",
    paddingBottom: 20,
  },
  outlinedButton: {
    margin: 10,
    borderRadius: 25,
    textTransform: "capitalize",
    color: theme.colorScheme,
    borderColor: theme.colorScheme,
    // "&:hover": {
    // 	borderColor: theme.colorScheme,
    // },
  },
  gridItem: {
    textAlign: "right",
  },
  label: {
    marginBottom: 0,
    marginTop: 20,
    textTransform: "uppercase",
  },
}));

const PackageForm = props => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState();
  const [resellers, setResellers] = useState();
  const [packageTemplates, setPackageTemplates] = useState();
  const [packageTemplate, setPackageTemplate] = useState();
  const [objectId, setObjectId] = useState(props.objectId);

  useEffect(() => {
    http.Get(
      'resellers',
      (data) => {
        var resellerMap = {};
        data.map((reseller)=>{
          resellerMap[reseller.id] = reseller.name;
        });
        setResellers(resellerMap);
      },
      () => {
      }
    );

    http.Get(
      `package_templates`,
      (data) => {
        data.map((t)=>{
          t.reseller_monthly_price = t.monthly_price;
          t.reseller_annual_price = t.annual_price;
          t.reseller_portal_addon_price = t.portal_addon_price;
          t.reseller_user_addon_price = t.user_addon_price;
        });
        setPackageTemplates(data);
      },
      () => {
      }
    );
  }, []);

  useEffect(() => {
    if (resellers && packageTemplates) {
      setFormFields([
        { 
          id: "reseller", 
          label: "Reseller", 
          type: "select", 
          valueMap: resellers
        },
        { 
          id: "package_name", 
          label: "PACKAGE NAME *", 
          type: "text", 
        },
        {
          id: "description",
          label: "DESCRIPTION *",
          type: "text", 
        },
        {
          id: "monthly_price",
          label: "SUGGESTED MONTHLY PRICE",
          type: "text", 
        },
        {
          id: "reseller_monthly_price",
          label: "MONTHLY COST",
          type: "text", 
        },
        {
          id: "annual_price",
          label: "SUGGESTED ANNUAL PRICE",
          type: "text", 
        },
        {
          id: "reseller_annual_price",
          label: "ANNUAL COST",
          type: "text", 
        },
        {
          id: "portal_addon_price",
          label: "SUGGESTED PORTAL ADDON PRICE",
          type: "text", 
        },
        {
          id: "reseller_portal_addon_price",
          label: "PORTAL ADDON COST",
          type: "text", 
        },
        {
          id: "user_addon_price",
          label: "SUGGESTED READONLY ADDON PRICE",
          type: "text", 
        },
        {
          id: "reseller_user_addon_price",
          label: "READONLY ADDON COST",
          type: "text", 
        },
        {
          id: "max_company",
          label: "MAX COMPANY",
          type: "text", 
        },
        {
          id: "max_company_site",
          label: "MAX SITES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_employee",
          label: "MAX EMPLOYEES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_device",
          label: "MAX SERVERS & DEVICES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_license",
          label: "MAX SOFTWARE LICENSES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_other_service",
          label: "MAX SERVICES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_domain",
          label: "MAX DOMAINS PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_gallery",
          label: "MAX GALLERY IMAGES PER COMPANY",
          type: "text",
        },
        {
          id: "max_company_document",
          label: "MAX DOCUMENTS PER COMPANY",
          type: "text",
        },
        {
          id: "max_resource",
          label: "MAX RESOURCE",
          type: "text",
        },
        {
          id: "max_vendor",
          label: "MAX VENDOR",
          type: "text",
        },
        {
          id: "max_service",
          label: "MAX SERVICE",
          type: "text",
        },
        {
          id: "max_custom_tab",
          label: "MAX CUSTOM TAB",
          type: "text",
        },
        {
          id: "max_custom_tab_entry",
          label: "MAX ENTRIES PER CUSTOM TAB",
          type: "text",
        },
        {
          id: "max_portal",
          label: "MAX PORTAL",
          type: "text", 
        },
        { 
          id: "default_invoicing", 
          label: "Default invoicing", 
          type: "select", 
          valueMap: invoicing
        },
        {
          id: "is_moneris_module_available",
          label: "IS MONERIS MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_connect_sync_available",
          label: "IS CONNECT SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_ninja_sync_available",
          label: "IS NINJA SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_syncro_sync_available",
          label: "IS SYNCRO SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_autotask_sync_available",
          label: "IS AUTOTASK SYNC AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_hr_module_available",
          label: "IS HR MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_meeting_module_available",
          label: "IS MEETING MODULE AVAILABLE",
          type: "radio", 
        },
        {
          id: "is_hidden",
          label: "IS HIDDEN",
          type: "radio", 
        },
      ]);
    }
  }, [resellers, packageTemplates]);

  return(
    <>
    {packageTemplates && !packageTemplate && !objectId &&
      <div>
        <div className={classes.header}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={8}>
              <Typography variant="h6" component="h6">
                {objectId ? "Edit Package" : "Add New Package"}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => props.closeOpen(false)}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      
        <Divider />
        <div className={classes.content}>
          <p className={classes.label}>Select template</p>
          <Select
            fullWidth
            variant="outlined"
            margin="dense">
              {packageTemplates.map((template)=>{
                return (
                    <MenuItem onClick={()=>{
                      setPackageTemplate(template);
                    }}>
                      {template.package_name}
                    </MenuItem>
                  );
              })}
          </Select>
        </div>
            
        <Divider />
        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
          
      </div>
    }
    {formFields && (packageTemplate || objectId) &&
    <ObjectForm 
      {...props} 
      url={`packages`} 
      formName={"Package"} 
      formFields={formFields} 
      object={{...packageTemplate, package_template:(packageTemplate?packageTemplate.id:null)}}/>
    }
    </>
  );
};

export default PackageForm;
