import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { set } from "date-fns";

export var device_status = 0;
export function setDS(prop){
  device_status = prop;
}

const ApiForm = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [error, setError] = useState(false);
  const [deviceError, setDeviceError] = useState({});
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);


  const handleClose = () => {
    setOpen(false);
    setUser(null);
  };
  const handleClose1 = () => {
    setError(false);
    setUser(null);
  };

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `tenant_users`,
      (data) => {
        var userList = [];
        for (let i = 0; i < data.length; i++) {
          userList.push(data[i]);
        }
        setUsers(userList);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();
    http.Post(
      `api_key`,
      {'user':user,'dailyCall':10},
      (pair) => {
          setKey(pair.apiKey);
          setSecret(pair.secret);
          setLoadData(false);
          setOpen(true)
        },
      (status, error) => {
        setError(true)
        setLoadData(false);
      }
    )
  }
  const onChange = (e) => {
    setUser(e.target.value);
  };

  return (
    <div className={classes.list}>
      <Dialog
        onClose={handleClose1}
        open={error}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Error happened while creating credential
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This user has already owned a key. Please choose another user. Note that one user can only have on key/value pair, if you want to regenerate a credential for this user, you will need to delete the existing pair first.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose1}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Attention
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Make sure you save both - you won't be able to access the secret again. 
          </Typography>
          <Typography gutterBottom>
            <b>Key : </b>{key}
          </Typography>
          <Typography gutterBottom>
            <b>Secret : </b>{secret}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              Generate new key/secret pair for a user
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <Grid container spacing={3} alignContent="space-between">

                <Grid item lg={12}>
                <p className={classes.label}>Users</p>
              <TextValidator
                id="user"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="user"
                value={user || ""}
                onChange={onChange}
                error={Boolean(deviceError.tag_type)}
                helperText={deviceError.tag_type && deviceError.tag_type[0]}
              >
                <MenuItem
                      key={"empty"}
                      value={null}
                      
                    >
                      {"NONE"}
                </MenuItem>
                {users && users.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No tags found
                  </MenuItem>
                ) : (
                  
                  users.map((option) => (
                
                    <MenuItem key={option.id} value={option.id}>
                      {option.first_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>
                </Grid>
              </Grid>
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="secondary"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
              onClick={() => props.objectId ?device_status=0:device_status=1}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default ApiForm;
