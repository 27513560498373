import { makeStyles } from "@material-ui/core/styles";
import commonConstraints from "../Common/CommonConstants";
const background = commonConstraints.background;

const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: background,
  },
  root: {
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  container: {
    minHeight: "100vh",
  },
  centerElement: {
    textAlign: "center",
  },
  rightElement: {
    textAlign: "right",
  },
  "@media (max-width: 375px)": {
    login: {
      height: "75vh",
    },
    logo: {
      width: "262.50px !important"
    },
  },
  link: {
    cursor: 'pointer',
    color: theme.linkColor,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  logo: {
    width: "50%",
    height: "auto",
  },
  button: {
    borderRadius: 5,
    // paddingLeft: 30,
    // paddingRight: 30,
    // backgroundColor: "#e37309",
    "&:hover": {
      backgroundColor: "#e37309",
      color: "white"
    },
  },
  error: {
    color: "red",
  },
  passwordField: {
    fontWeight: "bold",
  },
  title: {
    color: theme.text
  }
}));

export default useStyles;
