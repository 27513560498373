import React, { useState, useEffect } from "react";
import ObjectList from "../Common/ObjectList";
import ObjectListMobile from "../Common/ObjectListMobile";
import http from "../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	loadingBlock: {
		backgroundColor: "#DDDDDD",
		position: "fixed",
		width: "100%",
		height: "100%",
		zIndex: 10000,
		top: "0px",
		left: "0px",
		opacity: ".8",
		filter: "alpha(opacity=80)",
	},
	absoluteCenter: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	messageDiv: {
		maxHeight: 500,
		maxWidth: 500,
		overflowY: "auto",
		overflowX: "auto",
	},
	error: {
		color: "red",
	},
	outLinedButton: {
		borderColor: theme.buttonClickableBorderAlt,
		color: theme.buttonClickableText,
		"&:hover": {
			borderColor: theme.buttonClickableBorder
		}
	},
	saveButton: {
		margin: 10,
		borderRadius: 25,
		backgroundColor: theme.colorScheme,
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
			border: "1px solid white",
		},
	},
}));

const tableColumns = [
	{
		id: "name",
		label: "COMPANY NAME",
		minWidth: 200,
		sortable: true,
	},
	{
		id: "id",
		label: "CONNECTWISE ID",
		minWidth: 200,
		sortable: true,
	},
	{
		id: "sync",
		label: " ",
		minWidth: 50,
		sortable: false,
		disableTooltip: true,
	},
];

const ConnectWise = (props) => {
	const classes = useStyles();
	const [syncing, setSyncing] = useState();
	const [errors, setErrors] = useState([]);
	const [data, setData] = useState([]);
	const [messages, setMessages] = useState([]);

	const actions = {
		delete: false,
		importCSV: false,
		exportCSV: false,
		exportPDF: false,
		additionalActions: [
			{
				label: "SYNC",
				onClick: (ids) => {
					handleSync(ids);
				},
			},
		],
	};

	const fetchData = () => {
		setSyncing("Loading...");
		http.Get(
			"cw_companies/",
			(data) => {
				if (!data.error) {
					data.map((cwCompany) => {
						cwCompany.sync = (
							<Button
								variant="outlined"
								margin="dense"
								size="small"
								className={classes.outLinedButton}
								onClick={() => {
									handleSync([cwCompany.id]);
								}}
							>
								SYNC
							</Button>
						);
					});
					setData(data);
					setSyncing(null);
				} else {
					console.log(data.error)
					setSyncing(data.error);
					setTimeout(() => {
						setSyncing(null);
					}, 5000);
				}
			},
			() => {
				setSyncing("Connection error!");
				setTimeout(() => {
					setSyncing(null);
				}, 5000);
			}
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleSync = (objectIdList) => {
		var total = 0;
		var synced = 0;
		var failed = 0;
		var newErrors = [];
		var newMessages = [];

		total = objectIdList.length;
		setErrors(newErrors);
		setMessages(newMessages);
		setSyncing(
			" Synced:" + synced + "/" + total + " Failed:" + failed + "/" + total
		);
		var promises = objectIdList.map((objectId) => {
			var promise = http.Get(
				"sync/?cw_company_id=" + objectId,
				(data) => {
					if (data.error) {
						newErrors.push(synced + failed + 1);
						newErrors.push(data.error);
						setErrors(newErrors);
						failed = failed + 1;
						setSyncing(
							" Synced:" +
								synced +
								"/" +
								total +
								" Failed:" +
								failed +
								"/" +
								total
						);
					} else {
						newMessages.push(synced + failed + 1);
						newMessages = newMessages.concat(data.messages);
						setMessages(newMessages);
						synced = synced + 1;
						setSyncing(
							" Synced:" +
								synced +
								"/" +
								total +
								" Failed:" +
								failed +
								"/" +
								total
						);
					}
				},
				(status, error) => {
					newErrors.push(synced + failed + 1);
					newErrors.push(error);
					setErrors(newErrors);
					failed = failed + 1;
					setSyncing(
						" Synced:" +
							synced +
							"/" +
							total +
							" Failed:" +
							failed +
							"/" +
							total
					);
				}
			);
			return promise;
		});
		Promise.all(promises).then((texts) => {
			setTimeout(() => {
				setSyncing(null);
				// Make call to separate back end URL to initiate Celery task
				http.Put('eeye_after_sync/', objectIdList, (res) => { console.log(res) })
			}, 5000);
		});
	};

	return (
		<div>
			{syncing && (
				<div className={classes.loadingBlock}>
					<div className={classes.absoluteCenter}>
						<h1>{syncing}</h1>
						<div className={classes.messageDiv}>
							{errors.map((error) => {
								return <div className={classes.error}>{error}</div>;
							})}
							{messages.map((message) => {
								return <div>{message}</div>;
							})}
						</div>
					</div>
				</div>
			)}
			{props.mobile && data && (
				<ObjectListMobile
					{...props}
					moduleName={"ConnectWise Companies"}
					data={data}
					tableColumns={tableColumns}
					actions={actions}
					fetchData={fetchData}
					orderBy={"name"}
					searchFields={["name"]}
					pageHeader={
						(props.user.company_name || "") + " | ConnectWise Companies"
					}
					excludeAddButton={true}
				/>
			)}

			{!props.mobile && data && (
				<ObjectList
					{...props}
					moduleName={"ConnectWise Companies"}
					data={data}
					tableColumns={tableColumns}
					actions={actions}
					fetchData={fetchData}
					orderBy={"name"}
					searchFields={["name"]}
					pageHeader={
						(props.user.company_name || "") + " | ConnectWise Companies"
					}
					excludeAddButton={true}
				/>
			)}
		</div>
	);
};

export default ConnectWise;
