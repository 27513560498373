import React, { useState, useEffect } from "react";
import CredentialForm from "./CredentialForm";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import SnackbarComponent from "../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  passwordColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    fontFamily: "Raleway",
  },
}));


const tableColumns = [
  {
    id: "category_name",
    label: "ACCOUNT TYPE",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "username",
    label: "USERNAME",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "copyUsername",
    label: "",
    minWidth: 10,
    disableTooltip: true,
  },
  {
    id: "staredPassword",
    label: "PASSWORD",
    minWidth: 100,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "copyPassword",
    label: "",
    minWidth: 10,
    disableTooltip: true,
  },
  {
    id: "website",
    label: "WEBSITE",
    minWidth: 100,
    sortable: true,
    onClick: onWebsiteClick,
    style: commonConstants.cellCursor,
    colored: true,
  },
  {
    id: "notes",
    label: "NOTES",
    minWidth: 200,
    sortable: true,
  },
];

const Credentials = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const url = `employee/credential/?employee=${props.user.id}`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {

        data.map((item) => {

          item["copyUsername"] = (
            !item["username"]?"":<CopyIcon className={classes.iconColorScheme} onClick={(props,row,columnId)=>{onCopy(props,item,"username");setSnackBarMessage("Username Copied");}}/>
          );         

          item["copyPassword"] = (
            !item["password"]?"":<CopyIcon className={classes.iconColorScheme} onClick={(props,row,columnId)=>{onCopy(props,item,"password");setSnackBarMessage("Password Copied");}}/>
          );  

          item["staredPassword"] = (<span className={classes.passwordColorScheme} onClick={(e)=>{
              if (e.target.innerHTML === "************") e.target.innerHTML = item["password"]?item["password"].replaceAll("<","&lt;").replaceAll(">","&gt;"):"";
              else e.target.innerHTML = "************";}}>************</span>);
          
        });

        setData(data);
        setLoadData(false);
      },
      (status, error) => {
        setLoadData(false);
      },
      true
    );
  };

  return (
    <div>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={()=>{setSnackBarMessage(null);}}
        severity={"success"}
      ></SnackbarComponent>
      {!props.mobile &&
      <ObjectList
        {...props}
        moduleName={"Credentials"}
        url={url}
        tableColumns={tableColumns}
        actions={actions}
        data={data}
        fetchData={fetchData}
        objectForm={CredentialForm}
        orderBy={"category_name"}
        searchFields={["category_name"]}
        pageHeader={`${props.user.tenant.company_name} | ${props.user.company_name} Portal`}
        loadData={loadData}
      />
      }
      {props.mobile &&
      <ObjectListMobile
        {...props}
        moduleName={"Credentials"}
        url={url}
        tableColumns={tableColumns}
        actions={actions}
        data={data}
        fetchData={fetchData}
        objectForm={CredentialForm}
        orderBy={"category_name"}
        searchFields={["category_name"]}
        pageHeader={`${props.user.tenant.company_name} | ${props.user.company_name} Portal`}
        loadData={loadData}
      />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(Credentials);
