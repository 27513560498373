import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ConvertDateToLocale } from "../../Common/Utility";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "../../Common/Style/FormStyle";
import AlertDialog from "../../Common/AlertDialog";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";
import DataMappings from "../DataMappings";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { InputAdornment } from "@material-ui/core";
import { licenseLang } from "../../Common/Languages";

export var license_status = 0;
export function setLS(prop){
  license_status = prop;
}

const SoftwareLicenseForm = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [installDate, setInstallDate] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [isInvalidDate, setisInvalidDate] = useState(false);
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [softwareLicense, setSoftwareLicense] = useState({
    name: "",
    install_date: new Date(new Date().setHours(0, 0, 0, 0)),
    expiration_date: new Date(new Date().setHours(23, 59, 59, 999)),
    location: null,
    software_asset_tag: "",
    workstation_asset_tag: "",
    company: props.match.params.id,
  });
  const [licenseError, setLicenseError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [dataMappings, setDataMappings] = useState([]);
  const lang = localStorage.getItem("languages");

  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `software_licenses/${props.objectId}/`,
        (data) => {
          if (data.install_date)
            setInstallDate(ConvertDateToLocale(data.install_date));
          if (data.expiration_date)
            setExpirationDate(ConvertDateToLocale(data.expiration_date));

          setSoftwareLicense(data);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );

      http.Get(
        `data_mappings?from_id=${props.objectId}`,
        (data) => {
          setDataMappings(data);
        },
        () => {}
      );
    }

    http.Get(
      `companies/${id}/sites`,
      (sitesData) => {
        setSites(sitesData);
      },
      () => {}
    );

    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=License Type`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );
  }, []);

  const handleChange = (prop) => (event) => {
    setisInvalidDate(false);
    setSoftwareLicense({ ...softwareLicense, [prop]: event.target.value });
    setLicenseError({ ...licenseError, [prop]: false });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    setisInvalidDate(false);
    if (installDate && expirationDate) {
      setInstallDate(new Date(installDate.setHours(0, 0, 0, 0)));
      setExpirationDate(new Date(expirationDate.setHours(23, 59, 59, 999)));
      if (installDate > expirationDate) {
        setisInvalidDate(true);
        return;
      }
    }

    setisInvalidDate(false);
    setSoftwareLicense({ ...softwareLicense, install_date: installDate });
    setSoftwareLicense({ ...softwareLicense, expiration_date: expirationDate });

    var data = {
      ...softwareLicense,
      install_date: installDate,
      expiration_date: expirationDate,
    };
    setIsSubmitted(true);
    if (props.objectId) {
      http.Put(
        `software_licenses/${props.objectId}/`,
        data,
        (softwareLicense) => {
          http.Delete(
            "data_mappings",
            { from_id: props.objectId },
            () => {
              var promises = dataMappings.map((dataMapping) => {
                dataMapping.from_id = props.objectId;
                return http.Post(
                  "data_mappings",
                  dataMapping,
                  (data) => {
                    console.log(data);
                  },
                  (status, error) => {
                    console.log(status);
                    console.log(error);
                  }
                );
              });
              Promise.all(promises).then((results) => {
                props.handleObjectSubmit();
                setLoadData(false);
              });
            },
            () => {
              props.handleObjectSubmit();
              setLoadData(false);
            }
          );
        },
        (status, error) => {
          if (status === 400) setLicenseError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `software_licenses/`,
        data,
        (softwareLicense) => {
          var promises = dataMappings.map((dataMapping) => {
            dataMapping.from_id = softwareLicense.id;
            return http.Post(
              "data_mappings",
              dataMapping,
              (data) => {
                console.log(data);
              },
              (status, error) => {
                console.log(status);
                console.log(error);
              }
            );
          });
          Promise.all(promises).then((results) => {
            props.handleObjectSubmit();
            setLoadData(false);
          });
        },
        (status, error) => {
          if (status === 400) setLicenseError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.objectId
                ? licenseLang.EditSoftwareLicense[lang]
                : licenseLang.AddNewSoftwareLicense[lang]}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ValidatorForm onSubmit={onSubmit}>
          <div className={classes.content}>
            {loadData ? (
              <ScreenLoader />
            ) : (
              <div>
                <p className={classes.label}>{licenseLang.NAME[lang]+' *'}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="name"
                    value={softwareLicense.name || ""}
                    onChange={handleChange("name")}
                    inputProps={{ maxLength: 128 }}
                    validators={["required", "trim"]}
                    errorMessages={[
                      "This field is required",
                      "This field may not be blank.",
                    ]}
                    error={Boolean(licenseError.name)}
                    helperText={licenseError.name && licenseError.name[0]}
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.LICENSETYPE[lang]}</p>
                <TextValidator
                  id="category"
                  fullWidth
                  select
                  variant="outlined"
                  margin="dense"
                  name="category"
                  value={softwareLicense.category || ""}
                  onChange={handleChange("category")}
                  error={Boolean(licenseError.category)}
                  helperText={licenseError.category && licenseError.category[0]}
                >
                  {categories && categories.length > 0 && (
                    <MenuItem value={""}>{licenseLang.NONE[lang]}</MenuItem>
                  )}
                  {categories && categories.length === 0 ? (
                    <MenuItem value={""} selected disabled>
                      {licenseLang.helper[lang]}
                    </MenuItem>
                  ) : (
                    categories.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.category_name}
                      </MenuItem>
                    ))
                  )}
                </TextValidator>

                <p className={classes.label}>{licenseLang.SERIALNUMBER[lang]+' *'}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="serial-number"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="serial_number"
                    inputProps={{ maxLength: 256 }}
                    value={softwareLicense.serial_number || ""}
                    onChange={handleChange("serial_number")}
                    validators={["required", "trim"]}
                    errorMessages={[
                      licenseLang.helper1[lang],
                      licenseLang.helper2[lang],
                    ]}
                    error={Boolean(licenseError.serial_number)}
                    helperText={
                      licenseError.serial_number &&
                      licenseError.serial_number[0]
                    }
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.INSTALLDATE[lang]}</p>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    fullWidth
                    value={installDate}
                    onChange={(value) => {
                      setInstallDate(value);
                      setisInvalidDate(false);
                    }}
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.EXPIRATIONDATE[lang]}</p>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    fullWidth
                    value={expirationDate}
                    onChange={(value) => {
                      setExpirationDate(value);
                      setisInvalidDate(false);
                    }}
                  />
                </Grid>

                {isInvalidDate && (
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <p style={{ color: "red" }}>
                        {licenseLang.helper3[lang]}
                      </p>
                    </Grid>
                  </Grid>
                )}

                <p className={classes.label}>{licenseLang.LOCATION[lang]+' *'}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="location"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    name="location"
                    value={softwareLicense.location || ""}
                    onChange={handleChange("location")}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >
                    
                    {sites && sites.length === 0 ? (
                      <MenuItem value={""} selected disabled>
                        {licenseLang.Nolocationsfound[lang]}
                      </MenuItem>
                    ) : (
                      sites.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.site_name}
                        </MenuItem>
                      ))
                    )}
                  </TextValidator>
                </Grid>

                <p className={classes.label}>{licenseLang.SOFTWAREASSETTAG[lang]}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="software-asset-tag"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="software_asset_tag"
                    value={softwareLicense.software_asset_tag || ""}
                    inputProps={{ maxLength: 256 }}
                    onChange={handleChange("software_asset_tag")}
                    error={Boolean(licenseError.software_asset_tag)}
                    helperText={
                      licenseError.software_asset_tag &&
                      licenseError.software_asset_tag[0]
                    }
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.WORKSTATIONASSETTAG[lang]}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="workstation-asset-tag"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="workstation_asset_tag"
                    value={softwareLicense.workstation_asset_tag || ""}
                    onChange={handleChange("workstation_asset_tag")}
                    inputProps={{ maxLength: 256 }}
                    error={Boolean(licenseError.workstation_asset_tag)}
                    helperText={
                      licenseError.workstation_asset_tag &&
                      licenseError.workstation_asset_tag[0]
                    }
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.NOTES[lang]}</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="notes"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows={3}
                    name="notes"
                    value={softwareLicense.notes || ""}
                    onChange={handleChange("notes")}
                    error={Boolean(licenseError.notes)}
                    helperText={licenseError.notes && licenseError.notes[0]}
                  />
                </Grid>

                <p className={classes.label}>{licenseLang.RelatedEntries[lang]}</p>
                <DataMappings
                  {...props}
                  fromId={props.objectId}
                  fromModel={"License"}
                  dataMappings={dataMappings}
                  setDataMappings={setDataMappings}
                />
              </div>
            )}
          </div>

          <Divider />

          <div className={classes.footer}>
            <div className={classes.outlinedButtonContainer}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => props.closeOpen(false)}
              >
                {licenseLang.Cancel[lang]}
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.filledButton}
                type="submit"
                disabled={isSubmitted}
                onClick={() => props.objectId ?license_status=0:license_status=1}
              >
                {licenseLang.Save[lang]}
              </Button>
            </div>
          </div>
        </ValidatorForm>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};
export default connect(mapStateToProps, null)(SoftwareLicenseForm);
