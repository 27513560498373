import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    width: "100%",
  },
  addButton: {
    color: theme.buttonClickableText,
    borderColor: theme.buttonClickableBorder,
  },
  button: {
    margin: 10,
    borderRadius: 25,
    textTransform: "capitalize",
  },
  buttonContainer: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
  },
  chip: {
    margin: 10,
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  cloneButton: {
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    textTransform: "capitalize",
    textAlign: "center",
  },
  collapseButton: {
    color: theme.colorScheme,
    cursor: "pointer",
    fontSize: 32,
  },
	containerRow: {
		display: "flex",
		flexDirection: "row"
	},
  content: {
    // height: "calc(100vh - 149px)",
    height: "calc(100vh - 170px)",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    margin: "auto",
    paddingRight: "10%",
    paddingLeft: "10%",
    paddingBottom: 20,
  },
  cross: {
    textAlign: "right",
  },
  cursor: {
    cursor: "pointer",
  },
  dateTime: {
    textAlign: "center",
    width: 100,
    padding: 0,
  },
  disabled: {
    color: theme.buttonDisabled,
  },
  divider: {
    width: "100%",
    backgroundColor: theme.dividers,
  },
  drawerForm: {
    overflowY: "hidden",
  },
  download: {
    display: "inline-flex",
    cursor: "pointer",
  },
  // editor: {
  //   marginTop: 8,
  //   marginBottom: 4,
  //   backgroundColor: "None",
  //   "& .ck": {
  // 	backgroundColor: "None"
  //   },
  //   "& .ck-focused": {
  // 	borderColor: theme.colorScheme + "!important",
  //   },
  // },
  editor: {
    marginTop: 8,
    marginBottom: 4,
    "& .ck": {
      // background: "#d9d9d9",
      borderColor: theme.borderColorAlt + " !important",
      color: theme.subText,
      "& .ck-editor__main > *": {
        background: theme.ckEditorBackground,
        borderColor: theme.borderColorAlt,
      },
      // "& .ck-button": {
      //   "&:hover": {
      //     background: "#e6e6e6"
      //   },
      // },
    },
    "& .ck-focused": {
      borderColor: theme.colorScheme + "!important",
    },
  },
  editorToolbar: {
    backgroundColor: theme.ckEditorBackground,
  },
  error: {
    color: "#c43030",
		borderColor: "#c43030 !important"
  },
	errorHelper: {
		color: "#c43030",
		marginLeft: 15,
		fontSize: '1rem'
	},
  endIcons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fileInput: {
    padding: "0",
    margin: "0",
    height: "2.5em",
    width: "100%",
  },
  filledButton: {
    margin: 10,
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      opacity: 0.75,
    },
  },
  fileUpload: {
    border: "1px solid #ccc",
    padding: "5px 12px",
    cursor: "pointer",
    borderRadius: "5px",
    width: "100%"
  },
  footer: {
    height: 75,
    bottom: 0,
    width: "100%",
    margin: "auto",
    paddingLeft: "10%",
    paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    textAlign: "right",
  },
  guestField: {
    height: 80,
    overflow: "scroll",
  },
  header: {
    height: 75,
    width: "100%",
    margin: "auto",
    paddingLeft: "10%",
    paddingRight: "10%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  headOffice: {
    fontSize: "0.86rem",
    color: theme.text,
  },
  iconButton: {
    backgroundColor: "transparent !important",
  },
  iconButtonColored: {
		cursor: 'pointer',
    backgroundColor: "transparent !important",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
  },
  imageBorder: {
    border: "1px solid #ccc",
    padding: "5px 12px",
    marginTop: "5px",
    cursor: "pointer",
    borderRadius: "5px",
  },
  inlineLabel: {
    paddingTop: "20px",
    display: "inline-block",
    marginRight: "10px",
  },
  integrationHeader: {
    padding: "0px !important",
    height: 70,
    textAlign: "center",
  },
  integrationLogo: {
    width: "auto",
    maxHeight: 70,
    padding: 4,
  },
  label: {
    marginBottom: 0,
    marginTop: 20,
    textTransform: "uppercase",
    color: theme.text,
  },
  link: {
    color: theme.linkColor,
    cursor: "pointer",
    textDecoration: "None",
  },
  mentionItem: {
    display: "block !important",
    verticalAlign: "text-top !important",
  },
  mentionItemAvatar: {
    height: "60px !important",
  },
  mentionAvatar: {
    borderRadius: "100% !important",
    height: "30px !important",
  },
  mentionItemProfile: {
    height: "60px !important",
  },
  mentionItemFullname: {
    marginLeft: "2px !important",
  },
  mentionItemUsername: {
    marginLeft: "2px !important",
    fontWeight: "300px !important",
    fontSize: "12px !important",
  },
  menuItem: {
    marginLeft: 5,
    marginRight: 5,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
  },
  menuSelected: {
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 10,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
    backgroundColor: theme.colorScheme,
    color: "white",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  menuSelected2: {
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 10,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: "80px",
    backgroundColor: theme.colorScheme,
    color: "black",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  outlined: {
    border: "1px solid " + theme.colorScheme,
  },
  outlinedButton: {
    margin: 10,
    borderRadius: 25,
    textTransform: "capitalize",
    color: theme.colorScheme,
    borderColor: theme.colorScheme,
    "&:hover": {
      borderColor: theme.colorScheme,
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 15,
    },
  },
  paper: {
    backgroundColor: theme.dialogs,
  },
  popper: {
    placement: "bottom",
  },
  radioButton: {
    color: theme.colorScheme + " !important",
  },
  saveButton: {
    margin: 10,
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  select2Red: {
    marginTop: "5px",
    borderRadius: "5px",
    border: "1px solid #ff0000",
  },
  select2: {
    marginTop: "5px",
  },
  spacingLH: {
    marginLeft: theme.spacing(1),
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  subText: {
    color: theme.subText,
  },
  title: {
    color: theme.text,
  },
  titleThemed: {
    color: theme.textThemed,
  },
  tables: {
    minWidth: 300,
  },
  tableCellHeader: {
    color: `${theme.tableHeaderTitle} !important`,
    backgroundColor: theme.tableHeaderBG,
  },
  tableCell: {
    borderBottom: "None",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.tableRowHoverAlt,
    },
  },
	w100: {
		width: '100%',
		cursor: 'pointer'
	},
}));
export default useStyles;
