import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";

const TenantUserForm = props => {
  const [formFields, setFormFields] = useState();
  const [tenants, setTenants] = useState();

  useEffect(() => {
    http.Get(
      'tenants',
      (data) => {
        var tenantMap = {};
        data.map((tenant)=>{
          tenantMap[tenant.id] = tenant.company_name;
        });
        setTenants(tenantMap);
      },
      () => {
      }
    );
  }, []);

  var countryCodeMap = {};

  Object.keys(countryCodes).forEach(function(c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  Object.entries(countryCodeMap).map(([key, value]) => {
    countryCodeMap[key] = value + " (" + key + ")";
  });

  useEffect(() => {
    if (countryCodeMap && tenants) {
      setFormFields([
        {
          id: "tenant",
          label: "Tenant *",
          type: "select",
          valueMap: tenants
        },
        {
          id: "first_name",
          label: "FIRST NAME *",
          type: "text"
        },
        {
          id: "last_name",
          label: "LAST NAME *",
          type: "text"
        },
        {
          id: "email",
          label: "EMAIL *",
          type: "email"
        },
        {
          id: "profile_image",
          label: "PROFILE IMAGE",
          type: "image",
          onChange: (e, object, setObject, setErrors) => {
            if (e.target.files && e.target.files.length === 1) {
              var formData = new FormData();
              formData.append(
                "profile_image",
                e.target.files[0],
                e.target.files[0].name
              );
              formData.append("tenant_user_id", object.id);
              http.Post(
                "tenant_user_logo",
                formData,
                data => {
                  setObject(data);
                  props.fetchData();
                },
                (status, error) => {
                  setErrors(error);
                }
              );
            }
          },
          onDelete: (e, object, setObject, setErrors) => {
            object["profile_image"] = null;
            http.Put(
              "tenant_users/" + object.id,
              object,
              data => {
                setObject(data);
                props.fetchData();
              },
              (status, error) => {
                setErrors(error);
              }
            );
          }
        },
        {
          id: "company_name",
          label: "COMPANY NAME",
          type: "text"
        },
        {
          id: "department",
          label: "DEPARTMENT",
          type: "text"
        },
        {
          id: "phone",
          label: "PHONE",
          type: "text"
        },
        {
          id: "mobile",
          label: "MOBILE",
          type: "text"
        },
        {
          id: "country_code",
          label: "COUNTRY CODE",
          type: "select",
          valueMap: countryCodeMap
        },
        {
          id: "is_tenant_admin",
          label: "IS TENANT ADMIN",
          type: "radio"
        },
        {
          id: "is_readonly",
          label: "IS READONLY",
          type: "radio"
        },
        {
          id: "has_access",
          label: "HAS ACCESS",
          type: "radio"
        },
        {
          id: "password_need_change",
          label: "PASSWORD NEED CHANGE",
          type: "radio"
        },
        {
          id: "send_welcome_email",
          label: "SEND WELCOME EMAIL",
          type: "radio"
        },
        {
          id: "switch_from",
          label: "SWITCH FROM",
          type: "select",
          valueMap: {"None":"None","IT Glue":"IT Glue","Passportal":"Passportal","IT Boost":"IT Boost","Other":"Other",}
        },
        {
          id: "industry_type",
          label: "INDUSTRY TYPE",
          type: "select",
          valueMap: {"Aerospace":"Aerospace","Agriculture":"Agriculture","Construction":"Construction","Education":"Education",
          "Energy":"Energy","Entertainment":"Entertainment","Financial":"Financial","Health Care":"Health Care",
          "Food & Hospitality":"Food & Hospitality","Food & Hospitality":"Food & Hospitality","Information Technology":"Information Technology",
          "Legal":"Legal","Manufacturing":"Manufacturing","Non-Profit":"Non-Profit","Pharmaceutical":"Pharmaceutical",
          "Real Estate":"Real Estate","Retail":"Retail","Sports & Leisure":"Sports & Leisure","Telecommunications":"Telecommunications",
          "Transportation and Logistics":"Transportation and Logistics","Other":"Other",}          
        },
        {
          id: "company_size",
          label: "COMPANY SIZE",
          type: "select",
          valueMap: {"1~50":"1~50","51~100":"51~100","101~500":"101~500","501~1000":"501~1000","1000~more":"1000~more"}
        },
        {
          id: "hear_from",
          label: "HEAR FROM",
          type: "text"
        }
      ]);
    }
  }, [tenants]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`tenant_users`}
          formName={"Tenant User"}
          formFields={formFields}
          object={{ ...props.object, is_tenant_admin: true, number_of_result_per_page: 25, is_readonly: false }}
        />
      )}
    </div>
  );
};

export default TenantUserForm;
