import { Grid, Card, Dialog, IconButton, Typography, InputBase, Tooltip, Button, Input} from "@material-ui/core";
import React from "react";
import http from "../../Common/RestAPIHandler";
import ScreenLoader from "../../Common/Loader";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import noImage from './noImage.jpg';
import DeleteAlertDialog from "../../Common/AlertDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarComponent from "../../Common/Snackbar";
import commonConstants from "../../Common/CommonConstants"
import {GalleryLang} from "../../Common/Languages"

export var photo_status = 0;
const lang = localStorage.getItem("languages");
export function setPS(prop){
    photo_status = prop;
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.text
    },
	searchDiv: {
		display: "flex",
		width: 200,
		height: 32,
		borderRadius: 16,
		border: "1px solid rgba(0, 0, 0, 0.23)",
        borderColor: theme.borderColor,
        "&:hover": {
            borderColor: theme.borderColorAlt,
        },
        marginTop: 10,
	},
	searchInput: {
        marginLeft: theme.spacing(2),
        "&:focus": {
            borderColor: theme.borderColorAlt
        },
	},
    deleteButton: {
      borderRadius: 25,
      textTransform: "capitalize",
      color: theme.colorScheme,
      borderColor: theme.colorScheme,
    },
    updateButton: {
      borderRadius: 25,
      backgroundColor: theme.colorScheme,
      color: "white",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: theme.colorScheme,
        color: "white",
        opacity: 0.75,
      },
    },
}));

const Files = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    const url = `company_files/?company=${id}`;
    const [files, setFiles] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [multipleUpload, setMultipleUpload] = React.useState();
    const [filteredFiles, setFilteredFiles] = React.useState([]);
    const [file, setFile] = React.useState();
    const [uploadFile, setUploadFile] = React.useState();
    const [uploadFileSrc, setUploadFileSrc] = React.useState();
    const [deleteFile, setDeleteFile] = React.useState();
    const [aKey, setAKey] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
	const [search, setSearch] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    React.useEffect(() => {
        if(search && search!==""){
            var newFiles = files.filter((file)=>{
                return file.name.toLowerCase().includes(search.toLowerCase());
            });
            setFilteredFiles(newFiles);
        }
        else{
            setFilteredFiles(files);
        }
    }, [search,files]);

    React.useEffect(() => {
        if(files.length>0){
            var inputSearch = new URLSearchParams(window.location.search).get("input_search");
            if(inputSearch){	
                setSearch(inputSearch);
            }
            var showId = new URLSearchParams(window.location.search).get("show_id");
            if(showId){
                var newFiles = files.filter((file)=>{return file.id===showId;});
                setFile(newFiles[0]);
                props.history.replace({ search: window.location.search.replace("&show_id="+showId,"")});
            }
        }
    }, [files]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        http.Get(
            url,
            (data) => {
                setFiles(data);
                setLoading(false);
            },
            () => {
            }
        );
    };

    return (
        <div>
            <SnackbarComponent
                message={commonConstants.error.packageLimitReached}
                open={snackBarOpen}
                handleSnackBarClose={() => {setSnackBarOpen(false);}}
                severity={'error'}
			/>
            <DeleteAlertDialog
                open={Boolean(deleteFile)}
                onAgree={()=>{
                    setLoading(true);
                    setDeleteFile(null);
                    http.Delete(
                        "company_files/" + file.id + "/",
                        {},
                        (data) => {
                            setLoading(false);
                            setFile(null);
                            fetchData();
                            setPS(1);
                        },
                        (status, error) => {
                        }
                    );
                }}
                onDisagree={()=>{
                    setDeleteFile(null);
                }}
                primaryMessage={GalleryLang.delete[lang]}
                secondaryMessage={GalleryLang.confirm[lang]}
            />
            {loading && 
                <ScreenLoader/>
            }
            {!loading && file &&
                <Dialog
                    open={Boolean(file)}
                    fullWidth
                    maxWidth="lg"
                    onClose={()=>{
                        //setFile(null);
                    }}
                    onKeyDown={(e)=>{
                        if(e.key === "ArrowLeft"){
                            var index = filteredFiles.indexOf(file);
                            if(index > 0 && index <= filteredFiles.length - 1){
                                setFile(filteredFiles[index - 1]);
                            }
                        }
                        else if(e.key === "ArrowRight"){
                            var index = filteredFiles.indexOf(file);
                            if(index >= 0 && index < (filteredFiles.length - 1)){
                                setFile(filteredFiles[index + 1]);
                            }
                        }
                    }}
                >
                    <Grid container style={{textAlign:"center", padding:20}}>
                        <Grid item xs={12}>
                            <IconButton style={{float:"right"}}
                                onClick={()=>{setFile(null);}}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                    {filteredFiles.indexOf(file)>0 &&
                                        <div 
                                            style={{height:"60vh",paddingTop:"30vh",cursor:"pointer"}}
                                            onClick={()=>{
                                                var index = filteredFiles.indexOf(file);
                                                if(index > 0 && index <= filteredFiles.length - 1){
                                                    setFile(filteredFiles[index - 1]);
                                                }
                                            }}
                                        >
                                            <ChevronLeftIcon 
                                                fontSize="large" 
                                            />
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={10}>
                                    <img key={file.id}
                                        style={{margin:"auto", width:"80%", height:"60vh", objectFit:"scale-down", animation:"fadeIn 1s"}} 
                                        src={file.file_src}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {filteredFiles.indexOf(file)<(filteredFiles.length - 1) &&
                                        <div 
                                            style={{height:"60vh",paddingTop:"30vh",cursor:"pointer"}}
                                            onClick={()=>{
                                                var index = filteredFiles.indexOf(file);
                                                if(index >= 0 && index < (filteredFiles.length - 1)){
                                                    setFile(filteredFiles[index + 1]);
                                                }
                                            }}
                                        >
                                            <ChevronRightIcon 
                                                fontSize="large"
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item sm={12} md={4}>
                                {GalleryLang.Name[lang]+' * '}<input
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "5px 12px",
                                            borderRadius: "5px",
                                            height:33
                                        }}
                                        placeholder={GalleryLang.Name[lang]+' * '}
                                        value={file.name}
                                        onChange={(e) => {
                                            setFile({
                                                ...file,
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                {GalleryLang.Notes[lang]} <input
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "5px 12px",
                                            borderRadius: "5px",
                                            height:33
                                        }}
                                        placeholder={GalleryLang.Notes[lang]}
                                        value={file.notes}
                                        onChange={(e) => {
                                            setFile({
                                                ...file,
                                                notes: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Button
                                        className={classes.updateButton}                                  
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        onClick={()=>{
                                            file.file = null;
                                            setLoading(true);
                                            http.Put(
                                                "company_files/" + file.id + "/",
                                                file,
                                                (data) => { 
                                                    setLoading(false);
                                                    setFile(null);
                                                    fetchData();
                                                },
                                                (status, error) => {
                                                    setLoading(false);
                                                    setErrors({"file":error.name[0]});
                                                }
                                            );
                                        }}
                                    >{GalleryLang.Update[lang]}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        className={classes.deleteButton}                                    
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        onClick={()=>{
                                            setDeleteFile(file);
                                        
                                        }}
                                    >{GalleryLang.Delete[lang]}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            }
            {multipleUpload && 
              <Dialog
                open={true}
                onClose={() => {
                  setMultipleUpload(null);
                }}>
                  <div style={{padding:10}}>
                    <h3>{GalleryLang.BulkUpload[lang]}</h3>
                    <Input                   
                      inputProps={{
                        multiple: true,
                        accept: "image/*",
                      }}
                      type="file" 
                      onChange={(e)=>{
                        setUploadFiles(e.target.files);
                      }}/>
                    <br/>
                    <br/>
                    <Button
                      variant="outlined"
                      margin="dense"
                      size="small"
                      className={classes.updateButton}
                      onClick={props.companyTenant.max_company_gallery < 0 || 
                              (files.length + uploadFiles.length <= props.companyTenant.max_company_gallery) ? () => {     
                        setLoading(true);
                        setMultipleUpload(false);
                        if (uploadFiles && uploadFiles.length > 0) {
                          var i;
                          var promises = [];
                          for (i = 0; i < uploadFiles.length; i++) {
                            var formData = new FormData();
                            formData.append("company",id);
                            formData.append("name",uploadFiles[i].name);
                            formData.append("file",uploadFiles[i],uploadFiles[i].name);
                            formData.append("notes",uploadFiles[i].name);
                            var promise = http.Post(
                                "company_files/",
                                formData,
                                (data) => {
                                    // handling success create the uploading photo, meanwhile trigger the realtime update
                                    setPS(1);
                                },
                                (status, error) => {
                                }
                            );
                            promises.push(promise);
                          }
                          Promise.all(promises).then((results) => {
                            setLoading(false);
                            fetchData();
                          });
                        }
                        else{
                          setLoading(false);
                          setMultipleUpload(false);
                        }
                      } : ()=>{
                                setSnackBarOpen(true);
                                setMultipleUpload(false);
                                setUploadFiles([]);}}>{GalleryLang.Upload[lang]}</Button>
                      &nbsp;
                      <Button
                        variant="outlined"
                        margin="dense"
                        size="small"
                        className={classes.deleteButton}
                        onClick={()=>{
                          setMultipleUpload(false);
                          setUploadFiles([]);
                        }}>{GalleryLang.cancel[lang]}</Button>
                </div>
                </Dialog>
            }
            {!loading &&
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4" color="initial" classes={{ h4: classes.title }}>
                    {GalleryLang.Gallery[lang]}
                    </Typography>
                    {(props.companyPermission && props.companyPermission === "readAndWrite") &&
                    <Button 
                        className={classes.updateButton}
                        style={{float:"right"}}
                        onClick={props.companyTenant.max_company_gallery < 0 || 
                                 files.length < props.companyTenant.max_company_gallery ?
                                 ()=>{setMultipleUpload(true);} : () => {setSnackBarOpen(true)} }
                    >
                        {GalleryLang.BulkUpload[lang]}
                    </Button>}
                    <div className={classes.searchDiv}>
                        <InputBase
                            id={"input_search"}
                            className={classes.searchInput}
                            placeholder={GalleryLang.Search[lang]+" "}
                            inputProps={{ "aria-label": "search" }}
                            fullWidth
                            onChange={(e)=>{setSearch(e.target.value);}}
                            value={search}
                        />
                        <IconButton
                            aria-label="search"
                            disabled
                        >
                            <SearchIcon />
                        </IconButton>
                    </div>
                </Grid>
                {filteredFiles.map((file)=>{
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <div style={{textAlign:"center", padding:10}}>
                                    <Tooltip arrow={true} title={file.name}>
                                        <img 
                                            style={{width:200, height:200, objectFit:"contain", cursor:"pointer"}} 
                                            src={file.file_src}
                                            onClick={()=>{if(props.companyPermission && props.companyPermission === "readAndWrite")setFile(file);}}
                                        />
                                    </Tooltip>
                                </div>
                            </Card>
                        </Grid>
                    );
                })}
                {(props.companyPermission && props.companyPermission === "readAndWrite") &&
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card>
                        <div key={aKey} style={{textAlign:"center", padding:10, height:225}}>
                            <img 
                                style={{
                                    width:167, 
                                    height:167, 
                                    objectFit:"contain", 
                                }} 
                                src={uploadFileSrc?uploadFileSrc:noImage}
                            />
                            <br/>
                            <Tooltip arrow={true} title={GalleryLang.helper1[lang]}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{
                                        width: 167,
                                        height: 167,
                                        opacity: 0,
                                        position: "relative",
                                        top: -167
                                    }}
                                    onChange={(e) => {
                                        setUploadFile({
                                            ...uploadFile,
                                            company:id,
                                            name:e.target.files[0].name,
                                            file:e.target.files[0]
                                        });
                                        
                                        const reader = new FileReader();
                                        reader.readAsDataURL(e.target.files[0]);
                                        reader.onload = () => {
                                            setUploadFileSrc(reader.result);
                                        };
                                        setPS(1);
                                    }}
                                />
                            </Tooltip>
                            <br/>
                            <div
                                style={{
                                    position: "relative",
                                    top: -160
                                }}
                            >
                                <input
                                    style={{
                                        border: "1px solid #ccc",
                                        padding: "5px 12px",
                                        borderRadius: "5px",
                                        height:33,
                                        width:150,
                                    }}
                                    placeholder={GalleryLang.Name[lang]}
                                    value={uploadFile && uploadFile.name || ""}
                                    onChange={(e) => {
                                        setUploadFile({
                                            ...uploadFile,
                                            name:e.target.value
                                        });
                                    }}
                                /> &nbsp;
                                {uploadFile && uploadFile.name && uploadFileSrc &&
                                    <Button
                                        className={classes.updateButton}                                
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        onClick={props.companyTenant.max_company_gallery < 0 ||
                                                files.length < props.companyTenant.max_company_gallery ? () => {
                                            setLoading(true);
                                            var formData = new FormData();
                                            formData.append("company",uploadFile.company);
                                            formData.append("name",uploadFile.name);
                                            formData.append("file",uploadFile.file,uploadFile.file.name);
                                            formData.append("notes",uploadFile.file.name);
                                            http.Post(
                                                "company_files/",
                                                formData,
                                                (data) => {
                                                    setLoading(false);
                                                    setAKey(aKey+1);
                                                    setUploadFile(null);
                                                    setUploadFileSrc(null);
                                                    fetchData();
                                                    setPS(1);
                                                    
                                                },
                                                (status, error) => {
                                                    setLoading(false);
                                                    setErrors({"file":"Upload failed!"});
                                                }
                                            );
                                        } : () => {setSnackBarOpen(true)}}
                                    >{GalleryLang.Upload[lang]}</Button>
                                    
                                }
                            </div>
                            {errors && errors["file"] &&                                
                                <SnackbarComponent
                                    message={errors["file"]}
                                    open={Boolean(errors["file"])}
                                    handleSnackBarClose={() => {
                                        setErrors({"file":null});
                                        setUploadFile(null);
                                        setUploadFileSrc(null);
                                        
                                    }}
                                    severity={"error"}
                                />
                            }
                        </div>
                    </Card>
                </Grid>}
            </Grid>
            }
        </div>
    );
};

export default Files;