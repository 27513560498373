import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import {
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import { FormControlLabel, Radio } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";
import OutlinedInput from '@material-ui/core/OutlinedInput'; 
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TenantUserForm from "../TenantUser/TenantUserForm";

const TenantForm = (props) => {
  const classes = useStyles();
  const [tenant, setTenant] = useState({
    is_active: true,
    is_hr_module_installed: false,
    trial_end_date: new Date(new Date().getTime() + 86400000*14).toISOString().slice(0, 10),
    notification_days: 30,
    reseller: props.user.reseller,
    is_authy: true,
    is_sms: true,
    is_email: true,
    top_nav_back_colour: "Light",
    top_nav_text_colour: "Blue",
    user_addons: 1,
    portal_addons: 0,
    discount: 0,
    invoices: true,
    subscription: true
  });
  const [tenantError, setTenantError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [resellers, setResllers] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [logos, setLogos] = useState({});
  const [collapse, setCollapse] = useState(true);
  const [tenantUser, setTenantUser] = useState();
  const {
    requiredError,
    blankError,
    websitePlaceholder,
  } = commonConstants.error;

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `tenants/${props.objectId}`,
        (tenant) => {
          setAddress(tenant.address || {});
          setTenant(tenant);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }

    http.Get(
      `packages`,
      (data) => {
        setPackageList(data);
      },
      () => {}
    );

    http.Get(
      `resellers`,
      (data) => {
        setResllers(data);
        if(data.length === 1){
          if(!data[0].tenant_billing_enabled){
            setTenant({
              ...tenant,
              invoices: false,
              subscription: false
            });
          }
        }
      },
      () => {}
    );
    
  }, []);

  const onChange = (e) => {
    if ("checkbox" === e.target.type || "radio" === e.target.type) {
      setTenant({
        ...tenant,
        [e.target.name]: !tenant[e.target.name],
      });
    } else
      setTenant({
        ...tenant,
        [e.target.name]: e.target.value === -1 ? null : e.target.value,
      });
    setTenantError({
      ...tenantError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onAddressChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
    setAddressError({
      ...addressError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onLogoChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        setLogos({
          ...logos,
          [name]: reader.result,
          [name + "_name"]: file.name,
          [name + "_file"]: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setLogos({
        ...logos,
        [name]: "",
        [name + "_name"]: "",
        [name + "_file"]: "",
      });
    }
    setTenantError({
      ...tenantError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const logo = logos.logo ? logos.logo : tenant.logo;
    const mobile_logo = logos.mobile_logo
      ? logos.mobile_logo
      : tenant.mobile_logo;

    const tenantData = {
      ...tenant,
      logo: logo,
      mobile_logo: mobile_logo,
      address: address,
    };

    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `tenants/${props.objectId}`,
        tenantData,
        (tenant) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setTenantError(error);
          if (status === 400) setAddressError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `tenants`,
        tenantData,
        (data) => {
          //props.handleObjectSubmit();
          setTenantUser({tenant:data.id, company_name:data.company_name});
        },
        (status, error) => {
          if (status === 400) setTenantError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <>
    {tenantUser &&
      <TenantUserForm {...props} object={tenantUser} closeOpen={()=>{props.handleObjectSubmit();}}/>
    }    
    {!tenantUser &&
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.edit ? "Edit Tenant" : "Add New Tenant"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              {props.user.user_type === "Super User" && (
                <>
                <p className={classes.label}>RESELLER</p>
                <TextValidator
                  id="reseller"
                  fullWidth
                  select
                  variant="outlined"
                  margin="dense"
                  name="reseller"
                  value={tenant.reseller || ""}
                  defaultValue={""}
                  onChange={onChange}
                  error={Boolean(tenantError.reseller)}
                  helperText={tenantError.reseller && tenantError.reseller[0]}
                >
                  {resellers && resellers.length > 0 && (
                    <MenuItem value={""}>None</MenuItem>
                  )}
                  {resellers && resellers.length === 0 ? (
                    <MenuItem value={""} selected disabled>
                      No reseller found
                    </MenuItem>
                  ) : (
                    resellers.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  )}
                </TextValidator>
                </>
              )}

              <p className={classes.label}>ORGANIZATION NAME *</p>
              <TextValidator
                id="company_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="company_name"
                value={tenant.company_name || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(tenantError.company_name)}
                helperText={
                  tenantError.company_name && tenantError.company_name[0]
                }
              />

              <p className={classes.label}>ADDRESS</p>
              <TextValidator
                id="address"
                fullWidth
                variant="outlined"
                margin="dense"
                name="address"
                value={address.address || ""}
                onChange={onAddressChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.address)}
                helperText={addressError.address && addressError.address[0]}
              />

              <p className={classes.label}>COUNTRY</p>
              <TextValidator
                id="country"
                fullWidth
                variant="outlined"
                margin="dense"
                name="country"
                value={address.country || ""}
                onChange={onAddressChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.country)}
                helperText={addressError.country && addressError.country[0]}
              />

              <p className={classes.label}>PROVINCE</p>
              <TextValidator
                id="state"
                fullWidth
                variant="outlined"
                margin="dense"
                name="state"
                value={address.state || ""}
                onChange={onAddressChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.state)}
                helperText={addressError.state && addressError.state[0]}
              />

              <p className={classes.label}>CITY</p>
              <TextValidator
                id="city"
                fullWidth
                variant="outlined"
                margin="dense"
                name="city"
                value={address.city || ""}
                onChange={onAddressChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.city)}
                helperText={addressError.city && addressError.city[0]}
              />

              <p className={classes.label}>POSTAL</p>
              <TextValidator
                id="postal"
                fullWidth
                variant="outlined"
                margin="dense"
                name="postal"
                value={address.postal || ""}
                onChange={onAddressChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(addressError.postal)}
                helperText={addressError.postal && addressError.postal[0]}
              />
              
              <p className={classes.label}>PACKAGE NAME *</p>
              <TextValidator
                id="package"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="package"
                value={tenant.package || ""}
                defaultValue={""}
                onChange={onChange}
                error={Boolean(tenantError.package)}
                helperText={tenantError.package && tenantError.package[0]}
                required
              >
                {packageList && packageList.length > 0 && (
                  <MenuItem value={""}>None</MenuItem>
                )}
                {packageList && packageList.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No packages found
                  </MenuItem>
                ) : (
                  packageList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.package_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>

              <p className={classes.label}>USER COUNT</p>
              <TextValidator
                id="user_addons"
                fullWidth
                variant="outlined"
                margin="dense"
                name="user_addons"
                value={tenant.user_addons || 0}
                onChange={onChange}
                inputProps={{ maxLength: 5 }}
                error={Boolean(tenantError.user_addons)}
                helperText={tenantError.user_addons && tenantError.user_addons[0]}
              />

              <p className={classes.label}>PORTAL ADDONS</p>
              <TextValidator
                id="portal_addons"
                fullWidth
                variant="outlined"
                margin="dense"
                name="portal_addons"
                value={tenant.portal_addons || 0}
                onChange={onChange}
                inputProps={{ maxLength: 5 }}
                error={Boolean(tenantError.portal_addons)}
                helperText={tenantError.portal_addons && tenantError.portal_addons[0]}
              />

              <p className={classes.label}>DISCOUNT (%)</p>
              <TextValidator
                id="discount"
                fullWidth
                variant="outlined"
                margin="dense"
                name="discount"
                value={tenant.discount || 0}
                onChange={onChange}
                inputProps={{ maxLength: 3 }}
                error={Boolean(tenantError.discount)}
                helperText={tenantError.discount && tenantError.discount[0]}
              />

              <p className={classes.label}>FREE TRIAL END</p>
              <OutlinedInput 
                fullWidth
                margin="dense"
                type="date" 
                value={tenant.trial_end_date} 
                id="trial_end_date" 
                name="trial_end_date"
                onChange={onChange}/>
              <p style={{ color: "red" }}>{tenantError.trial_end_date}</p>

              <p className={classes.label}>SERVICE END</p>
              <OutlinedInput 
                fullWidth
                margin="dense"
                type="date" 
                value={tenant.service_end_date} 
                id="service_end_date" 
                name="service_end_date"
                onChange={onChange}/>
              <p style={{ color: "red" }}>{tenantError.service_end_date}</p>

              <div style={{ marginTop: 20 }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={10}>
                    <Typography
                      style={{ fontWeight: 500 }}
                      classes={{ root: classes.title }}
                    >
                      Optional
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.gridItem}>
                    {(collapse && (
                      <ArrowDropDownIcon
                        className={classes.collapseButton}
                        onClick={() => {
                          setCollapse(!collapse);
                        }}
                      />
                    )) || (
                      <ArrowDropUpIcon
                        className={classes.collapseButton}
                        onClick={() => {
                          setCollapse(!collapse);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>

                {!collapse && (
                  <div>
                    <p className={classes.label}>PHONE</p>
                    <TextValidator
                      id="phone"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="phone"
                      value={address.phone || ""}
                      onChange={onAddressChange}
                      inputProps={{ maxLength: 50 }}
                      error={Boolean(addressError.phone)}
                      helperText={addressError.phone && addressError.phone[0]}
                    />
      
                    <p className={classes.label}>WEBSITE</p>
                    <TextValidator
                      id="website"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="website"
                      value={address.website || ""}
                      placeholder={websitePlaceholder}
                      onChange={onAddressChange}
                      inputProps={{ maxLength: 255 }}
                      error={Boolean(addressError.website)}
                      helperText={addressError.website && addressError.website[0]}
                    />
                    <p className={classes.label}>FAX</p>
                    <TextValidator
                      id="fax"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="fax"
                      value={address.fax}
                      onChange={onAddressChange}
                      error={Boolean(addressError.fax)}
                      helperText={addressError.fax && addressError.fax[0]}
                    />
      
                    <p className={classes.label}>EMAIL</p>
                    <TextValidator
                      id="email"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="email"
                      value={tenant.email || ""}
                      onChange={onChange}
                      inputProps={{ maxLength: 255 }}
                      error={Boolean(tenantError.email)}
                      helperText={tenantError.email && tenantError.email[0]}
                    />
      
                    <p className={classes.label}>NOTES</p>
                    <TextValidator
                      id="notes"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="notes"
                      value={tenant.notes || ""}
                      onChange={onChange}
                      inputProps={{ maxLength: 255 }}
                      error={Boolean(tenantError.notes)}
                      helperText={tenantError.notes && tenantError.notes[0]}
                    />
      
                    <p className={classes.label}>LOGO</p>
                    <OutlinedInput 
                      fullWidth
                      margin="dense"
                      type="file"
                      inputProps={{
                        accept: "image/png, image/jpeg, image/jpg",
                      }}
                      name="logo"
                      onChange={onLogoChange}
                      className={classes.inputButton}
                    />
      
                    <p className={classes.label}>MOBILE LOGO</p>
                    <OutlinedInput 
                      fullWidth
                      margin="dense"
                      type="file"
                      inputProps={{
                        accept: "image/png, image/jpeg, image/jpg",
                      }}
                      name="mobile_logo"
                      onChange={onLogoChange}
                      className={classes.inputButton}
                    />
                    
                    <p></p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="is_active"
                          checked={tenant.is_active ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="ACTIVE"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>{tenantError.is_active}</p>
      
                    <p></p>
      
                    <p className={classes.label}>THEME</p>
                    <TextValidator
                      id="top_nav_back_colour"
                      fullWidth
                      select
                      variant="outlined"
                      margin="dense"
                      name="top_nav_back_colour"
                      value={tenant.top_nav_back_colour || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={[requiredError]}
                      error={Boolean(tenantError.top_nav_back_colour)}
                      helperText={
                        tenantError.top_nav_back_colour &&
                        tenantError.top_nav_back_colour[0]
                      }
                    >
                      <MenuItem value={"Dark"}>Dark</MenuItem>
                      <MenuItem value={"Light"}>Light</MenuItem>
                    </TextValidator>
      
                    <p className={classes.label}>COLOR SCHEME</p>
                    <TextValidator
                      id="top_nav_text_colour"
                      fullWidth
                      select
                      variant="outlined"
                      margin="dense"
                      name="top_nav_text_colour"
                      value={tenant.top_nav_text_colour || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={[requiredError]}
                      error={Boolean(tenantError.top_nav_text_colour)}
                      helperText={
                        tenantError.top_nav_text_colour &&
                        tenantError.top_nav_text_colour[0]
                      }
                    >
                      <MenuItem value={"Orange"}>Orange</MenuItem>
                      <MenuItem value={"Blue"}>Blue</MenuItem>
                    </TextValidator>
                    
                    <p className={classes.label}>INDUSTRY</p>
                    <TextValidator
                      id="industry_type"
                      fullWidth
                      select
                      variant="outlined"
                      margin="dense"
                      name="industry_type"
                      value={tenant.industry_type || ""}
                      onChange={onChange}
                      error={Boolean(tenantError.industry_type)}
                      helperText={
                        tenantError.industry_type &&
                        tenantError.industry_type[0]
                      }
                    >
                      <MenuItem value="Aerospace">Aerospace</MenuItem>
                      <MenuItem value="Agriculture">Agriculture</MenuItem>
                      <MenuItem value="Construction">Construction</MenuItem>
                      <MenuItem value="Education">Education</MenuItem>
                      <MenuItem value="Energy">Energy</MenuItem>
                      <MenuItem value="Entertainment">Entertainment</MenuItem>
                      <MenuItem value="Financial">Financial</MenuItem>
                      <MenuItem value="Health Care">Health Care</MenuItem>
                      <MenuItem value="Food & Hospitality">Food & Hospitality</MenuItem>
                      <MenuItem value="Information Technology">Information Technology</MenuItem>
                      <MenuItem value="Legal">Legal</MenuItem>
                      <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                      <MenuItem value="Non-Profit">Non-Profit</MenuItem>
                      <MenuItem value="Pharmaceutical">Pharmaceutical</MenuItem>
                      <MenuItem value="Real Estate">Real Estate</MenuItem>
                      <MenuItem value="Retail">Retail</MenuItem>
                      <MenuItem value="Sports & Leisure">Sports & Leisure</MenuItem>
                      <MenuItem value="Telecommunications">Telecommunications</MenuItem>
                      <MenuItem value="Transportation and Logistics">Transportation and Logistics</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextValidator>
                    
                    <p className={classes.label}>SIZE</p>
                    <TextValidator
                      id="company_size"
                      fullWidth
                      select
                      variant="outlined"
                      margin="dense"
                      name="company_size"
                      value={tenant.company_size || ""}
                      onChange={onChange}
                      error={Boolean(tenantError.company_size)}
                      helperText={
                        tenantError.company_size &&
                        tenantError.company_size[0]
                      }
                    >
                      <MenuItem value="1~10">1~10</MenuItem>
                      <MenuItem value="11~25">11~25</MenuItem>
                      <MenuItem value="26~50">26~50</MenuItem>
                      <MenuItem value="51~100">51~100</MenuItem>
                      <MenuItem value="100~more">100~more</MenuItem>
                    </TextValidator>
      
                    <p className={classes.label}>HEAR FROM</p>
                    <TextValidator
                      id="hear_from"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="hear_from"
                      value={tenant.hear_from || ""}
                      onChange={onChange}
                      inputProps={{ maxLength: 100 }}
                      error={Boolean(tenantError.hear_from)}
                      helperText={tenantError.hear_from && tenantError.hear_from[0]}
                    />
                    <p></p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="is_authy"
                          checked={tenant.is_authy ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="AUTHY 2FA"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>
                      {tenantError.is_authy}
                    </p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="is_sms"
                          checked={tenant.is_sms ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="SMS 2FA"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>
                      {tenantError.is_sms}
                    </p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="is_email"
                          checked={tenant.is_email ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="EMAIL 2FA"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>
                      {tenantError.is_email}
                    </p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="invoices"
                          checked={tenant.invoices ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="SHOW INVOICES"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>
                      {tenantError.invoices}
                    </p>
                    <FormControlLabel
                      control={
                        <Radio
                          name="subscription"
                          checked={tenant.subscription ? true : false}
                          onClick={onChange}
                          color="primary"
                        />
                      }
                      label="CAN CHANGE SUBSCRIPTION"
                      classes={{ label: classes.headOffice }}
                      labelPlacement="end"
                    />
                    <p style={{ color: "red" }}>
                      {tenantError.subscription}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
    }
    </>
  );
};

export default TenantForm;
