import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {
  Divider,
  Typography,
  Paper,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Snackbar,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const EmployeeDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  var { employeeId } = props.match.params;
  const [employee, setEmployee] = useState({});
  const [dataMappings, setDataMappings] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.objectId) {
      employeeId = props.objectId;
    }
    http.Get(
      `company_employees/${employeeId}`,
      (employee) => {
        setEmployee(employee);
        http.Get(
          `data_mappings?from_id=${employee.id}`,
          (data) => {
            setDataMappings(data);
            http.Get(
              `employee/credential/?employee=${employee.id}`,
              (credentials) => {
                setCredentials(credentials);
                setLoading(false);
              },
              () => {}
            );
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && (
        <Grid container spacing={1} className={classes.paper}>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {employee.employee_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButtonColored}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  } else {
                    props.history.push(
                      process.env.PUBLIC_URL + `/company/${props.user.company_id}/employees`
                    );
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider}/>
          </Grid>
          <Grid item xs={10}>
            <Paper >
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>EMPLOYEE NAME</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.employee_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>EMAIL</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.email}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>SITE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.site_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>PHONE NUMBER</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.phone_number}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>MOBILE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.mobile}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>COUNTRY CODE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.country_code}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>SHORT NOTE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.short_note}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>WORKSTATION</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.work_station}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>CATEGORY</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.category_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>PORTAL ROLE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.role_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>ACTIVE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.status && <CheckIcon />}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>HIDDEN</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.is_hidden && <CheckIcon />}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>BILLING CONTACT</span>
                  </Grid>
                  <Grid item xs={8}>
                    {employee.is_billing_contact && <CheckIcon />}
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ fontWeight: "bold" }}>CREDENTIALS</span>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Snackbar
                      open={Boolean(message)}
                      autoHideDuration={3000}
                      onClose={() => {
                        setMessage(null);
                      }}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert
                        onClose={() => {
                          setMessage(null);
                        }}
                        severity="success"
                        elevation={6}
                        variant="filled"
                      >
                        {message}
                      </Alert>
                    </Snackbar>
                    <Table classes={{ root: classes.tables }}>
                      <TableHead>
                        <TableCell className={classes.tableCellHeader}>
                          CATEGORY
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          DESCRIPTION
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          USERNAME
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          PASSWORD
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          WEBSITE
                        </TableCell>
                      </TableHead>
                      {credentials.map((credential) => {
                        return (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{credential.category_name}</TableCell>
                            <TableCell className={classes.tableCell}>{credential.description}</TableCell>
                            <TableCell className={classes.tableCell}>
                              <FilterNoneIcon
                                className={classes.iconButtonColored}
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  // color: "#a4a4a4",
                                  width: 15,
                                  verticalAlign: "bottom",
                                }}
                                onClick={() => {
                                  onCopy(props, credential, "username");
                                  setMessage("Username Copied");
                                }}
                              />
                              &nbsp;&nbsp;{credential.username}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FilterNoneIcon
                                className={classes.iconButtonColored}
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  // color: "#a4a4a4",
                                  width: 15,
                                  verticalAlign: "bottom",
                                }}
                                onClick={() => {
                                  onCopy(props, credential, "password");
                                  setMessage("Password Copied");
                                }}
                              />
                              &nbsp;&nbsp;
                              <span
                                className={classes.cursor}
                                onClick={(e) => {
                                  if (e.target.innerHTML === "············") {
                                    e.target.innerHTML = String(credential.password).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                  } else {
                                    e.target.innerHTML = "············";
                                  }
                                }}
                              >
                                ············
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell}>{credential.website}</TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <p style={{ fontWeight: "bold" }}>Related Entries:</p>
                <DataMappings
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EmployeeDetails;
