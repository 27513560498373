export const hasPermission = (props, arr) => {
  if (props.user.user_type === 'Super User') return true;
  if (props.user.is_tenant_admin && !arr.includes("Super User")) return true;
  if (props.user.is_reseller_admin && !arr.includes("Super User")) return true;
  const permissions = props.user.permissions || {};
  for (let i = 0; i < arr.length; i++) if (permissions[arr[i]]) return true;
  return false;
};

export const hasPortalPermission = (props, permission) => {
  return props.user.role?.status && props.user.role?.[permission];
}
