import { makeStyles } from "@material-ui/core/styles";
import commonConstraints from "../../../Common/CommonConstants";

const drawerWidth = 200;
const navWidth = 40;
const headerHeight = 75;

const useStyles = makeStyles((theme) => ({
  navLinkText: {
    marginLeft:"10px",
    verticalAlign: "40%",
  },
  navLink: {
    color: commonConstraints.background,
    underline: "none",
    textDecoration: "none",
    fontWeight: 500,
  },
  active: {
    color: theme.colorScheme + "!important",
  },
  root: {
    display: "flex",
    overflow: "hidden",
    background: commonConstraints.background,
  },
  appdiv: {
    height: headerHeight,
    width: "100%",
  },
  navdiv: {
    position: "fixed",
    left: 0,
    top: headerHeight,
    height: "calc(var(--vh, 1vh) * 100 - 75px)",
    width: drawerWidth,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  navbar: {
    width: "100%",
    paddingLeft: "10px",
    paddingTop: 20,
    paddingBottom: 2,
    fontWeight: 400,
  },
  maindiv: {
    position: "fixed",
    left: drawerWidth,
    top: headerHeight,
    width: "calc(100vw - " + drawerWidth + "px)",
    height: "calc(100vh - " + headerHeight + "px)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: theme.spacing(4),
  },
  footerdiv: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    height: 50,
    fontWeight: 500,
    color: theme.navLink
  },
  appBar: {
    backgroundColor: "white",
    height: headerHeight,
    width: "100%",
  },
  toolbarOne: {
    height: headerHeight,
    paddingLeft: "2%",
    paddingRight: "2%",
    backgroundColor: "#FEFFFF",
  },
  time: {
    color: "black",
    fontSize: 30,
    fontWeight: 300,
  },
  superscript: {
    fontSize: "0.7em",
  },
  date: {
    color: "black",
    fontWeight: 300,
  },
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "0px !important",
  },
  hideChip: {
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  showProfileImage: {
    [theme.breakpoints.up(800)]: {
      display: "none",
    },
  },
  headerRightGrid: {
    width: "max-content",
  },
  menuItem: {
    justifyContent: "center",
    marginLeft: 5,
  },
  arrowIcon: {
    height: 35,
  },
  TimeHide: {
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
  },
  DateHide: {
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
  },
  full_image: {
    maxWidth: 250,
    display: "block",
    maxHeight: 70,
  },
  mobile_image: {
    height: 40,
    width: 40,
    display: "none",
  },
  "@media (max-width: 800px)": {
    time: {
      fontSize: 20,
    },
    superscript: {
      fontSize: "0.5em",
    },
    navLinkText: {
      display: "none",      
    },
    footerdiv: {
      display: "none",      
    },
    navdiv: {
      width: navWidth,
    },
    maindiv: {
      left: navWidth,
      width: "calc(100vw - " + navWidth + "px)",
      padding: 5,
    },
  },
  "@media (max-width: 600px)": {
    full_image: {
      display: "none",
    },
    mobile_image: {
      display: "block",
    },
    maindiv: {
      height: "calc(100vh - " + (headerHeight + 50) + "px)",
    },
  },
}));

export default useStyles;
