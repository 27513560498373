import React from "react";
import ObjectForm from "../../Common/ObjectForm";
import {ToTenant} from "../../Common/Languages";

const ToTenantForm = (props) => {
  const [formFields, setFormFields] = React.useState();

  const lang = localStorage.getItem("languages");

  React.useEffect(() => {
    setFormFields([
      { 
        id: "to_tenant_email", 
        label: ToTenant.Sharing[lang], 
        type: "email", 
      },
    ]);
  }, []);


  return (
    <>
    {formFields && (
      <ObjectForm
        {...props}
        url={`invite_tenant`}
        formName={ToTenant.Sharing[lang]}
        formFields={formFields}
        object={{from_tenant:props.user.tenant}}
      />
    )}
    </>
  );
};

export default ToTenantForm;
