import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 90,
    padding: 5,
  },

  typogrpahy: {
    padding: 4,
  },

  featured: {
    padding: 5,
  },

  installed: {
    padding: 5,
    
  },

  image: {
    padding: 4,
  },

  label: {
    paddingLeft:4,
    paddingRight:3,
    marginLeft:4,
    marginBottom:-1,
    fontSize: "10px",
    color:"white",
    backgroundColor:theme.colorScheme,
    borderRadius: '5px!important'
  },

  installedButtons: {
    color: theme.colorScheme,
    fontWeight: "bold",
    padding: 4,
    cursor: "pointer",
    paddingTop: 0,
  },

  uninstallableButtons: {
    color: "white",
    backgroundColor: "red",
    fontWeight: "bold",
    padding: 3,
    marginLeft: 4,
  },

  featuredButtons: {
    color: "white",
    backgroundColor: theme.colorScheme,
    fontWeight: "bold",
    padding: 3,
    marginLeft: 4,
    cursor: "pointer",
  },

  alreadyInstalledButtons: {
    color: "white",
    backgroundColor: "slategray",
    fontWeight: "bold",
    padding: 3,
    marginLeft: 4,
  },

  installed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  drawerForm: {
    overflowY: "hidden",
  },
  link: {
    color: theme.linkColor
  },
  subTitle: {
    color: theme.subText
  },
  title: {
    color: theme.text
  },

  "@media (max-width: 1000px) and (min-width: 960px)": {
    typogrpahy: {
      "font-size": "14px",
    },
    installedButtons: {
      fontSize: "10px",
    },
  },

  "@media (max-width: 790px)": {
    installedButtons: {
      fontSize: "10px",
    },
  },

  "@media (max-width: 760px)": {
    typogrpahy: {
      "font-size": "14px",
    },
  },

  "@media (max-width: 720px)": {
    image: {
      height: 50,
      width: 50,
    },
    paper: {
      height: 70,
    },
  },
  "@media (max-width: 642px) and (min-width: 600px)": {
    paper: {
      width: 168,
    },
    installedButtons: {
      fontSize: 9,
    },
  },

  "@media (max-width: 420px)": {
    paper: {
      width: 176,
    },
  },
}));

export default useStyles;
