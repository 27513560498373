import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";
import {userManagementForm} from "../../Common/Languages";

const UserForm = props => {
  const [formFields, setFormFields] = useState();
  const [allRoles, setAllRoles] = useState();
  const [roleMappings, setRoleMappings] = useState();
  const [availableRoles, setAvailableRoles] = useState();

  var countryCodeMap = {};

  const lang = localStorage.getItem("languages");

  Object.keys(countryCodes).forEach(function(c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  Object.entries(countryCodeMap).map(([key, value]) => {
    countryCodeMap[key] = value + " (" + key + ")";
  });

  const getRoleMappings = () => {
    if(props.objectId){
      http.Get(
        `user_roles?user_id=${props.objectId}`,
      data => {
        var roleList = [];
        for(let i=0; i<data.length; i++){
          data[i]["value"] = data[i]["role_id"];
          data[i]["label"] = allRoles[data[i]["role_id"]];
          data[i]["onDelete"] = (e,object,setObject,setErrors) => {            
            http.Delete(
              "user_roles/" + data[i]["id"],
              {},
              data => {
                getRoleMappings();
              },
              (status, error) => {
                setErrors(error);
              }
            );

          };
          roleList.push(data[i]);
        }
        setRoleMappings(roleList);
        },
        () => {}
        );

    }
    else{
      var data = localStorage.getItem("rolesForTenantUser");
      if(data){
        var roles = data.split(",");
        var roleList = [];
        roles.map((role)=>{
            if(role){
                var data = {};
                data["value"] = role;
                data["label"] = allRoles[role];
                data["onDelete"] = (e,object,setObject,setErrors) => { 
                    var roles = localStorage.getItem("rolesForTenantUser");
                    if(roles){
                        localStorage.setItem("rolesForTenantUser",roles.replace(role + ",",""));
                        getRoleMappings();
                    }
                      var subDatas = object.subDatas;
                      if(subDatas){
                            var newSubDatas = subDatas.filter((subData)=>{
                                return subData.data.role_id!==role;
                                });
                          setObject({
                              ...object,
                              'subDatas': newSubDatas,
                            });
                      }
                        
                      };
                roleList.push(data);
            }
        });        
        setRoleMappings(roleList); 
      }
      else{
        localStorage.setItem("rolesForTenantUser","");
        setRoleMappings([]);
      }
    }
  };

  useEffect(() => {
    http.Get(
      `roles`,
      data => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].role_name;
        }
        setAllRoles(valueMap);
      },
      () => {}
    );
    localStorage.setItem("rolesForTenantUser","");
  }, []);
  
  
    useEffect(() => {
      if(allRoles && roleMappings) {
        var availableList = {...allRoles};
        for(let i=0;i<roleMappings.length;i++){
          if(availableList[roleMappings[i]['value']]){
            delete availableList[roleMappings[i]['value']];
          }
        }
        setAvailableRoles(availableList);
      }
  
    }, [allRoles,roleMappings]);

    useEffect(() => {
      if(allRoles) {
        getRoleMappings();
      }
  
    }, [allRoles]);


  useEffect(() => {
    if (availableRoles && allRoles && roleMappings) {
      setFormFields([
        {
          id: "first_name",
          label: userManagementForm.FIRSTNAME[lang],
          type: "text"
        },
        {
          id: "last_name",
          label: userManagementForm.LASTNAME[lang],
          type: "text"
        },
        {
          id: "email",
          label: userManagementForm.EMAIL[lang],
          type: "email"
        },
        {
          id: "profile_image",
          label: userManagementForm.PROFILEIMAGE[lang],
          type: "image",
          onChange: (e, object, setObject, setErrors) => {
            if (e.target.files && e.target.files.length === 1) {
              var formData = new FormData();
              formData.append(
                "profile_image",
                e.target.files[0],
                e.target.files[0].name
              );
              formData.append("tenant_user_id", object.id);
              http.Post(
                "tenant_user_logo",
                formData,
                data => {
                  setObject(data);
                  props.fetchData();
                },
                (status, error) => {
                  setErrors(error);
                }
              );
            }
          },
          onDelete: (e, object, setObject, setErrors) => {
            object["profile_image"] = null;
            http.Put(
              "tenant_users/" + object.id,
              object,
              data => {
                setObject(data);
                props.fetchData();
              },
              (status, error) => {
                setErrors(error);
              }
            );
          }
        },
        {
          id: "department",
          label: userManagementForm.DEPARTMENT[lang],
          type: "text"
        },
        {
          id: "phone",
          label: userManagementForm.PHONE[lang],
          type: "text"
        },
        {
          id: "ext",
          label: userManagementForm.EXT[lang],
          type: "text"
        },
        {
          id: "mobile",
          label: userManagementForm.MOBILE,
          type: "text"
        },
        {
          id: "country_code",
          label: userManagementForm.COUNTRYCODE[lang],
          type: "select",
          valueMap: countryCodeMap
        },
        {
          id: "roles",
          label: userManagementForm.ROLES[lang],
          type: "multiselect",
          valueMap: availableRoles,
          selectedValues: roleMappings,
          onChange: (e, object, setObject, setErrors) => {       
              if(object.id){
                http.Post(
                  "user_roles",
                  { user_id: object.id, role_id: e.target.value },
                  data => {
                    getRoleMappings();
                  },
                  (status, error) => {
                    setErrors(error);
                  }
                );
              }
              else{
                  var subDatas = object.subDatas;
                  if(!subDatas){
                      subDatas = [];
                  }
                  subDatas.push({url:"user_roles",data:{"role_id":e.target.value},id:'user_id'});
                  setObject({
                      ...object,
                      'subDatas': subDatas,
                    });
                    
                  var data = localStorage.getItem("rolesForTenantUser");     
                  if(!data){
                      data = "";
                  }
                  localStorage.setItem("rolesForTenantUser",data + e.target.value + ",");
                  getRoleMappings();
              }
            
          }
        }, 
        {
          id: "is_readonly",
          label: userManagementForm.LICENSETYPE[lang],
          type: "select",
          valueMap: {false:`Standard License ( ${props.tenant.user_addons - props.tenant.user_count} of ${props.tenant.user_addons} available)`,true:`Read Only ( ${props.tenant.readonly_addons - props.tenant.readonly_count} of ${props.tenant.readonly_addons} available)`},
        },       
        {
          id: "is_tenant_admin",
          label: userManagementForm.ISTENANTADMIN[lang],
          type: "radio"
        },
        {
          id: "has_access",
          label: userManagementForm.HASACCESS[lang],
          type: "radio"
        },
        {
          id: "password_need_change",
          label: userManagementForm.REQUIRE[lang],
          type: "radio"
        },
        {
          id: "send_welcome_email",
          label: userManagementForm.WELCOMEEMAIL[lang],
          type: "radio"
        },
        {
          id: "is_hidden",
          label: userManagementForm.CONTACTLIST[lang],
          type: "radio"
        },
        {
          id: "is_mobile_hidden",
          label: userManagementForm.HIDECONTACTLIST[lang],
          type: "radio"
        },
        {
          id: "number_of_result_per_page",
          label: userManagementForm.NUMBEROFRESULT[lang],
          type: "select",
          valueMap: {25:25,50:50,100:100,200:200}
        }
      ]);
    }
  }, [availableRoles,allRoles,roleMappings]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          objectId={props.objectId}
          url={`tenant_users`}
          formName={userManagementForm.TenantUsers[lang]}
          formFields={formFields}
          object={{ tenant: props.user.tenant, is_tenant_admin: false, is_readonly: false, number_of_result_per_page:25 }}
        />
      )}
    </div>
  );
};

export default UserForm;
