import React, { useEffect, useState } from "react";
import PermissionForm from "./PermissionForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "permission_name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "access_description",
    label: "Description",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "order",
    label: "Order",
    minWidth: 175,
    sortable: true,
  },
  {
    id: "permission_category_name",
    label: "Category",
    minWidth: 175,
    sortable: true,
  },
];

const Permissions = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `permissions`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Permissions"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={PermissionForm}
      tableColumns={tableColumns}
      searchFields={["permission_name"]}
      orderBy={"permission_name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Permissions"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Permissions"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={PermissionForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Permissions"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Permissions;
