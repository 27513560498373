import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";

const ResellerPermissionCategoryForm = props => {
  const [formFields, setFormFields] = useState();

  useEffect(() => {
    setFormFields([
      {
        id: "name",
        label: "NAME *",
        type: "text"
      },
    ]);
  }, []);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`reseller_permission_categories`}
          formName={"Reseller Permission Category"}
          formFields={formFields}
        />
      )}
    </div>
  );
};

export default ResellerPermissionCategoryForm;
