import React, { useEffect, useState } from "react";
import DeviceForm from "./DeviceForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onCopy } from "../../Common/TableUtility";
import { format } from "date-fns-tz";
import { ConvertDateToLocale } from "../../Common/Utility";
import { Dialog, Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import SnackbarComponent from "../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import ObjectVersions from "../../Common/ObjectVersions";
import DeviceCredentials from "./DeviceCredentials";
import HistoryIcon from "@material-ui/icons/History";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DeviceDetails from "./DeviceDetails";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
    width: 15,
    verticalAlign: "bottom",
  },
  errorIconColorScheme: {
    color: "#f44336",
    width: 15,
  },
  floatRight: {
    float: "right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    // color: theme.colorScheme,
  },
}));

const Devices = (props) => {
	const selectedSite = localStorage.getItem("Site");
	const classes = useStyles();
	const [activeIds, setActiveIds] = useState([]);
	const [sites, setSites] = useState();
	const [categories, setCategories] = useState();
	const [site, setSite] = useState(selectedSite);
	const [data, setData] = useState([]);
	const [showData, setShowData] = useState([]);
	const [unchangedData, setUnchangedData] = useState([]);
	const [device, setDevice] = useState();
	const [loadData, setLoadData] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState();
	const url = `companies/${props.user.company_id}/devices`;
	const exportFormat = commonConstants.deviceExportFormat;
	const [anchorEl, setAnchorEl] = useState(null);
	const [versions, setVersions] = useState();
	const actions = {
		delete: true,
		importCSV: false,
		exportCSV: false,
		exportPDF: false,
	};
	const [objectId, setObjectId] = useState();

  const toggleActiveIds = (props, object, property) => {
    if (activeIds.includes(object.id)) {
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== object.id;
        })
      );
      object["indicator"] = <ExpandMoreIcon />;
    } else {
      var ids = [].concat(activeIds);
      ids.push(object.id);
      setActiveIds(ids);
      object["indicator"] = <ExpandLessIcon />;
    }
  };

  const tableColumns = [
    {
      id: "indicator",
      label: "",
      minWidth: 10,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : toggleActiveIds,
    },
    {
      id: "device_name",
      label: "DEVICE NAME",
      minWidth: 130,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "infoCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "info",
      label: "INFO",
      minWidth: 150,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "usernameCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "username",
      label: "USERNAME",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "passwordCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "staredPassword",
      label: "PASSWORD",
      minWidth: 100,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "notes",
      label: "NOTES",
      minWidth: 150,
      sortable: false,
    },
    {
      id: "versions",
      label: " ",
      minWidth: 10,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
  ];

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }

    http.Get(
      `companies/${props.user.company_id}/sites`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].site_name;
        }
        setSites(valueMap);
      },
      () => {}
    );

    http.Get(
      `categories?category_type=Credential Type`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (sites && categories) {
      fetchData();
    }
  }, [sites, categories]);

  useEffect(() => {
    var dataList = [];
    data.map((device) => {
      dataList.push(device);
      /*
      if (
        site === "All" ||
        device.sites.includes(site) ||
        device.location_id === site
      ) {
        dataList.push(device);
      }
      */
    });
    setShowData(dataList);
  }, [data, site]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );

        data.map((item) => {
          item["infoCopy"] = (
            <FilterNoneIcon
              fontSize="small"
              className={classes.iconColorScheme}
              onClick={(props, row, columnId) => {
                onCopy(props, item, "url");
                setSnackBarMessage("URL Copied");
              }}
            />
          );

          item["info"] = (
            <React.Fragment>
              {item["category_name"]}
              <br />
              {item["description"]}
              <br />
              <span>
                {/* {item["url"] && (
									<> */}
                {/* <FilterNoneIcon
											fontSize="small"
											className={classes.iconColorScheme}
											onClick={(props, row, columnId) => {
												onCopy(props, item, "url");
												setSnackBarMessage("URL Copied");
											}}
										/> */}
                {item["url"]}
                {/* </>
								)} */}
              </span>
              <br />
              {item["site_name"]}
              <br />
              {item["tag_number"]}
              <br />
            </React.Fragment>
          );

          item["usernameCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={`${classes.iconColorScheme}`}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "username");
                  setSnackBarMessage("Username Copied");
                }}
              />
            </>
          );

          item["passwordCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={`${classes.iconColorScheme}`}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "password");
                  setSnackBarMessage("Password Copied");
                }}
              />
            </>
          );

          item["staredPassword"] = (
            <>
              <span
                className={classes.passwordColorScheme}
                onClick={(e) => {
                  if (e.target.innerHTML === "············")
                    e.target.innerHTML = String(item["password"])
                      .replaceAll("<", "&lt;")
                      .replaceAll(">", "&gt;");
                  else e.target.innerHTML = "············";
                }}
              >
                ············
              </span>{" "}
              &nbsp;
              {item["flagged_pass"] ? (
                <Tooltip
                  interactive
                  arrow={true}
                  placement="top-start"
                  title="Unsafe Password!"
                >
                  <ErrorOutlineIcon className={classes.errorIconColorScheme} />
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );

          item["versions"] = (
            <HistoryIcon
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setVersions(null);
                http.Get(
                  `versions?object_id=${item.id}&model_name=device`,
                  (versions) => {
                    setVersions(versions);
                  },
                  () => {
                    setVersions(null);
                  }
                );
              }}
            />
          );

          item["subData"] = (
            <DeviceCredentials
              {...props}
              categories={categories}
              deviceId={item["id"]}
              fetchData={fetchData}
            />
          );

          item["indicator"] = <ExpandMoreIcon />;
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <DeviceDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
            />
          </div>
        </Dialog>
      )}
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
      ></SnackbarComponent>
      {anchorEl && (
        <ObjectVersions
          {...props}
          versions={versions}
          fetchData={fetchData}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {device && (
        <Dialog
          open={true}
          onClose={() => {
            setDevice(null);
          }}
        >
          <div style={{ margin: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>DEVICE DETAILS</h3>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                DEVICE NAME
              </Grid>
              <Grid item xs={9}>
                {device.device_name}
              </Grid>
              <Grid item xs={3}>
                DEVICE TYPE
              </Grid>
              <Grid item xs={9}>
                {device.category_name}
              </Grid>
              <Grid item xs={3}>
                DESCRIPTION
              </Grid>
              <Grid item xs={9}>
                {device.description}
              </Grid>
              <Grid item xs={3}>
                IP ADDRESS/URL
              </Grid>
              <Grid item xs={9}>
                {device.url}
              </Grid>
              <Grid item xs={3}>
                USERNAME
              </Grid>
              <Grid item xs={9}>
                {device.username}
              </Grid>
              <Grid item xs={3}>
                PASSWORD
              </Grid>
              <Grid item xs={9}>
                {device.password}
              </Grid>
              <Grid item xs={3}>
                LOCATION
              </Grid>
              <Grid item xs={9}>
                {device.site_name}
              </Grid>
              <Grid item xs={3}>
                NOTES
              </Grid>
              <Grid item xs={9}>
                {device.notes}
              </Grid>
              <Grid item xs={3}>
                ASSET TAG NUMBER
              </Grid>
              <Grid item xs={9}>
                {device.tag_number}
              </Grid>
            </Grid>
          </div>
        </Dialog>
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={
            <Grid container>
              <Grid item>Servers and Devices </Grid>
              <Grid item>&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
              {/* <Grid item style={{ fontSize: "12px", marginLeft: "20px" }}>
								<Select
									value={site}
									onChange={(e) => {
										setSite(e.target.value);
									}}
								>
									<MenuItem value="All">All Sites</MenuItem>
									{sites &&
										Object.entries(sites).map(([key, value]) => (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										))}
								</Select>
							</Grid> */}
            </Grid>
          }
          url={url}
          actions={actions}
          portalPermissions={{
            create: props.user.role.devices_create,
            edit: props.user.role.devices_modify,
            delete: props.user.role.devices_delete,
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          objectForm={DeviceForm}
          orderBy={"device_name"}
          searchFields={["device_name", "url", "description"]}
          pageHeader={
            (props.company.company_name || "") + " | Servers and Devices"
          }
          loadData={loadData}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Servers and Devices"}
          // mobileMenu={
          // 	<Grid item xs style={{ fontSize: "12px" }}>
          // 		<Select
          // 			value={site}
          // 			onChange={(e) => {
          // 				setSite(e.target.value);
          // 			}}
          // 		>
          // 			<MenuItem value="All">All Sites</MenuItem>
          // 			{sites &&
          // 				Object.entries(sites).map(([key, value]) => (
          // 					<MenuItem key={key} value={key}>
          // 						{value}
          // 					</MenuItem>
          // 				))}
          // 		</Select>
          // 	</Grid>
          // }
          url={url}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          objectForm={DeviceForm}
          orderBy={"device_name"}
          searchFields={["device_name", "url", "description"]}
          pageHeader={
            (props.company.company_name || "") + " | Servers and Devices"
          }
          loadData={loadData}
          portalPermissions={{
            create: props.user.role.devices_create,
            delete: props.user.role.devices_delete, 
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Devices);
