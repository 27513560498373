import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";

const PermissionForm = props => {
  const [formFields, setFormFields] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    http.Get(
      `permission_categories`,
      (data) => {
        var valueMap = { "": "None" };
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].name;
        }
        setCategories(valueMap);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if(categories){
      setFormFields([
        {
          id: "permission_name",
          label: "NAME *",
          type: "text"
        },
        {
          id: "access_description",
          label: "Description",
          type: "text"
        },
        {
          id: "permission_category",
          label: "Category",
          type: "select",
          valueMap: categories,
        },
        {
          id: "order",
          label: "Order",
          type: "text"
        },
      ]);
    }
  }, [categories]);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`permissions`}
          formName={"Permissions"}
          formFields={formFields}
        />
      )}
    </div>
  );
};

export default PermissionForm;
