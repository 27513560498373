import React  from "react";
import ObjectForm from "../../../Common/ObjectForm";
import {postLang} from "../../../Common/Languages";

const lang = localStorage.getItem("languages");

const JobPostingTemplateForm = (props) => {
  const formFields = [
    {
      id: "title",
      label: postLang.TITLE[lang],
      type: "text",
    },
    {
      id: "data",
      label: postLang.TEMPLATE[lang],
      type: "ckeditor",
    },
  ];


  return (
    <ObjectForm
      {...props}
      objectId={props.objectId}
      url={`hr_job_posting_templates`}
      formName={postLang.JobPostingTemplate[lang]}
      formFields={formFields}
      object={{ tenant:props.user.tenant,data:""}}
    />
  );
};

export default JobPostingTemplateForm;
