import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MeetingForm from "./MeetingForm";
import ObjectList from "../../Common/ObjectList";
import { formatDateTime, formatDate } from "../../Common/Utility";
import http from "../../Common/RestAPIHandler";
import commonConstants from "../../Common/CommonConstants";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider, Paper } from "@material-ui/core";
import FileViewer from "../../Common/FileViewer";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../Common/Style/FormStyle";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ObjectListMobile from "../../Common/ObjectListMobile";
import SnackbarComponent from "../../Common/Snackbar";
import {MeetingLang} from "../../Common/Languages";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const lang = localStorage.getItem("languages");

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: "600px",
    minHeight: "400px",
  },
}))(MuiDialogContent);


const Meetings = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [content, setContent] = useState();
  const [loadData, setLoadData] = useState(false);
  const [filePath, setFilePath] = useState();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const url = "meetings";
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: true,
  };

  const getWhen = (item) => {
    var x = formatDateTime(item.start_time);
    var xDate = formatDate(item.start_time);
    var y = formatDateTime(item.end_time).replaceAll(xDate, "");
    return x + " - " + y;
  };
  
  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const exportToPDF = (data,filename) => {
    handleSnackBar(
      MeetingLang.help1[lang],
      MeetingLang.help2[lang]
    );
    http.ExportToPdf(
      `meetings/export_pdf`,
      data,
      (blob) => {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      () => {
        handleSnackBar(MeetingLang.error1[lang],MeetingLang.error2[lang]);
      }
    );
  };
  
const tableColumns = [
  { id: "name", label: MeetingLang.NAME[lang], minWidth: 200, sortable: true, colored: true, 
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : (props, item, column_id)=>{
                  item["content"] = item["content"].replaceAll("<a ", "<a target='blank' ")
                  http.Get(
                    `meeting_files?meeting=${item.id}`,
                    (data) => {
                      item["files"] = data;
                      setContent(item);
                    },
                    () => {
                    }
                  );
      }},
  { id: "download", label: "", minWidth: 10, colored: true, style: commonConstants.cellCursor },
  { id: "when", label: MeetingLang.WHEN[lang], minWidth: 200, sortable: true },
  { id: "location", label: MeetingLang.WHERE[lang], minWidth: 175, sortable: true },
  { id: "created_by_name", label: MeetingLang.BY[lang], minWidth: 150, sortable: true },
  { id: "id", label: "id", minWidth: 150, sortable: true },
];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => {
            item.when = getWhen(item);
            item.download = 
            <SaveAltIcon 
              onClick={() => {                
                const offset = new Date().getTimezoneOffset();
                exportToPDF({ offset: offset, ids: [item.id,] },item.name); 
              }}/>;
          }
        );
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <React.Fragment>
        <SnackbarComponent
          message={snackBarMessage}
          open={snackBarOpen}
          handleSnackBarClose={handleSnackBarClose}
          severity={severity}
        ></SnackbarComponent>
      {data && data.map((meeting)=>{
        return <div id={meeting.id} style={{position:"absolute",left:"-8000px",width:"780px",padding:"30px"}} dangerouslySetInnerHTML={{ __html: meeting.content }}/>
      })}
      {filePath &&
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setFilePath(null);
          }}>
          <FileViewer
            fileType={filePath.fileType.toLowerCase()}
            filePath={filePath.filePath}
            onError={() => {
              setFilePath(null);
            }}
          />
        </Dialog>
      }
      {content && (
        <div>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
              {content.name}
            </Typography>
            {formatDateTime(content.when)} {MeetingLang.by[lang]} {content.last_modified_by_name}
          </Grid>
          <Grid item xs={2}>
            <div style={{paddingTop:10,float:"right"}}>
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {        
                    const offset = new Date().getTimezoneOffset();
                    exportToPDF({ offset: offset, ids: [content.id,] },content.name);
                  }}
                >
                  <SaveAltIcon />
                </IconButton>

                <IconButton
                  className={classes.iconButton}
                  onClick={() => setContent(null)}
                >
                  <CancelIcon />
                </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>   
            <Divider/>
          </Grid>
          <Grid item xs={10}>
            <Paper>
                <div
                  style={{ maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "scroll",
                  overflowX: "auto",
                  marginLeft:10 }}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
              </Paper>
                <div id="divToPDF"
                  style={{ position:"absolute",
                    left:"-8000px",
                    width:"780px",
                    padding:"30px"}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
          </Grid>
          <Grid item xs={2}>
            <Paper>
                <div
                  style={{ maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "scroll",
                  overflowX: "auto",
                  marginLeft:10 }}
                >
                <p style={{fontWeight:"bold"}}>{MeetingLang.Attachments[lang]}</p>
                  {content.files && content.files.map((file) => (
                    <p key={file.id}>
                      <a className={classes.link} href="#" onClick={()=>{
                          if(file.file_src){
                            setFilePath({filePath:file.file_src,fileType:file.file.split('?')[0].split('.').pop()});
                          }
                          else{
                            alert("File Not Found!");
                          }
                        }}>
                        {file.name}
                      </a>
                      <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                          if(file.file_src){
                            var a = window.document.createElement("a");
                            a.href = file.file_src;
                            a.download = file.name;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                          }
                          else{
                            alert("File Not Found!");
                          }
                        }}
                      >
                        <SaveAltIcon/>
                      </IconButton>
                    </p>
                  ))}
                </div>
              </Paper>
          </Grid>
        </Grid>
        </div>
      )}

      {!props.mobile && !content && data && (
        <ObjectList
          {...props}
          moduleName={MeetingLang.Meetings[lang]}
          url={url}
          templateUrl={"public_templates?template_type_name=Meeting"}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={MeetingForm}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={props.user.company_name + " | Meetings"}
          loadData={loadData}
        />
      )}
      {props.mobile && !content && data && (
        <ObjectListMobile
          {...props}
          moduleName={"Meetings"}
          url={url}
          templateUrl={"public_templates?template_type_name=Meeting"}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={MeetingForm}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={props.user.company_name + " | Meetings"}
          loadData={loadData}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Meetings);
