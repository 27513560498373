import React from 'react';
import { nanoid } from "nanoid";
// Static oidc params for a single provider

export default function OneLoginSSO() {
  let state = nanoid(32);
  let nonce = nanoid(32);
  const authority = localStorage.getItem("onelogin_url");
  const client_id = localStorage.getItem("onelogin_client_id");
  const redirect_uri = window.location.protocol +  "//" + window.location.hostname + (window.location.port ? `:${window.location.port}/reseller/oneloginauth` : "/reseller/oneloginauth");
  const response_type = "id_token token";
  const scope = "openid profile";
  const authUrl = `${authority}/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}&nonce=${nonce}`;

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);

  return (
    <div>
    </div>
  )
}
