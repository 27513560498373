import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {
  Divider,
  Typography,
  Paper,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Snackbar,
} from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const DeviceDetails = (props) => {
  const classes = useStyles();
  var { deviceId } = props.match.params;
  const [device, setDevice] = useState({});
  const [dataMappings, setDataMappings] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.objectId) {
      deviceId = props.objectId;
    }
    http.Get(
      `companies/${props.user.company_id}/devices/${deviceId}`,
      (device) => {
        setDevice(device);
        http.Get(
          `data_mappings?from_id=${device.id}`,
          (data) => {
            setDataMappings(data);
            http.Get(
              `device/credential/?device=${device.id}`,
              (credentials) => {
                setCredentials(credentials);
                setLoading(false);
              },
              () => {}
            );
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && (
        <Grid container spacing={1} className={classes.paper}>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {device.device_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButtonColored}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  } else {
                    props.history.push(
                      process.env.PUBLIC_URL +
                        `/company/${props.user.company_id}/servers_devices`
                    );
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={10}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>DEVICE NAME</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.device_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>DEVICE TYPE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.category_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>DESCRIPTION</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.description}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>URL</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "username");
                        setMessage("Username Copied");
                      }}
                    />
                    &nbsp;&nbsp;{device.url}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>USERNAME</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "username");
                        setMessage("Username Copied");
                      }}
                    />
                    &nbsp;&nbsp;{device.username}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>PASSWORD</span>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterNoneIcon
                      className={classes.iconButtonColored}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        width: 15,
                        verticalAlign: "bottom",
                      }}
                      onClick={() => {
                        onCopy(props, device, "password");
                        setMessage("Password Copied");
                      }}
                    />
                    &nbsp;&nbsp;
                    <span
                      className={classes.cursor}
                      onClick={(e) => {
                        if (e.target.innerHTML === "············") {
                          e.target.innerHTML = String(device.password)
                            .replaceAll("<", "&lt;")
                            .replaceAll(">", "&gt;");
                        } else {
                          e.target.innerHTML = "············";
                        }
                      }}
                    >
                      ············
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>TAG NUMBER</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.tag_number}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>OPERATING SYSTEM</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.os}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>HARDWARE MAKE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_make}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>HARDWARE MODEL</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_model}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>
                      HARDWARE SERIAL NUMBER
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.hw_serial_number}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>CURRENT IP</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.current_ip}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>LAST LOGIN NAME</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.last_login_name}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>NOTES</span>
                  </Grid>
                  <Grid item xs={8} style={{whiteSpace:"pre-wrap"}}>
                    {device.notes}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>SITE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {device.site_name}
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ fontWeight: "bold" }}>CREDENTIALS</span>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Snackbar
                      open={Boolean(message)}
                      autoHideDuration={3000}
                      onClose={() => {
                        setMessage(null);
                      }}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert
                        onClose={() => {
                          setMessage(null);
                        }}
                        severity="success"
                        elevation={6}
                        variant="filled"
                      >
                        {message}
                      </Alert>
                    </Snackbar>
                    <Table classes={{ root: classes.tables }}>
                      <TableHead>
                        <TableCell className={classes.tableCellHeader}>
                          CATEGORY
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          DESCRIPTION
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          USERNAME
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          PASSWORD
                        </TableCell>
                      </TableHead>
                      {credentials.map((credential) => {
                        return (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {credential.category_name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {credential.description}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FilterNoneIcon
                                className={classes.iconButtonColored}
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  width: 15,
                                  verticalAlign: "bottom",
                                }}
                                onClick={() => {
                                  onCopy(props, credential, "username");
                                  setMessage("Username Copied");
                                }}
                              />
                              &nbsp;&nbsp;{credential.username}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <FilterNoneIcon
                                className={classes.iconButtonColored}
                                fontSize="small"
                                style={{
                                  cursor: "pointer",
                                  width: 15,
                                  verticalAlign: "bottom",
                                }}
                                onClick={() => {
                                  onCopy(props, credential, "password");
                                  setMessage("Password Copied");
                                }}
                              />
                              &nbsp;&nbsp;
                              <span
                                className={classes.cursor}
                                onClick={(e) => {
                                  if (e.target.innerHTML === "············") {
                                    e.target.innerHTML = String(
                                      credential.password
                                    )
                                      .replaceAll("<", "&lt;")
                                      .replaceAll(">", "&gt;");
                                  } else {
                                    e.target.innerHTML = "············";
                                  }
                                }}
                              >
                                ············
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <p style={{ fontWeight: "bold" }}>Related Entries:</p>
                <DataMappings
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DeviceDetails;
