import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ScreenLoader from "../../Common/Loader";
import Popover from "@material-ui/core/Popover";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import KeyGen from "../../Common/KeyGen";
import { validPassword } from "../../Common/Utility";
import DataMappings from "../DataMappings";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { DeviceLang } from "../../Common/Languages";

export var device_status = 0;
export function setDS(prop){
  device_status = prop;
}

const DeviceForm = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [device, setDevice] = useState({ company_id: props.match.params.id });
  const [deviceError, setDeviceError] = useState({});
  const [sites, setSites] = useState();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const { requiredError, blankError, urlOrIpError, websitePlaceholder } =
    commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const [siteMappings, setSiteMappings] = useState();
  const [availableSites, setAvailableSites] = useState();
  const [dataMappings, setDataMappings] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const lang = localStorage.getItem("languages");

  const handleClickShowKeyGen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseShowKeyGen = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `companies/${id}/devices/${props.objectId}`,
        (device) => {
          setDevice(device);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
      http.Get(
        `data_mappings?from_id=${props.objectId}`,
        (data) => {
          setDataMappings(data);
        },
        () => {}
      );
    }
    http.Get(
      `companies/${id}/sites`,
      (sites) => {
        var valueMap = { "": "NONE" };
        for (let i = 0; i < sites.length; i++) {
          valueMap[sites[i].id] = sites[i].site_name;
        }
        setSites(valueMap);
      },
      () => {}
    );

    http.Get(`categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Device Type`, (data) => {
      setCategories(data);
    });

    http.Get(`categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Tag Type`, (data) => {
      setTags(data);
    });

    localStorage.setItem("sitesForDevice", "");
  }, []);

  useEffect(() => {
    if (sites) {
      getSiteMappings();
    }
  }, [sites]);

  useEffect(() => {
    if (sites && siteMappings) {
      var availableList = { ...sites };
      for (let i = 0; i < siteMappings.length; i++) {
        if (availableList[siteMappings[i]["value"]]) {
          delete availableList[siteMappings[i]["value"]];
        }
      }
      setAvailableSites(availableList);
    }
  }, [sites, siteMappings]);

  const getSiteMappings = () => {
    if (props.objectId) {
      http.Get(
        `device_site_mappings/?device=${props.objectId}`,
        (data) => {
          var siteList = [];
          for (let i = 0; i < data.length; i++) {
            data[i]["value"] = data[i]["site"];
            data[i]["label"] = sites[data[i]["site"]];
            data[i]["onDelete"] = () => {
              http.Delete(
                "device_site_mappings/" + data[i]["id"] + "/",
                {},
                (data) => {
                  getSiteMappings();
                },
                (status, error) => {}
              );
            };
            siteList.push(data[i]);
          }
          setSiteMappings(siteList);
        },
        () => {}
      );
    } else {
      var data = localStorage.getItem("sitesForDevice");
      if (data) {
        var relatedSites = data.split(",");
        var relatedSiteList = [];
        relatedSites.map((relatedSite) => {
          if (relatedSite) {
            var data = {};
            data["value"] = relatedSite;
            data["label"] = sites[relatedSite];
            data["onDelete"] = (e) => {
              var relatedSites = localStorage.getItem("sitesForDevice");
              if (relatedSites) {
                localStorage.setItem(
                  "sitesForDevice",
                  relatedSites.replace(relatedSite + ",", "")
                );
                getSiteMappings();
              }
              var subDatas = device.subDatas;
              if (subDatas) {
                var newSubDatas = subDatas.filter((subData) => {
                  return subData.data.site !== relatedSite;
                });
                setDevice({
                  ...device,
                  subDatas: newSubDatas,
                });
              }
            };
            relatedSiteList.push(data);
          }
        });
        setSiteMappings(relatedSiteList);
      } else {
        localStorage.setItem("sitesForDevice", "");
        setSiteMappings([]);
      }
    }
  };

  const onChange = (e) => {
    setDevice({
      ...device,
      [e.target.name]: e.target.value,
    });
    setDeviceError({
      ...deviceError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    setIsSubmitted(true);
    const send = () => {
      if (props.objectId) {
        http.Put(
          `companies/${id}/devices/${props.objectId}`,
          device,
          (device) => {
            http.Delete(
              "data_mappings",
              { from_id: props.objectId },
              () => {
                var promises = dataMappings.map((dataMapping) => {
                  dataMapping.from_id = props.objectId;
                  return http.Post(
                    "data_mappings",
                    dataMapping,
                    (data) => {
                      console.log(data);
                    },
                    (status, error) => {
                      console.log(status);
                      console.log(error);
                    }
                  );
                });
                Promise.all(promises).then((results) => {
                  props.handleObjectSubmit();
                  setLoadData(false);
                });
              },
              () => {
                props.handleObjectSubmit();
                setLoadData(false);
              }
            );
          },
          (status, error) => {
            if (status === 400) setDeviceError(error);
            setIsSubmitted(false);
            setLoadData(false);
          }
        );
      } else {
        http.Post(
          `companies/${id}/devices`,
          device,
          (newDevice) => {
            if (device.subDatas) {
              device.subDatas.map((subData) => {
                if (subData.data instanceof FormData) {
                  subData.data.append(subData.id, newDevice.id);
                } else {
                  subData.data[subData.id] = newDevice.id;
                }
                http.Post(
                  subData.url,
                  subData.data,
                  (data) => {
                    console.log(data);
                  },
                  (status, error) => {
                    console.log(status);
                    console.log(error);
                  }
                );
              });
            }
            var promises = dataMappings.map((dataMapping) => {
              dataMapping.from_id = newDevice.id;
              return http.Post(
                "data_mappings",
                dataMapping,
                (data) => {
                  console.log(data);
                },
                (status, error) => {
                  console.log(status);
                  console.log(error);
                }
              );
            });
            Promise.all(promises).then((results) => {
              props.handleObjectSubmit();
              setLoadData(false);
            });
          },
          (status, error) => {
            if (status === 400) setDeviceError(error);
            setIsSubmitted(false);
            setLoadData(false);
          }
        );
      }
    };

    // If been presented with the 'unsafe password error' once, allow user to POST again
    validPassword(device).then(({ res, error }) => {
      if (res === true) {
        console.log("res supposed to be true", res);
        device["flagged_pass"] = false;
        send();
      } else if (error) {
        console.log("res supposed to be false", res);
        // Flag password as unsafe for UI feedback
        device["flagged_pass"] = true;
        console.log(device);
        send();
      }
    });
  };

  const handleClickShowPassword = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.objectId ? DeviceLang.EditDevice[lang] : DeviceLang.AddNewDevice[lang]}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>{DeviceLang.DEVICENAME[lang]+' *'}</p>
              <TextValidator
                id="device_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="device_name"
                value={device.device_name}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(deviceError.device_name)}
                helperText={
                  deviceError.device_name && deviceError.device_name[0]
                }
              />
              <p className={classes.label}>{DeviceLang.DEVICETYPE[lang]}</p>
              <TextValidator
                id="category"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="category"
                value={device.category || ""}
                onChange={onChange}
                error={Boolean(deviceError.category)}
                helperText={deviceError.category && deviceError.category[0]}
              >
                {categories && categories.length > 0 && (
                  <MenuItem value={""}>{DeviceLang.NONE[lang]}</MenuItem>
                )}
                {categories && categories.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    {DeviceLang.helper[lang]}
                  </MenuItem>
                ) : (
                  categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>
              <p className={classes.label}>{DeviceLang.DESCRIPTION[lang]}</p>
              <TextValidator
                id="description"
                fullWidth
                variant="outlined"
                margin="dense"
                name="description"
                value={device.description || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(deviceError.description)}
                helperText={
                  deviceError.description && deviceError.description[0]
                }
              />
              <p className={classes.label}>{DeviceLang.URL[lang]}</p>
              <TextValidator
                id="url"
                fullWidth
                variant="outlined"
                margin="dense"
                name="url"
                value={device.url || ""}
                placeholder={websitePlaceholder}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(deviceError.url)}
                helperText={deviceError.url && deviceError.url[0]}
              />
              <p className={classes.label}>{DeviceLang.USERNAME[lang]}</p>
              <TextValidator
                id="new-name"
                autoComplete="new-name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="new-name"
                value={device.username || ""}
                onChange={(e) => {
                  setDevice({
                    ...device,
                    ["username"]: e.target.value,
                  });
                  setDeviceError({
                    ...deviceError,
                    ["username"]: false,
                  });
                  setIsSubmitted(false);
                }}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.username)}
                helperText={deviceError.username && deviceError.username[0]}
              />
              <p className={classes.label}>{DeviceLang.PASSWORD[lang]}</p>
              <TextValidator
                id="new-pass"
                autoComplete="new-pass"
                fullWidth
                variant="outlined"
                margin="dense"
                type={isPasswordShow ? "text" : "password"}
                name="new-pass"
                value={device.password || ""}
                onChange={(e) => {
                  setDevice({
                    ...device,
                    ["password"]: e.target.value,
                  });
                  setDeviceError({
                    ...deviceError,
                    ["password"]: false,
                  });
                  setIsSubmitted(false);
                }}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.password)}
                helperText={deviceError.password && deviceError.password[0]}
                InputProps={{
                  style: { fontFamily: "Raleway" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {isPasswordShow ? (
                          <Visibility className={classes.iconButtonColored} />
                        ) : (
                          <VisibilityOff className={classes.disabled} />
                        )}
                      </IconButton>
                      <IconButton
                        aria-label="KeyGen"
                        onClick={handleClickShowKeyGen}
                      >
                        <FingerprintIcon
                          className={classes.iconButtonColored}
                        />
                      </IconButton>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseShowKeyGen}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <KeyGen
                          setPassword={(p) => {
                            setDevice({
                              ...device,
                              ["password"]: p,
                            });
                            handleCloseShowKeyGen();
                          }}
                        />
                      </Popover>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={3} alignContent="space-between">
                <Grid item lg={6}>
                  <p className={classes.label}>{DeviceLang.TAGNUMBER[lang]}</p>
                  <TextValidator
                    fullWidth
                    id="tag_number"
                    variant="outlined"
                    margin="dense"
                    name="tag_number"
                    value={device.tag_number || ""}
                    onChange={onChange}
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(deviceError.tag_number)}
                    helperText={
                      deviceError.tag_number && deviceError.tag_number[0]
                    }
                  />
                </Grid>

                <Grid item lg={6}>
                <p className={classes.label}>{DeviceLang.TAG[lang]}</p>
              <TextValidator
                id="tag_type"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="tag_type"
                value={device.tag_type || ""}
                onChange={onChange}
                error={Boolean(deviceError.tag_type)}
                helperText={deviceError.tag_type && deviceError.tag_type[0]}
              >
                <MenuItem
                      key={"empty"}
                      value={null}
                      
                    >
                      {DeviceLang.NONE[lang]}
                </MenuItem>
                {tags && tags.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    {DeviceLang.Notagsfound[lang]}
                  </MenuItem>
                ) : (
                  
                  tags.map((option) => (
                
                    <MenuItem key={option.id} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>
                </Grid>
              </Grid>
              <p className={classes.label}>{DeviceLang.OPERATINGSYSTEM[lang]}</p>
              <TextValidator
                id="os"
                fullWidth
                variant="outlined"
                margin="dense"
                name="os"
                value={device.os || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.os)}
                helperText={
                  deviceError.os && deviceError.os[0]
                }
              />
              <p className={classes.label}>{DeviceLang.HARDWAREMAKE[lang]}</p>
              <TextValidator
                id="hw_make"
                fullWidth
                variant="outlined"
                margin="dense"
                name="hw_make"
                value={device.hw_make || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.hw_make)}
                helperText={
                  deviceError.hw_make && deviceError.hw_make[0]
                }
              />
              <p className={classes.label}>{DeviceLang.HARDWAREMODEL[lang]}</p>
              <TextValidator
                id="hw_model"
                fullWidth
                variant="outlined"
                margin="dense"
                name="hw_model"
                value={device.hw_model || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.hw_model)}
                helperText={
                  deviceError.hw_model && deviceError.hw_model[0]
                }
              />
              <p className={classes.label}>{DeviceLang.HARDWARESERIALNUMBER[lang]}</p>
              <TextValidator
                id="hw_serial_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="hw_serial_number"
                value={device.hw_serial_number || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.hw_serial_number)}
                helperText={
                  deviceError.hw_serial_number && deviceError.hw_serial_number[0]
                }
              />
              <p className={classes.label}>{DeviceLang.LASTLOGINNAME[lang]}</p>
              <TextValidator
                id="last_login_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="last_login_name"
                value={device.last_login_name || ""}
                onChange={onChange}
                inputProps={{ maxLength: 100 }}
                error={Boolean(deviceError.last_login_name)}
                helperText={
                  deviceError.last_login_name && deviceError.last_login_name[0]
                }
              />
              <p className={classes.label}>{DeviceLang.NOTES[lang]}</p>
              <TextValidator
                id="notes"
                fullWidth
                variant="outlined"
                margin="dense"
                name="notes"
                multiline
                rows={3}
                value={device.notes || ""}
                onChange={onChange}
                error={Boolean(deviceError.notes)}
                helperText={deviceError.notes && deviceError.notes[0]}
              />
              <p className={classes.label}>{DeviceLang.SITE[lang]}</p>
              <TextValidator
                id="location"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="location_id"
                value={device.location_id || ""}
                onChange={onChange}
                error={Boolean(deviceError.location_id)}
                helperText={
                  deviceError.location_id && deviceError.location_id[0]
                }
              >
                {sites &&
                  Object.entries(sites).map(([key, value]) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
              </TextValidator>

              {availableSites && (
                <div>
                  <p className={classes.label}>{DeviceLang.TAGLOCATION[lang]}</p>
                  <Select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value=""
                    onChange={(e) => {
                      if (props.objectId) {
                        http.Post(
                          "device_site_mappings/",
                          { site: e.target.value, device: props.objectId },
                          (data) => {
                            getSiteMappings();
                          },
                          (status, error) => {}
                        );
                      } else {
                        var subDatas = device.subDatas;
                        if (!subDatas) {
                          subDatas = [];
                        }
                        subDatas.push({
                          url: "device_site_mappings/",
                          data: { site: e.target.value },
                          id: "device",
                        });
                        setDevice({
                          ...device,
                          subDatas: subDatas,
                        });

                        var data = localStorage.getItem("sitesForDevice");
                        if (!data) {
                          data = "";
                        }
                        localStorage.setItem(
                          "sitesForDevice",
                          data + e.target.value + ","
                        );
                        getSiteMappings();
                      }
                    }}
                  >
                    {Object.entries(availableSites).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {siteMappings &&
                    siteMappings.map((value) => {
                      return (
                        <p>
                          {" "}
                          {value["label"]}
                          <Button
                            onClick={(e) => {
                              value.onDelete(e);
                            }}
                          >
                            <CancelIcon />
                          </Button>
                        </p>
                      );
                    })}
                </div>
              )}

              <p className={classes.label}>{DeviceLang.RelatedEntries[lang]}</p>
              <DataMappings
                {...props}
                fromId={props.objectId}
                fromModel={"Device"}
                dataMappings={dataMappings}
                setDataMappings={setDataMappings}
              />
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              {DeviceLang.Cancel[lang]}
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="secondary"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
              onClick={() => props.objectId ?device_status=0:device_status=1}
            >
              {DeviceLang.Save[lang]}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default DeviceForm;
