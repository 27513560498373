import React, { useState }from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const MyComponent = (props) => {
  const [editorHtml, setEditorHtml] = useState(props.default ?? '');
  const handleChange = (html) =>{
    setEditorHtml(html)
    props.onChange(html)
  }
  return (
    <SunEditor setContents={editorHtml} setOptions = {{
      minHeight: "350px",
      imageResizing:true,
      imageFileInput:true,
      tableCellControllerPosition:'cell',
      buttonList:[['undo', 'redo'],
      ['font', 'fontSize', 'formatBlock'],
      ['paragraphStyle', 'blockquote'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      // ['fontColor', 'hiliteColor', 'textStyle'],
      ['removeFormat'],
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'lineHeight'],
      ['table', 'link', 'image', 'video'],
      ['fullScreen', 'showBlocks', 'codeView'],
      ['preview'],]}} onChange={handleChange}/>
  );
};
export default MyComponent;