import React, { useEffect, useState } from "react";
import http from "../../../Common/RestAPIHandler";
import JobPostingForm from "./JobPostingForm";
import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import useStyles from "../../../Common/Style/FormStyle";
import IconButton from "@material-ui/core/IconButton";
import { Divider, Typography, Paper } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { formatDateTime } from "../../../Common/Utility";
import { hasPermission } from "../../../Common/permission";
import {jobLang} from "../../../Common/Languages";

const JobPostingDetials = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [content, setContent] = useState();
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();

  const lang = localStorage.getItem("languages");


  useEffect(() => {
    http.Get(
      `hr_job_postings/${id}`,
      (item) => {
        setContent(item);
      },
      () => {}
    );
    
  }, []);

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <div>
      {content && (
        <div>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            disableEnforceFocus
            classes={{
              paper: classes.drawerForm,
            }}
          >
            <div style={{ width: width }}>
              <table>
                <tbody>
                  <tr>
                    <td
                      draggable
                      style={{ cursor: "ew-resize" }}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      <DragIndicatorIcon />
                    </td>
                    <td width="100%">
                      <JobPostingForm
                        {...props}
                        closeOpen={(open) => setOpen(open)}
                        objectId={content.id}
                        handleObjectSubmit={handleObjectSubmit}
                        template={{data:''}}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Drawer>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}} classes={{ h6: classes.title }}>
              {content.title}
            </Typography>
            <span className={classes.subText} >{formatDateTime(content.last_modified_time)} {jobLang.by[lang]} {content.last_modified_by_name}</span>
          </Grid>
          <Grid item xs={2}>
            <div style={{paddingTop:10,float:"right"}}>
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                    if(hasPermission(props, ["Edit Job Postings"])){
                      setOpen(true);
                    }
                  }}
                >
                  <CreateIcon />
                </IconButton>
              
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                    var divToPDF = document.getElementById('divToPDF');
                    html2canvas(divToPDF)
                      .then((canvas) => {
                        var imgData = canvas.toDataURL('image/png');
                        var imgWidth = 210; 
                        var pageHeight = 292;
                        var imgHeight = canvas.height * imgWidth / canvas.width;
                        var heightLeft = imgHeight;
                        var doc = new jsPDF('p', 'mm');
                        var position = 0;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                        
                        while (heightLeft >= 0) {
                          position = heightLeft - imgHeight;
                          doc.addPage();
                          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                          heightLeft -= pageHeight;
                        }
                        doc.save(content.id+".pdf");
                      })
                    ;
                  }}
                >
                  <SaveAltIcon />
                </IconButton>
                
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    props.history.push(
                      process.env.PUBLIC_URL + `/hr/jobPostings`
                    );}}
                >
                  <CancelIcon />
                </IconButton>     
            </div>
          </Grid>
          <Grid item xs={12}>   
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                <p
                  style={{ maxHeight: "calc(100vh - 350px)",
                  minHeight: "calc(100vh - 350px)",
                  overflowY: "auto",
                  overflowX: "auto", 
                  marginLeft:10}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
                </Paper>
                <div id="divToPDF"
                  style={{ position:"absolute",
                    left:"-8000px",
                    width:"780px",
                    padding:"30px"}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </div>
      )}
    </div>
  );
};

export default JobPostingDetials;
