import React, { useState, useEffect } from "react";
import ObjectForm from "../../../Common/ObjectForm";
import moment from 'moment';
var key1 = moment.duration(604800,'seconds');
const formFields = [
  {
    id: "name",
    label: "NAME *",
    type: "text"
  },
  {
    id: "url",
    label: "URL",
    type: "text"
  },
  {
    id: "username",
    label: "USERNAME",
    type: "text"
  },
  {
    id: "password",
    label: "PASSWORD",
    type: "text"
  },
  {
    id: "content",
    label: "NOTES",
    type: "textarea"
  },
  {
    type: "divider",
  },
  {
    id: "lifetime",
    label: "LIFETIME",
    type: "select",
    valueMap:{604800:"7 days",259200:"3 days",86400:"1 day",43200:"12 hours",14400:"4 hours",3600:"1 hour",1800:"30 minutes",300:"5 minutes"}
  },
  {
    id: "passphrase",
    label: "PASSPHRASE",
    type: "password"
  },
  {
    id: "usage",
    label: "NUMBER OF TIMES VIEWABLE",
    type: "integer",
    value: 1
  },
  
];

const ConfigurationForm = props => {
  const { id } = props.match.params;
  const objectId = props.objectId || id;
  return (
    <ObjectForm
      {...props}
      objectId={objectId}
      url={`tenant_user_configurations`}
      formName={"Vault"}
      formFields={formFields}
      object={{ tenant_user: props.user ? props.user.id :null ,usage:1,lifetime:3600}}
    />
  );
};

export default ConfigurationForm;
