import React, { useState, useEffect } from "react";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import ScreenLoader from "../../Common/Loader";
import CreateIcon from "@material-ui/icons/Create";
import SaveIcon from "@material-ui/icons/Save";
import DateFnsUtils from "@date-io/date-fns";
import { DomainLang } from "../../Common/Languages";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  Divider,
  Dialog,
  Chip,
  CardContent,
  Card,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import { ConvertDateToLocale } from "../../Common/Utility";

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "-webkit-fill-available",
    borderRadius: 8,
  },
  chip: {
    borderRadius: 8,
    color: "white",
    backgroundColor: theme.colorScheme,
    width: "-webkit-fill-available",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  chipOutlined: {
    borderRadius: 8,
    borderColor: theme.colorScheme,
    marginRight: "2px",
    color: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  blankChip: {
    borderRadius: 8,
    width: "-webkit-fill-available",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  bkIcon: {
    background: "black",
    color: "white",
  },
  cursor: {
    cursor: "pointer",
    color: theme.colorScheme,
    marginRight: "10px",
  },
  endIcons: {
    cursor: "pointer",
    color: theme.colorScheme,
    display: "flex",
    justifyContent: "flex-end",
  },
  FprintIconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    float: "right",
  },
  iconColorScheme: {
    cursor: "pointer",
    color: "#a4a4a4",
    // color: theme.colorScheme,
    width: 15,
    verticalAlign: "bottom",
  },
  errorIconColorScheme: {
    color: "#f44336",
    width: 15,
  },
  copy: {
    cursor: "pointer",
    color: theme.colorScheme,
  },
  error: {
    color: "red",
  },
  wordWrap: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "200px",
  },
  checkboxColor: {
    color: theme.colorScheme + "!important",
  },
  button: {
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  tables: {
    minWidth: 300,
  },
  tableCellHeader: {
    color: `${theme.tableHeaderTitle} !important`,
    backgroundColor: theme.tableHeaderBG,
  },
  tableCell: {
    borderBottom: "None",
    color: theme.subText,
  },
  tableRow: {
    backgroundColor: theme.notification,
    "&:hover": {
      backgroundColor: theme.tableRowHoverAlt,
    },
  },
}));

const SubDomain = (props) => {
  const classes = useStyles();
  const [subDomain, setSubDomain] = useState(props.subDomain);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const onChange = (e) => {
    if ("checkbox" === e.target.type || "radio" === e.target.type) {
      setSubDomain({
        ...subDomain,
        [e.target.name]: !subDomain[e.target.name],
      });
    } else
      setSubDomain({
        ...subDomain,
        [e.target.name]: e.target.value,
      });
  };

  const handlePut = (e) => {
    setEdit(false);
    setLoading(true);
    http.Put(
      `sub_domains/${subDomain.id}`,
      subDomain,
      (data) => {
        setSubDomain(data);
        setErrors({});
        setLoading(false);
      },
      (status, error) => {
        setErrors(error);
        setEdit(true);
        setLoading(false);
      }
    );
  };

  const deleteSubDomain = (subDomainId) => {
    setLoading(true);
    http.Delete(
      `sub_domains/${subDomainId}`,
      {},
      () => {
        props.fetchData();
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <React.Fragment>
      {loading && (
        <TableRow className={classes.tableRow}>
          <TableCell colSpan={8} className={classes.tableCell}>
            <ScreenLoader />
          </TableCell>
        </TableRow>
      )}
      {!props.mobile && !loading && edit && (
        <TableRow key={subDomain.id} className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <Input
              fullWidth
              variant="outlined"
              margin="dense"
              onChange={onChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handlePut();
                }
              }}
              type="text"
              name="sub_domain_name"
              style={{
                border: "0px",
                borderBottom: "1px solid black",
                outline: "none",
              }}
              value={subDomain.sub_domain_name}
            />
            <p style={{ color: "red" }}>{errors.sub_domain_name}</p>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <KeyboardDatePicker
              autoOk
              fullWidth
              disableToolbar
              variant="inline"
              margin="dense"
              name="ssl_expiration_date"
              format="yyyy-MM-dd"
              value={
                subDomain["ssl_expiration_date"] &&
                new Date(
                  new Date(subDomain["ssl_expiration_date"]).getTime() +
                    new Date(subDomain["ssl_expiration_date"]).getTimezoneOffset() *
                      60 *
                      1000
                )
              }
              onChange={(value) => {
                if (value && String(value) !== "Invalid Date") {
                  setSubDomain({
                    ...subDomain,
                    ssl_expiration_date: value
                      .toISOString()
                      .split("T")[0],
                  });
                } else {
                  setSubDomain({
                    ...subDomain,
                    ssl_expiration_date: value,
                  });
                }
              }}
              placeholder={DomainLang.SSLEXPIRATIONDATE[lang]}
            />
            <p style={{ color: "red" }}>{errors.ssl_expiration_date}</p>
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.endIcons}`}>
            <SaveIcon onClick={handlePut} className={classes.cursor} />
          </TableCell>
        </TableRow>
      )}
      {!props.mobile && !loading && !edit && (
        <TableRow key={subDomain.id} className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            {subDomain.sub_domain_name}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {subDomain.icon_ssl} {subDomain.ssl_expiration_date}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.endIcons}`}>
            <CreateIcon
              onClick={(e) => {
                setEdit(true);
              }}
              className={classes.cursor}
            />
            <DeleteIcon
              onClick={(e) => {
                deleteSubDomain(subDomain.id);
              }}
              className={classes.cursor}
            />
          </TableCell>
        </TableRow>
      )}
      {props.mobile && !loading && edit && (
        <Dialog
          id={"hightop"}
          open={edit}
          onClose={() => setEdit(false)}
          disablePortal
          // style={{ width: 350, margin: 0 }}
        >
          {/* Need to redo the error handling <p> tags effecting styling */}
          <Card style={{ width: 300 }}>
            <CardContent>
              <Grid container spacing={1} direction="column">
                <Grid item xs={12}>
                  <TextField
                    id="sub_domain_name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="sub_domain_name"
                    value={subDomain.sub_domain_name || ""}
                    onChange={onChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handlePut();
                      }
                    }}
                    placeholder={DomainLang.SUBDOAMINNAME[lang]}
                  />
                  <p className={classes.error}>{errors.sub_domain_name}</p>
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    name="ssl_expiration_date"
                    format="yyyy-MM-dd"
                    value={
                      subDomain["ssl_expiration_date"] &&
                      new Date(
                        new Date(subDomain["ssl_expiration_date"]).getTime() +
                          new Date(subDomain["ssl_expiration_date"]).getTimezoneOffset() *
                            60 *
                            1000
                      )
                    }
                    onChange={(value) => {
                      if (value && String(value) !== "Invalid Date") {
                        setSubDomain({
                          ...subDomain,
                          ssl_expiration_date: value
                            .toISOString()
                            .split("T")[0],
                        });
                      } else {
                        setSubDomain({
                          ...subDomain,
                          ssl_expiration_date: value,
                        });
                      }
                    }}
                    placeholder="SSL EXPIRATION DATE"
                  />
                  <p className={classes.error}>{errors.ssl_expiration_date}</p>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Chip
                      variant="outlined"
                      onClick={() => setEdit(false)}
                      label="Cancel"
                      className={classes.blankChip}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Chip
                      label="Save"
                      onClick={handlePut}
                      className={classes.chip}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Dialog>
      )}
      {props.mobile && !loading && !edit && (
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12}>
            <Card style={{ borderRadius: 10 }}>
              <CardContent style={{ display: "flex", height: 80 }}>
                <Grid container item xs={11} spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="overline">{DomainLang.SUBDOMAINNAME[lang]}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {subDomain.sub_domain_name
                        ? subDomain.sub_domain_name
                        : " "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={1} spacing={0}>
                  <Grid item xs={12} style={{ alignSelf: "center" }}>
                    <IconButton size="small" className={classes.bkIcon}>
                      {collapse ? (
                        <KeyboardArrowUpIcon
                          onClick={() => setCollapse((prev) => !prev)}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          onClick={() => setCollapse((prev) => !prev)}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
              <Collapse key={subDomain.id} in={collapse} timeout="auto">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  style={{ margin: 10 }}
                >
                  <Grid item xs={12}>
                    <Typography variant="overline">
                      SSL EXPIRATION DATE
                    </Typography>
                    <Typography variant="body2">
                      {subDomain.icon_ssl}{" "}
                      {subDomain.ssl_expiration_date
                        ? subDomain.ssl_expiration_date
                        : " "}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  spacing={1}
                  direction="row-reverse"
                  style={{ height: 50 }}
                >
                  <Grid item xs className={classes.endIcons}>
                    <DeleteIcon
                      fontSize="small"
                      onClick={(e) => {
                        deleteSubDomain(subDomain.id);
                      }}
                      className={classes.cursor}
                    />
                    <CreateIcon
                      fontSize="small"
                      onClick={() => {
                        setEdit(true);
                      }}
                      className={classes.cursor}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const SubDomains = (props) => {
  const classes = useStyles();
  const categories = props.categories;
  const [errors, setErrors] = useState({});
  const [subDomains, setSubDomains] = useState([]);
  const [subDomain, setSubDomain] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const today = new Date();
  const expirationDate = new Date(
    today.getTime() + localStorage.getItem("notification_days") * 86400000
  );

  const fetchData = () => {
    setLoading("Loading");
    setSubDomains([]);
    http.Get(
      `sub_domains?domain=${props.domain.id}`,
      (data) => {
        data.map((subDomain) => {
          if (
            subDomain.ssl_expiration_date &&
            ConvertDateToLocale(subDomain.ssl_expiration_date) < today
          ) {
            subDomain["icon_ssl"] = (
              <Tooltip title="SSL has expired.">
                <ClearIcon />
              </Tooltip>
            );
          } else if (
            subDomain.ssl_expiration_date &&
            ConvertDateToLocale(subDomain.ssl_expiration_date) < expirationDate
          ) {
            subDomain["icon_ssl"] = (
              <Tooltip title="SSL will expire soon.">
                <WarningIcon />
              </Tooltip>
            );
          }
        });
        setSubDomains(data);
        setLoading(null);
      },
      () => {
        setLoading(null);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = (e) => {
    setLoading(true);
    subDomain.domain = props.domain.id;
    http.Post(
      `sub_domains`,
      subDomain,
      (subDomain) => {
        fetchData();
        setSubDomain({});
        setErrors({});
        setLoading(false);
      },
      (status, error) => {
        setErrors(error);
        setLoading(false);
      }
    );
  };

  const onChange = (e) => {
    if ("checkbox" === e.target.type || "radio" === e.target.type) {
      setSubDomain({
        ...subDomain,
        [e.target.name]: !subDomain[e.target.name],
      });
    } else
      setSubDomain({
        ...subDomain,
        [e.target.name]: e.target.value,
      });
  };

  const onBlur = (e) => {
    http.Get(
      `domain_lookup?domain_name=${e.target.value}.${props.domain.domain_name}`,
      data => {
        if(data['ssl_expiration_date']){
          setSubDomain({
            ...subDomain,
            ssl_expiration_date: data['ssl_expiration_date'].split("T")[0],
          });
        }
      },
      () => {}
    );
  };

  return (
    <div>
      {loading && <ScreenLoader />}

      {!props.mobile && !loading && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Table aria-label="simple table" style={{ backgroundColor: "white" }}>
            <TableBody>
              {subDomains.map((subDomain) => {
                return (
                  <SubDomain
                    {...props}
                    categories={categories}
                    subDomain={subDomain}
                    fetchData={fetchData}
                  />
                );
              })}
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Input
                    id="sub_domain_name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="sub_domain_name"
                    value={subDomain.sub_domain_name || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        onSubmit();
                      }
                    }}
                    placeholder={DomainLang.SUBDOMAINNAME[lang]}
                  />
                  <p className={classes.error}>{errors.sub_domain_name}</p>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    name="ssl_expiration_date"
                    format="yyyy-MM-dd"
                    value={
                      subDomain["ssl_expiration_date"] &&
                      new Date(
                        new Date(subDomain["ssl_expiration_date"]).getTime() +
                          new Date(subDomain["ssl_expiration_date"]).getTimezoneOffset() *
                            60 *
                            1000
                      )
                    }
                    onChange={(value) => {
                      if (value && String(value) !== "Invalid Date") {
                        setSubDomain({
                          ...subDomain,
                          ssl_expiration_date: value
                            .toISOString()
                            .split("T")[0],
                        });
                      } else {
                        setSubDomain({
                          ...subDomain,
                          ssl_expiration_date: value,
                        });
                      }
                    }}
                    placeholder="SSL EXPIRATION DATE"
                  />
                  <p className={classes.error}>{errors.ssl_expiration_date}</p>
                </TableCell>
                <TableCell
                  className={`${classes.tableCell} ${classes.endIcons}`}
                >
                  <Tooltip arrow={true} title={DomainLang.AddNew[lang]} placement="top-start">
                    <IconButton size="small" onClick={onSubmit}>
                      <AddIcon className={classes.cursor} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MuiPickersUtilsProvider>
      )}

      {props.mobile && !loading && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            spacing={1}
            style={{ justifyContent: "center", paddingTop: 8 }}
          >
            <Grid item xs={12}>
              <Chip
                label="ADD SUB DOMAIN"
                variant="outlined"
                style={{
                  width: "-webkit-fill-available",
                  height: 45,
                  fontSize: "initial",
                }}
                onClick={() => setOpenModal(true)}
                className={classes.chipOutlined}
              />
            </Grid>
            <br />
            {subDomains.map((subDomain) => {
              if (subDomain.employee === props.employeeId) {
                return (
                  <Grid item xs={12}>
                    <SubDomain
                      {...props}
                      categories={categories}
                      subDomain={subDomain}
                      fetchData={fetchData}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>

          <Dialog
            id={"hightop"}
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            disablePortal
          >
            {/* Need to redo the error handling <p> tags effecting styling */}
            <Card style={{ width: 300 }}>
              <CardContent>
                <Grid container spacing={0} direction="column">
                  <Grid item xs={12}>
                    <TextField
                      id="sub_domain_name"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="sub_domain_name"
                      value={subDomain.sub_domain_name || ""}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onSubmit();
                        }
                      }}
                      placeholder={DomainLang.SUBDOMAINNAME[lang]}
                    />
                    <p className={classes.error}>{errors.sub_domain_name}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      autoOk
                      name="ssl_expiration_date"
                      disableToolbar
                      variant="inline"
                      margin="dense"
                      inputVariant="outlined"
                      format="yyyy-MM-dd"
                      fullWidth
                      value={subDomain.ssl_expiration_date || ""}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          onSubmit();
                        }
                      }}
                      placeholder="SSL EXPIRATION DATE"
                    />
                    <p className={classes.error}>
                      {errors.ssl_expiration_date}
                    </p>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: 0 }}>
                    <Grid item xs={6}>
                      <Chip
                        variant="outlined"
                        onClick={() => setOpenModal(false)}
                        label="Cancel"
                        className={classes.blankChip}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Chip
                        label="Save"
                        onClick={onSubmit}
                        className={classes.chip}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Dialog>
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
};

export default SubDomains;
