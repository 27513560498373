import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import http from "../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import CommonConstants from "../Common/CommonConstants";
import ScreenLoader from "../Common/Loader";
import companyLogo from "../../Images/SyncMonkey_Registration_Logo.png";
import backsplash from "../../Images/SyncMonkey_Registration_BG.png";
import {
  Checkbox,
  CssBaseline,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fefefefe",
    width: "auto",
    margin: "auto",
  },
  error: {
    color: "red",
  },
  formContainer: {
    alignSelf: "center",
    padding: 50
  },
  formFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fontColor: {
    color: "#f4f4f4",
  },
  leftPanel: {
    display: "flex",
    backgroundColor: "#2a2d38",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backsplash})`,
    backgroundPosition: "bottom",
  },
  loginButton: {
    borderRadius: 5,
    color: "#d9d941",
    textTransform: "capitalize",
    borderColor: "#d9d941",
    "&:hover": {
      color: "#e4e643",
      borderColor: "#e4e643",
      backgroundColor: "#9c9ba0",
    },
    width: 300
  },
  logo: {
    maxWidth: "100%",
  },
  pageContainer: {
    minHeight: "100vh",
    width: "auto",
    overflow: "hidden"
  },
  regButton: {
    margin: 10,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    color: "#585555",
    textTransform: "capitalize",
    borderColor: "#d9d941",
    "&:hover": {
      backgroundColor: "#e4e643",
      borderColor: "#e4e643",
    },
  },
  rightPanel: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
		backgroundColor: "#f7f9fc",
    alignItems: "center",
  },
  spacer: {
    height: 200,
    [theme.breakpoints.down("md")]: {
      height: 10,
    },
  },
}));

const Register = (props) => {
  const classes = useStyles();
  const [object, setObject] = useState({
    is_tenant_admin: false,
    number_of_result_per_page: 25,
		promo_opt: false
  });
  const [error, setError] = useState({});
  const [loadData, setLoadData] = useState(false);
  const errorText = CommonConstants.error;

  let text1 = "I don't wish to receive any promotional emails from SyncMonkey";


  const onChange = (e) => {
    if ("checkbox" === e.target.type || "radio" === e.target.type) {
      setObject({
        ...object,
        [e.target.name]: !object[e.target.name],
      });
    } else if ("file" === e.target.type) {
      setObject({
        ...object,
        [e.target.name]: e.target.files[0],
      });
    } else setError({});
    setObject({
      ...object,
      [e.target.name]: e.target.value,
    });
  };

  const nextStep = (e) => {
    e.preventDefault();
    if (object["password"] === object["confirmPassword"]) {
      setError({ confirmPassword: null });
      setLoadData(true);
      http.Post(
        "register",
        object,
        (response) => {
          window.location = process.env.PUBLIC_URL + "/registered";
        },
        (status, error) => {
          setError(error);
          setLoadData(false);
        }
      );
    } else {
      setError({ confirmPassword: "Passwords Do Not Match" });
    }
  };

  React.useEffect(() => {
    const script1 = document.createElement('script');  
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-FJW8VPDDCX";
    script1.async = true;  
    document.body.appendChild(script1);

    const script2 = document.createElement('script');  
    script2.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-FJW8VPDDCX');";
    script2.async = false;  
    document.body.appendChild(script2);

    const script3 = document.createElement('script');  
    script3.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PB4BXZ8');";
    script3.async = false;  
    document.body.appendChild(script3);

    const script4 = document.createElement('noscript');  
    script4.innerHTML = <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PB4BXZ8" height="0" width="0" style="display:none;visibility:hidden"></iframe>;
    script4.async = false;  
    document.body.appendChild(script4);
  
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={0} className={classes.pageContainer}>
        <Grid item md={12} lg={5} className={classes.leftPanel}>
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.fontColor}>
                  WELCOME TO{" "}
                  <span style={{ color: "#d9d941" }}>SYNCMONKEY</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.fontColor}>
                  Empower your MSP and manage your client's documents and
                  accesses in one place. Register now to start your 15 day
                  trial.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.fontColor}>
                  Already have an account?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.loginButton}
                  size="large"
                  onClick={() => {
                    window.location.replace(process.env.PUBLIC_URL + "/login");
                  }}
                >
                  LOG IN
                </Button>
                <div className={classes.spacer}>&nbsp;</div>
              </Grid>
            </Grid>
        </Grid>
        <Grid item md={12} lg={7} className={classes.rightPanel}>
          {loadData && <ScreenLoader />}
          {!loadData && (
            <Grid container spacing={3} className={classes.formContainer}>
              <Grid item xs={12}>
                <img src={companyLogo} alt="SyncMonkey" className={classes.logo}></img>
              </Grid>
              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={nextStep}
                  onError={(errors) => console.log(errors)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        id="first_name"
                        label="First Name"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="first_name"
                        className={classes.dualInput}
                        value={object["first_name"] || ""}
                        onChange={onChange}
                        validators={["required"]}
                        errorMessages={["This Field is Required"]}
                        error={error["first_name"]}
                        helperText={error["first_name"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        id="last_name"
                        label="Last Name"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="last_name"
                        className={classes.dualInput}
                        value={object["last_name"] || ""}
                        onChange={onChange}
                        validators={["required"]}
                        errorMessages={["This Field is Required"]}
                        error={error["last_name"]}
                        helperText={error["last_name"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextValidator
                        id="company_name"
                        label="Company Name"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="company_name"
                        value={object["company_name"] || ""}
                        onChange={onChange}
                        validators={["required"]}
                        errorMessages={["This Field is Required"]}
                        error={error["company_name"]}
                        helperText={error["company_name"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextValidator
                        id="email"
                        label="Email"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="email"
                        value={object["email"] || ""}
                        onChange={onChange}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "This Field is Required",
                          "Enter a valid email address",
                        ]}
                        error={error["email"] || error["__all__"]}
                        helperText={error["email"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        id="password"
                        label="Password"
                        fullWidth
                        type="password"
                        variant="outlined"
                        margin="dense"
                        name="password"
                        className={classes.dualInput}
                        value={object["password"] || ""}
                        onChange={onChange}
                        validators={[
                          "required",
                          "minStringLength:8",
                          "matchRegexp:(?=.*[A-Z])",
                          "matchRegexp:(?=.*[0-9])",
                          "matchRegexp:(?=.*[!@#$%^&*'+])",
                        ]}
                        errorMessages={[
                          errorText.requiredError,
                          errorText.eightChar,
                          errorText.uppercase,
                          errorText.numeric,
                          errorText.specialChar,
                        ]}
                        error={error["password"]}
                        helperText={error["password"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        id="confirmPassword"
                        label="Confirm Password"
                        fullWidth
                        type="password"
                        name="confirmPassword"
                        className={classes.dualInput}
                        variant="outlined"
                        margin="dense"
                        value={object["confirmPassword"] || ""}
                        onChange={onChange}
                        validators={[
                          "required",
                          "minStringLength:8",
                          "matchRegexp:(?=.*[A-Z])",
                          "matchRegexp:(?=.*[0-9])",
                          "matchRegexp:(?=.*[!@#$%^&*'+])",
                        ]}
                        errorMessages={[
                          errorText.requiredError,
                          errorText.eightChar,
                          errorText.uppercase,
                          errorText.numeric,
                          errorText.specialChar,
                        ]}
                        error={error["confirmPassword"]}
                        helperText={error["confirmPassword"]}
                      />
                    </Grid>
                  </Grid>
                  &nbsp;
                  <Grid item xs={12} className={classes.formFooter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="promo_opt"
                          checked={object['promo_opt'] ? true : false}
                          onClick={e => setObject({...object, [e.target.name]: !object[e.target.name] })}
                          size="small"
                        />
                      }
                      label={text1}
                    />
                    <Typography variant="body2" align="center">
                      By clicking the submit button, you are creating a
                      SyncMonkey account, and agree to SyncMonkey’s{" "}
                      <a href="https://syncmonkey.com/terms-and-conditions/">
                        Terms of Use{" "}
                      </a>
                      and{" "}
                      <a href="https://syncmonkey.com/privacy-policy/">
                        Privacy Policy
                      </a>
                      .
                    </Typography>
                  </Grid>
                  &nbsp;
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="outlined"
                        size="large"
                        className={classes.regButton}
                      >
                        REGISTER
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;
