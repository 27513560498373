import React, { useEffect, useState } from "react";
import http from "../../../../Common/RestAPIHandler";
import {
  Chip,
  Divider,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell} from "@material-ui/core";
import ScreenLoader from "../../../../Common/Loader";
import useStyles from "../../../../Common/Style/CommonStyle";
import {ReportsLang} from "../../../../Common/Languages";

const VacationDetails = (props) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  // For creating date strings based on return value of Date.prototype.getMonth() [0 to 11].
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const dayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const vacationTypes = ["Vacation", "Sick", "Personal", "Moving"];

  const lang = localStorage.getItem("languages");

  // Create string of date range for time off
  const getDateRangeStr = (startDateStr, endDateStr) => {
      const startDate = new Date(new Date(startDateStr).getTime() + new Date(startDateStr).getTimezoneOffset() * 60 * 1000);
      // If no end date exists, assume it's a same-day time off and output one date only.
      if (!endDateStr) return `${dayName[startDate.getDay()]}, ${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`;
      const endDate = new Date(new Date(endDateStr).getTime() + new Date(endDateStr).getTimezoneOffset() * 60 * 1000);
      // If startDate and endDate are the same, return one date only. Otherwise return a date range.
      if (startDate.getTime() === endDate.getTime()) return `${dayName[startDate.getDay()]}, ${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`;
      else return `${dayName[startDate.getDay()]}, ${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()} to ${dayName[endDate.getDay()]}, ${monthNames[endDate.getMonth()]} ${endDate.getDate()}, ${endDate.getFullYear()}`;
  }

  // Create string showing days/hours taken for time off.
  const getDiffStr = (endDate, hours) => {
    // If no end date exists, we can assume it's a same-day time off
    if (!endDate) {
        // Output hours.
        if (hours != 1) return `${hours} hours`;
        else return '1 hour';
    }
    // Output days based on number of work hours.
    const diff = hours / 8;
    if (diff != 1) return `${diff} days`;
    else return '1 day';
  }

  useEffect(() => {
      for (const type of vacationTypes) {
          props.details[type].map((entry) => {
              entry['str'] = `${getDateRangeStr(entry.start_date, entry.end_date)} (${getDiffStr(entry.end_date, entry.hours)})`;
              if (!entry.available_hours_used) entry['str'] += ' (UNPAID)'
          });
      }
      props.details.Other.map((entry) => {
          entry['str'] = `${getDateRangeStr(entry.start_date, entry.end_date)} (${getDiffStr(entry.end_date, entry.hours)}) - Other: ${entry.reason}`;
          if (!entry.available_hours_used) entry['str'] += ' (UNPAID)'
      });
      setData(props.details);
  }, []);

  // Export pdf file of vacation details. File is created from backend template.
  const exportData = () => {
    http.ExportToPdf(
      `hr/report/vacation-details/export_pdf`,
      data,
      (blob) => {
        //window.open(window.URL.createObjectURL(blob));
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Vacation Details - ${data.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      () => {}
    );
  }

  return (
    <>
    {data ? (
        <Grid container spacing={1}>
            <Grid item xs={9}>
                <Typography variant="h4" component="h4" style={{paddingTop:10, paddingBottom:10, paddingLeft:10}}>
                    {`${data.name}`}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <div style={{paddingTop:10, paddingBottom:10,float:"right"}}>
                <Chip
                    label="EXPORT"
                    clickable
                    className={classes.chip}
                    color="primary"
                    onClick={exportData}
                />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6" style={{paddingLeft:10}}>
                    {`${data.title}, ${data.department}`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6" style={{paddingLeft:10, paddingBottom:20}}>
                    {`${data.location}`}
                </Typography>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.Summary[lang]}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <div
                        style={{ overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Table className={{root: classes.table}}>
                                    <TableHead>
                                        <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>{ReportsLang.VACATION[lang]}</TableCell>
                                        <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>{ReportsLang.SICK[lang]}</TableCell>
                                        <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>{ReportsLang.PERSONAL[lang]}</TableCell>
                                        <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>{ReportsLang.MOVING[lang]}</TableCell>
                                        <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>{ReportsLang.OTHER[lang]}</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow classes={classes.tableRow}>
                                            <TableCell classes={classes.tableCell}>{data.Vacation_days_total}</TableCell>
                                            <TableCell classes={classes.tableCell}>{data.Sick_days_total}</TableCell>
                                            <TableCell classes={classes.tableCell}>{data.Personal_days_total}</TableCell>
                                            <TableCell classes={classes.tableCell}>{data.Moving_days_total}</TableCell>
                                            <TableCell classes={classes.tableCell}>{data.Other_days_total}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.VacationDays[lang]}
                </Typography>
                <List>
                    {data.Vacation.length === 0 && (
                        <ListItem disablePadding>
                            <ListItemText primary={"None"} />
                        </ListItem>
                    ) || data.Vacation.map((entry, index) => { return (
                        <ListItem disablePadding key={index}>
                            <ListItemText primary={entry.str} />
                        </ListItem>
                        )}
                    )}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.SickDays[lang]}
                </Typography>
                <List>
                    {data.Sick.length === 0 && (
                        <ListItem disablePadding>
                            <ListItemText primary={"None"} />
                        </ListItem>
                    ) || data.Sick.map((entry, index) => { return (
                        <ListItem disablePadding key={index}>
                            <ListItemText primary={entry.str} />
                        </ListItem>
                        )}
                    )}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.PersonalDays[lang]}
                </Typography>
                <List>
                    {data.Personal.length === 0 && (
                        <ListItem disablePadding>
                            <ListItemText primary={"None"} />
                        </ListItem>
                    ) || data.Personal.map((entry, index) => { return (
                        <ListItem disablePadding key={index}>
                            <ListItemText primary={entry.str} />
                        </ListItem>
                        )}
                    )}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.MovingDays[lang]}
                </Typography>
                <List>
                    {data.Moving.length === 0 && (
                        <ListItem disablePadding>
                            <ListItemText primary={"None"} />
                        </ListItem>
                    ) || data.Moving.map((entry, index) => { return (
                        <ListItem disablePadding key={index}>
                            <ListItemText primary={entry.str} />
                        </ListItem>
                        )}
                    )}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{paddingTop:20, paddingLeft:10}}>
                    {ReportsLang.Other[lang]}
                </Typography>
                <List>
                    {data.Other.length === 0 && (
                        <ListItem disablePadding>
                            <ListItemText primary={"None"} />
                        </ListItem>
                    ) || data.Other.map((entry, index) => { return (
                        <ListItem disablePadding key={index}>
                            <ListItemText
                                primary={entry.str} />
                        </ListItem>
                        )}
                    )}
                </List>
            </Grid>
        </Grid>
    ) : <ScreenLoader />}
    </>
  );
};

export default VacationDetails;
