import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 260;
const headerHeight = 130;
const footerHeight = 20;
const tenantTrialHeight = 48;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  active: {
    color: theme.colorScheme + "!important",
  },
  appdiv: {
    height: headerHeight,
    width: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    width: "100%",
    height: headerHeight,
  },
  arrowIcon: {
    height: 35,
  },
  companyName: {
    fontSize: "120%",
    color: theme.companyName,
    width: drawerWidth - 20,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  date: {
    color: theme.text,
    fontWeight: 300,
  },
  divider: {
    backgroundColor: theme.dividersLight,
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.sidebar,
    overflowX: "hidden",
  },
  drawerCompanyName: {
    paddingLeft: 10,
    paddingTop: 20,
  },
  footerdiv: {
    position: "fixed",
    bottom: 15,
    color: theme.text,
    backgroundColor: theme.pallete,
    fontWeight: 500,
  },
  full_image: {
    display: "block",
    height: 70,
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  headerRightGrid: {
    width: "max-content",
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconButton: {
    backgroundColor: "transparent !important",
    padding: "0px !important",
    color: theme.subText, 
    "&:hover": {
      color: theme.text
    }
  },
  iconColorTheme: {
    color: theme.menuButton,
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  link: {
    color: theme.subText,
  },
  listItem: {
    paddingRight: 0,
  },
  listItemText: {
    fontSize: "99%",
  },
  listItemIcon: {
    minWidth: 30,
    color: theme.icons,
  },
  navdiv: {
    width: drawerWidth,
    height: "calc(100vh - " + (headerHeight + tenantTrialHeight) + "px)",
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  maindiv: {
    width: "calc(100% - " + drawerWidth + "px)",
    height: "calc(100vh - " + (headerHeight + footerHeight) + "px)",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.pallete,
  },
  menuButton: {
    color: theme.menuButton,
    marginRight: theme.spacing(1),
    display: "none",
  },
  menuItem: {
    marginLeft: 5,
    whiteSpace: "normal",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    maxHeight: 70,
  },
  menuHeader: {
    fontWeight: "bold",
    marginLeft: 5,
  },
  mobile_image: {
    height: 40,
    width: 40,
    display: "none",
  },
  mobileHide: {
    [theme.breakpoints.down(762)]: {
      display: "none",
    },
  },
  moreIcon: {
    color: "#000000",
  },
  "@media (min-width: 960px)": {
    menuButton: {
      color: theme.menuButton,
      marginRight: theme.spacing(1),
      display: "block",
    },
    navdiv: {
      display: "none",
    },
    maindiv: {
      width: "100%",
    },
    footerdiv: {
      marginLeft: 0,
    },
  },
  "@media (max-width: 550px)": {
    full_image: {
      display: "none",
    },
    mobile_image: {
      display: "block",
    },
    maindiv: {
      height: "calc(100vh - " + (headerHeight + footerHeight + 10 + tenantTrialHeight) + "px)",
    },
  },
  "@media (min-width: 376px)": {
    toolbarTrial: {
      backgroundColor: theme.colorScheme,
      placeContent: "center"
    }
  },
  navBarMargin: {
    width: 120,
  },
  navDivider: {
    color: "#9c9ba0",
  },
  navBarLink: {
    margin: theme.spacing(1),
    color: theme.navBarLink,
    underline: "none",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "80%",
    cursor: "pointer"
  },
  notificationBadge: { 
    backgroundColor: theme.colorScheme
  },
  searchIconButton: {
    padding: "0px !important",
    backgroundColor: "transparent !important",
    color: theme.subText,
    "&:hover": {
      color: theme.text
    }
  },
  sectionDesktop: {
    [theme.breakpoints.down(700)]: {
      display: "none",
    },
  },
  sectionMobile: {
    display: "none",
    [theme.breakpoints.down(700)]: {
      display: "flex",
    },
  },
  show: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sideMenu: {
    "&:hover": {
      backgroundColor: theme.tableRowHover
    }
  },
  superscript: {
    fontSize: "0.7em",
  },
  toolbarOne: {
    height: 75,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: theme.navBarOne
  },
  toolbarTwo: {
    height: "55px !important",
    minHeight: "55px !important",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: theme.navBarTwo,
  },
  toolbarTrial: {
    backgroundColor: theme.colorScheme,
  },
  time: {
    color: theme.text,
    fontSize: 30,
    fontWeight: 300,
  },
  trialNotice: {
    color: theme.text,
    textAlign: "center",
    // fontSize: "small",
  },
  iconFloatRight: {
    cursor: "pointer",
    color: theme.colorScheme,
    position: "fixed",
    top: headerHeight + 10,
    right: 10,
    zIndex: theme.zIndex.drawer + 1,
  },
  numberCircle: {
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    padding: "8px",

    background: "grey",
    border: "0px",
    color: "white",
    textAlign: "center",

    font: "28px",
  },
}));

export default useStyles;
