import React, { useEffect, useReducer, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import http from "../../Common/RestAPIHandler";
import {dashboard} from "../../Common/Languages";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Checkbox,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { HTMLParse } from "../../Common/Utility";
import CakeIcon from "@material-ui/icons/Cake";
import { addMonths } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ScreenLoader from "../../Common/Loader";
import Badge from "@material-ui/core/Badge";
import VisibilityIcon from "@material-ui/icons/Visibility";
import eagle_eye from "../../../Images/eagle_eye.svg";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/HighlightOff";
import useStyles from "../Style/style";

const initialState = { show: false };

function reducer(state, action) {
  switch (action.type) {
    case "on":
      return { show: true };
    case "off":
      return { show: false };
    default:
      throw new Error();
  }
}

const Notification = (props) => {
  const classes = useStyles();
  const [loaderState, dispatch] = useReducer(reducer, initialState);
  const [errorNotifications, setErrorNotifications] = useState([]);
  const [warningNotifications, setWarningNotifications] = useState([]);
  const [birthdayNotifications, setBirthdayNotifications] = useState([]);
  const [objectId,setObjectId] = useState();
  const [eventNotifications, setEventNotifications] = useState([]);
  const [breachNotifications, setBreachNotifications] = useState([]);
  const [affectedEmployees, setAffectedEmployees] = useState([]);
  const [affectedCompanies, setAffectedCompanies] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [licenses, setLicenses] = useState();
  const [domains, setDomains] = useState();
  const [ssls, setSSLs] = useState();
  const [hrs, setHRs] = useState();
  const [accountings, setAccountings] = useState();
  const [errorCount, setErrorCount] = useState(0);
  const [warningCount, setWarningCount] = useState(0);
  const [birthdayCount, setBirthdayCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [showBreach, setShowBreach] = useState(true); // set this to true to show breach notifications in components
  const [showWarning, setShowWarning] = useState(true);
  const [showError, setShowError] = useState(true);
  const [showBirthday, setShowBirthday] = useState(true);
  const [showEvent, setShowEvent] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [loadHIBP, setLoadHIBP] = useState(false);
  const [loadHIBPemp, setLoadHIBPemp] = useState(false);
  const [loadLicense, setLoadLicense] = useState(false);
  const [loadDomain, setLoadDomain] = useState(false);
  const [loadDomainSSL, setLoadDomainSSL] = useState(false);
  const [loadHr, setLoadHr] = useState(false);
  const [loadAccounting, setLoadAccounting] = useState(false);
  const [activeIds, setActiveIds] = useState([]);

  const lang = localStorage.getItem("languages");

  var today = new Date();
  var expirationDate = new Date(
    today.getTime() +
      (localStorage.getItem("notification_days") || 30) * 86400000
  );

  const dialogTarget = (notification) => {
    if (activeIds.includes(notification.id)) {
      setOpen(false);
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== notification.id;
        })
      );
    } else {
      setOpen(true);
      activeIds.push(notification.id);
      setActiveIds(activeIds);
    }
  };


  const fetchCompanyHistoryItemClick = (path) => {
    if (path.category === "license"){
      window.location = process.env.PUBLIC_URL + "/company/" + path.cid + "/software_licenses?input_search=" + path.search + "&show_id=" + path.id;
      //window.location = process.env.PUBLIC_URL + "/company/" + path.cid + "/software_licenses";
      setObjectId(path.id)
    }else if (path.category === "domain"){
      window.location = process.env.PUBLIC_URL + "/company/" + path.cid + "/domains?input_search=" + path.search + "&show_id=" + path.id;
      //window.location = process.env.PUBLIC_URL + "/company/" + path.cid + "/software_licenses";
      setObjectId(path.id)}
    
  };

  const checkLoaders = () => {
    if (
      loadHIBP ||
      loadHIBPemp ||
      loadLicense ||
      loadDomain ||
      loadDomainSSL ||
      loadHr ||
      loadAccounting
    ) {
      setLoadData(true);
    } else {
      setLoadData(false);
    }
  };

  useEffect(() => {
    setLoadData(true);
    setLoadHIBP(true);
    http.Get(
      `feature/hibp/${props.user.tenant}`,
      (res) => {
        setBreachNotifications(res.data);
      },
      (err) => {
        console.log(err.message);
        setLoadHIBP(false);
      }
    );

    setLoadHIBPemp(true);
    http.Get(
      `feature/hibp/${props.user.tenant}/employees`,
      (res) => {
        setAffectedEmployees(res.data);
      },
      (res) => {
        console.log(res.message);
        setLoadHIBPemp(false);
      }
    );

    setLoadLicense(true);
    http.Get(
      `software_licenses/?expiration_date=${expirationDate.getFullYear()}-${
        expirationDate.getMonth() + 1
      }-${expirationDate.getDate()}`,
      (licenses) => {
        setLicenses(licenses);
      },
      () => {
        setLoadLicense(false);
      }
    );

    setLoadDomain(true);
    http.Get(
      `company_domains?expiration_date=${expirationDate.getFullYear()}-${
        expirationDate.getMonth() + 1
      }-${expirationDate.getDate()}`,
      (domains) => {
        setDomains(domains);
      },
      () => {
        setLoadDomain(false);
      }
    );

    setLoadDomainSSL(true);
    http.Get(
      `ssls?ssl_expiration_date=${expirationDate.getFullYear()}-${
        expirationDate.getMonth() + 1
      }-${expirationDate.getDate()}`,
      (domains) => {
        setSSLs(domains);
      },
      () => {
        setLoadDomainSSL(false);
      }
    );

    if (props.user.is_tenant_admin) {
      setLoadHr(true);
      http.Get(
        `hr_employees/?status=true`,
        (hrs) => {
          setHRs(hrs);
        },
        () => {
          setLoadHr(false);
        }
      );
    }

    if (props.user.is_tenant_admin) {
      setLoadAccounting(true);
      http.Get(
        `company_payment_infos?expiration=${
          Math.floor(expirationDate.getFullYear() / 100) * 100 +
          expirationDate.getMonth()
        }`,
        (accs) => {
          setAccountings(accs);
        },
        () => {
          setLoadAccounting(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    // Use a Map to store affected companies as key value pairs [company, incident]
    let companies = new Map();

    let arr = [];

    // Check for matches of companies with employees included in breaches and keeps a tally
    breachNotifications &&
      breachNotifications.map((breach) => {
        if (!breach.count) {
          breach.count = 0;
          affectedEmployees &&
            affectedEmployees.map((employee) => {
              if (employee.incident === breach.Name) {
                breach.count++;
                setBreachNotifications([...breachNotifications, breach]);
                const { company, incident } = employee;
                let obj = {
                  company_id: employee.company_id,
                  company: company,
                  incident: incident,
                };

                // Check if Array has size, add entry if it does not already exist
                // Add entry if Array is empty, no need to check
                if (!arr.length) {
                  arr.push(obj);
                  // Put array of affected companies into state
                  setAffectedCompanies(...affectedCompanies, arr);
                } else if (arr.length > 0) {
                  if (arr.find((el) => el.company == obj.company)) {
                    console.log("found");
                    return;
                  }
                  console.log("not found");
                  arr.push(obj);
                  setAffectedCompanies(...affectedCompanies, arr);
                } else {
                  console.log("found");
                }
              }
            });
        }
      });

    setLoadData(false);
  }, [affectedEmployees, breachNotifications]);

  useEffect(() => {
    var errorCountVar = 0;
    var warningCountVar = 0;
    var birthdayCountVar = 0;
    var eventCountVar = 0;

    if (licenses && domains && hrs && ssls && accountings) {
      licenses.map((license) => {
        let diffTime = Math.abs(new Date(license.expiration_date) - today);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let day = "";
        if (diffDays > 1) {
          day = " days ";
        } else day = " day ";

        if (new Date(license.expiration_date) >= today) {
          warningCountVar = warningCountVar + 1;
          warningNotifications.push({
            id: license.id,
            cid: license.company,
            category: "license",
            company_name: license.company_name,
            search: license.name,
            name: license.name + " license will expire soon.",
            days: "In " + diffDays + day + ".",
            diffDays: diffDays,
            icon: <WarningIcon />,
            type: "warning",
          });
        } else {
          errorCountVar = errorCountVar + 1;
          errorNotifications.push({
            id: license.id,
            cid: license.company,
            company_name: license.company_name,
            category: "license",
            search: license.name,
            name: license.name + " license has expired.",
            days: diffDays + day + "ago",
            diffDays: diffDays,
            icon: <ClearIcon />,
            type: "error",
          });
        }
      });

      domains.map((domain) => {
        let diffTime = Math.abs(new Date(domain.expiration_date) - today);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let day = "";
        if (diffDays > 1) {
          day = " days ";
        } else day = " day ";
        if (new Date(domain.expiration_date) >= today) {
          warningCountVar = warningCountVar + 1;
          warningNotifications.push({
            id: domain.id,
            cid: domain.company,
            company_name: domain.company_name,
            search: domain.domain_name,
            category: "domain",
            name: domain.domain_name + " domain will expire soon.",
            days: "In " + diffDays + day + ".",
            diffDays: diffDays,
            icon: <WarningIcon />,
            type: "warning",
          });
        } else {
          errorCountVar = errorCountVar + 1;
          errorNotifications.push({
            id: domain.id,
            cid: domain.company,
            category: "domain",
            company_name: domain.company_name,
            name: domain.domain_name + " domain has expired.",
            search: domain.domain_name,
            days: diffDays + day + "ago",
            diffDays: diffDays,
            icon: <ClearIcon />,
            type: "error",
          });
        }
      });

      ssls.map((domain) => {
        let diffTime = Math.abs(new Date(domain.ssl_expiration_date) - today);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let day = "";
        if (diffDays > 1) {
          day = " days ";
        } else day = " day ";
        if (new Date(domain.ssl_expiration_date) >= today) {
          warningCountVar = warningCountVar + 1;
          warningNotifications.push({
            id: domain.id,
            company_name: domain.company_name,
            search: "domain",
            cid: domain.company,
            name: domain.domain_name + " SSL will expire soon.",
            days: "In " + diffDays + day + ".",
            diffDays: diffDays,
            icon: <WarningIcon />,
            type: "warning",
          });
        } else {
          errorCountVar = errorCountVar + 1;
          errorNotifications.push({
            id: domain.id,
            company_name: domain.company_name,
            search: "domain",
            cid: domain.company,
            name: domain.domain_name + " SSL has expired.",
            days: diffDays + day + "ago",
            diffDays: diffDays,
            icon: <ClearIcon />,
            type: "error",
          });
        }
      });

      if (props.user.is_tenant_admin) {
        hrs.map((hr) => {
          let the_date = new Date(
            new Date(hr.birth_date).getTime() +
              new Date(hr.birth_date).getTimezoneOffset() * 60 * 1000
          );
          let diffTime =
            new Date(the_date.setYear(today.getFullYear())) - today;
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          let day = "";
          if (diffDays > 1) {
            day = " days";
          } else day = " day ";
          if (
            diffDays > 0 &&
            diffDays < localStorage.getItem("notification_days")
          ) {
            birthdayCountVar = birthdayCountVar + 1;
            birthdayNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: "'s birthday soon.",
              days: "In " + diffDays + day + ".",
              diffDays: diffDays,
              icon: <CakeIcon />,
              type: "birthday",
            });
          } else if (diffDays === 0) {
            birthdayCountVar = birthdayCountVar + 1;
            birthdayNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: "'s birthday today.",
              days: "",
              diffDays: diffDays,
              icon: <CakeIcon />,
              type: "birthday",
            });
          }

          the_date = new Date(
            new Date(hr.joining_date).getTime() +
              new Date(hr.joining_date).getTimezoneOffset() * 60 * 1000
          );
          diffTime = new Date(the_date.setYear(today.getFullYear())) - today;
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          day = "";
          if (diffDays > 1) {
            day = " days ";
          } else day = " day ";
          if (
            diffDays > 0 &&
            diffDays < localStorage.getItem("notification_days")
          ) {
            eventCountVar = eventCountVar + 1;
            eventNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: "'s anniversary soon.",
              days: "In " + diffDays + day + ".",
              diffDays: diffDays,
              icon: <EventIcon />,
              type: "event",
            });
          } else if (diffDays === 0) {
            eventCountVar = eventCountVar + 1;
            eventNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: `'s anniversary today.`,
              days: "",
              diffDays: diffDays,
              icon: <EventIcon />,
              type: "event",
            });
          }

          the_date = new Date(
            new Date(hr.joining_date).getTime() +
              new Date(hr.joining_date).getTimezoneOffset() * 60 * 1000
          );
          diffTime = new Date(addMonths(the_date, 3)) - today;
          diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          day = "";
          if (diffDays > 1) {
            day = " days ";
          } else day = " day ";
          if (
            diffDays > 0 &&
            diffDays < localStorage.getItem("notification_days")
          ) {
            eventCountVar = eventCountVar + 1;
            eventNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: "'s probation end soon.",
              days: "In " + diffDays + day + ".",
              diffDays: diffDays,
              icon: <EventIcon />,
              type: "event",
            });
          } else if (diffDays === 0) {
            eventCountVar = eventCountVar + 1;
            eventNotifications.push({
              id: hr.id,
              company_name: hr.first_name,
              name: "'s probation end today.",
              days: "",
              diffDays: diffDays,
              icon: <EventIcon />,
              type: "event",
            });
          }
        });

        accountings.map((acc) => {
          var expYear = Math.floor(acc.expiration / 100);
          var expMonth = acc.expiration % 100;
          let diffTime = Math.abs(
            new Date(expYear + 2000, expMonth, 1) - today
          );
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          let day = "";
          if (diffDays > 1) {
            day = " days ";
          } else day = " day ";
          if (
            2000 + expYear === today.getFullYear() &&
            expMonth - 1 === today.getMonth()
          ) {
            warningCountVar = warningCountVar + 1;
            warningNotifications.push({
              id: acc.id,
              company_name: acc.company_name,
              name: " credit card will expire soon.",
              days: "In " + diffDays + day + ".",
              diffDays: diffDays,
              icon: <WarningIcon />,
              type: "warning",
            });
          } else {
            errorCountVar = errorCountVar + 1;
            errorNotifications.push({
              id: acc.id,
              company_name: acc.company_name,
              name: " credit card has expired.",
              days: diffDays + day + "ago",
              diffDays: diffDays,
              icon: <ClearIcon />,
              type: "error",
            });
          }
        });
      }
    }

    setWarningCount(warningCountVar);
    setErrorCount(errorCountVar);
    setBirthdayCount(birthdayCountVar);
    setEventCount(eventCountVar);
  }, [licenses, domains, hrs, accountings]);

  useEffect(() => {
    errorNotifications.sort(function(a,b){
      var keyA = a.diffDays,
      keyB = b.diffDays;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
        return 0;
    });
    warningNotifications.sort(function(a,b){
      var keyA = a.diffDays,
      keyB = b.diffDays;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
        return 0;
    });
  },[warningCount,errorCount,birthdayCount,eventCount])

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setNoti(collapse);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item lg>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridItem}>
              <Grid
                container
                spacing={3}
                alignItems="center"
                className={classes.collapse}
              >
                <Grid item xs={12}>
                  {/* ------------- Checkbox positional render for mobile view ------------- */}
                  {props.mobile ? (
                    <>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={10} className={classes.title}>
                          NOTIFICATIONS
                        </Grid>
                        <Grid item xs={2} className={classes.settingItem}>
                          {(collapse && (
                            <ArrowDropDownIcon
                              fontSize="default"
                              className={classes.collapseButton}
                              onClick={handleArrowClick}
                            />
                          )) || (
                            <ArrowDropUpIcon
                              fontSize="default"
                              className={classes.collapseButton}
                              onClick={handleArrowClick}
                            />
                          )}
                        </Grid>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className={classes.checkboxColor}
                                  checked={showError}
                                  onClick={(e) => {
                                    setShowError(!showError);
                                  }}
                                />
                              }
                              label={
                                <Badge
                                  className={classes.notificationIcons}
                                  showZero={true}
                                  badgeContent={errorCount}
                                >
                                  <ClearIcon />
                                </Badge>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className={classes.checkboxColor}
                                  checked={showWarning}
                                  onClick={(e) => {
                                    setShowWarning(!showWarning);
                                  }}
                                />
                              }
                              label={
                                <Badge
                                  className={classes.notificationIcons}
                                  showZero={true}
                                  badgeContent={warningCount}
                                >
                                  <WarningIcon />
                                </Badge>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className={classes.checkboxColor}
                                  checked={showBreach}
                                  onClick={(e) => {
                                    setShowBreach(!showBreach);
                                  }}
                                />
                              }
                              label={
                                <Badge
                                  className={classes.notificationIcons}
                                  showZero={true}
                                  badgeContent={breachNotifications.length}
                                >
                                  <VisibilityIcon/>
                                </Badge>
                              }
                            />
                            {props.user.is_tenant_admin && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkboxColor}
                                    checked={showBirthday}
                                    onClick={(e) => {
                                      setShowBirthday(!showBirthday);
                                    }}
                                  />
                                }
                                label={
                                  <Badge
                                    className={classes.notificationIcons}
                                    showZero={true}
                                    badgeContent={birthdayCount}
                                  >
                                    <CakeIcon />
                                  </Badge>
                                }
                              />
                            )}
                            {props.user.is_tenant_admin && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkboxColor}
                                    checked={showEvent}
                                    onClick={(e) => {
                                      setShowEvent(!showEvent);
                                    }}
                                  />
                                }
                                label={
                                  <Badge
                                    className={classes.notificationIcons}
                                    showZero={true}
                                    badgeContent={eventCount}
                                  >
                                    <EventIcon />
                                  </Badge>
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    // ----- Checkbox render for desktop, fits in between 'NOTIFICATIONS' and collapse icon ----- //
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={4} className={classes.title}>
                        <span>{dashboard.NOTIFICATIONS[lang]}</span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        classes={{ root: classes.dashboardDiv }}
                      >
                        <Tooltip title={dashboard.Errors[lang]} arrow={true}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                size="small"
                                style={{ backgroundColor: "transparent" }}
                                className={classes.checkboxColor}
                                checked={showError}
                                onClick={(e) => {
                                  setShowError(!showError);
                                }}
                              />
                            }
                            label={
                              <Badge
                                className={classes.notificationIcons}
                                showZero={true}
                                badgeContent={errorCount}
                              >
                                <ClearIcon fontSize="small" />
                              </Badge>
                            }
                          />
                        </Tooltip>
                        <Tooltip arrow={true} title={dashboard.Warnings[lang]}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                size="small"
                                style={{ backgroundColor: "transparent" }}
                                className={classes.checkboxColor}
                                checked={showWarning}
                                onClick={(e) => {
                                  setShowWarning(!showWarning);
                                }}
                              />
                            }
                            label={
                              <Badge
                                className={classes.notificationIcons}
                                showZero={true}
                                badgeContent={warningCount}
                              >
                                <WarningIcon fontSize="small" />
                              </Badge>
                            }
                          />
                        </Tooltip>
                        <Tooltip arrow={true} title={dashboard.EagleEye[lang]}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableRipple
                                size="small"
                                style={{ backgroundColor: "transparent" }}
                                className={classes.checkboxColor}
                                checked={showBreach}
                                onClick={(e) => {
                                  setShowBreach(!showBreach);
                                }}
                              />
                            }
                            label={
                              <Badge
                                className={classes.notificationIcons}
                                showZero={true}
                                badgeContent={breachNotifications.length}
                              >
                                <VisibilityIcon fontSize="small" />
                              </Badge>
                            }
                          />
                        </Tooltip>
                        {props.user.is_tenant_admin && (
                          <Tooltip arrow={true} title={dashboard.Birthdays[lang]}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple
                                  size="small"
                                  style={{ backgroundColor: "transparent" }}
                                  className={classes.checkboxColor}
                                  checked={showBirthday}
                                  onClick={(e) => {
                                    setShowBirthday(!showBirthday);
                                  }}
                                />
                              }
                              label={
                                <Badge
                                  className={classes.notificationIcons}
                                  showZero={true}
                                  badgeContent={birthdayCount}
                                >
                                  <CakeIcon fontSize="small" />
                                </Badge>
                              }
                            />
                          </Tooltip>
                        )}
                        {props.user.is_tenant_admin && (
                          <Tooltip arrow={true} title={dashboard.Events[lang]}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple
                                  size="small"
                                  style={{ backgroundColor: "transparent" }}
                                  className={classes.checkboxColor}
                                  checked={showEvent}
                                  onClick={(e) => {
                                    setShowEvent(!showEvent);
                                  }}
                                />
                              }
                              label={
                                <Badge
                                  className={classes.notificationIcons}
                                  showZero={true}
                                  badgeContent={eventCount}
                                >
                                  <EventIcon fontSize="small" />
                                </Badge>
                              }
                            />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={2} className={classes.settingItem}>
                        {(collapse && (
                          <ArrowDropDownIcon
                            fontSize="default"
                            className={classes.collapseButton}
                            onClick={handleArrowClick}
                          />
                        )) || (
                          <ArrowDropUpIcon
                            fontSize="default"
                            className={classes.collapseButton}
                            onClick={handleArrowClick}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Divider
                    classes={{ root: classes.dividers }}
                    component="hr"
                    orientation="horizontal"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loadData ? (
          <ScreenLoader />
        ) : (
          <>
            &nbsp;
            {!collapse && (
              <Grid item xs={12} className={classes.contentGrid}>
                <Grid container spacing={3}>
                  {showBreach &&
                    breachNotifications.map((notification) => {
                      if (notification)
                        return (
                          <>
                            <Grid item xs={12} key={notification.id}>
                              <Paper
                                elevation={0}
                                className={classes.eagleEyeNotification}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Grid item xs={1}>
                                    <PriorityHighIcon
                                      className={classes.alert}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={10}
                                    className={classes.eagleEye}
                                  >
                                    <span className={classes.alert}>
                                      Recent server breach found with credential
                                      dump! &nbsp;
                                    </span>
                                    <span className={classes.title}>
                                      @ {notification.Title} - on -{" "}
                                      {notification.Date} &nbsp; Affected:
                                      &nbsp;
                                      {notification.count}
                                    </span>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Tooltip
                                      arrow={true}
                                      title="More Info"
                                      placement="right-end"
                                    >
                                      <HelpOutlineIcon
                                        cursor="pointer"
                                        fontSize="small"
                                        className={classes.eagleEyeInfo}
                                        onClick={() =>
                                          dialogTarget(notification)
                                        }
                                      />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                            {activeIds && activeIds.includes(notification.id) && (
                              <Dialog
                                id={notification.id}
                                aria-labelledby="simple-dialog-title"
                                open={open}
                                fullScreen={false}
                                disableBackdropClick
                                PaperProps={{
                                  className: classes.eagleEyeDialog,
                                }} // In case we use styling here instead of nav component
                              >
                                <List
                                  component="nav"
                                  className={classes.eagleEyeList}
                                >
                                  <IconButton className={classes.eagleEyeClose}>
                                    <CloseIcon
                                      size="medium"
                                      onClick={() => dialogTarget(notification)}
                                    />
                                  </IconButton>
                                  {Object.keys(notification)
                                    .slice(1, 4)
                                    .map((key, index) => {
                                      return (
                                        <ListItem key={notification.id}>
                                          {index === 0 ? (
                                            <>
                                              <ListItemText
                                                primary={key}
                                                primaryTypographyProps={{
                                                  className: classes.title,
                                                }}
                                                secondary={HTMLParse(
                                                  notification[key]
                                                )}
                                                secondaryTypographyProps={{
                                                  className: classes.subText,
                                                }}
                                              />
                                              <img
                                                src={`${notification.Logo}`}
                                                style={{
                                                  maxHeight: 50,
                                                  maxWidth: 150,
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <ListItemText
                                              primary={key}
                                              primaryTypographyProps={{
                                                className: classes.title,
                                              }}
                                              secondary={HTMLParse(
                                                notification[key]
                                              )}
                                              secondaryTypographyProps={{
                                                className: classes.subText,
                                              }}
                                            />
                                          )}
                                        </ListItem>
                                      );
                                    })}
                                  <Divider
                                    variant="middle"
                                    classes={{ root: classes.dividers }}
                                  />
                                  <ListItem>
                                    <ListItemText
                                      primary="Companies with affected employees: "
                                      primaryTypographyProps={{
                                        className: classes.title,
                                      }}
                                    />
                                  </ListItem>
                                  {affectedCompanies ? (
                                    affectedCompanies.map((company) => {
                                      return (
                                        <ListItem button>
                                          <ListItemText
                                            onClick={() =>
                                              window.location.assign(
                                                `/company/${company.company_id}/employees`
                                              )
                                            }
                                            secondary={company.company}
                                            secondaryTypographyProps={{
                                              className: classes.breaches,
                                            }}
                                          />
                                        </ListItem>
                                      );
                                    })
                                  ) : (
                                    <ListItem>
                                      <ListItemText>None.</ListItemText>
                                    </ListItem>
                                  )}
                                  <ListItem>
                                    <ListItemText
                                      secondary="haveibeenpwned.com"
                                      secondaryTypographyProps={{
                                        className: classes.footnote,
                                      }}
                                    />
                                  </ListItem>
                                </List>
                                <div></div>
                              </Dialog>
                            )}
                          </>
                        );
                    })}
                  
                  {showError &&
                    errorNotifications &&
                    errorNotifications.map((notification) => {
                      return (
                        <Grid item xs={12} key={notification.id}>
                          <Paper elevation={0} className={classes.paperOdd}>
                            <Grid 
                              container
                              spacing={3}
                              alignItems="center"
                              onClick={() => {
                                fetchCompanyHistoryItemClick(notification);
                              }}
                            >
                              <Grid item xs={1}>
                                <ClearIcon />
                              </Grid>
                              <Grid 
                                item xs={11} 
                                style={{ fontSize: "12px" }}>
                                <span className={classes.alert}>
                                  {notification.company_name}
                                </span>
                                {" " + notification.name}
                                <br />
                                {notification.days}
                              </Grid>
                              
                            </Grid>
                            
                          </Paper>
                        </Grid>
                      );
                    })}
                  {showWarning &&
                    warningNotifications &&
                    warningNotifications.map((notification) => {
                        return (
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperEven}>
                              <Grid 
                                container
                                spacing={3}
                                alignItems="center"
                                onClick={() => {
                                  
                                  fetchCompanyHistoryItemClick(notification);
                                }}
                                >
                                <Grid item xs={1}>
                                  {notification.icon}
                                </Grid>
                                <Grid item xs={11} style={{ fontSize: "12px" }}>
                                  <span className={classes.warning}>
                                    {notification.company_name}
                                  </span>{" "}
                                  {notification.name}
                                  <br />
                                  {notification.days}
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                    })}
                  {showBirthday &&
                    birthdayNotifications &&
                    birthdayNotifications.map((notification) => {
                        return (
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperEven}>
                              <Grid 
                                container
                                spacing={3}
                                alignItems="center"
                                onClick={() => {
                                  
                                  fetchCompanyHistoryItemClick(notification);
                                }}
                                >
                                <Grid item xs={1}>
                                  {notification.icon}
                                </Grid>
                                <Grid item xs={11} style={{ fontSize: "12px" }}>
                                  <span className={classes.warning}>
                                    {notification.company_name}
                                  </span>{" "}
                                  {notification.name}
                                  <br />
                                  {notification.days}
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      
                    })}
                  {showEvent &&
                    eventNotifications &&
                    eventNotifications.map((notification) => {
                        return (
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperEven}>
                              <Grid 
                                container
                                spacing={3}
                                alignItems="center"
                                >
                                <Grid item xs={1}>
                                  {notification.icon}
                                </Grid>
                                <Grid item xs={11} style={{ fontSize: "12px" }}>
                                  <span className={classes.warning}>
                                    {notification.company_name}
                                  </span>{" "}
                                  {notification.name}
                                  <br />
                                  {notification.days}
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      
                    })}
                  {/* {errorNotifications &&
                    errorNotifications.map((notification) => {
                      if (showError && notification.type === "error") {
                        return (
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperOdd}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={1}>
                                  {notification.icon}
                                </Grid>
                                <Grid item xs={11} style={{ fontSize: "12px" }}>
                                  <span className={classes.warning}>
                                    {notification.company_name}
                                  </span>{" "}
                                  {notification.name}
                                  <br />
                                  {notification.days}
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      }
                    })} */}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};
export default Notification;
