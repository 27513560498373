import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Page404 from "./Components/404/404";
import Page from "./Components/Common/Page";
import CompanyList from "./Components/Company/CompanyList/CompanyList";
import Dashboard from "./Components/Dashboard/Dashboard";
import Sites from "./Components/Company/Sites/Sites";
import Login from "./Components/Login/Login";
import PasswordLogin from "./Components/Login/PasswordLogin";
import TwoFA from "./Components/Login/TwoFA";
import AzureSSO from "./Components/Login/AzureSSO";
import OneLoginSSO from "./Components/Login/OneLoginSSO";
import OneLoginAuth from "./Components/Login/OneLoginAuth";
import Register from "./Components/Register/Register";
import Registered from "./Components/Register/Registered";
import TenantUsers from "./Components/ResellerPortal/TenantUser/TenantUsers";
import MyProfile from "./Components/Profile/MyProfile";
import CompanyDetails from "./Components/Company/CompanyDetails/CompanyDetails";
import ForgetPassword from "./Components/ResetPassword/ForgetPassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Meetings from "./Components/Tenant/Meeting/Meetings";
import SoftwareLicenses from "./Components/Company/SoftwareLicense/SoftwareLicenses";
import Tenants from "./Components/ResellerPortal/Tenant/Tenants";
import ConnectWise from "./Components/Sync/ConnectWise";
import Ninja from "./Components/Sync/Ninja";
import Syncro from "./Components/Sync/Syncro";
import CompanyDocuments from "./Components/Company/Document/Documents";
import CompanyDocumentDetails from "./Components/Company/Document/DocumentDetails";
import CompanyEmployees from "./Components/Company/Employee/Employees";
import Categories from "./Components/Tenant/Category/Categories";
import Packages from "./Components/ResellerPortal/Package/Packages";
import AdminPacakges from "./Components/AdminPortal/Package/Packages";
import PackageTemplates from "./Components/AdminPortal/PackageTemplate/PackageTemplates";
import Users from "./Components/Tenant/User/Users";
import Template from "./Components/Tenant/Template/Templates";
import Devices from "./Components/Company/Device/Devices";
import Services from "./Components/Company/Service/Services";
import CompanySettings from "./Components/Tenant/Settings/CompanySettings";
import RoleList from "./Components/Tenant/User/Role/RoleList";
import RolePermission from "./Components/Tenant/User/RolePermission/RolePermission";
import TenantDocuments from "./Components/Tenant/Document/Documents";
import TenantDocumentDetails from "./Components/Tenant/Document/DocumentDetails";
import Supports from "./Components/Tenant/Support/Supports";
import Integrations from "./Components/Tenant/Integrations/Integrations";
import TenantInit from "./Components/Tenant/TenantInit";
import CompanyDomains from "./Components/Company/Domain/Domains";
import { connect } from "react-redux";
import { hasPermission, hasPortalPermission } from "./Components/Common/permission";
import Accounting from "./Components/Tenant/Accounting/Accounting";
import AccountDetails from "./Components/Tenant/Accounting/AccountingDetails";
import Address from "./Components/Tenant/Address/Address";
import TenantServices from "./Components/Tenant/Service/Services";
import AccountingReport from "./Components/Tenant/Accounting/AccountingReport";
import KeyGen from "./Components/Common/KeyGen";
import Billing from "./Components/ResellerPortal/Billing/Billing";
import BillingDetails from "./Components/ResellerPortal/Billing/BillingDetails";
import Invoices from "./Components/Tenant/Payment/Invoices";
import Archives from "./Components/Company/Archives";
import Passwords from "./Components/Company/Passwords";
import Subscription from "./Components/Tenant/Payment/Subscription";
import Notifications from "./Components/AdminPortal/Notification/Notifications";
import AdminPage from "./Components/AdminPortal/Common/Page";
import AdminLogin from "./Components/AdminPortal/Login/Login";
import AdminTwoFA from "./Components/AdminPortal/Login/TwoFA";
import ResellerPage from "./Components/ResellerPortal/Common/Page";
import ResellerLogin from "./Components/ResellerPortal/Login/Login";
import ResellerTwoFA from "./Components/ResellerPortal/Login/TwoFA";
import ResellerProfile from "./Components/ResellerPortal/Profile/MyProfile";
import ResellerUsers from "./Components/ResellerPortal/ResellerUser/ResellerUsers";
import Resellers from "./Components/AdminPortal/Reseller/Resellers";
import AdminResellerUsers from "./Components/AdminPortal/ResellerUser/ResellerUsers";
import Configurations from "./Components/Company/Configuration/Configurations";
import TenantUserConfigurations from "./Components/Tenant/User/Configuration/Configurations";
import TenantUserConfiguration from "./Components/Tenant/User/Configuration/Configuration";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getColorScheme } from "./Components/Common/Utility";
import ApiSchemas from "./Components/Tenant/ApiManagement/ApiSchemas";
import JsonSchemas from "./Components/Tenant/JsonSchema/JsonSchemas";
import JsonDatas from "./Components/Company/JsonData/JsonDatas";
import ResellerDashboard from "./Components/ResellerPortal/Dashboard/Dashboard";
import AdminDashboard from "./Components/AdminPortal/Dashboard/Dashboard";
import AdminBilling from "./Components/AdminPortal/Billing/Billing";
import AdminBillingDetails from "./Components/AdminPortal/Billing/BillingDetails";
import TenantArchives from "./Components/Tenant/Archives";
import Files from "./Components/Company/File/Files";
import Permissions from "./Components/AdminPortal/Permission/Permissions";
import PermissionCategories from "./Components/AdminPortal/PermissionCategory/PermissionCategories";
import ResellerPermissions from "./Components/AdminPortal/ResellerPermission/ResellerPermissions";
import ResellerPermissionCategories from "./Components/AdminPortal/ResellerPermissionCategory/ResellerPermissionCategories";
import ResellerRoleList from "./Components/ResellerPortal/ResellerRole/ResellerRoleList";
import ResellerRolePermission from "./Components/ResellerPortal/ResellerRole/ResellerRolePermission/ResellerRolePermission";
import ResellerArchives from "./Components/ResellerPortal/Archives";
import PortalPage from "./Components/ClientPortal/Common/Page";
import Credentials from "./Components/ClientPortal/Credential/Credentials";
import PortalAccounting from "./Components/ClientPortal/Accounting/Accounting";
import PortalEmployees from "./Components/ClientPortal/Employee/Employees";
import PortalLogin from "./Components/ClientPortal/Login/Login";
import PortalTwoFA from "./Components/ClientPortal/Login/TwoFA";
import PortalChangePassword from "./Components/ClientPortal/ResetPassword/ChangePassword";
import PortalUsers from "./Components/Tenant/User/PortalUsers";
import PortalRoleList from "./Components/Tenant/User/Role/PortalRoleList";
import PortalSites from "./Components/ClientPortal/Sites/Sites"
import PortalDevices from "./Components/ClientPortal/Device/Devices"
import PortalSoftwareLicenses from "./Components/ClientPortal/SoftwareLicense/SoftwareLicenses"
import PortalServices from "./Components/ClientPortal/Service/Services"
import PortalJsonDatas from "./Components/ClientPortal/JsonData/JsonDatas"
import PortalCompanyDocuments from "./Components/ClientPortal/Document/Documents";
import PortalCompanyDocumentDetails from "./Components/ClientPortal/Document/DocumentDetails";
import PortalPasswords from "./Components/ClientPortal/Passwords";
import AutoTask from "./Components/Sync/AutoTask";
import IncomingTenants from "./Components/ResellerPortal/TenantTransfer/IncomingTenants";
import OutgoingTenants from "./Components/ResellerPortal/TenantTransfer/OutgoingTenants";
import AdminTenants from "./Components/AdminPortal/Tenant/Tenants";
import HR from "./Components/Tenant/HR/HR";
import HRDashboard from "./Components/Tenant/HR/Dashboard/Dashboard"
import HREmployees from "./Components/Tenant/HR/Employees/Employees"
import HrReports from "./Components/Tenant/HR/Reports/Reports"
import JobPostings from "./Components/Tenant/HR/JobPosting/JobPostings"
import JobPostingDetails from "./Components/Tenant/HR/JobPosting/JobPostingDetails";
import JobPostingTemplates from "./Components/Tenant/HR/JobPostingTemplate/JobPostingTemplates";
import VacationProfiles from "./Components/Tenant/HR/Settings/VacationProfiles/VacationProfiles";
import HRSettings from "./Components/Tenant/HR/Settings/HRSettings";
import Sharings from "./Components/Tenant/Sharing/Sharings";
import ToTenants from "./Components/Tenant/Sharing/ToTenants";
import FromTenants from "./Components/Tenant/Sharing/FromTenants";
import ToTenantDetails from "./Components/Tenant/Sharing/ToTenantDetails";
import Merges from "./Components/Tenant/Merge/Merges";
import Merge from "./Components/Tenant/Merge/Merge";
import ResellerSettings from "./Components/ResellerPortal/Settings/ResellerSettings";
import ResellerAzureSSO from "./Components/ResellerPortal/Login/AzureSSO";
import ResellerOneLoginSSO from "./Components/ResellerPortal/Login/OneLoginSSO";
import ResellerOneLoginAuth from "./Components/ResellerPortal/Login/OneLoginAuth";
import ResellerPasswordLogin from "./Components/ResellerPortal/Login/PasswordLogin";

const App = (props) => {
	

  // Zendesk help widget
	window.zESettings.webWidget["color"]["theme"] = getColorScheme();
	window.zESettings.webWidget["color"]["launcherText"] = "#fafafa";

	const mobile = useMediaQuery("(max-width: 430px)");

	return (
		<>
			<BrowserRouter>
				<Switch>
					{hasPermission(props, ["View Data", "Create Company"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanyList} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanyDetails} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/sites"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Sites} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/servers_devices"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Devices} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/software_licenses"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={SoftwareLicenses} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/other_services"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Services} />
							)}
						/>
					)}
					{hasPermission(props, ["Company Archive Access"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/archives"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Archives} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/passwords"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Passwords} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={
								process.env.PUBLIC_URL +
								"/company/:id/json_datas/:jsonSchemaId/"
							}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={JsonDatas} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/meeting"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Meetings} />
							)}
						/>
					)}
					<Route
						path={process.env.PUBLIC_URL + "/dashboard"}
						exact
						render={(props) => (
							<Page {...props} mobile={mobile} component={Dashboard} />
						)}
					/>
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/services"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={TenantServices} />
							)}
						/>
					)}
					{hasPermission(props, ["Accounting"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/accounting"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Accounting} />
							)}
						/>
					)}
					{hasPermission(props, ["Accounting"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/accounting/:id/details"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={AccountDetails} />
							)}
						/>
					)}
					{hasPermission(props, ["Accounting"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/accountingReport"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={AccountingReport} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/documents"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={TenantDocuments} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/documents/:id/details"}
							exact
							render={(props) => (
								<Page
									{...props}
									mobile={mobile}
									component={TenantDocumentDetails}
								/>
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HRDashboard} />
							)}
						/>
					)}
					
					{/*HR SECTION BEGINS*/}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/employees"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HREmployees} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/jobPostings"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={JobPostings} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/jobPostings/:id/details"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={JobPostingDetails} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/reports"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HrReports} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/settings/general"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HRSettings} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/settings/vacation-profiles"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={VacationProfiles} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/settings/users"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HR} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/settings/roles"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={HR} />
							)}
						/>
					)}
					{hasPermission(props, ["HR"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/hr/settings/jobPostingTemplates"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={JobPostingTemplates} />
							)}
						/>
					)}
					{/*HR SECTION END*/}

					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/support"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Supports} />
							)}
						/>
					)}
					<Route
						path={process.env.PUBLIC_URL + "/myprofile"}
						exact
						render={(props) => (
							<Page {...props} mobile={mobile} component={MyProfile} />
						)}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/configurations"}
						exact
						render={(props) => (
							<Page
								{...props}
								mobile={mobile}
								component={TenantUserConfigurations}
							/>
						)}
					/>
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/templates"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Template} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/address"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Address} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/sharings"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Sharings} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/merges"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Merges} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/merges/:id/details"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Merge} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/to_tenants"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={ToTenants} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/to_tenants/:id/details"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={ToTenantDetails} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/from_tenants"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={FromTenants} />
							)}
						/>
					)}
					<Route
						path={process.env.PUBLIC_URL + "/forgetPassword"}
						exact
						render={(props) => <ForgetPassword {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/resetPassword"}
						exact
						render={(props) => <ResetPassword {...props} />}
					/>
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/sync"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={ConnectWise} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/syncNinja"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Ninja} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/syncSyncro"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Syncro} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/syncAutoTask"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={AutoTask} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/documents"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanyDocuments} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={
								process.env.PUBLIC_URL +
								"/company/:id/documents/:documentId/details"
							}
							exact
							render={(props) => (
								<Page
									{...props}
									mobile={mobile}
									component={CompanyDocumentDetails}
								/>
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/categories"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Categories} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/employees/"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanyEmployees} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/configurations/"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Configurations} />
							)}
						/>
					)}
					{hasPermission(props, ["View Data"]) && (
					  <Route
						path={process.env.PUBLIC_URL + "/company/:id/files"}
						exact
						render={(props) => (
						  <Page {...props} mobile={mobile} component={Files} />
						)}
					  />
					)}
					<Route
						path={process.env.PUBLIC_URL + "/tenantInit"}
						exact
						render={(props) => <TenantInit {...props} />}
					/>
					{hasPermission(props, ["View Data"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/company/:id/domains/"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanyDomains} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/users"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Users} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/user/roles"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={RoleList} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/user/roles/:role_id"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={RolePermission} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/profile"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={CompanySettings} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/invoices"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Invoices} />
							)}
						/>
					)}
					{hasPermission(props, []) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/lockout"}
							exact
							render={(props) => <Subscription {...props} />}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/subscription"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Subscription} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/extensions"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={Integrations} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/json_schemas"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={JsonSchemas} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/api_user_management"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={ApiSchemas} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/archives"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={TenantArchives} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Portal Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/portal-users"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={PortalUsers} />
							)}
						/>
					)}
					{hasPermission(props, ["Manage Portal Users"]) && (
						<Route
							path={process.env.PUBLIC_URL + "/settings/portal-user/roles"}
							exact
							render={(props) => (
								<Page {...props} mobile={mobile} component={PortalRoleList} />
							)}
						/>
					)}
					{/*client portal route begin---------------*/}
					<Route
						path={process.env.PUBLIC_URL + "/portal/login"}
						exact
						render={(props) => <PortalLogin {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/portal/twoFA"}
						exact
						render={(props) => <PortalTwoFA {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/portal/changePassword"}
						exact
						render={(props) => (
							<PortalPage
								{...props}
								mobile={mobile}
								component={PortalChangePassword}
							/>
						)}
					/>
					{props.user.role?.status &&
						<Route
							path={process.env.PUBLIC_URL + "/portal/credentials"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={Credentials} />
							)}
						/>
					}
					{hasPortalPermission(props, 'docs_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/documents"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalCompanyDocuments} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'docs_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/documents/:documentId/details"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalCompanyDocumentDetails} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'employees_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/employees"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalEmployees} />
							)}
						/>
					)}
					<Route
						path={process.env.PUBLIC_URL + "/portal/passwords"}
						exact
						render={(props) => (
							<PortalPage {...props} mobile={mobile} component={PortalPasswords} />
						)}
					/>
					{props.user.is_billing_contact && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/billing"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalAccounting} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'sites_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/sites"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalSites} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'devices_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/servers_devices"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalDevices} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'licenses_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/software_licenses"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalSoftwareLicenses} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'services_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/other_services"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalServices} />
							)}
						/>
					)}
					{hasPortalPermission(props, 'custom_read') && (
						<Route
							path={process.env.PUBLIC_URL + "/portal/json_datas/:jsonSchemaId/"}
							exact
							render={(props) => (
								<PortalPage {...props} mobile={mobile} component={PortalJsonDatas} />
							)}
						/>
					)}
					{/*---------------client portal route end*/}

					{/*reseller portal route begin---------------*/}
					<Route
						path={process.env.PUBLIC_URL + "/reseller/login"}
						exact
						render={(props) => <ResellerLogin {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/reseller/twoFA"}
						exact
						render={(props) => <ResellerTwoFA {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/reseller/azuresso"}
						exact
						render={(props) => <ResellerAzureSSO {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/reseller/oneloginsso"}
						exact
						render={(props) => <ResellerOneLoginSSO {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/reseller/oneloginauth"}
						exact
						render={(props) => <ResellerOneLoginAuth {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/reseller/passwordlogin"}
						exact
						render={(props) => <ResellerPasswordLogin {...props} />}
					/>
					{props.user.user_type === "Reseller User" && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/profile"}
							exact
							render={(props) => (
								<ResellerPage
									{...props}
									mobile={mobile}
									component={ResellerProfile}
								/>
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Access Billing'])) && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/billing"}
							exact
							render={(props) => (
								<ResellerPage {...props} mobile={mobile} component={Billing} />
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Access Billing'])) && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/billing/:id/details"}
							exact
							render={(props) => (
								<ResellerPage
									{...props}
									mobile={mobile}
									component={BillingDetails}
								/>
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Packages'])) && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/packages"}
							exact
							render={(props) => (
								<ResellerPage {...props} mobile={mobile} component={Packages} />
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Tenants'])) && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/tenants"}
							exact
							render={(props) => (
								<ResellerPage {...props} mobile={mobile} component={Tenants} />
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Tenant Users'])) && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/tenant_users"}
							exact
							render={(props) => (
								<ResellerPage
									{...props}
									mobile={mobile}
									component={TenantUsers}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Reseller User" && (
						<Route
							path={process.env.PUBLIC_URL + "/reseller/dashboard"}
							exact
							render={(props) => (
								<ResellerPage
									{...props}
									mobile={mobile}
									component={ResellerDashboard}
								/>
							)}
						/>
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Reseller Users'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/reseller_users"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={ResellerUsers} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Reseller Roles'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/reseller_roles"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={ResellerRoleList} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Reseller Users'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/reseller_roles/:id"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={ResellerRolePermission} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Access Archives'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/archives"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={ResellerArchives} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Access Tenant Transfer'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/incomingTenants"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={IncomingTenants} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Access Tenant Transfer'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/outgoingTenants"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={OutgoingTenants} />
						)}
					  />
					)}
					{(props.user.user_type === "Reseller User" && hasPermission(props, ['Create/Modify Reseller Settings'])) && (
					  <Route
						path={process.env.PUBLIC_URL + "/reseller/settings"}
						exact
						render={(props) => (
						  <ResellerPage {...props} mobile={mobile} component={ResellerSettings} />
						)}
					  />
					)}
					{/*---------------reseller portal route end*/}
					{/*admin portal route begin---------------*/}
					<Route
						path={process.env.PUBLIC_URL + "/admin/login"}
						exact
						render={(props) => <AdminLogin {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/admin/twoFA"}
						exact
						render={(props) => <AdminTwoFA {...props} />}
					/>
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/notifications"}
							exact
							render={(props) => (
								<AdminPage
									{...props}
									mobile={mobile}
									component={Notifications}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/resellers"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={Resellers} />
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/reseller_users"}
							exact
							render={(props) => (
								<AdminPage
									{...props}
									mobile={mobile}
									component={AdminResellerUsers}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/packages"}
							exact
							render={(props) => (
								<AdminPage
									{...props}
									mobile={mobile}
									component={AdminPacakges}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/package_templates"}
							exact
							render={(props) => (
								<AdminPage
									{...props}
									mobile={mobile}
									component={PackageTemplates}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/dashboard"}
							exact
							render={(props) => (
								<AdminPage
									{...props}
									mobile={mobile}
									component={AdminDashboard}
								/>
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
					  <Route
						path={process.env.PUBLIC_URL + "/admin/billing"}
						exact
						render={(props) => (
						  <AdminPage {...props} mobile={mobile} component={AdminBilling} />
						)}
					  />
					)}
					{props.user.user_type === "Super User" && (
					  <Route
						path={process.env.PUBLIC_URL + "/admin/billing/:id/details"}
						exact
						render={(props) => (
						  <AdminPage {...props} mobile={mobile} component={AdminBillingDetails} />
						)}
					  />
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/permissions"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={Permissions} />
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/permission_categories"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={PermissionCategories} />
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/reseller_permissions"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={ResellerPermissions} />
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/reseller_permission_categories"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={ResellerPermissionCategories} />
							)}
						/>
					)}
					{props.user.user_type === "Super User" && (
						<Route
							path={process.env.PUBLIC_URL + "/admin/tenants"}
							exact
							render={(props) => (
								<AdminPage {...props} mobile={mobile} component={AdminTenants} />
							)}
						/>
					)}
					{/*---------------admin portal route end*/}
					<Route
						path={process.env.PUBLIC_URL + "/configuration/:series"}
						exact
						render={(props) => <TenantUserConfiguration {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/keyGen"}
						exact
						render={(props) => <KeyGen {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/login"}
						exact
						render={(props) => <Login {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/passwordlogin"}
						exact
						render={(props) => <PasswordLogin {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/twoFA"}
						exact
						render={(props) => <TwoFA {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/azuresso"}
						exact
						render={(props) => <AzureSSO {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/oneloginsso"}
						exact
						render={(props) => <OneLoginSSO {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/oneloginauth"}
						exact
						render={(props) => <OneLoginAuth {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/register"}
						exact
						render={(props) => <Register {...props} />}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/registered"}
						exact
						render={(props) => <Registered {...props} />}
					/>
					{props.user && props.user.authy_verified &&
					<Redirect
						exact
						from={process.env.PUBLIC_URL + "/"}
						to={process.env.PUBLIC_URL + "/dashboard"}
					/>}
					{!(props.user && props.user.authy_verified) &&
					<Redirect
						exact
						from={process.env.PUBLIC_URL + "/"}
						to={process.env.PUBLIC_URL + "/login"}
					/>}
					<Redirect
						exact
						from={process.env.PUBLIC_URL + "/portal"}
						to={process.env.PUBLIC_URL + "/portal/login"}
					/>
					<Redirect
						exact
						from={process.env.PUBLIC_URL + "/admin"}
						to={process.env.PUBLIC_URL + "/admin/login"}
					/>
					<Redirect
						exact
						from={process.env.PUBLIC_URL + "/reseller"}
						to={process.env.PUBLIC_URL + "/reseller/login"}
					/>
					<Route
						path={process.env.PUBLIC_URL + "/404"}
						exact
						render={(props) => <Page404 {...props} />}
					/>
					<Redirect from="*" to={process.env.PUBLIC_URL + "/404"} />
				</Switch>
			</BrowserRouter>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		colorScheme: getColorScheme(),
	};
};

export default connect(mapStateToProps, {})(App);
