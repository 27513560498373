import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MergeTypeIcon from '@material-ui/icons/MergeType';

const useStyles = makeStyles((theme) => ({
  chipAddNew: {
    float: "right",
    backgroundColor: theme.colorScheme,
    marginTop: "5px",
    marginRight: "-14px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  button: {
    margin: 10,
    color: theme.colorScheme,
    cursor:"pointer",
    float:"right"
  },
}));

const tableColumns = [
  {
    id: "time",
    label: "TIME",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "status",
    label: "STATUS",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "conflicts",
    label: "CONFLICTS",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "fixed_conflicts",
    label: "FIXED CONFLICTS",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "actions",
    label: "",
    minWidth: 80,
    sortable: true,
    colored: true,
  },
];

const Merges = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [error, setError] = useState();

  const url = `merges`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((m)=>{
          m["time"]=new Date(m["created_time"]).toLocaleDateString();
          m["actions"]=
            <>
            <VisibilityIcon
              className={classes.button} 
              onClick={()=>{
                props.history.push(process.env.PUBLIC_URL + `/settings/merges/${m.id}/details`);
              }}
            />
            {m.conflicts === m.fixed_conflicts && m.status === "Pending" &&
            <MergeTypeIcon
              className={classes.button} 
              onClick={()=>{
                m.status = "Approved";
                http.Put(
                  `merges/${m.id}`,
                  m,
                  (data) => {
                      fetchData();
                  },
                  (status, error) => {
                      console.log(error);
                  }
              );
              }}
            />}
            </>;
        });
        setData(data);
        setLoadData(false);
      },
      (status, error) => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={
            <div>
              Merges
              {!loadData && 
              <Chip
                label="ADD NEW"
                clickable
                className={classes.chipAddNew}
                color="primary"
                onClick={() => {
                  setLoadData(true);
                  http.Post(
                    url,
                    {"tenant":props.user.tenant,"status":"Pending"},
                    (data) => {
                      fetchData();
                    },
                    (status, error) => {
                      setError(error);
                      setLoadData(false);
                    }
                  );
                }}
              />}
            </div>}
          url={"merges"}
          actions={actions}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"status"}
          searchFields={["status"]}
          pageHeader={"Merges"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Merges"}
          url={"merges"}
          actions={actions}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"status"}
          searchFields={["status"]}
          pageHeader={"Merges"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

export default Merges;
