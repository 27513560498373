import { isSaturday, isWednesday } from "date-fns";

export const index = [{value:0,label: 'English'},{value:1,label: '简体中文'},{value:2,label: 'Français'}];

export const companyDetail = {
    Address : ['Address','地址','Adresse'],
    HoursOfOperation : ['Hours of operation','运营时间',"Heures d'ouverture"],
    Website : ['Website','网址',"Site web"],
    Contact : ['Contact','联系方式',"Contact"],
    Phone : ['Phone','电话',"Téléphone"],
    Notes: ['Notes','备注',"Notes"],
    Tags: ['Tags','标签',"Tags"],
    RECENTACTIVITY: ['RECENT ACTIVITY','最近活动',"ACTIVITÉ RÉCENTE"],
    Import: ['Import','导入',"Importer"],
    Export: ['Export','导出',"Exporter"],
    UPDATE: ['UPDATE','更新',"METTRE À JOUR"],
    BY: ['BY','执行人',"PAR"],
    DATE: ['DATE','时间',"DATE"],
    ViewAll: ['View All','展开',"Voir Tout"],
    AllSites: ['All Sites','所有站点',"Tous les Sites"],
    Home: ['Home','主页',"Accueil"]
};

export const leftBar = {
    Sites : ['Sites','站点',"Sites"],
    Documents : ['Documents','文档',"Documents"],
    Employees : ['Employees','员工',"Employés"],
    ServersDevices : ['Servers & Devices','设备',"Serveurs et Dispositifs"],
    SoftwareLicenses : ['Software Licenses','软件注册信息',"Licences Logicielles"],
    OtherServices : ['Other Services','其他服务',"Autres Services"],
    Domains : ['Domains','域名',"Domaines"],
    Archives : ['Archives','垃圾桶',"Archives"],
    ImportVault : ['Import Vault','导入库',"Coffre d'Importation"],
    Gallery : ['Gallery','相册',"Galerie"],
    PasswordVault : ['Password Vault','密码库',"Coffre de Mots de Passe"]
};

export const companySetting = {
    CompanyDetails : ['Company Details','公司细节',"Détails de l'Entreprise"],
    Billing : ['Billing','付款',"Facturation"],
    UsersControl : ['Users and Control','用户管理',"Utilisateurs et Contrôle"],
    Users : ['Users','用户',"Utilisateurs"],
    SecurityControl : ['Security Control','安全',"Contrôle de Sécurité"],
    Sharing : ['Sharing','分享',"Partage"],
    ToTenants : ['To Tenants','到承租人',"Vers les Locataires"],
    FromTenants : ['From Tenants','从承租人',"Des Locataires"],
    SharedCompanies : ['Shared Companies','分享公司',"Entreprises Partagées"],
    Customization : ['Customization','客制化',"Personnalisation"],
    Categories : ['Categories','类别',"Catégories"],
    Templates : ['Templates','模板',"Modèles"],
    DeveloperSpace : ['Developer Space','开发者空间',"Espace Développeur"],
    Extensions : ['Extensions','扩展',"Extensions"],
    CustomizedData : ['Customized Data','客制化数据',"Données Personnalisées"],
    DataAdministration : ['Data Administration','数据管理',"Administration des Données"],
    PortalManagement : ['Portal Management','门户管理',"Gestion du Portail"],
    Archives : ['Archives','垃圾桶',"Archives"],
    General : ['General','常规',"Général"],
    Addresses : ['Addresses','地址',"Adresses"],
    Invoices : ['Invoices','收据',"Factures"],
    Subscription : ['Subscription','订阅',"Abonnement"]
};

export const topNav = {
    Companies : ['Companies','全部公司',"Entreprises"],
    Resources : ['Resources','资源',"Ressources"],
    Services : ['Services','服务',"Services"],
    Vendors : ['Vendors','经销商',"Fournisseurs"],
    Meetings : ['Meetings','会议',"Réunions"],
    Dashboard : ['Dashboard','总览',"Tableau de Bord"],
    Employees : ['Employees','员工',"Employés"],
    JobPostings : ['Job Postings','工作机会',"Annonces d'Emploi"],
    Reports : ['Reports','报告',"Rapports"],
    Settings : ['Settings','设置',"Paramètres"],
    General : ['General','通用',"Général"],
    VacationProfiles : ['Vacation Profiles','休假信息',"Profils de Congés"],
    JobTemplates : ['Job Templates','工作模板',"Modèles d'Emploi"],
    Accounting : ['Accounting','会计',"Comptabilité"]
};

export const topNav1 = {
    COMPANIES : ['COMPANIES','全部公司',"ENTREPRISES"],
    RESOURCES : ['RESOURCES','资源',"RESSOURCES"],
    SERVICES : ['SERVICES','服务',"SERVICES"],
    VENDORS : ['VENDORS','经销商',"FOURNISSEURS"],
    MEETINGS : ['MEETINGS','会议',"RÉUNIONS"],
    ACCOUNTING : ['ACCOUNTING','会计',"COMPTABILITÉ"],
    HR : ['HR','人力资源',"RH"]
};

export const ShortCut = {
    title : ['Shortcuts: ( press and hold "Ctrl" + "Alt" )','捷径: ( 长摁 "Ctrl" + "Alt" )',"Raccourcis : (maintenez enfoncées 'Ctrl' + 'Alt')"],
    GlobalSearch : ['Global Search','全局搜索',"Recherche Globale"],
    RecentlyViewedCompanies : ['Recently Viewed Companies','最近访问过的公司',"Entreprises Consultées Récemment"],
    Companies : ['Companies','全部公司',"Entreprises"],
    TenantResources : ['Tenant Resources','承租人资源',"Ressources des Locataires"],
    TenantServices : ['Tenant Services','承租人服务',"Services des Locataires"],
    TenantVendors : ['Tenant Vendors','承租人经销商',"Fournisseurs des Locataires"],
    TenantMeetings : ['Tenant Meetings','承租人会议',"Réunions des Locataires"],
    TenantAccounting : ['Tenant Accounting','承租人会计',"Comptabilité des Locataires"],
    TenantHR : ['Tenant HR','承租人人力资源',"RH des Locataires"],
    CompanyDetails : ['Company Details','公司细节',"Détails de l'Entreprise"],
    CompanySites : ['Company Sites','公司站点',"Sites de l'Entreprise"],
    CompanyDocuments : ['Company Documents','公司文档',"Documents de l'Entreprise"],
    CompanyEmployees : ['Company Employees','公司职员',"Employés de l'Entreprise"],
    CompanyServersDevices : ['Company Servers & Devices','公司服务和设备',"Serveurs et Dispositifs de l'Entreprise"],
    CompanySoftwareLicenses : ['Company Software Licenses','公司软件注册信息',"Licences Logicielles de l'Entreprise"],
    CompanyOtherServices : ['Company Other Services','公司其他服务',"Autres Services de l'Entreprise"],
    CompanyDomains : ['Company Domains','公司域名',"Domaines de l'Entreprise"],
    CompanyArchives : ['Company Archives','公司垃圾桶',"Archives de l'Entreprise"],
}

export const footer = {
    powered : ['POWERED BY SYNCMONKEY','由SYNCMONKEY提供技术支持',"ALIMENTÉ PAR SYNCMONKEY"]
}

export const userMenu = {
    MyProfile : ['My Profile','我的信息',"Mon Profil"],
    MyVault : ['My Vault','我的库',"Mon Coffre"],
    KnowledgeBase : ['Knowledge Base','知识库',"Base de Connaissances"],
    CompanySettings : ['Company Settings','公司设定',"Paramètres de l'Entreprise"],
    LogOut : ['Log Out','登出',"Déconnexion"],
    Hi : ['Hi ','你好 ',"Bonjour "]
}

const dayList = {
    Monday : ['Monday','星期一',"Lundi"],
    Tuesday : ['Tuesday','星期二',"Mardi"],
    Wednesday : ['Wednesday','星期三',"Mercredi"],
    Thursday : ['Thursday','星期四',"Jeudi"],
    Friday : ['Friday','星期五',"Vendredi"],
    Saturday : ['Saturday','星期六',"Samedi"],
    Sunday : ['Sunday','星期日',"Dimanche"]
}
const monthList = {
    January : ['January','一月',"Janvier"],
    February : ['February','二月',"Février"],
    March : ['March','三月',"Mars"],
    April : ['April','四月',"Avril"],
    May : ['May','五月',"Mai"],
    June : ['June','六月',"Juin"],
    July : ['July','七月',"Juillet"],
    August : ['August','八月',"Août"],
    September : ['September','九月',"Septembre"],
    October : ['October','十月',"Octobre"],
    November : ['November','十一月',"Novembre"],
    December : ['December','十二月',"Décembre"],
}

export function Week(eng, index) {
    if(eng === 'Monday'){
        return dayList.Monday[index]
    }else if(eng === 'Tuesday'){
        return dayList.Tuesday[index]
    }else if(eng === 'Wednesday'){
        return dayList.Wednesday[index]
    }else if(eng === 'Thursday'){
        return dayList.Thursday[index]
    }else if(eng === 'Friday'){
        return dayList.Friday[index]
    }else if(eng === 'Saturday'){
        return dayList.Saturday[index]
    }else if(eng === 'Sunday'){
        return dayList.Sunday[index]
    }
}

export function Month(engInput, index) {
    var eng = engInput.split(' ')[0]
    if(eng === 'Jan'){
        return monthList.January[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Feb'){
        return monthList.February[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Mar'){
        return monthList.March[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Apr'){
        return monthList.April[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'May'){
        return monthList.May[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Jun'){
        return monthList.June[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Jul'){
        return monthList.July[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Aug'){
        return monthList.August[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Sep'){
        return monthList.September[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Oct'){
        return monthList.October[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Nov'){
        return monthList.November[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }else if(eng === 'Dec'){
        return monthList.December[index] + ' ' + engInput.split(' ')[1] + ' ' + engInput.split(' ')[2]
    }
}

export const companyList = {
    COMPANYNAME : ['COMPANY NAME','公司名称',"NOM DE L'ENTREPRISE"],
    PHONE : ['PHONE','电话',"TÉLÉPHONE"],
    INDUSTRYTYPE : ['INDUSTRY TYPE','领域',"TYPE D'INDUSTRIE"],
    WEBSITE : ['WEBSITE','网址',"SITE WEB"],
    STATUS : ['STATUS','状态',"STATUT"],
    SYNC : ['SYNC','同步',"SYNC"],
    SHARE : ['SHARE ','分享',"PARTAGER"],
    Readonly : ['Readonly','只读用户',"Lecture seule"],
    Yes : ['Yes','是','Oui'],
    No : ['No','不','Non'],
    Companies : ['Companies','全部公司',"Entreprises"]
}

export const form = {
    Search : ['Search ','搜索',"Recherche"],
    ADDNEW : ['ADD NEW','创建',"AJOUTER NOUVEAU"],
    DeleteSelectedRows : ['Delete Selected Rows','删除所选条目',"Supprimer les Lignes Sélectionnées"],
    CreateSecret : ['Create One-Time Secret','创建一次性密钥',"Créer un Secret Unique"],
    Edit : ['Edit','编辑',"Modifier"],
    DownloadImportFormat : ['Download Import Format','下载上传范本',"Télécharger le Format d'Importation"],
    ImportfromCSV : ['Import from CSV','从CSV上传',"Importer depuis CSV"],
    ExportAlltoCSV : ['Export All to CSV','下载全部数据CSV格式',"Exporter Tout en CSV"],
    ExportSelectedtoCSV : ['Export Selected to CSV','下载选择的数据CSV格式',"Exporter Sélectionnés en CSV"],
    ExportSelectedtoPDF : ['Export Selected to PDF','下载选择的数据pdf格式',"Exporter Sélectionnés en PDF"],
    ExportAlltoPDF : ['Export All to PDF','下载全部数据pdf格式',"Exporter Tout en PDF"],
    Thislistisempty : ['This list is empty.','空',"Cette liste est vide."],
    help1 : ["Please finish inputting, or clear the form!!",'请完成输入 或清空输入项',"Veuillez terminer la saisie, ou vider le formulaire!!"],
    help2 : ["warning",'警告',"avertissement"],
    Sort : ['Sort','排序',"Trier"]
}

export const topNavHelpTool = {
    Search : ['Search','搜索',"Recherche"],
    Recently : ['Recently viewed companies','最近浏览',"Entreprises Consultées Récemment"],
    ShowNotifications : ['Show notifications','展示通知',"Afficher les Notifications"]
}

export const PersonalSettings = {
    Settings : ['Personal Settings','个人设置',"Paramètres Personnels"],
    MyProfile : ['My Profile','个人资料',"Mon Profil"],
    USERSETTINGS : ['USER SETTINGS','用户设置',"PARAMÈTRES UTILISATEUR"],
    ProfileImage : ['Profile Image','个人图片',"Image de Profil"],
    Upload : ['Upload your image','上传图片',"Télécharger votre image"],
    Email : ['Email','电子邮件',"Email"],
    Password : ['Password','密码',"Mot de passe"],
    ColorScheme : ['Color Scheme','主题颜色',"Schéma de Couleur"],
    Language : ['Language','语言',"Langue"],
    ext : ['ext','国家码',"poste"],
    Phone : ['Phone','电话',"Téléphone"],
    Mobile : ['Mobile','手机',"Mobile"],
    Languages : ['Languages','语言',"Langues"],
    Theme : ['Theme','主题',"Thème"],
    CHANGEPASSWORD : ['CHANGE PASSWORD','更改密码',"CHANGER LE MOT DE PASSE"],
    CurrentPassword : ['Current Password','当前密码',"Mot de Passe Actuel"],
    NewPassword : ['New Password','新密码',"Nouveau Mot de Passe"],
    RetypePassword: ['Retype Password','再次输入密码',"Retaper le Mot de Passe"],
    Save : ['Save','保存',"Sauvegarder"],
    Dark : ['Dark','夜间模式',"Sombre"],
    message1 : ['The following settings will be updated','以下设置会被更改','Les paramètres suivants seront mis à jour'],
    message2 : ['You will now be logged out to apply the changes','为了更改设置你会被登出','Vous allez maintenant être déconnecté pour appliquer les modifications'],
    message3 : ['No changes detected','没有发现任何修改','Aucun changement détecté'],
    Confirmed : ['Confirmed','保存',"Confirmé"],
    Close : ['Close','取消',"Fermer"],
    Light : ['Light','白天模式',"Clair"]
}

export const myVault = {
    Vault: ['Vault','库',"Coffre"],
    NAME: ['NAME','名称',"NOM"],
    USAGE: ['USAGE','使用次数',"UTILISATION"],
    EXPIREAT: ['EXPIRE AT','过期时间',"EXPIRATION À"],
    Link: ['Link','链接',"Lien"],
    Move: ['Move','转移',"Déplacer"]
}

// Company setting page
export const settingDetail = {
    Id : ['ID','识别号',"ID"],
    CompanyDetails : ['Company Details','公司详细设定',"Détails de l'Entreprise"],
    Logo : ['Logo','公司标识',"Logo"],
    Uploadlogo : ['Upload logo','上传标识',"Télécharger le logo"],
    MobileLogo : ['Mobile Logo','移动端标识',"Logo Mobile"],
    Uploadmobilelogo : ['Upload mobile logo','上传移动端标识',"Télécharger le logo mobile"],
    OrganizationName: ['Organization Name*','公司名称',"Nom de l'Organisation*"],
    BusinessMainLocation: ['Business Main Location*','公司位置',"Lieu Principal de l'Entreprise*"],
    CompanyAddress: ['RECENT ACTIVITY','公司地址',"ACTIVITÉ RÉCENTE"],
    Theme: ['Default Theme','默认主题',"Thème Par Défaut"],
    ColorScheme: ['Default Color Scheme','默认颜色',"Schéma de Couleur Par Défaut"],
    DateFormat: ['Date Format','日历格式',"Format de Date"],
    DateTimeFormat: ['Date Time Format','时间格式',"Format Date Heure"],
    NotificationDays: ['Notification Days','推送时间',"Jours de Notification"],
    Languages: ['Default Languages','默认语言',"Langues Par Défaut"],
    Save: ['Save','保存',"Sauvegarder"],
    Dark : ['Dark','夜间模式',"Sombre"],
    Light : ['Light','白天模式',"Clair"],
    Orange : ['Orange','橙色',"Orange"],
    Blue : ['Blue','蓝色',"Bleu"],
    UploadHelper1 : ['This logo will appear on the documents (estimates,invoices, etc) that are created.','这个标识也会同时被上传到文档界面',"Ce logo apparaîtra sur les documents (devis, factures, etc.) qui sont créés."],
    UploadHelper2 : ['Preferred Image Size: 240px x 240px @72 DPI Maximum size of 1MB.','图片尺寸: 240px x 240px @72 DPI 大小最大 1MB',"Taille d'Image Préférée : 240px x 240px @72 DPI Taille maximale de 1 Mo."]
};

// tenant Invoices
export const invoices = {
    CURRENTBALANCE : ['CURRENT BALANCE','当前余额',"SOLDE ACTUEL"],
    CARDINFO : ['CARD INFO','银行卡信息',"INFORMATIONS SUR LA CARTE"], 
    Exp : ['Exp: ','过期日期',"Exp :"],
    SUBSCRIPTION : ['SUBSCRIPTION','订阅信息',"ABONNEMENT"],
    INVOICEID : ['INVOICE ID','发票识别号',"ID DE FACTURE"],
    ITEMS : ['ITEMS','项目',"ARTICLES"],
    SUBTOTAL : ['SUB TOTAL','小计',"SOUS TOTAL"],
    DISCOUNT : ['DISCOUNT(%)','减免',"REMBOURSEMENT (%)"],
    TOTAL : ['TOTAL','总计',"TOTAL"],
    PERIOD : ['PERIOD','周期',"PÉRIODE"],
    users : ['users','用户',"utilisateurs"],
    readonly : ['readonly','只读用户',"lecture seule"],
    portals : ['portals','门户用户',"portails"]
}

// tenant subscription
export const sub = {
    Billing : ['Billing','账单',"Facturation"],
    PLAN : ['PLAN','方案',"PLAN"],
    Subscription : ['Subscription','订阅',"Abonnement"],
    Monthly : ['Monthly','月供',"Mensuel"],
    Annual : ['Annual','年供',"Annuel"],
    helper : ['Change subscription to monthly will be available 2 weeks before renewal date.','更改订阅为每月付款将在续订日期前两周变为可更改',"Changer l'abonnement en mensuel sera disponible 2 semaines avant la date de renouvellement."],
    UPDATEPLAN : ['UPDATE PLAN','升级方案',"MISE À JOUR DU PLAN"],
    Users : ['Users','用户',"Utilisateurs"],
    Readonly : ['Readonly','只读用户',"Lecture seule"],
    AddPortals : ['Add Portals','门户用户',"Ajouter des Portails"],
    SUBSCRIPTIONDETAILS : ['SUBSCRIPTION DETAILS','订阅细节',"DÉTAILS DE L'ABONNEMENT"],
    Renewal : ['Renewal on ','更新在',"Renouvellement le"],
    ITEM : ['ITEM','项目',"ARTICLE"],
    No : ['NO. OF UNITS','数量',"NOMBRE D'UNITÉS"],
    UNITPRICE : ['UNIT PRICE','单价',"PRIX UNITAIRE"],
    TOTALTime : ['TOTAL / ','总计',"TOTAL /"],
    NETTOTAL : ['NET TOTAL','净总计',"TOTAL NET"],
    DISCOUNT : ['DISCOUNT','减免',"REMBOURSEMENT"],
    TOTAL : ['TOTAL','总计',"TOTAL"],
    PAYMENTMETHOD: ['PAYMENT METHOD','付款方式',"MÉTHODE DE PAIEMENT"],
    EXP : ['EXP (YYMM) *','过期日期',"EXP (AA/MM) *"],
    UPDATECARD : ['UPDATE CARD','更新银行卡信息',"MISE À JOUR DE LA CARTE"]
}

// tenant user management
export const userManagement = {
    TenantUsers : ['Tenant Users','承租用户',"Utilisateurs des Locataires"],
    NAME : ['NAME','姓名',"NOM"],
    ROLES : ['ROLES','权限',"ROLES"],
    EMAIL : ['EMAIL','电子邮件',"EMAIL"],
    LASTLOGIN : ['LAST LOGIN','上次登录',"DERNIÈRE CONNEXION"],
    TenantAdmin : ['Tenant Admin','承租管理员',"Administrateur Locataire"],
    Readonly : ['Readonly','只读用户',"Lecture seule"],
    NoAccess : ['No Access','没有权限',"Pas d'Accès"]

}

export const userManagementForm = {
    TenantUsers : ['Tenant Users','承租用户',"Utilisateurs des Locataires"],
    FIRSTNAME : ['FIRST NAME *','名',"PRÉNOM *"],
    LASTNAME : ['LAST NAME *','姓',"NOM DE FAMILLE *"],
    EMAIL : ['EMAIL *','电子邮件',"EMAIL *"],
    PROFILEIMAGE : ['PROFILE IMAGE','头像',"IMAGE DE PROFIL"],
    DEPARTMENT : ['DEPARTMENT','部门',"DÉPARTEMENT"],
    PHONE : ['PHONE','电话',"TÉLÉPHONE"],
    EXT : ['EXT','区号',"POSTE"],
    MOBILE : ['MOBILE','手机电话',"MOBILE"],
    COUNTRYCODE : ['COUNTRY CODE','国家地区代码',"CODE DU PAYS"],
    ROLES : ['ROLES','权限',"ROLES"],
    LICENSETYPE : ['LICENSE TYPE','执照种类',"TYPE DE LICENCE"],
    ISTENANTADMIN : ['IS TENANT ADMIN','是否是管理员',"EST-IL ADMINISTRATEUR LOCATAIRE"],
    HASACCESS : ['HAS ACCESS','是否有查看权限',"A UN ACCÈS"],
    REQUIRE : ['REQUIRE USER TO RESET PASSWORD ON NEXT LOGIN','是否要求用户在下次登录重置密码',"EXIGER QUE L'UTILISATEUR RÉINITIALISE SON MOT DE PASSE AU PROCHAIN CONNEXION"],
    WELCOMEEMAIL : ['SEND WELCOME EMAIL','发送欢迎邮件',"ENVOYER UN EMAIL DE BIENVENUE"],
    CONTACTLIST : ['HIDE USER FROM OFFICE CONTACT LIST','不让这个用户看到公司的联络本',"MASQUER L'UTILISATEUR DE LA LISTE DE CONTACTS DU BUREAU"],
    HIDECONTACTLIST : ['HIDE MOBILE NUMBER FROM OFFICE CONTACT LIST','不让这个用户手机出现在公司的联络本',"MASQUER LE NUMÉRO MOBILE DE LA LISTE DE CONTACTS DU BUREAU"],
    NUMBEROFRESULT : ['NUMBER OF RESULT PER PAGE *','每页展现的结果数量 *',"NOMBRE DE RÉSULTATS PAR PAGE *"],
}

export const objectForm = {
    save : ['Save','保存',"Sauvegarder"],
    cancel : ['Cancel','取消',"Annuler"],
    AddNew : ['Add New ','新',"Ajouter Nouveau"],
    Edit : ['Edit ','编辑',"Modifier"]
}

export const userRoleManagement = {
    SecurityControl : ['Security Control','安全管理',"Contrôle de Sécurité"],
    ROLENAME : ['ROLE NAME','权限名称',"NOM DU RÔLE"],
    ROLEDESCRIPTION : ['ROLE DESCRIPTION ','权限简介',"DESCRIPTION DU RÔLE"],
    STATUS : ['STATUS ','状态',"STATUT"],
    CREATEDBY : ['CREATED BY ','创建人',"CRÉÉ PAR"]
}

// to tenants

export const ToTenant = {
    Tenants : ['Tenants','承包人',"Locataires"],
    TOTENANT : ['TO TENANT','到另一个承包人',"VERS LE LOCATAIRE"],
    ACCEPTED : ['ACCEPTED','接受',"ACCEPTÉ"],
    Sharing : ['Sharing','分享',"Partage"]
}

// From tenants
export const FromTenant = {
    Tenants : ['Tenants','承包人',"Locataires"],
    From : ['From Tenant','从另一个承包人',"Depuis le Locataire"]
}

// Sharing companies

export const SharingTenant = {
    COMPANYNAME : ['COMPANY NAME','公司名',"NOM DE L'ENTREPRISE"],
    FROMTENANT : ['FORM TENANT','从另一个承包人',"DU LOCATAIRE"],
    PHONE : ['PHONE','电话',"TÉLÉPHONE"],
    WEBSITE : ['WEBSITE','网站',"SITE WEB"],
    INDUSTRYTYPE : ['INDUSTRY TYPE','领域',"TYPE D'INDUSTRIE"],
    STATUS : ['STATUS','状态',"STATUT"],
    title : ['Companies From Other Tenants','来自另一个承包人的公司',"Entreprises des Autres Locataires"]
}

// Role Form

export const roleForm = {
    AddNewRole : ["Add New Role",'新建权限',"Ajouter Nouveau Rôle"],
    EditRole : ["Edit Role",'编辑权限',"Modifier Rôle"],
    Clone : ["Clone Role: ",'克隆权限',"Cloner Rôle :"],
    ROLENAME : ['ROLE NAME *','权限名称',"NOM DU RÔLE *"],
    ROLEDESCRIPTION : ['ROLE DESCRIPTION','权限描述',"DESCRIPTION DU RÔLE"],
    STATUS : ['STATUS','状态',"STATUT"],
    CLONEROLE : ['CLONE ROLE','克隆权限',"CLONER LE RÔLE"],
    Active : ['Active','活跃',"Actif"],
    Disabled : ['Disabled','禁用',"Désactivé"],
    Duplicate : ['Duplicate this role','克隆这个权限',"Dupliquer ce rôle"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    None : ['None','未选择',"Aucun"],
    NoRoles : ['No roles found','没有已存的权限',"Aucun rôle trouvé"]
}

// Role details

export const roleDetail = {
    UsersControl : ['Users and Control','用户管理',"Utilisateurs et Contrôle"],
    COMPANIESACCESS : ["COMPANIES ACCESS",'公司访问权限',"ACCÈS AUX ENTREPRISES"],
    AllowAllCompanies : ["Allow All Companies",'允许所有公司',"Autoriser Toutes les Entreprises"],
    SearchCompanies : ['Search Companies','搜索公司',"Rechercher des Entreprises"]
}

// categories

export const categories = {
    Category : ['Category','类别',"Catégorie"],
    Categories : ['Categories','类别',"Catégories"],
    All : ["All",'全部',"Tout"],
    AccountType : ["Account Type",'账户种类',"Type de Compte"],
    CredentialType : ["Credential Type",'凭证类型',"Type de Credential"],
    DeviceType : ["Device Type",'设备种类',"Type de Dispositif"],
    DocumentCategory : ["Document Category",'文档类别',"Catégorie de Document"],
    EmployeeType : ["Employee Type",'员工类别',"Type d'Employé"],
    IndustryType : ["Industry Type",'领域种类',"Type d'Industrie"],
    LicenseType : ["License Type",'许可证类型',"Type de Licence"],
    ServiceType : ["Service Type",'服务类型',"Type de Service"],
    TagType : ["Tag Type",'标签类型',"Type de Tag"],
    CATEGORYNAME : ["CATEGORY NAME",'类别名称',"NOM DE LA CATÉGORIE"],
    CATEGORYNAME1 : ["CATEGORY NAME *",'类别名称*',"NOM DE LA CATÉGORIE *"],
    CATEGORYTYPE1 : ["CATEGORY TYPE *",'类别类型*',"TYPE DE CATÉGORIE *"],
    ORDER : ['ORDER','顺序',"ORDRE"],
    ISDEFAULT : ['IS DEFAULT','是否是默认',"EST PAR DÉFAUT"],
    CWCONFIGURATIONTYPE : ['CW CONFIGURATION TYPE','CW 配置类型',"TYPE DE CONFIGURATION CW"],
    CWUSERNAME : ['CW USERNAME','CW 用户名',"NOM D'UTILISATEUR CW"],
    CWPASSWORD : ['CW PASSWORD','cw 密码',"MOT DE PASSE CW"],
    AUTOTASKCONFIGURATION : ['AUTOTASK CONFIGURATION ITEM TYPE','AUTOTASK设置',"TYPE D'ARTICLE DE CONFIGURATION AUTOTASK"],
    CATEGORYTYPE : ["CATEGORY TYPE",'类别类型',"TYPE DE CATÉGORIE"]
}

// tenant template

export const templateLang = {
    Templates : ['Templates','模板',"Modèles"],
    TITLE : ['TITLE','名称',"TITRE"],
    TYPENAME : ["TYPE NAME",'类别名称',"NOM DU TYPE"],
    BY : ["BY",'执行人',"PAR"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    EditTemplate : ["Edit Template",'编辑模板',"Modifier le Modèle"],
    AddNewTemplate : ["Add New Template","新建模板","Ajouter Nouveau Modèle"],
    TITLE1 : ["TITLE *",'名称 *',"TITRE *"],
    TEMPLATE : ["TEMPLATE",'模板',"MODÈLE"],
    TEMPLATETYPE : ["TEMPLATE TYPE *",'模板类别 *',"TYPE DE MODÈLE *"],
    HR : ["HR",'人力资源',"RH"],
    Document : ["Document",'文档',"Document"],
    Meeting : ["Meeting",'会面',"Réunion"],
    IncidentReporting : ["Incident Reporting",'事故报告',"Rapport d'Incident"],
}

// extension
export const extension = {
    unintalled1 : ["Are you sure you want to uninstall?",'模板',"Êtes-vous sûr de vouloir désinstaller ?"],
    unintalled2 : ["Uninstalling will delete data parmanently.",'名称',"La désinstallation supprimera les données de façon permanente."],
    CompanySettings : ["Company Settings",'公司设置',"Paramètres de l'Entreprise"],
    Extensions : ["Extensions",'扩展',"Extensions"],
    FEATURED : ['FEATURED','支持',"EN VEDETTE"],
    INSTALLED : ['INSTALLED','已安装',"INSTALLÉES"],
    Noextensions : ["No extensions installed.",'没有任何已安装的扩展程序',"Aucune extension installée."],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    Nooptionsfound : ['No options found','没有选项',"Aucune option trouvée"],
    Installed : ['Installed','已安装',"Installé"],
    Install : ['Install','安装',"Installer"],
    AnotherProvider : ['Another provider already installed','另一个服务商已被安装',"Un autre fournisseur est déjà installé"],
    Modify : ['Modify','更改',"Modifier"],
    Uninstall : ['Uninstall','卸载',"Désinstaller"]
}

//customized
export const customizedJson = {
    CustomizedDataModels : ["Customized Data Models",'客制化数据模型',"Modèles de Données Personnalisés"],
    MODELNAME : ["MODEL NAME",'模型名称',"NOM DU MODÈLE"],
    ORDER : ["ORDER",'顺序',"ORDRE"],
    MODELNAME1 : ["MODEL NAME *",'模型名称*',"NOM DU MODÈLE *"],
    JSONSCHEMA : ['JSON SCHEMA','JSON数据结构',"SCHÉMA JSON"],
    ICON : ['ICON','图标',"ICÔNE"],
    AddNewField : ["Press Enter To Add New Field",'没有任何已安装的扩展程序',"Appuyez sur Entrée pour Ajouter un Nouveau Champ"],
    Required : ['Required','必填',"Requis"],
    Listed : ['Listed','列入',"Répertorié"],
    Searchable : ['Searchable','可搜索',"Recherchable"],
    Copyable : ['Copyable','可复制',"Copiable"],
    Name : ['Name','名称',"Nom"],
    text : ['text','文字',"texte"],
    number : ['number','数字',"numéro"],
    textarea : ['textarea','文字区域',"textarea"],
    password : ['password','密码',"mot de passe"],
    email : ['email','电子邮件地址',"email"],
    url : ['url','网址',"url"],
    boolean : ['boolean','布尔类型',"boolean"],
    Category : ['Category','类别',"Catégorie"],
    AccountType : ["Account Type",'账户种类',"Type de Compte"],
    CredentialType : ["Credential Type",'凭证类型',"Type de Credential"],
    DeviceType : ["Device Type",'设备种类',"Type de Dispositif"],
    DocumentCategory : ["Document Category",'文档类别',"Catégorie de Document"],
    EmployeeType : ["Employee Type",'员工类别',"Type d'Employé"],
    IndustryType : ["Industry Type",'领域种类',"Type d'Industrie"],
    ServiceType : ["Service Type",'服务类型',"Type de Service"]
}


// portal user management
export const portalUser = {
    ROLENAME : ["ROLE NAME",'权限名称',"NOM DU RÔLE"],
    ROLEDESCRIPTION : ["ROLE DESCRIPTION",'权限描述',"DESCRIPTION DU RÔLE"],
    STATUS : ["STATUS",'状态',"STATUT"],
    USERCOUNT : ["USER COUNT",'用户数量',"NOMBRE D'UTILISATEURS"],
    CREATEDBY : ['CREATED BY','创建者',"CRÉÉ PAR"],
    PortalSecurityControl : ['Portal Security Control','门户安全管理',"Contrôle de Sécurité du Portail"],
    ROLENAME1 : ["ROLE NAME *",'权限名称*',"NOM DU RÔLE *"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    ClonePortalRole : ['Clone Portal Role: ','克隆这个权限: ',"Cloner le Rôle du Portail :"],
    EditPortalRole : ['Edit Portal Role','编辑这个权限',"Modifier le Rôle du Portail"],
    AddNewPortalRole : ['Add New Portal Role','创建一个新权限',"Ajouter Nouveau Rôle de Portail"],
    SITES : ['SITES','站点',"SITES"],
    DOCUMENTS : ['DOCUMENTS','文档',"DOCUMENTS"],
    EMPLOYEES : ['EMPLOYEES','员工',"EMPLOYÉS"],
    SERVERSDEVICES : ['SERVERS & DEVICES','设备',"SERVEURS ET DISPOSITIFS"],
    SOFTWARELICENSES : ['SOFTWARELICENSES','软件注册信息',"LICENCES LOGICIELLES"],
    OTHERSERVICES : ['OTHER SERVICES','其他服务',"AUTRES SERVICES"],
    CUSTOMTABS : ['CUSTOM TABS','自定义数据',"TABS PERSONNALISÉS"],
    Duplicatethisrole : ['Duplicate this role','复制这个权限',"Dupliquer ce rôle"],
    CLONEROLE : ['CLONE ROLE','克隆权限',"CLONER LE RÔLE"],
    None : ['None','未选择',"Aucun"],
    Active : ['Active','活跃',"Actif"],
    Disabled : ['Disabled','禁用',"Désactivé"],
    Norolesfound : ['No roles found','没有权限',"Aucun rôle trouvé"]
}

// portal user
export const pUser = {
    NAME : ["NAME",'名字',"NOM"],
    COMPANY : ["COMPANY",'公司',"ENTREPRISE"],
    ROLE : ["ROLE",'权限',"RÔLE"],
    BILLINGCONTACT : ["BILLING CONTACT",'付款人联系方式',"CONTACT FACTURATION"],
    LASTLOGIN : ['LAST LOGIN','最后一次登录',"DERNIÈRE CONNEXION"],
    NEVERLOGGEDIN : ['NEVER LOGGED IN','从来没有登陆过',"JAMAIS CONNEXION"],
    ROLENAME1 : ["ROLE NAME *",'权限名称*',"NOM DU RÔLE *"],
    PortalUsers : ['Portal Users','门户用户',"Utilisateurs du Portail"],
    ResendInvite : ['Resend Invite"','重新发送邀请',"Renvoyer l'Invitation"],
    EMPLOYEENAME : ["EMPLOYEE NAME",'员工姓名',"NOM DE L'EMPLOYÉ"],
    EMAIL : ["EMAIL",'电子邮件',"EMAIL"],
    MOBILE : ["MOBILE",'手机号',"MOBILE"],
    COUNTRYCODE : ["COUNTRY CODE",'国家号',"CODE DU PAYS"],
    HIDDEN : ["HIDDEN",'隐藏',"MASQUÉ"],
    PORTALROLE : ["PORTAL ROLE",'门户权限',"RÔLE DU PORTAIL"],
    PortalUser : ["Portal User",'门户用户',"Utilisateur du Portail"]
}

// tenant admin archive
export const tenantArchive = {
    Archives : ["Archives",'垃圾桶',"Archives"],
    MODEL : ["MODEL",'属于',"MODÈLE"],
    NAME : ["NAME",'名字',"NOM"],
    LASTMODIFIEDBY : ["LAST MODIFIED BY",'更改人',"DERNIÈRE MODIFICATION PAR"],
    LASTMODIFIEDAT : ['LAST MODIFIED AT','更改时间',"DERNIÈRE MODIFICATION À"],
    Deletepermanently : ['Delete permanently?','永久删除？',"Supprimer définitivement ?"],
    Remove : ["Remove",'移除',"Retirer"],
    Restore : ['Restore','恢复',"Restaurer"]
}

// tenant user dashboard
export const dashboard = {
    NOTIFICATIONS : ["NOTIFICATIONS",'通知',"NOTIFICATIONS"],
    Errors : ["Errors",'错误',"Erreurs"],
    Warnings : ["Warnings",'警告',"Avertissements"],
    EagleEye : ["Eagle Eye",'鹰眼',"Œil d'Aigle"],
    Birthdays : ['Birthdays','生日',"Anniversaires"],
    Events : ['Events','活动',"Événements"],
    DOCUMENTUNLOCKREQUESTS : ["DOCUMENT UNLOCK REQUESTS",'解除文档限制请求',"DEMANDES DE DÉVERROUILLAGE DE DOCUMENTS"],
    SHAREDLINKS : ['SHARED LINKS','分享链接',"LIENS PARTAGÉS"],
    delete : ['Do you want to delete?','是否删除',"Voulez-vous supprimer ?"],
    confirm : ['If you agree, data will be deleted permanently','如果确认, 数据将会被永久删除',"Si vous êtes d'accord, les données seront supprimées définitivement"],
    Add : ['Add','添加',"Ajouter"],
    Edit : ['Edit','編輯',"Modifier"],
    Delete : ['Delete','刪除',"Supprimer"],
    TIMEOFFAVAILABILITY : ['TIME OFF AVAILABILITY','所剩的休息天數',"DISPONIBILITÉ DE CONGÉS"],
    VACATIONDAYS : ['VACATION DAYS','假期',"JOURS DE CONGÉS"],
    SICKDAYS : ['SICK DAYS','病假',"JOURS MALADIE"],
    PERSONALDAYS : ['PERSONAL DAYS','事假',"JOURS PERSONNELS"],
    REQUESTFORM : ['REQUEST FORM','填表申請',"FORMULAIRE DE DEMANDE"],
    HISTORY : ['HISTORY','历史',"HISTORIQUE"],
    OFFICECONTACTS : ['OFFICE CONTACTS','办公室联系人',"CONTACTS BUREAU"],
    NAME : ['NAME','姓名',"NOM"],
    EXT : ['EXT','区号',"POSTE"],
    EMAIL : ['EMAIL','电子邮件',"EMAIL"],
    MOBILE : ['MOBILE','手机',"MOBILE"],
    OFFICES : ['OFFICES','办公地点',"BUREAUX"],
}

// tenant user Resources
export const Resources = {
    Resources : ["Resources ",'资源',"Ressources"],
    AllCategories : ["All Categories",'全部类别',"Toutes les Catégories"],
    TITLE : ["TITLE",'标题',"TITRE"],
    LASTMODIFIED : ["LAST MODIFIED",'更改时间',"DERNIÈRE MODIFICATION"],
    BY : ['BY','更改人',"PAR"],
    EditDocument : ['Edit Document','更改文档',"Modifier Document"],
    AddNewDocument : ["Add New Document",'添加新文档',"Ajouter Nouveau Document"],
    TITLE1 : ['TITLE *','标题*',"TITRE *"],
    DOCUMENTTYPE : ['DOCUMENT TYPE','文档类别',"TYPE DE DOCUMENT"],
    NONE : ['NONE','未选择',"AUCUN"],
    Nodocumenttypesfound : ['No document types found','没有已存的文档类别',"Aucun type de document trouvé"],
    CONTENT : ['CONTENT','内容',"CONTENU"],
    FILES : ['FILES','文件',"FICHIERS"],
    Click : ['Click to upload...','单击上传文件',"Cliquez pour télécharger..."],
    FileNotFound : ['File Not Found!','刪除',"Fichier non trouvé !"],
    RELATEDDOCUMENTS : ['RELATED DOCUMENTS','相关的文档',"DOCUMENTS LIÉS"],
    TAGCOMPANY : ['TAG COMPANY','指定公司',"TAGUER L'ENTREPRISE"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    by : ['by','被',"par"],
    Attachments : ['Attachments:','附件:',"Pièces Jointes :"],
    RelatedDocuments : ['Related Documents:','相关的文档:',"Documents Liés :"],
    Versions : ['Versions:','版本:',"Versions :"]
}

// tenant user Services
export const ServicesLang = {
    SERVICENAME : ["SERVICE NAME",'服务名称',"NOM DU SERVICE"],
    DESCRIPTION : ["DESCRIPTION",'描述',"DESCRIPTION"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    WEBSITE : ['WEBSITE','网址',"SITE WEB"],
    NOTES : ['NOTES','备注',"NOTES"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"],
    WebsiteCopied : ['Website Copied','已复制网址',"Site Web Copié"],
    TenantServices : ['Tenant Services','承包人服务',"Services des Locataires"],
    EditService : ['Edit Service','编辑服务',"Modifier le Service"],
    AddNewService : ['Add New Service','创建新服务',"Ajouter Nouveau Service"],
    SERVICENAME1 : ['SERVICE NAME *','服务名称 *',"NOM DU SERVICE *"],
    SERVICETYPE : ['SERVICE TYPE','服务类别',"TYPE DE SERVICE"],
    OTPKEY : ['OTP KEY','一次性动态密码',"CLÉ OTP"],
    LOCATION : ['LOCATION','地点',"EMPLACEMENT"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    Nocategoryfound : ['No category found','没有发现任何类别',"Aucune catégorie trouvée"],
    NONE : ['NONE','未选择',"AUCUN"],
    Nolocationsfound : ['No locations found','没有已存的地址',"Aucun emplacement trouvé"],
    OTPCopied : ['OTP Copied','已复制OTP','OTP Copié']
}

// tenant user Vendors
export const VendorsLang = {
    COMPANY : ["COMPANY",'公司名称',"ENTREPRISE"],
    WEBSITE : ['WEBSITE','网址',"SITE WEB"],
    PHONE : ['PHONE','电话',"TÉLÉPHONE"],
    HOURSOFOPERATION : ['HOURS OF OPERATION','运营时间',"HEURES D'OUVERTURE"],
    WebsiteCopied : ['Website Copied','已复制网址',"Site Web Copié"],
    NOTES : ['NOTES','备注',"NOTES"],
    EditVendor : ['Edit Vendor','编辑供应商',"Modifier le Fournisseur"],
    AddNewVendor : ['Add New Vendor','创建新供应商',"Ajouter Nouveau Fournisseur"],
    COMPANYNAME : ['COMPANY NAME','公司名称',"NOM DE L'ENTREPRISE"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    Vendors : ["Vendors",'供应商',"Fournisseurs"]
}

// tenant user meeting
export const MeetingLang = {
    help1 : ["Preparing data to export. Download will begin automatically shortly.",'正在准备输出数据，马上开始下载',"Préparation des données à exporter. Le téléchargement commencera automatiquement sous peu."],
    help2 : ["success",'成功',"succès"],
    help3 : ['Invalid Meeting time.','错误的会议时间',"Heure de réunion invalide."],
    error1 : ["Error occured in export to pdf.",'输出时发生了错误',"Erreur survenue lors de l'exportation en PDF."],
    error2 : ["error",'错误',"erreur"],
    Attachments : ['Attachments:','附件:',"Pièces Jointes :"],
    Meetings : ['Meetings','会议',"Réunions"],
    NAME : ['NAME','名字',"NOM"],
    WHEN : ['WHEN','时间',"QUAND"],
    WHERE : ['WHERE','地点',"OÙ"],
    BY : ['BY','执行人',"PAR"],
    by : ['by','更改人',"par"],
    TITLE : ["TITLE",'标题',"TITRE"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    Start : ['Start','开始',"Début"],
    End : ['End','结束',"Fin"],
    EditMeeting : ['Edit Meeting','编辑会议',"Modifier Réunion"],
    AddNewMeeting : ['Add New Meeting','添加新会议',"Ajouter Nouvelle Réunion"],
    DESCRIPTION : ['DESCRIPTION','描述',"DESCRIPTION"],
    GUESTS : ['GUESTS','客人',"INVITÉS"],
    ROLES : ['ROLES','职权',"ROLES"],
    LOCATION : ['LOCATION','地点',"EMPLACEMENT"],
    Files : ['Files','文件',"Fichiers"],
    MEETINGTIME : ["MEETING TIME",'会议时间',"HEURE DE LA RÉUNION"]
}

// tenant user accounting
export const AccountingLang = {
    Accounting : ["Accounting",'会计',"Comptabilité"],
    CUSTOMERNAME : ["CUSTOMER NAME",'客户名称',"NOM DU CLIENT"],
    CARDONFILE : ['CARD ON FILE','记录的卡号',"CARTE EN REGISTRE"],
    PAYMENTTYPE : ["PAYMENT TYPE",'付款方式',"TYPE DE PAIEMENT"],
    REPEAT : ["REPEAT",'周期',"RÉPÉTER"],
    ChargeOn : ['Charge On','付款时间',"Facturer Le"],
    Report : ['Report','举报',"Rapport"],
    EditAccount : ['Edit Account','编辑',"Modifier le Compte"],
    AddAccount : ['Add Account','添加',"Ajouter Compte"],
    COMPANY : ['COMPANY','公司',"ENTREPRISE"],
    VAULTID : ['VAULT ID','保管库 ID',"ID DU COFFRE"],
    CARDNUMBER : ['CARD NUMBER','卡号',"NUMÉRO DE CARTE"],
    EXPIRATION : ["EXPIRATION",'过期时间',"EXPIRATION"],
    PAYMENTCHARGEDAY : ['PAYMENT CHARGE DAY','付款时间',"JOUR DE FACTURATION"],
    helper1 : ["Insert date in format",'请根据格式输入日期',"Insérer la date au format"],
    helper2 : ["Date must be within 01-31.",'日期必须在01-31之间',"La date doit être entre 01 et 31."],
    PAYMENTTYPE : ['PAYMENT TYPE','付款方式',"TYPE DE PAIEMENT"],
    Recurring : ['Recurring','循环',"Récurrent"],
    Manual : ['Manual','手动',"Manuel"],
    PAYMENTGATEWAYTYPE : ['PAYMENT GATEWAY TYPE','付款平台',"TYPE DE PASSERELLE DE PAIEMENT"],
    PAYMENTFREQUENCYTYPE : ['PAYMENT FREQUENCY TYPE','付款周期',"TYPE DE FRÉQUENCE DE PAIEMENT"],
    Monthly : ['Monthly','每月',"Mensuel"],
    Annually : ['Annually','每年',"Annuel"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"]
}



// tenant user HR
export const HRlang = {
    AVERAGESALARYPERDEPARTMENT : ["AVERAGE SALARY PER DEPARTMENT",'每个部门平均工资',"Retrieving data. Wait a few seconds and try to cut or copy again."],
    DEPARTMENT : ["DEPARTMENT",'部门',"DÉPARTEMENT"],
    AVERAGESALARY : ['AVERAGE SALARY','平均工资',"SALARIÉ MOYEN"],
    EMPLOYEES : ["EMPLOYEES",'员工',"EMPLOYÉS"],
    CreateNewHRProfile : ['Create New HR Profile','新人力档案',"Créer un Nouveau Profil RH"],
    NUMBEROFEMPLOYEESPERLOCATION : ['NUMBER OF EMPLOYEES PER LOCATION','每个地点的员工数',"NOMBRE D'EMPLOYÉS PAR EMPLACEMENT"],
    LOCATION : ["LOCATION",'地点',"EMPLACEMENT"],
    TOTAL : ["TOTAL",'总计',"TOTAL"],
    ChargeOn : ['Charge On','付款时间',"Facturer Le"],
    Report : ['Report','举报',"Rapport"],
    EditAccount : ['Edit Account','编辑',"Modifier le Compte"],
    AddAccount : ['Add Account','添加',"Ajouter Compte"],
    COMPANY : ['COMPANY','公司',"ENTREPRISE"],
    VAULTID : ['VAULT ID','保管库 ID',"ID DU COFFRE"],
    CARDNUMBER : ['CARD NUMBER','卡号',"NUMÉRO DE CARTE"],
    EXPIRATION : ["EXPIRATION",'过期时间',"EXPIRATION"],
    PAYMENTCHARGEDAY : ['PAYMENT CHARGE DAY','付款时间',"JOUR DE FACTURATION"],
    helper1 : ["Insert date in format",'请根据格式输入日期',"Insérer la date au format"],
    helper2 : ["Date must be within 01-31.",'日期必须在01-31之间',"La date doit être entre 01 et 31."],
    PAYMENTTYPE : ['PAYMENT TYPE','付款方式',"TYPE DE PAIEMENT"],
    Recurring : ['Recurring','循环',"Récurrent"],
    Manual : ['Manual','手动',"Manuel"],
    PAYMENTGATEWAYTYPE : ['PAYMENT GATEWAY TYPE','付款平台',"TYPE DE PASSERELLE DE PAIEMENT"],
    PAYMENTFREQUENCYTYPE : ['PAYMENT FREQUENCY TYPE','付款周期',"TYPE DE FRÉQUENCE DE PAIEMENT"],
    Monthly : ['Monthly','每月',"Mensuel"],
    Annually : ['Annually','每年',"Annuel"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"]
}

// tenant user employee
export const HRemployee = {
    FIRSTNAME : ["FIRST NAME",'名字',"PRÉNOM"],
    LASTNAME : ["LAST NAME",'姓',"NOM DE FAMILLE"],
    TITLE : ['TITLE','职位',"TITRE"],
    STARTDATE : ["START DATE",'入职日期',"DATE DE DÉBUT"],
    BIRTHDAY : ['BIRTHDAY','生日',"ANNIVERSAIRE"],
    STATUS : ['STATUS','状态',"STATUT"],
    LOCATION : ["LOCATION",'地点',"EMPLACEMENT"],
    TOTAL : ["TOTAL",'总计',"TOTAL"],
    USERLINK : ['USER LINK','员工链接',"LIEN UTILISATEUR"],
    Birthday : ['Birthday','生日',"Anniversaire"],
    monthscompletion : ['3 months completion','试用期结束',"3 mois d'achèvement"],
    Joininganniversary : ['Joining anniversary','加入日',"Anniversaire d'embauche"],
    istoday : [' is today!','是今天！',"est aujourd'hui !"],
    istomorrow : [' is tomorrow','是明天！',"est demain"],
    isin : [' is in ','在',"est dans"],
    days : [" days",'天以后',"jours"],
    HRPROFILES : ['HR PROFILES','人力资源档案',"Profils RH"],
    Employees : ["Employees",'员工',"Employés"],
    Calendar : ["Calendar",'日历',"Calendrier"],
    TOP : ['TOP','回归上级',"HAUT"],
    New : ['New','新',"Nouveau"],
    helper1 : ["Preparing data to export. Download will begin automatically shortly.",'正在准备输出数据，马上开始下载',"Préparation des données à exporter. Le téléchargement commencera automatiquement sous peu."],
    helper2 : ["Error occured in export to pdf.",'输出时发生了错误',"Erreur survenue lors de l'exportation en PDF."],
    helper3 : ["Would you like to map this HR Employee to existing Tenant User?",'你是否想把这个员工放置在一个现有的用户名下',"Souhaitez-vous mapper cet Employé RH à un Utilisateur Locataire existant ?"],
    helper4 : ["Agreeing will map this HR Employee to Tenant User ",'如果同意这个员工会被放置在一个现有的用户名下',"Accepter signifie mapper cet Employé RH à un Utilisateur Locataire"],
    CONTACTS : ['CONTACTS','联系',"CONTACTS"],
    MEETINGS : ['MEETINGS','会议',"RÉUNIONS"],
    ATTACHMENTS : ['ATTACHMENTS','附件',"PIÈCES JOINTES"],
    PRINT : ['PRINT','打印',"IMPRIMER"],
    Age : ['Age','年龄',"Âge"],
    Title : ['Title','职位',"Titre"],
    yrs : ["yrs ",'年 ',"ans"],
    mos : ['mos ','月 ',"mois"],
    EmploymentDuration : ['Employment Duration','入职时间',"Durée d'Emploi"],
    EMPLOYEEINFOMATION : ['EMPLOYEE INFOMATION','员工信息',"INFORMATIONS SUR L'EMPLOYÉ"],
    NAME : ['NAME','名字',"NOM"],
    ADDRESS : ['ADDRESS','地址',"ADRESSE"],
    FirstName : ['First Name','名字',"Prénom"],
    MiddleName : ['Middle Name','中间名字',"Deuxième Prénom"],
    LastName : ['Last Name','姓',"Nom de Famille"],
    Street : ['Street','街',"Rue"],
    City : ['City','城市',"Ville"],
    Province : ['Province','省',"Province"],
    Postal : ['Postal','邮编',"Code Postal"],
    Country : ['Country','城市',"Pays"],
    PhoneNumber : ['Phone Number','电话号码',"Numéro de Téléphone"],
    alt : ["Alt. Phone Number",'另一个电话',"Numéro de Téléphone Alternatif"],
    PHONE : ['PHONE','手机',"TÉLÉPHONE"],
    EMAIL : ['EMAIL','电子邮件',"EMAIL"],
    Email : ['Email','电子邮件',"Email"],
    BIRTHDAY : ['BIRTHDAY','生日',"ANNIVERSAIRE"],
    Invalidbirthdate : ['Invalid birth date.','不正确的生日格式',"Date de naissance invalide."],
    Startdate : ['Start date cannot be before birthday.','开始日期',"La date de début ne peut pas être antérieure à l'anniversaire."],
    SIN : ["SIN",'社保码',"SIN"],
    HR : [" | HR | ",' | 人力资源 | ',"| RH |"],
    HR1 : [' | HR',' | 人力资源',"| RH"],
    ADDNEW : ["ADD NEW",'新建',"AJOUTER NOUVEAU"],
    TITLE : ['TITLE','标题',"TITRE"],
    Title : ['Title','标题',"Titre"],
    DEPARTMENT : ['DEPARTMENT','部门',"DÉPARTEMENT"],
    LOCATION : ['LOCATION','地点',"EMPLACEMENT"],
    Nolocations : ['No locations found.','没有检测到地点',"Aucun emplacement trouvé."],
    REPORTSTO : ['REPORTS TO','隶属于',"RAPPORT À"],
    VACATIONPROFILE : ['VACATION PROFILE','假期档案',"PROFIL DE CONGÉS"],
    SALARY : ['SALARY','工资',"SALAIRE"],
    Amount : ['Amount','数量',"Montant"],
    ISHOURLY : ['IS HOURLY','按小时计算',"EST HORAIRES"],
    SALARYSTARTDATE : ['SALARY START DATE','工资开始日期',"DATE DE DÉBUT DU SALAIRE"],
    Invalidsalarydate : ['Invalid salary date.','不正确的工资日期',"Date de salaire invalide."],
    helper1 : ["Salary record for this date already exists.",'工资记录已存在',"Le dossier de salaire pour cette date existe déjà."],
    STARTENDDATE : ['START / END DATE','开始 / 结束日期',"DATE DE DÉBUT / FIN"],
    helper2 : ["Start date cannot be before birthday.",'开始时间不能比生日早',"La date de début ne peut pas être antérieure à l'anniversaire."],
    helper3 : ["End date cannot be before birthday.",'结束日期不能比生日早',"La date de fin ne peut pas être antérieure à l'anniversaire."],
    Active : ['Active','活跃',"Actif"],
    Disabled : ['Disabled','禁止',"Désactivé"],
    EMERGENCYCONTACTS : ['EMERGENCY CONTACTS','紧急联络人',"CONTACTS D'URGENCE"],
    CONTACT : ['CONTACT','联系',"CONTACT"],
    Relation : ['Relation','关系',"Relation"],
    ContactNumber : ['Contact Number','联络电话',"Numéro de Contact"],
    Delete : ['Delete','删除',"Supprimer"],
    Name : ['Name','名字',"Nom"],
    helper4 : ['ARE THERE ANY HEALTH ISSUES YOUR EMPLOYER SHOULD BE AWARE OF?','你是否有任何你雇主需要知道的健康问题',"Y A-T-IL DES PROBLÈMES DE SANTÉ QUE VOTRE EMPLOYEUR DOIT CONNAÎTRE ?"],
    HEALTHISSUES : ['HEALTH ISSUES','健康问题',"PROBLÈMES DE SANTÉ"],
    HealthIssues : ['Health Issues','健康问题',"Problèmes de Santé"],
    Add : ['Add','添加',"Ajouter"],
    MEETINGS : ['MEETINGS','会议',"RÉUNIONS"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    ALLUSERS : ['ALL USERS','全部员工',"TOUS LES UTILISATEURS"]
}

// tenant user meeting
export const jobLang = {
    JobPostings : ["Job Postings",'工作机会',"Annonces d'Emploi"],
    JobPosting : ["Job Posting",'工作机会',"Annonce d'Emploi"],
    TITLE : ["TITLE",'标题',"TITRE"],
    LASTMODIFIED : ["LAST MODIFIED",'更改时间',"DERNIÈRE MODIFICATION"],
    BY : ['BY','更改人',"PAR"],
    EditDocument : ['Edit Document','更改文档',"Modifier Document"],
    AddNewDocument : ["Add New Document",'添加新文档',"Ajouter Nouveau Document"],
    TITLE1 : ['TITLE *','标题*',"TITRE *"],
    DOCUMENTTYPE : ['DOCUMENT TYPE','文档类别',"TYPE DE DOCUMENT"],
    NONE : ['NONE','未选择',"AUCUN"],
    Nodocumenttypesfound : ['No document types found','没有已存的文档类别',"Aucun type de document trouvé"],
    CONTENT : ['CONTENT','内容',"CONTENU"],
    FILES : ['FILES','文件',"FICHIERS"],
    Click : ['Click to upload...','单击上传文件',"Cliquez pour télécharger..."],
    FileNotFound : ['File Not Found!','刪除',"Fichier non trouvé !"],
    RELATEDDOCUMENTS : ['RELATED DOCUMENTS','相关的文档',"DOCUMENTS LIÉS"],
    TAGCOMPANY : ['TAG COMPANY','指定公司',"TAGUER L'ENTREPRISE"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    by : ['by','被',"par"],
    Attachments : ['Attachments:','附件:',"Pièces Jointes :"],
    RelatedDocuments : ['Related Documents:','相关的文档:',"Documents Liés :"],
    Versions : ['Versions:','版本:',"Versions :"]
}

// tenant user meeting
export const ReportsLang = {
    Reports : ["Reports",'报告',"Rapports"],
    REPORTTYPE : ["REPORT TYPE",'报告形式',"TYPE DE RAPPORT"],
    Summary : ["Summary",'总览',"Résumé"],
    VACATION : ["VACATION",'休假',"CONGÉS"],
    SICK : ['SICK','生病',"MALADE"],
    MOVING : ['MOVING','搬家',"DÉMÉNAGEMENT"],
    OTHER : ["OTHER",'其他',"AUTRE"],
    VacationDays : ['Vacation Days','休假日期',"Jours de Congés"],
    SickDays : ['Sick Days','病假',"Jours Maladie"],
    PersonalDays : ['Personal Days','事假',"Jours Personnels"],
    MovingDays : ['Moving Days','搬家假期',"Jours de Déménagement"],
    Other : ['Other','其他',"Autre"],
    Generate : ['Generate','生成',"Générer"],
    LOCATIONS : ['LOCATIONS','地点',"EMPLACEMENTS"],
    DATERANGE : ['DATE RANGE','时间区间',"PLAGE DE DATES"],
    helper : ["Select date range and hit 'Enter'.",'选择时间区间 然后单击回车',"Sélectionner une plage de dates et appuyer sur 'Entrée'."],
    helper2 : ["Date cannot come after 'To' date.",'时间在到后面不正确',"La date ne peut pas être après la date 'Jusqu'à'."],
    helper3 : ["Date cannot come before 'From' date.",'时间在从后面不正确',"La date ne peut pas être avant la date 'De'."],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    by : ['by','被',"par"],
    PERSONAL : ['PERSONAL','人',"PERSONNEL"],
    From : ['From','从',"De"],
    To : ['To','到',"À"],
    VacationOverview : ['Vacation Overview','假期总览',"Aperçu des Congés"],
    EMPLOYEE : ['EMPLOYEE','员工',"EMPLOYÉ"],
    LOCATION : ['LOCATION','地点',"EMPLACEMENT"],
    EXPORT : ['EXPORT','输出',"EXPORTER"],
    SICK : ['SICK','生病',"MALADE"],
    PERSONAL : ['PERSONAL','人员',"PERSONNEL"],
    MOVING : ['MOVING','搬家',"DÉMÉNAGEMENT"],
    Empty : ['This table is empty.','无数据',"Ce tableau est vide."],
    Versions : ['Versions:','版本:',"Versions :"]
}

//HR setting
export const SettingLang = {
    HRSettings : ['HR Settings','人力资源设置',"Paramètres RH"],
    helper : ['Also Send Vacation Requests to','同时发送休假请求到',"Envoyer également les demandes de congés à"],
    helper2 : ['Vacation CC updated!','休假请求同时发送地址已更新',"CC des Congés mis à jour !"],
    Save : ['Save','保存',"Sauvegarder"],
    Emails : ['Emails','电子邮件地址',"Emails"]
}

//HR vacation
export const vacationLang = {
    NAME : ['NAME','姓名',"NOM"],
    MAXVACATIONS : ['MAX VACATIONS','最大假期天数',"MAX CONGÉS"],
    MAXSICKDAYS : ['MAX SICK DAYS','最大病假天数',"MAX JOURS MALADIE"],
    MAXMOVINGDAYS : ['MAX MOVING DAYS','最大搬家天数',"MAX JOURS DE DÉMÉNAGEMENT"],
    MAXPERSONALDAYS : ['MAXPERSONALDAYS','最大事假天数',"MAX JOURS PERSONNELS"],
    Enteranewname : ['Enter a new name','请输入名字',"Entrer un nouveau nom"],
    VacationProfile : ['Vacation Profile','假期档案',"Profil de Congés"],
    helper3 : ['Continue saving or create a new copy?','继续保存或者新建一份记录',"Continuer à sauvegarder ou créer une nouvelle copie ?"],
    helper4 : ['Vacation Saving this change to the profile will affect all associated users including their historical reports.','已保存，所有相关的用户已被囊括',"La sauvegarde de ce changement dans le profil affectera tous les utilisateurs associés, y compris leurs rapports historiques."],
    helper2 : ['Name already in use.','名字已被使用',"Nom déjà utilisé."],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    VACATIONS : ['VACATIONS','假期',"CONGÉS"],
    SICKDAYS : ['SICK DAYS','病假',"JOURS MALADIE"],
    PERSONALDAYS : ['PERSONAL DAYS','事假',"JOURS PERSONNELS"],
    MOVINGDAYS : ['MOVING DAYS','搬家',"JOURS DE DÉMÉNAGEMENT"],
    VacationProfiles : ['Vacation Profiles','假期档案',"Profils de Congés"],
    helper : ['Name already in use. Please enter another one.','名字已被使用请输入另一个名字',"Nom déjà utilisé. Veuillez en entrer un autre."]
}

//job post template
export const postLang = {
    TITLE : ['TITLE','标题',"TITRE"],
    JobPostingTemplate : ['Job Posting Template','工作模板',"Modèle d'Annonce d'Emploi"],
    TEMPLATE : ['TEMPLATE','模板',"MODÈLE"],
    JobPostingTemplates : ['Job Posting Templates','工作模板',"Modèles d'Annonces d'Emploi"]
}

//Global search

export const searchLang = {
    CompanySearch : ['Company Search','公司搜索',"Recherche d'Entreprise"],
    GlobalSearch : ['Global Search','全局搜索',"Recherche Globale"],
    Shortcuts : ['Options/ Shortcuts','选项/快捷键',"Options / Raccourcis"],
    select : ['select','选择',"sélectionner"],
    Location : ['Location','地点',"Emplacement"],
    esc : ['esc','退出',"esc"],
    cancel : ['cancel','取消',"annuler"],
    helper : ['Click to show info / Double click to open entry','单击显示信息/双击打开',"Cliquez pour afficher les informations / Double-cliquez pour ouvrir l'entrée"],
    Name : ['Name','名字',"Nom"],
    IndustryType : ['Industry Type','领域',"Type d'Industrie"],
    Website : ['Website','网站',"Site Web"],
    Email : ['Email','电子邮件',"Email"],
    PhoneNumber : ['Phone Number','电话号码',"Numéro de Téléphone"],
    Status : ['Status','状态',"Statut"],
    Notes : ['Notes','备注',"Notes"],
    Address : ['Address','地址',"Adresse"],
    ContactNumber : ['Contact Number','联系数字',"Numéro de Contact"],
    Company : ['Company','公司',"Entreprise"],
    Category : ['Category','类型',"Catégorie"],
    Content : ['Content','内容',"Contenu"],
    DeviceType : ['Device Type','设备型号',"Type de Dispositif"],
    Description : ['Description','描述',"Description"],
    Username : ['Username','用户名',"Nom d'Utilisateur"],
    Password : ['Password','密码',"Mot de Passe"],
    TagNumber : ['Tag Number','标签数字',"Numéro de Tag"],
    SerialNumber : ['Serial Number','注册码',"Numéro de Série"],
    InstallDate : ['Install Date','安装时间',"Date d'Installation"],
    SoftwareAssetTag : ['Software Asset Tag','软件标签',"Tag d'Actif Logiciel"],
    WorkstationAssetTag : ['Workstation Asset Tag','工作站标签',"Tag d'Actif Station de Travail"],
    PortalAccess : ['Portal Access','门户访问',"Accès au Portail"],
    Model : ['Model','模型',"Modèle"],
    SchemaName : ['Schema Name','模式名字',"Nom du Schéma"],
    DomainName : ['Domain Name','域名',"Nom de Domaine"],
    Registrar : ['Registrar','注册表',"Registraire"],
    ExpirationDate : ['Expiration Date','过期时间',"Date d'Expiration"],
    SSLExpirationDate : ['SSL Expiration Date','SSL 过期时间',"Expiration SSL"],
    SubDomains : ['Sub Domains','子域名',"Sous-domaines"],
    Showpassword : ['Show password','显示密码',"Afficher le mot de passe"],
    Copy : ['Copy','复制',"Copier"],
    KeyWords : ['KeyWords','关键词',"Mots-clés"],
    helper1 : ['Password copied!','已复制密码！',"Mot de passe copié !"],
    navigate : ['navigate','导航',"naviguer"]
}

//company recent activities
export const recentActivityLang = {
    RECENTACTIVITY : ['RECENT ACTIVITY','最近活动',"ACTIVITÉ RÉCENTE"],
    UPDATE : ['UPDATE','更新',"MISE À JOUR"],
    BY : ['BY','更改人',"PAR"],
    DATE: ['DATE','时间',"DATE"]
}

//company site list
export const siteLang = {
    HEADOFFICE : ['HEAD OFFICE','总公司',"SIÈGE SOCIAL"],
    NAME : ['NAME','名字',"NOM"],
    PRIMARYCONTACTNUMBER : ['PRIMARY CONTACT NUMBER','主要联系方式',"NUMÉRO DE CONTACT PRINCIPAL"],
    ADDRESS : ['ADDRESS','地址',"ADRESSE"],
    WEBSITE : ['WEBSITE','网站',"SITE WEB"],
    CITY : ['CITY','市',"VILLE"],
    PROVINCE : ['PROVINCE','省',"PROVINCE"],
    POSTAL : ['POSTAL','邮政编码',"CODE POSTAL"],
    COUNTRY : ['COUNTRY','国家',"PAYS"],
    PRIMARYCONTACT : ['PRIMARY CONTACT','主要联系人',"CONTACT PRINCIPAL"],
    HOURSOFOPERATION : ['HOURS OF OPERATION','运营时间',"HEURES D'OUVERTURE"],
    TAG : ['TAG','标签',"TAG"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    NONE : ['NONE','未选择',"AUCUN"],
    Site : ['Site','地点',"Site"],
    Document : ["Document",'文档',"Document"],
    Employee : ['Employee','雇员',"Employé"],
    Device : ['Device','设备',"Dispositif"],
    License : ['License','注册表',"Licence"],
    Service : ['Service','服务',"Service"],
    Domain : ['Domain','域名',"Domaine"],
    Gallery : ['Gallery','相册',"Galerie"],
    Loading : ['Loading...','正在加载...',"Chargement..."],
    SitesList : ['Sites List','地点列表',"Liste des Sites"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    helper1 : ["Are you sure you want to change head office?",'你确定要更换总公司地址？',"Êtes-vous sûr de vouloir changer le siège social ?"],
    helper2 : ["Assigning a new head office will replace the previous head office.",'重新确定一个总公司地址会更换之前的地址',"Attribuer un nouveau siège social remplacera le siège social précédent."],
    EditSite : ["Edit Site",'编辑地点',"Modifier Site"],
    AddNewSite : ['Add New Site','添加新地点',"Ajouter Nouveau Site"],
    Notagsfound : ['No tags found','没发现标签',"Aucun tag trouvé"],
    SITENAME : ['SITE NAME','地点名字',"NOM DU SITE"]
}

//company Documents list
export const DocumentsLang = {
    Documents : ['Documents','全部文档',"Documents"],
    Root : ['Root','根目录',"Racine"],
    Folders : ['Folders','文件夹',"Dossiers"],
    Folder : ['Folder','文件夹',"Dossier"],
    AllCategories : ['All Categories','所有分类',"Toutes les Catégories"],
    TITLE : ['TITLE','标题',"TITRE"],
    SITE : ["SITE",'地点',"SITE"],
    BY : ["BY",'执行人',"PAR"],
    WEBSITE : ['WEBSITE','网站',"SITE WEB"],
    LASTMODIFIED : ["LAST MODIFIED",'更改时间',"DERNIÈRE MODIFICATION"],
    BulkUpload : ['Bulk Upload','批量上传',"Téléchargement en masse"],
    Lock : ['Lock','锁定',"Verrouiller"],
    Unlock : ['Unlock','解锁',"Déverrouiller"],
    Upload : ['Upload','上传',"Télécharger"],
    Locked : ['Locked','已锁定',"Verrouillé"],
    HOURSOFOPERATION : ['HOURS OF OPERATION','运营时间',"HEURES D'OUVERTURE"],
    TAG : ['TAG','标签',"TAG"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    RELATEDENTRIES : ['RELATED ENTRIES','相关文件',"ENTRÉES LIÉES"],
    NONE : ['NONE','未选择',"AUCUN"],
    Sites : ['Sites','地点',"Sites"],
    CATEGORY : ['CATEGORY','类别',"CATÉGORIE"],
    FILES : ['FILES','文件',"FICHIERS"],
    helper1 : ['File size exceeds 5.24MB! Please upload a smaller file!','上传的文件超过了5.24MB,请上传一个小一些的文件',"La taille du fichier dépasse 5,24 Mo ! Veuillez télécharger un fichier plus petit !"],
    DocumentisLocked : ['Document is Locked','这个文档被锁定了',"Document Verrouillé"],
    helper2 : ['You have sent an unlock request, please wait!','你已经发送了一个解锁的要求，请等待',"Vous avez envoyé une demande de déverrouillage, veuillez patienter !"],
    UnlockRequest : ['Unlock Request','解锁要求',"Demande de Déverrouillage"],
    Document : ["Document",'文档',"Document"],
    Cancel : ['Cancel','取消',"Annuler"],
    SHARED : ["SHARED",'分享',"PARTAGÉ"],
    NO : ["NO",'否',"NON"],
    YES : ["YES",'是',"OUI"],
    by : ['by','被',"par"],
    Location : ['Location','地点',"Emplacement"],
    Attachments : ['Attachments','附件',"Pièces Jointes"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    Versions : ['Versions','版本',"Versions"],
    CONTENT : ['CONTENT','内容',"CONTENU"]
}

//company Employees list
export const EmployeesLang = {
    Employees : ['Employees ','全部员工 ',"Employés"],
    Active : ['Active','活跃',"Actif"],
    Inactive : ['Inactive','不活跃',"Inactif"],
    NAME : ['NAME','名字',"NOM"],
    SITE : ["SITE",'地点',"SITE"],
    EMAIL : ['EMAIL','电子邮件',"EMAIL"],
    PHONE : ['PHONE','电话',"TÉLÉPHONE"],
    WORKSTATION : ['WORKSTATION','工作站',"STATION DE TRAVAIL"],
    PORTALROLE : ["PORTAL ROLE",'门户权限',"RÔLE PORTAIL"],
    AllSites : ['All Sites','全部地点',"Tous les Sites"],
    AllCategories : ['All Categories','所有分类',"Toutes les Catégories"],
    Visibility : ['Visibility','可视性',"Visibilité"],
    EmployeeCredential : ['Employee Credential','用户凭证',"Identifiant de l'Employé"],
    DownloadCredentialsFormat : ['Download Credentials Format','下载证书格式',"Télécharger Format des Identifiants"],
    ImportCredentials : ['Import Credentials','输入证书',"Importer Identifiants"],
    ExportAllCredentials : ['Export All Credentials','输出全部证书',"Exporter Tous les Identifiants"],
    ExportShowedCredentials : ['Export Showed Credentials','输出显示的证书',"Exporter les Identifiants Affichés"],
    EMPLOYEENAME : ["EMPLOYEE NAME",'员工姓名',"NOM DE L'EMPLOYÉ"],
    PHONENUMBER : ['PHONE NUMBER','手机号码',"NUMÉRO DE TÉLÉPHONE"],
    ACTIVE : ['ACTIVE','活跃',"ACTIF"],
    MOBILE : ['MOBILE','手机',"MOBILE"],
    COUNTRYCODE : ['COUNTRY CODE','国家地区代码',"CODE DU PAYS"],
    SHORTNOTE : ['SHORT NOTE','短备注',"NOTE COURTE"],
    CATEGORY : ['CATEGORY','类别',"CATÉGORIE"],
    PORTALROLE : ["PORTAL ROLE",'门户权限',"RÔLE DU PORTAIL"],
    HIDDEN : ["HIDDEN",'隐藏',"MASQUÉ"],
    BILLINGCONTACT : ["BILLING CONTACT",'付款人联系方式',"CONTACT DE FACTURATION"],
    NONE : ['NONE','未选择',"AUCUN"],
    EMPLOYEENAME : ["EMPLOYEE NAME",'员工姓名',"NOM DE L'EMPLOYÉ"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    WEBSITE : ['WEBSITE','网站',"SITE WEB"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    DESCRIPTION : ["DESCRIPTION",'描述',"DESCRIPTION"],
    CREDENTIALS : ['CREDENTIALS','证书',"IDENTIFIANTS"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"],
    ACCOUNTTYPE : ['ACCOUNT TYPE','账户类型',"TYPE DE COMPTE"],
    SelectAccountType : ['Select Account Type','选择账户类型',"Sélectionner le Type de Compte"],
    otp : ["O.T.P. KEY",'一次性动态密码',"CLÉ OTP"],
    OTP : ["OTP",'一次性动态密码',"OTP"],
    AddNew : ["Add New",'新增',"Ajouter Nouveau"],
    History : ["History",'历史',"Historique"],
    Edit : ["Edit",'编辑',"Modifier"],
    Delete : ["Delete",'删除',"Supprimer"],
    RELATEDENTRIES : ['RELATED ENTRIES','相关文件',"Entrées Connexes"]
}

//company Device list
export const DeviceLang = {
    ServersandDevices : ['Servers and Devices','服务器和设备',"Serveurs et Dispositifs"],
    DEVICENAME : ['DEVICE NAME','设备名称',"NOM DU DISPOSITIF"],
    DEVICETYPE : ['DEVICE TYPE','设备种类',"TYPE DE DISPOSITIF"],
    None : ['None','未选择',"Aucun"],
    NONE : ['NONE','未选择',"AUCUN"],
    DESCRIPTION : ["DESCRIPTION",'描述',"DESCRIPTION"],
    URL : ['URL','网址',"URL"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    TAGNUMBER : ['TAG NUMBER','标签数字',"NUMÉRO DE TAG"],
    TAG : ['TAG','标签',"TAG"],
    Notagsfound : ['No tags found','没发现标签',"Aucun tag trouvé"],
    OPERATINGSYSTEM : ['OPERATING SYSTEM','操作系统',"Retrieving data. Wait a few seconds and try to cut or copy again."],
    HARDWAREMAKE : ['HARDWARE MAKE','硬件制造厂商',"Retrieving data. Wait a few seconds and try to cut or copy again."],
    HARDWAREMODEL : ['HARDWARE MODEL','硬件型号',"MODÈLE DU MATÉRIEL"],
    HARDWARESERIALNUMBER : ['HARDWARE SERIAL NUMBER','硬件序列号',"NUMÉRO DE SÉRIE DU MATÉRIEL"],
    LASTLOGINNAME : ['LAST LOGIN NAME','最后一次登录用户',"NOM DE CONNEXION FINAL"],
    TAGLOCATION : ['TAG LOCATION','标签地址',"EMPLACEMENT DU TAG"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    DEFAULT : ['DEFAULT','默认',"DÉFAUT"],
    Options : ['Options','选项',"Options"],
    NOTES : ['NOTES','备注',"NOTES"],
    SITE : ["SITE",'地点',"SITE"],
    EditDevice : ['Edit Device','编辑设备信息',"Modifier Dispositif"],
    AddNewDevice : ['Add New Device','新增设备信息',"Ajouter Nouveau Dispositif"],
    INFO : ['INFO','信息',"INFO"],
    CURRENTIP : ['CURRENT IP','当前的IP地址',"IP COURANTE"],
    CREDENTIALS : ['CREDENTIALS','证书',"IDENTIFIANTS"],
    CATEGORY : ['CATEGORY','类别',"CATÉGORIE"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    URLCopied : ["URL Copied",'已复制地址',"URL Copiée"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"],
    CREDENTIALTYPE : ["CREDENTIAL TYPE",'凭证类型',"TYPE D'IDENTIFIANT"],
    DeviceCredential : ["Device Credential",'设备凭证',"Identifiant de Dispositif"],
    AddNew : ["Add New",'新增',"Ajouter Nouveau"],
    History : ["History",'历史',"Historique"],
    Edit : ["Edit",'编辑',"Modifier"],
    Delete : ["Delete",'删除',"Supprimer"],
    helper : ['No device types found','没有发现任何设备',"Aucun type de dispositif trouvé"]
}

//company license list
export const licenseLang = {
    SoftwareLicenses : ['Software Licenses','软件注册信息',"Licences Logicielles"],
    SOFTWARENAME : ['SOFTWARE NAME','软件名称',"NOM DU LOGICIEL"],
    EXPIRATIONDATE : ['EXPIRATION DATE','过期日期',"DATE D'EXPIRATION"],
    SOFTWAREASSETTAG : ['SOFTWARE ASSET TAG','软件资产标签',"TAG D'ACTIF LOGICIEL"],
    WORKSTATIONASSETTAG : ['WORKSTATION ASSET TAG','工作站资产标签',"TAG D'ACTIF STATION DE TRAVAIL"],
    LICENSETYPE : ['LICENSE TYPE','软件种类',"TYPE DE LICENCE"],
    NAME : ['NAME','名字',"NOM"],
    INSTALLDATE : ['INSTALL DATE','安装时间',"DATE D'INSTALLATION"],
    EXPIRATIONDATE : ['EXPIRATION DATE','过期时间',"DATE D'EXPIRATION"],
    LOCATION : ['LOCATION','地址',"EMPLACEMENT"],
    NONE : ['NONE','未选择',"AUCUN"],
    helper : ['No license types found','没发现任何软件种类',"Aucun type de licence trouvé"],
    NOTES : ['NOTES','备注',"NOTES"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    EditSoftwareLicense : ['Edit Software License','修改软件注册信息',"Modifier Licence Logicielle"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    helper1 : ['This field is required','此为必填',"Ce champ est requis"],
    helper2 : ['This field may not be blank.','此栏不可为空',"Ce champ ne peut pas être vide."],
    helper3 : ['Install date can not be after expiration date.','安装时间不可晚于过期时间',"La date d'installation ne peut pas être après la date d'expiration."],
    Nolocationsfound : ['No locations found','没有已存的地址',"Aucun emplacement trouvé"],
    AddNewSoftwareLicense : ['Add New Software License','新建软件注册信息',"Ajouter Nouvelle Licence Logicielle"],
    SERIALNUMBER : ['SERIAL NUMBER','序列号',"NUMÉRO DE SÉRIE"]
}

//company service list
export const ServiceLang = {
    OtherServices : ['Other Services','其他服务',"Autres Services"],
    DESCRIPTION : ["DESCRIPTION",'描述',"DESCRIPTION"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    WEBSITE : ['WEBSITE','网址',"SITE WEB"],
    NOTES : ['NOTES','备注',"NOTES"],
    NONE : ['NONE','未选择',"AUCUN"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"],
    WebsiteCopied : ['Website Copied','已复制网址',"Site Web Copié"],
    SERVICENAME : ["SERVICE NAME",'服务名称',"NOM DU SERVICE"],
    OTP : ['OTP','一次性动态密码',"OTP"],
    LOCATION : ['LOCATION','地址',"EMPLACEMENT"],
    RelatedEntries : ['Related Entries','相关文件',"Entrées Connexes"],
    RELATEDENTRIES : ['RELATED ENTRIES','相关文件',"ENTRÉES LIÉES"],
    OTPKEY : ['OTP KEY','一次性动态密码',"CLÉ OTP"],
    ServiceType : ["Service Type",'服务类型',"type de service"],
    SERVICETYPE : ["SERVICE TYPE",'服务类型',"TYPE DE SERVICE"],
    AddNewService : ['Add New Service','新建服务',"Ajouter Nouveau Service"],
    EditService : ['Edit Service','更改服务',"Modifier Service"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    helper1 : ['This field is required','此为必填',"Ce champ est requis"],
    helper2 : ['This field may not be blank.','此栏不可为空',"Ce champ ne peut pas être vide."],
    helper3 : ['Install date can not be after expiration date.','安装时间不可晚于过期时间',"La date d'installation ne peut pas être après la date d'expiration."],
    Nolocationsfound : ['No locations found','没有已存的地址',"Aucun emplacement trouvé"],
    AddNewSoftwareLicense : ['Add New Software License','新建软件注册信息',"Ajouter Nouvelle Licence Logicielle"],
    SERIALNUMBER : ['SERIAL NUMBER','序列号',"NUMÉRO DE SÉRIE"]
}

//company Domain list
export const DomainLang = {
    Domains : ['Domains','域名',"Domaines"],
    Domain : ['Domain','域名',"Domaine"],
    SUBDOMAIN : ['SUB DOMAIN','次级域名',"SOUS-DOMAINE"],
    LASTUPDATED : ['LAST UPDATED','最后更新',"DERNIÈRE MISE À JOUR"],
    CREATIONDATE : ['CREATION DATE','创建时间',"DATE DE CRÉATION"],
    DOMAINNAME : ['DOMAIN NAME','域名名称',"NOM DE DOMAINE"],
    REGISTRAR : ['REGISTRAR','注册表',"REGISTRAIRE"],
    EXPIRATIONDATE : ['EXPIRATION DATE','过期日期',"DATE D'EXPIRATION"],
    SSLEXPIRATIONDATE : ['SSL EXPIRATION DATE','SSL 过期时间',"DATE D'EXPIRATION SSL"],
    Domainhasexpired : ['Domain has expired.','域名已过期',"Le domaine a expiré."],
    Domainwillexpiresoon : ['Domain will expire soon.','域名快要过期',"Le domaine expirera bientôt."],
    SSLhasexpired : ['SSL has expired.','SSL已过期',"SSL a expiré."],
    SSLwillexpiresoon : ['SSL will expire soon.','SSL快要过期',"SSL expirera bientôt."],
    RefreshAllDomains : ['Refresh All Domains','刷新所有域名',"Rafraîchir Tous les Domaines"],
    SUBDOMAINNAME : ['SUB DOAMIN NAME','次级域名名字',"NOM SOUS-DOMAINE"],
    RELATEDENTRIES : ['RELATED ENTRIES','相关文件',"ENTRÉES LIÉES"],
    AddNew : ['Add New','新建',"Ajouter Nouveau"],
    DNS : ['DNS','DNS',"DNS"]
}

//company Archive list
export const ArchiveLang = {
    Archives : ["Archives",'垃圾桶',"Archives"],
    MODEL : ["MODEL",'属于',"MODÈLE"],
    NAME : ["NAME",'名字',"NOM"],
    LASTMODIFIEDBY : ["LAST MODIFIED BY",'更改人',"DERNIÈRE MODIFICATION PAR"],
    LASTMODIFIEDAT : ['LAST MODIFIED AT','更改时间',"DERNIÈRE MODIFICATION À"],
    Remove : ['Remove','移除',"Retirer"],
    helper : ['Delete permanently?','是否永久移除？',"Supprimer définitivement ?"],
    Restore : ['Restore','复原',"Restaurer"]
}


//company passwords list
export const PasswordLang = {
    Passwords : ["Passwords",'密码',"Mots de Passe"],
    MODEL : ["MODEL",'属于',"MODÈLE"],
    NAME : ["NAME",'名字',"NOM"],
    LASTMODIFIEDBY : ["LAST MODIFIED BY",'更改人',"DERNIÈRE MODIFICATION PAR"],
    LASTMODIFIEDAT : ['LAST MODIFIED AT','更改时间',"DERNIÈRE MODIFICATION À"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    NONE : ['NONE','未选择',"AUCUN"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"]
}

//company gallery list
export const GalleryLang = {
    Gallery : ["Gallery",'相册',"Galerie"],
    BulkUpload : ['Bulk Upload','批量上传',"Téléchargement en masse"],
    helper1 : ["Click Here to Upload Image (Max 5M)",'更改人',"Cliquez Ici pour Télécharger l'Image (Max 5M)"],
    helper2 : ['Upload failed!','更改时间',"Échec du Téléchargement !"],
    confirm : ['If you agree, data will be deleted permanently.','如果确认, 数据将会被永久删除',"Si vous êtes d'accord, les données seront supprimées définitivement."],
    Upload : ['Upload','移除',"Télécharger"],
    Update : ['Update','更新',"Mettre à Jour"],
    delete : ['Do you want to delete?','是否删除',"Voulez-vous supprimer ?"],
    helper : ['Delete permanently?','是否永久移除？',"Supprimer définitivement ?"],
    Notes: ['Notes','备注',"Notes"],
    Delete : ["Delete",'删除',"Supprimer"],
    cancel : ['Cancel','取消',"Annuler"],
    Search : ['Search','搜索',"Recherche"],
    Name : ['Name','名字',"Nom"]
}

//company configuration
export const ImportVaultLang = {
    NAME: ['NAME','名称',"NOM"],
    USERNAME : ["USERNAME",'用户名',"NOM D'UTILISATEUR"],
    PASSWORD : ["PASSWORD",'密码',"MOT DE PASSE"],
    URL : ['URL','网址',"URL"],
    DELETEAT : ['DELETE AT','删除时间',"SUPPRIMER À"],
    UsernameCopied : ["Username Copied",'已复制用户名',"Nom d'utilisateur Copié"],
    PasswordCopied : ['Password Copied','已复制密码',"Mot de Passe Copié"],
    Name : ['Name','名称',"Nom"],
    MoveTo : ['Move To','移动到',"Déplacer Vers"],
    CredentialType : ["Credential Type",'凭证类型',"Type d'Identifiant"],
    DeviceType : ["Device Type",'设备种类',"Type de Dispositif"],
    Service : ['Service','服务',"Service"],
    helper : ['Current credential will be overwritten.','现在的凭证会被覆写',"L'identifiant actuel sera écrasé."],
    ServiceType : ["Service Type",'服务类型',"Type de Service"],
    Employee : ['Employee','雇员',"Employé"],
    EmployeeName : ['Employee Name','雇员姓名',"Nom de l'Employé"], 
    AccountType : ["Account Type",'账户种类',"Type de Compte"],
    cancel : ['Cancel','取消',"Annuler"],
    ImportVault : ['Import Vault','导入库',"Importer Coffre"],
    ServersDevices : ['Servers & Devices','设备',"Serveurs & Dispositifs"],
    OtherServices : ['Other Services','其他服务',"Autres Services"],
    EmployeeCredentials : ['Employee Credentials','员工凭证',"Identifiants des Employés"],
    CreateNew : ['Create New','新',"Créer Nouveau"],
    Move: ['Move','转移',"Déplacer"]
}


//company configuration
export const LayoutLang = {
    Settings : ['Settings','设置',"Paramètres"],
    Vault: ['Vault','库',"Coffre"],
    Profile : ['Profile','信息',"Profil"],
    Resources : ['Resources','资源',"Ressources"],
    Accounting : ['Accounting','会计',"Comptabilité"],
    Welcome : ['Welcome','欢迎',"Bienvenue"],
    Meetings : ['Meetings','会议',"Réunions"],
    HR : ['HR','人力资源',"RH"],
    Vendors : ['Vendors','经销商',"Fournisseurs"],
    Services : ['Services','服务',"Services"],
    NotAvailable : ['Not Available','无',"Indisponible"],
    Nothingtoshow : ['Nothing to show','没有项目显示',"Rien à montrer"],
    Companies : ['Companies','全部公司',"Entreprises"]
}


//company configuration
export const CompanyLang = {
    EditCompany : ['Edit Company','修改公司',"Modifier l'Entreprise"],
    AddNewCompany: ['Add New Company','添加新公司',"Ajouter une Nouvelle Entreprise"],
    COMPANYNAME : ['COMPANY NAME','公司名称',"NOM DE L'ENTREPRISE"],
    NAMEABBREVIATION : ['NAME ABBREVIATION','公司简称',"ABRÉVIATION DU NOM"],
    HEADOFFICE : ['HEAD OFFICE','总部',"SIÈGE SOCIAL"],
    Nositesfound : ['No sites found','欢迎',"Aucun site trouvé"],
    ADDRESS : ['ADDRESS','地址',"ADRESSE"],
    CITY : ['CITY','市',"VILLE"],
    PROVINCE : ['PROVINCE','省',"PROVINCE"],
    POSTALCODE : ['POSTALCODE','邮政编码',"CODE POSTAL"],
    MAINPHONENUMBER : ['MAIN PHONE NUMBER','主要电话',"Indisponible"],
    FAXNUMBER : ['FAX NUMBER','传真',"NUMÉRO DE FAX"],
    ACTIVEDIRECTORYDOMAIN : ['ACTIVE DIRECTORY DOMAIN','域名',"DOMAINE ACTIVE DIRECTORY"],
    NOTES: ['NOTES','备注',"NOTES"],
    HoursOfOperation : ['Hours of operation','运营时间',"Heures d'ouverture"],
    TAG : ['TAG','标签',"TAG"],
    Notagsfound : ['No tags found','没发现标签',"Aucun tag trouvé"],
    INDUSTRYTYPE : ['INDUSTRY TYPE','领域',"TYPE D'INDUSTRIE"],
    NONE : ['NONE','未选择',"Aucun"],
    Noindustrytypesfound : ['No industry types found','没有领域显示',"Aucun type d'industrie trouvé"],
    STATUS : ['STATUS','没有项目显示',"Rien à montrer"],
    Active : ['Active','活跃',"Actif"],
    Disabled : ['Disabled','禁止',"Désactivé"],
    LOGO : ['LOGO','头像',"LOGO"],
    CompanyPayment : ['Company Payment (Optional)','公司付款设置 (可选)',"Paiement de l'Entreprise (Optionnel)"],
    VAULTID : ['VAULT ID','保管库 ID',"ID DU COFFRE"],
    CARDNUMBER : ['CARD NUMBER','卡号',"NUMÉRO DE CARTE"],
    EXPIRATION : ["EXPIRATION",'过期时间',"EXPIRATION"],
    PAYMENTCHARGEDAY : ['PAYMENT CHARGE DAY','付款时间',"JOUR DE FACTURATION"],
    PAYMENTTYPE : ["PAYMENT TYPE",'付款方式',"TYPE DE PAIEMENT"],
    Recurring : ['Recurring','循环',"Récurrent"],
    Manual : ['Manual','手动',"Manuel"],
    PAYMENTGATEWAYTYPE : ['PAYMENT GATEWAY TYPE','付款平台',"TYPE DE PASSERELLE DE PAIEMENT"],
    PAYMENTFREQUENCYTYPE : ['PAYMENT FREQUENCY TYPE','付款周期',"TYPE DE FRÉQUENCE DE PAIEMENT"],
    Monthly : ['Monthly','每月',"Mensuel"],
    Annually : ['Annually','每年',"Annuel"],
    Save : ['Save','保存',"Sauvegarder"],
    Cancel : ['Cancel','取消',"Annuler"],
    on : ['Click to turn off sync','单击开启同步',"Cliquez pour activer la synchronisation"],
    off : ['Click to turn on sync','单击取消同步',"Cliquez pour désactiver la synchronisation"],
    WEBSITE : ['WEBSITE','网址',"SITE WEB"]
}