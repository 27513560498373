import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Logo from "../../../Images/logo.png";
import useStyles from "./style";
import { setUser } from "../../../redux-tools/actions/userActions";
import { connect } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import http from "../../Common/RestAPIHandler";
import auth from "../../../services/authService";
import Alert from "@material-ui/lab/Alert";
import commonConstants from "../../Common/CommonConstants";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const EmployeeLogin = (props) => {
  const classes = useStyles();
  const [employee, setEmployee] = useState({ email: "", password: "" });
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [locked, setLocked] = useState(false);
  const [lockoutTime, setLockoutTime] = useState("");
  const [invalid, setInvalid] = useState(false);
  const errorText = commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const resetAll = () => {
    setInvalid(false);
    setLocked(false);
    setInvalidUser(false);
    setInvalidPassword(false);
    setAccessDenied(false);
  };

  const onInputChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
    resetAll();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetAll();
    setIsSubmitted(true);
    http.Post(
      "employee/login/",
      employee,
      (response) => {
        props.setUser(response);
        auth.saveToken(response.access_token);
        localStorage.setItem("date_time_format", response.date_time_format);
        localStorage.setItem("date_format", response.date_format);
        if(response.authy_verified){
          props.history.replace(process.env.PUBLIC_URL + "/portal/credentials");
        }
        else{
          props.history.replace(process.env.PUBLIC_URL + "/portal/twoFA");
        }
      },
      (status, error) => {
        if (status === 404) {
          setInvalidUser(true);
        } else if (status === 400) {
          if (error.invalid_email_password) {
            http.Post(
              "user_session/lock/?user_type=Company Employee",
              employee,
              () => {},
              () => {}
            );
            setInvalidPassword(true);
          } else {
            setInvalid(true);
          }
        } else if (status === 403) {
          setAccessDenied(true);
        } else if (status === 423) {
          let date = new Date(error.lockout_to);
          let now = new Date();
          setLockoutTime(Math.floor(((date-now)/1000)/60));
          setLocked(true);
        } else {
          setInvalid(true);
        }
        setIsSubmitted(false);
      }
    );
  };

  return (
    <div>
      <div className={classes.main}>
        <div className={classes.root}>
          <ValidatorForm onSubmit={onSubmit}>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justify="center"
              className={classes.container}
            >
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.centerElement}>
                    <Typography variant="h6" color="inherit" noWrap>
                      <img
                        src={Logo}
                        alt="Not Available"
                        className={classes.logo}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.centerElement}>
                    <Typography
                      variant="h5"
                      component="h4"
                      color="textSecondary"
                    >
                      Portal Login
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      id="outlined-email-input"
                      label="E-mail"
                      type="text"
                      name="email"
                      variant="outlined"
                      fullWidth
                      value={employee.email || ""}
                      onChange={onInputChange}
                      validators={["required", "trim", "isEmail"]}
                      errorMessages={[
                        errorText.requiredError,
                        errorText.blankError,
                        errorText.invalidEmailError,
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      id="outlined-password-input"
                      label="Password"
                      type="password"
                      name="password"
                      variant="outlined"
                      fullWidth
                      inputProps={{ style: { fontWeight: "bold" } }}
                      value={employee.password || ""}
                      onChange={onInputChange}
                      validators={["required"]}
                      errorMessages={[errorText.requiredError]}
                    />
                  </Grid>

                  {(invalidPassword || invalidUser) && (
                    <Grid item xs={12}>
                      <Alert severity="error" variant="filled">
                        Username or password incorrect!
                      </Alert>
                    </Grid>
                  )}

                  {accessDenied && (
                    <Grid item xs={12}>
                      <Alert severity="error" variant="filled">
                        Portal role not assigned to user. Please have tenant assign portal role.
                      </Alert>
                    </Grid>
                  )}

                  {locked && (
                    <Grid item xs={12}>
                      <Alert severity="error" variant="filled">
                        Your account is temporarily locked for {lockoutTime} minutes due to multiple failed login attempts
                        .
                      </Alert>
                    </Grid>
                  )}


                  {invalid && (
                    <Grid item xs={12}>
                      <Alert severity="error" variant="filled">
                        Unknown error. Please try again later.
                      </Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Link
                          component={RouterLink}
                          to={process.env.PUBLIC_URL + "/forgetPassword?userType=Company Employee"}
                          color="textPrimary"
                        >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item xs={6} className={classes.rightElement}>
                        <Button
                          type="submit"
                          className={classes.outlinedButton}
                          variant="outlined"
                          disabled={isSubmitted}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { setUser })(EmployeeLogin);
