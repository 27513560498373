import React, { useEffect, useState } from "react";
import ObjectList from "../../../Common/ObjectList";
import ObjectListMobile from "../../../Common/ObjectListMobile";
import commonConstants from "../../../Common/CommonConstants";
import http from "../../../Common/RestAPIHandler";
import JobPostingForm from "./JobPostingForm";
import { formatDateTime } from "../../../Common/Utility";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import {jobLang} from "../../../Common/Languages";

const JobPostings = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `hr_job_postings`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const lang = localStorage.getItem("languages");

  const tableColumns = [
    {
      id: "title",
      label: jobLang.TITLE[lang],
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : (props, item, column_id)=>{
        props.history.push(
          process.env.PUBLIC_URL + `jobPostings/${item.id}/details`
        );
      }
    },
    { 
      id: "download", 
      label: "", 
      minWidth: 10, 
      colored: true, 
      style: commonConstants.cellCursor 
    },
    {
      id: "last_modified_time",
      label: jobLang.LASTMODIFIED[lang],
      minWidth: 175,
      sortable: true,
    },
    {
      id: "created_by_name",
      label: jobLang.BY[lang],
      minWidth: 150,
      sortable: true,
    },
  ];


  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((item) => {
          item.last_modified_time = formatDateTime(item.last_modified_time);
          item.download = 
          <SaveAltIcon 
            onClick={() => {        
              var divToPDF = window.document.getElementById(item.id);
              html2canvas(divToPDF)
                .then((canvas) => {
                  var imgData = canvas.toDataURL('image/png');
                  var imgWidth = 210; 
                  var pageHeight = 292;
                  var imgHeight = canvas.height * imgWidth / canvas.width;
                  var heightLeft = imgHeight;
                  var doc = new jsPDF('p', 'mm');
                  var position = 0;
                  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                  heightLeft -= pageHeight;
                  
                  while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                  }
                  doc.save(item.id+".pdf");
                })
              ;
            }}/>;
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {data && data.map((jobPosting)=>{
        return <div id={jobPosting.id} style={{position:"absolute",left:"-8000px",width:"780px",padding:"30px"}} dangerouslySetInnerHTML={{ __html: jobPosting.content }}/>
      })}
      {!props.mobile && data && (
        <ObjectList
          {...props}
          moduleName={jobLang.JobPostings[lang]}
          url={url}
          templateUrl={"hr_job_posting_templates"}
          actions={actions}
          objectForm={JobPostingForm}
          permissions={{
            create: ["Edit Job Postings"],
            edit: ["Edit Job Postings"],
            delete: ["Edit Job Postings"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title"]}
          pageHeader={"Job Postings"}
          loadData={loadData}
        />
      )}
      {props.mobile && data && (
        <ObjectListMobile
          {...props}
          moduleName={"Job Postings"}
          url={url}
          templateUrl={"public_templates?template_type_name=Job Posting"}
          actions={actions}
          objectForm={JobPostingForm}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title"]}
          pageHeader={"Job Postings"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

export default JobPostings;
