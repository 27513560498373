import React, { useEffect, useState } from "react";
import ResellerRoleForm from "./ResellerRoleForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";

const redirect = (props, object, property) => {
  if (!object.rp_disabled) props.history.push("/reseller/reseller_roles/" + object.id);
  else alert("Role status is disabled.");
};

const tableColumns = [
  {
    id: "role_name",
    label: "ROLE NAME",
    minWidth: 150,
    sortable: true,
    style: commonConstants.cellCursor,
    colored: true,
    onClick: redirect,
  },
  {
    id: "description",
    label: "ROLE DESCRIPTION",
    minWidth: 220,
    sortable: true,
  },
  { id: "created_by_name", label: "CREATED BY", minWidth: 150, sortable: true },
  { id: "status", label: "STATUS", minWidth: 100, sortable: true },
];

const ResellerRoleList = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `reseller_roles`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.rp_disabled = !item.status),
            (item.status = item.status ? "Active" : "Disabled")
          )
        );
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Reseller Roles"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Reseller Users"],
        edit: ["Create/Modify Reseller Users"],
        delete: ["Delete Reseller Users"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={ResellerRoleForm}
      orderBy={"role_name"}
      searchFields={["role_name"]}
      pageHeader={"Reseller Roles"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Reseller Roles"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Reseller Users"],
        edit: ["Create/Modify Reseller Users"],
        delete: ["Delete Reseller Users"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={ResellerRoleForm}
      orderBy={"role_name"}
      searchFields={["role_name"]}
      pageHeader={"Reseller Roles"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default ResellerRoleList;
