const tokenName = "token";

const isAuthorized = props => {
  return localStorage.getItem(tokenName) && Object.keys(props.user).length > 0;
};

const saveToken = token => localStorage.setItem(tokenName, token);

const getToken = () => {
  return localStorage.getItem(tokenName);
};

const deleteToken = () => localStorage.removeItem(tokenName);

export default {
  isAuthorized,
  saveToken,
  getToken,
  deleteToken
};
