import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { getColorScheme } from "../Common/Utility";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    color: theme.colorScheme,
  },
  spinner: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  },
}));

const ScreenLoader = (props) => {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className={classes.spinner}>
        <Loader type="ThreeDots" color={getColorScheme()} />
      </div>
    )
  );
};

export default ScreenLoader;
