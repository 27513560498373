import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";

const ResellerForm = props => {
  const [formFields, setFormFields] = useState();

  useEffect(() => {
    setFormFields([
      {
        id: "name",
        label: "NAME *",
        type: "text"
      },
      {
        id: "payment_gateway",
        label: "Payment Gateway",
        type: "select",
        valueMap: {"Stripe":"Stripe","Moneris":"Moneris"}
      },
      {
        id: "is_default",
        label: "Is Default",
        type: "radio"
      },
      {
        id: "stripe_api_key",
        label: "Stripe Api Key",
        type: "text"
      },
      {
        id: "moneris_api_token",
        label: "Moneris Api Token",
        type: "text"
      },
      {
        id: "moneris_server_url",
        label: "Moneris Server Url",
        type: "text"
      },
      {
        id: "moneris_store_id",
        label: "Moneris Store Id",
        type: "text"
      },
      {
        id: "is_authy",
        label: "AUTHY 2FA",
        type: "radio"
      },
      {
        id: "is_sms",
        label: "SMS 2FA",
        type: "radio"
      },
      {
        id: "is_email",
        label: "EMAIL 2FA",
        type: "radio"
      },
      {
        id: "tenant_billing_enabled",
        label: "TENANT BILLING ENABLED",
        type: "radio"
      },
      {
        id: "address",
        label: "Address",
        type: "text"
      },
      {
        id: "city",
        label: "CITY",
        type: "text"
      },
      {
        id: "state",
        label: "PROVINCE",
        type: "text"
      },
      {
        id: "country",
        label: "COUNTRY",
        type: "text"
      },
      {
        id: "postal",
        label: "POSTAL",
        type: "text"
      },
    ]);
  }, []);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`resellers`}
          formName={"Resellers"}
          formFields={formFields}
          object={{}}
        />
      )}
    </div>
  );
};

export default ResellerForm;
