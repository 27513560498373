import React from "react";
import Layout from "./Layout/Layout";
import { connect } from "react-redux";
import auth from "../../services/authService";
import { Redirect } from "react-router-dom";

const Page = (props) => {
	if (auth.isAuthorized(props)) {
		if (props.user && props.user.user_type == "Tenant User") {
			return (
				<Layout {...props}/>
			);
		} else {
			return <Redirect to={process.env.PUBLIC_URL + "/404"} />;
		}
	}
	return <Redirect to={process.env.PUBLIC_URL + "/login"} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(Page);
