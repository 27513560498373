import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../Common/Style/FormStyle";
import MenuItem from "@material-ui/core/MenuItem";
import CommonConstants from "../../Common/CommonConstants";
import ScreenLoader from "../../Common/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "react-select";
import { getColorScheme, getColorSchemeLight } from "../../Common/Utility";
import {AccountingLang} from "../../Common/Languages";

const customStyles = {
  control: (base, state) => ({
    ...base,
    // Overwrittes the different states of border
    borderColor: "rgba(0, 0, 0, 0.23)",
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? getColorScheme() : "rgba(0, 0, 0, 0.87)",
    },
    // Removes weird border around container
    boxShadow: state.isFocused ? "0 0 0 1px " + getColorScheme() : null,
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  option: (base, state) => ({
    ...base,
    "&:active": {
      background: getColorScheme(),
    },
    // checking state of the menu items, render color accordingly
    background: state.isFocused
      ? getColorSchemeLight()
      : state.isSelected
      ? getColorScheme()
      : state.isActive
      ? getColorSchemeLight()
      : "",
    // state.isActive: getColorSchemeLight()
  }),
};

const AccountingForm = (props) => {
  const classes = useStyles();
  const [account, setAccount] = useState({});
  const [accountError, setAccountError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isChargeDayInvalid, setIsChargeDayInvalid] = useState(false);
  const [isExpirationInvalid, setIsExpirationInvalid] = useState(false);
  const errorText = CommonConstants.error;
  const [loadData, setLoadData] = useState(false);

  const lang = localStorage.getItem("languages");

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `company_payment_infos/${props.objectId}`,
        (account) => {
          account.payment_charge_day = account.payment_charge_day.toString();
          account.expiration = account.expiration.toString();
          setAccount(account);
          http.Get(
            `company_payment_infos`,
            (data) => {
              var paymentInfos = {};
              for (let i = 0; i < data.length; i++) {
                if (data[i].company_id !== account.company_id) {
                  paymentInfos[data[i].company_id] = data[i].company_name;
                }
              }
              http.Get(
                `companies`,
                (data) => {
                  var valueMap = [];
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].cw_company_id && !paymentInfos[data[i].id]) {
                      valueMap.push({
                        value: data[i].id,
                        label: data[i].company_name,
                      });
                    }
                  }
                  setCompanies(valueMap);
                  setLoadData(false);
                },
                () => {
                  setLoadData(false);
                }
              );
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    } else {
      setLoadData(false);
      http.Get(
        `company_payment_infos`,
        (data) => {
          var paymentInfos = {};
          for (let i = 0; i < data.length; i++) {
            paymentInfos[data[i].company_id] = data[i].company_name;
          }
          http.Get(
            `accounting_companies`,
            (data) => {
              var valueMap = [];
              for (let i = 0; i < data.length; i++) {
                if (
                  (data[i].cw_company_id || data[i].autotask_company_id) 
                  && !paymentInfos[data[i].id]
                ) {
                  valueMap.push({
                    value: data[i].id,
                    label: data[i].company_name,
                  });
                }
              }
              setCompanies(valueMap);
              setLoadData(false);
            },
            () => {
              setLoadData(false);
            }
          );
        },
        () => {
          setLoadData(false);
        }
      );
    }
  }, []);

  const onChange = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
    setAccountError({
      ...accountError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    if (
      !account.expiration ||
      account.expiration % 100 > 12 ||
      account.expiration % 100 < 1
    ) {
      setIsExpirationInvalid(true);
      return;
    }

    if (
      !account.payment_charge_day ||
      account.payment_charge_day < 1 ||
      account.payment_charge_day > 31
    ) {
      setIsChargeDayInvalid(true);
      return;
    }

    setIsSubmitted(true);

    if (props.edit && props.objectId) {
      http.Put(
        `company_payment_infos/${props.objectId}`,
        account,
        () => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setAccountError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `company_payment_infos`,
        account,
        () => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setAccountError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
            >
              {props.edit ? "Edit Account" : "Add Account"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>{AccountingLang.COMPANY[lang]} *</p>
              <Select
                id="company_id"
                name="company_id"
                options={companies}
                onChange={(e) => {
                  setAccount({
                    ...account,
                    ["company_id"]: e.value,
                  });
                  setAccountError({
                    ...accountError,
                    ["company_id"]: false,
                  });
                  setIsSubmitted(false);
                }}
                styles={customStyles}
                defaultValue={{
                  value: account.company_id,
                  label: account.company_name,
                }}
                // className={accountError.company_id ? classes.select2Red : classes.select2}
              />
              {/* <Select
									id={"category"}
									name={"category"}
									value={account.company_id || "Placeholder"}
									error={errors && errors.company_id}
									onChange={(e) => {
                    setAccount({
                      ...account,
                      ["company_id"]: e.value,
                    });
                    setAccountError({
                      ...accountError,
                      ["company_id"]: false,
                    });
                    setIsSubmitted(false);
                  }}
									input={<InputBase />}
									// inputProps={classes.input}
									style={{
										border: "0px",
										width: "195px",
										borderBottom: "1px solid black",
										outline: "none",
									}}
								>
									<MenuItem disabled value="Placeholder">
										Select Company
									</MenuItem>
									{Object.entries(companies).map(([key, value]) => (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									))}
								</Select>
								{errors.category ? (
									<FormHelperText className={classes.error}>
										{errors.category}
									</FormHelperText>
								) : (
									<>&nbsp;</>
								)} */}
              <p className={classes.error}>{accountError.company_id}</p>
              <p className={classes.label}>{AccountingLang.VAULTID[lang]}</p>
              <TextValidator
                id="vault_id"
                fullWidth
                variant="outlined"
                margin="dense"
                name="vault_id"
                value={account.vault_id || ""}
                onChange={onChange}
                inputProps={{ maxLength: 25 }}
                validators={["maxStringLength:25"]}
                errorMessages={["Thsi field can not exceed 25 characters."]}
                error={Boolean(accountError.vault_id)}
                helperText={accountError.vault_id && accountError.vault_id[0]}
              />
              <p className={classes.label}>{AccountingLang.CARDNUMBER[lang]}</p>
              <TextValidator
                id="card_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="card_number"
                value={account.card_number || ""}
                onChange={onChange}
                inputProps={{ maxLength: 19 }}
                error={Boolean(accountError.card_number)}
                helperText={
                  accountError.card_number && accountError.card_number[0]
                }
              />
              <p className={classes.label}>{AccountingLang.EXPIRATION[lang]} (YYMM) *</p>
              <TextValidator
                id="expiration"
                fullWidth
                variant="outlined"
                margin="dense"
                name="expiration"
                value={account.expiration || ""}
                onChange={onChange}
                inputProps={{ maxLength: 4 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                validators={["required"]}
                errorMessages={[errorText.requiredError]}
                error={
                  Boolean(accountError.expiration) ||
                  (isExpirationInvalid &&
                    account.expiration &&
                    (account.expiration % 100 > 12 ||
                      account.expiration % 100 < 1 ||
                      account.expiration.length != 4))
                }
                helperText={
                  (accountError.expiration && accountError.expiration[0]) ||
                  (isExpirationInvalid &&
                    account.expiration &&
                    (account.expiration % 100 > 12 ||
                      account.expiration % 100 < 1 ||
                      account.expiration.length != 4) &&
                    "Insert proper format YYMM.")
                }
              />
              <p className={classes.label}>{AccountingLang.PAYMENTCHARGEDAY[lang]} (DD) *</p>
              <TextValidator
                id="payment_charge_day"
                fullWidth
                variant="outlined"
                margin="dense"
                name="payment_charge_day"
                value={account.payment_charge_day || ""}
                onChange={onChange}
                inputProps={{ maxLength: 2 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                validators={["required"]}
                errorMessages={[errorText.requiredError]}
                error={
                  Boolean(accountError.payment_charge_day) ||
                  (isChargeDayInvalid &&
                    account.payment_charge_day &&
                    account.payment_charge_day.length != 2) ||
                  (isChargeDayInvalid &&
                    account.payment_charge_day &&
                    (account.payment_charge_day < 1 ||
                      account.payment_charge_day > 31))
                }
                helperText={
                  (accountError.payment_charge_day &&
                    accountError.payment_charge_day[0]) ||
                  (account.payment_charge_day &&
                    account.payment_charge_day.length != 2 && (
                      <span style={{ color: "#c43030", fontSize: "1rem" }}>
                        {AccountingLang.helper1[lang]}
                      </span>
                    )) ||
                  (isChargeDayInvalid &&
                    account.payment_charge_day &&
                    (account.payment_charge_day < 1 ||
                      account.payment_charge_day > 31) && (
                      <span style={{ color: "#c43030", fontSize: "1rem" }}>
                        {AccountingLang.helper2[lang]}
                      </span>
                    ))
                }
              />
              <p className={classes.label}>{AccountingLang.PAYMENTTYPE[lang]} *</p>
              <SelectValidator
                id="payment_type"
                fullWidth
                variant="outlined"
                margin="dense"
                name="payment_type"
                value={account["payment_type"] || ""}
                onChange={onChange}
                validators={["required"]}
                errorMessages={[errorText.requiredError]}
                error={Boolean(accountError.payment_type)}
                helperText={
                  accountError.payment_type && accountError.payment_type[0]
                }
              >
                <MenuItem value="Recurring">{AccountingLang.Recurring[lang]}</MenuItem>
                <MenuItem value="Manual">{AccountingLang.Manual[lang]}</MenuItem>
              </SelectValidator>
              <p className={classes.label}>{AccountingLang.PAYMENTGATEWAYTYPE[lang]} *</p>
              <SelectValidator
                id="payment_gateway_type"
                fullWidth
                variant="outlined"
                margin="dense"
                name="payment_gateway_type"
                value={account["payment_gateway_type"] || ""}
                onChange={onChange}
                validators={["required"]}
                errorMessages={[errorText.requiredError]}
                error={Boolean(accountError.payment_gateway_type)}
                helperText={
                  accountError.payment_gateway_type &&
                  accountError.payment_gateway_type[0]
                }
              >
                <MenuItem value="Moneris">Moneris</MenuItem>
                <MenuItem value="MSI">MSI</MenuItem>
              </SelectValidator>
              <p className={classes.label}>{AccountingLang.PAYMENTFREQUENCYTYPE[lang]} *</p>
              <SelectValidator
                id="payment_frequency_type"
                fullWidth
                variant="outlined"
                margin="dense"
                name="payment_frequency_type"
                value={account["payment_frequency_type"] || ""}
                onChange={onChange}
                validators={["required"]}
                errorMessages={[errorText.requiredError]}
                error={Boolean(accountError.payment_frequency_type)}
                helperText={
                  accountError.payment_frequency_type &&
                  accountError.payment_frequency_type[0]
                }
              >
                <MenuItem value="Monthly">{AccountingLang.Monthly[lang]}</MenuItem>
                <MenuItem value="Annually">{AccountingLang.Annually[lang]}</MenuItem>
              </SelectValidator>
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              {AccountingLang.Cancel[lang]}
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              {AccountingLang.Save[lang]}
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AccountingForm;
