import { format } from "date-fns-tz";
import {
  differenceInCalendarDays,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import { useState, useEffect } from "react";
import http from "./RestAPIHandler";
import * as msal from "@azure/msal-browser";
// import * as msal from "@azure/msal-node";

const SHA1 = require("js-sha1");
const crypto = require("crypto");

const desc = (a, b, orderBy) => {
  let va = a[orderBy] === null ? "" : "" + a[orderBy];
  let vb = b[orderBy] === null ? "" : "" + b[orderBy];

  return va.toLowerCase() > vb.toLowerCase()
    ? 1
    : va.toLowerCase() === vb.toLowerCase()
    ? 0
    : -1;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => -desc(a, b, orderBy)
    : (a, b) => desc(a, b, orderBy);
};

export const ConvertDateToLocale = (utcDate) => {
  var localDate = new Date(utcDate);
  return localDate;
};

export const formatDate = (utcDate) => {
  try {
    if (utcDate) {
      if (utcDate.includes("T")) {
        var localDate = format(
          new Date(utcDate),
          localStorage.getItem("date_format")
        );
        return localDate;
      } else {
        var localDate = format(
          new Date(
            new Date(utcDate).getTime() +
              new Date(utcDate).getTimezoneOffset() * 60 * 1000
          ),
          localStorage.getItem("date_format")
        );
        return localDate;
      }
    } else {
      return utcDate;
    }
  } catch {
    return utcDate;
  }
};

export const formatDateTime = (utcDate) => {
  try {
    if (utcDate.includes("T")) {
      var localDate = format(
        new Date(utcDate),
        localStorage.getItem("date_time_format")
      );
      return localDate;
    } else {
      var localDate = format(
        new Date(
          new Date(utcDate).getTime() +
            new Date(utcDate).getTimezoneOffset() * 60 * 1000
        ),
        localStorage.getItem("date_time_format")
      );
      return localDate;
    }
  } catch {
    return utcDate;
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] || match[1] === undefined ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      // height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

export const isDarkEnabled = () => {
  const savedMode = JSON.parse(localStorage.getItem("isDarkEnabled"));
  return savedMode || false;
};

export const getColorScheme = () => {
  const savedMode = JSON.parse(localStorage.getItem("colorScheme"));
  if (savedMode == "Orange") return "#d4602f";
  else return "#1a84db";
};

export const getColorSchemeLight = () => {
  const savedMode = JSON.parse(localStorage.getItem("colorScheme"));
  if (savedMode == "Orange"){
		 return isDarkEnabled() ? "#bf562a" : "#d87044";
	} else {
		return isDarkEnabled() ? "#1777c5" : "#3190df";

	} 
};

export const daysUntilThreeMonths = (day) => {
  const today = new Date();
  var date = new Date(day);
  date = addMonths(date, 3);
  return differenceInCalendarDays(date, today);
};

export const daysUntilAnniversary = (day) => {
  const today = new Date();
  var date = new Date(day);
  if (date >= today || isSameDay(date, today)) {
    date = addYears(date, 1);
    return differenceInCalendarDays(date, today);
  }

  date.setFullYear(today.getFullYear());
  if (
    date.getMonth() < today.getMonth() ||
    (date.getMonth() === today.getMonth() && date.getDate() < today.getDate())
  ) {
    date = addYears(date, 1);
  }
  return differenceInCalendarDays(date, today);
};

export const HTMLParse = (string) => {
  let domParser = new DOMParser();
  let parsed = domParser.parseFromString(string, "text/html");
  return parsed.lastElementChild.innerText;
};

export const alphaArray = () => {
  return Array.apply(null, { length: 26 }).map((x, i) =>
    String.fromCharCode(65 + i)
  ); // 97 for all lowercase
};

export const siteSelected = (company, site) => {
  const url = `companies/${company}`;
  let selectedSite = http.Get(url, (data) => {
    console.log("Expecting " + site);
    return data.site_id;
  });

  return selectedSite == site;
};

export const isInit = () => {
  const url = window.location.href;
  // console.log(url.includes('tenantInit'));
  if (url.includes("register")) {
    return true;
  } else {
    return false;
  }
};

// Function for checking password safety
// let unsafePassword = async (password) => {
// 	// Hash password to be sent to Database
// 	const hash = SHA1(password);
// 	const snippet = hash.slice(0, 5);

// 	// Call HIBP passwords to check incoming password hash
// 	fetch(`https://api.pwnedpasswords.com/range/${snippet}`)
// 		.then(function (response) {
// 			if (response.status !== 200 && response.status !== 0) {
// 				console.log(
// 					"Looks like there was a problem. Status Code: " + response.status
// 				);
// 				return;
// 			}

// 			// Examine the text in the response
// 			response.text().then((data) => {
// 				if (data.length >= 1) {

// 					// Split into array at the return spaces
// 					let arr = data.split(/\s+/);

// 					// Loop through the resulting array, slice off the end at colon
// 					for (let index in arr) {
// 						arr[index] = arr[index].slice(0, arr[index].indexOf(":"));

// 						// Add hash prefix snippet to the returned hash string and compare password hash
// 						if ((snippet + arr[index]).toLowerCase() == hash) {
// 							console.log('found, unsafe', arr[index])
// 							return true;
// 						}
// 					}

// 					// If not found in the list of hashes, return false
// 					console.log('not-found, safe', hash)
// 					return false;

// 				} else {
// 					// If API returned no results, return false
// 					console.log('not-found, safe', hash)
// 					return false;
// 				}
// 			});
// 		})
// 		.catch(function (error) {
// 			console.log("Fetch Error :-S", error);
// 		});

// };

// // Hand off incoming password to our safePassword utility function for validation
// // If password is un-safe, return False
// export let validPass = async (userObj) => {
// 	console.log(userObj.username);
// 	// Deconstruct incoming user object
// 	const {password, username} = userObj;

// 	// Check for empty values in object before continuing
// 	if(username == undefined || password == undefined || username == '' || password == '') {
// 		console.log(username, password);
// 		// Allow POST req to go through to hit back-end validation if either are blank
// 		return {res: true, error: null};
// 	}

// 	const passwd = await unsafePassword(password);
// 	const result = Promise.resolve(passwd);
// 	console.log(result);
// 	if (result == true) {
// 		const error = {
// 			'password': 'Unsafe! Submit again to use anyway.',
// 			'tooltip': 'Password hash was found in a database of compromised passwords!'
// 		};
// 		return {res: null, error: error};
// 	} else {
// 		return {res: true, error: null};
// 	}
// };

export const validPassword = async (userObj) => {
  // Check for empty values in object before continuing
  if (
    username == undefined ||
    password == undefined ||
    username == "" ||
    password == ""
  ) {
    // Allow POST req to go through to hit back-end validation if either are blank
    return { res: true, error: null };
  }

  const { password, username } = userObj;
  // Hash password to be sent to Database
  const hash = SHA1(password);
  const snippet = hash.slice(0, 5);

  const filterData = (data) => {
    if (data.length >= 1) {
      // Split into array at the return spaces
      let arr = data.split(/\s+/);

      // Loop through the resulting array, slice off the end at colon
      for (let index in arr) {
        arr[index] = arr[index].slice(0, arr[index].indexOf(":"));

        // Add hash prefix snippet to the returned hash string and compare password hash
        if ((snippet + arr[index]).toLowerCase() == hash) {
          console.log("found, unsafe", arr[index]);
          return true;
        }
      }

      // If not found in the list of hashes, return false
      console.log("not-found, safe", hash);
      return false;
    } else {
      // If API returned no results, return false
      console.log("not-found, safe", hash);
      return false;
    }
  };

  // Call HIBP passwords to check incoming password hash
  const response = await fetch(
    `https://api.pwnedpasswords.com/range/${snippet}`
  );
  const data = await response.text();
  const filteredData = filterData(data);
  console.log(filteredData);
  if (filteredData === true) {
    const error = {
      password: "Unsafe! Submit again to use anyway.",
      tooltip:
        "Password hash was found in a database of compromised passwords!",
    };
    return { res: null, error: error };
  } else {
    return { res: true, error: null };
  }
};

// Function for checking whitelisted addresses (not used currently)
export const whiteList = (address) => {
  const savedAddresses = ["https://api.pwnedpasswords.com/range/"];

  for (let each of savedAddresses) {
    if (address.includes(each)) {
      console.log("address whitelisted!", address);
      return true;
    }
  }

  return false;
};
