import React, { useEffect, useState } from "react";
import ObjectList from "../../../Common/ObjectList";
import ObjectListMobile from "../../../Common/ObjectListMobile";
import http from "../../../Common/RestAPIHandler";
import EmployeeForm from "./EmployeeForm";
import EmployeeFormMobile from "./EmployeeFormMobile";
import {
  formatDate,
  daysUntilThreeMonths,
  daysUntilAnniversary,
} from "../../../Common/Utility";
import CakeIcon from "@material-ui/icons/Cake";
import EventIcon from "@material-ui/icons/Event";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { hasPermission } from "../../../Common/permission";
import CreateIcon from "@material-ui/icons/Create";
import commonConstants from "../../../Common/CommonConstants";
import { Dialog, Link } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import ScreenLoader from "../../../Common/Loader";
import {HRemployee} from "../../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 8,
    backgroundColor: theme.colorScheme,
    color: "white",
    marginRight: "2px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  chipAddNew: {
    float: "right",
    backgroundColor: theme.colorScheme,
    marginTop: "5px",
    marginRight: "-14px",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  iconColorScheme: {
    color: theme.colorScheme + " !important",
  },
  iconDisabled: {
    color: theme.subtext,
  },
}));

const Employees = (props) => {
  const classes = useStyles();
  const hrProfilesUrl = `hr_employees/`;
  const tenantUsersUrl = `tenant_users`;
  const [hrToggle, setHRToggle] = useState(true);
  const [hrProfiles, setHRProfiles] = useState([]);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [userLink, setUserLink] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [objectId, setObjectId] = useState(null);
  const [calendar, setCalendar] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [loadData, setLoadData] = useState(false);

  const lang = localStorage.getItem("languages");

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    additionalActions: [
      {
        label: HRemployee.Calendar[lang],
        onClick: (ids) => {
          setShowCalendar(true);
        },
      },
    ],
  };

  const tableActions = (target) => {
    return !target.user ? ( // If HR profile show edit
      <CreateIcon
        onClick={() => {
          setEdit(true);
          setObjectId(target.id);

          setShowForm(true);
        }}
      />
    ) : target.hr_profile ? ( // Show add if user has no HR profile
      <></>
    ) : (
      <AddIcon
        onClick={() => {
          setEdit(true);
          setObjectId(target.id);
          setShowForm(true);
          setUserLink(true);
        }}
      />
    );
  };

  const hrLink = (target) => {
    return target.hr_profile || target.tenant_user ? (
      <LinkIcon className={classes.iconColorScheme} />
    ) : (
      <LinkOffIcon className={classes.iconDisabled} />
    );
  };

  const dataHandler = () => {
    return hrToggle ? hrProfiles : tenantUsers;
  };

  const handleToggle = () => {
    setHRToggle(!hrToggle);
  };

  const tableColumns = [
    {
      id: "first_name",
      label: HRemployee.FIRSTNAME[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "last_name",
      label: HRemployee.LASTNAME[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "designation",
      label: HRemployee.TITLE[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "joining_date",
      label: HRemployee.STARTDATE[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "joining_date_icon",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "birth_date",
      label: HRemployee.BIRTHDAY[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "birth_date_icon",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "status",
      label: HRemployee.STATUS[lang],
      minWidth: 100,
      sortable: true,
    },
    {
      id: "link",
      label: HRemployee.USERLINK[lang],
      minWidth: 100,
      sortable: false,
    },
    {
      id: "actions",
      label: "",
      minWidth: 100,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
    },
  ];

  const isShowIcon = (item, column_id) => {
    var daysCount = -1;
    if (column_id === "birth_date" && item[column_id]) {
      daysCount = daysUntilAnniversary(item[column_id]);
    } else if (column_id === "joining_date" && item[column_id]) {
      daysCount = daysUntilThreeMonths(item[column_id]);
      if (!(daysCount >= 0 && daysCount <= 7)) {
        daysCount = daysUntilAnniversary(item[column_id]);
      }
    }

    if (daysCount >= 0 && daysCount <= 7) {
      return true;
    } else {
      return false;
    }
  };

  const getIconText = (item, column_id) => {
    var iconText = "";
    var daysCount = -1;

    if (column_id === "birth_date" && item[column_id]) {
      daysCount = daysUntilAnniversary(item[column_id]);
      if (daysCount >= 0 && daysCount <= 7) {
        iconText = HRemployee.Birthday[lang];
      }
    } else if (column_id === "joining_date" && item[column_id]) {
      daysCount = daysUntilThreeMonths(item[column_id]);
      if (daysCount >= 0 && daysCount <= 7) {
        iconText = HRemployee.monthscompletion[lang];
      } else {
        daysCount = daysUntilAnniversary(item[column_id]);
        if (daysCount >= 0 && daysCount <= 7) {
          iconText = HRemployee.Joininganniversary[lang];
        }
      }
    }

    if (daysCount >= 0 && daysCount <= 7) {
      if (daysCount === 0) {
        iconText +=  HRemployee.istoday[lang];
      } else if (daysCount === 1) {
        iconText += HRemployee.istomorrow[lang];
      } else {
        iconText += (HRemployee.isin[lang] + {daysCount} + HRemployee.days[lang]);
      }
    }

    return iconText;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    http.Get(
      `hr_calendar?year=${year}&month=${month}`,
      (data) => {
        setCalendar(data.calendar);
      },
      () => {}
    );
  }, [year, month]);

  function fetchData() {
    setLoadData(true);
    let tenantUsers = [];
    let hrProfiles = [];

    http.Get(
      tenantUsersUrl,
      (data) => {
        tenantUsers = data;
        setLoadData(true);
        http.Get(
          hrProfilesUrl,
          (data) => {
            hrProfiles = data;
            tenantUsers.map((user) => {
              // map users and check for id in hr profiles array
              // tag user with hr profile if match found
              hrProfiles.map((profile, index) => {
                if (user.id === profile.tenant_user) {
                  // Add flag and actions
                  user.hr_profile = profile.id;
                }
                user.birth_date = user.hr_profile
                  ? formatDate(profile.birth_date)
                  : "Unlinked";
                user.joining_date = user.hr_profile
                  ? formatDate(profile.joining_date)
                  : "Unlinked";
                profile.actions = tableActions(profile);
                profile.link = hrLink(profile);
                profile.status = profile.status ? "Active" : "Disabled";
              });
              user.actions = tableActions(user);
              user.link = hrLink(user);

              // Date formatting and actions
              user.status = user.status ? "Active" : "Disabled";
              // user.rp_disabled = !user.status;
              user["mobile_view"] = user.designation;
              user["birth_date_icon"] = isShowIcon(user, "birth_date") ? (
                <Tooltip title={getIconText(user, "birth_date")}>
                  <CakeIcon
                    onClick={() => {
                      setShowCalendar(true);
                    }}
                    className={classes.iconColorScheme}
                  />
                </Tooltip>
              ) : (
                ""
              );
              user["joining_date_icon"] = isShowIcon(user, "joining_date") ? (
                <Tooltip title={getIconText(user, "joining_date")}>
                  <EventIcon
                    onClick={() => {
                      setShowCalendar(true);
                    }}
                    className={classes.iconColorScheme}
                  />
                </Tooltip>
              ) : (
                ""
              );
            });
            setTenantUsers(tenantUsers);
            setHRProfiles(hrProfiles);
            setLoadData(false);
          },
          (err) => {
            setLoadData(false);
          }
        );
      },
      (err) => {
        setLoadData(false);
      }
    );
  }

  return (
    <div>
      <Dialog
        open={showCalendar}
        onClose={() => {
          setShowCalendar(false);
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
          }}
        >
          <a
            href="#"
            onClick={() => {
              if (month > 1) {
                setMonth(month - 1);
              } else {
                setMonth(12);
                setYear(year - 1);
              }
            }}
          >
            {"<<<<"}
          </a>
          <a
            href="#"
            style={{ float: "right" }}
            onClick={() => {
              if (month < 12) {
                setMonth(month + 1);
              } else {
                setMonth(1);
                setYear(year + 1);
              }
            }}
          >
            {">>>>"}
          </a>
        </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
          dangerouslySetInnerHTML={{ __html: calendar }}
        />
      </Dialog>

      {showForm && (
        <EmployeeForm
          {...props}
          closeOpen={() => {
            setShowForm(!showForm);
          }}
          edit={edit}
          objectId={objectId}
          userLink={userLink}
          pageHeader={(props.user.company_name || "") + HRemployee.HR[lang]}
          handleObjectSubmit={() => {
            fetchData();
            setObjectId(null);
            setShowForm(!showForm);
          }}
        />
      )}
      {loadData && <ScreenLoader />}
      {!loadData && !props.mobile && !showForm && (
        <ObjectList
          {...props}
          moduleName={
            <div>
              {HRemployee.Employees[lang]}
              <Switch checked={hrToggle} onChange={handleToggle} />
              <small>{hrToggle ? HRemployee.HRPROFILES[lang] : HRemployee.ALLUSERS[lang]}</small>
              <Chip
                label={HRemployee.ADDNEW[lang]}
                clickable
                className={classes.chipAddNew}
                color="primary"
                onClick={() => {
                  setEdit(false);
                  setObjectId(null);
                  setShowForm(true);
                }}
                disabled={
                  props.permissions &&
                  !hasPermission(props, props.permissions.create)
                }
              />
            </div>
          }
          tableColumns={tableColumns}
          data={dataHandler}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"first_name"}
          searchFields={["first_name"]}
          pageHeader={(props.user.company_name || "") + HRemployee.HR1[lang]}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {!loadData && props.mobile && !showForm && (
        <ObjectListMobile
          {...props}
          moduleName={"Employees"}
          tableColumns={tableColumns}
          data={dataHandler}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"first_name"}
          searchFields={["first_name"]}
          pageHeader={(props.user.company_name || "") + HRemployee.HR1[lang]}
          loadData={loadData}
          objectForm={EmployeeFormMobile}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Employees);
