import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import CommonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";

const SideForm = props => {
  const classes = useStyles();
  const [contact, setContact] = useState({});
  const [contactError, setContactError] = useState({});
  const {
    requiredError,
    blankError,
    urlError,
    websitePlaceholder,
    invalidEmailError
  } = CommonConstants.error;
  const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onChange = e => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
    setContactError({
      ...contactError,
      [e.target.name]: false
    });
    setIsWebsiteInvalid(false);
    setIsSubmitted(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmitted(true);

    {
      http.Post(
        `offices`,
        contact,
        contact => {
          props.handleContactSubmit(contact, props.edit);
        },
        (status, error) => {
          if (status === 400) setContactError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" className={classes.light}>
              {props.edit ? "Edit Contact" : "Add New Contact"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          <p className={`${classes.label} ${classes.semiBold}`}>NAME</p>
          <TextValidator
            id="name"
            fullWidth
            margin="dense"
            variant="outlined"
            name="name"
            value={contact.name || ""}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={Boolean(contactError.title)}
            helperText={contactError.title && contactError.title[0]}
          />
          <p className={`${classes.label} ${classes.semiBold}`}>EXT</p>
          <TextValidator
            id="ext"
            fullWidth
            margin="dense"
            variant="outlined"
            name="ext"
            value={contact.ext || ""}
            onChange={onChange}
            inputProps={{ maxLength: 50 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={Boolean(contactError.ext)}
            helperText={contactError.ext && contactError.ext[0]}
          />
          <p className={`${classes.label} ${classes.semiBold}`}>EMAIL</p>
          <TextValidator
            id="email"
            fullWidth
            margin="dense"
            variant="outlined"
            name="email"
            value={contact.email || ""}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={Boolean(contactError.email)}
            helperText={contactError.email && contactError.email[0]}
          />
          <p className={`${classes.label} ${classes.semiBold}`}>MOBILE</p>
          <TextValidator
            id="mobile"
            fullWidth
            margin="dense"
            variant="outlined"
            name="mobile"
            value={contact.mobile || ""}
            onChange={onChange}
            inputProps={{ maxLength: 255 }}
            validators={["required", "trim"]}
            errorMessages={[requiredError, blankError]}
            error={Boolean(contactError.mobile)}
            helperText={contactError.mobile && contactError.mobile[0]}
          />
        </div>
        <div className={classes.divider}>
          <Divider />
        </div>
        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default SideForm;
