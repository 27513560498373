import React, { useEffect, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import Notification from "./Notification/Notification";
import OfficeContact from "./OfficeContact/OfficeContact";
import Resource from "./Resource/Resource";
import Offices from "./OfficeContact/Offices";
import TimeOffAvailability, {
  TimeOffHistoryModal,
} from "./TimeOffAvailability/TimeOffAvailability";
import PendingTimeOffRequests from "./PendingTimeOffRequests/PendingTimeOffRequests";
import UnlockRequests from "./UnlockRequest/UnlockRequests";
import { hasPermission } from "../Common/permission";
import useStyles from "./Style/style";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarComponent from "../Common/Snackbar";

const Dashboard = (props) => {
  const classes = useStyles();
  const cssList4 = [classes.dashboardContainer,classes.dashboard41Container,classes.dashboard42Container,classes.dashboard43Container,classes.dashboard44Container];
  const cssList3 = [classes.dashboardContainer,classes.dashboard31Container,classes.dashboard32Container,classes.dashboard33Container];
  const cssList2 = [classes.dashboardContainer,classes.dashboard21Container,classes.dashboard22Container];

  
  const [showTimeOffHistory, setShowTimeOffHistory] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  ///////////////////////////////////
  ///Left Part/////
  const [leftPart,setLeftPart] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return {"1":true,"2":true,"5":1}
    } else if (!hasPermission(props, ["Lock Documents"])){
      return {"1":true,"2":true,"4":true,"5":2}
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return {"1":true,"2":true,"3":true,"5":2}
    } else{
      return {"1":true,"2":true,"3":true,"4":true,"5":3}
    }
  });
  const [notiCss,setNotiCss] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return classes.dashboard22Container
    } else if (!hasPermission(props, ["Lock Documents"])){
      return classes.dashboard33Container
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard44Container
    }
  });
  const [docCss,setDocCss] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return classes.dashboard22Container
    } else if (!hasPermission(props, ["Lock Documents"])){
      return classes.dashboard33Container
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard44Container
    }
  });
  const [linkCss,setLinkCss] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return classes.dashboard22Container
    } else if (!hasPermission(props, ["Lock Documents"])){
      return classes.dashboard33Container
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard44Container
    }
  });
  const [pendCss,setPendCss] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return classes.dashboard22Container
    } else if (!hasPermission(props, ["Lock Documents"])){
      return classes.dashboard33Container
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard44Container
    }
  });
  const [noti, setNoti] = useState(true);
  const [doc, setDoc] = useState(true);
  const [link,setLink] = useState(true);
  const [pending,setPending] = useState(true);
  const [leftCss,setLeftCss] = useState(()=>{
    if ((!hasPermission(props, ["Lock Documents"]))&&(!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0))){
      return cssList2
    } else if (!hasPermission(props, ["Lock Documents"])){
      return cssList3
    } else if (!(hasPermission(props, ["HR"]) && props.tenant && props.tenant.is_hr_module_available && props.user.reporting_employees_count > 0)){
      return cssList3
    } else{
      return cssList4
    }
  });
  ////////////////
  ///Right Part///
  const [rightPart,setRightPart] = useState(()=>{
    if ((hasPermission(props, ["HR"]) &&
    props.tenant &&
    props.tenant.is_hr_module_available)){
      return {"2":true,"3":true,"5":1}
    } else{
      return{"1":true,"2":true,"3":true,"5":2}
    }
  });
  
  const [timeOffCss,setTimeOffCss] = useState(classes.dashboard33Container)
  const [contactCss,setContactCss] = useState(()=>{
    if ((hasPermission(props, ["HR"]) &&
    props.tenant &&
    props.tenant.is_hr_module_available)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard22Container
    }
  });
  const [officeCss,setOfficeCss] = useState(()=>{
    if ((hasPermission(props, ["HR"]) &&
    props.tenant &&
    props.tenant.is_hr_module_available)){
      return classes.dashboard33Container
    } else{
      return classes.dashboard22Container
    }
  });
  const [timeOff, setTimeOff] = useState(true);
  const [contact, setContact] = useState(true);
  const [offices,setOffices] = useState(true);
  const [rightPartCss,setRightPartCss] = useState(
    ()=>{
      if (hasPermission(props, ["HR"]) &&
      props.tenant &&
      props.tenant.is_hr_module_available){
        return cssList2
      } else{
        return cssList3
      }
    });
  ////////////////
  ///////////////////////////////////
  
  // Clear localStorage of firstlogin flag if left over from user login
  useEffect(() => {
    if (props.user && localStorage.getItem("firstLogin")) {
      localStorage.removeItem("firstLogin");
    };
    // setLeftDec({"1":3,"2":3,"3":3,"4":3})
  }, []);

  useEffect(() => {
    if(noti){
      setLeftPart({...leftPart,"1":true,"5":leftPart["5"]+1})
    }else{
      setLeftPart({...leftPart,"1":false,"5":leftPart["5"]-1})
    }
  },[noti])

  useEffect(() => {
    if(timeOff){
      setRightPart({...rightPart,"1":true,"5":rightPart["5"]+1})
    }else{
      setRightPart({...rightPart,"1":false,"5":rightPart["5"]-1})
    }
  },[timeOff])

  useEffect(() => {
    if(doc){
      setLeftPart({...leftPart,"3":true,"5":leftPart["5"]+1})
    }else{
      setLeftPart({...leftPart,"3":false,"5":leftPart["5"]-1})
    }
  },[doc])

  useEffect(() => {
    if(contact){
      setRightPart({...rightPart,"2":true,"5":rightPart["5"]+1})
    }else{
      setRightPart({...rightPart,"2":false,"5":rightPart["5"]-1})
    }
  },[contact])

  useEffect(() => {
    if(link){
      setLeftPart({...leftPart,"2":true,"5":leftPart["5"]+1})
    }else{
      setLeftPart({...leftPart,"2":false,"5":leftPart["5"]-1})
    }
  },[link])

  useEffect(() => {
    if(offices){
      setRightPart({...rightPart,"3":true,"5":rightPart["5"]+1})
    }else{
      setRightPart({...rightPart,"3":false,"5":rightPart["5"]-1})
    }
  },[offices])

  useEffect(() => {
    if(pending){
      setLeftPart({...leftPart,"4":true,"5":leftPart["5"]+1})
    }else{
      setLeftPart({...leftPart,"4":false,"5":leftPart["5"]-1})
    }
  },[pending])

  useEffect(() => {
    const keyList = Object.keys(leftPart);
    keyList.map((key)=>{
      if (key!=="5"){
        if (leftPart[key]){
          if(key === "1"){
            setNotiCss(leftCss[leftPart["5"]]);
          }
          if(key === "2"){
            setLinkCss(leftCss[leftPart["5"]]);
          }
          if(key === "3"){
            setDocCss(leftCss[leftPart["5"]]);
          }
          if(key === "4"){
            setPendCss(leftCss[leftPart["5"]])
          }
        }else{
          if(key === "1"){
            setNotiCss(leftCss[0]);
          }
          if(key === "2"){
            setLinkCss(leftCss[0]);
          }
          if(key === "3"){
            setDocCss(leftCss[0]);
          }
          if(key === "4"){
            setPendCss(leftCss[0])
          }
        }
      }
    }
    )},[leftPart])

  useEffect(() => {
    const keyList = Object.keys(rightPart);
    keyList.map((key)=>{
      if (key!=="5"){
        if (rightPart[key]){  
          if(key === "1"){
            setTimeOffCss(rightPartCss[rightPart["5"]])
          }
          if(key === "2"){
            setContactCss(rightPartCss[rightPart["5"]])
          }
          if(key === "3"){
            setOfficeCss(rightPartCss[rightPart["5"]])
          }
          }else{
            if(key === "1"){
              setTimeOffCss(rightPartCss[0])
            }
            if(key === "2"){
              setContactCss(rightPartCss[0])
            }
            if(key === "3"){
              setOfficeCss(rightPartCss[0])
            }
          }
        }
      }
      )},[rightPart])


  if (window.location.hash) {
    window.location.href = window.location.href.split("#")[0];
  }

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <div style={{overflow:"hidden",padding:20}}>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarOpen}
        handleSnackBarClose={handleSnackBarClose}
        severity={severity}
      ></SnackbarComponent>
      {showTimeOffHistory && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(showTimeOffHistory)}
          onClose={() => {
            setShowTimeOffHistory(null);
          }}
        >
          <div className={classes.popupDialog} style={{ padding: 10 }}>
            <TimeOffHistoryModal
              {...props}
              handleClose={() => {
                setShowTimeOffHistory(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setShowTimeOffHistory(null);
              }}
            />
          </div>
        </Dialog>
      )}
      <Grid container spacing={6}>
        {/* Left */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid container spacing={6} className={classes.grid4Container}>
            <Grid item xs={12} className={classes.grid3Container}>
              <Grid
                container
                spacing={3}
                className={notiCss}
              >
                <Grid item xs={12}>
                  <Notification {...props} setNoti = {setNoti}/>
                </Grid>
              </Grid>
            </Grid>
            {hasPermission(props, ["Lock Documents"]) && (
              <Grid item xs={12} className={classes.grid3Container}>
                <Grid
                  container
                  spacing={3}
                  className={docCss}
                >
                  <Grid item xs={12}>
                    <UnlockRequests {...props} setDoc = {setDoc}/>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.grid3Container}>
              <Grid
                container
                spacing={3}
                className={linkCss}
              >
                <Grid item xs={12}>
                  <Resource {...props} setLink = {setLink}/>
                </Grid>
              </Grid>
            </Grid>
            {hasPermission(props, ["HR"]) &&
							props.tenant &&
              props.tenant.is_hr_module_available &&
              props.user.reporting_employees_count > 0 && (

                <Grid item xs={12} className={classes.grid3Container}>
                  <Grid
                    container
                    spacing={3}
                    className={pendCss}
										>
                    <Grid item xs={12}>
                      <PendingTimeOffRequests
                        {...props}
                        setPending = {setPending}
                        setShowTimeOffHistory={setShowTimeOffHistory}
                        setSnackBarMessage={setSnackBarMessage}
                        setSnackBarOpen={setSnackBarOpen}
                        setSeverity={setSeverity}
												/>
                    </Grid>
                  </Grid>
                </Grid>)}
          </Grid>
        </Grid>
        {/* right */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid container direction="column" spacing={6} className={classes.grid4Container}>
            {hasPermission(props, ["HR"]) &&
							props.tenant &&
              props.tenant.is_hr_module_available && (
                <Grid item xs={12} className={classes.grid3Container}>
                  <Grid
                    container
                    spacing={3}
                    className={timeOffCss}
                  >
                    <Grid item xs={12}>
                      <TimeOffAvailability
                        {...props}
                        setTimeOff={setTimeOff}
                        setShowTimeOffHistory={setShowTimeOffHistory}
                        setSnackBarMessage={setSnackBarMessage}
                        setSnackBarOpen={setSnackBarOpen}
                        setSeverity={setSeverity}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <Grid item xs={12} className={classes.grid3Container}>
              <Grid
                container
                spacing={3}
                className={contactCss}
              >
                <Grid item xs={12}>
                  <OfficeContact {...props} setContact={setContact}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.grid3Container}>
              <Grid
                container
                spacing={3}
                className={officeCss}
              >
                <Grid item xs={12}>
                  <Offices {...props} setOffices={setOffices}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

// </Grid>
// <Grid item xs={12} sm={12} md={12} lg={6}>

export default Dashboard;
