import React, { useEffect, useState } from "react";
import ResellerForm from "./ResellerForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import CheckIcon from '@material-ui/icons/Check';

const tableColumns = [
  {
    id: "name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "id",
    label: "ID",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "default",
    label: "Default",
    sortable: true,
  },
  {
    id: "payment_gateway",
    label: "Payment Gateway",
    sortable: true,
  },
];

const Resellers = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `resellers`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((reseller)=>{
          reseller.default = reseller.is_default ? <CheckIcon/> : "";
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Resellers"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={ResellerForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Resellers"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Resellers"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={ResellerForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Resellers"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Resellers;
