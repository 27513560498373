import auth from "../../services/authService";
import commonConstraints from "./CommonConstants";
import { trackPromise } from "react-promise-tracker";
import {whiteList} from "./Utility";

const processError = (error, status, errorHandler, isClientPortal) => {
  console.log("status code: ", status, " error: ", error);
  if (errorHandler) errorHandler(status, error);
  if (status === 401) {
    auth.deleteToken();
    localStorage.removeItem("isDarkEnabled");
    localStorage.removeItem("colorScheme");
    if (isClientPortal === true) {
      window.location.replace(process.env.PUBLIC_URL + "/portal/login");
    } else {
      window.location.replace(process.env.PUBLIC_URL + "/login");
    }
  }
};

class RestAPIHandler {
  /*updated by linkpoint*/
  static serverName = commonConstraints.serverName;

  static ExportToPdf(
    moduleName,
    data,
    successHandler,
    errorHandler,
    isClientPortal = false
  ) {
    const httpHeaders = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = fetch(this.serverName + moduleName, {
      method: "POST",
      headers: httpHeaders,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) return res.blob();
      })
      .then((blob) => {
        successHandler(blob);
      })
      .catch((err) => {
        console.log(this.serverName + moduleName);
        processError(err.message, null, errorHandler, isClientPortal);
      });

    return promise;
  }

  static Get(moduleName, successHandler, errorHandler, isClientPortal = false) {
    const httpHeaders = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = trackPromise(
      fetch(this.serverName + moduleName, {
        method: "GET",
        headers: httpHeaders,
      })
        .then((res) => {
          return res.text().then((text) => {
            let data = text.length ? JSON.parse(text) : {};
            return { status: res.status, data: data };
          });
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            successHandler(res.data);
          } else {
            console.log(this.serverName + moduleName);
            processError(res.data, res.status, errorHandler, isClientPortal);
          }
        })
        .catch((err) => {
          console.log(this.serverName + moduleName);
          processError(err.message, null, errorHandler, isClientPortal);
        })
    );
    return promise;
  }

  static Post(
    moduleName,
    data,
    successHandler,
    errorHandler,
    isClientPortal = false
  ) {
    var httpHeaders, dataBody;
    if (data instanceof FormData) {
      httpHeaders = new Headers({});
      dataBody = data;
    } else {
      httpHeaders = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      });
      dataBody = JSON.stringify(data);
    }

    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = trackPromise(
      fetch(this.serverName + moduleName, {
        method: "POST",
        headers: httpHeaders,
        body: dataBody,
      })
        .then((res) => {
          return res.text().then((text) => {
            let data = text.length ? (text[0] == '<' ? text : JSON.parse(text)) : {};
            return { status: res.status, data: data };
          });
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            successHandler(res.data);
          } else {
            console.log(this.serverName + moduleName);
            processError(res.data, res.status, errorHandler, isClientPortal);
          }
        })
        .catch((err) => {
          console.log(this.serverName + moduleName);
          processError(err.message, null, errorHandler, isClientPortal);
        })
    );
    return promise;
  }

  static Put(
    moduleName,
    data,
    successHandler,
    errorHandler,
    isClientPortal = false
  ) {
    var httpHeaders, dataBody;

    if (data instanceof FormData) {
      httpHeaders = new Headers({});
      dataBody = data;
    } else {
      httpHeaders = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      });
      dataBody = JSON.stringify(data);
    }
    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = trackPromise(
      fetch(this.serverName + moduleName, {
        method: "PUT",
        headers: httpHeaders,
        body: dataBody,
      })
        .then((res) => {
          return res.text().then((text) => {

            let data = text.length ? JSON.parse(text) : {};
            return { status: res.status, data: data };
          });
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            successHandler(res.data);
          } else {
            console.log(this.serverName + moduleName);
            processError(res.data, res.status, errorHandler, isClientPortal);
          }
        })
        .catch((err) => {
          console.log(this.serverName + moduleName);
          processError(err.message, null, errorHandler, isClientPortal);
        })
    );
    return promise;
  }

  static Patch(
    moduleName,
    data,
    successHandler,
    errorHandler,
    isClientPortal = false
  ) {
    var httpHeaders, dataBody;

    if (data instanceof FormData) {
      httpHeaders = new Headers({});
      dataBody = data;
    } else {
      httpHeaders = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      });
      dataBody = JSON.stringify(data);
    }
    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = trackPromise(
      fetch(this.serverName + moduleName, {
        method: "PATCH",
        headers: httpHeaders,
        body: dataBody,
      })
        .then((res) => {
          return res.text().then((text) => {

            let data = text.length ? JSON.parse(text) : {};
            return { status: res.status, data: data };
          });
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            successHandler(res.data);
          } else {
            console.log(this.serverName + moduleName);
            processError(res.data, res.status, errorHandler, isClientPortal);
          }
        })
        .catch((err) => {
          console.log(this.serverName + moduleName);
          processError(err.message, null, errorHandler, isClientPortal);
        })
    );
    return promise;
  }

  static Delete(
    moduleName,
    data,
    successHandler,
    errorHandler,
    isClientPortal = false
  ) {
    const httpHeaders = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });

    var token = auth.getToken();
    if (token) {
      httpHeaders.append("Authorization", `Bearer ${token}`);
    }

    var promise = trackPromise(
      fetch(this.serverName + moduleName, {
        method: "DELETE",
        headers: httpHeaders,
        body: data && JSON.stringify(data),
      })
        .then((res) => {
          return res.text().then((text) => {
            let data = text.length ? JSON.parse(text) : {};
            return { status: res.status, data: data };
          });
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            successHandler(res.status, res.data);
          } else {
            console.log(this.serverName + moduleName);
            processError(res.data, res.status, errorHandler, isClientPortal);
          }
        })
        .catch((err) => {
          console.log(this.serverName + moduleName);
          processError(err.message, null, errorHandler, isClientPortal);
        })
    );
    return promise;
  }
}

export default RestAPIHandler;
