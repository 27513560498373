import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import http from "../../../../Common/RestAPIHandler";
import {
  Checkbox,
  Button,
  Divider,
  Tooltip,
  Select,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from "@date-io/date-fns";
import ScreenLoader from "../../../../Common/Loader";
import {ReportsLang} from "../../../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    width: "max-content",
  },
  checkboxColor: {
    color: theme.colorSchemeAlt,
    "&:hover": {
      color: theme.colorScheme + "!important",
    },
  },
  containerItem: {
    textAlign: "right",
  },
  chipOutlined: {
    borderRadius: 8,
    borderColor: theme.colorScheme,
    marginRight: "2px",
    color: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  divContent: {
    border: "1px solid #cecece",
    padding: 20,
    height: "calc(100vh/2.4)",
    overflow: "auto",
  },
  label: {
    color: theme.subText,
  },
  search: {
    display: "flex",
    minWidth: 100,
    height: 32,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 5,
  },
  title: {
    color: theme.text,
  },
  textColor: {
    color: theme.colorScheme,
  },
}));

const lang = localStorage.getItem("languages");

const VacationFilter = (props) => {
  const classes = useStyles();
  const today = new Date();
  const [error, setError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(today ? today : null);
  const [invalidDate, setInvalidDate] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationChecks, setLocationChecks] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const ttMessage = ReportsLang.helper[lang];

  // For formating dates before passing them in POST request.
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // Retrieve all tenant locations and set them as checkboxes for location section.
  // Checkboxes are labelled with the location's city.
  useEffect(() => {
    setLoadData(true);
    http.Get(
      `tenant_address`,
      (data) => {
        let locs = new Array();
        for (let x in data) {
          locs.push({ id: data[x].id, city: data[x].city });
        }
        setLocations(locs);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  // Sets all locations as CHECK ALL's value.
  useEffect(() => {
		let data = new Object();
    locations.map((location) => {
			console.log(locationChecks)
			data[location.id] = checkAll;
    });
		setLocationChecks(data);
  }, [checkAll]);

  // Keeps track of which location's checkbox is selected.
  const handleChange = (e) => {
    setLocationChecks({ ...locationChecks, [e.target.id]: e.target.checked });
  };	

  const onSubmit = () => {
    //Check that if both fromDate and toDate are set, then fromDate <= toDate.
    if (fromDate && toDate && fromDate > toDate) {
      setInvalidDate(true);
      return;
    }
    // Clear previous results.
    props.setResults(null);

    // Filter for and push selected locations into list.
    const locations = [];
    for (const [key, value] of Object.entries(locationChecks)) {
      if (value) locations.push(key);
    }

    // Send filter data to retrieve employees and their vacation records as POST request.
    http.Post(
      `hr/report/vacations`,
      {
        from_date: fromDate ? formatDate(fromDate) : "0001-01-01",
        to_date: toDate ? formatDate(toDate) : "9999-12-30",
        locations: locations,
      },
      (data) => {
        props.setResults(data["data"]); // Data gets passed to parent component.
      },
      (status, error) => {
        if (status === 400) {
          setError(error);
          setIsSubmitted(false);
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      {loadData ? (
        <ScreenLoader />
      ) : (
        <ValidatorForm onSubmit={onSubmit}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3} direction="row">
              <Grid item xs>
                <Grid container spacing={3} direction="row">
                  <Grid item xs={12}>
                    <div style={{ fontWeight: 520 }}>
                      <span className={classes.title}>{ReportsLang.DATERANGE[lang]}</span>
                    </div>
                    <Divider />
                  </Grid>
                  <Tooltip title={ttMessage} placement="left-start" arrow>
                    <Grid item xs={6} className={classes.gridItem}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        label={ReportsLang.From[lang]}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        margin="dense"
                        id="from_date"
                        name="from_date"
                        value={fromDate}
                        onChange={(value) => {
                          setFromDate(value);
                          setError({ ...error, from_date: false });
                          setInvalidDate(false);
                          setIsSubmitted(false);
                        }}
                        maxDate={today}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={Boolean(error.from_date) || invalidDate}
                        helperText={
                          (error.from_date && error.from_date[0]) ||
                          (invalidDate && ReportsLang.helper2[lang])
                        }
                      />
                    </Grid>
                  </Tooltip>
                  <Tooltip title={ttMessage} placement="left-start" arrow>
                    <Grid item xs={6} className={classes.gridItem}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        label={ReportsLang.To[lang]}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        margin="dense"
                        id="to_date"
                        name="to_date"
                        value={toDate}
                        onChange={(value) => {
                          setToDate(value);
                          setError({ ...error, to_date: false });
                          setInvalidDate(false);
                          setIsSubmitted(false);
                        }}
                        maxDate={today}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={Boolean(error.to_date) || invalidDate}
                        helperText={
                          (error.to_date && error.to_date[0]) ||
                          (invalidDate &&
                            ReportsLang.helper3[lang])
                        }
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
              <div>
                <Divider orientation="vertical" />
              </div>
              <Grid item xs>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormGroup
                      row={true}
                      style={{ justifyContent: "space-between" }}
                    >
                      <FormControl>
                        <div style={{ fontWeight: 520 }}>
                          <span className={classes.title}>{ReportsLang.LOCATIONS[lang]}</span>
                        </div>
                      </FormControl>
                    </FormGroup>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ maxHeight: "200px", overflow: "auto" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormControl fullWidth component="fieldset">
                            <FormLabel component="legend">
                              <FormGroup row={true}>
                                {locations.map((item) => {
                                  return (
                                    <div key={item.id}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            id={item.id}
                                            className={classes.checkboxColor}
                                            checked={
                                              locationChecks[item.id]
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => handleChange(e)}
                                          />
                                        }
                                        label={item.city}
                                        className={classes.label}
                                      />
                                    </div>
                                  );
                                })}
                              </FormGroup>
                            </FormLabel>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={checkAll}
                            onChange={(e) => {
                              setCheckAll(e.target.checked);
                            }}
                          />
                        }
                        label="All"
                        className={classes.label}
                      />
                      <Button
                        variant="outlined"
                        margin="dense"
                        className={classes.filledButton}
                        type="submit"
                        disabled={isSubmitted}
                        size="small"
                      >
                        {ReportsLang.Generate[lang]}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ValidatorForm>
      )}
    </div>
  );
};

export default VacationFilter;
