import React, { useEffect, useState } from "react";
import JobPostingTemplateForm from "./JobPostingTemplateForm";
import ObjectList from "../../../Common/ObjectList";
import ObjectListMobile from "../../../Common/ObjectListMobile";
import http from "../../../Common/RestAPIHandler";
import {postLang} from "../../../Common/Languages";
const lang = localStorage.getItem("languages");
const tableColumns = [
  {
    id: "title",
    label: postLang.TITLE[lang],
    minWidth: 200,
    sortable: true,
  },
  { id: "created_by_name", label: "BY", minWidth: 150, sortable: true },
];

const JobPostingTemplate = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `hr_job_posting_templates`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={postLang.JobPostingTemplates[lang]}
      url={url}
      actions={actions}
      permissions={{ create: ["Edit Job Postings"], edit: ["Edit Job Postings"], delete: ["Edit Job Postings"] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={JobPostingTemplateForm}
      orderBy={"title"}
      searchFields={["title"]}
      pageHeader={"Job Posting Templates"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Job Posting Templates"}
      url={url}
      actions={actions}
      permissions={{ create: ["Edit Job Postings"], edit: ["Edit Job Postings"], delete: ["Edit Job Postings"] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={JobPostingTemplateForm}
      orderBy={"title"}
      searchFields={["title"]}
      pageHeader={"Job Posting Templates"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default JobPostingTemplate;
