import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { getColorScheme } from "../Common/Utility";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    color: theme.colorScheme,
  },
  spinner: {
    width: "2rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  },
}));

const MiniSpinLoader = (props) => {
  const classes = useStyles();
  // const { promiseInProgress } = usePromiseTracker();

  return (
      <div className={classes.spinner}>
        <Loader type="TailSpin" height={20} width={20} color={getColorScheme()} />
      </div>
  );
};

export default MiniSpinLoader;
