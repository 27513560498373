import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import { clearUser, setUser } from "../../../redux-tools/actions/userActions";
import auth from "../../../services/authService";
import CommonConstants from "../../Common/CommonConstants";
import ScreenLoader from "../../Common/Loader";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    backgroundColor: "white",
    height: 30,
    padding: 0,
    margin: 0,
    fontSize: 14,
  },
  saveButton: {
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  rightAlign: {
    textAlign: "right",
    fontSize: 14,
  },
  viewButtonLabel: {
    textTransform: "none",
  },
  inputButton: {
    display: "none",
    textAlign: "center",
  },
  uploadButton: {
    border: "1px dashed gray",
    height: 30,
    fontSize: 13,
  },
  hidden: {
    display: "none !important",
  },
}));


const MyProfile = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
  const [isPasswordSameAsOrignal, setIsPasswordSameAsOriginal] = useState(
    false
  );
  const [isPasswordWhitespace, setIsPasswordWhitespace] = useState(false);
  const [userError, setUserError] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const errorMessage = CommonConstants.error;
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `reseller_user_profile/`,
      (user) => {
        console.log(user);
        setUser(user);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const onSubmit = () => {
    if (!(user.mobile && user.country_code)) {
      setIsMobile(true);
      return;
    }
    const data = new FormData();
    for (var key in user) {
      if (user[key] || ["area_code", "phone"].includes(key)) {
        data.append(key, user[key]);
      }
    }
    
    if(!user.profile_image){
        data.append("profile_image", "");
    }
    else if(!user.profile_image.name){
        data.delete("profile_image");
    }
    
    if (user.current_password || user.retype_password || user.new_password) {
      if (user.current_password) {
        if (user.retype_password && user.new_password) {
          if (user.current_password === user.new_password) {
            setIsPasswordSameAsOriginal(true);
            return;
          } else {
            let str = user.new_password;
            if (str.charAt(0) === " " || str.charAt(str.length - 1) === " ") {
              setIsPasswordWhitespace(true);
              return;
            }
          }
          if (user.retype_password === user.new_password) {
            setIsSubmitted(true);
            http.Put(
              `reseller_user_profile/`,
              data,
              (user) => {
                auth.deleteToken();
                props.clearUser();
                props.history.replace(process.env.PUBLIC_URL + "/reseller/login");
              },
              (status, error) => {
                if (status === 400 && error.invalid_password) {
                  setIsPasswordValid(true);
                }
                setUserError(error);
                setIsSubmitted(false);
              }
            );
          } else {
            setIsPasswordMatch(true);
          }
        } else {
          setIsPasswordEmpty(true);
        }
      } else {
        setIsPasswordEmpty(true);
      }
    } else {
      setIsSubmitted(true);
      http.Put(
        `reseller_user_profile/`,
        data,
        (userData) => {
          if (
            props.user.email !== userData.email ||
            props.user.mobile !== userData.mobile
          ) {
            auth.deleteToken();
            props.clearUser();
            props.history.replace("/login");
          } else {
            props.setUser(userData);
            setUser(userData);
            setIsSubmitted(false);
          }
        },
        (status, error) => {
          setUserError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "mobile" || e.target.name === "country_code")
      setIsMobile(false);
    if (e.target.name === "mobile") {
      setUserError({
        ...userError,
        [e.target.name]: null,
        cellphone: null,
      });
    } else {
      setUserError({
        ...userError,
        [e.target.name]: null,
      });
    }
    setIsPasswordMatch(false);
    setIsPasswordValid(false);
    setIsPasswordSameAsOriginal(false);
    setIsPasswordWhitespace(false);
    setIsSubmitted(false);
  };

  const handleFile = (e) => {
    setUser({
      ...user,
      profile_image: e.target.files[0],
    });
    setUserError({
      ...userError,
    });
  };

  return (
    <div className={classes.root}>
      <ValidatorForm onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12}>
            <span>{props.user.reseller_name} | Personal Settings</span>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">My Profile</Typography>
          </Grid>
          <Grid item xs={12}>
            {loadData ? (
              <ScreenLoader />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" noWrap>
                            USER SETTINGS
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              >
                                <span>Profile Image</span>
                              </Grid>
                              <Grid item xs={8} sm={5} md={4} lg={3}>
                                <Button
                                  variant="text"
                                  fullWidth
                                  component="label"
                                  classes={{ label: classes.viewButtonLabel }}
                                  className={classes.uploadButton}
                                  color="primary"
                                >
                                  Upload your image
                                  <input
                                    type="file"
                                    onChange={handleFile}
                                    className={classes.inputButton}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                            {user.profile_image && user.profile_image.name && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                                <Grid item xs>
                                  {user.profile_image.name}
                                </Grid>
                              </Grid>
                            )}
                            {user.profile_image && !user.profile_image.name && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                                <Grid item xs>                                
                                  <img
                                    height="100px"
                                    alt={user.id}
                                    src={user["profile_image_src"]}
                                  />
                                    <Button
                                      onClick={(e) => {
                                            setUser({
                                              ...user,
                                              profile_image: null,
                                            });
                                      }}
                                    >
                              <CancelIcon />
                            </Button>
                                </Grid>
                              </Grid>
                            )}
                            {userError.profile_image && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                                <Grid item xs style={{ color: "red" }}>
                                  {userError.profile_image[0]}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              >
                                <span>Email</span>
                              </Grid>
                              <Grid item xs={8} className={classes.hidden}>
                                <TextValidator
                                  id="email"
                                  type="text"
                                  fullWidth
                                  value=""
                                  margin="dense"
                                  variant="outlined"
                                  name="email"
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                              <Grid item xs={8} sm={7} md={6} lg={4}>
                                <TextValidator
                                  id="email"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  name="email"
                                  value={user.email || ""}
                                  InputProps={{ className: classes.input }}
                                  validators={["required"]}
                                  errorMessages={[errorMessage.requiredError]}
                                  error={Boolean(userError.email)}
                                  helperText={
                                    userError.email && userError.email[0]
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              >
                                <span>Phone</span>
                              </Grid>
                              <Grid item xs={5} sm={5} md={4} lg={3}>
                                <TextValidator
                                  id="phone"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  placeholder="Phone"
                                  name="phone"
                                  value={user.phone || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                              <Grid item xs={3} sm={2} md={2} lg={1}>
                                <TextValidator
                                  id="area_code"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  placeholder="ext"
                                  name="area_code"
                                  value={user.area_code || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              >
                                <span>Mobile</span>
                              </Grid>
                              <Grid item xs={3} sm={2} md={2} lg={1}>
                                <TextValidator
                                  id="country_code"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  placeholder="ext"
                                  name="country_code"
                                  value={user.country_code || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                              <Grid item xs={5} sm={5} md={4} lg={3}>
                                <TextValidator
                                  id="mobile"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  placeholder="Mobile"
                                  name="mobile"
                                  value={user.mobile || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                            </Grid>
                            {(userError.country_code ||
                              userError.cellphone ||
                              isMobile) && (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                                <Grid item xs style={{ color: "red" }}>
                                  {(userError.country_code
                                    ? "Country code " + userError.country_code
                                    : "") +
                                    (userError.cellphone
                                      ? " Mobile no " + userError.cellphone
                                      : "") || errorMessage.bothRequired}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1" noWrap>
                            CHANGE PASSWORD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid
                                  item
                                  xs={4}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  className={classes.rightAlign}
                                >
                                  <span>Current Password</span>
                                </Grid>

                                <Grid item xs={8} sm={7} md={6} lg={4}>
                                  <TextValidator
                                    autoComplete="current-password"
                                    type="password"
                                    id="current_password"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onChange}
                                    name="current_password"
                                    value={user.current_password || ""}
                                    InputProps={{ className: classes.input }}
                                    error={
                                      (isPasswordEmpty &&
                                        !user.current_password) ||
                                      isPasswordValid
                                    }
                                    helperText={
                                      (isPasswordEmpty &&
                                        !user.current_password &&
                                        errorMessage.requiredError) ||
                                      (isPasswordValid &&
                                        errorMessage.passwordInvalid)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid
                                  item
                                  xs={4}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  className={classes.rightAlign}
                                >
                                  <span>New Password</span>
                                </Grid>
                                <Grid item xs={8} className={classes.hidden}>
                                  <TextValidator
                                    autoComplete="current-password"
                                    type="password"
                                    id="current_password"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    name="current_password"
                                    InputProps={{ className: classes.input }}
                                  />
                                </Grid>
                                <Grid item xs={8} sm={7} md={6} lg={4}>
                                  <TextValidator
                                    id="new_password"
                                    autoComplete="new-password"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onChange}
                                    name="new_password"
                                    value={user.new_password || ""}
                                    InputProps={{ className: classes.input }}
                                    type="password"
                                    validators={
                                      user.new_password && [
                                        "minStringLength:8",
                                        "matchRegexp:(?=.*[A-Z])",
                                        "matchRegexp:(?=.*[0-9])",
                                        "matchRegexp:(?=.*[!@#$%^&*'+])",
                                      ]
                                    }
                                    errorMessages={[
                                      errorMessage.eightChar,
                                      errorMessage.uppercase,
                                      errorMessage.numeric,
                                      errorMessage.specialChar,
                                    ]}
                                    error={
                                      (isPasswordEmpty && !user.new_password) ||
                                      isPasswordSameAsOrignal ||
                                      isPasswordWhitespace ||
                                      isPasswordMatch
                                    }
                                    helperText={
                                      (isPasswordSameAsOrignal &&
                                        "New password and current password cannot be same.") ||
                                      (isPasswordEmpty &&
                                        !user.new_password &&
                                        errorMessage.requiredError) ||
                                      (isPasswordWhitespace &&
                                        "Cannot contain leading or trailing whitespace.") ||
                                      (isPasswordMatch &&
                                        errorMessage.passwordMismatch)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid
                                  item
                                  xs={4}
                                  sm={2}
                                  md={2}
                                  lg={2}
                                  className={classes.rightAlign}
                                >
                                  <span>Retype Password</span>
                                </Grid>
                                <Grid item xs={8} sm={7} md={6} lg={4}>
                                  <TextValidator
                                    autoComplete="retype-password"
                                    id="retype_password"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onChange}
                                    name="retype_password"
                                    value={user.retype_password || ""}
                                    InputProps={{ className: classes.input }}
                                    type="password"
                                    error={
                                      (isPasswordEmpty &&
                                        !user.retype_password) ||
                                      isPasswordMatch
                                    }
                                    helperText={
                                      (isPasswordEmpty &&
                                        !user.retype_password &&
                                        errorMessage.requiredError) ||
                                      (isPasswordMatch &&
                                        errorMessage.passwordMismatch)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                      <Grid item xs={4} sm={2} lg={1}>
                        <Button
                          className={classes.filledButton}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          type="submit"
                          onChange={onChange}
                          disabled={isSubmitted}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { clearUser, setUser })(MyProfile);
