import React, { useState, useEffect } from "react";
import Logo from "../../Images/logo.png";
import http from "../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Grid,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Slider,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlacesAutocomplete from "react-places-autocomplete";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
  },
  backButton: {
    margin: 10,
    marginBottom: 50,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: "capitalize",
    float: "right",
    borderColor: "grey",
    "&:hover": {
      borderColor: "gray",
    },
  },
  backSplash: {
		height: "100vh",
    backgroundColor: "#f7f9fc",
  },
  centerElement: {
    width: "80%",
    textAlign: "center",
    margin: "auto",
  },
  error: {
    color: "red",
  },
  logo: {
    width: "300px",
    height: "auto",
    margin: "10px",
  },
  nextButton: {
    margin: 10,
    marginBottom: 50,
    borderRadius: 5,
    paddingLeft: 30,
    paddingRight: 30,
    color: theme.tiText,
    textTransform: "capitalize",
    "&:hover": {
      color: theme.tiText,
    },
    float: "right",
  },
  tableBody: {
    textAlign: "center",
    paddingTop: "10px",
    height: "50px",
  },
  tableHeader: {
    textAlign: "center",
    paddingTop: "10px",
    backgroundColor: "#333333",
    color: "#a4d0ef",
    height: "50px",
  },
  textLeft: {
    textAlign: "left",
  },
  selectedButton: {
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#333333",
    color: "#a4d0ef",
    cursor: "pointer",
  },
  unselectedButton: {
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#eeeeee",
    cursor: "pointer",
  },
}));

const TenantInit = (props) => {
  const classes = useStyles();
  const [object, setObject] = useState({ step: 0 });
  const [part, setPart] = useState(0);
  const tableHeaderHeight = "160px";

  const showHeader = useMediaQuery("(min-width: 1280px)");

  const onChange = (e) => {
    if ("checkbox" === e.target.type || "radio" === e.target.type) {
      setObject({
        ...object,
        [e.target.name]: !object[e.target.name],
      });
    } else if ("file" === e.target.type) {
      setObject({
        ...object,
        [e.target.name]: e.target.files[0],
      });
    } else
      setObject({
        ...object,
        [e.target.name]: e.target.value,
      });
  };

  const nextStep = (e) => {
    e.preventDefault();
    http.Post(
      "tenant_init",
      {
        ...object,
        ["step"]: object.step + 1,
      },
      (response) => {
        setObject(response);
      },
      () => {}
    );
  };

  const backStep = (e) => {
    e.preventDefault();
    http.Post(
      "tenant_init",
      {
        ...object,
        ["step"]: object.step - 1,
      },
      (response) => {
        setObject(response);
      },
      () => {}
    );
  };

  return (
    <div className={classes.backSplash}>
      <div className={classes.root}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.centerElement}>
                <Typography variant="h6" color="inherit" noWrap>
                  <img
                    src={Logo}
                    alt="Not Available"
                    className={classes.logo}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} alignContent="center" alignItems="center">
                <Stepper activeStep={object.step}>
                  <Step>
                    <StepLabel>{object.step === 0 && "Login"}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{object.step === 1 && "Tenant User"}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{object.step === 2 && "Tenant"}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{object.step === 3 && "Agreement"}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{object.step === 4 && "Package"}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{object.step === 6 && "Done"}</StepLabel>
                  </Step>
                </Stepper>
                <br />
                <br />
                {object.step === 0 && (
                  <ValidatorForm
                    onSubmit={nextStep}
                    className={classes.centerElement}
                  >
                    <TextValidator
                      id="email"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="email"
                      value={object["email"] || ""}
                      onChange={onChange}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This Field is Required",
                        "Enter a valid email address",
                      ]}
                    />
                    <TextValidator
                      id="password"
                      label="Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="password"
                      value={object["password"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <p className={classes.error}>{object["user_error"]}</p>
                    <Button
                      variant="outlined"
                      type="submit"
                      className={classes.nextButton}
                    >
                      NEXT
                    </Button>
                  </ValidatorForm>
                )}
                {object.step === 1 && (
                  <ValidatorForm
                    onSubmit={nextStep}
                    className={classes.centerElement}
                  >
                    <TextValidator
                      id="first_name"
                      label="First Name *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="first_name"
                      value={object["first_name"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="last_name"
                      label="Last Name *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="last_name"
                      value={object["last_name"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="department"
                      label="Department"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="department"
                      value={object["department"] || ""}
                      onChange={onChange}
                    />
                    {/* <TextValidator
                          id="mobile"
                          label="Mobile *"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="mobile"
                          value={object["mobile"] || ""}
                          onChange={onChange}
                          validators={["required"]}
                          errorMessages={["This Field is Required"]}
                      />
                      <TextValidator
                          id="country_code"
                          label="Country Code *"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="country_code"
                          value={object["country_code"] || ""}
                          onChange={onChange}
                          validators={["required"]}
                          errorMessages={["This Field is Required"]}
                      /> */}
                    <Button
                      variant="outlined"
                      type="submit"
                      className={classes.nextButton}
                    >
                      NEXT
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.backButton}
                      onClick={backStep}
                    >
                      Back
                    </Button>
                  </ValidatorForm>
                )}
                {object.step === 2 && (
                  <ValidatorForm
                    onSubmit={nextStep}
                    className={classes.centerElement}
                  >
                    <TextValidator
                      id="company_name"
                      label="Company Name *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="company_name"
                      value={object["company_name"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="city"
                      label="City *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="city"
                      value={object["city"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="address"
                      label="Address *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="address"
                      value={object["address"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="state"
                      label="Province *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="state"
                      value={object["state"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="country"
                      label="Country *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="country"
                      value={object["country"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="country"
                      label="Postal Code *"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="postal"
                      value={object["postal"] || ""}
                      onChange={onChange}
                      validators={["required"]}
                      errorMessages={["This Field is Required"]}
                    />
                    <TextValidator
                      id="switch_from"
                      label="What other document management systems have you used in the past or present?"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      name="switch_from"
                      value={object["switch_from"] || ""}
                      onChange={onChange}
                    />
                    {/* <TextValidator
                          id="industry_type"
                          select
                          label="Industry *"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="industry_type"
                          value={object["industry_type"] || ""}
                          onChange={onChange}
                          validators={["required"]}
                          errorMessages={["This Field is Required"]}
                        >
                        <MenuItem value="Aerospace">Aerospace</MenuItem>
                        <MenuItem value="Agriculture">Agriculture</MenuItem>
                        <MenuItem value="Construction">Construction</MenuItem>
                        <MenuItem value="Education">Education</MenuItem>
                        <MenuItem value="Energy">Energy</MenuItem>
                        <MenuItem value="Entertainment">Entertainment</MenuItem>
                        <MenuItem value="Financial">Financial</MenuItem>
                        <MenuItem value="Health Care">Health Care</MenuItem>
                        <MenuItem value="Food & Hospitality">Food & Hospitality</MenuItem>
                        <MenuItem value="Information Technology">Information Technology</MenuItem>
                        <MenuItem value="Legal">Legal</MenuItem>
                        <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                        <MenuItem value="Non-Profit">Non-Profit</MenuItem>
                        <MenuItem value="Pharmaceutical">Pharmaceutical</MenuItem>
                        <MenuItem value="Real Estate">Real Estate</MenuItem>
                        <MenuItem value="Retail">Retail</MenuItem>
                        <MenuItem value="Sports & Leisure">Sports & Leisure</MenuItem>
                        <MenuItem value="Telecommunications">Telecommunications</MenuItem>
                        <MenuItem value="Transportation and Logistics">Transportation and Logistics</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextValidator> */}
                    {/* <TextValidator
                    id="company_size"
                    label="Company Size *"
                    select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="company_size"
                    value={object["company_size"] || ""}
                    onChange={onChange}
                    validators={["required"]}
                    errorMessages={["This Field is Required"]}
                  >
                    <MenuItem value="1~10">1~10</MenuItem>
                    <MenuItem value="11~25">11~25</MenuItem>
                    <MenuItem value="26~50">26~50</MenuItem>
                    <MenuItem value="51~100">51~100</MenuItem>
                    <MenuItem value="100~more">100~more</MenuItem>
                  </TextValidator> */}
                    {/* <TextValidator
                          id="hear_from"
                          label="How did you hear about us? *"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="hear_from"
                          value={object["hear_from"] || ""}
                          onChange={onChange}
                          validators={["required"]}
                          errorMessages={["This Field is Required"]}
                      /> */}
                    <Button
                      variant="outlined"
                      type="submit"
                      className={classes.nextButton}
                    >
                      NEXT
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.backButton}
                      onClick={backStep}
                    >
                      Back
                    </Button>
                  </ValidatorForm>
                )}
                {object.step === 3 && (
                  <div className={classes.centerElement}>
                    <p
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      End User License Agreement (EULA) – Platform
                    </p>
                    <div
                      style={{
                        textAlign: "left",
                        maxHeight: "300px",
                        height: "300px",
                        overflow: "scroll",
                        padding: "20px",
                      }}
                    >
                      <a id="part0"></a>
                      <p>
                        SyncMonkey Software Inc. (“<b>SyncMonkey</b>”) provides
                        an online document management platform (the “
                        <b>Software</b>
                        ”). By clicking on the “Agree” button below and by using
                        the Software, you and the company you represent
                        (collectively, the “<b>Licensee</b>”) accept all the
                        terms and conditions of this end user license agreement
                        (the “<b>Agreement</b>”). If you do not agree to the
                        terms of this Agreement, do not click the “Agree” button
                        below and do not access or use the Software.
                      </p>
                      <a id="part1"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        AUTHORITY TO END USER AGREEMENT
                      </p>
                      <p>
                        If the Licensee is a company, you represent and warrant
                        that you are an authorized representative of the company
                        and that you have the authority to cause the company to
                        enter into this Agreement.
                      </p>
                      <a id="part2"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        GRANT OF LICENSE
                      </p>
                      <p>
                        SyncMonkey grants the Licensee a revocable,
                        non-exclusive, non-transferable, limited license to
                        access and use the Software in accordance with the terms
                        and conditions of this Agreement (the “<b>License</b>”).
                        The License does not include any rights to review or
                        access the human readable source code of the Software.
                      </p>
                      <a id="part3"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        AUTHORIZED USERS
                      </p>
                      <p>
                        The Licensee shall allow only a fixed number of users
                        (the “<b>Authorized Users</b>”) to access and use the
                        Software via personal login credentials. The number of
                        Authorized Users shall be agreed upon by the Licensee
                        and the Licensor. The Licensee shall ensure that the
                        login credentials of a particular Authorized User are
                        used only by that Authorized User. The Licensee shall
                        ensure that all Authorized Users are employees of the
                        Licensee.
                      </p>
                      <a id="part4"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        SECURITY OF LOGIN CREDENTIALS
                      </p>
                      <p>
                        The Licensee shall be solely responsible for ensuring
                        its Authorized Users maintain the security of their
                        Login Credentials. SyncMonkey shall not be liable for
                        any damages resulting from the misuse of an Authorized
                        User’s login credentials.
                      </p>
                      <a id="part5"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        LICENSE FEE
                      </p>
                      <p>
                        As consideration for the License, the Licensee shall pay
                        a monthly license fee to SyncMonkey (the “
                        <b>License Fee</b>
                        ”). The amount of the License Fee may depend on the
                        version of the Software being licensed and the number of
                        Authorized Users. SyncMonkey may change the License Fee
                        at any time upon written notice to the Licensee.
                      </p>
                      <a id="part6"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        OWNERSHIP OF INTELLECTUAL PROPERTY
                      </p>
                      <p>
                        The Licensee acknowledges and agrees that this Agreement
                        does not grant any intellectual property rights in the
                        Software to the Licensee. The Licensee acknowledges and
                        agrees that SyncMonkey is the sole owner of the Software
                        and all intellectual property rights therein.
                      </p>
                      <a id="part7"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        THIRD-PARTY SERVICES
                      </p>
                      <p>
                        The Software may include components that enable the
                        Licensee to link to and use certain services provided by
                        third parties (“<b>Third-Party Services</b>”). The
                        Licensee acknowledges and agrees that its use the
                        Third-Party Services is subject to its agreement with
                        the applicable third-party service providers. The
                        Licensee acknowledges and agrees that its use of the
                        Third-Party services is entirely at its own risk.
                      </p>
                      <a id="part8"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        THIRD-PARTY SOFTWARE
                      </p>
                      <p>
                        This Agreement does not apply to any open-source
                        software which is included in the Software or to any
                        third-party software that is licensed separately under
                        the terms of various separate license agreements (“
                        <b>Other Software</b>”). The Other Software is not
                        subject to the terms and conditions of this Agreement
                        and is instead licensed to you under the terms of the
                        applicable third-party license agreements (the “
                        <b>Other Software Terms</b>”). Copyrights to the Other
                        Software are held by copyright holders indicated in the
                        Other Software Terms. Any terms of this Agreement that
                        conflict with the terms of any license agreements for
                        Other Software will not apply to the Other Software.
                        Nothing in this Agreement limits your rights under, or
                        grants you rights that supersede, the terms and
                        conditions of any applicable end user license for the
                        Other Software.
                      </p>
                      <a id="part9"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        RESTRICTIONS ON USE
                      </p>
                      <p>
                        The Licensee shall not: (a) reproduce the Software, in
                        whole or in part; (b) create derivative works based on
                        the Software or any part or component thereof; (c)
                        alter, translate, decompile, or attempt to reverse
                        engineer the Software or any part or component thereof;
                        (d) remove, alter, or obscure any proprietary notice of
                        SyncMonkey; (e) use the Software to create a product,
                        service, or software that is, directly or indirectly,
                        competitive with or in any way a substitute for a
                        product, service, or software offered by the Company;
                        (f) use the Software in contravention of any applicable
                        laws or regulations, including laws and regulations
                        regarding personal information and privacy; (g) expect
                        as expressly permitted by Section 9, sell, assign,
                        license, sub-license disclose, or otherwise transfer or
                        make available the Software, in whole or in part, to any
                        third party.
                      </p>
                      <a id="part10"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        CLIENT PORTAL
                      </p>
                      <p>
                        The Licensee may provide its clients (the “
                        <b>Clients</b>
                        ”) with access to the client portal component of the
                        Software (the “<b>Client Portal</b>”), provided,
                        however, that the Clients must agree to the terms of a
                        separate end user license agreement with SyncMonkey
                        before accessing the Client Portal. The Licensee may
                        charge its Clients to access the Client Portal.
                      </p>
                      <a id="part11"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        SOFTWARE UPDATES
                      </p>
                      <p>
                        The Licensee acknowledges and agrees that SyncMonkey may
                        modify and upgrade the Software from time to time
                        without notice to or consent of the Licensee.
                      </p>
                      <a id="part12"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        NO WARRANTY
                      </p>
                      <p>
                        The Licensee acknowledges and agrees that the Software
                        is provided on an “as-is” basis and that SyncMonkey
                        provides no warranties, either express or implied, with
                        respect to the Software. SyncMonkey makes no warranty
                        that the Software will be uninterrupted, free of viruses
                        or other harmful code, timely, secure, or error-free.
                      </p>
                      <a id="part13"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        LIMITATION OF LIABILITY
                      </p>
                      <p>
                        To the maximum extent permitted by applicable law, in no
                        event shall SyncMonkey be liable for any special,
                        incidental, punitive, indirect, or consequential damages
                        whatsoever arising out of or in any way related to the
                        use of or inability to use the Software.
                      </p>
                      <a id="part14"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        LIABILITY CAP
                      </p>
                      <p>
                        Notwithstanding any damages that the Licensee might
                        incur for any reason whatsoever, the entire liability of
                        SyncMonkey under any provision of this Agreement and the
                        Licensee’s exclusive remedy hereunder shall be limited
                        to, and in no event will SyncMonkey’s total cumulative
                        damages exceed, the aggregate License Fee paid by the
                        Licensee to SyncMonkey for the Software.
                      </p>
                      <a id="part15"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        INFRINGEMENT OF ACKNOWLEDGEMENT
                      </p>
                      <p>
                        The Licensee acknowledges and agrees that in the event
                        of a claim by a third party against the Licensee
                        alleging that the Software or the Licensee’s use thereof
                        infringes the third party’s intellectual property
                        rights, the Licensee will be responsible for the
                        investigation, defence, settlement, and discharge of the
                        claim. The Licensee shall promptly notify SyncMonkey of
                        any such claims.
                      </p>
                      <a id="part16"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        INDEMNITY
                      </p>
                      <p>
                        The Licensee hereby indemnifies and hold harmless
                        SyncMonkey, and its officers, employees, agents,
                        subsidiaries, and affiliates from any damages arising
                        out of, relating to, or resulting from the Licensee’s
                        and the Client’s use of the Software.
                      </p>
                      <a id="part17"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        NO IMPLIED RIGHTS
                      </p>
                      <p>
                        All rights not expressly granted by SyncMonkey to you in
                        this Agreement are hereby reserved by SyncMonkey. There
                        are no implied rights in this Agreement
                      </p>
                      <a id="part18"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        TERMINATION
                      </p>
                      <p>
                        This Agreement is effective from the time and date the
                        Licensee first uses the Software and shall continue
                        until terminated. Either party may terminate this
                        Agreement at any time by providing written notice to the
                        other party. Upon termination, the License granted by
                        this Agreement will immediately terminate and the
                        Licensee shall stop all access and use of the Software.
                        The provisions that by their nature continue and survive
                        will survive any termination of this Agreement.
                      </p>
                      <a id="part19"></a>
                      <p
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        GENERAL
                      </p>
                      <p>
                        This Agreement is governed by and construed in
                        accordance with the laws of the Province of British
                        Columbia. This Agreement constitutes the entire
                        agreement between SyncMonkey and the Licensee relating
                        to the Software. If any provision of this Agreement is
                        held by a court of competent jurisdiction to be contrary
                        to law, such provision will be changed and interpreted
                        so as to best accomplish the objectives of the original
                        provision to the fullest extent allowed by law and the
                        remaining provisions of the Agreement will remain in
                        force and effect. The Licensee may not transfer or
                        assign its rights under this Agreement and any attempted
                        assignment in violation of this Section is void.
                      </p>
                    </div>
                    <br />
                    <a
                      href="#part0"
                      onClick={() => {
                        setPart(0);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 0 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part1"
                      onClick={() => {
                        setPart(1);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 1 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part2"
                      onClick={() => {
                        setPart(2);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 2 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part3"
                      onClick={() => {
                        setPart(3);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 3 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part4"
                      onClick={() => {
                        setPart(4);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 4 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part5"
                      onClick={() => {
                        setPart(5);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 5 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part6"
                      onClick={() => {
                        setPart(6);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 6 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part7"
                      onClick={() => {
                        setPart(7);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 7 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part8"
                      onClick={() => {
                        setPart(8);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 8 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part9"
                      onClick={() => {
                        setPart(9);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 9 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part10"
                      onClick={() => {
                        setPart(10);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 10 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part11"
                      onClick={() => {
                        setPart(11);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 11 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part12"
                      onClick={() => {
                        setPart(12);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 12 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part13"
                      onClick={() => {
                        setPart(13);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 13 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part14"
                      onClick={() => {
                        setPart(14);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 14 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part15"
                      onClick={() => {
                        setPart(15);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 15 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part16"
                      onClick={() => {
                        setPart(16);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 16 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part17"
                      onClick={() => {
                        setPart(17);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 17 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part18"
                      onClick={() => {
                        setPart(18);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 18 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <a
                      href="#part19"
                      onClick={() => {
                        setPart(19);
                      }}
                      style={{ textDecoration: "none", margin: 5 }}
                    >
                      <span
                        style={{
                          backgroundColor: part === 19 ? "#1a84db" : "grey",
                          borderRadius: 20,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </a>
                    <br />
                    <Button
                      variant="outlined"
                      className={classes.nextButton}
                      onClick={nextStep}
                    >
                      Agree
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.backButton}
                      onClick={backStep}
                    >
                      Back
                    </Button>
                  </div>
                )}
                {object.step === 4 && (
                  <div className={classes.centerElement}>
                    <Grid container spacing={0}>
                      <Grid item md={12} lg={3}>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <div
                              className={classes.tableBody}
                              style={{
                                height: tableHeaderHeight,
                                maxHeight: tableHeaderHeight,
                                minHeight: tableHeaderHeight,
                              }}
                            >
                              {object["is_monthly"] && (
                                <div style={{ marginTop: "50px" }}>
                                  <span
                                    className={classes.selectedButton}
                                    style={{
                                      borderRadius: "10px 0px 0px 10px",
                                    }}
                                  >
                                    Monthly
                                  </span>
                                  <span
                                    onClick={() => {
                                      setObject({
                                        ...object,
                                        ["is_monthly"]: false,
                                      });
                                    }}
                                    className={classes.unselectedButton}
                                    style={{
                                      borderRadius: "0px 10px 10px 0px",
                                    }}
                                  >
                                    Annual
                                  </span>
                                </div>
                              )}
                              {!object["is_monthly"] && (
                                <div style={{ marginTop: "50px" }}>
                                  <span
                                    onClick={() => {
                                      setObject({
                                        ...object,
                                        ["is_monthly"]: true,
                                      });
                                    }}
                                    className={classes.unselectedButton}
                                    style={{
                                      borderRadius: "10px 0px 0px 10px",
                                    }}
                                  >
                                    Monthly
                                  </span>
                                  <span
                                    className={classes.selectedButton}
                                    style={{
                                      borderRadius: "0px 10px 10px 0px",
                                    }}
                                  >
                                    Annual
                                  </span>
                                </div>
                              )}
                            </div>
                          </Grid>
                          {showHeader && (
                            <>
                              <Grid item xs={12}>
                                <div
                                  className={classes.tableHeader}
                                  style={{ borderRadius: "20px 0px 0px 0px" }}
                                >
                                  Moneris Module
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  ConnectWise Sync
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  HR Module
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  Meeting Module
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  Ninja Sync
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  Companies Included
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableHeader}>
                                  Portals Included
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  className={classes.tableHeader}
                                  style={{ paddingTop: "20px", height: "70px" }}
                                >
                                  Users Addons
                                </div>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <div
                                  className={classes.tableHeader}
                                  style={{
                                    borderRadius: "0px 0px 0px 20px",
                                    paddingTop: "20px",
                                    height: "70px",
                                  }}
                                >
                                  Portals Addons
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.tableBody}>&nbsp;</div>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      {object["packages"].map((p) => (
                        <Grid item md={12} lg={3}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <div
                                className={classes.tableHeader}
                                style={{
                                  borderRadius: "20px 20px 0px 0px",
                                  height: tableHeaderHeight,
                                  maxHeight: tableHeaderHeight,
                                  minHeight: tableHeaderHeight,
                                }}
                              >
                                <h3>{p.package_name}</h3>
                                {object["is_monthly"] && (
                                  <>
                                    <h1 style={{ color: "#f2eb54" }}>
                                      <strong>${p.monthly_price}</strong>
                                    </h1>
                                    <p style={{ color: "#ffffff" }}>
                                      Per Month
                                    </p>
                                  </>
                                )}
                                {!object["is_monthly"] && (
                                  <>
                                    <h1 style={{ color: "#f2eb54" }}>
                                      <strong>${p.annual_price}</strong>
                                    </h1>
                                    <p style={{ color: "#ffffff" }}>Per Year</p>
                                  </>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {showHeader && (
                                  <p>
                                    {p.is_moneris_module_available ? (
                                      <DoneIcon color="primary" />
                                    ) : (
                                      <ClearIcon color="disabled" />
                                    )}
                                  </p>
                                )}
                                {!showHeader && (
                                  <p>
                                    {p.is_moneris_module_available ? (
                                      <span>Moneris Module</span>
                                    ) : (
                                      <del>Moneris Module</del>
                                    )}
                                  </p>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {showHeader && (
                                  <p>
                                    {p.is_connect_sync_available ? (
                                      <DoneIcon color="primary" />
                                    ) : (
                                      <ClearIcon color="disabled" />
                                    )}
                                  </p>
                                )}
                                {!showHeader && (
                                  <p>
                                    {p.is_connect_sync_available ? (
                                      <span>ConnectWise Sync</span>
                                    ) : (
                                      <del>ConnectWise Sync</del>
                                    )}
                                  </p>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {showHeader && (
                                  <p>
                                    {p.is_hr_module_available ? (
                                      <DoneIcon color="primary" />
                                    ) : (
                                      <ClearIcon color="disabled" />
                                    )}
                                  </p>
                                )}
                                {!showHeader && (
                                  <p>
                                    {p.is_hr_module_available ? (
                                      <span>HR Module</span>
                                    ) : (
                                      <del>HR Module</del>
                                    )}
                                  </p>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {showHeader && (
                                  <p>
                                    {p.is_meeting_module_available ? (
                                      <DoneIcon color="primary" />
                                    ) : (
                                      <ClearIcon color="disabled" />
                                    )}
                                  </p>
                                )}
                                {!showHeader && (
                                  <p>
                                    {p.is_meeting_module_available ? (
                                      <span>Meeting Module</span>
                                    ) : (
                                      <del>Meeting Module</del>
                                    )}
                                  </p>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {showHeader && (
                                  <p>
                                    {p.is_ninja_sync_available ? (
                                      <DoneIcon color="primary" />
                                    ) : (
                                      <ClearIcon color="disabled" />
                                    )}
                                  </p>
                                )}
                                {!showHeader && (
                                  <p>
                                    {p.is_ninja_sync_available ? (
                                      <span>Ninja Sync</span>
                                    ) : (
                                      <del>Ninja Sync</del>
                                    )}
                                  </p>
                                )}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {!showHeader && (
                                  <span> Companies Included</span>
                                )}
                                {p.max_company > -1
                                  ? p.max_company
                                  : "Unlimited"}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div className={classes.tableBody}>
                                {!showHeader && <span> Portals Included</span>}
                                {p.max_portal > -1 ? p.max_portal : "Unlimited"}
                              </div>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                className={classes.tableBody}
                                style={{
                                  height: "70px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                {!showHeader && <span>User Count: </span>}
                                {object["user_addons"]} ( $
                                {object["is_monthly"]
                                  ? p.monthly_price
                                  : p.annual_price}{" "}
                                per user )
                                <Slider
                                  value={object["user_addons"]}
                                  min={1}
                                  max={128}
                                  onChange={(event, newValue) => {
                                    setObject({
                                      ...object,
                                      ["user_addons"]: newValue,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                className={classes.tableBody}
                                style={{
                                  height: "70px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                {!showHeader && <span>Readonly Addons: </span>}
                                {object["readonly_addons"]} ( $
                                {object["is_monthly"]
                                  ? p.user_addon_price
                                  : p.user_addon_price * 12.0}{" "}
                                per portal )
                                <Slider
                                  value={object["readonly_addons"]}
                                  min={0}
                                  max={128}
                                  onChange={(event, newValue) => {
                                    setObject({
                                      ...object,
                                      ["readonly_addons"]: newValue,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                className={classes.tableBody}
                                style={{
                                  height: "70px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                {!showHeader && <span>Portal Addons: </span>}
                                {object["portal_addons"]} ( $
                                {object["is_monthly"]
                                  ? p.portal_addon_price
                                  : p.portal_addon_price * 12.0}{" "}
                                per portal )
                                <Slider
                                  value={object["portal_addons"]}
                                  min={0}
                                  max={128}
                                  onChange={(event, newValue) => {
                                    setObject({
                                      ...object,
                                      ["portal_addons"]: newValue,
                                    });
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                className={classes.tableBody}
                                style={{ marginBottom: "20px" }}
                              >
                                <Button
                                  fullWidth
                                  variant={
                                    p.id === object["packageId"]
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={classes.nextButton}
                                  onClick={() => {
                                    setObject({
                                      ...object,
                                      ["packageId"]: p.id,
                                    });
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Button
                      variant="outlined"
                      className={classes.nextButton}
                      disabled={!object.packageId || object.user_addons === 0}
                      onClick={nextStep}
                    >
                      Next
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.backButton}
                      onClick={backStep}
                    >
                      Back
                    </Button>
                  </div>
                )}
                {object.step === 5 && (
                  <div className={classes.centerElement}>
                    Tenant initialized. <a href="login">Login</a>
                    <br />
                    <br />
                    <Button
                      variant="outlined"
                      className={classes.backButton}
                      onClick={backStep}
                    >
                      Back
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TenantInit;
