import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";

const NotificationForm = props => {
  const [formFields, setFormFields] = useState();

  useEffect(() => {
      if(props.objectId){
        setFormFields([
          {
            id: "content",
            label: "CONTENT",
            type: "textarea"
          },
        ]);
      }
      else{
        setFormFields([
          {
            id: "content",
            label: "CONTENT",
            type: "textarea"
          },
          {
            id: "tenant_admin_only",
            label: "TENANT ADMIN ONLY",
            type: "radio"
          },
        ]);
      }
  }, []);

  return (
    <div>
      {formFields && (
        <ObjectForm
          {...props}
          url={`tenant_user_notifications`}
          formName={"Notifications"}
          formFields={formFields}
          object={{}}
        />
      )}
    </div>
  );
};

export default NotificationForm;
