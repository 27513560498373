import React, { useEffect, useState } from "react";
import TenantForm from "./TenantForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import commonConstants from "../../Common/CommonConstants";
import CheckIcon from '@material-ui/icons/Check';
import Subscription from "./Subscription";
import { hasPermission } from "../../Common/permission";

const Tenants = (props) => {
  const [data, setData] = useState([]);
  const [tenant, setTenant] = useState();
  const [loadData, setLoadData] = useState(false);
  const [activeIds, setActiveIds] = useState([]);
  const url = `tenants`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
		additionalActions: [
			{
				label: "Clean Up",
        disabled: !hasPermission(props, ['Clean Up Tenants']),
				onClick: (ids) => {
          if(window.confirm("Delete lockout more than 60 days?")){
            var ids = [];
            data.map((tenant)=>{
              if(tenant.status === "Lockout"){
                if(tenant.last_payment && Math.ceil((new Date() - new Date(tenant.last_payment)) / (1000 * 60 * 60 * 24)) > 60){
                  ids.push(tenant.id);
                }
                else if(Math.ceil((new Date() - new Date(tenant.trial_end_date)) / (1000 * 60 * 60 * 24)) > 60){
                  ids.push(tenant.id);
                }
              }
            });
            http.Delete(
              url,
              {"ids":ids},
              (res) => {
                fetchData();
              },
              () => {}
            );
          }
				},
			},
		],
  };

  const tableColumns = [
    {
      id: "company_name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
			colored: hasPermission(props, ['Access Billing']),
			style: hasPermission(props, ['Access Billing']) ? commonConstants.cellCursor : null,
      onClick: (props, object, property) => {
        if(hasPermission(props, ['Access Billing'])){
          setTenant(object);
        }
      },
    },
    {
      id: "id",
      label: "ID",
      minWidth: 300,
      sortable: false,
    },
    {
      id: "phone",
      label: "PHONE",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "email",
      label: "EMAIL",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "package_name",
      label: "PACKAGE",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "is_active",
      label: "ACTIVE",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "status",
      label: "STATUS",
      minWidth: 150,
      sortable: true,
			colored: true,
			style: commonConstants.cellCursor,
      onClick: (props, object, property) => {
        if (activeIds.includes(object.id)) {
          setActiveIds(
            activeIds.filter((activeId) => {
              return activeId !== object.id;
            })
          );
        } else {
          var ids = [].concat(activeIds);
          ids.push(object.id);
          setActiveIds(ids);
        }
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((tenant) => {
          tenant["is_active"] = tenant.is_active ? <CheckIcon/> : "";
          tenant["phone"] = tenant.address && tenant.address.phone ? tenant.address.phone : "";
          tenant["subData"] = (
            <span>
              <span style={{fontWeight:"bold"}}>Signed Up</span>: {tenant.created_time && tenant.created_time.split("T")[0]} | <span style={{fontWeight:"bold"}}>Trial End</span>: {tenant.trial_end_date} | <span style={{fontWeight:"bold"}}>Status</span>: {tenant.status} | <span style={{fontWeight:"bold"}}>Last Payment</span>: {tenant.last_payment && tenant.last_payment.split("T")[0]} | <span style={{fontWeight:"bold"}}>Max Company</span>: {tenant.max_company} ({tenant.company_count} used) | <span style={{fontWeight:"bold"}}>User Count</span>: {tenant.user_addons} ({tenant.user_count} used) | <span style={{fontWeight:"bold"}}>Readonly Count</span>: {tenant.readonly_addons} ({tenant.readonly_count} used) | <span style={{fontWeight:"bold"}}>Portal Addons</span>: {tenant.portal_addons} ({tenant.portal_count} used)
            </span>
          );
          tenant["mobile_view"] = tenant["package_name"];
        });
        setData(data);
        setLoadData(false);
      },
      () => {}
    );
  };

  return (
    <>
    {tenant &&  
      <Subscription {...props} id={tenant.id} setTenant={setTenant}/>
    }
    {!tenant && !props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Tenants"],
        edit: ["Create/Modify Tenants"],
        delete: ["Delete Tenant"],
      }}
      objectForm={TenantForm}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      activeIds={activeIds}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
    />
    }
    {!tenant && props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Create/Modify Tenants"],
        edit: ["Create/Modify Tenants"],
        delete: ["Delete Tenant"],
      }}
      objectForm={TenantForm}
      tableColumns={tableColumns}
      searchFields={["company_name"]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Tenants;
