import React, { useState, useEffect } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CommonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import { Button, Divider, Grid, IconButton, MenuItem, Typography, FormControlLabel, Radio } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const TimeOffRequestForm = props => {
  const classes = useStyles();
  const [request, setRequest] = useState({
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    hours: 0,
    vacation_type: "Vacation",
    available_hours_used: false,
    reason: null,
    request_status: "PENDING",
    hr_employee: props.hrEmployee.id,
  });
  const [requestError, setRequestError] = useState({});
  const { requiredError, blankError } = CommonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const vacationTypes = ['Vacation', 'Sick', 'Personal', 'Moving', 'Other'];
  
  const today = new Date()
  const [startDate, setStartDate] = useState(today);
  const [invalidStartDate, setInvalidStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [invalidEndDate, setInvalidEndDate] = useState(null);
  const [isAllDay, setIsAllDay] = useState(true);

  const [startTime, setStartTime] = useState(today);
  const [invalidStartTime, setInvalidStartTime] = useState(null);
  const [endTime, setEndTime] = useState(today);
  const [invalidEndTime, setInvalidEndTime] = useState(null);
  
  const [width, setWidth] = useState(window.innerWidth * 0.8);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (time) => {
    let t = new Date(time).toLocaleTimeString().split(':');
    return [t[0], t[1]].join(':');
  }

  useEffect(() => {
    if (isAllDay) {
      setInvalidStartTime(null);
      setInvalidEndTime(null);
      setRequestError({
        ...requestError,
        start_time: false,
        end_time: false,
        available_hours_used: false,
      });
    }
    else {
      setInvalidEndDate(null);
      setRequestError({
        ...requestError,
        end_date: false,
        available_hours_used: false,
      });
    }
    setIsSubmitted(false);
  }, [isAllDay]);

  useEffect(() => {
		const vw = Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		);
		setWidth(vw * 0.8);
	}, [window.innerWidth]);

  const onChange = e => {
    setRequest({
      ...request,
      [e.target.name]: e.target.value
    });
    setRequestError({
      ...requestError,
      [e.target.name]: false
    });
    setIsSubmitted(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!startDate) {
      setInvalidStartDate("Start date required.");
      return;
    }
    if (isAllDay) {
      if (!endDate) {
        setInvalidEndDate("End date required.");
        return;
      }
      else if (startDate > endDate) {
        const msg = "Invalid date range.";
        setInvalidStartDate(msg);
        setInvalidEndDate(msg);
        return;
      }
    }
    else {
      if (!startTime || !endTime) {
        if (!startTime) {
          setInvalidStartTime("Start time required.");
        }
        if (!endTime) {
          setInvalidEndTime("End time required.");
        }
        return;
      }
      else if (startTime > endTime) {
        const msg = "Invalid time range.";
        setInvalidStartTime(msg);
        setInvalidEndTime(msg);
        return;
      }
    }

    request['start_date'] = formatDate(startDate);
    if (isAllDay) {
      request['end_date'] = formatDate(endDate);
    }
    else {
      request['start_time'] = formatTime(startTime)
      request['end_time'] = formatTime(endTime)
      request['hours'] = ((endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60).toFixed(2);
    }

    http.Post(
      'hr_employee_vacations',
      request,
      (timeOffRequest) => {
        props.setSnackBarMessage(`Time off request sent.`)
        props.setSnackBarOpen(true);
        props.setSeverity("success");
        props.handleSubmit();
      },
      (status, error) => {
        if (status === 400) setRequestError(error);
      }
    );
  };

  return (
    <div className={classes.list} style={{width: width}}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" className={classes.title}>
              {"Time Off Request"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ValidatorForm onSubmit={onSubmit}>
          <div className={classes.content}>
            <p className={`${classes.label} ${classes.semiBold}`}>EMPLOYEE</p>
            <TextValidator
              id="employee"
              fullWidth
              margin="dense"
              variant="outlined"
              name="employee"
              value={props.hrEmployee.full_name || ""}
              onChange={onChange}
              inputProps={{ maxLength: 100, readOnly: true }}
              /*
              validators={["required", "trim"]}
              errorMessages={[requiredError, blankError]}
              error={Boolean(contactError.title)}
              helperText={contactError.title && contactError.title[0]}
              */
            />
            <p className={`${classes.label} ${classes.semiBold}`}>MANAGER</p>
            <TextValidator
              id="manager"
              fullWidth
              margin="dense"
              variant="outlined"
              name="manager"
              value={props.hrEmployee.manager_full_name || ""}
              onChange={onChange}
              inputProps={{ maxLength: 100, readOnly: true }}
              /*
              validators={["required", "trim"]}
              errorMessages={[requiredError, blankError]}
              error={Boolean(contactError.ext)}
              helperText={contactError.ext && contactError.ext[0]}
              */
            />
            <p className={`${classes.label} ${classes.semiBold}`}>TIME OFF PERIOD</p>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  margin="dense"
                  id="start_date"
                  name="start_date"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    setInvalidStartDate(null);
                    setRequestError({...requestError, start_date: false, available_hours_used: false});
                    setInvalidEndDate(null);
                    setIsSubmitted(false);
                  }}
                  minDate={new Date()}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={Boolean(requestError.start_date) || Boolean(invalidStartDate)}
                  helperText={
                    (requestError.start_date && requestError.start_date[0]) || (invalidStartDate)
                  }
                />
              </Grid>
              {isAllDay ? (
                <>
                <Grid item xs={1} style={{textAlign: "center"}}>
                  TO
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="dd/mm/yyyy"
                    margin="dense"
                    id="end_date"
                    name="end_date"
                    value={endDate}
                    onChange={(value) => {
                      setEndDate(value);
                      setInvalidStartDate(null);
                      setRequestError({...requestError, end_date: false, available_hours_used: false});
                      setInvalidEndDate(null);
                      setIsSubmitted(false);
                    }}
                    minDate={new Date()}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    error={Boolean(requestError.end_date) || Boolean(invalidEndDate)}
                    helperText={
                      (requestError.end_date && requestError.end_date[0]) || (invalidEndDate)
                    }
                  />
                </Grid>
                </>
              ) : (
                <>
                <Grid item xs={1} style={{textAlign: "center"}}>
                  FROM
                </Grid>
                <Grid item xs={3}>
                  <KeyboardTimePicker
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    margin="dense"
                    id="start_time"
                    name="start_time"
                    value={startTime}
                    onChange={(value) => {
                      setStartTime(value);
                      setInvalidStartTime(null);
                      setInvalidEndTime(null);
                      setIsSubmitted(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    error={Boolean(requestError.start_time) || Boolean(invalidStartTime)}
                    helperText={
                      (requestError.start_time && requestError.start_time[0]) || (invalidStartTime)
                    }
                  />
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                  TO
                </Grid>
                <Grid item xs={3}>
                  <KeyboardTimePicker
                    fullWidth
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    margin="dense"
                    id="end_time"
                    name="end_time"
                    value={endTime}
                    onChange={(value) => {
                      setEndTime(value);
                      setInvalidStartTime(null);
                      setInvalidEndTime(null);
                      setIsSubmitted(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    error={Boolean(requestError.end_time) || Boolean(invalidEndTime)}
                    helperText={
                      (requestError.end_time && requestError.end_time[0]) || (invalidEndTime)
                    }
                  />
                </Grid>
                </>
              )}
            </Grid>
            <FormControlLabel
              control={
              <Radio
                name="is_all_day"
                checked={isAllDay}
                onClick={() => {
                  setIsAllDay(!isAllDay);
                  setIsSubmitted(false);
                }}
                color="primary"
              />
              }
              label="ALL DAY"
              classes={{ label: classes.headOffice }}
              labelPlacement="end"
            />
            <p className={`${classes.label} ${classes.semiBold}`}>TYPE OF LEAVE</p>
            <TextValidator
              id="vacation_type"
              fullWidth
              select
              margin="dense"
              variant="outlined"
              name="vacation_type"
              value={request.vacation_type || ""}
              onChange={(e) => {
                setRequest({...request, [e.target.name]: e.target.value});
                setRequestError({...requestError,[e.target.name]: false, available_hours_used: false});
                setIsSubmitted(false);
              }}
            >
              {vacationTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextValidator>
            {request.vacation_type === 'Other' && (
              <>
                <p className={`${classes.label} ${classes.semiBold}`}>REASON</p>
                <TextValidator
                  id="reason"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  name="reason"
                  value={request.reason || ""}
                  onChange={onChange}
                  inputProps={{ maxLength: 100}}
                  validators={["required", "trim"]}
                  errorMessages={[requiredError, blankError]}
                />
              </>
            )}
            <FormControlLabel
              control={
              <Radio
                name="available_hours_used"
                checked={request.available_hours_used}
                onClick={() => {
                  setRequest({...request, available_hours_used: !request.available_hours_used})
                  setRequestError({...requestError, available_hours_used: false});
                  setIsSubmitted(false);
                }}
                color="primary"
              />
              }
              label="USE AVAILABLE HOURS"
              classes={{ label: classes.headOffice }}
              labelPlacement="end"
              style={{marginTop: 10}}
            />
            <p className={classes.error}>{requestError.available_hours_used}</p>
          </div>
          <div className={classes.divider}>
            <Divider />
          </div>
          <div className={classes.footer}>
            <div className={classes.outlinedButtonContainer}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => props.closeOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.filledButton}
                type="submit"
                disabled={isSubmitted}
              >
                Save
              </Button>
            </div>
          </div>
        </ValidatorForm>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default TimeOffRequestForm;
