import React from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import ScreenLoader from "../../Common/Loader";

const SharingForm = (props) => {
  const { id } = props.match.params;
  const [companies, setCompanies] = React.useState();
  const [formFields, setFormFields] = React.useState();

  React.useEffect(() => {
    if(companies){
      setFormFields([
        { 
          id: "company", 
          label: "COMPANY", 
          type: "select", 
          valueMap: companies,
        },
        { 
          id: "readonly", 
          label: "READONLY", 
          type: "radio", 
        },
      ]);
    }
  }, [companies]);


  React.useEffect(() => {
    http.Get(
      `companies`,
      (companies) => {
        var valueMap = {};
        companies.map((c)=>{
          valueMap[c.id] = c.company_name;
        });
        setCompanies(valueMap);
      },
      (status, error) => {
      }
    );
  }, []);

  return (
    <>    
		{!formFields && <ScreenLoader />}

    {formFields && (
      <ObjectForm
        {...props}
        url={`sharings`}
        formName={"Sharing"}
        formFields={formFields}
        object={{tenant:id,readonly:true}}
      />
    )}
    </>
  );
};

export default SharingForm;
