import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CommonConstants from "../../Common/CommonConstants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "40%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%"
    }
  },
  centerElement: {
    textAlign: "center"
  },
  button: {
    borderRadius: 30,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme
    },
    color: "white"
  },
}));

const ChangePassword = props => {
  const classes = useStyles();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const errorText = CommonConstants.error;

  const onSubmit = e => {
    e.preventDefault();
    if(newPassword === newPasswordConfirm){
        setIsSubmit(true);
        setIsSuccess(false);
        setHasError(false);
        http.Post(
          "password_change/?user_type=Company Employee",
          { password: password, newPassword: newPassword, email: props.user.email},
          () => {
            setIsSuccess(true);
            setHasError(false);
            setSuccessMessage(
              "Password changed!"
            );
          },
          (status, error) => {
            setIsSubmit(false);
            setHasError(true);
            setErrorMessage(error.password);
          }
        );
    }
    else{
        setIsSubmit(false);
        setHasError(true);
        setErrorMessage("New password not match!");
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <ValidatorForm className={classes.form} onSubmit={onSubmit}>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                </Grid>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h4" component="h5" color="textSecondary">
                    Change Password
                  </Typography>
                </Grid>
                {!isSubmit && (
                    <React.Fragment>
                    <Grid item xs={12}>
                        <TextValidator
                        key="outlined-email-input"
                        label="Current Password"
                        type="password"
                        name="password"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        value={password || ""}
                        onChange={(e) =>{                        
                            setPassword(e.target.value.trim());
                            setHasError(false);
                            setIsSubmit(false);
                        }}
                        validators={["required", "trim"]}
                        errorMessages={[
                            errorText.requiredError,
                            errorText.blankError
                        ]}
                        error={hasError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextValidator
                        key="outlined-email-input"
                        label="New Password"
                        type="password"
                        name="newPassword"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        value={newPassword || ""}
                        onChange={(e) =>{                        
                            setNewPassword(e.target.value.trim());
                            setHasError(false);
                            setIsSubmit(false);
                        }}
                        validators={[
                          "required",
                          "minStringLength:8",
                          "matchRegexp:(?=.*[A-Z])",
                          "matchRegexp:(?=.*[0-9])",
                          "matchRegexp:(?=.*[!@#$%^&*'+])"
                        ]}
                        errorMessages={[
                          errorText.requiredError,
                          errorText.eightChar,
                          errorText.uppercase,
                          errorText.numeric,
                          errorText.specialChar
                        ]}
                        error={hasError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextValidator
                        key="outlined-email-input"
                        label="New Password Confirm"
                        type="password"
                        name="newPasswordConfirm"
                        variant="outlined"
                        autoFocus
                        fullWidth
                        value={newPasswordConfirm || ""}
                        onChange={(e) =>{                        
                            setNewPasswordConfirm(e.target.value.trim());
                            setHasError(false);
                            setIsSubmit(false);
                        }}
                        validators={[
                          "required",
                          "minStringLength:8",
                          "matchRegexp:(?=.*[A-Z])",
                          "matchRegexp:(?=.*[0-9])",
                          "matchRegexp:(?=.*[!@#$%^&*'+])"
                        ]}
                        errorMessages={[
                          errorText.requiredError,
                          errorText.eightChar,
                          errorText.uppercase,
                          errorText.numeric,
                          errorText.specialChar
                        ]}
                        error={hasError}
                        />
                    </Grid>
                    </React.Fragment>
                  )}                

                {isSuccess && (
                    <Grid item xs={12}>
                      <p style={{ color: "green" }}>{successMessage}</p>
                    </Grid>
                )}

                {hasError && (
                    <Grid item xs={12}>
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    </Grid>
                )}

                {!isSubmit && (
                <Grid item xs={12}>
                  <Grid container spacing={1} justify="center">
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={8}
                      align="center"
                      className={classes.centerElement}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.outlinedButton}
                        disabled={isSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default ChangePassword;
