import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import http from "../../../Common/RestAPIHandler";
import { Checkbox, Chip, Grid, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.text
  },
  containerItem: {
    textAlign: "right",
  },
  chipOutlined: {
    borderRadius: 8,
    borderColor: theme.colorScheme,
    marginRight: "2px",
    color: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  search: {
    display: "flex",
    minWidth: 100,
    height: 32,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 5,
  },
  divContent: {
    border: "1px solid #cecece",
    padding: 20,
    height: "calc(100vh - 250px)",
    overflow: "auto",
  },
  textColor: {
    color: theme.colorScheme,
  },
  checkbox: {
    width: "max-content",
  },
  floatRight: {
    color: theme.colorScheme,
    float: "right",
    cursor: "pointer"
  },
}));

const ResellerRolePermission = (props) => {
  const classes = useStyles();
  const [permissionCheck, setPermissionCheck] = useState({});
  const { id } = props.match.params;
  const [permissions, setPermissions] = useState([]);
  const [permissionCategories, setPermissionCategories] = useState([]);
  const [role, setRole] = useState({});

  useEffect(() => {
    http.Get(
      `reseller_roles/${id}`,
      (role) => {
        setRole(role);
      },
      () => {}
    );
    http.Get(
      `reseller_permissions`,
      (permissions) => {
        setPermissions(permissions);
      },
      () => {}
    );
    http.Get(
      `reseller_permission_categories`,
      (permissionCategories) => {
        permissionCategories.map((permissionCategory)=>{
          permissionCategory["show"] = true;
        });
        setPermissionCategories(permissionCategories);
      },
      () => {}
    );
    fetchRolePermission();
  }, []);

  const fetchRolePermission = () => {
    http.Get(
      `reseller_roles/${id}/permissions`,
      (data) => {
        let checks = {};
        data.forEach((item) => {
          checks[item.permission_id] = true;
        });
        setPermissionCheck(checks);
      },
      () => {}
    );
  };

  const handlePermissionCheckChange = (permissionId) => (event) => {
    setPermissionCheck({
      ...permissionCheck,
      [permissionId]: event.target.checked,
    });
    if (event.target.checked) {
      http.Post(
        `reseller_roles/${id}/permissions/${permissionId}`,
        {},
        () => {
          fetchRolePermission();
        },
        () => {}
      );
    } else {
      http.Delete(
        `reseller_roles/${id}/permissions/${permissionId}`,
        {},
        () => {
          fetchRolePermission();
        },
        () => {}
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={8}>
              <Typography variant="h4" color="initial">
                {role.role_name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Chip
                label="BACK"
                variant="outlined"
                style={{
                  height: 35,
                  fontSize: "initial",
                  margin: 0,
                  width: "-webkit-fill-available",
                }}
                className={classes.chipOutlined}
                onClick={() => props.history.goBack()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.divContent}>
                <Grid container spacing={1} alignItems="center">
                  {permissionCategories.map((permissionCategory) => {
                    return (
                      <Grid item xs={12}>
                        {permissionCategory.name}
                        {permissionCategory.show &&
                          <ArrowDropUpIcon
                            fontSize="default"
                            className={classes.floatRight}
                            onClick={()=>{
                              permissionCategory.show = false;
                              var newPermissionCategories = permissionCategories.map((pc)=>{
                                return {...pc};
                              });
                              setPermissionCategories(newPermissionCategories);
                            }}
                          />
                        }
                        {!permissionCategory.show &&
                          <ArrowDropDownIcon
                            fontSize="default"
                            className={classes.floatRight}
                            onClick={()=>{
                              permissionCategory.show = true;
                              var newPermissionCategories = permissionCategories.map((pc)=>{
                                return {...pc};
                              });
                              setPermissionCategories(newPermissionCategories);
                            }}
                          />
                        }
                        <hr/>
                        {permissionCategory.show && permissions.map((permission) => {
                          if(permission.permission_category === permissionCategory.id){
                            return (
                              <Grid container spacing={1} alignItems="center">
                                <Grid item className={classes.checkbox}>
                                  <Checkbox
                                    checked={permissionCheck[permission.id] ? true : false}
                                    onChange={handlePermissionCheckChange(permission.id)}
                                  />
                                </Grid>
                                <Grid item xs>
                                  {permission.permission_name}
                                </Grid>
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResellerRolePermission;
