import React, { useState, useEffect } from "react";
import DomainForm from "./DomainForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import { formatDate } from "../../Common/Utility";
import { ConvertDateToLocale } from "../../Common/Utility";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import commonConstants from "../../Common/CommonConstants";
import { Dialog, Tooltip } from "@material-ui/core";
import DomainDetails from "./DomainDetails";
import SubDomains from "./SubDomains";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { DomainLang } from "../../Common/Languages";

const Domains = (props) => {
  const { id } = props.match.params;
  const [activeIds, setActiveIds] = useState([]);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `company_domains?company=${id}`;
  const today = new Date();
  const expirationDate = new Date(
    today.getTime() + localStorage.getItem("notification_days") * 86400000
  );
  const [objectId, setObjectId] = useState();
  const lang = localStorage.getItem("languages");
  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    additionalActions: [
      {
        label: DomainLang.RefreshAllDomains[lang],
        onClick: (ids) => {
          setLoadData(true);
          var promises = data.map((newObject) => {
            return http.Get(
              `domain_lookup?domain_name=${newObject.domain_name}`,
              (data) => {
                if (data["WHOIS"] && data["WHOIS"]["domain_name"]) {
                  
                  if (Array.isArray(data["WHOIS"]["domain_name"])) {
                    newObject["domain_name"] = data["WHOIS"]["domain_name"][0];
                  } else {
                    newObject["domain_name"] = data["WHOIS"]["domain_name"];
                  }
                  if (Array.isArray(data["WHOIS"]["registrar"])) {
                    newObject["registrar"] = data["WHOIS"]["registrar"][0];
                  } else {
                    newObject["registrar"] = data["WHOIS"]["registrar"];
                  }
                  if (Array.isArray(data["WHOIS"]["expiration_date"])) {
                    newObject["expiration_date"] =
                      data["WHOIS"]["expiration_date"][0].split("T")[0];
                  } else {
                    newObject["expiration_date"] =
                      data["WHOIS"]["expiration_date"].split("T")[0];
                  }
                  if (Array.isArray(data["WHOIS"]["creation_date"])) {
                    newObject["creation_date"] =
                      data["WHOIS"]["creation_date"][0].split("T")[0];
                  } else {
                    newObject["creation_date"] =
                      data["WHOIS"]["creation_date"].split("T")[0];
                  }
                  if (Array.isArray(data["WHOIS"]["updated_date"])) {
                    newObject["last_updated"] =
                      data["WHOIS"]["updated_date"][0].split("T")[0];
                  } else {
                    newObject["last_updated"] =
                      data["WHOIS"]["updated_date"].split("T")[0];
                  }
                  if (Array.isArray(data["DNS"])) {
                    newObject["dns"] = data["DNS"][0];
                  } else {
                    newObject["dns"] = data["DNS"];
                  }
                  if (data["ssl_expiration_date"]) {
                    newObject["ssl_expiration_date"] =
                      data["ssl_expiration_date"].split("T")[0];
                  }
                  http.Put(
                    `company_domains/${newObject.id}`,
                    newObject,
                    (newObject) => {
                      console.log(newObject);
                    },
                    (status, error) => {
                      console.log(status);
                      console.log(error);
                    }
                  );
                }
              },
              () => {}
            );
          });

          Promise.all(promises).then((results) => {
            fetchData();
            setLoadData(false);
          });
        },
      },
    ],
  };

  const toggleActiveIds = (props, object, property) => {
    if (activeIds.includes(object.id)) {
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== object.id;
        })
      );
      object["indicator"] = <ExpandMoreIcon />;
    } else {
      var ids = [].concat(activeIds);
      ids.push(object.id);
      setActiveIds(ids);
      object["indicator"] = <ExpandLessIcon />;
    }
  };

  const tableColumns = [
    {
      id: "indicator",
      label: "",
      minWidth: 10,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
      onClick:
        props.companyPermission && props.companyPermission === "readAndWrite"
          ? toggleActiveIds
          : (props, object, property) => {
              setObjectId(object.id);
            },
    },
    {
      id: "domain_name",
      label: DomainLang.DOMAINNAME[lang],
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "registrar",
      label: DomainLang.REGISTRAR[lang],
      minWidth: 175,
      sortable: true,
    },
    {
      id: "icon",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "expiration_date",
      label: DomainLang.EXPIRATIONDATE[lang],
      minWidth: 175,
      sortable: true,
    },
    {
      id: "icon_ssl",
      label: "",
      minWidth: 10,
      disableTooltip: true,
    },
    {
      id: "ssl_expiration_date",
      label: DomainLang.SSLEXPIRATIONDATE[lang],
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }

    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((domain) => {
          if (
            domain.expiration_date &&
            ConvertDateToLocale(domain.expiration_date) < today
          ) {
            domain["icon"] = (
              <Tooltip title={DomainLang.Domainhasexpired[lang]}>
                <ClearIcon />
              </Tooltip>
            );
          } else if (
            domain.expiration_date &&
            ConvertDateToLocale(domain.expiration_date) < expirationDate
          ) {
            domain["icon"] = (
              <Tooltip title={DomainLang.Domainwillexpiresoon[lang]}>
                <WarningIcon />
              </Tooltip>
            );
          }
          domain["searchFields"] =
            domain["domain_name"] + "," + domain["registrar"];
          domain["expiration_date"] = formatDate(domain["expiration_date"]);
          domain["last_updated"] = formatDate(domain["last_updated"]);
          domain["creation_date"] = formatDate(domain["creation_date"]);
          if (
            domain.ssl_expiration_date &&
            ConvertDateToLocale(domain.ssl_expiration_date) < today
          ) {
            domain["icon_ssl"] = (
              <Tooltip title={DomainLang.SSLhasexpired[lang]}>
                <ClearIcon />
              </Tooltip>
            );
          } else if (
            domain.ssl_expiration_date &&
            ConvertDateToLocale(domain.ssl_expiration_date) < expirationDate
          ) {
            domain["icon_ssl"] = (
              <Tooltip title={DomainLang.SSLwillexpiresoon[lang]}>
                <WarningIcon />
              </Tooltip>
            );
          }
          domain["ssl_expiration_date"] = formatDate(
            domain["ssl_expiration_date"]
          );

          domain["subData"] = (
            <SubDomains {...props} domain={domain} fetchData={fetchData} />
          );

          domain["indicator"] = <ExpandMoreIcon />;

          domain["mobile_view"] = (
            <>
              {domain["icon"]} {domain["icon_ssl"]}
            </>
          );
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <DomainDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
            />
          </div>
        </Dialog>
      )}
      {!props.mobile && data && (
        <ObjectList
          {...props}
          moduleName={DomainLang.Domains[lang]}
          url={url}
          activeIds={activeIds}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_domain > -1 &&
              data.length >= props.companyTenant.max_company_domain)
              ? null
              : DomainForm
          }
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"domain_name"}
          searchFields={["searchFields"]}
          pageHeader={(props.company.company_name || "") + " | Domains"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && data && (
        <ObjectListMobile
          {...props}
          moduleName={"Domains"}
          url={url}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_domain > -1 &&
              data.length >= props.companyTenant.max_company_domain)
              ? null
              : DomainForm
          }
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"domain_name"}
          searchFields={["searchFields"]}
          pageHeader={(props.company.company_name || "") + " | Domains"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Domains);
