import React, { useEffect, useState } from "react";
import PermissionCategoryForm from "./PermissionCategoryForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "name",
    label: "NAME",
    minWidth: 200,
    sortable: true,
  },
];

const PermissionCategories = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `permission_categories`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Permission Categories"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={PermissionCategoryForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Permission Categories"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Permission Categories"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Users"],
        edit: ["Manage Users"],
        delete: ["Manage Users"],
      }}
      objectForm={PermissionCategoryForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"name"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Permission Categories"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default PermissionCategories;
