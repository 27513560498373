import React, { useEffect, useState } from "react";
import CategoryForm from "./CategoryForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import {categories} from "../../Common/Languages";

const Categories = (props) => {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [categoryType, setCategoryType] = useState("All");
  const [loadData, setLoadData] = useState(false);
  const url = `categories`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const lang = localStorage.getItem("languages");
  
  const tableColumns = [
    {
      id: "category_name",
      label: categories.CATEGORYNAME[lang],
      minWidth: 200,
      sortable: true,
    },
    {
      id: "category_type",
      label: categories.CATEGORYTYPE[lang],
      minWidth: 200,
      sortable: true,
    },
  ];

  if(props.user.is_tenant_admin){
    actions["additionalActions"] = [
      {
        label:"Load Default",
        onClick:(ids)=>{
          setLoadData(true);
          http.Get(
            "categories/load_default",
            (data) => {
              fetchData();
              setLoadData(false);
            },
            () => {
              setLoadData(false);
            }
          );
        },
      },
    ];
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    var dataList = [];
    data.map((category) => {
      if (categoryType === "All" || category.category_type === categoryType) {
        dataList.push(category);
      }
    });
    setShowData(dataList);
  }, [data, categoryType]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((category) => {
          category["mobile_view"] = category["category_type"];
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={
        <div>
          {categories.Categories[lang]}{" "}
          <Select
            style={{ fontSize: "12px", marginLeft: "50px", width: "150px" }}
            value={categoryType}
            onChange={(e) => {
              setCategoryType(e.target.value);
            }}
          >
            <MenuItem value="All">{categories.All[lang]}</MenuItem>
            <MenuItem value="Account Type">{categories.AccountType[lang]}</MenuItem>
            <MenuItem value="Credential Type">{categories.CredentialType[lang]}</MenuItem>
            <MenuItem value="Device Type">{categories.DeviceType[lang]}</MenuItem>
            <MenuItem value="Document Category">{categories.DocumentCategory[lang]}</MenuItem>
            <MenuItem value="Employee Type">{categories.EmployeeType[lang]}</MenuItem>
            <MenuItem value="Industry Type">{categories.IndustryType[lang]}</MenuItem>
            <MenuItem value="License Type">{categories.LicenseType[lang]}</MenuItem>
            <MenuItem value="Service Type">{categories.ServiceType[lang]}</MenuItem>
            <MenuItem value="Tag Type">{categories.TagType[lang]}</MenuItem>
          </Select>
        </div>
      }
      // moduleButton={
      //   props.user.is_tenant_admin && (
      //     <Chip
      //       label="LOAD DEFAULT"
      //       clickable
      //       className={classes.chip}
      //       color="primary"
      //       onClick={() => {
      //         setLoadData(true);
      //         http.Get(
      //           "categories/load_default",
      //           (data) => {
      //             fetchData();
      //             setLoadData(false);
      //           },
      //           () => {
      //             setLoadData(false);
      //           }
      //         );
      //       }}
      //     />
      //   )
      // }
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={showData}
      fetchData={fetchData}
      objectForm={(props) => (
        <CategoryForm {...props} categoryType={categoryType} />
      )}
      orderBy={"category_name"}
      searchFields={["category_name"]}
      pageHeader={(props.user.company_name || "") + " | Categories"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Categories"}
      mobileMenu={
        <Grid item xs style={{ fontSize: "12px" }}>
        <Select
          value={categoryType}
          onChange={(e) => {
            setCategoryType(e.target.value);
          }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Account Type">Account Type</MenuItem>
          <MenuItem value="Credential Type">Credential Type</MenuItem>
          <MenuItem value="Device Type">Device Type</MenuItem>
          <MenuItem value="Document Category">Document Category</MenuItem>
          <MenuItem value="Employee Type">Employee Type</MenuItem>
          <MenuItem value="Industry Type">Industry Type</MenuItem>
          <MenuItem value="License Type">License Type</MenuItem>
          <MenuItem value="Service Type">Service Type</MenuItem>
          <MenuItem value="Tag Type">Tag Type</MenuItem>
        </Select>
        </Grid>
      }
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={showData}
      fetchData={fetchData}
      objectForm={(props) => (
        <CategoryForm {...props} categoryType={categoryType} />
      )}
      orderBy={"category_name"}
      searchFields={["category_name"]}
      pageHeader={(props.user.company_name || "") + " | Categories"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Categories;
