import React, { useEffect, useState } from "react";
import SiteForm from "./SiteForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick } from "../../Common/TableUtility";
import { Dialog } from "@material-ui/core";
import SiteDetails from "./SiteDetails";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/core/styles";
import {siteLang} from "../../Common/Languages";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  },
}));

const Site = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [objectId, setObjectId] = useState(null);

  const url = `companies/${id}/sites`;

  const lang = localStorage.getItem("languages");

  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

	const handleClose = () => {
		if (props.handleClose) {
			props.handleClose();
		}
	}

  useEffect(() => {
    fetchData();
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.head_office = item.is_head_office ? siteLang.HEADOFFICE[lang] : ""),
            (item.mobile_view = item.address),
            (item.mobile_view_button = item.primary_contact_number ? (
              <IconButton
                disableTouchRipple
                className={classes.button}
                edge="start"
                href={`tel://${item.primary_contact_number}`}
              >
                <PhoneIcon fontSize="small" />
              </IconButton>
            ) : (
              ""
            ))
          )
        );
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  const tableColumns = [
    {
      id: "site_name",
      label: siteLang.NAME[lang],
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "primary_contact_number",
      label: siteLang.PRIMARYCONTACTNUMBER[lang],
      minWidth: 200,
      sortable: true,
    },
    {
      id: "primary_contact",
      label: siteLang.PRIMARYCONTACT[lang],
      minWidth: 175,
      sortable: true,
    },
    {
      id: "address",
      label: siteLang.ADDRESS[lang],
      minWidth: 150,
      sortable: true,
    },
    {
      id: "head_office",
      label: "",
      minWidth: 150,
      style: commonConstants.smallFont,
      sortable: false,
    },
  ];

  return (
    <>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <SiteDetails
              {...props}
              objectId={objectId}
              handleClose={handleClose}
							onClose={() => {
                setObjectId(null);
              }}
            />
          </div>
        </Dialog>
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={siteLang.SitesList[lang]}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_site > -1 &&
              data.length >= props.companyTenant.max_company_site)
              ? null
              : SiteForm
          }
          orderBy={"site_name"}
          searchFields={["site_name"]}
          pageHeader={(props.company.company_name || "") + " | Sites"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Sites List"}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_site > -1 &&
              data.length >= props.companyTenant.max_company_site)
              ? null
              : SiteForm
          }
          orderBy={"site_name"}
          searchFields={["site_name"]}
          pageHeader={(props.company.company_name || "") + " | Sites"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Site);
