import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import SnackbarComponent from "../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CopyIcon from "@material-ui/icons/FileCopy";
import Popover from '@material-ui/core/Popover';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  stableSort,
  getSorting,
} from "../../Common/Utility";
import { set } from "date-fns/fp";
import {ImportVaultLang} from "../../Common/Languages"

const lang = localStorage.getItem("languages");

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    float:"right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    color: theme.colorScheme,
    fontFamily: "Raleway",
  },
  button: {
    margin: 10,
    borderRadius: 25,
    backgroundColor: theme.colorScheme,
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
      border: "1px solid white",
    },
  },
  buttonCancel: {
    margin: 10,
    borderRadius: 25,
  },
  popover:{
    padding: "20px",
    width:"400px",
    maxWidth:"600px"
  },
  popoverMobile:{
    padding: "20px",
    width:"300px",
    maxWidth:"350px"
  }
}));

const tableColumns = [
  {
    id: "name",
    label: ImportVaultLang.NAME[lang],
    minWidth: 150,
    sortable: true,
  },
  {
    id: "copyUsername",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "username",
    label: ImportVaultLang.USERNAME[lang],
    minWidth: 100,
    sortable: true,
  },
  {
    id: "copyPassword",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "staredPassword",
    label: ImportVaultLang.PASSWORD[lang],
    minWidth: 100,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "url",
    label: ImportVaultLang.URL[lang],
    minWidth: 150,
    sortable: true,
    onClick: onWebsiteClick,
    style: commonConstants.cellCursor,
    colored: true,
  },
  {
    id: "delete_at",
    label: ImportVaultLang.DELETEAT[lang],
    minWidth: 150,
    sortable: true,
  },
  {
    id: "move",
    label: " ",
    minWidth: 10,
    disableTooltip: true,
  },
];

const Configurations = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [object, setObject] = useState();
  const [employees, setEmployees] = useState();
  const [employee, setEmployee] = useState();
  const [newEmployee, setNewEmployee] = useState();
  const [moveTo, setMoveTo] = useState();
  const [devices, setDevices] = useState();
  const [device, setDevice] = useState();
  const [services, setServices] = useState();
  const [service, setService] = useState();
  const [categories, setCategories] = useState();  
  const [category, setCategory] = useState();
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const url = `configurations?company=${id}`;
  const csvHeader = commonConstants.configurationHeader;
  const actions = {
    delete: (props.companyPermission && props.companyPermission === "readAndWrite"),
    importCSV: (props.companyPermission && props.companyPermission === "readAndWrite"),
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );
    fetchEmployees();
    fetchDevices();
    fetchServices();
  }, []);

  const fetchEmployees = () => {
    http.Get(
      `company_employees?company_id=${id}`,
      (data) => {
        var sortedObjects = stableSort(data, getSorting("asc", "employee_name"));
        setEmployees(sortedObjects);
      },
      () => {
      }
    );
  };

  const fetchDevices = () => {
    http.Get(
      `companies/${id}/devices`,
      (data) => {
        var sortedObjects = stableSort(data, getSorting("asc", "device_name"));
        setDevices(sortedObjects);
      },
      () => {
      }
    );  
  };

  const fetchServices = () => {
    http.Get(
      `companies/${id}/services`,
      (data) => {
        var sortedObjects = stableSort(data, getSorting("asc", "service_name"));
        setServices(sortedObjects);
      },
      () => {
      }
    );
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item)=>{
          
          item["copyUsername"] = !item["username"]?"":<CopyIcon
          fontSize="small"
          className={classes.iconColorScheme}
          onClick={(props, row, columnId) => {
            onCopy(props, item, "username");
            setSeverity("success");
            setSnackBarMessage("Username Copied");
          }}
          />;
          
        item["copyPassword"] = !item["password"]?"":<CopyIcon
          fontSize="small"
          className={classes.iconColorScheme}
          onClick={(props, row, columnId) => {
            onCopy(props, item, "password");
            setSeverity("success");
            setSnackBarMessage("Password Copied");
          }}
          />;
          

        item["staredPassword"] = (
          <span
            className={classes.passwordColorScheme}
            onClick={(e) => {
              if (e.target.innerHTML === "************")
                e.target.innerHTML = String(item["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
              else e.target.innerHTML = "************";
            }}
          >
            ************
          </span>
        );

        item['move'] = <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={(e)=>{
                setObject(item);
                setMoveTo(null);
                setDevice(null);
                setService(null);
                setEmployee(null);
                setAnchorEl(e.currentTarget)
                }}>Move</Button>;
                
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(()=> {
    if ((device === "Create New" && props.companyTenant.max_company_device > -1 && devices.length >= props.companyTenant.max_company_device) 
        || (service === "Create New"  && props.companyTenant.max_company_other_service > -1 && services.length >= props.companyTenant.max_company_other_service)
        || (employee === "Create New" && props.companyTenant.max_company_employee > -1 && employees.length >= props.companyTenant.max_company_employee)) {
      setSnackBarMessage(commonConstants.error.packageLimitReached);
      setSeverity("error");
    }
  }
  , [device, service, employee]);

  return (
    <div>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
          setSeverity("success");
        }}
        severity={severity}
      ></SnackbarComponent>
      {anchorEl &&        
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={()=>{setAnchorEl(null);}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
          <div className={props.mobile ? classes.popoverMobile : classes.popover}>
            <h3>{ImportVaultLang.Name[lang]}</h3>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              value={object["name"]}/>
            <h3>{ImportVaultLang.MoveTo[lang]}</h3>              
            <Select
              fullWidth
              variant="outlined"
              margin="dense"
              onChange={(e) => {
                setDevice(null);
                setService(null);
                setEmployee(null);
                setMoveTo(e.target.value);
              }}
            >
              <MenuItem value={""}>
                {""}
              </MenuItem>
              <MenuItem value={"Servers & Devices"}>
                {ImportVaultLang.ServersDevices[lang]}
              </MenuItem>
              <MenuItem value={"Other Services"}>
                {ImportVaultLang.OtherService[lang]}
              </MenuItem>
              <MenuItem value={"Employee Credentials"}>
                {ImportVaultLang.EmployeeCredentials[lang]}
              </MenuItem>
            </Select>
            {devices && moveTo && moveTo === "Servers & Devices" &&
              <>
              <br/><h3>Device</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setDevice(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {ImportVaultLang.CreateNew[lang]}
                </MenuItem>
                {devices.map((device)=>{
                  return <MenuItem key={device.id} value={device.id}>{device.device_name}</MenuItem>;
                })}
              </Select>
              {device && device !== "Create New" &&
                <>
                <br/><h3>{ImportVaultLang.CredentialType[lang]}</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Credential Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && !loading &&
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{                    
                      var data ={
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "device":device,
                        "category":category,
                      };
                      setLoading(true);
                      http.Post(
                        `device/credential/`,
                        data,
                        (newDevice) => {            
                          http.Delete(
                            "configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                              setLoading(false);
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                              setLoading(false);
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                          setLoading(false);
                        }
                      );
                    }}>{ImportVaultLang.Move[lang]}</Button>
                  }
                  </>
                }
              {device && device === "Create New" && (props.companyTenant.max_company_device < 0 || devices.length < props.companyTenant.max_company_device) &&
                <>
                <br/><h3>{ImportVaultLang.DeviceType[lang]}</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Device Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && !loading &&
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var data ={
                        "device_name":object["name"],
                        "url":object["url"],
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "company_id":id,
                        "category":category,
                      };
                      setLoading(true);
                      http.Post(
                        `companies/${id}/devices`,
                        data,
                        (newDevice) => {
                          fetchDevices();          
                          http.Delete(
                            "configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                              setLoading(false);
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                              setLoading(false);
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                          setLoading(false);
                        }
                      );
                    }}>{ImportVaultLang.Move[lang]}</Button>
                  }
                  </>
                }
                </>
            }
            {services && moveTo && moveTo === "Other Services" && !loading && 
              <>
              <br/><h3>{ImportVaultLang.Service[lang]}</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setService(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {ImportVaultLang.CreateNew[lang]}
                </MenuItem>
                {services.map((service)=>{
                  return <MenuItem key={service.id} value={service.id}>{service.service_name}</MenuItem>;
                })}
              </Select>              
              {service && service !== "Create New" && !loading && 
                <>
                <br/>{ImportVaultLang.helper[lang]}<br/>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.outlinedButton}
                  onClick={()=>{
                    var data = services.filter((s)=>{return s.id===service;})[0];
                    data["username"] = object["username"];
                    data["password"] = object["password"];
                    setLoading(true);
                    http.Put(
                      `companies/${id}/services/${service}`,
                      data,
                      (newService) => {            
                        http.Delete(
                          "configurations/" + object["id"],
                          {},
                          () => {
                            setAnchorEl(null);
                            fetchData();
                            setLoading(false);
                          },
                          (status, error) => {
                            setSeverity("error");
                            setSnackBarMessage(JSON.stringify(error));
                            setLoading(false);
                          }
                        );
                      },
                      (status, error) => {
                        setSeverity("error");
                        setSnackBarMessage(JSON.stringify(error));
                        setLoading(false);
                      }
                    );
                  }}>Move</Button>
                  </>
                }
              {service && service === "Create New" && (props.companyTenant.max_company_device < 0 || services.length < props.companyTenant.max_company_other_service) &&
                <>
                <br/><h3>{ImportVaultLang.ServiceType[lang]}</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Service Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && !loading && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var url = object["url"];
                      if(url && !url.startsWith("http")){
                        url = "http://" + url;
                      }
                      var data ={
                        "service_name":object["name"],
                        "website":url,
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "company_id":id,
                        "category": category,
                        "otp":object["otp"],
                      };
                      setLoading(true);  
                      http.Post(
                        `companies/${id}/services`,
                        data,
                        (newService) => {
                          fetchServices(); 
                                   
                          http.Delete(
                            "configurations/" + object["id"],
                            {},
                            () => {
                              setAnchorEl(null);
                              fetchData();
                              setLoading(false);
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                              setLoading(false);
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                          setLoading(false);
                        }
                      );
                    }}>{ImportVaultLang.Move[lang]}</Button>
                  }
                </>
                }
              </>
            }
            {employees && moveTo && moveTo === "Employee Credentials" &&
              <>
              <br/><h3>{ImportVaultLang.Employee[lang]}</h3>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                onChange={(e)=>{
                  setEmployee(e.target.value);
              }}>
                <MenuItem value={"Create New"}>
                  {ImportVaultLang.CreateNew[lang]}
                </MenuItem>
                {employees.map((employee)=>{
                  return <MenuItem key={employee.id} value={employee.id}>{employee.employee_name}</MenuItem>;
                })}
              </Select>
              {employee && employee === "Create New" && categories && (props.companyTenant.max_company_employee < 0 || employees.length < props.companyTenant.max_company_employee) &&
                <>
                <br/><h3>{ImportVaultLang.EmployeeName[lang]}</h3>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={newEmployee}
                  onChange={(e)=>{
                    setNewEmployee(e.target.value);
                  }}></TextField>
                <br/><h3>{ImportVaultLang.AccountType[lang]}</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Account Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && newEmployee && !loading && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={ (props.companyTenant.max_company_employee < 0 || employees.length < props.companyTenant.max_company_employee) ? ()=>{                      
                      http.Post(
                        `company_employees`,
                        {employee_name:newEmployee,company_id:id,status:true},
                        (e) => {
                          fetchEmployees();
                          var url = object["url"];
                          if(url && !url.startsWith("http")){
                            url = "http://" + url;
                          }
                          var credential ={
                            "description":object["name"],
                            "website":url,
                            "username":object["username"],
                            "password":object["password"],
                            "notes":object["notes"],
                            "employee":e.id,
                            "category":category,
                            "otp":object["otp"],
                          };
                          setLoading(true);
                          http.Post(
                            `employee/credential/`,
                            credential,
                            (newCredential) => {
                              http.Delete(
                                "configurations/" + object["id"],
                                {},
                                data => {
                                  setAnchorEl(null);
                                  fetchData();
                                  setLoading(false);
                                },
                                (status, error) => {
                                  setSeverity("error");
                                  setSnackBarMessage(JSON.stringify(error));
                                  setLoading(false);
                                }
                              );
                            },
                            (status, error) => {
                              setSeverity("error");
                              setSnackBarMessage(JSON.stringify(error));
                              setLoading(false);
                            }
                          );
                        },
                        (status, error) => {
                          setSeverity("error");
                          setSnackBarMessage(JSON.stringify(error));
                          setLoading(false);
                        }
                      );
                    } : () => {
                      setSeverity("error");
                      setSnackBarMessage(commonConstants.error.packageLimitReached);
                      setLoading(false);
                    }}>{ImportVaultLang.Move[lang]}</Button>
                }
                </>
              }
              {employee && employee !== "Create New" && categories &&
                <>
                <br/><h3>{ImportVaultLang.AccountType[lang]}</h3>
                <Select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={(e)=>{
                    setCategory(e.target.value);
                }}>
                  <MenuItem value={""}>
                    {""}
                  </MenuItem>
                  {categories.map((category)=>{
                    if(category["category_type"] === "Account Type"){
                      return <MenuItem key={category.id} value={category.id}>{category.category_name}</MenuItem>;
                    }
                  })}
                </Select>
                {category && !loading && 
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.outlinedButton}
                    onClick={()=>{
                      var url = object["url"];
                      if(url && !url.startsWith("http")){
                        url = "http://" + url;
                      }
                      var credential ={
                        "description":object["name"],
                        "website":url,
                        "username":object["username"],
                        "password":object["password"],
                        "notes":object["notes"],
                        "employee":employee,
                        "category":category,
                        "otp":object["otp"],
                    };
                    setLoading(true);
                    http.Post(
                      `employee/credential/`,
                      credential,
                      (newCredential) => {
                        http.Delete(
                          "configurations/" + object["id"],
                          {},
                          data => {
                            setAnchorEl(null);
                            fetchData();
                            setLoading(false);
                          },
                          (status, error) => {
                            setSeverity("error");
                            setSnackBarMessage(JSON.stringify(error));
                            setLoading(false);
                          }
                        );
                      },
                      (status, error) => {
                        setSeverity("error");
                        setSnackBarMessage(JSON.stringify(error));
                        setLoading(false);
                      }
                    );
                  }}>{ImportVaultLang.Move[lang]}</Button>
                }
                </>
              }
              </>
            }
            {category && loading && <span style = {{marginTop:"16px"}}><CircularProgress /></span>}
            {!loading && <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.outlinedButtonCancel}
              style={{margin:"16px"}}
              onClick={()=>{
                setAnchorEl(null);
              }}>
                {ImportVaultLang.Cancel[lang]}
            </Button>}
          </div>
        </Popover>
      }
      {!props.mobile &&
      <ObjectList
        {...props}
        moduleName={ImportVaultLang.ImportVault[lang]}
        url={url}
        importUrl={`companies/${id}/import_configurations`}
        csvHeader={csvHeader}
        actions={actions}
        permissions={{
          create: ["Create/Update Data"],
          edit: ["Create/Update Data"],
          delete: ["Remove Data"],
        }}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        orderBy={"name"}
        searchFields={["name", "notes"]}
        pageHeader={(props.company.company_name || "") + " | Import Vault"}
        loadData={loadData}
        unchangedData={[]}
        excludeAddButton={true}
      />
      }
      {props.mobile &&
      <ObjectListMobile
        {...props}
        moduleName={"Import Vault"}
        url={url}
        importUrl={`companies/${id}/import_configurations`}
        csvHeader={csvHeader}
        actions={actions}
        permissions={{
          create: ["Create/Update Data"],
          edit: ["Create/Update Data"],
          delete: ["Remove Data"],
        }}
        tableColumns={tableColumns}
        data={data}
        fetchData={fetchData}
        orderBy={"name"}
        searchFields={["name", "notes"]}
        pageHeader={(props.company.company_name || "") + " | Import Vault"}
        loadData={loadData}
        unchangedData={[]}
        excludeAddButton={true}
      />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Configurations);
